import React from "react"; 
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function Form2(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
            
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [providentFund, setProvidentFund] = useState([]);
    const providentFundTemp = [];

    const [pensionScheme, setPensionScheme] = useState([]);
    const pensionSchemeTemp = [];

    const [personalData, setPersonalData] = useState([]);
    const personalDataTemp = [];

    const [communicationData, setCommunicationData] = useState([]);
    const communicationDataTemp = [];



    function SetStateAsPerFetched(data) {
        //Provident Fund Data
        for (var i = 0; i < data.providentFundData.length; i++) {
            providentFundTemp.push({
                'firstNameNominee': data.providentFundData[i].firstNameNominee, 'middleNameNominee': data.providentFundData[i].middleNameNominee ? data.providentFundData[i].middleNameNominee : "",
                'lastNameNominee': data.providentFundData[i].lastNameNominee, 'dob': data.providentFundData[i].dobStr,
                'nomineeRelationWithMember': data.providentFundData[i].nomineeRelationWithMember, 'nomineeAddress': data.providentFundData[i].nomineeAddress,
                'nomineeShare': data.providentFundData[i].nomineeShare, 'guardianName': data.providentFundData[i].guardianName ? data.providentFundData[i].guardianName : "",
                'guardianAddress': data.providentFundData[i].guardianAddress ? data.providentFundData[i].guardianAddress : "", 'validationOfMinorYesOrNo': data.providentFundData[i].validationOfMinorYesOrNo,
                'count': i + 1

            });
        }
        setProvidentFund(providentFundTemp);


        //Pension Scheme Data
        for (var i = 0; i < data.pensionSchemeData.length; i++) {
            pensionSchemeTemp.push({
                'firstNameNominee': data.pensionSchemeData[i].firstNameNominee, 'middleNameNominee': data.pensionSchemeData[i].middleNameNominee ? data.pensionSchemeData[i].middleNameNominee : "",
                'lastNameNominee': data.pensionSchemeData[i].lastNameNominee, 'dob': data.pensionSchemeData[i].dobStr,
                'relationWithMember': data.pensionSchemeData[i].relationWithMember, 'nomineeAddress': data.pensionSchemeData[i].nomineeAddress,
                'gaurdianName': data.pensionSchemeData[i].gaurdianName ? data.pensionSchemeData[i].gaurdianName : "",
                'gaurdianAddress': data.pensionSchemeData[i].gaurdianAddress ? data.pensionSchemeData[i].gaurdianAddress : "", 'validationOfMinorYesOrNo': data.pensionSchemeData[i].validationOfMinorYesOrNo,
                'count': i + 1

            });
        }
        setPensionScheme(pensionSchemeTemp);

        //Personal Data
        personalDataTemp.push({
            'firstName': data.personalData.firstName, 'middleName': data.personalData.middleName ? data.personalData.middleName : "",
            'lastName': data.personalData.lastName, 'fatherName': data.personalData.fatherName ? data.personalData.fatherName : (data.personalData.spouseName ? data.personalData.spouseName : ""),
            'gender': data.personalData.genders[0].name, 'maritalStatus': data.personalData.maritalStatuses[0].name,
            'dateOfBirth': data.personalData.dateOfBirthStr, 'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr

        });

        setPersonalData(personalDataTemp);

        //Communication Data
        communicationDataTemp.push({
            'premanentAddress1': data.communicationData.premanentAddress1, 'permanentAddress2': data.communicationData.permanentAddress2,
            'permanentAddress3': data.communicationData.permanentAddress3 ? data.communicationData.permanentAddress3 : "",
            'presentAddress1': data.communicationData.presentAddress1, 'presentAddress2': data.communicationData.presentAddress2,
            'presentAddress3': data.communicationData.presentAddress3 ? data.communicationData.presentAddress3 : ""

        });

        setCommunicationData(communicationDataTemp);

    }


    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);

            }
        }

    }, [encryptedId, props.token]);

    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">
            <table className="width-100">


                {/* <thead>
                    <tr className="d-flex jc-space-bt">
                        <th>
                        </th>
                        <th className="d-flex jc-center">
                            <p className="f-16 pl-5 fw-bold">FORM 2 (Revised)</p>
                         </th>
                        <th className="">
                            <p className="f-12 mr-3e">GID: {props.gid}</p>
                        </th>
                    </tr>
                </thead> */}
                
                <tbody>
                {/*    <tr>*/}
                {/*    <tr className="d-flex jc-center">*/}
                {/*        <td>*/}
                {/*            <b className="f-16 fw-bold">FORM 2 (Revised)</b>*/}
                {/*            </td>*/}
                {/*    </tr>*/}

                {/*        <td>*/}
                {/*            <div className="d-flex jc-flex-end f-12">*/}
                {/*                <span className="mb-0 fw-bold">*/}
                {/*                    GID:<span className="fw-bold">{props.gid}</span>*/}
                {/*                </span>*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}

                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*            <span className="f-14 pr-2 fw-bold mt-1">NOMINATION AND DECLARATION*/}
                {/*            FORM</span>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*            <span className="f-14 pr-2 fw-bold mt-1">FOR UNEXEMPTED/EXEMPTED*/}
                {/*            ESTABLISHMENTS</span>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*                    <span className="f-12 fw-bold mt-05">Declaration and Nomination Form*/}
                {/*            under the Employees’ Provident Funds and Employees’ Pension Scheme</span>*/}
                {/*        </td>*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex">*/}
                {/*            <span className="f-12 ml-1 mt-1">(Paragraphs 33 & 61 (1) of the*/}
                {/*        Employees Provident Fund Scheme, 1952 and Paragraph 18 of the Employees’ Pension scheme,*/}
                {/*            1995)</span>*/}
                {/*        </td>*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td className="h-20">*/}
                {/*            <table>*/}
                {/*                <tbody>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">1. Name : {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}</span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">2. Father’s/Husband’s*/}
                {/*                            Name : {personalData.map(personalDataNew => <span>{personalDataNew.fatherName}</span>)}</span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">3. Date of Birth*/}
                {/*                            : {personalData.map(personalDataNew => <span>{personalDataNew.dateOfBirth}</span>)}</span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">4. Sex : {personalData.map(personalDataNew => <span>{personalDataNew.gender}</span>)}</span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">5. Marital Status*/}
                {/*                            : {personalData.map(personalDataNew => <span>{personalDataNew.maritalStatus}</span>)}</span>*/}
                {/*                        </td>*/}
                {/*                    </tr >*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">6. Account No. :</span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">7. Address :*/}
                {/*                                <span className="f-12 ml-3 mt-1">Permanent : {communicationData.map(communicationDataNew => <span>{communicationDataNew.premanentAddress1 + ", " + communicationDataNew.permanentAddress2 + ", " + communicationDataNew.permanentAddress3}</span>)}</span>*/}
                {/*                            </span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <div className="f-12 ml-77 mt-1">Temporary : {communicationData.map(communicationDataNew => <span>{communicationDataNew.presentAddress1 + ", " + communicationDataNew.presentAddress2 + ", " + communicationDataNew.presentAddress3}</span>)}</div>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <span className="f-12 ml-1 mt-1">8. Date of Joining :*/}
                {/*                                <span className="f-12 ml-08 mt-1">EPF :  {personalData.map(personalDataNew => <span>{personalDataNew.joiningDate}</span>)}</span>*/}
                {/*                            </span>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                    <tr>*/}
                {/*                        <td className="d-flex">*/}
                {/*                            <div className="f-12 ml-78 mt-1">EPS :  {personalData.map(personalDataNew => <span>{personalDataNew.joiningDate}</span>)}</div>*/}
                {/*                        </td>*/}
                {/*                        </tr>*/}
                {/*                </tbody>*/}
                {/*            </table >*/}
                {/*        </td >*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*        <span className="f-16 fw-bold mt-1">PART – A (EPF)</span>*/}
                {/*        </td>*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*        <span className="f-12 mt-1">I hereby nominate the person(s)*/}
                {/*        /cancel the nomination made by me previously and nominate the person(s) mentioned below*/}
                {/*        to receive the amount standing to my credit in the Employees’ Provident Fund in the event of my*/}
                {/*            death :</span>*/}
                {/*        </td>*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <table className="bdr-black border-collapse width-100 ext-center vertical-middle mt-2">*/}
                {/*                <tbody className="f-12">*/}
                {/*                    <tr>*/}
                {/*                        <td className="bdr-black">Name of the nominee/nominees*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black">Address of the nominee/nominees*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black">Nominee’s relationship with the member</td>*/}
                {/*                        <td className="bdr-black">Date of Birth</td>*/}
                {/*                        <td className="bdr-black">Total amount of share of Accumulations in Provident Fund*/}
                {/*                            to be paid to each nominee</td>*/}
                {/*                        <td className="bdr-black">If the nominee is a minor, name & relationship & address*/}
                {/*                        of the guardian who may receive the amount during the minority*/}
                {/*                            of nominee</td>*/}
                {/*                    </tr>*/}
                {/*                    <tr className="h-20">*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {providentFund.map(providentFundNew => <div><br />{providentFundNew.firstNameNominee + " " + providentFundNew.middleNameNominee + " " + providentFundNew.lastNameNominee}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {providentFund.map(providentFundNew => <div><br />{providentFundNew.nomineeAddress}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {providentFund.map(providentFundNew => <div><br />{providentFundNew.nomineeRelationWithMember}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {providentFund.map(providentFundNew => <div><br />{providentFundNew.dob}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {providentFund.map(providentFundNew => <div><br />{providentFundNew.nomineeShare}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {providentFund.map(providentFundNew => <div><br />{"Guardian Name: " + providentFundNew.guardianName + " , Guardian Address: " + providentFundNew.guardianAddress}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                                    
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex">*/}
                {/*            <div className="f-12 mt-1 ml-5">1 * Certified that I have no family*/}
                {/*            as defined in para 2(g) of the Employees’ Provident Fund Scheme, 1952 and should*/}
                {/*                I acquire a Family hereafter, the above nomination should be deemed as cancelled.</div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex">*/}
                {/*            <div className="f-12 mt-1 ml-5">2 * Certified that my father/mother*/}
                {/*                is/are dependent upon me.</div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <div className="d-flex jc-space-bt mt-1 ml-1">*/}
                {/*                <span className="mt-1 f-12">_____________________________<br />*Strike out*/}
                {/*                    whichever is not*/}
                {/*                    applicable.</span>*/}
                {/*                <span className="mt-1 mr-2 f-14 fw-bold">Signature*/}
                {/*                    or thumb impression of the subscriber</span>*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*            <span className="f-16 fw-bold mt-1">Part B (EPS) (Para*/}
                {/*            18)</span>*/}
                {/*        </td>*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex">*/}
                {/*            <div className="f-12 ml-2 mt-1">I hereby furnish below*/}
                {/*            particulars of the*/}
                {/*            members of my family who would be eligible to receive widow/children pension*/}
                {/*                in the event of my death.</div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <table className="bdr-black border-collapse width-100 text-center vertical-middle mt-2">*/}
                {/*                <tbody className="f-12">*/}
                {/*                    <tr>*/}
                {/*                        <td className="bdr-black">S.No.</td>*/}
                {/*                        <td className="bdr-black">Name of the family members</td>*/}
                {/*                        <td className="bdr-black">Address</td>*/}
                {/*                        <td className="bdr-black">Date of Birth</td>*/}
                {/*                        <td className="bdr-black">Relationship with the member</td>*/}
                {/*                    </tr>*/}
                {/*                    <tr className="h-25">*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.count}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.firstNameNominee + " " + pensionSchemeNew.middleNameNominee + " " + pensionSchemeNew.lastNameNominee}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.nomineeAddress}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.dob}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.relationWithMember}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                                    
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex">*/}
                {/*            <div className="f-12 ml-2 mt-1">** Certified that I have no family,*/}
                {/*            as defined in para 2(vii) of Employees’ Pension Scheme, 1995 and should I acquire a*/}
                {/*            family*/}
                {/*                hereafter I shall furnish particulars thereon in the above form.</div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex">*/}
                {/*                    <div className="f-12 ml-2 mt-05">I hereby nominate the following*/}
                {/*            person for receiving the monthly widow pension (admissible under para 16 2(a)(i) and*/}
                {/*            (ii)*/}
                {/*            in the event of my death without leaving any eligible family member for receiving Pension.*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*            <table className="bdr-black border-collapse width-60 text-center vertical-middle mt-2">*/}
                {/*                <tbody className="f-12">*/}
                {/*                    <tr>*/}
                {/*                        <td className="bdr-black">Name of the Nominee</td>*/}
                {/*                        <td className="bdr-black">Address of the Nominee</td>*/}
                {/*                        <td className="bdr-black">Date of Birth</td>*/}
                {/*                        <td className="bdr-black">Relationship with the member</td>*/}
                {/*                    </tr>*/}
                {/*                    <tr className="h-70">*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.firstNameNominee + " " + pensionSchemeNew.middleNameNominee + " " + pensionSchemeNew.lastNameNominee}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.nomineeAddress}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.dob}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        <td className="bdr-black text-center vertical-middle">*/}
                {/*                            {pensionScheme.map(pensionSchemeNew => <div><br />{pensionSchemeNew.relationWithMember}<br /><br /></div>)}*/}
                {/*                        </td>*/}
                {/*                        </tr>*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="h-30">*/}
                {/*        <td>*/}
                {/*            <span className="ml-1 f-12">Place: {personalData.map(personalDataNew => <span>{personalDataNew.placeOfWork}</span>)}</span>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="h-30">*/}
                {/*        <td>*/}
                {/*            <span className="ml-1 f-12">Date:  {personalData.map(personalDataNew => <span>{personalDataNew.joiningDate}</span>)}</span>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <div className="d-flex mt-1 ml-1 jc-space-bt">*/}
                {/*                <span className="f-12">_____________________________<br />*Strike out*/}
                {/*                    whichever is not*/}
                {/*                    applicable.</span>*/}
                {/*                <span className="ml-2 f-14 fw-bold">Signature*/}
                {/*                    or thumb impression of the subscriber</span>*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex jc-center">*/}
                {/*            <span className="f-16 fw-bold mt-1">CERTIFICATE BY*/}
                {/*            EMPLOYER</span>*/}
                {/*        </td>*/}
                {/*    </tr >*/}
                {/*    <tr>*/}
                {/*        <td className="d-flex l-30">*/}
                {/*            <div className="f-12 mt-1 ml-2">Certified*/}
                {/*            that the above declaration and nomination has been signed/thumb impressed before me by*/}
                {/*            Shri/Smt./Kum. {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)} employed in my establishment after he/she has read the entries/entries have*/}
                {/*            been*/}
                {/*            read over to*/}
                {/*            him/her*/}
                {/*                by me and got confirmed by him/her.</div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <div className="d-flex mt-1 ml-1 jc-space-bt">*/}
                {/*                <span className="ml-3 f-12">Place: </span>*/}
                {/*                <span className="mr-2 f-14 fw-bold">Signature of the employer or*/}
                {/*                    other<br />Authorized Officers of the Establishment.</span>*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <div className="d-flex mt-1 ml-1 jc-space-bt">*/}
                {/*                <span className="ml-3 f-12">Date: </span>*/}
                {/*                <span className="mr-11 f-14 fw-bold">Designation</span>*/}
                {/*            </div>*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*<td>*/}
                {/*    <div className="d-flex jc-flex-end mt-1 ml-1">*/}
                {/*        <span className="mr-2 f-14 fw-bold">Name & Address of the*/}
                {/*            Factory/<br />Establishment or Rubber Stamp Thereon</span>*/}
                {/*    </div>*/}
                {/*</td>*/}
                {/*    </tr>*/}
                </tbody>
            </table >
        </div>
    );

}