import { Box, Button, Grid, TextField, ThemeProvider } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import FormDatePicker from "../components/DatePicker";
import { getDateBefore15YearsFromNow, baseDHLApiURL } from "./utilsDHLPrerequisites";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDHLAction } from "../../Redux/Actions/actions";
export default function CanididateDetailsDHL(props) {
    const dispatch = useDispatch();
    const storedInputDataDHL = useSelector(state => state.inputDataDHLReducer.inputDataDHL);
    let [inputDataToDHL, setInputDataToDHL] = useState({
        applicantId: getURLParameter("id"),
        userIdencrypted: props.userId,
        firstName: "",
        lastName: "",
        email: "",
        areObj: {},
        taname: "",
        taemail: "",
        address: "",
        dateOfBirth: null,
        mprnumber: "",
        referenceNo: "",
        letterType: 0,
        letterTypeLabel: "",
        letterTypeObj: {},
        letterTemplateType: 0,
        letterTemplateTypeLabel: "",
        letterTemplateTypeObj: {},
        noOfSignatories: 0,
        noOfSignatoriesLabel: "",
        noOfSignatoriesObj: {},
        signatoryDetails: [],
        employeeType: 0,
        employeeTypeLabel: "",
        employeeTypeObj: {},
        jobLocation: 0,
        jobLocationLabel: "",
        jobLocationObj: {},
        jobTitle: 0,
        jobTitleLabel: "",
        jobTitleObj: {},
        businessTitle: "",
        businessUnit: "",
        grip: 0,
        gripLabel: "",
        gripCode: "",
        gripObj: {},
        staff: 0,
        staffLabel: "",
        staffObj: {},
        compensationGrade: 0,
        compensationGradeLabel: "",
        compensationGradeObj: {},
        compensationGradeProfile: 0,
        compensationGradeProfileLabel: "",
        compensationGradeProfileObj: {},
        payPlan: 1,
        payPlanLabel: "Salary Plan",
        payPlanObj: { id: 1, name: "Salary Plan" },
        performencePayPlan: 0,
        performencePayPlanLabel: "",
        performencePayPlanObj: {},
        salaryAmount: 0,
        performencePay: 0,
        targetPay: 0,
        allowancePlan: "",
        allowancePlanLabel: "",
        allowancePlanObj: [],
        allowanceAmountObj: [],
        bfp: 0,
        bp: 0,
        bpa: 0,
        hra: 0,
        totalRetirals: 0,
        pfContri: 0,
        gratuityContri: 0,
        negotiationLetterType: 0,
        negotiationLetterTypeLabel: "",
        negotiationLetterTypeObj: {},
        compensationLetterUploadId: 0,
        isCompensationLetterUploaded: false,
        isNegotiationSent: false,
        digiLetterWorkflowId: 0,
        isCompensationAccepted: true,
        isCompensationRejected: false,
        digiLetterStatusId: 0,
        isOfferAccepted: false,
        isOfferRejected: false,
        offerLetterUploadId: 0,
        offerLetterUploadId: 0,
        isOfferLetteruploaded: false,
        siemensLogo: "../assets/images/sie-logo-petrol-rgb.png",
    });
    function dateChangeEvent(dateValue, label) {
        if (label === "Date Of Birth") {
          setInputDataToDHL({
                ...inputDataToDHL,
                dateOfBirth: dateValue,
          });
            let inputUpdatedDHL = {
                ...inputDataToDHL,
                dateOfBirth: dateValue,
            };
            dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
    }
    const handleConfirm = (e) => {
        if (dialogPropsConfirm?.type !== "Recall Offer Letter") {
            setDialogPropsConfirm({
                ...dialogPropsConfirm,
                open: false,
                className: "bg-light-green clr-white",
                type: "",
                title: "",
                content: "",
            });
        }
        switch (dialogPropsConfirm?.type) {
            case "Save As New":

                saveDHLPrerequisites();


                break;
            case "Update Existing":

                updateDHLPrerequisites();


                break;
            case "Save & Send Negotiation":
                saveAndSendNegotiation();
                break;
            case "Send Offer for Digital Signature":
                saveAndPrepareOfferDataForDHL();
                break;
            case "Recall Offer Letter":
                if (!objRecallOfferLetter?.reasonOfRecall) {
                    e.preventDefault();
                    setObjRecallOfferLetter({
                        ...objRecallOfferLetter,
                        errorTextReason: "Recall reason is mandatory",
                    });
                } else {
                    objRecallOfferLetter.shubharambhLetterId =
                        inputDataToDHL?.offerLetterUploadId;
                    objRecallOfferLetter.tAEmail = inputDataToDHL?.taemail;
                    objRecallOfferLetter.tAName = inputDataToDHL?.taname;
                    objRecallOfferLetter.errorTextReason = "";
                    setDialogPropsConfirm({
                        ...dialogPropsConfirm,
                        open: false,
                        className: "bg-light-green clr-white",
                        type: "",
                        title: "",
                        content: "",
                    });
                    recallOfferLetterFromDHL(objRecallOfferLetter);
                }
                break;
            default:
                handleCloseConfirm();
                break;
        }
    };
    const handleLoader = (isEnable, text) => {
        setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
    };
    let [dialogPropsALert, setDialogPropsALert] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    let [dialogPropsConfirm, setDialogPropsConfirm] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    const handleCloseConfirm = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogPropsConfirm({
            ...dialogPropsConfirm,
            open: false,
            className: "bg-light-green clr-white",
            type: "",
            title: "",
            content: "",
        });
    };
    const handleDialogOpenAlert = (type, title, content) => {
        setDialogPropsALert({
            ...dialogPropsALert,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };
    const handleCloseAlert = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogPropsALert({
            ...dialogPropsALert,
            open: false,
            className: "bg-light-green clr-white",
            type: "",
            title: "",
            content: "",
        });
        switch (dialogPropsALert?.content) {
            case "Digital Letter details saved successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Digital Letter details updated successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Data saved, workflow updated and compensation proposal sent successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Data saved, workflow updated but compensation proposal sent failed.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Offer sent for Digital Signature successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Compensation Letter uploaded successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Compensation Letter data deleted successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Offer Letter uploaded successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Offer Letter data deleted successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Document uploaded successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Additional Document data deleted successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            default:
                setDialogPropsALert({
                    ...dialogPropsALert,
                    open: false,
                    className: "bg-light-green clr-white",
                    type: "",
                    title: "",
                    content: "",
                });
                break;
        }
    };
    let [loaderProps, setLoaderProps] = useState({
        isEnable: false,
        loaderText: "",
    });
    let [objRecallOfferLetter, setObjRecallOfferLetter] = useState({
        shubharambhLetterId: inputDataToDHL?.offerLetterUploadId,
        tAEmail: inputDataToDHL?.taemail,
        tAName: inputDataToDHL?.taname,
        reasonOfRecall: "",
        errorTextReason: "",
    });
    const alertDialogComponent = (
        <Dialog
            open={dialogPropsALert?.open}
            onClose={handleCloseAlert}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
        >
            <DialogTitle
                className={dialogPropsALert?.className}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
            >
                {dialogPropsALert?.type}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="clr-dark-blue">
                    {dialogPropsALert?.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-content">
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleCloseAlert}
                    className="ns-btn-secondary-light"
                >
                    <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
    const confirmDialogComponenet = (
        <Dialog
            open={dialogPropsConfirm?.open}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadClear"
        >
            <DialogTitle
                className={dialogPropsConfirm?.className}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
            >
                {dialogPropsConfirm?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="clr-dark-blue">
                    {dialogPropsConfirm?.content}
                    {dialogPropsConfirm?.title?.toLowerCase()?.includes("recall") ? (
                        <TextField
                            className="mt-1 lightInputFields"
                            id="recallReason"
                            label="Reason Of Recall Offer Letter"
                            variant="filled"
                            fullWidth
                            multiline
                            maxRows={4}
                            placeholder="Enter a valid reason for recall the offer letter"
                            name="rejectionReason"
                            value={objRecallOfferLetter?.reasonOfRecall}
                            onChange={(e) =>
                                setObjRecallOfferLetter({
                                    ...objRecallOfferLetter,
                                    reasonOfRecall: e.target.value,
                                })
                            }
                            InputLabelProps={{ shrink: true }}
                            required
                            error={objRecallOfferLetter?.errorTextReason}
                            helperText={objRecallOfferLetter?.errorTextReason}
                            style={{ backgroundColor: "#E8F8F8" }}
                        />
                    ) : (
                        <></>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-content">
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleConfirm}
                    className="ns-btn-secondary-light mr-15px"
                >
                    <span className="f-16 fw-bold clr-white">Confirm</span>
                </Button>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleCloseConfirm}
                    className="ns-btn-secondary-light"
                >
                    <span className="f-16 fw-bold clr-white">Close</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
  
    

    function saveDHLPrerequisites() {
        debugger;
        inputDataToDHL.applicantIdEncrypted = inputDataToDHL.applicantId;
        inputDataToDHL.applicantId = "";
        handleLoader(true, "Saving Digital Letter Prerequisites data....");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: inputDataToDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Success",
                        "Digital Letter Data Save Success",
                        result.responseMsg
                    );
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Digital Letter Data Save Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                handleDialogOpenAlert("Exception", "Digital Letter Data Save Exception", error);
            });
    }
    function updateDHLPrerequisites() {
        handleLoader(true, "Updating Digital Letter Prerequisites data....");
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/UpdateDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: inputDataToDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Success",
                        "Digital Letter Data Update Success",
                        result.responseMsg
                    );
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Digital Letter Data Update Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                handleDialogOpenAlert("Exception", "Digital Letter Data Update Exception", error);
            });
    }
    function saveAndSendNegotiation() {
        inputDataToDHL.applicantIdEncrypted = inputDataToDHL.applicantId;
        inputDataToDHL.applicantId = "";
        handleLoader(
            true,
            "Saving Digital Letter Prerequisites data and sending compensation letter...."
        );
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveAndSendNegotiation",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: inputDataToDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Success",
                        "Digital Letter Data Save And Send Negotiation Success",
                        result.responseMsg
                    );
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Digital Letter Data Save And Send Negotiation Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                handleDialogOpenAlert(
                    "Exception",
                    "Data Save And Send Negotiation Exception",
                    error
                );
            });
    }
    function recallOfferLetter(data) {
        handleLoader(true, "Updating recall status....");
        //console.log("Recall data : ", data);
        handleLoader(false, "");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/RecallOfferLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: data,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    // setInputDataToDHL({
                    //     ...inputDataToDHL,
                    //     digiLetterStatusId: result?.objResult?.digiLetterStatusId,
                    // });
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterStatusId: result?.objResult?.digiLetterStatusId,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                   // dispatch(inputDataDHLAction(inputUpdatedDHL));

                    handleDialogOpenAlert(
                        "Success",
                        "Recall Offer Letter Success",
                        result.responseMsg
                    );

                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Recall Offer Letter Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }

    function sendOfferToDHL(data) {
        handleLoader(true, "Sending offer data for Digital Signature....");
        const token = props.token;
        const options = {
            method: "post",
            url: baseDHLApiURL + "/DHLAPI/SaveShubharambhLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: data,
        };
        axios(options)
            .then((response) => {
                let result = response.data;
                if (result) {
                    handleLoader(false, "");

                    // setInputDataToDHL({
                    //     ...inputDataToDHL,
                    //     digiLetterStatusId: data?.digiLetterStatusId,
                    // });
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterStatusId: data?.digiLetterStatusId,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                    //dispatch(inputDataDHLAction(inputUpdatedDHL));

                    handleDialogOpenAlert(
                        "Success",
                        "Send Offer Success",
                        "Offer has been sent For Digital Signature successfully."
                    );

                } else {
                    handleLoader(false, "");

                    handleDialogOpenAlert(
                        "Alert",
                        "Send Offer Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    function saveAndPrepareOfferDataForDHL() {
        inputDataToDHL.applicantIdEncrypted = inputDataToDHL.applicantId;
        inputDataToDHL.applicantId = "";
        handleLoader(true, "Preparing offer data to be sent for Signature....");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveAndSendDataToDHL",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: inputDataToDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    sendOfferToDHL(result.objResult);
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Send Offer To Digital Letter Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    function recallOfferLetterFromDHL(dataToDHL) {
        handleLoader(true, "Recalling offer letter ....");
        const token = props.token;
        const options = {
            method: "post",
            url: baseDHLApiURL + "/DHLAPI/RecallShubharambhLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: dataToDHL,
        };
        // console.log("data to dhl : ", dataToDHL);
        // handleLoader(false, "");
        axios(options)
            .then((response) => {
                let result = response.data;
                if (result) {
                    handleLoader(false, "");
                    let data = {
                        applicantIdEncrypted: inputDataToDHL?.applicantId,
                        userIdencrypted: inputDataToDHL?.userIdencrypted,
                        offerRecallReason: dataToDHL?.reasonOfRecall,
                    };
                    recallOfferLetter(data);

                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Recall Offer Letter Failed",
                        "There is some issue while recalling offer letter. Please try again or contact to administrator."
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    const handleDialogOpenConfirm = (type, title, content) => {
        setDialogPropsConfirm({
            ...dialogPropsConfirm,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };
    
   
  

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }

    let encryptedId = getURLParameter("id");
    let data, id;
    function setDigiLetterDataState(data) {
         setInputDataToDHL({
             ...inputDataToDHL,
             digiLetterDetailsId: data?.digiLetterDetailsId,
             applicantId: data?.applicantIdEncrypted,
             firstName: data?.firstName,
             lastName: data?.lastName,
             email: data?.email,
             joiningDate: data?.joiningDate,
             companyId: data?.companyId,
             areObj: {
                 id: data?.areObj?.companyId,
                 areCode: data?.areObj?.companyArename,
                 areName: data?.areObj?.companyAredescription,
             },
             mprnumber: data?.mprnumber,
             referenceNo: data?.referenceNo,
             division: data?.division,
             taname: data?.taname,
             taemail: data?.taemail,
             address: data?.address,
             dateOfBirth: data?.dateOfBirth,
             letterType: data?.letterType,
             letterTypeDetails: data?.letterTypeObj,
             letterTypeLabel: data?.letterTypeObj
                 ? data?.letterTypeObj?.letterTypeName
                 : "",
             letterTypeObj: {
                 id: data?.letterTypeObj?.letterTypeId,
                 name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
             },
             noOfSignatories: data?.noOfSignatories,
             noOfSignatoriesObj: {
                 id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                 name:
                     data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
             },
             noOfSignatoriesLabel:
                 data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
             signatoryDetails: data?.signatoryDetails,
             employeeType: data?.employeeType,
             employeeTypeLabel: data?.employeeTypeObj
                 ? data?.employeeTypeObj?.name
                 : "",
             employeeTypeObj: data?.employeeTypeObj,
             jobLocation: data?.jobLocation,
             jobLocationLabel: data?.jobLocationObj
                 ? data?.jobLocationObj?.locationCode +
                 " - " +
                 data?.jobLocationObj?.cityName +
                 " - " +
                 data?.jobLocationObj?.streetName
                 : "",
             jobLocationObj: data?.jobLocationObj
                 ? {
                     id: data?.jobLocationObj?.locationCodeId,
                     name:
                         data?.jobLocationObj?.locationCode +
                         " - " +
                         data?.jobLocationObj?.cityName +
                         " - " +
                         data?.jobLocationObj?.streetName,
                 }
                 : { id: 0, name: "" },
             locationObj: data?.jobLocationObj,
             jobTitle: data?.jobTitle,
             jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
             businessTitle: data?.businessTitle
                 ? data?.businessTitle
                 : data?.jobTitleObj
                     ? data?.jobTitleObj?.name
                     : "",
             jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
             gripLabel: data?.gripObj ? data?.gripObj?.name : "",
             grip: data?.grip,
             businessUnit: data?.businessUnit,
             gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
             gripCode: data?.gripCode,
             staff: data?.staff,
             staffLabel: data?.staffObj ? data?.staffObj?.name : "",
             staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
             compensationGrade: data?.compensationGrade,
             compensationGradeLabel: data?.compensationGradeObj
                 ? data?.compensationGradeObj?.name
                 : "",
             compensationGradeObj: data?.compensationGradeObj
                 ? data?.compensationGradeObj
                 : { id: 0, name: "" },
             compensationGradeProfile: data?.compensationGradeProfile
                 ? data?.compensationGradeProfile
                 : { id: 0, name: "" },
             compensationGradeProfileLabel: data?.compensationGradeProfileObj
                 ? data?.compensationGradeProfileObj?.name
                 : "",
             compensationGradeProfileObj: data?.compensationGradeProfileObj,
             payPlan: data?.payPlan,
             payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
             payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
             performencePayPlan: data?.performencePayPlan,
             performencePayPlanLabel: data?.performencePayPlanObj
                 ? data?.performencePayPlanObj?.name
                 : "",
             performencePayPlanObj: data?.performencePayPlanObj
                 ? data?.performencePayPlanObj
                 : { id: 0, name: "" },
             salaryAmount: data?.salaryAmount,
             targetPay: data?.targetPay,
             performencePay: data?.performencePay,
             joiningBonus: data?.joiningBonus,
             recoveryDuration: data?.recoveryDuration,
             allowancePlanObj:
                 data?.allowancePlanObj?.length > 0
                     ? data?.allowancePlanObj
                     : inputDataToDHL?.allowancePlanObj,
             allowanceAmountObj:
                 data?.allowanceAmountObj?.length > 0
                     ? data?.allowanceAmountObj
                     : inputDataToDHL?.allowanceAmountObj,
             compensationLetterUploadId: data?.compensationLetterUploadId,
             isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
             offerLetterUploadId: data?.offerLetterUploadId,
             isOfferLetteruploaded: data?.isOfferLetteruploaded,
             siemensLogo:
                 data?.companyId === 1 || data.companyId === 2
                     ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                     : "../assets/images/sie-logo-petrol-rgb.png",
             digiLetterWorkflowId: data?.digiLetterWorkflowId,
             isCompensationAccepted: data?.isCompensationAccepted,
             isCompensationRejected: data?.isCompensationRejected,
             digiLetterStatusId: data?.digiLetterStatusId,
             isOfferAccepted: data?.isOfferAccepted,
             isOfferRejected: data?.isOfferRejected,
             isSuperannuation: data?.isSuperannuation,
         });

        let inputUpdatedDHL = {
            ...inputDataToDHL,
            digiLetterDetailsId: data?.digiLetterDetailsId,
            applicantId: data?.applicantIdEncrypted,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            joiningDate: data?.joiningDate,
            companyId: data?.companyId,
            areObj: {
                id: data?.areObj?.companyId,
                areCode: data?.areObj?.companyArename,
                areName: data?.areObj?.companyAredescription,
            },
            mprnumber: data?.mprnumber,
            referenceNo: data?.referenceNo,
            division: data?.division,
            taname: data?.taname,
            taemail: data?.taemail,
            address: data?.address,
            dateOfBirth: data?.dateOfBirth,
            letterType: data?.letterType,
            letterTypeDetails: data?.letterTypeObj,
            letterTypeLabel: data?.letterTypeObj
                ? data?.letterTypeObj?.letterTypeName
                : "",
            letterTypeObj: {
                id: data?.letterTypeObj?.letterTypeId,
                name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
            },
            noOfSignatories: data?.noOfSignatories,
            noOfSignatoriesObj: {
                id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                name:
                    data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            },
            noOfSignatoriesLabel:
                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            signatoryDetails: data?.signatoryDetails,
            employeeType: data?.employeeType,
            employeeTypeLabel: data?.employeeTypeObj
                ? data?.employeeTypeObj?.name
                : "",
            employeeTypeObj: data?.employeeTypeObj,
            jobLocation: data?.jobLocation,
            jobLocationLabel: data?.jobLocationObj
                ? data?.jobLocationObj?.locationCode +
                " - " +
                data?.jobLocationObj?.cityName +
                " - " +
                data?.jobLocationObj?.streetName
                : "",
            jobLocationObj: data?.jobLocationObj
                ? {
                    id: data?.jobLocationObj?.locationCodeId,
                    name:
                        data?.jobLocationObj?.locationCode +
                        " - " +
                        data?.jobLocationObj?.cityName +
                        " - " +
                        data?.jobLocationObj?.streetName,
                }
                : { id: 0, name: "" },
            locationObj: data?.jobLocationObj,
            jobTitle: data?.jobTitle,
            jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
            businessTitle: data?.businessTitle
                ? data?.businessTitle
                : data?.jobTitleObj
                    ? data?.jobTitleObj?.name
                    : "",
            jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
            gripLabel: data?.gripObj ? data?.gripObj?.name : "",
            grip: data?.grip,
            businessUnit: data?.businessUnit,
            gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
            gripCode: data?.gripCode,
            staff: data?.staff,
            staffLabel: data?.staffObj ? data?.staffObj?.name : "",
            staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
            compensationGrade: data?.compensationGrade,
            compensationGradeLabel: data?.compensationGradeObj
                ? data?.compensationGradeObj?.name
                : "",
            compensationGradeObj: data?.compensationGradeObj
                ? data?.compensationGradeObj
                : { id: 0, name: "" },
            compensationGradeProfile: data?.compensationGradeProfile
                ? data?.compensationGradeProfile
                : { id: 0, name: "" },
            compensationGradeProfileLabel: data?.compensationGradeProfileObj
                ? data?.compensationGradeProfileObj?.name
                : "",
            compensationGradeProfileObj: data?.compensationGradeProfileObj,
            payPlan: data?.payPlan,
            payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
            payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
            performencePayPlan: data?.performencePayPlan,
            performencePayPlanLabel: data?.performencePayPlanObj
                ? data?.performencePayPlanObj?.name
                : "",
            performencePayPlanObj: data?.performencePayPlanObj
                ? data?.performencePayPlanObj
                : { id: 0, name: "" },
            salaryAmount: data?.salaryAmount,
            targetPay: data?.targetPay,
            performencePay: data?.performencePay,
            joiningBonus: data?.joiningBonus,
            recoveryDuration: data?.recoveryDuration,
            allowancePlanObj:
                data?.allowancePlanObj?.length > 0
                    ? data?.allowancePlanObj
                    : inputDataToDHL?.allowancePlanObj,
            allowanceAmountObj:
                data?.allowanceAmountObj?.length > 0
                    ? data?.allowanceAmountObj
                    : inputDataToDHL?.allowanceAmountObj,
            compensationLetterUploadId: data?.compensationLetterUploadId,
            isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
            offerLetterUploadId: data?.offerLetterUploadId,
            isOfferLetteruploaded: data?.isOfferLetteruploaded,
            siemensLogo:
                data?.companyId === 1 || data.companyId === 2
                    ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                    : "../assets/images/sie-logo-petrol-rgb.png",
            digiLetterWorkflowId: data?.digiLetterWorkflowId,
            isCompensationAccepted: data?.isCompensationAccepted,
            isCompensationRejected: data?.isCompensationRejected,
            digiLetterStatusId: data?.digiLetterStatusId,
            isOfferAccepted: data?.isOfferAccepted,
            isOfferRejected: data?.isOfferRejected,
            isSuperannuation: data?.isSuperannuation,
        }
        //setInputDataToDHL(inputUpdatedDHL);
        //dispatch(inputDataDHLAction(inputUpdatedDHL));
    }
    function getDigiLetterDetails(encryptedId) {

       handleLoader(true, "Fetching data....");
        const token = props.token;
        var stat = localStorage.getItem("Status");
        // id = encryptedId.substr(22);
        id = encryptedId;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    console.log("Inside getDigiLetterDetails", data);
                   
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterDetailsId: data?.digiLetterDetailsId,
                        applicantId: data?.applicantIdEncrypted,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        joiningDate: data?.joiningDate,
                        companyId: data?.companyId,
                        areObj: {
                            id: data?.areObj?.companyId,
                            areCode: data?.areObj?.companyArename,
                            areName: data?.areObj?.companyAredescription,
                        },
                        mprnumber: data?.mprnumber,
                        referenceNo: data?.referenceNo,
                        division: data?.division,
                        taname: data?.taname,
                        taemail: data?.taemail,
                        address: data?.address,
                        dateOfBirth: data?.dateOfBirth,
                        letterType: data?.letterType,
                        letterTypeDetails: data?.letterTypeObj,
                        letterTypeLabel: data?.letterTypeObj
                            ? data?.letterTypeObj?.letterTypeName
                            : "",
                        letterTypeObj: {
                            id: data?.letterTypeObj?.letterTypeId,
                            name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                        },
                        noOfSignatories: data?.noOfSignatories,
                        noOfSignatoriesObj: {
                            id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                            name:
                                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        },
                        noOfSignatoriesLabel:
                            data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        signatoryDetails: data?.signatoryDetails,
                        employeeType: data?.employeeType,
                        employeeTypeLabel: data?.employeeTypeObj
                            ? data?.employeeTypeObj?.name
                            : "",
                        employeeTypeObj: data?.employeeTypeObj,
                        jobLocation: data?.jobLocation,
                        jobLocationLabel: data?.jobLocationObj
                            ? data?.jobLocationObj?.locationCode +
                            " - " +
                            data?.jobLocationObj?.cityName +
                            " - " +
                            data?.jobLocationObj?.streetName
                            : "",
                        jobLocationObj: data?.jobLocationObj
                            ? {
                                id: data?.jobLocationObj?.locationCodeId,
                                name:
                                    data?.jobLocationObj?.locationCode +
                                    " - " +
                                    data?.jobLocationObj?.cityName +
                                    " - " +
                                    data?.jobLocationObj?.streetName,
                            }
                            : { id: 0, name: "" },
                        locationObj: data?.jobLocationObj,
                        jobTitle: data?.jobTitle,
                        jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                        businessTitle: data?.businessTitle
                            ? data?.businessTitle
                            : data?.jobTitleObj
                                ? data?.jobTitleObj?.name
                                : "",
                        jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                        gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                        grip: data?.grip,
                        businessUnit: data?.businessUnit,
                        gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                        gripCode: data?.gripCode,
                        staff: data?.staff,
                        staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                        staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                        compensationGrade: data?.compensationGrade,
                        compensationGradeLabel: data?.compensationGradeObj
                            ? data?.compensationGradeObj?.name
                            : "",
                        compensationGradeObj: data?.compensationGradeObj
                            ? data?.compensationGradeObj
                            : { id: 0, name: "" },
                        compensationGradeProfile: data?.compensationGradeProfile
                            ? data?.compensationGradeProfile
                            : { id: 0, name: "" },
                        compensationGradeProfileLabel: data?.compensationGradeProfileObj
                            ? data?.compensationGradeProfileObj?.name
                            : "",
                        compensationGradeProfileObj: data?.compensationGradeProfileObj,
                        payPlan: data?.payPlan,
                        payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                        payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                        performencePayPlan: data?.performencePayPlan,
                        performencePayPlanLabel: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj?.name
                            : "",
                        performencePayPlanObj: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj
                            : { id: 0, name: "" },
                        salaryAmount: data?.salaryAmount,
                        targetPay: data?.targetPay,
                        performencePay: data?.performencePay,
                        joiningBonus: data?.joiningBonus,
                        recoveryDuration: data?.recoveryDuration,
                        allowancePlanObj:
                            data?.allowancePlanObj?.length > 0
                                ? data?.allowancePlanObj
                                : inputDataToDHL?.allowancePlanObj,
                        allowanceAmountObj:
                            data?.allowanceAmountObj?.length > 0
                                ? data?.allowanceAmountObj
                                : inputDataToDHL?.allowanceAmountObj,
                        compensationLetterUploadId: data?.compensationLetterUploadId,
                        isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                        offerLetterUploadId: data?.offerLetterUploadId,
                        isOfferLetteruploaded: data?.isOfferLetteruploaded,
                        siemensLogo:
                            data?.companyId === 1 || data.companyId === 2
                                ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                                : "../assets/images/sie-logo-petrol-rgb.png",
                        digiLetterWorkflowId: data?.digiLetterWorkflowId,
                        isCompensationAccepted: data?.isCompensationAccepted,
                        isCompensationRejected: data?.isCompensationRejected,
                        digiLetterStatusId: data?.digiLetterStatusId,
                        isOfferAccepted: data?.isOfferAccepted,
                        isOfferRejected: data?.isOfferRejected,
                        isSuperannuation: data?.isSuperannuation,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                   // dispatch(inputDataDHLAction(inputUpdatedDHL));

                    setDigiLetterDataState(data);
                    handleLoader(false, "");
                    //alert(stat);
                    if (stat == "Success") {
                        handleDialogOpenAlert(
                            "Success",
                            "Letter Uploaded Successfully",
                            "Letter Uploaded Successfully"
                        );
                        //alert("Success");
                        localStorage.removeItem("Status");
                    }
                    else if (stat == "Failed") {
                        handleDialogOpenAlert(
                            "Failure",
                            "Letter Uploaded Failed",
                            "Letter Upload Failed"
                        );
                    }
                    console.log("Inside 1 getDigiLetterDetails", data);
                    handleLoader(false, "");
                }
                console.log("Inside 2 getDigiLetterDetails", result);
                handleLoader(false, "");
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
            });
    }
    useEffect(() => {
        console.log("Candidate Details DHL useeffect", storedInputDataDHL);
        getDigiLetterDetails(encryptedId);
    }, [props.encryptedId]);
    return (
        <>
            <Grid container spacing={1} className="d-flex jc-space-around fd-row">
                <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
                    <h5 className="clr-dark-blue">Candidate Details</h5>
                </Grid>

                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="firstName"
                        label="Candidate First Name"
                        variant="filled"
                        fullWidth
                        name="firstName"
                        value={inputDataToDHL.firstName}
                        required
                        disabled
                        error={
                           inputDataToDHL.firstNameErrorText &&
                           inputDataToDHL.firstNameErrorText.length > 0
                        }
                        helperText={inputDataToDHL.firstNameErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="lastName"
                        label="Candidate Last Name"
                        variant="filled"
                        fullWidth
                        name="lastName"
                        value={inputDataToDHL.lastName}
                        required
                        disabled
                        error={
                            inputDataToDHL.lastNameErrorText &&
                            inputDataToDHL.lastNameErrorText.length > 0
                        }
                        helperText={inputDataToDHL.lastNameErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="emai"
                        label="Candidate Email"
                        variant="filled"
                        fullWidth
                        name="email"
                        value={inputDataToDHL.email}
                        required
                        disabled
                        error={
                            inputDataToDHL.emailErrorText &&
                            inputDataToDHL.emailErrorText.length > 0
                        }
                        helperText={inputDataToDHL.emailErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={2} className="d-flex Width-100">
                    <FormDatePicker
                        placeholder="Date Of Joining"
                        label="Date Of Joining"
                        value={inputDataToDHL.joiningDate}
                        disabled={true}
                        // disableBefore={"01-Sep-2021"}
                        // isSpecificDateDisable={
                        //   props.roleId && props.roleId == 1 ? false : true
                        // }
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="mprnumber"
                        label="Folder Id"
                        variant="filled"
                        fullWidth
                        name="mprnumber"
                        value={inputDataToDHL.mprnumber}
                        required
                        disabled
                        // error={
                        //   inputDataToDHL.lastNameErrorText &&
                        //   inputDataToDHL.lastNameErrorText.length > 0
                        // }
                        // helperText={inputDataToDHL.lastNameErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="referenceNo"
                        label="Reference Id"
                        variant="filled"
                        fullWidth
                        name="referenceNo"
                        value={inputDataToDHL.referenceNo}
                        required
                        disabled
                        // error={
                        //   inputDataToDHL.lastNameErrorText &&
                        //   inputDataToDHL.lastNameErrorText.length > 0
                        // }
                        // helperText={inputDataToDHL.lastNameErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="taName"
                        label="TA Name"
                        variant="filled"
                        fullWidth
                        name="taName"
                        value={inputDataToDHL.taname}
                        required
                        disabled
                        error={
                            inputDataToDHL.taNameErrorText &&
                            inputDataToDHL.taNameErrorText.length > 0
                        }
                        helperText={inputDataToDHL.taNameErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="taEmail"
                        label="TA Email"
                        variant="filled"
                        fullWidth
                        name="taEmail"
                        value={inputDataToDHL.taemail}
                        required
                        disabled
                        error={
                            inputDataToDHL.taEmailErrorText &&
                            inputDataToDHL.taEmailErrorText.length > 0
                        }
                        helperText={inputDataToDHL.taEmailErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={5} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="areName"
                        label="Company Name"
                        variant="filled"
                        fullWidth
                        name="areName"
                        value={inputDataToDHL.areObj.areName}
                        required
                        disabled
                        error={
                            inputDataToDHL.areCodeErrorText &&
                            inputDataToDHL.areCodeErrorText.length > 0
                        }
                        helperText={inputDataToDHL.areCodeErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={2} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="areCode"
                        label="ARE Code"
                        variant="filled"
                        fullWidth
                        name="areCode"
                        value={inputDataToDHL.areObj.areCode}
                        required
                        disabled
                        error={
                            inputDataToDHL.areCodeErrorText &&
                            inputDataToDHL.areCodeErrorText.length > 0
                        }
                        helperText={inputDataToDHL.areCodeErrorText}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="division"
                        label="Division"
                        variant="filled"
                        fullWidth
                        name="division"
                        value={inputDataToDHL.division}
                        required
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={2} className="d-flex Width-100">
                    <FormDatePicker
                        placeholder="Enter Date Of Birth"
                        label="Date Of Birth"
                        value={inputDataToDHL.dateOfBirth}
                        onChange={dateChangeEvent}
                        disablefuturedate={true}
                        disablepastdate={false}
                        disableAfter={getDateBefore15YearsFromNow()}
                        // disableBefore={"01-Sep-2021"}
                        // isSpecificDateDisable={
                        //   props.roleId && props.roleId == 1 ? false : true
                        // }
                        required={true}
                        helperText={props.errorTexts.dob}
                    />
                </Grid>
                <Grid item xs={12} sm={9} className="d-flex Width-100">
                    <TextField
                        className="mt-1 lightInputFields"
                        id="address"
                        label="Candidate Address"
                        variant="filled"
                        fullWidth
                        multiline
                        maxRows={4}
                        placeholder="Flat/Aprt. No., Street/Colony Name, Vill/Town/City, PIN Code needed"
                        name="address"
                        value={inputDataToDHL.address}
                        onChange={(e) => {
                            
                            setInputDataToDHL({
                                ...inputDataToDHL,
                                address: e.target.value,
                            })
                            let inputUpdatedDHL = {
                                ...inputDataToDHL,
                                address: e.target.value,
                            };
                            dispatch(inputDataDHLAction(inputUpdatedDHL));
                            }
                        }
                        InputLabelProps={{ shrink: true }}
                        required
                        error={
                            props.errorTexts.address && props.errorTexts.address.length > 0
                        }
                        helperText={props.errorTexts.address}
                    />
                </Grid>
            </Grid>
            {alertDialogComponent}
            {confirmDialogComponenet}
            {loaderProps?.isEnable ? (
                <Loader loaderText={loaderProps?.loaderText} />
            ) : (
                <></>
            )}
        </>
    );
}
