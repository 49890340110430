import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setDate } from "date-fns/esm";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },
    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function MedicalData(props) {
  var id;
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [attrDisabled, setAttrDisabled] = useState(true);
  const [employeeMedicalId, setEmployeeMedicalId] = useState("");
  const [dateOfExamination, setDateOfExamination] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [bloodGroupObj, setbloodGroupObj] = useState("");
  const [bloodGroupText, setbloodGroupText] = useState("");
  const [rhFactor, setRHFactor] = useState("");
  const [rhFactorObj, setrhFactorObj] = useState("");
  const [rhFactorText, setrhFactorText] = useState("");
  const [disabilityYesNo, setDisabilityYesNo] = useState(2);
  const [disabilityYesNoObj, setDisabilityYesNoObj] = useState("");
  const [disabilityYesNoText, setDisabilityYesNoText] = useState("");
  const [disability, setDisability] = useState("");
  const [disabilityObj, setDisabilityObj] = useState("");
  const [disabilityText, setDisabilityText] = useState("");
  const [disabilityGrade, setDisabilityGrade] = useState("");
  const [disabilityGradeObj, setDisabilityGradeObj] = useState("");
  const [disabilityGradeText, setDisabilityGradeText] = useState("");
  const [disableSince, setDisableSince] = useState(null);

  const [disableDisability, setdisableDisability] = useState(true);
  const [requireDisability, setRequireDisability] = useState(false);
  const [disableDisabilityGrade, setdisableDisabilityGrade] = useState(true);
  const [requireDisabilityGrade, setRequireDisabilityGrade] = useState(false);
  const [disableDisableSince, setdisableDisableSince] = useState(true);
  const [requireDisabledSince, setRequireDisabledSince] = useState(false);

  //Error State and Message
  const [errorDOE, setErrorDOE] = useState(false);
  const [errorTextDOE, setErrorTextDOE] = useState("");
  const [errorBloodGroup, setErrorBloodGroup] = useState(false);
  const [errorTextBloodGroup, setErrorTextBloodGroup] = useState("");
  const [errorRh, setErrorRh] = useState(false);
  const [errorTextRh, setErrorTextRh] = useState("");
  const [errorDisabilityYN, setErrorDisabilityYN] = useState(false);
  const [errorTextDisabilityYN, setErrorTextDisabilityYN] = useState("");
  const [errorDisability, setErrorDisability] = useState(false);
  const [errorTextDisability, setErrorTextDisability] = useState("");
  const [errorDisabilityGrade, setErrorDisabilityGrade] = useState(false);
  const [errorTextDisabilityGrade, setErrorTextDisabilityGrade] = useState("");
  const [errorDisableSince, setErrorDisableSince] = useState(false);
  const [errorTextDisableSince, setErrorTexDisableSince] = useState("");

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  function dateChangeEvent(dateValue, label) {
    if (label === "Date Of Examination") {
      setDateOfExamination(dateValue);
    } else if (label === "Disabled Since") {
      setDisableSince(dateValue);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  //const handleChangeBloodGroup = (event) => {
  //    setBloodGroup(event.target.value);
  //};

  const handleChangeBloodGroup = (event, newValue) => {
    setbloodGroupObj(newValue);
    setBloodGroup(newValue ? newValue.id : 0);
  };
  const handleChangeBloodGroupInput = (event, newInputValue) => {
    setbloodGroupText(newInputValue);
  };
  const handleChangeRHFactor = (event, newValue) => {
    setrhFactorObj(newValue);
    setRHFactor(newValue ? newValue.id : 0);
  };
  const handleChangeRHFactorInput = (event, newInputValue) => {
    setrhFactorText(newInputValue);
  };
  const handleChangeDisabilityYesNo = (event, newValue) => {
    setDisabilityYesNoObj(newValue);
    setDisabilityYesNo(newValue ? newValue.id : 0);
    setDisability("");
    setDisabilityObj("");
    setDisabilityText("");
    setDisabilityGrade("");
    setDisabilityGradeObj("");
    setDisabilityGradeText("");
    setDisableSince(null);
    if ((newValue ? newValue.id : 0) == 1) {
      setdisableDisability(false);
      setdisableDisabilityGrade(false);
      setdisableDisableSince(false);
      setRequireDisability(true);
      setRequireDisabilityGrade(true);
      setRequireDisabledSince(true);
    } else {
      setdisableDisability(true);
      setdisableDisabilityGrade(true);
      setdisableDisableSince(true);
      setRequireDisability(false);
      setRequireDisabilityGrade(false);
      setRequireDisabledSince(false);
    }
  };
  const handleChangeDisabilityYesNoInput = (event, newInputValue) => {
    setDisabilityYesNoText(newInputValue);
  };
  const handleChangeDisability = (event, newValue) => {
    setDisabilityObj(newValue);
    setDisability(newValue ? newValue.id : 0);
  };
  const handleChangeDisabilityInput = (event, newInputValue) => {
    setDisabilityText(newInputValue);
  };
  const handleChangeDisabilityGrade = (event, newValue) => {
    setDisabilityGradeObj(newValue);
    setDisabilityGrade(newValue ? newValue.id : 0);
  };
  const handleChangeDisabilityGradeInput = (event, newInputValue) => {
    setDisabilityGradeText(newInputValue);
  };
  var formData = {
    EmployeeMedicalId: employeeMedicalId,
    ApplicantId: props.id,
    BloodGroupId: bloodGroup,
    DateOfExamination: dateOfExamination,
    RhFactorId: rhFactor,
    DisabilityYesNoId: disabilityYesNo,
    DisabilityId: disability,
    DisabilityGradeId: disabilityGrade,
    DisabledSince: disableSince,
    UserId: props.userId,
  };
  function clearStates() {
    setDateOfExamination(null);
    setBloodGroup("");
    setbloodGroupObj("");
    setbloodGroupText("");
    setRHFactor("");
    setrhFactorObj("");
    setrhFactorText("");
    setDisabilityYesNo("");
    setDisability("");
    setDisabilityObj("");
    setDisabilityText("");
    setDisabilityGrade("");
    setDisabilityGradeObj("");
    setDisabilityGradeText("");
    setDisableSince(null);
    setRequireDisability(false);
    setdisableDisability(true);
    setRequireDisabilityGrade(false);
    setdisableDisabilityGrade(true);
    setRequireDisabledSince(false);
    setdisableDisableSince(true);
  }

  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false,
      e = false,
      f = false,
      g = false;
    //if (data.DateOfExamination == null || data.DateOfExamination == '') {
    //    setErrorDOE(true);
    //    setErrorTextDOE("Mandatory field");
    //    a = true;
    //}
    //else {
    //    setErrorDOE(false);
    //    setErrorTextDOE("");
    //    a = false;
    //}
    if (data.BloodGroupId == null || data.BloodGroupId == "") {
      setErrorBloodGroup(true);
      setErrorTextBloodGroup("Mandatory field");
      b = true;
    } else {
      setErrorBloodGroup(false);
      setErrorTextBloodGroup("");
      b = false;
    }
    //if (data.RhFactorId == null || data.RhFactorId == '') {
    //    setErrorRh(true);
    //    setErrorTextRh("Mandatory field");
    //    c = true;
    //}
    //else {
    //    setErrorRh(false);
    //    setErrorTextRh("");
    //    c = false;
    //}
    //if (data.DisabilityYesNoId == null || data.DisabilityYesNoId == '') {
    //    setErrorDisabilityYN(true);
    //    setErrorTextDisabilityYN("Mandatory field");
    //    d = true;

    //    setErrorDisability(false);
    //    setErrorTextDisability("");
    //    setErrorDisabilityGrade(false);
    //    setErrorTextDisabilityGrade("");
    //    setErrorDisableSince(false);
    //    setErrorTexDisableSince("");
    //    e = false; f = false; g = false;
    //}
    //else {
    //    setErrorDisabilityYN(false);
    //    setErrorTextDisabilityYN("");
    //    d = false;
    //    if (data.DisabilityYesNoId == 1) {
    //        if (data.DisabilityId == null || data.DisabilityId == '') {
    //            setErrorDisability(true);
    //            setErrorTextDisability("Mandatory Field");
    //            e = true;
    //        }
    //        else {
    //            setErrorDisability(false);
    //            setErrorTextDisability("");
    //            e = false;
    //        }
    //        if (data.DisabilityGradeId == null || data.DisabilityGradeId == '') {
    //            setErrorDisabilityGrade(true);
    //            setErrorTextDisabilityGrade("Mandatory Field");
    //            f = true;
    //        }
    //        else {
    //            setErrorDisabilityGrade(false);
    //            setErrorTextDisabilityGrade("");
    //            f = false;
    //        }
    //        if (data.DisabledSince == null || data.DisabledSince == '') {
    //            setErrorDisableSince(true);
    //            setErrorTexDisableSince("Mandatory Field");
    //            g = true;
    //        }
    //        else {
    //            setErrorDisableSince(false);
    //            setErrorTexDisableSince("");
    //            g = false;
    //        }
    //    }
    //    else {
    //        setErrorDisability(false);
    //        setErrorTextDisability("");
    //        setErrorDisabilityGrade(false);
    //        setErrorTextDisabilityGrade("");
    //        setErrorDisableSince(false);
    //        setErrorTexDisableSince("");
    //        e = false; f = false; g = false;
    //    }
    //}

    if (a || b || c || d || e || f || g) {
      return false;
    } else {
      return true;
    }
  }

  const submitHandler = () => {
    setIsLoader(true);
    formData.ApplicantId = props.id;
    formData.BloodGroupId = bloodGroup;
    formData.DateOfExamination = dateOfExamination;
    formData.EmployeeMedicalId = employeeMedicalId;
    formData.RhFactorId = rhFactor;
    formData.UserId = props.userId;
    formData.DisabilityYesNoId = disabilityYesNo;
    formData.DisabilityId = disability;
    formData.DisabilityGradeId = disabilityGrade;
    formData.DisabledSince = disableSince;

    if (ValidateFormFields(formData)) {
      if (
        employeeMedicalId != null &&
        employeeMedicalId != "" &&
        employeeMedicalId > 0
      ) {
        UpdateMedicalData(formData);
      } else {
        SaveMedicalData(formData);
        //console.log(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  function SaveMedicalData(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("OnBoardingForm/SaveMedicalData/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("bg-success clr-white");
          setOpenType("Success");
          //alert(data.responseMsg);
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("bg-Error clr-white");
          setOpenType("Error");
          //alert(data.responseMsg);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  function UpdateMedicalData(formData) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("OnBoardingForm/UpdateMedicalData/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("bg-success clr-white");
          setOpenType("Success");
          // alert(data.responseMsg);
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("bg-error clr-white");
          setOpenType("Error");
          //alert(data.responseMsg);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  const cancelHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("bg-alert clr-white");
    setOpenType("Alert");

    //var x = window.confirm('Do you want to clear all the above fields?');
    //if (x) {
    //    clearStates();
    //}
  };

  function SetStateAsPerFetched(data) {
    setDateOfExamination(data.dateOfExamination);
    setBloodGroup(data.bloodGroupId);
    if (data.bloodGroup[0].id > 0) {
      setbloodGroupObj(data.bloodGroup[0]);
      setbloodGroupText(data.bloodGroup[0].name);
    }
    setRHFactor(data.rhFactorId);
    if (data.rhFactor[0].id > 0) {
      setrhFactorObj(data.rhFactor[0]);
      setrhFactorText(data.rhFactor[0].name);
    }
    setEmployeeMedicalId(data.employeeMedicalId);
    setDisabilityYesNo(data.disabilityYesNoId);
    if (data.disabilityYesNoObj[0].id > 0) {
      setDisabilityYesNoObj(data.disabilityYesNoObj[0]);
      setDisabilityYesNoText(data.disabilityYesNoObj[0].name);
    }
    setDisability(data.disabilityId);
    if (data.disibilityObj[0].id > 0) {
      setDisabilityObj(data.disibilityObj[0]);
      setDisabilityText(data.disibilityObj[0].name);
    }
    setDisabilityGrade(data.disabilityGradeId);
    if (data.disabilityGradeObj[0].id > 0) {
      setDisabilityGradeObj(data.disabilityGradeObj[0]);
      setDisabilityGradeText(data.disabilityGradeObj[0].name);
    }
    setDisableSince(data.disabledSince);
    if (data.disabilityYesNoId == 1) {
      setdisableDisability(false);
      setdisableDisabilityGrade(false);
      setdisableDisableSince(false);
      setRequireDisability(true);
      setRequireDisabilityGrade(true);
      setRequireDisabledSince(true);
    } else {
      setdisableDisability(true);
      setdisableDisabilityGrade(true);
      setdisableDisableSince(true);
      setRequireDisability(false);
      setRequireDisabilityGrade(false);
      setRequireDisabledSince(false);
    }
    setIsLoader(false);
  }

  //Get PersonalData On Page Load
  function getMedicalDataOnLoad(applicantId) {
    setIsLoader(true);
    const token = props.token;
    id = applicantId;
    fetch("OnBoardingForm/GetMedicalData/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //fetch Blood Group
  const [itemsBloodGroup, setItemsBloodGroup] = useState([]);
  async function getBloodGroup() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetAllBloodGroup", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsBloodGroup(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsBloodGroup(jsonItems);
  }

  //fetch Rh Factor
  const [itemsRhFactor, setItemsRhFactor] = useState([]);
  async function getRhFactor() {
    const response = await fetch("OnBoardingForm/GetAllRhFactor");
    const jsonItems = await response.json();
    //setItemsRhFactor(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsRhFactor(jsonItems);
  }

  //fetch YesNo Options
  const [itemsYesNo, setItemsYesNo] = useState([]);
  async function getYesNoOptions() {
    const response = await fetch("OnBoardingForm/GetYesNoOptions");
    const jsonItems = await response.json();
    //setItemsRhFactor(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsYesNo(jsonItems);
  }

  //fetch Disabilities
  const [itemsDisability, setItemsDisability] = useState([]);
  async function getDisability() {
    const response = await fetch("OnBoardingForm/GetAllDisabilities");
    const jsonItems = await response.json();
    //setItemsRhFactor(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsDisability(jsonItems);
  }

  //fetch Rh Factor
  const [itemsDisabilityGrades, setItemsDisabilityGrades] = useState([]);
  async function getDisabilityGrades() {
    const response = await fetch("OnBoardingForm/GetAllDisabilityGrades");
    const jsonItems = await response.json();
    //setItemsRhFactor(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsDisabilityGrades(jsonItems);
  }

  //Change Button Text
  function getButtonTextSave(employeeMedicalId) {
    employeeMedicalId != null && employeeMedicalId > 0
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getMedicalDataOnLoad(props.id);
      }
    }
    if (props.disableValue) {
      setdisableDisability(true);
      setdisableDisabilityGrade(true);
      setdisableDisableSince(true);
    }
  }, [props.id, employeeMedicalId, props.disableValue, props.token]);

  useEffect(() => {
    if (props.token) {
      getBloodGroup();
      //getRhFactor();
      //getYesNoOptions();
      //getDisability();
      //getDisabilityGrades();
    }
    getButtonTextSave(employeeMedicalId);
  }, [employeeMedicalId, , props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Date Of Examination"
                  label="Date Of Examination"
                  value={dateOfExamination}
                  onChange={dateChangeEvent}
                  disablefuturedate={attrDisabled}
                  disabled={props.disableValue}
                  //required={True}
                  error={errorDOE}
                  helperText={errorTextDOE}
                />
              </Grid>
              {/*<Grid item xs={12} sm={4} className="d-flex jc-center mt-1 Width-100">
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Blood Group</InputLabel>
                                    <Select className="lightInputFields"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bloodGroup}
                                        onChange={handleChangeBloodGroup}
                                        disabled={props.disableValue}
                                    >
                                        {itemsBloodGroup.map(item => (
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>*/}
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex jc-center mt-1 Width-100"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="demo-simple-select"
                  value={bloodGroupObj}
                  inputValue={bloodGroupText}
                  options={itemsBloodGroup}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeBloodGroup}
                  onInputChange={handleChangeBloodGroupInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Blood Group"
                      variant="filled"
                      required
                      error={errorBloodGroup}
                      helperText={errorTextBloodGroup}
                    />
                  )}
                  disabled={props.disableValue}
                />
              </Grid>

              {/* <Grid item xs={12} sm={4} className="d-flex jc-center mt-1 Width-100">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="demo-simple-select"
                                    value={rhFactorObj}
                                    inputValue={rhFactorText}
                                    options={itemsRhFactor}
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    onChange={handleChangeRHFactor}
                                    onInputChange={handleChangeRHFactorInput}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Rh Factor"
                                                variant="filled"
                                                required
                                                error={errorRh}
                                                helperText={errorTextRh}
                                            />
                                    }
                                    disabled={props.disableValue}
                                />
                            </Grid> */}

              {/*<Grid item xs={12} sm={3} className="d-flex jc-center mt-1 Width-100">*/}
              {/*    <Autocomplete*/}
              {/*        className="lightInputFields hover-lightblue"*/}
              {/*        id="demo-simple-select"*/}
              {/*        value={disabilityYesNoObj}*/}
              {/*        inputValue={disabilityYesNoText}*/}
              {/*        options={itemsYesNo}*/}
              {/*        getOptionLabel={option => option.name ? option.name : ""}*/}
              {/*        onChange={handleChangeDisabilityYesNo}*/}
              {/*        onInputChange={handleChangeDisabilityYesNoInput}*/}
              {/*        renderInput={*/}
              {/*            (params) =>*/}
              {/*                <TextField {...params}*/}
              {/*                    label="Are You Disable?"*/}
              {/*                    variant="filled"*/}
              {/*                    required*/}
              {/*                    error={errorDisabilityYN}*/}
              {/*                    helperText={errorTextDisabilityYN}*/}
              {/*                />*/}
              {/*        }*/}
              {/*        disabled={props.disableValue}*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} sm={3} className="d-flex jc-center mt-1 Width-100">*/}
              {/*    <Autocomplete*/}
              {/*        className="lightInputFields hover-lightblue"*/}
              {/*        id="demo-simple-select"*/}
              {/*        value={disabilityObj}*/}
              {/*        inputValue={disabilityText}*/}
              {/*        options={itemsDisability}*/}
              {/*        getOptionLabel={option => option.name ? option.name : ""}*/}
              {/*        onChange={handleChangeDisability}*/}
              {/*        onInputChange={handleChangeDisabilityInput}*/}
              {/*        renderInput={*/}
              {/*            (params) =>*/}
              {/*                <TextField {...params}*/}
              {/*                    label="Disability"*/}
              {/*                    variant="filled"*/}
              {/*                    required={requireDisability}*/}
              {/*                    error={errorDisability}*/}
              {/*                    helperText={errorTextDisability}*/}
              {/*                />*/}
              {/*        }*/}
              {/*        disabled={disableDisability}*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} sm={3} className="d-flex jc-center mt-1 Width-100">*/}
              {/*    <Autocomplete*/}
              {/*        className="lightInputFields hover-lightblue"*/}
              {/*        id="demo-simple-select"*/}
              {/*        value={disabilityGradeObj}*/}
              {/*        inputValue={disabilityGradeText}*/}
              {/*        options={itemsDisabilityGrades}*/}
              {/*        getOptionLabel={option => option.name ? option.name : ""}*/}
              {/*        onChange={handleChangeDisabilityGrade}*/}
              {/*        onInputChange={handleChangeDisabilityGradeInput}*/}
              {/*        renderInput={*/}
              {/*            (params) =>*/}
              {/*                <TextField {...params}*/}
              {/*                    label="Disability Grade"*/}
              {/*                    variant="filled"*/}
              {/*                    required={requireDisabilityGrade}*/}
              {/*                    error={errorDisabilityGrade}*/}
              {/*                    helperText={errorTextDisabilityGrade}*/}
              {/*                />*/}
              {/*        }*/}
              {/*        disabled={disableDisabilityGrade}*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} sm={3} className="d-flex jc-center Width-100">*/}
              {/*    <FormDatePicker*/}
              {/*        placeholder="Disabled Since"*/}
              {/*        label="Disabled Since"*/}
              {/*        value={disableSince}*/}
              {/*        onChange={dateChangeEvent}*/}
              {/*        disablefuturedate={attrDisabled}*/}
              {/*        disabled={disableDisableSince}*/}
              {/*        required={requireDisabledSince}*/}
              {/*        error={errorDisableSince}*/}
              {/*        helperText={errorTextDisableSince}*/}
              {/*    />*/}
              {/*</Grid>*/}
            </Grid>
            <div className="d-flex jc-center">
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2"
                disabled={props.disableValue}
                onClick={cancelHandler}
              >
                <span>Clear</span>
              </Button>
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2 ml-2"
                disabled={props.disableValue}
                onClick={submitHandler}
              >
                <span>{buttonTextSave}</span>
              </Button>
            </div>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
