import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        minHeight: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function CompensationDetails(props) {
  var id;

  const [compensationDetailsId, setCompensationDetailsId] = useState("");
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [compensationGrade, setCompensationGrade] = useState("");
  const [errorCompensationGrade, setErrorcompensationGrade] = useState(false);
  const [errorTextcompensationGrade, setErrorTextcompensationGrade] =
    useState("");
  const [compensationGradeObj, setCompensationGradeObj] = useState("");
  const [compensationGradeText, setCompensationGradeText] = useState("");
  const [compensationGradeProfile, setCompensationGradeProfile] = useState("");
  const [errorCompensationGradeProfile, setErrorcompensationGradeProfile] =
    useState(false);
  const [
    errorTextcompensationGradeProfile,
    setErrorTextcompensationGradeProfile,
  ] = useState("");
  const [compensationGradeProfileObj, setCompensationGradeProfileObj] =
    useState("");
  const [compensationGradeProfileText, setCompensationGradeProfileText] =
    useState("");
  const [payPlan, setPayPlan] = useState("");
  const [errorPayPlan, setErrorpayPlan] = useState(false);
  const [errorTextpayPlan, setErrorTextpayPlan] = useState("");
  const [payPlanObj, setpayPlanObj] = useState("");
  const [payPlanText, setpayPlanText] = useState("");
  const [salaryAmount, setSalaryAmount] = useState(null);
  const [errorsalaryAmount, setErrorsalaryAmount] = useState(false);
  const [errorTextsalaryAmount, setErrorTextsalaryAmount] = useState("");
  const [allowancePlan, setAllowancePlan] = useState("");
  const [allowancePlanObj, setallowancePlanObj] = useState([]);
  const [allowancePlanText, setallowancePlanText] = useState([]);
  const [allowancePlanMultiSelect, setallowancePlanMultiSelect] = useState([]);
  const [allowanceAmount, setAllowanceAmount] = useState("");
  let [allowanceAmountMultiSelect, setallowanceAmountMultiSelect] = useState(
    []
  );
  const [errorallowanceAmount, setErrorallowanceAmount] = useState(false);
  const [errorTextallowanceAmount, setErrorTextallowanceAmount] = useState("");
  const [bonusPlan, setBonusPlan] = useState("");
  const [bonusPlanObj, setbonusPlanObj] = useState("");
  const [bonusPlanText, setbonusPlanText] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");
  const [bonusPercentage, setBonusPercentage] = useState("");
  const [targetPay, setTargetPay] = useState("");

  let empTypes = [5, 6, 8];

  const [value, setValue] = useState("");

  //Alerts
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");
  const [confirmationClause, setConfirmationClause] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    if (confirmationClause === "ConfirmAnnual") {
      setOpenConfirm(false);
      submitHandler();
    } else {
      clearStates();
      setOpenConfirm(false);
    }
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  function clearStates() {
    setCompensationGrade("");
    setCompensationGradeObj("");
    setCompensationGradeText("");
    setCompensationGradeProfile("");
    setCompensationGradeProfileObj("");
    setCompensationGradeProfileText("");
    setPayPlan("");
    setpayPlanObj("");
    setpayPlanText("");
    setSalaryAmount("");
    setAllowancePlan("");
    setallowancePlanObj([]);
    setallowancePlanText([]);
    setallowancePlanMultiSelect([]);
    setallowanceAmountMultiSelect([]);
    setAllowanceAmount("");
    setBonusPlan("");
    setbonusPlanObj("");
    setbonusPlanText("");
    setBonusAmount("");
    setBonusPercentage("");
  }

  const handleChangeCompensationGrade = (event, newValue) => {
    setCompensationGradeObj(newValue);
    setCompensationGrade(newValue ? newValue.id : 0);
    setCompensationGradeProfile("");
    setCompensationGradeProfileObj("");
    setCompensationGradeProfileText("");
    getCompensationGradeProfile(newValue ? newValue.id : 0);
  };
  const handleChangeCompensationGradeInput = (event, newInputValue) => {
    setCompensationGradeText(newInputValue);
  };

  const handleChangeCompensationGradeProfile = (event, newValue) => {
    setCompensationGradeProfile("");
    setCompensationGradeProfileObj("");
    setCompensationGradeProfileText("");
    setCompensationGradeProfileObj(newValue);
    setCompensationGradeProfile(newValue ? newValue.id : 0);
  };
  const handleChangeCompensationGradeProfileInput = (event, newInputValue) => {
    setCompensationGradeProfileText(newInputValue);
  };

  const handleChangePayPlan = (event, newValue) => {
    setpayPlanObj(newValue);
    setPayPlan(newValue ? newValue.id : 0);
  };
  const handleChangePayPlanInput = (event, newInputValue) => {
    setpayPlanText(newInputValue);
  };

  function AddAllowanceAmountField(newValue) {
    setallowancePlanMultiSelect(newValue);

    if (newValue.length > 0) {
      for (var i = 0; i < allowanceAmountMultiSelect.length; i++) {
        let objFindAllowanceAmount = newValue.find(
          (x) => x.id == allowanceAmountMultiSelect[i].id
        );
        if (!objFindAllowanceAmount) {
          allowanceAmountMultiSelect.splice(i, 1);

          //Target Pay
          var sum = 0;
          if (newValue.length > 0) {
            for (var i = 0; i < newValue.length; i++) {
              if (newValue[i].value) {
                if (
                  (newValue[i].id >= 66 && newValue[i].id <= 83) ||
                  (newValue[i].id >= 92 && newValue[i].id <= 97)
                ) {
                  sum = sum + Number(newValue[i].value);
                } else {
                  sum = sum + Number(newValue[i].value) * 12;
                }
              }
            }
          }
          var target =
            Number(salaryAmount) * 12 + Number(bonusAmount) + Number(sum);
          setTargetPay(target);
        }
      }
    } else {
      //Target Pay
      var sum = 0;
      var target =
        Number(salaryAmount) * 12 + Number(bonusAmount) + Number(sum);
      setTargetPay(target);
    }
  }

  //useEffect(() => {
  //    console.log("abc:", allowanceAmountMultiSelect, allowancePlanMultiSelect);
  //}, [allowanceAmountMultiSelect], [allowancePlanMultiSelect])

  function handleChangeAllowanceAmount(event, newValue) {
    var amountLabel = event.target.name;
    var amountValue = event.target.value;

    if (allowanceAmountMultiSelect.length > 0) {
      let obj = allowanceAmountMultiSelect.find((x) => x.id == amountLabel);
      if (obj) {
        obj.name = amountValue;
        obj.value = amountValue;
        setallowanceAmountMultiSelect([...allowanceAmountMultiSelect]);

        let objNew = allowancePlanMultiSelect.find((x) => x.id == amountLabel);
        if (objNew) {
          objNew.value = amountValue;
          setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
        }
      } else {
        allowanceAmountMultiSelect.push({
          id: amountLabel,
          name: amountValue,
          value: amountValue,
        });
        setallowanceAmountMultiSelect([...allowanceAmountMultiSelect]);
        setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
      }
    } else {
      allowanceAmountMultiSelect.push({
        id: amountLabel,
        name: amountValue,
        value: amountValue,
      });
      setallowanceAmountMultiSelect([...allowanceAmountMultiSelect]);
      setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
    }

    if (allowancePlanMultiSelect.length > 0) {
      let objNew = allowancePlanMultiSelect.find((x) => x.id == amountLabel);
      if (objNew) {
        objNew.value = amountValue;
        setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
      } else {
        allowancePlanMultiSelect.push({ id: amountLabel, value: amountValue });
        setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
      }
    } else {
      allowancePlanMultiSelect.push({ id: amountLabel, value: amountValue });
      setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
    }

    //Target Pay
    var sum = 0;
    if (allowancePlanMultiSelect.length > 0) {
      for (var i = 0; i < allowancePlanMultiSelect.length; i++) {
        if (allowancePlanMultiSelect[i].value) {
          if (
            (allowancePlanMultiSelect[i].id >= 66 &&
              allowancePlanMultiSelect[i].id <= 83) ||
            (allowancePlanMultiSelect[i].id >= 92 &&
              allowancePlanMultiSelect[i].id <= 97)
          ) {
            sum = sum + Number(allowancePlanMultiSelect[i].value);
          } else {
            sum = sum + Number(allowancePlanMultiSelect[i].value) * 12;
          }
        }
      }
    }
    var target = Number(salaryAmount) * 12 + Number(bonusAmount) + Number(sum);
    setTargetPay(target);
  }

  function handleChangeAllowanceAmountBlur(event, newValue) {
    var amountLabel = event.target.name;
    var amountValue = event.target.value;

    if (allowanceAmountMultiSelect.length > 0) {
      let obj = allowanceAmountMultiSelect.find((x) => x.id == amountLabel);
      if (obj) {
        obj.name = amountValue;
        obj.value = amountValue;
        setallowanceAmountMultiSelect([...allowanceAmountMultiSelect]);
        //setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
      } else {
        allowanceAmountMultiSelect.push({
          id: amountLabel,
          name: amountValue,
          value: amountValue,
        });
        setallowanceAmountMultiSelect([...allowanceAmountMultiSelect]);
        //setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
      }
    } else {
      allowanceAmountMultiSelect.push({
        id: amountLabel,
        name: amountValue,
        value: amountValue,
      });
      setallowanceAmountMultiSelect([...allowanceAmountMultiSelect]);
      //setallowancePlanMultiSelect([...allowancePlanMultiSelect]);
    }
  }

  const handleChangeAllowancePlan = (event, newValue) => {
    setallowancePlanObj(newValue);
    setAllowancePlan(newValue ? newValue.id : 0);

    AddAllowanceAmountField(newValue);
  };
  const handleChangeAllowancePlanInput = (event, newInputValue) => {
    setallowancePlanText(newInputValue);
  };

  const handleChangeBonusPlan = (event, newValue) => {
    setbonusPlanObj(newValue);
    setBonusPlan(newValue ? newValue.id : 0);
  };
  const handleChangeBonusPlanInput = (event, newInputValue) => {
    setbonusPlanText(newInputValue);
  };

  const handleChangeBonusAmount = (event) => {
    setBonusAmount(event.target.value);

    //Target Pay
    var sum = 0;
    if (allowancePlanMultiSelect.length > 0) {
      for (var i = 0; i < allowancePlanMultiSelect.length; i++) {
        if (allowancePlanMultiSelect[i].value) {
          if (
            (allowancePlanMultiSelect[i].id >= 66 &&
              allowancePlanMultiSelect[i].id <= 83) ||
            (allowancePlanMultiSelect[i].id >= 92 &&
              allowancePlanMultiSelect[i].id <= 97)
          ) {
            sum = sum + Number(allowancePlanMultiSelect[i].value);
          } else {
            sum = sum + Number(allowancePlanMultiSelect[i].value) * 12;
          }
        }
      }
    }
    var target =
      Number(salaryAmount) * 12 + Number(event.target.value) + Number(sum);
    setTargetPay(target);
  };

  const handleChangeSalaryAmount = (event) => {
    setSalaryAmount(event.target.value);

    //Target Pay
    var sum = 0;
    if (allowancePlanMultiSelect.length > 0) {
      for (var i = 0; i < allowancePlanMultiSelect.length; i++) {
        if (allowancePlanMultiSelect[i].value) {
          if (
            (allowancePlanMultiSelect[i].id >= 66 &&
              allowancePlanMultiSelect[i].id <= 83) ||
            (allowancePlanMultiSelect[i].id >= 92 &&
              allowancePlanMultiSelect[i].id <= 97)
          ) {
            sum = sum + Number(allowancePlanMultiSelect[i].value);
          } else {
            sum = sum + Number(allowancePlanMultiSelect[i].value) * 12;
          }
        }
      }
    }
    var target =
      Number(event.target.value) * 12 + Number(bonusAmount) + Number(sum);
    setTargetPay(target);
  };

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (
      characterCode === "Backspace" ||
      characterCode === "ArrowUp" ||
      characterCode === "ArrowDown" ||
      characterCode === "ArrowRight" ||
      characterCode === "ArrowLeft" ||
      characterCode === "Tab"
    )
      return;
    else if (
      characterCode.includes(".") &&
      e.target.value &&
      e.target.value.indexOf(".") < 0
    )
      return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  var formData = {
    ApplicantId: props.id,
    CompensationGradeId: compensationGrade,
    CompensationGradeProfileId: compensationGradeProfile,
    PayPlanId: payPlan,
    SalaryAmount: salaryAmount,
    BonusPlanId: bonusPlan,
    BonusAmount: bonusAmount,
    BonusPercentage: bonusPercentage,
    AllowancePlanObj: allowancePlanObj,
    AllowanceAmountObj: allowanceAmountMultiSelect,
      UserId: props.userId,
      UserIdencrypted: props.loginDetail[0].userEncryptedId,
  };
  //Validation
  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false,
      e = false;
    if (data.CompensationGradeId == null || data.CompensationGradeId == "") {
      setErrorcompensationGrade(true);
      setErrorTextcompensationGrade("Mandatory field");
      a = true;
    } else {
      setErrorcompensationGrade(false);
      setErrorTextcompensationGrade("");
      a = false;
    }
    if (
      data.CompensationGradeProfileId == null ||
      data.CompensationGradeProfileId == ""
    ) {
      setErrorcompensationGradeProfile(true);
      setErrorTextcompensationGradeProfile("Mandatory field");
      b = true;
    } else {
      setErrorcompensationGradeProfile(false);
      setErrorTextcompensationGradeProfile("");
      b = false;
    }
    if (data.PayPlanId == null || data.PayPlanId == "") {
      setErrorpayPlan(true);
      setErrorTextpayPlan("Mandatory field");
      c = true;
    } else {
      setErrorpayPlan(false);
      setErrorTextpayPlan("");
      c = false;
    }
    if (empTypes.includes(props.empType)) {
      data.SalaryAmount = null;
      setErrorsalaryAmount(false);
      setErrorTextsalaryAmount("");
      d = false;
    } else {
      if (!data.SalaryAmount) {
        setErrorsalaryAmount(true);
        setErrorTextsalaryAmount("Mandatory field");
        d = true;
      } else if (data.SalaryAmount === 0 || data.SalaryAmount === "0") {
        setErrorsalaryAmount(true);
        setErrorTextsalaryAmount("Zero not allowed");
        d = true;
      } else {
        setErrorsalaryAmount(false);
        setErrorTextsalaryAmount("");
        d = false;
      }
    }

    if (allowancePlanObj.length > 0) {
      if (allowanceAmountMultiSelect.length != allowancePlanObj.length) {
        if (allowancePlanObj.length < allowanceAmountMultiSelect.length) {
          for (var i = 0; i < allowanceAmountMultiSelect.length; i++) {
            let objFindAllowanceAmount = allowancePlanObj.find(
              (x) => x.id == allowanceAmountMultiSelect[i].id
            );
            if (!objFindAllowanceAmount) {
              allowanceAmountMultiSelect.splice(i, 1);
            }
          }
        } else {
          setErrorallowanceAmount(true);
          setErrorTextallowanceAmount("Please enter Allowance Amount.");
          e = true;
        }
      } else if (allowanceAmountMultiSelect.length == allowancePlanObj.length) {
        for (var i = 0; i < allowanceAmountMultiSelect.length; i++) {
          if (!allowanceAmountMultiSelect[i].value) {
            setErrorallowanceAmount(true);
            setErrorTextallowanceAmount("Please enter Allowance Amount.");
            e = true;
            break;
          }
        }
        if (e == true) {
          setErrorallowanceAmount(true);
          setErrorTextallowanceAmount("Please enter Allowance Amount.");
          e = true;
        } else {
          setErrorallowanceAmount(false);
          setErrorTextallowanceAmount("");
          e = false;
        }
      } else {
        setErrorallowanceAmount(false);
        setErrorTextallowanceAmount("");
        e = false;
      }
    }

    if (a || b || c || d || e) {
      return false;
    } else {
      return true;
    }
  }

  async function clickEventConfirmAnnual() {
    setConfirmationClause("ConfirmAnnual");
    setOpenConfirm(true);
    setOpenAlertConfirm("Are you sure Target Pay matches the CTC?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  }

  const submitHandler = (e) => {
    setIsLoader(true);
    formData = {
      ApplicantId: props.id,
      CompensationGradeId: compensationGrade,
      CompensationGradeProfileId: compensationGradeProfile,
      PayPlanId: payPlan,
      SalaryAmount: salaryAmount,
      BonusPlanId: bonusPlan,
      BonusAmount: bonusAmount,
      BonusPercentage: bonusPercentage,
      AllowancePlanObj: allowancePlanObj,
      AllowanceAmountObj: allowanceAmountMultiSelect,
      UserId: props.userId,
        UserIdencrypted: props.loginDetail[0].userEncryptedId,
    };
    if (ValidateFormFields(formData)) {
      if (compensationDetailsId && compensationDetailsId > 0) {
        UpdateCompensationDetails(formData);
      } else {
        SaveCompensationDetails(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  const clearHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  //Save Compensation Details
  function SaveCompensationDetails(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/SaveCompensationDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          SaveAllowancePlanDetails(formData);
          //setOpen(true);
          //setOpenAlert(data.responseMsg);
          //setClassName("clr-dark-blue bg-success");
          //setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Save Allowance Details
  function SaveAllowancePlanDetails(formData) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/SaveAllowancePlanDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data[0].responseMsg == "Compensation Details Saved Successfully.") {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data[0].responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert("Compensation Details Save Failed");
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Update Compensation Details
  function UpdateCompensationDetails(formData) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/UpdateCompensationDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          UpdateAllowancePlanDetails(formData);
          //setOpen(true);
          //setOpenAlert(data.responseMsg);
          //setClassName("clr-dark-blue bg-success");
          //setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Update Allowance Details
  function UpdateAllowancePlanDetails(formData) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/UpdateAllowancePlanDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (
          data[0].responseMsg == "Compensation Details Updated Successfully."
        ) {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data[0].responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert("Compensation Details Update Failed");
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //fetch Compensation Grade
  const [itemsCompensationGrade, setItemsCompensationGrade] = useState([]);
  async function getCompensationGrade() {
    const token = props.token;
    const response = await fetch("HRDetails/GetCompensationGrade", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsCompensationGrade(jsonItems);
  }

  //fetch Compensation Grade Profile
  const [itemsCompensationGradeProfile, setItemsCompensationGradeProfile] =
    useState([]);
  async function getCompensationGradeProfile(id) {
    const token = props.token;
    const response = await fetch(
      "HRDetails/GetCompensationGradeProfile/" + id,
      { headers: { Authorization: "Bearer " + token } }
    );
    const jsonItems = await response.json();
    setItemsCompensationGradeProfile(jsonItems);
  }

  //fetch Pay Plan
  const [itemsPayPlan, setItemsPayPlan] = useState([]);
  async function getPayPlan() {
    const token = props.token;
    const response = await fetch("HRDetails/GetPayPlan", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsPayPlan(jsonItems);
  }

  //fetch Allowance Plan
  const [itemsAllowancePlan, setItemsAllowancePlan] = useState([]);
  async function getAllowancePlan(id) {
    const token = props.token;
    const response = await fetch("HRDetails/GetAllowancePlan/" + id, {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsAllowancePlan(jsonItems);
  }

  //fetch Bonus Plan
  const [itemsBonusPlan, setItemsBonusPlan] = useState([]);
  async function getBonusPlan() {
    const token = props.token;
    const response = await fetch("HRDetails/GetBonusPlan", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsBonusPlan(jsonItems);
  }

  //Change Button Text
  function getButtonTextSave(id) {
    id != null && id > 0 && id != undefined
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  //Set All States
  function SetStateAsPerFetched(data) {
    setIsLoader(true);
    getCompensationGradeProfile(data.compensationGradeId);
    setCompensationDetailsId(data.compensationDetailsId);
    setCompensationGrade(data.compensationGradeId);
    if (data.compensationGradeObj[0].id > 0) {
      setCompensationGradeObj(data.compensationGradeObj[0]);
      setCompensationGradeText(data.compensationGradeObj[0].name);
    }

    setCompensationGradeProfile(data.compensationGradeProfileId);
    if (data.compensationGradeProfileObj[0].id > 0) {
      setCompensationGradeProfileObj(data.compensationGradeProfileObj[0]);
      setCompensationGradeProfileText(data.compensationGradeProfileObj[0].name);
    }
    setPayPlan(data.payPlanId);
    if (data.payPlanObj[0].id > 0) {
      setpayPlanObj(data.payPlanObj[0]);
      setpayPlanText(data.payPlanObj[0].name);
    }
    setSalaryAmount(data.salaryAmount);
    //setAllowancePlan(data.allowancePlanId);
    if (data.allowancePlanObj && data.allowancePlanObj.length > 0) {
      for (var i = 0; i < data.allowancePlanObj.length; i++) {
        let objFind = allowancePlanObj.find(
          (x) => x.id == data.allowancePlanObj[i].id
        );
        if (objFind) {
          objFind.id = data.allowancePlanObj[i].id;
          objFind.name = data.allowancePlanObj[i].name;
          objFind.value = data.allowancePlanObj[i].allowanceAmount;

          setallowancePlanObj([...allowancePlanObj]);

          //setallowancePlanText([...allowancePlanText]);
        } else {
          allowancePlanObj.push({
            id: data.allowancePlanObj[i].id,
            name: data.allowancePlanObj[i].name,
            value: data.allowancePlanObj[i].allowanceAmount,
          });
          setallowancePlanObj([...allowancePlanObj]);

          //allowancePlanText.push({ name: data.allowancePlanObj[i].name })
          //setallowancePlanText([...allowancePlanText]);
        }

        //Allowance Amount
        let objFindAmount = allowancePlanMultiSelect.find(
          (x) => x.id == data.allowancePlanObj[i].id
        );
        if (objFindAmount) {
          objFindAmount.id = data.allowancePlanObj[i].id;
          objFindAmount.value = data.allowancePlanObj[i].allowanceAmount;
          objFindAmount.label = data.allowancePlanObj[i].name;
          objFindAmount.name = data.allowancePlanObj[i].allowanceAmount;

          setallowanceAmountMultiSelect([...allowancePlanMultiSelect]);
        } else {
          allowancePlanMultiSelect.push({
            id: data.allowancePlanObj[i].id,
            value: data.allowancePlanObj[i].allowanceAmount,
            label: data.allowancePlanObj[i].name,
            name: data.allowancePlanObj[i].allowanceAmount,
          });
          setallowanceAmountMultiSelect([...allowancePlanMultiSelect]);
        }
      }
    }
    //setAllowanceAmount(data.allowanceAmount);

    setBonusPlan(data.bonusPlanId);
    if (data.bonusPlanObj[0].id > 0) {
      setbonusPlanObj(data.bonusPlanObj[0]);
      setbonusPlanText(data.bonusPlanObj[0].name);
    }
    setBonusAmount(data.bonusAmount);
    setBonusPercentage(data.bonusPercentage);

    //Target Pay
    var sum = 0;
    if (allowancePlanMultiSelect.length > 0) {
      for (var i = 0; i < allowancePlanMultiSelect.length; i++) {
        if (allowancePlanMultiSelect[i].value) {
          if (
            (allowancePlanMultiSelect[i].id >= 66 &&
              allowancePlanMultiSelect[i].id <= 83) ||
            (allowancePlanMultiSelect[i].id >= 92 &&
              allowancePlanMultiSelect[i].id <= 97)
          ) {
            sum = sum + Number(allowancePlanMultiSelect[i].value);
          } else {
            sum = sum + Number(allowancePlanMultiSelect[i].value) * 12;
          }
        }
      }
    }
    var target =
      Number(data.salaryAmount) * 12 + Number(data.bonusAmount) + Number(sum);
    setTargetPay(target);

    if (
      data.compensationGradeId == 0 ||
      data.compensationGradeId == null ||
      data.compensationGradeId == "" ||
      data.compensationGradeId == "undefined"
    ) {
      getCompensationGradeOnLoad(props.id);
      //getCompensationGradeProfileOnLoad(props.id);
    }
    if (
      data.compensationGradeProfileId == 0 ||
      data.compensationGradeProfileId == null ||
      data.compensationGradeProfileId == "" ||
      data.compensationGradeProfileId == "undefined"
    ) {
      //getCompensationGradeOnLoad(props.id);
      getCompensationGradeProfileOnLoad(props.id);
    }

    if (
      data.payPlanId == 0 ||
      data.payPlanId == null ||
      data.payPlanId == "" ||
      data.payPlanId == "undefined"
    ) {
      getPayPlanOnLoad();
    }

    setIsLoader(false);
  }

  //Get Compensation Details On Page Load
  function getCompensationDetailsOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("HRDetails/GetCompensationDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  async function getPayPlanOnLoad() {
    setIsLoader(true);
    const token = props.token;
    await fetch("HRDetails/GetPayPlan", {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setPayPlan(data[0].id);
          setpayPlanObj(data[0]);
          setpayPlanText(data[0].name);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          console.log("Error at GetPayPlan");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Get Compensation Grade On Page Load
  async function getCompensationGradeOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    await fetch("HRDetails/GetCompensationGradeOnLoad/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCompensationGrade(data.id);
          setCompensationGradeObj(data);
          setCompensationGradeText(data.name);
          getCompensationGradeProfile(data.id);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          console.log("Error at getCompensationGradeOnLoad");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Get Compensation Grade Profile On Page Load
  async function getCompensationGradeProfileOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    await fetch("HRDetails/GetCompensationGradeProfileOnLoad/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCompensationGradeProfile(data.id);
          setCompensationGradeProfileObj(data);
          setCompensationGradeProfileText(data.name);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          console.log("Error at getCompensationGradeProfileOnLoad");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }
  function renderTargetPayithThousandSeparator(value) {
    return !value
      ? value
      : value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getCompensationDetailsOnLoad(props.id);

        if (
          compensationGrade == null ||
          compensationGrade == "" ||
          compensationGrade == undefined
        ) {
          getCompensationGradeOnLoad(props.id);
          getCompensationGradeProfileOnLoad(props.id);
        }
      }

      if (payPlan == null || payPlan == "" || payPlan == undefined) {
        getPayPlanOnLoad();
      }

      getCompensationGrade();
      getCompensationGradeProfile(compensationGrade);
      getPayPlan();
      getAllowancePlan(props.id);
      getBonusPlan();
    }
    getButtonTextSave(compensationDetailsId);
  }, [props.id, compensationDetailsId, props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <Grid container spacing={2} className="d-flex jc-space-bt fd-row">
              <p className="f-12 clr-red">
                <i>*Note: p.a. - per annum; p.m. - per month</i>
              </p>
              <p className="f-12 clr-red">
                <i>
                  *Disclaimer: To load <strong>Allowance Plan</strong> dropdown
                  please fill <strong>Job Details</strong> first
                </i>
              </p>
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center Width-100 mt-1"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={compensationGradeObj}
                  inputValue={compensationGradeText}
                  options={itemsCompensationGrade}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeCompensationGrade}
                  onInputChange={handleChangeCompensationGradeInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Compensation Grade"
                      label="Compensation Entity"
                      variant="filled"
                      required
                      error={errorCompensationGrade}
                      helperText={errorTextcompensationGrade}
                    />
                  )}
                  //disabled={props.disableValue}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center Width-100 mt-1"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={compensationGradeProfileObj}
                  inputValue={compensationGradeProfileText}
                  options={itemsCompensationGradeProfile}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeCompensationGradeProfile}
                  onInputChange={handleChangeCompensationGradeProfileInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Compensation Grade Profile"
                      label="GRIP Position Level"
                      variant="filled"
                      required
                      error={errorCompensationGradeProfile}
                      helperText={errorTextcompensationGradeProfile}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center Width-100 mt-1"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={payPlanObj}
                  inputValue={payPlanText}
                  options={itemsPayPlan}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangePayPlan}
                  onInputChange={handleChangePayPlanInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pay Plan"
                      variant="filled"
                      required
                      error={errorPayPlan}
                      helperText={errorTextpayPlan}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center Width-100  mt-1"
              >
                <TextField
                  className="lightInputFields"
                  required={!empTypes.includes(props.empType)}
                  id="salaryAmount"
                  fullWidth
                  label="Basic Salary p.m."
                  variant="filled"
                  name="Basic Salary"
                  value={salaryAmount}
                  onKeyDown={handleKeypress}
                  onChange={handleChangeSalaryAmount}
                  error={errorsalaryAmount}
                  helperText={errorTextsalaryAmount}
                  // disabled={
                  //   props.disableOnceCompleted
                  //     ? props.disableOnceCompleted
                  //     : props.disableValue
                  // }
                  disabled={
                    props.disableOnceCompleted ||
                    props.disableValue ||
                    empTypes.includes(props.empType)
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                className="d-flex jc-center Width-100  mt-1"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  multiple
                  value={
                    allowancePlanObj.name
                      ? allowancePlanObj.name
                      : allowancePlanObj
                  }
                  limitTags={4}
                  id="checkboxes-tags-demo"
                  options={itemsAllowancePlan}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name ? option.name : ""}
                    </React.Fragment>
                  )}
                  //value={[allowancePlanText] ? [allowancePlanText] : [""]}
                  //value={obj.id}
                  // defaultValue={[allowancePlanText[0]]}
                  onChange={handleChangeAllowancePlan}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Allowance Plan"
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>

              <React.Fragment>
                {allowancePlanMultiSelect.map((obj, id) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="d-flex jc-center Width-100 mt-1"
                  >
                    <TextField
                      className="lightInputFields"
                      id="allowanceAmount"
                      type="text"
                      fullWidth
                      label={obj.label ? obj.label : obj.name}
                      variant="filled"
                      name={obj.id}
                      value={obj.value ? obj.value : ""}
                      //defaultValue={obj.value ? obj.value : ""}
                      onKeyDown={handleKeypress}
                      onChange={handleChangeAllowanceAmount}
                      onBlur={handleChangeAllowanceAmountBlur}
                      error={errorallowanceAmount}
                      helperText={errorTextallowanceAmount}
                      disabled={
                        props.disableOnceCompleted
                          ? props.disableOnceCompleted
                          : props.disableValue
                      }
                    />
                  </Grid>
                ))}
              </React.Fragment>

              <Grid item xs={12} className="d-flex jc-center Width-100">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="d-flex jc-center Width-100 mt-1 mr-1"
                >
                  <Autocomplete
                    className="lightInputFields hover-lightblue"
                    id="combo-box-demo"
                    value={bonusPlanObj}
                    inputValue={bonusPlanText}
                    options={itemsBonusPlan}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    onChange={handleChangeBonusPlan}
                    onInputChange={handleChangeBonusPlanInput}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Performance Pay Plan"
                        variant="filled"
                      />
                    )}
                    disabled={
                      props.disableOnceCompleted
                        ? props.disableOnceCompleted
                        : props.disableValue
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  className="d-flex jc-center Width-100 mt-1 mr-1"
                >
                  <TextField
                    className="lightInputFields"
                    id="bonusAmount"
                    fullWidth
                    label="Performance Pay Amount p.a."
                    variant="filled"
                    name="Performance Pay Amount"
                    value={bonusAmount}
                    onKeyDown={handleKeypress}
                    onChange={handleChangeBonusAmount}
                    disabled={
                      props.disableOnceCompleted
                        ? props.disableOnceCompleted
                        : props.disableValue
                    }
                  />
                </Grid>
                {/*<Grid
                  item
                  xs={12}
                  sm={3}
                  className="d-flex jc-center Width-100 mt-1 mr-1"
                >
                  <TextField
                    className="lightInputFields"
                    id="bonusPercentage"
                    fullWidth
                    label="Bonus Percentage"
                    variant="filled"
                    name="Bonus Percentage"
                    value={bonusPercentage}
                    onChange={(e) => setBonusPercentage(e.target.value)}
                    disabled={
                      props.disableOnceCompleted
                        ? props.disableOnceCompleted
                        : props.disableValue
                    }
                  />
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm={3}
                  className="d-flex jc-center Width-100 mt-1"
                >
                  <TextField
                    className="lightInputFields"
                    id="targetPay"
                    fullWidth
                    label="Target Pay p.a."
                    variant="filled"
                    name="Target Pay"
                    value={renderTargetPayithThousandSeparator(targetPay)}
                    disabled
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={4} className="d-flex jc-center Width-100  mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue mt-1"
                                    id="combo-box-demo"
                                    value={allowancePlanObj}
                                    inputValue={allowancePlanText}
                                    options={itemsAllowancePlan}
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    onChange={handleChangeAllowancePlan}
                                    onInputChange={handleChangeAllowancePlanInput}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Allowance Plan"
                                                variant="filled"

                                            />
                                    }
                                    disabled={props.disableOnceCompleted ? props.disableOnceCompleted : props.disableValue}
                                />
                            </Grid> */}

              {/*  <Grid item xs={12} sm={4} className="d-flex jc-center Width-100 mt-1">
                                <TextField className="lightInputFields mt-1"
                                    id="allowanceAmount"
                                    fullWidth
                                    label="Allowance Amount"
                                    variant="filled"
                                    name="Allowance Amount"
                                    value={allowanceAmount}
                                    onChange={(e) => setAllowanceAmount(e.target.value)}
                                    disabled={props.disableOnceCompleted ? props.disableOnceCompleted : props.disableValue}
                                />
                            </Grid> */}

              <Grid item xs={12} className="d-flex jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={clearHandler}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={clickEventConfirmAnnual}
                >
                  <span>{buttonTextSave}</span>
                </Button>
              </Grid>

              {/* <Grid item xs={12} className="d-flex jc-center">
                <Button className="ns-btn-primary mt-2" onClick={clearHandler}>
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  onClick={submitHandler}
                >
                  <span>{buttonTextSave}</span>
                </Button>
              </Grid> */}
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
