import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function Superannuation(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
           
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [superAnnuation, setSuperAnnuation] = useState([]);
    const superAnnuationTemp = [];

    const [personalData, setPersonalData] = useState([]);
    const personalDataTemp = [];



    function SetStateAsPerFetched(data) {
        //SuperAnnuation Data
        for (var i = 0; i < data.superAnnuationData.length; i++) {
            superAnnuationTemp.push({
                'firstNameNominee': data.superAnnuationData[i].firstNameNominee, 'middleNameNominee': data.superAnnuationData[i].middleNameNominee ? data.superAnnuationData[i].middleNameNominee : "",
                'lastNameNominee': data.superAnnuationData[i].lastNameNominee, 'dob': data.superAnnuationData[i].dobStr,
                'relationWithEmployee': data.superAnnuationData[i].relationWithEmployee, 'nomineeAddress': data.superAnnuationData[i].nomineeAddress,
                'sharePercentage': data.superAnnuationData[i].sharePercentage, 'gaurdianName': data.superAnnuationData[i].gaurdianName ? data.superAnnuationData[i].gaurdianName : "",
                'gaurdianAddress': data.superAnnuationData[i].gaurdianAddress ? data.superAnnuationData[i].gaurdianAddress : "", 'validationOfMinorYesOrNo': data.superAnnuationData[i].validationOfMinorYesOrNo,
                'count': i + 1

            });
        }
        setSuperAnnuation(superAnnuationTemp);

        //Personal Data
        personalDataTemp.push({
            'firstName': data.personalData.firstName, 'middleName': data.personalData.middleName ? data.personalData.middleName : "",
            'lastName': data.personalData.lastName, 'registrationNo': data.personalData.registrationNo ? data.personalData.registrationNo : "",
            'gender': data.personalData.genders[0].name, 'religion': data.personalData.religions[0].name, 'maritalStatus': data.personalData.maritalStatuses[0].name,
            'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr
        });

        setPersonalData(personalDataTemp);

    }


    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);

            }
        }

    }, [encryptedId, props.token]);


    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">
            <table className="width-100">
                <thead>
                    <tr className="d-flex jc-space-bt">
                        <th>
                            {
                                (props.AREID == 1 || props.AREID == 2)
                                    ?
                                    <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                                    :
                                    <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                            }
                        </th>
                        <th className="">
                            <p className="f-12 mt-2e mr-3e">GID: {props.gid}</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="d-flex jc-center">
                            <p className="f-16 fw-bold bdr-bottom-black">SECTION IV</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-center">
                            <strong className="f-12 mT-1e">(To be completed by the Annuitants and witnessed by the Trustees)</strong>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-center">
                            <p className="f-14 fw-bold bdr-bottom-black mt-1">NOMINATION SUPERANNUATION SCHEME</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex f-12 ml-0">
                                <span className="mt-1">I, {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}, a member of
                                    THE <span className="text-uppercase">{props.AREName}</span> SUPERANNUANTION SCHEME, hereby nominate the below to
                                receive the pension in the event of my death during the guaranteed period as per the
                                rules of the scheme/the pension
                                corpus on my death during the guaranteed declare that upon such payments, the
                                corporation will be discharged of all
                                liability in this respect under the Master Policy No.____________.</span>
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table className="bdr-black border-collapse width-100 text-center mt-2">
                                <tbody className="f-12">
                                    <tr>
                                        <td className="bdr-black ">Name of Nominee(s)
                                    </td>
                                        <td className="bdr-black ">Full address of Nominee(s)
                                    </td>
                                        <td className="bdr-black ">Relationship with the employee</td>
                                        <td className="bdr-black ">Date of Birth</td>
                                        <td className="bdr-black ">Proportion by which the gratuity will be
                                    shared</td>
                                        <td className="bdr-black ">If Nominee is minor, Name & Relation of the
                                    guardian who may receive the amount during minority</td>
                                    </tr>
                                    <tr className="">
                                        <td className="bdr-black text-center" >
                                            {superAnnuation.map(superAnnuationNew => <div><br />{superAnnuationNew.firstNameNominee + " " + superAnnuationNew.middleNameNominee + " " + superAnnuationNew.lastNameNominee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {superAnnuation.map(superAnnuationNew => <div><br />{superAnnuationNew.nomineeAddress}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {superAnnuation.map(superAnnuationNew => <div><br />{superAnnuationNew.relationWithEmployee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {superAnnuation.map(superAnnuationNew => <div><br />{superAnnuationNew.dob}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {superAnnuation.map(superAnnuationNew => <div><br />{superAnnuationNew.sharePercentage}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {superAnnuation.map(superAnnuationNew => <div><br />{"Guardian Name: " + superAnnuationNew.gaurdianName + " , Guardian Address: " + superAnnuationNew.gaurdianAddress}<br /><br /></div>)}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr className="d-flex jc-flex-end">
                        <td>
                            <div className="mt-2 mr-2 f-12">
                                Signature of Appointee as token of consent
                            </div>
                        </td>
                    </tr>
                    <tr className="d-flex jc-flex-end">
                        <td>
                            <div className="mt-2 mr-2 f-12">
                                ______________________________<br />
                                (Signature of Annuitant)
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex mt-1 ml-1 f-12 jc-space-bt">
                                <span className="mt-1">_____________________________<br />(Signature of Trustee)<br />
                                    <span className="text-uppercase">{props.AREName}</span> SUPERANNUATION SCHEME</span>
                                <span className="mt-1 mr-2">______________________________<br />(Signature
                                of Nominee)</span>
                            </div>
                        </td>
                    </tr>
                    <tr className="h-30 f-12">
                        <td>
                            <span className="ml-1">Place: {personalData.map(personalDataNew => <span>{personalDataNew.placeOfWork}</span>)}</span>
                        </td>
                    </tr>
                    <tr className="h-30 f-12">
                        <td>
                            <span className="ml-1">Date:  {personalData.map(personalDataNew => <span>{personalDataNew.joiningDate}</span>)}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

}