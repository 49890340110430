import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "0px solid #FFFFFF",
                },
                "&:after": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #FFFFFF",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#005673",
            },
        },
        MuiPickersBasePicker: {
            container: {
                background: "",
            },
            pickerView: {
                689: {
                    background: "#000028",
                },
                background: "#000028",
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: ""
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
    },
});

export default function Declaration(props) {
    var id;
    const [buttonTextSave, setButtonTextSave] = useState("Save Declaration");
    const [declarationDetailsId, setDeclarationDetailsId] = useState([]);
    const [radioNonExpiredContract, setRadioNonExpiredContract] = useState("No");
    const [nonExpiredContract, setNonExpiredContract] = useState("");
    const [radioConfidentialObligations, setRadioConfidentialObligations] =
        useState("No");
    const [confidentialObligations, setConfidentialObligations] = useState("");
    const [radioArrested, setRadioArrested] = useState("No");
    const [arrested, setArrested] = useState("");
    const [radioLitigation, setRadioLitigation] = useState("No");
    const [litigation, setLitigation] = useState("");
    const [radioCloseRelativesPolitical, setRadioCloseRelativesPolitical] =
        useState("No");
    const [closeRelativesPolitical, setCloseRelativesPolitical] = useState("");
    const [radioCloseRelatives, setRadioCloseRelatives] = useState("No");
    const [closeRelatives, setCloseRelatives] = useState("");
    const [radioContractualObligation, setRadioContractualObligation] =
        useState("No");
    const [contractualObligation, setContractualObligation] = useState("");

    //Error State and Msg
    const [
        errorPlaceHolderNonExpiredContract,
        setErrorPlaceHolderNonExpiredContract,
    ] = useState("If yes,Give details");
    const [textareaClassNonExpiredContract, settextareaClassNonExpiredContract] =
        useState("lightInputFields");
    const [
        errorPlaceHolderConfidentialObligations,
        setErrorPlaceHolderConfidentialObligations,
    ] = useState("If yes,Give details");
    const [
        textareaClassConfidentialObligations,
        settextareaClassConfidentialObligations,
    ] = useState("lightInputFields");
    const [errorPlaceHolderArrested, setErrorPlaceHolderArrested] = useState(
        "If yes,Give details"
    );
    const [textareaClassArrested, settextareaClassArrested] =
        useState("lightInputFields");
    const [errorPlaceHolderLitigation, setErrorPlaceHolderLitigation] = useState(
        "If yes,Give details"
    );
    const [textareaClassLitigation, settextareaClassLitigation] =
        useState("lightInputFields");
    const [
        errorPlaceHolderCloseRelativesPolitical,
        setErrorPlaceHolderCloseRelativesPolitical,
    ] = useState("If yes,Give details");
    const [
        textareaClassCloseRelativesPolitical,
        settextareaClassCloseRelativesPolitical,
    ] = useState("lightInputFields");
    const [errorPlaceHolderCloseRelatives, setErrorPlaceHolderCloseRelatives] =
        useState("If yes,Give details");
    const [textareaClassCloseRelatives, settextareaClassCloseRelatives] =
        useState("lightInputFields");
    const [
        errorPlaceHolderContractualObligation,
        setErrorPlaceHolderContractualObligation,
    ] = useState("If yes,Give details");
    const [
        textareaClassContractualObligation,
        settextareaClassContractualObligation,
    ] = useState("lightInputFields");

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const [isLoader, setIsLoader] = useState(false);

    //Alerts
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        clearStates();
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleChangeRadioButton = (event) => {
        switch (event.target.name) {
            case "1":
                setRadioNonExpiredContract(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setNonExpiredContract("");
                }
                break;
            case "2":
                setRadioConfidentialObligations(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setConfidentialObligations("");
                }
                break;
            case "3":
                setRadioArrested(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setArrested("");
                }
                break;
            case "4":
                setRadioCloseRelatives(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setCloseRelatives("");
                }
                break;
            case "5":
                setRadioContractualObligation(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setContractualObligation("");
                }
                break;
            case "6":
                setRadioLitigation(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setLitigation("");
                }
                break;
            case "7":
                setRadioCloseRelativesPolitical(event.target.value);
                if (
                    event.target.value == "No" ||
                    event.target.value == "Not Applicable"
                ) {
                    setCloseRelativesPolitical("");
                }
                break;
            default:
                break;
        }
    };

    var formData = {
        ApplicantId: props.id,
        UserId: props.userId,
        ListDeclarationData: [
            {
                DeclarationDetailsId: declarationDetailsId[0],
                DeclarationId: 1,
                DeclarationComments: nonExpiredContract,
                YesNoNa:
                    radioNonExpiredContract == "No"
                        ? 0
                        : radioNonExpiredContract == "Yes"
                            ? 1
                            : 2,
            },
            {
                DeclarationDetailsId: declarationDetailsId[1],
                DeclarationId: 2,
                DeclarationComments: confidentialObligations,
                YesNoNa:
                    radioConfidentialObligations == "No"
                        ? 0
                        : radioConfidentialObligations == "Yes"
                            ? 1
                            : 2,
            },
            {
                DeclarationDetailsId: declarationDetailsId[2],
                DeclarationId: 3,
                DeclarationComments: arrested,
                YesNoNa: radioArrested == "No" ? 0 : radioArrested == "Yes" ? 1 : 2,
            },
            {
                DeclarationDetailsId: declarationDetailsId[3],
                DeclarationId: 4,
                DeclarationComments: closeRelatives,
                YesNoNa:
                    radioCloseRelatives == "No"
                        ? 0
                        : radioCloseRelatives == "Yes"
                            ? 1
                            : 2,
            },
            {
                DeclarationDetailsId: declarationDetailsId[4],
                DeclarationId: 5,
                DeclarationComments: contractualObligation,
                YesNoNa:
                    radioContractualObligation == "No"
                        ? 0
                        : radioContractualObligation == "Yes"
                            ? 1
                            : 2,
            },
            {
                DeclarationDetailsId: declarationDetailsId[5],
                DeclarationId: 6,
                DeclarationComments: litigation,
                YesNoNa: radioLitigation == "No" ? 0 : radioLitigation == "Yes" ? 1 : 2,
            },
            {
                DeclarationDetailsId: declarationDetailsId[6],
                DeclarationId: 7,
                DeclarationComments: closeRelativesPolitical,
                YesNoNa:
                    radioCloseRelativesPolitical == "No"
                        ? 0
                        : radioCloseRelativesPolitical == "Yes"
                            ? 1
                            : 2,
            },
        ],
    };

    function ValidateFormFields(data) {
        var a = false,
            b = false,
            c = false,
            d = false,
            e = false,
            f = false,
            g = false;
        var lstDeclarationData = data.ListDeclarationData;
        for (let i = 0; i < lstDeclarationData.length; i++) {
            switch (lstDeclarationData[i].DeclarationId) {
                case 1:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderNonExpiredContract("Mandatory Field");
                            settextareaClassNonExpiredContract(
                                "border-color-textarea-validate"
                            );
                            a = true;
                        } else {
                            setErrorPlaceHolderNonExpiredContract("If yes,Give details");
                            settextareaClassNonExpiredContract("lightInputFields");
                            a = false;
                        }
                    } else {
                        setErrorPlaceHolderNonExpiredContract("If yes,Give details");
                        settextareaClassNonExpiredContract("lightInputFields");
                        a = false;
                    }
                    break;
                case 2:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderConfidentialObligations("Mandatory Field");
                            settextareaClassConfidentialObligations(
                                "border-color-textarea-validate"
                            );
                            b = true;
                        } else {
                            setErrorPlaceHolderConfidentialObligations("If yes,Give details");
                            settextareaClassConfidentialObligations("lightInputFields");
                            b = false;
                        }
                    } else {
                        setErrorPlaceHolderConfidentialObligations("If yes,Give details");
                        settextareaClassConfidentialObligations("lightInputFields");
                        b = false;
                    }
                    break;
                case 3:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderArrested("Mandatory Field");
                            settextareaClassArrested("border-color-textarea-validate");
                            c = true;
                        } else {
                            setErrorPlaceHolderArrested("If yes,Give details");
                            settextareaClassArrested("lightInputFields");
                            c = false;
                        }
                    } else {
                        setErrorPlaceHolderArrested("If yes,Give details");
                        settextareaClassArrested("lightInputFields");
                        c = false;
                    }
                    break;
                case 4:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderCloseRelatives("Mandatory Field");
                            settextareaClassCloseRelatives("border-color-textarea-validate");
                            d = true;
                        } else {
                            setErrorPlaceHolderCloseRelatives("If yes,Give details");
                            settextareaClassCloseRelatives("lightInputFields");
                            d = false;
                        }
                    } else {
                        setErrorPlaceHolderCloseRelatives("If yes,Give details");
                        settextareaClassCloseRelatives("lightInputFields");
                        d = false;
                    }
                    break;
                case 5:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderContractualObligation("Mandatory Field");
                            settextareaClassContractualObligation(
                                "border-color-textarea-validate"
                            );
                            e = true;
                        } else {
                            setErrorPlaceHolderContractualObligation("If yes,Give details");
                            settextareaClassContractualObligation("lightInputFields");
                            e = false;
                        }
                    } else {
                        setErrorPlaceHolderContractualObligation("If yes,Give details");
                        settextareaClassContractualObligation("lightInputFields");
                        e = false;
                    }
                    break;
                case 6:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderLitigation("Mandatory Field");
                            settextareaClassLitigation("border-color-textarea-validate");
                            f = true;
                        } else {
                            setErrorPlaceHolderLitigation("If yes,Give details");
                            settextareaClassLitigation("lightInputFields");
                            f = false;
                        }
                    } else {
                        setErrorPlaceHolderLitigation("If yes,Give details");
                        settextareaClassLitigation("lightInputFields");
                        f = false;
                    }
                    break;
                case 7:
                    if (lstDeclarationData[i].YesNoNa === 1) {
                        if (
                            lstDeclarationData[i].DeclarationComments == null ||
                            lstDeclarationData[i].DeclarationComments == ""
                        ) {
                            setErrorPlaceHolderCloseRelativesPolitical("Mandatory Field");
                            settextareaClassCloseRelativesPolitical(
                                "border-color-textarea-validate"
                            );
                            g = true;
                        } else {
                            setErrorPlaceHolderCloseRelativesPolitical("If yes,Give details");
                            settextareaClassCloseRelativesPolitical("lightInputFields");
                            g = false;
                        }
                    } else {
                        setErrorPlaceHolderCloseRelativesPolitical("If yes,Give details");
                        settextareaClassCloseRelativesPolitical("lightInputFields");
                        g = false;
                    }
                    break;
                default:
                    break;
            }
        }
        if (a || b || c || d || e || f || g) {
            return false;
        } else {
            return true;
        }
    }

    //Change Button Text
    function getButtonTextSave(id) {
        id != null && id > 0 && id != undefined
            ? setButtonTextSave("Update")
            : setButtonTextSave(buttonTextSave);
    }

    const submitHandler = () => {
        setIsLoader(true);
        formData = {
            ApplicantId: props.id,
            UserId: props.userId,
            ListDeclarationData: [
                {
                    DeclarationDetailsId: declarationDetailsId[0],
                    DeclarationId: 1,
                    DeclarationComments: nonExpiredContract,
                    YesNoNa:
                        radioNonExpiredContract == "No"
                            ? 0
                            : radioNonExpiredContract == "Yes"
                                ? 1
                                : 2,
                },
                {
                    DeclarationDetailsId: declarationDetailsId[1],
                    DeclarationId: 2,
                    DeclarationComments: confidentialObligations,
                    YesNoNa:
                        radioConfidentialObligations == "No"
                            ? 0
                            : radioConfidentialObligations == "Yes"
                                ? 1
                                : 2,
                },
                {
                    DeclarationDetailsId: declarationDetailsId[2],
                    DeclarationId: 3,
                    DeclarationComments: arrested,
                    YesNoNa: radioArrested == "No" ? 0 : radioArrested == "Yes" ? 1 : 2,
                },
                {
                    DeclarationDetailsId: declarationDetailsId[3],
                    DeclarationId: 4,
                    DeclarationComments: closeRelatives,
                    YesNoNa:
                        radioCloseRelatives == "No"
                            ? 0
                            : radioCloseRelatives == "Yes"
                                ? 1
                                : 2,
                },
                {
                    DeclarationDetailsId: declarationDetailsId[4],
                    DeclarationId: 5,
                    DeclarationComments: contractualObligation,
                    YesNoNa:
                        radioContractualObligation == "No"
                            ? 0
                            : radioContractualObligation == "Yes"
                                ? 1
                                : 2,
                },
                {
                    DeclarationDetailsId: declarationDetailsId[5],
                    DeclarationId: 6,
                    DeclarationComments: litigation,
                    YesNoNa:
                        radioLitigation == "No" ? 0 : radioLitigation == "Yes" ? 1 : 2,
                },
                {
                    DeclarationDetailsId: declarationDetailsId[6],
                    DeclarationId: 7,
                    DeclarationComments: closeRelativesPolitical,
                    YesNoNa:
                        radioCloseRelativesPolitical == "No"
                            ? 0
                            : radioCloseRelativesPolitical == "Yes"
                                ? 1
                                : 2,
                },
            ],
        };
        if (ValidateFormFields(formData)) {
            if (declarationDetailsId && declarationDetailsId.length > 0) {
                UpdateDeclarationData(formData);
            } else {
                SaveDeclarationData(formData);
            }
        } else {
            setOpen(true);
            setOpenAlert(
                "Form validation unsuccessful. Please check the error messages."
            );
            setClassName("clr-white bg-alert");
            setOpenType("Alert");
        }
    };
    const clearHandler = () => {
        setOpenConfirm(true);
        setOpenAlertConfirm("Do you want to clear all the above fields?");
        setClassName("clr-dark-blue bg-alert");
        setOpenType("Confirmation");
    };
    function clearStates() {
        setRadioNonExpiredContract("No");
        setNonExpiredContract("");
        setRadioConfidentialObligations("No");
        setConfidentialObligations("");
        setRadioArrested("No");
        setArrested("");
        setRadioLitigation("No");
        setLitigation("");
        setRadioCloseRelatives("No");
        setCloseRelatives("");
        setRadioCloseRelativesPolitical("No");
        setCloseRelativesPolitical("");
        setRadioContractualObligation("No");
        setContractualObligation("");
    }

    //Set All States
    function SetStateAsPerFetched(data) {
        if (data.listDeclarationData != null) {
            var arrIds = [];
            for (var i = 0; i < data.listDeclarationData.length; i++) {
                arrIds.push(data.listDeclarationData[i].declarationDetailsId);
                setDeclarationDetailsId(arrIds);
                switch (data.listDeclarationData[i].declarationId) {
                    case 1:
                        setRadioNonExpiredContract(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setNonExpiredContract(
                            data.listDeclarationData[i].declarationComments
                        );
                        break;
                    case 2:
                        setRadioConfidentialObligations(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setConfidentialObligations(
                            data.listDeclarationData[i].declarationComments
                        );
                        break;
                    case 3:
                        setRadioArrested(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setArrested(data.listDeclarationData[i].declarationComments);
                        break;
                    case 4:
                        setRadioCloseRelatives(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setCloseRelatives(data.listDeclarationData[i].declarationComments);
                        break;
                    case 5:
                        setRadioContractualObligation(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setContractualObligation(
                            data.listDeclarationData[i].declarationComments
                        );
                        break;
                    case 6:
                        setRadioLitigation(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setLitigation(data.listDeclarationData[i].declarationComments);
                        break;
                    case 7:
                        setRadioCloseRelativesPolitical(
                            data.listDeclarationData[i].yesNoNa == 0
                                ? "No"
                                : data.listDeclarationData[i].yesNoNa == 1
                                    ? "Yes"
                                    : "Not Applicable"
                        );
                        setCloseRelativesPolitical(
                            data.listDeclarationData[i].declarationComments
                        );
                        break;
                    default:
                        break;
                }
            }
        }
        setIsLoader(false);
    }

    //Save Employee Declaration Data
    function SaveDeclarationData(formData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("PreHiringForm/SaveEmployeeDeclaration/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    //alert(data.responseMsg);
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Update Employee Declaration Data
    function UpdateDeclarationData(formDetails) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formDetails),
        };
        fetch("PreHiringForm/UpdateEmployeeDeclaration/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    // alert(data.responseMsg);
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Get Employee Declaration
    function getDeclarationDataOnLoad(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("PreHiringForm/GetEmployeeDeclarationDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: "100%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
    }));
    const classes = useStyles();

    useEffect(() => {
        getButtonTextSave(declarationDetailsId[0]);
    }, [declarationDetailsId[0]]);

    useEffect(() => {
        if (props.token) {
            if (props.id != undefined && props.id != "") {
                getDeclarationDataOnLoad(props.id);
            }
        }
    }, [props.id, declarationDetailsId[0], props.token]);

    return (
        <ThemeProvider theme={theme}>
            <div className="width-100">
                <form className="d-flex jc-center">
                    <Grid container spacing={1} className="d-flex fd-column ">
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
                        >
                            <p className="m-0">
                                Do you have a non-compete clause in contract with your current or
                                former employer that has not yet expired that could impact your
                                ability to work at siemens in the position for which you have
                                applied
                            </p>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="1"
                                        value={radioNonExpiredContract}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioNonExpiredContract == "Yes" ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="d-flex jc-center Width-100"
                                >
                                    <TextareaAutosize
                                        className={textareaClassNonExpiredContract}
                                        id="Contract"
                                        name="1"
                                        rowsMin={2}
                                        placeholder={errorPlaceHolderNonExpiredContract}
                                        value={nonExpiredContract}
                                        onChange={(e) => setNonExpiredContract(e.target.value)}
                                        disabled={props.disableValue}
                                    />
                                </Grid>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
                        >
                            <p className="m-0">
                                Do you have a confidentiality obligation with your current or
                                former employer that could impact your ability to work at
                                Siemens in the position for which you have applied.
                            </p>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="2"
                                        value={radioConfidentialObligations}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioConfidentialObligations == "Yes" ? (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <TextareaAutosize
                                            className={textareaClassConfidentialObligations}
                                            id="confidentially"
                                            name="2"
                                            rowsMin={2}
                                            placeholder={errorPlaceHolderConfidentialObligations}
                                            value={confidentialObligations}
                                            onChange={(e) =>
                                                setConfidentialObligations(e.target.value)
                                            }
                                            disabled={props.disableValue}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
                        >
                            <div item xs={12} sm={12} className="Width-100">
                                <p className="m-0">
                                    Have you ever been arrested / prosecuted / detained /
                                    convicted / fined
                                </p>
                            </div>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="3"
                                        value={radioArrested}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioArrested == "Yes" ? (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <TextareaAutosize
                                            className={textareaClassArrested}
                                            id="arrested"
                                            name="3"
                                            rowsMin={2}
                                            placeholder={errorPlaceHolderArrested}
                                            value={arrested}
                                            onChange={(e) => setArrested(e.target.value)}
                                            disabled={props.disableValue}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
                        >
                            <div item xs={12} sm={12} className="Width-100">
                                <p className="m-0">
                                    Are you currently involved in any past or ongoing litigations
                                    related to fraud, bribery, money laundering, or any other
                                    criminal litigations or proceedings by any Court of Law or any
                                    Government Authority?
                                </p>
                            </div>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="6"
                                        value={radioLitigation}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioLitigation == "Yes" ? (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <TextareaAutosize
                                            className={textareaClassLitigation}
                                            id="arrested"
                                            name="6"
                                            rowsMin={2}
                                            placeholder={errorPlaceHolderLitigation}
                                            value={litigation}
                                            onChange={(e) => setLitigation(e.target.value)}
                                            disabled={props.disableValue}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
                        >
                            <p className="m-0">
                                Do you or any of your family members or close relatives had /
                                have any relationship, connection or link to any politician or
                                political party or government official?
                            </p>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="7"
                                        value={radioCloseRelativesPolitical}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioCloseRelativesPolitical == "Yes" ? (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <TextareaAutosize
                                            className={textareaClassCloseRelativesPolitical}
                                            id="closeRelatives"
                                            rowsMin={2}
                                            name="7"
                                            placeholder={errorPlaceHolderCloseRelativesPolitical}
                                            value={closeRelativesPolitical}
                                            onChange={(e) => setCloseRelativesPolitical(e.target.value)}
                                            disabled={props.disableValue}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
                        >
                            <p className="m-0">
                                Are you or any of your family members or close relatives
                                currently holding direct / indirect stake or a key
                                decision-making position in any organization which is currently
                                engaged in performing business with Siemens (e.g., Suppliers /
                                Customers / Business Partners) or is in competition with Siemens
                                business?
                            </p>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="4"
                                        value={radioCloseRelatives}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioCloseRelatives == "Yes" ? (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <TextareaAutosize
                                            className={textareaClassCloseRelatives}
                                            id="closeRelatives"
                                            rowsMin={2}
                                            name="4"
                                            placeholder={errorPlaceHolderCloseRelatives}
                                            value={closeRelatives}
                                            onChange={(e) => setCloseRelatives(e.target.value)}
                                            disabled={props.disableValue}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-bt mt-1 fd-row"
                        >
                            <p className="m-0">
                                Do you have a contractual obligation with your current or former
                                employer that could impact your ability to work at Siemens in
                                the position for which you have applied
                            </p>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormControl component="fieldset" className="">
                                    <RadioGroup
                                        aria-label=""
                                        name="5"
                                        value={radioContractualObligation}
                                        onChange={handleChangeRadioButton}
                                        row
                                    >
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            disabled={props.disableValue}
                                            value="Not Applicable"
                                            control={<Radio />}
                                            label="Not Applicable"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {radioContractualObligation == "Yes" ? (
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <TextareaAutosize
                                            className={textareaClassContractualObligation}
                                            id="contractualObligation"
                                            rowsMin={2}
                                            name="5"
                                            placeholder={errorPlaceHolderContractualObligation}
                                            value={contractualObligation}
                                            onChange={(e) => setContractualObligation(e.target.value)}
                                            disabled={props.disableValue}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </Grid>

                        <div className="d-flex jc-center">
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2"
                                disabled={props.disableValue}
                                onClick={clearHandler}
                            >
                                <span>Clear</span>
                            </Button>
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2 ml-2"
                                disabled={props.disableValue}
                                onClick={submitHandler}
                            >
                                <span>{buttonTextSave}</span>
                            </Button>
                        </div>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-content">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadClear"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlertConfirm}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-content">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">Cancel</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? <Loader /> : <></>}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
