import React from "react";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";


const theme = createMuiTheme({
    overrides: {
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            }
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC"
            },
        },
        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC"
                }
            }
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68"
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {

                    backgroundColor: "#CCF5F5",
                }
            }
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2"
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiFilledInput: {
            root: {
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&$hover": {
                    backgroundColor: "#E8F8F8"
                },
                "&$disabled": {
                    "pointerEvent": "none",
                    "color": "#707080",
                    "backgroundColor": "#001035",
                    "border": "2px solid #707070",
                    "borderRadius": "0",
                    "&:before": {
                        "borderBottom": "1px solid #001035",
                    },
                    "&:hover:before": {
                        "borderBottom": "1px solid #001035",
                        "borderBottomStyle": "solid"
                    }
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5"
            }
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            }
        },
        MuiPaper: {
            rounded: {
                border: `2px solid #2EE6E2`,
                marginBottom: "20px",
                background: "#CCF5F5"
            }
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none"
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`
                }
            },
            menuItem: {
                color: "#FFFFFF"
            },
            selectIcon: {
                color: "#00CCCC"
            },
            input: {
                backgroundColor: "#000028"
            }
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
                "& div:hover": {
                    color: "#00CCCC"
                }
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            }
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important"
            },
            body: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px"
            }
        },
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2"
            }
        },
        //MuiPickersBasePicker: {
        //    pickerView: {
        //        background: "#FFFFFF"
        //    }
        //},
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF"
            },
            switchHeader: {
                color: "#FFFFFF"
            },
            daysHeader: {
                color: "#FFFFFF"
            },
            dayLabel: {
                color: "#FFFFFF"
            }
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF"
            }
        },
        MuiPickersBasePicker: {
            container: {
                background: ""
            }
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF"
            }
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028"
            }
        }
    }
});

export default function UnauthorizedAccess(props) {

    const logout = () => {
        props.logoutClick();
    }

    return (
        <ThemeProvider theme={theme}>

            <div className="master-layout-block d-flex jc-center">
                <Grid item xs={12} sm={9} className="d-flex fd-column bdr-radius box-shadow mt-5">
                    <h2 className="text-danger d-flex jc-center mb-1">WARNING!!! You are not authorized to access this page.</h2>
                    <h4 className="clr-darkk-blue d-flex jc-center mt-0">Contact Administrator if there is an issue with your request.</h4>
                    <p className="f-16 clr-dark-green d-flex jc-center mt-0">
                        <a href="javascript:void(0)" onClick={logout} className="clr-dark-green">Click here </a>
                        &nbsp;  to go back to login page. </p>
                </Grid>
            </div>


        </ThemeProvider>
    );
}