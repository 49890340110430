import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Tooltip from "@material-ui/core/Tooltip";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FileSaver from "file-saver";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&$hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "000028",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#000028",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "",
      },
      pickerView: {
        background: "#FFFFFF",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: ""
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function AdminOrRecruiterData(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);

  const [isLoader, setIsLoader] = useState(false);

  const chnageEventFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const chnageEventLastName = (e) => {
    setLastName(e.target.value);
  };
  const changeEventEmail = (e) => {
    setEmail(e.target.value);
  };
  var searchTexts = {
    FirstName: firstName,
    LastName: lastName,
    UserEmail: email,
  };
  var searchFilterData = JSON.stringify(searchTexts);
  const clickEventSearchFilter = async () => {
    setIsLoader(true);
    searchTexts = {
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
    };
    searchFilterData = JSON.stringify(searchTexts);
    GetAdminOrRecruitersList(searchFilterData);
  };
  async function GetAdminOrRecruitersList(searchFilterData) {
    const token = props.token;
    const options = {
      method: "post",
      //type: 'async',
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: searchFilterData,
    };
    await fetch("Employee/GetAllUser/", options)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.log("Error:", error));
    setIsLoader(false);
  }
  const clickEventClearSearchFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };
  const clickEventExportToExcel = async () => {
    searchTexts = {
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
    };
    searchFilterData = JSON.stringify(searchTexts);
    GetUserListDownload(searchFilterData);
  };

  async function GetUserListDownload(searchFilterData) {
    const token = props.token;
    searchTexts = {
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
    };

    axios
      .post("/Employee/GetAllUserDownload/", searchTexts, {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + token },
      })
      .then(function (response) {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, "UserList.xlsx");
      });
  }

  function EditUser(rowData) {
    //window.open(
    //  "/UserRegistration?id=" + rowData.userIdencrypted + rowData.userId,
    //  "_blank"
    //  );

      window.open(
          "/UserRegistration?id=" + rowData.userIdencrypted,
          "_blank"
      );
  }
  function GetEditButton(rowData) {
    //var url ="/UserRegistration?id=" + rowData.userIdencrypted + rowData.userId;

      var url = "/UserRegistration?id=" + rowData.userIdencrypted;

    return (
      <Button href="javascript:void(0)" onClick={() => EditUser(rowData)}>
        <span>
          <EditOutlinedIcon />
        </span>
      </Button>
    );
  }

  var columns = [
    {
      title: "Edit",
      field: "",
      render: (rowData) => rowData && GetEditButton(rowData),
    },
    { title: "UserID", field: "userId", hidden: true },
    { title: "UserIDEncrypted", field: "userIdencrypted", hidden: true },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email", field: "userEmail" },
    { title: "Role", field: "roleName" },
    { title: "ARE", field: "companyFullName" },
    { title: "Op Co/Po Co/St. Co/Ctry fn", field: "division", hidden: true },
    { title: "Business Unit", field: "businessUnit", hidden: true },
  ];

  useEffect(() => {
    setIsLoader(true);
    if (props.token) {
      GetAdminOrRecruitersList(searchFilterData);
    }
    //props.loader === true ? setIsLoader(true) : setIsLoader(false);
  }, [props.loader, props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="master-layout-block ">
        <Box paddingTop={{ xs: "5rem", sm: "4rem" }}>
          <h3 className="clr-dark-blue d-flex jc-center">
            Adminstrator/Recruiter List
          </h3>
        </Box>
        <Grid
          container
          spacing={1}
          className="d-flex fd-column px-2 box-shadow mb-2 bdr-radius"
        >
          <Grid container spacing={2} className="d-flex jc-space-around fd-row">
            <Grid item xs={12} sm={3} className="d-flex Width-100">
              <TextField
                className="lightInputFields mt-1"
                id="firstName"
                label="First Name"
                variant="filled"
                fullWidth
                name="First Name"
                value={firstName}
                onChange={chnageEventFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={3} className="d-flex Width-100">
              <TextField
                className="lightInputFields mt-1"
                id="lastName"
                label="Last Name"
                variant="filled"
                fullWidth
                name="Last Name"
                value={lastName}
                onChange={chnageEventLastName}
              />
            </Grid>
            <Grid item xs={12} sm={3} className="d-flex Width-100">
              <TextField
                className="lightInputFields mt-1"
                id="email"
                label="Email"
                variant="filled"
                fullWidth
                name="Email"
                value={email}
                onChange={changeEventEmail}
              />
            </Grid>
            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
              <Tooltip title="Search" placement="top">
                <Button className="mt-1" onClick={clickEventSearchFilter}>
                  <span>
                    <SearchIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip title="Clear Search Fields" placement="top">
                <Button className="mt-1" onClick={clickEventClearSearchFields}>
                  <span>
                    <ClearIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
              .
              <Tooltip title="Export to Excel" placement="top">
                <Button className="mt-1" onClick={clickEventExportToExcel}>
                  <span>
                    <ImportExportIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt-1 mb-2">
            <MaterialTable
              title="Adminstrator Or Recruiter List"
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                search: false,
              }}
              //editable={{
              //    onRowUpdate: (newData, oldData) =>
              //        new Promise((resolve) => {
              //            handleRowUpdate(newData, oldData, resolve);

              //        }),
              //    onRowAdd: (newData) =>
              //        new Promise((resolve) => {
              //            handleRowAdd(newData, resolve)
              //        }),
              //    onRowDelete: (oldData) =>
              //        new Promise((resolve) => {
              //            handleRowDelete(oldData, resolve)
              //        }),
              //}}
            />
          </Grid>
          {isLoader ? <Loader /> : <></>}
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default AdminOrRecruiterData;
