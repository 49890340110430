import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import Loader from "../../../components/Loader";
import { theme } from "./CL_Utility";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { CancelOutlined, Done, DoneAll } from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

export default function ContractLetters(props) {
   
    const [ddlSaf, setDdlSaf] = React.useState('');
    const handleChange = (event) => {
        setDdlSaf(event.target.value);
    };
    const useStyles = makeStyles((theme) => ({
        divSAF: {
            overflow: "hidden",
            width: "100%",
            background: "#FFFFFF",
        },
        formControl: {
            minWidth: "45%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
        formControlItem: {
            maxWidth: "45%",
            marginTop: "4%",
            float: "left",
            fontSIze: "15px",
            fontFamily: "SiemensSerif_Roman", 
            marginRight:"2%",
        },
        formControlItemMenu: {
            width: "55%",
            fontSIze: "15px",
            fontFamily: "SiemensSerif_Roman",
        },
    }));
    const classes = useStyles();

  let [isLoader, setIsLoader] = useState(false);
  // Alerts
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");
  const handleClose = () => {
    if (
      //openType === "Accept Compensation Success" ||
        openType === "Accept Compensation Letter" ||
      //openType === "Accept Offer Success" ||
        openType === "Accept Offer Letter" ||
      //openType === "Reject Compensation Success" ||
        openType === "Reject Compensation Letter" ||
      openType === "Reject Offer Letter"
    ) {
      getDigiLetterWorkflow(props.id);
    }
    setOpen(false);
  };
  const handleConfirm = (e) => {
    if (!rejectProps?.comments) {
      e.preventDefault();
      setRejectProps({
        ...rejectProps,
        errorText: "Rejection reason is mandatory",
      });
    } else {
      setOpenConfirm(false);
      handleReject(e, rejectProps);
    }
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  let [letterWorkflowData, setLetterWorkflowData] = useState({});
    function setWorkflowDataState(data) {
       
    setLetterWorkflowData({
      ...letterWorkflowData,
      digiLetterWorkflowId: data?.digiLetterWorkflowId,
      applicantId: data?.applicantId,
      userIdencrypted: props?.userId,
      isCompensationAccepted: data?.isCompensationAccepted,
      isCompensationRejected: data?.isCompensationRejected,
      compensationRejectionComments: data?.compensationRejectionComments,
      isOfferAccepted: data?.isOfferAccepted,
      isOfferRejected: data?.isOfferRejected,
      offerRejectionComments: data?.offerRejectionComments,
      digiLetterStatusId: data?.digiLetterStatusId,
      digiLetterStatus: data?.digiLetterStatus,
      isSuperannuation: data?.isSuperannuation ? data?.isSuperannuation : false,
    });
    setIsLoader(false);
    props.getDigiLetterWorkflow(data?.applicantId);
    }
    function getDigiLetterWorkflow(id) {
       
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "get",
      url: "OnboardingDHLMerge/GetDigiLetterWorkflowDetails",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      params: {
        id: id,
      },
    };
    axios(options)
        .then((response) => {
           
            let result = response.data.result;
            
        if (result.response) {
          let data = result.objResult;
          setWorkflowDataState(data);
        } else {
          setIsLoader(false);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  }
  let [compensationUploadedData, setCompensationUploadedData] = useState({});
  function setCompensationUploadState(data) {
    setCompensationUploadedData({
      ...compensationUploadedData,
      compensationLetterUploadId: data?.compensationLetterUploadId,
      applicantId: data?.applicantId,
      userId: data?.userId,
      compensationFileName: data?.compensationFileName,
      compensationFileUrl: data?.compensationFileUrl,
    });
  }
  function getUploadedCompensationLetterDetails(id) {
    const token = props.token;
    const options = {
      method: "get",
      url: "OnboardingDHLMerge/GetUploadedCompensationLetterDetails",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      params: { id: id },
    };
    axios(options)
      .then((response) => {
        let res = response.data.result;
        if (res.response) {
          let data = res.objResult;
          setCompensationUploadState(data);
        }
      })
      .catch((error) => console.log(error));
  }
  let [offerUploadedData, setOfferUploadedData] = useState({});
  function setOfferUploadState(data) {
    setOfferUploadedData({
      ...offerUploadedData,
      offerLetterUploadId: data?.offerLetterUploadId,
      applicantId: data?.applicantId,
      userId: data?.userId,
      offerFileName: data?.offerFileName,
      offerFileUrl: data?.offerFileUrl,
    });
  }
  function getUploadedOfferLetterDetails(id) {
    const token = props.token;
    const options = {
      method: "get",
      url: "OnboardingDHLMerge/GetUploadedOfferLetterDetails",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      params: { id: id },
    };
    axios(options)
      .then((response) => {
        let res = response.data.result;
        if (res.response) {
          let data = res.objResult;
          setOfferUploadState(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
    function handleAccept(e, type) {
        //if (ddlSaf == "" && type == "Compensation" && (gripDetails?.gripLevelId > 0 && gripDetails?.gripLevelId <= 7) ) {
        //    setOpen(true);
        //    setOpenType("Alert");
        //    setClassName("clr-dark-blue bg-error");
        //    setOpenAlert("Please select Superannuation Fund dropdown value");
        //}
        //else {
            //if (ddlSaf == 1)
            //    type = "CompensationWSAF";
            //else if (ddlSaf == 2)
            //    type = "Compensation";
            switch (type) {
                //case "CompensationWSAF":
                //    letterWorkflowData.isSuperannuation = true;
                //    AcceptCompensation(letterWorkflowData);
                //    break;
                case "Compensation":
                    AcceptCompensation(letterWorkflowData);
                    break;
                case "Offer":
                    AcceptOffer(letterWorkflowData);
                    break;
                default:
                    break;
            }
        //}
  }
  let [rejectProps, setRejectProps] = useState({
    isEnable: false,
    type: "",
    comments: "",
  });
  function handleRejection(e, type) {
    setOpenType(type + " Letter Rejection");
    setRejectProps({ ...rejectProps, isEnable: true, type: type });
    setOpenConfirm(true);
  }
  function handleReject(e, rejectObj) {
    switch (rejectObj?.type) {
      case "Compensation":
        letterWorkflowData.compensationRejectionComments = rejectObj?.comments;
        RejectCompensation(letterWorkflowData);
        break;
      case "Offer":
        letterWorkflowData.offerRejectionComments = rejectObj?.comments;
        RejectOffer(letterWorkflowData);
        break;
      default:
        break;
    }
    }
   
  function AcceptCompensation(data) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "post",
      url: "OnboardingDHLMerge/AcceptCompensationLetter",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`, 
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((response) => {
        let result = response.data.result;
        if (result.response) {
          setIsLoader(false);
          setOpen(true);
            setOpenType("Accept Compensation Letter");
          setClassName("clr-dark-blue bg-success");
          setOpenAlert(result.responseMsg);
        } else {
          setIsLoader(false);
          setOpen(true);
            setOpenType("Error in Compensation Letter Acceptance");
          setClassName("clr-dark-blue bg-error");
          setOpenAlert(result.responseMsg);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        setOpen(true);
          setOpenType("Error in Compensation Letter Acceptance");
        setClassName("clr-dark-blue bg-error");
        setOpenAlert("Exception occurred while accepting compensation");
      });
  }
  function AcceptOffer(data) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "post",
      url: "OnboardingDHLMerge/AcceptOfferLetter",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((response) => {
        let result = response.data.result;
        if (result.response) {
          setIsLoader(false);
          setOpen(true);
            setOpenType("Accept Offer Letter");
          setClassName("clr-dark-blue bg-success");
          setOpenAlert(result.responseMsg);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenType("Error in Offer Letter Acceptance");
          setClassName("clr-dark-blue bg-error");
          setOpenAlert(result.responseMsg);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        setOpen(true);
          setOpenType("Error in Offer Letter Acceptance");
        setClassName("clr-dark-blue bg-error");
        setOpenAlert("Exception occurred while accepting offer");
      });
  }
  function RejectCompensation(data) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "post",
      url: "OnboardingDHLMerge/RejectCompensationLetter",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((response) => {
        let result = response.data.result;
        if (result.response) {
          setIsLoader(false);
          setOpen(true);
          setOpenType("Reject Compensation Letter");
          setClassName("clr-dark-blue bg-success");
          setOpenAlert(result.responseMsg);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenType("Error in Compensation Letter Rejection");
          setClassName("clr-dark-blue bg-error");
          setOpenAlert(result.responseMsg);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        setOpen(true);
          setOpenType("Error in Compensation Letter Rejection");
        setClassName("clr-dark-blue bg-error");
        setOpenAlert("Exception occurred while rejecting compensation");
      });
  }
  function RejectOffer(data) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "post",
      url: "OnboardingDHLMerge/RejectOfferLetter",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    axios(options)
      .then((response) => {
        let result = response.data.result;
        if (result.response) {
          setIsLoader(false);
          setOpen(true);
          setOpenType("Reject Offer Letter");
          setClassName("clr-dark-blue bg-success");
          setOpenAlert(result.responseMsg);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenType("Error in Offer Letter Rejection");
          setClassName("clr-dark-blue bg-error");
          setOpenAlert(result.responseMsg);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
        setOpen(true);
          setOpenType("Error in Offer Letter Rejection");
        setClassName("clr-dark-blue bg-error");
        setOpenAlert("Exception occurred while rejecting offer");
      });
  }
  let [gripDetails, setGripDetails] = useState({});
  function setGripDetailsState(data) {
    setGripDetails({
      ...gripDetails,
      gripLevelId: data?.gripLevelId,
      gripLevel: data?.gripLevel,
    });
    setIsLoader(false);
  }
  function getGripDetails(id) {
    setIsLoader(true);
    const token = props.token;
    const options = {
      method: "get",
      url: "OnboardingDHLMerge/GetCandidateGripLevel",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      params: {
        id: id,
      },
    };
    axios(options)
      .then((response) => {
        let result = response.data.result;
        if (result.response) {
          let data = result.objResult;
          setGripDetailsState(data);
        } else {
          setIsLoader(false);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  }
    useEffect(() => {
       
    if (props.token && props.id != '') {
      getUploadedCompensationLetterDetails(props.id);
        getUploadedOfferLetterDetails(props.id);
       
      getDigiLetterWorkflow(props.id);
      getGripDetails(props.id);
    }
  }, [props.token, props.id]);

  return (
    <ThemeProvider theme={theme}>
      {!props.isDigital ? (
        <h4 className="text-center mt-6">
          Compensation and Offer Letters are expected to be processed offline.
          If not please contact to recruiter or Admin.
        </h4>
      ) : (
        <div className="mb-2 mt-6">
          {!letterWorkflowData?.isCompensationAccepted ? (
            <>
              <p className="text-right clr-red font-italic">
                *Please note : Candidate need to accept compensation to fill up
                pre-joining formalities.
              </p>
                              {/*  {gripDetails?.gripLevelId > 0 && gripDetails?.gripLevelId <= 7 ? (
                <p className="text-right clr-red font-italic">
                  **SAF : Superannuation.
                </p>
              ) : (
                <></>
                              )}*/}
            </>
          ) : (
            <></>
          )}
          {letterWorkflowData?.digiLetterStatusId > 0 &&
          letterWorkflowData?.digiLetterStatusId !== 7 ? (
            <Grid container spacing={1} className="d-flex jc-center">
              {compensationUploadedData?.compensationLetterUploadId ? (
                // <Grid container item xs={12} sm={8} spacing={1}>
                // </Grid>
                <>
                  {/* <Grid
                    item
                    xs={12}
                    sm={2}
                    className="Width-100 d-flex jc-flex-end"
                  >
                    <p>Compensation Letter :</p>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    className="Width-100 align-self-center"
                  >
                    <a
                      className="mt-1"
                      href={compensationUploadedData?.compensationFileUrl}
                      target="_blank"
                                                  title="Open Compensation Letter"
                                                  style={{ fontSize:"15px", fontFamily: "'SiemensSerif_Roman'" }}
                    >
                      View Compensation Annexure
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    className="Width-100 align-self-center"
                  >
                    {letterWorkflowData?.digiLetterStatusId === 1 ? (
                      <>
                        {/* <span>
                        <Checkbox
                          checked={letterWorkflowData?.isSuperannuation}
                          onChange={(e) =>
                            setLetterWorkflowData({
                              ...letterWorkflowData,
                              isSuperannuation: e.target.checked,
                            })
                          }
                          title="Accept With Superannuation"
                        />
                        <i style={{ fontSize: "12px" }}>
                          Accept With Superannuation
                        </i>
                      </span> */}
                                                      {/* {gripDetails?.gripLevelId > 0 &&
                        gripDetails?.gripLevelId <= 7 ? (
                        <FormControl className={classes.formControl}>
                           <div className={classes.divSAF}>
                                                                      <div id="demo-simple-select-label" className={classes.formControlItem}>Superannuation Fund</div>
                            <Select
                                className={classes.formControlItemMenu}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={ddlSaf}
                                 label="SAF"
                                onChange={handleChange}
                                 >
                      <MenuItem value={1}>with superannuation Fund</MenuItem>
                       <MenuItem value={2}>without superannuation Fund</MenuItem>
                        </Select>
                        </div>
                        </FormControl>
                        ) : (
                          <></>
                                                      )}*/}
                        <Button
                          className="ns-btn-fit-content-no-min-width ml-2"
                          title="Accept Compensation Letter Without Superannuation"
                          onClick={(e) => handleAccept(e, "Compensation")}
                          variant="contained"
                          startIcon={<Done style={{ color: "white" }} />}
                        >
                        {/*Accept Compensation Without SAF*/}
                        Accept Compensation
                        </Button>
                        <Button
                          className="ns-btn-fit-content-no-min-width ml-2"
                          title="Reject Compensation Letter"
                          onClick={(e) => handleRejection(e, "Compensation")}
                          variant="contained"
                          startIcon={
                            <CancelOutlined style={{ color: "white" }} />
                          }
                        >
                          Reject Compensation
                        </Button>
                      </>
                    ) : letterWorkflowData?.digiLetterStatusId === 5 ? (
                      <div className="d-flex">
                                                          <CancelIcon style={{ fontSize: 30, color: "red", marginTop: "5px"}} />
                                                          <p style={{ fontSize: "15px", margin: "0.5em", fontFamily: "'SiemensSerif_Roman'" }}>
                          {letterWorkflowData?.digiLetterStatus}
                          {"."}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>{"Reason: "}</b>
                          {letterWorkflowData?.compensationRejectionComments}
                        </p>
                      </div>
                    ) : letterWorkflowData?.digiLetterStatusId === 7 ? (
                      <></>
                    ) : (
                      <div className="d-flex">
                        <CheckCircleOutlineOutlined
                          style={{ fontSize: 30, color: "green",marginTop:"5px" }}
                        />
                           <p style={{ fontSize: "15px", margin: "0.5em", fontFamily: "'SiemensSerif_Roman'" }}>
                          Compensation Letter Accepted.
                        </p>
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {offerUploadedData?.offerLetterUploadId &&
              (letterWorkflowData?.digiLetterStatusId === 3 ||
               letterWorkflowData?.digiLetterStatusId === 4 ||
               letterWorkflowData?.digiLetterStatusId === 9||
               letterWorkflowData?.digiLetterStatusId === 6) ? (
                // <Grid container item xs={12} sm={3} spacing={1}>
                // </Grid>
                <>
                  {/* <Grid
                    item
                    xs={12}
                    sm={2}
                    className="Width-100 d-flex jc-flex-end"
                  >
                    <p>Offer Letter : </p>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    className="Width-100 align-self-center"
                  >
                    <a
                      className="mt-1"
                      href={offerUploadedData?.offerFileUrl}
                      target="_blank"
                                                      title="Open Offer Letter"
                                                      style={{ fontSize: "15px", fontFamily: "'SiemensSerif_Roman'" }}
                    >
                      View Offer Letter
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    className="Width-100 align-self-center"
                  >
                    {letterWorkflowData?.digiLetterStatusId === 3 ? (
                      <>
                        <Button
                          variant="contained"
                          className="ns-btn-fit-content-no-min-width"
                          title="Accept Offer Letter"
                          onClick={(e) => handleAccept(e, "Offer")}
                          startIcon={
                            <CheckCircleOutlineOutlined
                              style={{ color: "white" }}
                            />
                          }
                        >
                          Accept Offer
                        </Button>
                        <Button
                          variant="contained"
                          className="ns-btn-fit-content-no-min-width ml-2"
                          title="Reject Offer Letter"
                          onClick={(e) => handleRejection(e, "Offer")}
                          startIcon={
                            <CancelOutlined style={{ color: "white" }} />
                          }
                        >
                          Reject Offer
                        </Button>
                      </>
                    ) : letterWorkflowData?.digiLetterStatusId === 4 ? (
                      <div className="d-flex">
                        <CheckCircleOutlineOutlined
                         style={{ fontSize: 30, color: "green", marginTop: "12px", marginRight: "8px"}}
                        />
                                                              <p style={{ fontSize: "15px",fontFamily: "'SiemensSerif_Roman'"}}>
                          Offer Letter Accepted.
                        </p>
                      </div>
                      ) : letterWorkflowData?.digiLetterStatusId === 6 || letterWorkflowData?.digiLetterStatusId === 9? (
                      <div className="d-flex">
                                                                  <CancelIcon style={{ fontSize: 30, color: "red", marginTop: "5px" }} />
                                                                  <p style={{ fontSize: "15px", margin: "0.5em", fontFamily: "'SiemensSerif_Roman'"  }}>
                          {letterWorkflowData?.digiLetterStatus}
                          {"."}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>{"Reason: "}</b>
                          {letterWorkflowData?.offerRejectionComments}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <>
              Letters generation are in progress and will be apeared here soon.
            </>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle
              className={openClassName}
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
            >
              {openType}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadClear"
            fullWidth
          >
            <DialogTitle
              className={openClassName}
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
            >
              {openType}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="clr-dark-blue">
                {rejectProps?.isEnable ? (
                  <TextField
                    className="mt-1 lightInputFields"
                    id="rejectionReason"
                    label="Rejection Reason"
                    variant="filled"
                    fullWidth
                    multiline
                    maxRows={4}
                    placeholder="Enter a valid reason for rejection"
                    name="rejectionReason"
                    value={rejectProps?.comments}
                    onChange={(e) =>
                      setRejectProps({
                        ...rejectProps,
                        comments: e.target.value,
                      })
                    }
                    InputLabelProps={{ shrink: true }}
                    required
                    error={rejectProps?.errorText}
                    helperText={rejectProps?.errorText}
                    style={{ backgroundColor: "#E8F8F8" }}
                  />
                ) : (
                  openAlertConfirm
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleConfirm}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">Confirm</span>
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={handleCloseConfirm}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">Cancel</span>
              </Button>
            </DialogActions>
          </Dialog>
          {isLoader ? <Loader /> : <></>}
        </div>
      )}
    </ThemeProvider>
  );
}
