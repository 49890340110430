import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
    TextField,
    createMuiTheme,
    ThemeProvider,
    Input,
    FormHelperText,
    InputAdornment,
    IconButton,
    Typography,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import ImageUploader from "react-images-upload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";
import ErrorOutlineOutlined from "@material-ui/icons/ErrorOutlineOutlined";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
            adornedEnd: {
                backgroundColor: "#E8F8F8",
                //padding:"12px 0 0 12px !important",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8 !important",
                },
                //"&&&&:hover": {
                //    backgroundColor: "#E8F8F8"
                //},
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid transparent",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #00CCCC",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8",
        //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8",
        //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                border: `2px solid #2EE6E2`,
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#000028" /*"#2EE6E2"*/,
            },
        },
        //MuiPickersBasePicker: {
        //    pickerView: {
        //        background: "#FFFFFF"
        //    }
        //},
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#FFFFFF",
            },
            container: {
                background: "",
            },
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
    },
});

export default function HiringInfo(props) {
    const [PostAppliedFor, setPostAppliedFor] = useState("");
    const [ReferenceNumber, setReferenceNumber] = useState("");
    const [source, setSource] = useState("");
    const [sourceObj, setSourceObj] = useState("");
    const [sourceText, setSourceText] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [imageFileInBase64, setImageFileInBase64] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [imageHelper, setImageHelper] = useState("");
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [disabledUploadButton, setDisableUploadButton] = useState(false);
    const [postAppliedDisable, setPostAppliedDisable] = useState(false);
    const [referenceNumberDisable, setReferenceNumberDisable] = useState(false);
    const [classesGridUploadButtons, setClassesGridUploadButtons] = useState(
        "d-flex Width-100 box-shadow-none h-100 mr-14-ve"
    );

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const [isLoader, setIsLoader] = useState(false);
    //Alerts
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    var formData = {
        ApplicantId: props.id,
        FirstName: null,
        MiddleName: null,
        LastName: null,
        PostAppliedFor: PostAppliedFor,
        RegistrationNo: ReferenceNumber,
        SourceId: source,
        TitleId: null,
    };
    var fileUploadData = new FormData();
    let uploadFileData = {
        applicantId: props.id,
        employeeImageData: imageFileInBase64,
        userId: props.userId,
    };
    const handleChangeSource = (event) => {
        //setSourceObj(newValue);
        //setSource(newValue ? newValue.id : 0);
        setSource(event.target.value);
        //formData.SourceId = (newValue ? newValue.id : 0);
        formData.SourceId = event.target.value;
        SaveHiringInfo(formData);
    };
    const handleChangeSourceInput = (event, newValueInput) => {
        setSourceText(newValueInput);
    };
    const changeFileHandler = async (e) => {
        if (e.target.files[0]) {
            let type = e.target.files[0].type.replace("image/", "");
            let size = e.target.files[0].size;
            if (type.toLowerCase() !== "jpg" && type.toLowerCase() !== "jpeg") {
                setImageHelper("Only jpg and jpeg format are supported");
                setImageFile(null);
                setImageFileInBase64(null);
                setInputFileValue(Date.now);
            } else if (size > 153600) {
                setImageHelper("Max of 150KB file is allowed");
                setImageFile(null);
                setImageFileInBase64(null);
                setInputFileValue(Date.now);
            } else {
                setImageHelper("");
                setImageFile(e.target.files[0]);
                let binFile = await toBase64(e.target.files[0]).catch((e) => Error(e));
                if (binFile instanceof Error) {
                    console.log("Error: ", binFile.message);
                    setImageHelper("Some error in file conversion");
                    setImageFile(null);
                    setImageFileInBase64(null);
                    setInputFileValue(Date.now);
                } else {
                    let binFileArr = binFile.split(",");
                    setImageFileInBase64(binFileArr[1]);
                }
            }
        } else {
            setImageFile(null);
            setImageFileInBase64(null);
            setInputFileValue(Date.now);
        }
    };
    const uploadHandler = (e) => {
        setIsLoader(true);
        uploadFileData.applicantId = props.id;
        //uploadFileData.userId = props.userId;
        uploadFileData.userId = props.userId;
        //uploadFileData.UserIdencrypted = props.loginDetail[0].userEncryptedId;

        uploadFileData.employeeImageData = imageFileInBase64;
        UploadEmployeePhoto(uploadFileData);
    };
    const deleteImageHandler = (e) => {
        setIsLoader(true);
        uploadFileData.applicantId = props.id;
        uploadFileData.userId = props.userId;
        uploadFileData.employeeImageData = imageFileInBase64;
        deleteEmployeePhoto(uploadFileData);
    };
    function UploadEmployeePhoto(uploadFileData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(uploadFileData),
        };
        fetch("ApplicationForm/UploadEmployeeImage/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setImageFile(null);
                    setImageFileInBase64(null);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAfterFileOperation(data);
                } else {
                    setImageFile(null);
                    setImageFileInBase64(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    function deleteEmployeePhoto(uploadFileData) {
        const token = props.token;
        const options = {
            method: "delete",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(uploadFileData),
        };
        fetch("ApplicationForm/DeleteEmployeePhoto/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setIsLoader(false);
                    setImageFile(null);
                    setImageFileInBase64(null);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    getEmployeePhoto(props.id);
                } else {
                    setImageFile(null);
                    setImageFileInBase64(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    const handleChangeReferenceNo = (event) => {
        setReferenceNumber(event.target.value);
    };
    const handleBlurReference = (event) => {
        formData.RegistrationNo = event.target.value;
        SaveHiringInfo(formData);
    };

    const handleChangePostAppliedFor = (event) => {
        setPostAppliedFor(event.target.value);
    };
    const handleBlurPostAppliedFor = (event) => {
        formData.PostAppliedFor = event.target.value;
        SaveHiringInfo(formData);
    };

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: "100%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
    }));
    const classes = useStyles();

    //fetch Source
    const [itemsSource, setItemsSource] = useState([]);
    async function getSource() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllSources", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        setItemsSource(
            jsonItems.map(({ id, name }) => ({ label: name, value: id }))
        );
        //setItemsSource(jsonItems);
    }

    function SetStateAsPerFetched(data) {
        setPostAppliedFor(data.postAppliedFor);
        setSource(data.sourceId);
        if (data.sources[0].id > 0) {
            setSourceObj(data.sources[0]);
            setSourceText(data.sources[0].name);
        }
        setReferenceNumber(data.registrationNo);

        if (props.roleId == 2 || props.roleId == 4 || props.roleId == 3) {
            setPostAppliedDisable(true);
            setReferenceNumberDisable(true);
        }
        setIsLoader(false);
    }

    function SetStateAfterFileOperation(data) {
        setImageData(data.employeeImageData);
        setImageFile(null);
        setIsLoader(false);
    }

    function getHiringInfo(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("PreHiringForm/GetHiringInfoPreHiring/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    function getEmployeePhoto(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("ApplicationForm/GetUploadedImages/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAfterFileOperation(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    function SaveHiringInfo(formData) {
        setIsLoader(true);
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("PreHiringForm/SaveHiringInfoPreHiring/", options)
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Disable upload button if image exists
    function disableUploadButton(imageContent) {
        imageContent != null && imageContent.length > 0
            ? setDisableUploadButton(true)
            : setDisableUploadButton(false);
    }

    function GetSelectPhotoTooltip() {
        return (
            <React.Fragment>
                <ListItemText primary="• Photo should have full face, front view & Center head within frame. (soft copy taken at a Photo studio only)" />
                <ListItemText primary="• Digital photograph of 300 dpi resolution" />
                <ListItemText primary="• Plain light colour background" />
                <ListItemText primary="• Passport size" />
                <ListItemText primary="• File size: 150KB Max" />
                <ListItemText primary="• File Type: .jpg, .jpeg" />
            </React.Fragment>
        );
    }

    useEffect(() => {
        disableUploadButton(imageData);
        if (props.token) {
            getSource();
            if (props.id != undefined && props.id != "") {
                getEmployeePhoto(props.id);
                getHiringInfo(props.id);
            }
        }
        imageData != null && imageData.length > 0
            ? setClassesGridUploadButtons("d-flex Width-100 box-shadow-none h-100")
            : setClassesGridUploadButtons(
                "d-flex Width-100 box-shadow-none h-100 mr-14-ve"
            );
    }, [props.id, imageData, props.token]);

    return (
        <ThemeProvider theme={theme}>
            <div className="mb-2">
                <form className="d-flex jc-center">
                    <Grid container spacing={1} className="d-flex fd-column ">
                        {imageData && imageData.length > 0 ? (
                            <></>
                        ) : (
                            <table>
                                <tbody>
                                    <tr className="d-flex jc-space-bt f-12 font-italic mt-1">
                                        <td></td>
                                        <td className="clr-dark-blue">
                                            {/***Note : Please provide GID on <span className="fw-bold">In Company Manager</span> text field*/}
                                        </td>
                                        <td className="clr-red fw-bold">
                                            *Mandatory : Upload Photo: Passport Size Photograph (SIZE: 420 X 540 Pixels, RESOLUTION: 300 DPI)
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}


                        <Grid
                            container
                            spacing={3}
                            className="d-flex jc-space-around fd-row"
                        >
                            {(props.roleId != 3) ?
                                <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={imageData != null && imageData.length > 0 ? 3 : 2}
                                        className="d-flex  Width-100 h-100"
                                    >
                                        <TextField
                                            className="mt-1 lightInputFields mb-4"
                                            required={true}
                                            id="postAppliedFor"
                                            label="Post Applied For"
                                            variant="filled"
                                            fullWidth
                                            name="Post Applied For"
                                            value={PostAppliedFor}
                                            onChange={handleChangePostAppliedFor}
                                            onBlur={handleBlurPostAppliedFor}
                                            //disabled={props.disableValue}
                                            disabled={
                                                props.disableOnceCompleted
                                                    ? props.disableOnceCompleted
                                                    : postAppliedDisable
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={imageData != null && imageData.length > 0 ? 3 : 2}
                                        className="d-flex jc-center Width-100 h-100"
                                    >
                                        <TextField
                                            className="lightInputFields mt-1 mb-4"
                                            required={true}
                                            id="referenceNumber"
                                            fullWidth
                                            label="Reference Number"
                                            variant="filled"
                                            name="Reference Number"
                                            value={ReferenceNumber}
                                            onChange={handleChangeReferenceNo}
                                            onBlur={handleBlurReference}
                                            //disabled={props.disableValue}
                                            disabled={
                                                props.disableOnceCompleted
                                                    ? props.disableOnceCompleted
                                                    : referenceNumberDisable
                                            }
                                        />
                                    </Grid>
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            {
                                <Grid
                                    item
                                    xs={12}
                                    sm={imageData != null && imageData.length > 0 ? 3 : 2}
                                    className="d-flex jc-center mt-1 Width-100 h-100"
                                >
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label" required={true}>
                                            Source
                                        </InputLabel>
                                        <Select
                                            className="lightInputFields"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={source}
                                            onChange={handleChangeSource}
                                            disabled={
                                                props.disableOnceCompleted
                                                    ? props.disableOnceCompleted
                                                    : props.disableValue
                                            }
                                        >
                                            {itemsSource.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            {
                                //<Grid item xs={12} sm={2} className="d-flex Width-100 mt-1">
                                //    <Autocomplete
                                //        className="lightInputFields hover-lightblue"
                                //        id="combo-box-demo"
                                //        value={sourceObj}
                                //        inputValue={sourceText}
                                //        options={itemsSource}
                                //        getOptionLabel={option => option.name ? option.name : ""}
                                //        onChange={handleChangeSource}
                                //        onInputChange={handleChangeSourceInput}
                                //        renderInput={
                                //            (params) =>
                                //                <TextField {...params}
                                //                    label="Source"
                                //                    variant="filled"
                                //                    required
                                //                />
                                //        }
                                //    />
                                //</Grid>
                            }
                            {imageData != null && imageData.length > 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    className="d-flex jc-center mt-1 Width-100 h-100"
                                >
                                    <img
                                        src={`data:image/*;base64,${imageData}`}
                                        alt="Candidate Image"
                                    />
                                    <br />
                                    {props.roleId == 3 &&
                                        (props.status == null ||
                                            props.status == "" ||
                                            props.status == 3) ? (
                                        <>
                                            <Tooltip title="Delete Image" placement="top">
                                                <IconButton onClick={deleteImageHandler}>
                                                    <RemoveCircleOutlineOutlinedIcon
                                                        color="secondary"
                                                        style={{ fontSize: 15 }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            ) : (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className="d-flex jc-center mt-1 Width-100 h-100"
                                    >
                                        <TextField
                                            id="employeePhotoChooseFile"
                                            type="file"
                                            className="lightInputFields mb-4"
                                            required
                                            label="Select Photo"
                                            onChange={changeFileHandler}
                                            error={imageHelper.length !== 0}
                                            helperText={imageHelper}
                                            inputProps={{ accept: "image/*", multiple: false }}
                                            key={inputFileValue}
                                            variant="filled"
                                            disabled={props.roleId === 3 ? false : true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                // <-- This is where the error text is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title={
                                                                imageFile
                                                                    ? "Image selected successfully"
                                                                    : GetSelectPhotoTooltip()
                                                            }
                                                            placement="bottom"
                                                        >
                                                            {imageFile ? (
                                                                <CheckCircleOutlineOutlined
                                                                    style={{ color: "green" }}
                                                                />
                                                            ) : (
                                                                <span
                                                                    style={{ color: "red", fontSize: "10px" }}
                                                                >
                                                                    <i>
                                                                        <strong>
                                                                            read
                                                                            <br />
                                                                            me
                                                                        </strong>
                                                                    </i>
                                                                </span>
                                                                // <ErrorOutlineOutlined
                                                                //   style={{ color: "red" }}
                                                                // />
                                                            )}
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        className="d-flex jc-center mt-1 Width-100 h-100"
                                    >
                                        <Button
                                            variant="contained"
                                            className="ns-btn-upload"
                                            startIcon={<CloudUploadIcon style={{ color: "white" }} />}
                                            onClick={uploadHandler}
                                            //disabled={disabledUploadButton}
                                            disabled={
                                                props.roleId === 3 ? (imageFile ? false : true) : true
                                            }
                                        >
                                            Upload Photo
                                        </Button>
                                    </Grid>
                                </>
                            )}
                            <>
                                {/* <Grid item xs={6} sm={2} className={classesGridUploadButtons}>
                {imageData != null && imageData.length > 0 ? (
                  <React.Fragment>
                    <img
                      src={`data:image/*;base64,${imageData}`}
                      alt="Candidate Image"
                    />
                    <br />
                    {props.roleId == 3 &&
                    (props.status == null ||
                      props.status == "" ||
                      props.status == 3) ? (
                      <React.Fragment>
                        <Tooltip title="Delete Image" placement="top">
                          <span>
                            <Button onClick={deleteImageHandler}>
                              <span className="mr-2">
                                <RemoveCircleOutlineOutlinedIcon
                                  color="secondary"
                                  style={{ fontSize: 15 }}
                                />
                              </span>
                            </Button>
                          </span>
                        </Tooltip>
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <ImageUploader
                    className="h-50 p-1"
                    withIcon={false}
                    withPreview={false}
                    withLabel={false}
                    //label="Max file size: 5MB Accepted: .jpg, .jpeg, .png, .webp, .tiff, .tif, .bmp"
                    buttonText="SELECT PHOTO"
                    buttonClassName="ns-btn-upload"
                    onChange={changeFileHandler}
                    imgExtension={[".jpg", ".jpeg"]}
                    maxFileSize={153600}
                    singleImage={true}
                    disabled={props.roleId === 3 ? false : true}
                  />
                )}
              </Grid>
              <React.Fragment>
                {imageFile != null && imageFile.size > 0 ? (
                  <Grid item xs={2} sm={2} className="Width-100 mr-12-ve">
                    <Tooltip
                      title="Image Selected Successfully"
                      placement="top"
                    >
                      <DoneOutlinedIcon
                        style={{
                          fontSize: 30,
                          marginLeft: "10rem",
                          marginTop: "1.75rem",
                          color: "#6cc070",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </React.Fragment>
              {imageData != null && imageData.length > 0 ? (
                <React.Fragment></React.Fragment>
              ) : (
                <Grid item xs={4} sm={2} className="Width-100 mt-1 mr-6">
                  <Tooltip title="Upload Photo" placement="top">
                     <span>
                         <Button className="mt-1" onClick={uploadHandler} disabled={disabledUploadButton}>
                             <span className=""><PublishOutlinedIcon fontSize="large" /></span>
                         </Button>
                     </span>
                  </Tooltip>
                  <Button
                    variant="contained"
                    className="ns-btn-upload"
                    startIcon={<CloudUploadIcon style={{ color: "white" }} />}
                    onClick={uploadHandler}
                    //disabled={disabledUploadButton}
                    disabled={props.roleId === 3 ? false : true}
                  >
                    Upload Photo
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} className="d-flex fd-column mt-1">
              {imageData != null && imageData.length > 0 ? (
                <React.Fragment></React.Fragment>
              ) : (
                <React.Fragment>
                  <p className="f-12 d-flex jc-flex-end mt-2-ve mr-2 clr-red">
                    <i>*Mandatory : Employee must upload a self photgraph</i>
                  </p>
                  <p className="clr-dark-blue f-14 d-flex jc-flex-end m-0 mt-1-ve">
                    <i>
                      **Note : File size: 150KB Max; File Type: .jpg, .jpeg
                      <br />
                      <Tooltip title={GetSelectPhotoTooltip()} placement="left">
                        <span className="text-underline clr-red">
                          Specification Of Photograph
                        </span>
                      </Tooltip>
                    </i>
                  </p>
                </React.Fragment>
              )} */}
                            </>
                        </Grid>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadClear"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlertConfirm}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">Cancel</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? <Loader /> : <></>}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
