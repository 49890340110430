import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import ComputerIcon from "@material-ui/icons/Computer";
import ClassIcon from "@material-ui/icons/Class";
import DescriptionIcon from "@material-ui/icons/Description";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PaymentIcon from "@material-ui/icons/Payment";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PrivacyNotice from "../pages/PrivacyNotice";
import { Typography } from "@material-ui/core";
import UserManualToRaiseTicket from "../common/SupportDoc/User_Manual.pdf";


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  marginTop: {
    marginTop: 20,
  },
});

export default function SwipeableDrawerSection(props) {
  const anchor = "left";
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  var roleId;
  var listTag;
  if (props.loginDetails != undefined) {
    roleId = props.loginDetails[0].roleId;
  } else {
    roleId = "";
  }
  if (roleId != "" && roleId == 1) {
    listTag = (
      <List>
        <ListItem button component={Link} to="/AdminOrRecruiterData">
          <ListItemIcon>
            <HomeIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Home"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/ResetPassword">
          <ListItemIcon>
            <VpnKeyIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Change Password"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/UserRegistration">
          <ListItemIcon>
            <PersonAddIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"New User Registration"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/CandidateData">
          <ListItemIcon>
            <LibraryBooksIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"On-Boarding Dashboard"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/CandidateRegistration">
          <ListItemIcon>
            <LibraryAddIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"New Candidate Registration"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/WorkdayData">
          <ListItemIcon>
            <AssignmentIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Workday Data"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        {/* <ListItem button component={Link} to="/AdminOrRecruiterData">
                    <ListItemIcon>
                        <AssessmentIcon className="clr-dark-green" />
                    </ListItemIcon>
                    <ListItemText primary={"Candidate Hiring Manager Report"} className={"clr-dark-green fw-bold list-item-text"} />
                </ListItem> */}
      </List>
    );
  } else if (roleId != "" && (roleId == 2 || roleId == 4)) {
    listTag = (
      <List>
        <ListItem button component={Link} to="/CandidateData">
          <ListItemIcon>
            <LibraryBooksIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Home"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/ResetPassword">
          <ListItemIcon>
            <VpnKeyIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Change Password"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/CandidateRegistration">
          <ListItemIcon>
            <LibraryAddIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"New Candidate Registration"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
      </List>
    );
  } else if (roleId != "" && roleId == 3) {
    listTag = (
      <List>
        <ListItem button component={Link} to="/ApplicationForm">
          <ListItemIcon>
            <ClassIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Home"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/ResetPassword">
          <ListItemIcon>
            <VpnKeyIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Change Password"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
      </List>
    );
  } else {
    listTag = (
      <List>
        <ListItem button component={Link} to="/AdminOrRecruiterData">
          <ListItemIcon>
            <HomeIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Home"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>
        <ListItem button component={Link} to="/ResetPassword">
          <ListItemIcon>
            <VpnKeyIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Change Password"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>

        <ListItem button component={Link} to="/UserRegistration">
          <ListItemIcon>
            <PersonAddIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"New User Registration"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>

        <ListItem button component={Link} to="/CandidateRegistration">
          <ListItemIcon>
            <LibraryAddIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"New Candidate Registration"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>

        <ListItem button component={Link} to="/ApplicationForm">
          <ListItemIcon>
            <ClassIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Application Form"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>

        <ListItem button component={Link} to="/CandidateData">
          <ListItemIcon>
            <LibraryBooksIcon className="clr-dark-green" />
          </ListItemIcon>
          <ListItemText
            primary={"Candidate Data"}
            className={"clr-dark-green fw-bold list-item-text"}
          />
        </ListItem>

        {/* <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <AssessmentIcon className="clr-dark-green" />
                    </ListItemIcon>
                    <ListItemText primary={"Candidate Hiring Manager Report"} className={"clr-dark-green fw-bold list-item-text"} />
                </ListItem> */}
      </List>
    );
  }
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box className="d-flex jc-center">
        <a href="/" rel="">
          <img
            src={"../assets/images/sie-logo-petrol-rgb.png"}
            alt="Siemens Company Logo"
            className="siemens-logo-dark"
          />
        </a>
      </Box>

      <Divider />
      {listTag}
      <div className="drawer-footer-text">
        <Typography className="clr-dark-green fw-bold f-14" variant="subtitle1">
          &#169; Siemens 2021
          <a href="/PrivacyNotice" target="_blank">
            <span className="ml-2">Privacy</span>
          </a>
          <a href={UserManualToRaiseTicket} target="_blank">
            <span className="ml-2">Support</span>
          </a>
        </Typography>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <Button className="jc-start pt-13" onClick={toggleDrawer(anchor, true)}>
          <img
            src={"../assets/icons/burger-white.svg"}
            height="20"
            width="20"
            alt="Menu Icon"
            className="burger-icon"
          />
        </Button>
        <SwipeableDrawer
          className="width-0"
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
