import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios'
import { Alert } from '@material-ui/lab';
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                '&$disabled': {
                    backgroundColor: '#454545 !important',
                },
            }
        },
        MuiFilledInput: {
            root: {
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8"
                },
                "&$disabled": {
                    "pointerEvent": "none",
                    "color": "#707080",
                    "backgroundColor": "#001035",
                    "border": "2px solid #707070",
                    "borderRadius": "0",
                    "&:before": {
                        "borderBottom": "1px solid #001035",
                    },
                    "&:hover:before": {
                        "borderBottom": "1px solid #001035",
                        "borderBottomStyle": "solid"
                    }
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                backgroundColor: "#E8F8F8",

            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                '&$disabled': {
                    backgroundColor: '#454545 !important',
                },
            },
        },
        MuiTabs: {
            scroller: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            }
        },

        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC"
                }
            }
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68"
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {

                    backgroundColor: "#CCF5F5",
                }
            }
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949"
                },
                "&:after": {
                    borderBottom: "2px solid #002949"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2"
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5"
            }
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            }
        },
        MuiPaper: {
            rounded: {
                border: `1px solid #FFFFFF`,
                marginBottom: "5px",
                color: "#000028"

            }
        },
        MuiToolbar: {
            root: {
                background: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none"
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`
                }
            },
            menuItem: {
                color: "#FFFFFF"
            },
            selectIcon: {
                color: "#00CCCC"
            },
            input: {
                backgroundColor: "#000028"
            }
        },
        MuiTypography: {
            caption: {
                color: "#000028"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#000028",
                "&$focused": {
                    color: "#000028",
                },
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
                "& div:hover": {
                    color: "#00CCCC"
                }
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            }
        },
        MuiTableCell: {
            root: {
                color: "#000028 !important",
                background: "#E8F8F8 !important",
                padding: "10px"
            },
            body: {
                background: "#FFFFFF",
                color: "#000028"
            },
            head: {
                lineHeight: "1rem"
            }
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px"
            }
        },
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#000028"
            },
            container: {
                background: "#000028"
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF"
            },
            switchHeader: {
                color: "#FFFFFF"
            },
            daysHeader: {
                color: "#FFFFFF"
            },
            dayLabel: {
                color: "#FFFFFF"
            }
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF"
            }
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: "#000028"
        //    }
        //},
        MuiDialogActions: {
            root: {
                background: "#000028"
            }
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF"
            }
        },
        MuiTable: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MTableToolbar: {
            searchField: {
                paddingLeft: "0px !important",
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: "0.6rem !important",
                backgroundColor: "#ebfbfb !important",
                marginBottom: "-7px ​!important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important"
            },
        },
    }
});
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const api = axios.create({
    baseURL: `https://reqres.in/api`
})




function ProfessionalTraining(props) {

    //var columns = [
    //    { title: "id", field: "id", hidden: true },
    //    { title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.first_name} /> },
    //    { title: "First name", field: "first_name" },
    //    { title: "Last name", field: "last_name" },
    //    { title: "email", field: "email" }
    //]
    const [data, setData] = useState([]); //table data

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState('');
    const [openClassName, setClassName] = useState('');
    const [openType, setOpenType] = useState('');

    const handleErrorClose = (e) => {
        setIserror(false);
    }

    var day = 60 * 60 * 24 * 1000;

    const handleClose = () => {

        GetTrainingData(props.id);
        setOpen(false);
        //window.location.reload()
    };

    function ValidatePeriodFrom(rowData) {
        var isValid = true;
        if (rowData.periodFrom === null || rowData.periodFrom === '') {
            isValid = false;
        }
        else {
            const dateFrom = new Date(rowData.periodFrom);
            const dateTo = new Date(rowData.periodTo);
            if (rowData.periodTo !== null && rowData.periodTo !== '' && dateFrom > dateTo) {
                isValid = false;
            }
            else {
                isValid = true;
            }
        }
        return isValid;
    }
    function ValidatePeriodTo(rowData) {
        var isValid = true;
        if (rowData.periodTo === null || rowData.periodTo === '') {
            isValid = false;
        }
        else {
            const dateFrom = new Date(rowData.periodFrom);
            const dateTo = new Date(rowData.periodTo);
            if (rowData.periodFrom !== null && rowData.periodFrom !== '' && dateFrom > dateTo) {
                isValid = false;
            }
            else {
                isValid = true;
            }
        }
        return isValid;
    }

    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    var columns = [
        { title: 'Row ID', field: 'employeeTrainingId', hidden: true },
        { title: 'Applicant ID*', field: 'applicantId', hidden: true },
        { title: 'Course Name*', field: 'course', validate: rowData => (rowData.course === null || rowData.course === '') ? { isValid: false, helperText: 'Mandatory Field' } : true },
        {
            title: 'Period From*', field: 'periodFrom', type: 'date', dateSetting: { locale: 'fr-ca' },
            validate: rowData => ValidatePeriodFrom(rowData),
            editComponent: props => {
                const dateFrom = props.rowData.periodFrom;
                const dateTo = props.rowData.periodTo;
                let errorText = "";
                var errorState = false;
                if (dateFrom === null || dateFrom === '') {
                    errorState = true;
                    errorText = "Mandatory Field";
                }
                else {
                    const dtFrom = new Date(dateFrom);
                    const dtTo = new Date(dateTo);
                    if (dateTo !== null && dateTo !== '' && dtFrom > dtTo) {
                        errorText = "Date must be less than Period To";
                        errorState = true;
                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}
                        locale={props.dateTimePickerLocalization}>
                        <DatePicker
                            value={(props.value == undefined ? null : props.value)}
                            onChange={(date) => {
                                props.onChange(date);
                            }}
                            format={'yyyy-MM-dd'}
                            clearable
                            disableFuture
                            //label="Period From"
                            //required={true}
                            helperText={errorText}
                            error={errorState}
                        />
                    </MuiPickersUtilsProvider>
                )
            }
        },
        {
            title: 'Period To*', field: 'periodTo', type: 'date', dateSetting: { locale: 'fr-ca' },
            validate: rowData => ValidatePeriodTo(rowData),
            editComponent: props => {
                const dateFrom = props.rowData.periodFrom;
                const dateTo = props.rowData.periodTo;
                let errorText = "";
                var errorState = false;
                if (dateTo === null || dateTo === '') {
                    errorText = "Mandatory Field";
                    errorState = true;
                }
                else {
                    const dtFrom = new Date(dateFrom);
                    const dtTo = new Date(dateTo);
                    if (dateFrom !== null && dateFrom !== '' && dtFrom > dtTo) {
                        errorText = "Date must be greater than Period From";
                        errorState = true;
                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}
                        locale={props.dateTimePickerLocalization}>
                        <DatePicker
                            value={(props.value == undefined ? null : props.value)}
                            onChange={(date) => {
                                props.onChange(date);
                            }}
                            format={'yyyy-MM-dd'}
                            minDate={props.rowData.periodFrom ? new Date(props.rowData.periodFrom).getTime() + day : null}
                            clearable
                            //label="Period To"
                            //required={true}
                            helperText={errorText}
                            error={errorState}
                        />
                    </MuiPickersUtilsProvider>
                )
            }
        },
        { title: 'Institute/Organization*', field: 'trainingInstitute', validate: rowData => (rowData.trainingInstitute === null || rowData.trainingInstitute === '') ? { isValid: false, helperText: 'Mandatory Field' } : true },
        { title: 'Details Of Training', field: 'detailsOfTraining' },

    ];

    //for error handling
    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    //useEffect(() => {
    //    api.get("/users")
    //        .then(res => {
    //            setData(res.data.data)
    //        })
    //        .catch(error => {
    //            console.log("Error")
    //        })
    //}, [])

    function GetTrainingData(id) {
        const token = props.token;
        axios.get('/PreHiringForm/GetTrainingData/' + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then((res) => {
                setData(res.data);

            })
            .catch(error => {
                console.log("Error");
            })
    }

    useEffect(() => {
        if (props.token) {
            if (props.id != null && props.id != undefined) {
                GetTrainingData(props.id);
            }
        }

    }, [props.id, props.token])

    //useEffect(() => {
    //    if (props.id != null && props.id != undefined) {
    //        axios.get('/PreHiringForm/GetTrainingData/' + props.id)
    //            .then((res) => {
    //                setData(res.data)
    //            })
    //            .catch(error => {
    //                console.log("Error")
    //            })
    //    }

    //}, [props.id])

    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        //validation
        let errorList = []
        //if (newData.course === "" || newData.course === null) {
        //    errorList.push("Please enter Course Name")
        //}
        //if (newData.periodFrom === "" || newData.periodFrom === null) {
        //    errorList.push("Please enter Period From")
        //}
        //if (newData.periodTo === "" || newData.periodTo === null) {
        //    errorList.push("Please enter Period To")
        //}
        //if (newData.trainingInstitute === "" || newData.trainingInstitute === null) {
        //    errorList.push("Please enter Institute/Organization")
        //}
        ////if (newData.detailsOfTraining === "") {
        ////    errorList.push("Please enter Details Of Training")
        ////}

        //if (newData.periodFrom != "" && newData.periodFrom != null && newData.periodTo != "" && newData.periodTo != null) {
        //    var formattedDateJoining = formatDate(newData.periodFrom);
        //    var formattedDateLeaving = formatDate(newData.periodTo);
        //    var dateJoining = new Date(formattedDateJoining);
        //    var dateLeaving = new Date(formattedDateLeaving);
        //    if (dateJoining > dateLeaving) {
        //        errorList.push("Period From date cannot be greater than Period To date")
        //    }
        //}

        newData.employeeTrainingId = oldData.tableData.id;
        newData.applicantId = props.id;
        newData.userId = props.loginDetail[0].userid;

        if (errorList.length < 1) {
            //api.patch("/users/" + newData.id, newData)
            //    .then(res => {
            //        const dataUpdate = [...data];
            //        const index = oldData.tableData.id;
            //        dataUpdate[index] = newData;
            //        setData([...dataUpdate]);
            //        resolve()
            //        setIserror(false)
            //        setErrorMessages([])
            //    })
            const token = props.token;
            axios.put('/PreHiringForm/UpdateTrainingData', newData, { headers: { 'Authorization': 'Bearer ' + token } })

                .then(response => {
                    if (response.data.response) {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-success");
                        setOpenType("Success");
                        resolve();
                        setIserror(false);
                        setErrorMessages([]);
                    }
                    else {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-error");
                        setOpenType("Error");
                        //resolve();
                        reject();
                        setIserror(false);
                        setErrorMessages([]);
                    }
                })

                .catch(error => {
                    reject();
                    setErrorMessages(["Update failed! Server error" + error]);
                    setIserror(true);
                    //resolve();
                    
                })
        } else {
            //setErrorMessages(errorList);
            //setIserror(true);
            //resolve()
            reject();

        }

    }

    const handleRowAdd = (newData, resolve, reject) => {
        //validation
        let errorList = []
        if (newData.course === undefined || newData.course === "" || newData.course === null) {
            errorList.push("Please enter Course Name")
        }
        if (newData.periodFrom === undefined || newData.periodFrom === "" || newData.periodFrom === null) {
            errorList.push("Please enter Period From")
        }
        if (newData.periodTo === undefined || newData.periodTo === "" || newData.periodTo === null) {
            errorList.push("Please enter Period To")
        }
        if (newData.trainingInstitute === undefined || newData.trainingInstitute === "" || newData.trainingInstitute === null) {
            errorList.push("Please enter Institute/Organization")
        }
        //if (newData.detailsOfTraining === undefined) {
        //    errorList.push("Please enter Details Of Training")
        //}

        if (newData.periodFrom != "" && newData.periodFrom != undefined && newData.periodFrom != null && newData.periodTo != "" && newData.periodTo != undefined && newData.periodTo != null) {
            var formattedDateJoining = formatDate(newData.periodFrom);
            var formattedDateLeaving = formatDate(newData.periodTo);
            var dateJoining = new Date(formattedDateJoining);
            var dateLeaving = new Date(formattedDateLeaving);
            if (dateJoining > dateLeaving) {
                errorList.push("Period From date cannot be greater than Period To date")
            }
        }

        newData.applicantId = props.id;
        newData.userId = props.loginDetail[0].userid;

        if (errorList.length < 1) { //no error
            //api.post("/users", newData)
            //    .then(res => {
            //        let dataToAdd = [...data];
            //        dataToAdd.push(newData);
            //        setData(dataToAdd);
            //        resolve()
            //        setErrorMessages([])
            //        setIserror(false)
            //    })
            const token = props.token;
            axios.post('/PreHiringForm/SaveTrainingData', newData, { headers: { 'Authorization': 'Bearer ' + token } })
                .then(response => {
                    if (response.data.response) {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-success");
                        setOpenType("Success");
                        resolve();
                        setIserror(false);
                        setErrorMessages([]);
                    }
                    else {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-error");
                        setOpenType("Error");
                        //resolve();
                        reject();
                        setIserror(false);
                        setErrorMessages([]);
                    }
                    // window.location.reload()
                })

                .catch(error => {
                    reject();
                    setErrorMessages(["Cannot add data. Server error!"]);
                    setIserror(true);
                    //resolve()
                    
                })
        } else {
            setErrorMessages(errorList);
            setIserror(true);
            //resolve()
            reject();
        }


    }

    const handleRowDelete = (oldData, resolve, reject) => {

        //api.delete("/users/" + oldData.id)
        //    .then(res => {
        //        const dataDelete = [...data];
        //        const index = oldData.tableData.id;
        //        dataDelete.splice(index, 1);
        //        setData([...dataDelete]);
        //        resolve()
        //    })

        oldData.employeeTrainingId = oldData.tableData.id;
        oldData.applicantId = props.id;
        const token = props.token;
        axios.put('/PreHiringForm/DeleteTrainingData', oldData, { headers: { 'Authorization': 'Bearer ' + token } })

            .then(response => {
                if (response.data.response) {
                    setOpen(true);
                    setOpenAlert(response.data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    resolve();
                    setIserror(false);
                    setErrorMessages([]);
                }
                else {
                    setOpen(true);
                    setOpenAlert(response.data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //resolve();
                    reject();
                    setIserror(false);
                    setErrorMessages([]);
                }
                // window.location.reload()
            })
            .catch(error => {
                reject();
                setErrorMessages(["Delete failed! Server error"]);
                setIserror(true);
                //resolve()
               
            })
    }


    return (
        <ThemeProvider theme={theme}>
            <div className="width-100">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div>
                            {iserror &&
                                <Alert severity="error" onClose={handleErrorClose}>
                                    {errorMessages.map((msg, i) => {
                                        return <div key={i}>{msg}</div>
                                    })}
                                </Alert>
                            }
                        </div>
                        <MaterialTable
                            title="Professional Training"
                            columns={columns}
                            data={data}
                            icons={tableIcons}
                            localization={{
                                header: {
                                    actions: ''
                                }
                            }}
                            options={{
                                search: false,
                                toolbar: props.disableValue === true ? false : true
                            }}
                            editable={{
                                isEditable: rowData => props.disableValue === false,
                                isDeletable: rowData => props.disableValue === false,

                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(newData, oldData, resolve, reject);

                                    }),
                                onRowAdd: (newData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newData, resolve, reject)
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldData, resolve, reject)
                                    }),
                            }}
                        />
                    </Grid>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                        id="templateUploadSuccess">
                        <DialogTitle className={openClassName} id="draggable-dialog-title">
                            {openType}
                        </DialogTitle>
                        <DialogContent className="bg-content">
                            <DialogContentText className="clr-dark-blue">
                                {openAlert}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="bg-popup">
                            <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                                <span className="f-16 fw-bold">OK</span>
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default ProfessionalTraining;