import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function AssignOrganizarion(props) {
  var id;
  const [assignOrganizationDetailsId, setAssignOrganizationDetailsId] =
    useState(0);
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [companyARE, setCompanyARE] = useState("");
  const [companyAREObj, setCompanyAREObj] = useState("");
  const [companyAREText, setCompanyAREText] = useState("");
  const [costCenter, setCostCenter] = useState("");
  const [costCenterObj, setCostCenterObj] = useState("");
  const [costCenterText, setCostCenterText] = useState("");
  const [inCompanyManager, setInCompanyManager] = useState("");
  const [costCenterName, setCostCenterName] = useState("");

  //Error States and Msg
  const [errorCompanyARE, setErrorCompanyARE] = useState(false);
  const [errorTextCompanyARE, setErrorTextCompanyARE] = useState("");
  const [errorCostCenter, setErrorCostCenter] = useState(false);
  const [errorTextCostCenter, setErrorTextCostCenter] = useState("");

  //Alerts
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleChangeCompanyARE = (event, newValue) => {
    setCompanyAREObj(newValue);
    setCompanyARE(newValue ? newValue.id : 0);

    id = newValue == undefined ? 0 : newValue.id;
    getCostCenterDropdown(id);
    setCostCenter("");
    setCostCenterObj("");
    setCostCenterText("");
  };
  const handleChangeCompanyAREInput = (event, newInputValue) => {
    setCompanyAREText(newInputValue);

    setCostCenter("");
    setCostCenterObj("");
    setCostCenterText("");
  };

  const handleChangeCostCenter = (event, newValue) => {
    setCostCenterObj(newValue);
    setCostCenter(newValue ? newValue.id : 0);
    if (
      costCenterName == "" ||
      costCenterName == null ||
      costCenterName == undefined
    ) {
      setCostCenterName(newValue.name);
    }
  };
  const handleChangeCostCenterInput = (event, newInputValue) => {
    setCostCenterText(newInputValue);
  };

  var formData = {
    ApplicantId: props.id,
    UserId: props.userId,
    CompanyId: companyARE,
    CostCenterId: costCenter,
    CostCenter: costCenterName,
    CompanyManagerGid: inCompanyManager,
  };

  function ValidateFormFields(data) {
    var a = false,
      b = false;
    if (data.CompanyId == null || data.CompanyId == "") {
      setErrorCompanyARE(true);
      setErrorTextCompanyARE("Mandatory field");
      a = true;
    } else {
      setErrorCompanyARE(false);
      setErrorTextCompanyARE("");
      a = false;
    }
    //if (data.CostCenterId == null || data.CostCenterId == '') {
    //    setErrorCostCenter(true);
    //    setErrorTextCostCenter("Mandatory field");
    //    b = true;
    //}
    //else {
    //    setErrorCostCenter(false);
    //    setErrorTextCostCenter("");
    //    b = false;
    //}
    if (data.CostCenter == null || data.CostCenter == "") {
      setErrorCostCenter(true);
      setErrorTextCostCenter("Mandatory field");
      b = true;
    } else {
      setErrorCostCenter(false);
      setErrorTextCostCenter("");
      b = false;
    }
    if (a || b) {
      return false;
    } else {
      return true;
    }
  }

  function clearStates() {
    if (props.roleId && props.roleId == 1) {
      setCompanyARE("");
      setCompanyAREObj("");
      setCompanyAREText("");
      setCostCenterName("");
    }
    setCostCenter("");
    setCostCenterObj("");
    setCostCenterText("");
    setInCompanyManager("");
  }

  const submitHandler = (e) => {
    setIsLoader(true);
    formData = {
      ApplicantId: props.id,
      UserId: props.userId,
      CompanyId: companyARE,
      CostCenterId: costCenter,
      CostCenter: costCenterName,
      CompanyManagerGid: inCompanyManager,
    };
    if (ValidateFormFields(formData)) {
      if (
        assignOrganizationDetailsId != null &&
        assignOrganizationDetailsId > 0 &&
        assignOrganizationDetailsId != undefined
      ) {
        UpdateAssignOrgaDetails(formData);
      } else {
        SaveAssignOrgaDetails(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  const clearHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //Save Assign Organization Details
  function SaveAssignOrgaDetails(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/SaveAssignOrgaDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Update Assign Organization Details
  function UpdateAssignOrgaDetails(formData) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/UpdateAssignOrgaDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Set All States
  function SetStateAsPerFetched(data) {
    setAssignOrganizationDetailsId(data.assignOrganizationDetailsId);
    setCompanyARE(data.companyId ? data.companyId : props.ApplicantARE);
    if (data.companyObj[0].id > 0) {
      setCompanyAREObj(data.companyObj[0]);
      setCompanyAREText(data.companyObj[0].name);
    }
    getCostCenterDropdown(data.companyId ? data.companyId : props.ApplicantARE);
    setCostCenter(data.costCenterId);
    if (data.costCenterObj[0].id > 0) {
      setCostCenterObj(data.costCenterObj[0]);
      setCostCenterText(data.costCenterObj[0].name);
    }
    setCostCenterName(data.costCenterName);
    setInCompanyManager(data.companyManagerGid);
    setIsLoader(false);
  }

  //Change Button Text
  function getButtonTextSave(id) {
    id != null && id > 0 && id != undefined
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  //fetch Company ARE Codes
  const [itemCompanyCodes, setItemCompanyCodes] = useState([]);
  async function getCompanyCodes() {
    const token = props.token;
    const response = await fetch("HRDetails/GetCompaniesARE", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemCompanyCodes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemCompanyCodes(jsonItems);
  }
  async function getCompanyObjById(id) {
    const token = props.token;
    return await fetch("HRDetails/GetCompaniesARE", {
      headers: { Authorization: "Bearer " + token },
    }).then((response) => {
      return response
        .json()
        .then((data) => {
          return data.find((c) => c.id === id);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  //fetch Cost Center
  const [itemCostCenter, setItemCostCenter] = useState([]);
  //async function getCostCenter() {
  //    const token = props.token;
  //    const response = await fetch("HRDetails/GetCostCenter", { headers: { 'Authorization': 'Bearer ' + token } });
  //    const jsonItems = await response.json();
  //    //setItemCostCenter(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
  //    setItemCostCenter(jsonItems);
  //}
  async function getCostCenterDropdown(id) {
    const token = props.token;
    const response = await fetch("HRDetails/GetCostCenterFromARE/" + id, {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemCostCenter(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemCostCenter(jsonItems);
  }

  //Get Assign Organization Details On Page Load
  function getAssignOrgaDetailsOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("HRDetails/GetAssignOrgaDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Get Applicant ARE Details On Page Load
  function getApplicantAREDetailsByAREId(id) {
    const token = props.token;
    fetch("HRDetails/GetCompanyAREById/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.id > 0) {
          setCompanyAREObj(data);
          setCompanyAREText(data.name);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  useEffect(() => {
    if (props.token) {
      getCompanyCodes();
      //getCostCenter();
      //getCostCenterDropdown(id);
    }
  }, [props.token]);

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getAssignOrgaDetailsOnLoad(props.id);
      }

      //if (props.id != undefined && props.id > 0) {
      //    getApplicantAREDetails(props.id);
      //}
      if (props.ApplicantARE) {
        getApplicantAREDetailsByAREId(props.ApplicantARE);
      }
    }
    getButtonTextSave(assignOrganizationDetailsId);
  }, [props.id, assignOrganizationDetailsId, props.token, props.ApplicantARE]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <table>
              <tbody>
                <tr className="d-flex jc-space-bt f-12 font-italic mt-1">
                  <td></td>
                  <td className="clr-dark-blue">
                    {/***Note : Please provide GID on <span className="fw-bold">In Company Manager</span> text field*/}
                  </td>
                  <td className="clr-red">
                    *Note : To change{" "}
                    <span className="fw-bold">Company ARE</span> please click on{" "}
                    <span className="fw-bold">Edit Candidate</span> Data from{" "}
                    <span className="fw-bold">
                      Candidate Data or On-Boarding Dashboard
                    </span>{" "}
                    List
                  </td>
                </tr>
              </tbody>
            </table>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label" required={true}>CompanyARE</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={companyARE}
                  //        onChange={handleChangeCompanyARE}
                  //    >
                  //        {itemCompanyCodes.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={companyAREObj}
                  inputValue={companyAREText}
                  options={itemCompanyCodes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeCompanyARE}
                  onInputChange={handleChangeCompanyAREInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company ARE"
                      variant="filled"
                      required
                      error={errorCompanyARE}
                      helperText={errorTextCompanyARE}
                    />
                  )}
                  //disabled={props.disableValue}
                  disabled={true}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label" required={true}>Cost Center</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={costCenter}
                  //        onChange={handleChangeCostCenter}
                  //    >
                  //        {itemCostCenter.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={costCenterObj}
                  inputValue={costCenterText}
                  options={itemCostCenter}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeCostCenter}
                  onInputChange={handleChangeCostCenterInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cost Center"
                      variant="filled"
                      //required
                      //error={errorCostCenter}
                      //helperText={errorTextCostCenter}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="costCenterName"
                  required={true}
                  disabled={true}
                  fullWidth
                  label="Cost Center"
                  variant="filled"
                  name="Cost Center"
                  value={costCenterName}
                  //onChange={(e) => setCostCenterName(e.target.value)}
                  error={errorCostCenter}
                  helperText={errorTextCostCenter}
                />
              </Grid>

              {/*<Grid item xs={12} sm={4} className="d-flex jc-center Width-100">*/}
              {/*    <TextField className="lightInputFields mt-1"*/}
              {/*        id="inCompanyManager"*/}
              {/*        fullWidth*/}
              {/*        label="Company Manager(GID)"*/}
              {/*        variant="filled"*/}
              {/*        name="In Company Manager"*/}
              {/*        value={inCompanyManager}*/}
              {/*        onChange={(e) => setInCompanyManager(e.target.value)}*/}
              {/*    />*/}
              {/*</Grid>*/}
              <Grid item xs={12} className="d-flex jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={clearHandler}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={submitHandler}
                >
                  <span>{buttonTextSave}</span>
                </Button>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
