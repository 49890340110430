import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import Box from "@material-ui/core/Box";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import FileSaver from "file-saver";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormDatePicker from "../pages/components/DatePicker";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "000028",
        color: "#000028",
        '&[class*="MTableToolbar-highlight"]': {
          color: "#000028 !important",
          backgroundColor: "#E8F8F8 !important",
        },
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "transparent",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "1px",
        fontSize: "11px !important",
        //border: "1px solid #CCC"
        alignItems: "center",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#000028",
      },
    },
    //MuiPickersBasePicker: {
    //    pickerView: {
    //        background: "#FFFFFF"
    //    }
    //},
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "",
      },
      pickerView: {
        background: "#FFFFFF",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
      root: {
        minWidth: "40px !important",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
        fontSize: "13px",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

export default function WorkdayData(props) {
  const [data, setData] = useState([]);
  const [doj, setDOJ] = useState(null);
  const [are, setARE] = useState("");
  const [areObj, setAREObj] = useState("");
  const [areText, setAREText] = useState("");

  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [rowdt, setRowdt] = useState([]);

  const handleClose = () => {
    setOpen(false);
    if (openType === "Success") {
      setIsLoader(true);
      GetCandidateList(searchFilterData);
    }
  };
  const handleConfirm = () => {
    setOpenConfirm(false);
    if (openType === "EIB Download Confirmation") {
      DownloadWorkdayData(rowdt);
    }
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleChangeARE = (event, newValue) => {
    setAREObj(newValue);
    setARE(newValue ? newValue.id : 0);
  };
  const handleChangeAREInput = (event, newInputValue) => {
    setAREText(newInputValue);
  };

  function dateChangeEvent(dateValue, label) {
    if (label === "Date Of Joining") {
      setDOJ(dateValue);
    }
  }

  const clickEventClearSearchFields = () => {
    setARE("");
    setAREObj("");
    setAREText("");
    setDOJ(null);
  };
  var searchTexts = {
    AREId: are,
    DOJ: doj,
  };
  var searchFilterData = JSON.stringify(searchTexts);
  const clickEventSearchFilter = async () => {
    setIsLoader(true);
    searchTexts = {
      AREId: are,
      DOJ: doj,
    };
    searchFilterData = JSON.stringify(searchTexts);
    GetCandidateList(searchFilterData);
  };

  async function GetCandidateList(searchFilterData) {
    const token = props.token;
    const options = {
      method: "post",
      //type: 'async',
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: searchFilterData,
    };
    await fetch("Workday/GetAllCandidateDataForWorkday/", options)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch((error) => console.log("Error:", error));
    setIsLoader(false);
  }

  function GetDownloadButton() {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          className="ns-btn-secondary-light"
          startIcon={<CloudDownloadIcon style={{ color: "white" }} />}
        >
          Download Data
        </Button>
      </React.Fragment>
    );
  }
  function DownloadWDConfirm(data) {
    setOpenConfirm(true);
    setOpenAlertConfirm(
      "EIB Data can be downloaded only once. Are you sure you want to download data of selected candidate(s)?"
    );
    setClassName("clr-dark-blue bg-alert");
    setOpenType("EIB Download Confirmation");
    setRowdt(data);
  }
  async function DownloadWorkdayData(data) {
    setIsLoader(true);
    var arrIds = [];
    for (var i = 0; i < data.length; i++) {
      arrIds.push(data[i].employeeId);
    }
    var ids = arrIds.toString();
    //window.location.href = 'Workday/FetchWorkdayDataDownload/' + ids;
    const token = props.token;
    const options = {
      responseType: "arraybuffer",
      headers: { Authorization: "Bearer " + token },
      params: {
        ids: ids,
          userId: props.userEncryptedId,
      },
    };
    /*axios.get('Workday/FetchWorkdayDataDownload/' + ids, { responseType: 'arraybuffer', headers: { 'Authorization': 'Bearer ' + token } })*/
    axios
      .get("Workday/FetchWorkdayDataDownload/", options)
      .then(function (response) {
        if (response.status) {
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(blob, "WorkdayData.xlsx");
          setIsLoader(false);
          setOpen(true);
          setOpenAlert("Workday Data Downloaded Successfully.");
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert("Workday Data Download Failed");
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        setOpen(true);
        setOpenAlert(error);
        setClassName("clr-dark-blue bg-error");
        setOpenType("Error");
      });
  }
  const [itemsARE, setItemsARE] = useState([]);
  async function getAREs() {
    const token = props.token;
    const response = await fetch("Workday/GetAllAREs", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsStatus(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsARE(jsonItems);
  }
  useEffect(() => {
    setIsLoader(true);
    if (props.token) {
      GetCandidateList();
      getAREs();
    } else {
      setIsLoader(false);
    }
  }, [props.token]);

  var columns = [
    { title: "ApplicantId", field: "employeeId", hidden: true },
    { title: "Candidate Name", field: "fullName" },
    { title: "Candidate Email", field: "userEmail" },
    { title: "Candidate ARE", field: "are" },
    { title: "Date Of Joining", field: "doj" },
    { title: "DownloadFlag", field: "isWddownloaded", hidden: true },
    { title: "Downloaded On", field: "wddownloadedOn" },
  ];
  const actions = [
    {
      icon: () => GetDownloadButton(),
      //icon: 'delete',
      tooltip: "Download Workday Data",
      onClick: (event, rowData) => DownloadWDConfirm(rowData),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="master-layout-data">
        <Box paddingTop={{ xs: "5rem", sm: "2rem" }}>
          <h3 className="clr-dark-blue d-flex jc-center">
            Candidate List For Workday
          </h3>
        </Box>
        <Grid
          container
          spacing={1}
          className="d-flex fd-column px-1 box-shadow mb-2 bdr-radius"
        >
          <Grid container spacing={2} className="d-flex jc-flex-end fd-row">
            <Grid item xs={12} sm={2} className="d-flex Width-100">
              <Autocomplete
                className="lightInputFields hover-lightblue mt-1"
                id="combo-box-demo"
                value={areObj}
                inputValue={areText}
                options={itemsARE}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                onChange={handleChangeARE}
                onInputChange={handleChangeAREInput}
                renderInput={(params) => (
                  <TextField {...params} label="ARE" variant="filled" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} className="d-flex Width-100">
              <FormDatePicker
                placeholder="Date Of Joining"
                label="Date Of Joining"
                value={doj}
                onChange={dateChangeEvent}
                disablefuturedate={false}
                disablepastdate={false}
                disabled={false}
                required={false}
                error={false}
                helperText={null}
              />
            </Grid>
            <Grid item xs={12} sm={1} className="d-flex Width-100">
              <Tooltip title="Search" placement="top">
                <Button className="mt-1" onClick={clickEventSearchFilter}>
                  <span>
                    <SearchIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip title="Clear Search Fields" placement="top">
                <span>
                  <Button
                    className="mt-1"
                    onClick={clickEventClearSearchFields}
                  >
                    <span>
                      <ClearIcon fontSize="large" />
                    </span>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt-1 mb-2">
            <MaterialTable
              title="List of Candidates"
              actions={actions}
              columns={columns}
              data={data}
              //data={sampledata}
              icons={tableIcons}
              options={{
                search: false,
                selection: true,
                selectionProps: (rowData) => {
                  rowData.tableData.disabled = rowData.isWddownloaded;
                  return {
                    disabled: rowData.isWddownloaded,
                  };
                },
              }}
              components={{
                overlayLoading: () => (
                  <React.Fragment>
                    <CircularProgress />
                  </React.Fragment>
                ),
              }}
              //editable={{
              //    onRowUpdate: (newData, oldData) =>
              //        new Promise((resolve) => {
              //            handleRowUpdate(newData, oldData, resolve);

              //        }),
              //    onRowAdd: (newData) =>
              //        new Promise((resolve) => {
              //            handleRowAdd(newData, resolve)
              //        }),
              //    onRowDelete: (oldData) =>
              //        new Promise((resolve) => {
              //            handleRowDelete(oldData, resolve)
              //        }),
              //}}
            />
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle
              className={openClassName}
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
            >
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadClear"
          >
            <DialogTitle
              className={openClassName}
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
            >
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlertConfirm}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleConfirm}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={handleCloseConfirm}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">Cancel</span>
              </Button>
            </DialogActions>
          </Dialog>
          {isLoader ? <Loader /> : <></>}
        </Grid>
      </div>
    </ThemeProvider>
  );
}
