import { Button, createMuiTheme } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import React, { forwardRef } from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import axios from "axios";
import moment from "moment";

export const themeDHL = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        minHeight: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
      paper: {
        backgroundColor: "#E8F8F8",
        borderRadius: "20px",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#E8F8F8",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        //height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "#E8F8F8",
        color: "#000028",
        '&[class*="header-light-bg"]': {
          color: "#000028 !important",
          backgroundColor: "#000028 !important",
        },
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "10px",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#000028",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersMonth: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    // MuiDialogActions: {
    //   root: {
    //     background: "#000028",
    //   },
    // },
    MuiDialogActions: {
      root: {
        background: "#00CCCC",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        fontSize: "0.6rem",
        margin: "-15px 0 0 0 !important",
        color: "red !important",
        lineHeight: "1.66",
        letterSpacing: 0,
      },
      // root: {
      //   position: "absolute",
      //   marginTop: "-5px",
      //   marginLeft: "-2px",
      //   fontSize: "10px",
      // },
    },
  },
});
export const tableIcons = {
  // Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Add: forwardRef((props, ref) => (
    <Button
      {...props}
      ref={ref}
      variant="contained"
      className="ns-btn-secondary-light"
    >
      Add
    </Button>
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} style={{ color: `var(--red)` }} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} style={{ color: `var(--dark-blue)` }} ref={ref} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export const getRoundUptoXDecimals = (value, decimal) => {
  let factor = Math.pow(10, decimal);
  return Math.round(value * factor + Number.EPSILON) / factor;
};
export const offerLetterTemplates = [
  { id: 1, name: "Siemens Ltd. DTE" },
  { id: 2, name: "Siemens Ltd. GTE" },
  { id: 3, name: "Siemens Ltd. PGTE" },
];
export const appointmentLetterTemplates = [
  { id: 1, name: "Siemens Ltd. DTE" },
  { id: 2, name: "Siemens Ltd. GTE" },
  { id: 3, name: "Siemens Ltd. PGTE" },
];
export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const getSysteDateInddMMyyyyDotSeperated = () => {
  let sysdt = new Date();
  let dd = sysdt.getDate();
  let mm = sysdt.getMonth();
  let yyyy = sysdt.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};
export function getCustomFormattedDate(date, type) {
  let dtFormat = new Date(date);
  let dd = dtFormat.getDate();
  let mm = dtFormat.getMonth() + 1;
  let yyyy = dtFormat.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // return type === "DOB"
  //   ? dd + "-" + mm + "-" + yyyy
  //   : dd + "." + mm + "." + yyyy;
  return moment(date).format("DD-MMM-YYYY");
}
export const renderValueWithThousandSeparator = (value) => {
  return !value
    ? value
    : value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

//YAT
//export const baseDHLApiURL = "https://dhlwebapi-uat.azurewebsites.net/api";
//export const baseDHLApiURL = "https://dhlwebapiuat.hrapp-in.siemens.cloud/api";

//PROD
export const baseDHLApiURL = "https://dhlwebapi.hrapp-in.siemens.cloud/api";

export function INRInWords(value) {
  let fraction = Math.round(frac(value) * 100);
  let f_text = "";
  if (fraction > 0) {
    f_text = " Rupee(s) And " + convert_number(fraction) + " Paise";
  }
  // return convert_number(value) + f_text + (fraction > 0 ? "" : " Rupee(s)");
  return convert_number(value) + f_text;
}
function frac(f) {
  return f % 1;
}
function convert_number(number) {
  if (number < 0 || number > 9999999999) {
    return "NUMBER OUT OF RANGE!";
  }
  let Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  let kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  let Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  let Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  let tn = Math.floor(number / 10);
  let one = Math.floor(number % 10);
  let res = "";

  if (Gn > 0) {
    res += convert_number(Gn) + " Crore";
  }
  if (kn > 0) {
    res += (res == "" ? "" : " ") + convert_number(kn) + " Lakh";
  }
  if (Hn > 0) {
    res += (res == "" ? "" : " ") + convert_number(Hn) + " Thousand";
  }

  if (Dn) {
    res += (res == "" ? "" : " ") + convert_number(Dn) + " Hundred";
  }

  let ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "NineTeen",
  ];
  let tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Fourty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (tn > 0 || one > 0) {
    if (!(res == "")) {
      res += " And ";
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += "-" + ones[one];
      }
    }
  }
  if (res == "") {
    res = "zero";
  }
  return res;
}
export const tagsToBeReplaced = [
  "{FirstName}",
  "{LastName}",
  "{SystemDate}",
  "{JobTitle}",
  "{PositionLevel}",
  "{Grip}",
  "{Division}",
  "{ARECode}",
  "{AREName}",
  "{BasicSalary}",
  "{BFP}",
  "{TotalAllowances}",
  "{BP}",
  "{PF}",
  "{Gratuity}",
  "{TotalRetirals}",
  "{TFPpm}",
  "{TFPpa}",
  "{PP}",
  "{TargetPay}",
  "{ELpa}",
  "{WOSAF}",
  "{WSAF}",
  "{BasicSalarySAF}",
  "{BFPSAF}",
  "{TotalAllowancesSAF}",
  "{BPSAF}",
  "{PFSAF}",
  "{SAF}",
  "{GratuitySAF}",
  "{TotalRetiralsSAF}",
  "{TFPpmSAF}",
  "{TFPpaSAF}",
  "{PPSAF}",
  "{TargetPaySAF}",
  "{ELpaSAF}",
  "{HIPCpa}",
  "{PAICpa}",
  "{TLICpa}",
  "{EDLIC}",
  "{ESIC}",
  "{SiemensLogo}",
  "{TAName}",
  "{TAEmail}",
  "{Address}",
  "{JoiningDate}",
  "{BusinessUnit}",
  "{JoiningBonusClause}",
  "{JobLocation}",
  "{OfficeLocation}",
  "{DateOfBirth}",
  "{Folder ID/Person ID}",
  "{PositionLevelClauseGBS}",
  "{PositionLevelClauseAdvanta}",
  "{BusinessSignatoryName}",
  "{FullBusinessSignature}",
  "{HR SignatoryName}",
  "{TargetPayInWords}",
  "{FullHRSignature}",
  "{FullBusinessDesignation}",
  "{FullHRDesignation}",
  "{JoiningBonusAmount}",
  "{JoiningBonusAmountInWords}",
  "{NumberOfYears}",
  "{HRA}",
  "{BPA}",
   "{TotalAllowance}",
   "{BPPM}",
   "{TotalFixedPaypm}",
   "{TotalFixedPaypa}",
];
export function getDateBefore15YearsFromNow() {
  let date = new Date();
  date = date.setFullYear(date.getFullYear() - 15);
  return moment(date).format("DD-MMM-YYYY");
}
