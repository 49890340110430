import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function EditServicesDate(props) {
  const [serviceDatesId, setServiceDatesId] = useState("");
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [continuousServiceDates, setContinuousServiceDates] = useState(null);
  const [companyServiceDate, setCompanyServiceDate] = useState(null);
  const [seniorityDate, setSeniorityDate] = useState(null);

  //Error States and Msg
  const [errorContinuousServiceDate, setErrorContinuousServiceDate] =
    useState(false);
  const [errorTextContinuousServiceDate, setErrorTextContinuousServiceDate] =
    useState("");
  const [errorCompanyServiceDate, setErrorCompanyServiceDate] = useState(false);
  const [errorTextCompanyServiceDate, setErrorTextCompanyServiceDate] =
    useState("");
  const [errorSeniorityDate, setErrorSeniorityDate] = useState(false);
  const [errorTextSeniorityDate, setErrorTextSeniorityDate] = useState("");

  //Alerts
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  function dateChangeEvent(dateValue, label) {
    if (label === "Continuous Service Date") {
      setContinuousServiceDates(dateValue);
    } else if (label === "Company Service date") {
      setCompanyServiceDate(dateValue);
    } else if (label === "Seniority Date") {
      setSeniorityDate(dateValue);
    }
  }

  var formData = {
    ApplicantId: props.id,
    UserId: props.userId,
    ContinuousServiceDates: continuousServiceDates,
    CompanyServiceDate: companyServiceDate,
    SeniorityDate: seniorityDate,
  };

  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false;
    if (
      data.ContinuousServiceDates == null ||
      data.ContinuousServiceDates == ""
    ) {
      setErrorCompanyServiceDate(true);
      setErrorTextContinuousServiceDate("Mandatory field");
      a = true;
    } else {
      setErrorCompanyServiceDate(false);
      setErrorTextContinuousServiceDate("");
      a = false;
    }
    if (data.CompanyServiceDate == null || data.CompanyServiceDate == "") {
      setErrorCompanyServiceDate(true);
      setErrorTextCompanyServiceDate("Mandatory field");
      b = true;
    } else {
      setErrorCompanyServiceDate(false);
      setErrorTextCompanyServiceDate("");
      b = false;
    }
    if (data.SeniorityDate == null || data.SeniorityDate == "") {
      setErrorSeniorityDate(true);
      setErrorTextSeniorityDate("Mandatory field");
      c = true;
    } else {
      setErrorSeniorityDate(false);
      setErrorTextSeniorityDate("");
      c = false;
    }
    if (a || b || c) {
      return false;
    } else {
      return true;
    }
  }

  function clearStates() {
    if (props.roleId && props.roleId == 1) {
      setContinuousServiceDates(null);
      setCompanyServiceDate(null);
      setSeniorityDate(null);
    }
  }

  const submitHandler = (e) => {
    setIsLoader(true);
    formData = {
      ApplicantId: props.id,
      UserId: props.userId,
      ContinuousServiceDates: continuousServiceDates,
      CompanyServiceDate: companyServiceDate,
      SeniorityDate: seniorityDate,
    };
    if (ValidateFormFields(formData)) {
      if (
        serviceDatesId != null &&
        serviceDatesId > 0 &&
        serviceDatesId != undefined
      ) {
        UpdateServiceDates(formData);
      } else {
        SaveServiceDates(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  const clearHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  //Save Service Date Details
  function SaveServiceDates(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/SaveServiceDates/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Update Service Date Details
  function UpdateServiceDates(formData) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/UpdateServiceDates/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Set All States
  function SetStateAsPerFetched(data) {
    setServiceDatesId(data.serviceDatesId);
    setContinuousServiceDates(data.continuousServiceDates);
    setCompanyServiceDate(data.companyServiceDate);
    setSeniorityDate(data.seniorityDate);
    setIsLoader(false);
  }

  //Change Button Text
  function getButtonTextSave(id) {
    id != null && id > 0 && id != undefined
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //Get Service Dates On Page Load
  function getServiceDatesOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("HRDetails/GetServiceDates/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getServiceDatesOnLoad(props.id);
      }
    }
    getButtonTextSave(serviceDatesId);
  }, [props.id, serviceDatesId, props.token, props.HireDate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Continuous Service Date
                                                (defaults to Hire date if blank) *"
                  label="Continuous Service Date"
                  required={true}
                  value={continuousServiceDates}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                  error={errorContinuousServiceDate}
                  helperText={errorTextContinuousServiceDate}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Company Service date
                                                (defaults to Hire date if blank) *"
                  label="Company Service date"
                  required={true}
                  value={companyServiceDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                  error={errorCompanyServiceDate}
                  helperText={errorTextCompanyServiceDate}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Seniority Date
                                                (defaults to Hire date if blank) *"
                  label="Seniority Date"
                  required={true}
                  value={seniorityDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                  error={errorSeniorityDate}
                  helperText={errorTextSeniorityDate}
                />
              </Grid>

              <Grid item xs={12} className="d-flex jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={clearHandler}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={submitHandler}
                >
                  <span>{buttonTextSave}</span>
                </Button>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
