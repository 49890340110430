import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import SwipeableDrawerSection from "./SwipeDrawer";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Box from "@material-ui/core/Box";
import UserManualToRaiseTicket from "../common/SupportDoc/User_Manual.pdf";
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
export default function AppHeader(props) {
  const anchor = "left";
  //let pageURL = {
  //    url1: "http://localhost:5000/",
  //    url2: "http://localhost:5000/ForgetPassword",
  //    url3: "http://localhost:5000/OTPPage"
  //};
  let defaultUrl = window.location.href;
  var arrURL = defaultUrl.split("/");
  let pageURLPart = {
    part1: "ForgetPassword",
    part2: "OTPPage",
    part3: "ResetPassword",
    part4: "UnauthorizedAccess",
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></div>
  );

  const logout = () => {
    props.logoutClick();
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className="no-print">
        <Toolbar className={"header-light-bg"}>
          {
            //defaultUrl === pageURL.url1 || defaultUrl === pageURL.url2 || defaultUrl === pageURL.url3
            arrURL[3] === "" ||
            arrURL[3] === pageURLPart.part1 ||
            arrURL[3] === pageURLPart.part2 ||
            (arrURL[3] === pageURLPart.part3 &&
              props.loginDetails[0].forgetPassword) ||
            arrURL[3] === pageURLPart.part4 ||
            props.loginDetails[0].LoginFlag != 1 ? (
              <React.Fragment> </React.Fragment>
            ) : (
              <React.Fragment key={anchor}>
                <SwipeableDrawerSection
                  loginDetails={props.loginDetails}
                  className="height-inherit bg-dark-blue"
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  roleId={props.roleId}
                >
                  {list(anchor)}
                </SwipeableDrawerSection>
              </React.Fragment>
            )
          }

          <div
            className={
              "d-flex  flex-wrap pt-8 jc-space-bt align-item-center width-100"
            }
          >
            <a href="/" rel="">
              <img
                src={"../assets/images/siemens-logo-white.svg"}
                alt="Siemens Company Logo"
                width="125"
                height="20"
                className="siemens-logo"
              />
            </a>

            <div className="mr-3 appheader-text">
              Shubharambh - The Start Of Journey
            </div>

            <div className="d-flex-header">
              {
                //defaultUrl === pageURL.url1 || defaultUrl === pageURL.url2 || defaultUrl === pageURL.url3
                arrURL[3] === "" ||
                arrURL[3] === pageURLPart.part1 ||
                arrURL[3] === pageURLPart.part2 ||
                (arrURL[3] === pageURLPart.part3 &&
                  props.loginDetails[0].forgetPassword) ||
                arrURL[3] === pageURLPart.part4 ? (
                  <React.Fragment> </React.Fragment>
                ) : (
                  <React.Fragment key={anchor}>
                    <p className="m-0 clr-white cstLineHeight">
                      Welcome {props.loginDetails[0].userfirstname}
                    </p>
                    <Tooltip title="View Support Document">
                      <IconButton
                        href={UserManualToRaiseTicket}
                        target="_blank"
                        className="appbar-icon"
                      >
                        <ContactSupportOutlinedIcon
                          style={{ color: "#2EE6E2" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                )
              }
              {/* <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" target="_blank" rel="noopener noreferrer">
                                    <Tooltip title="Help">
                                        <IconButton aria-label="Help">
                                            <HelpOutlineOutlinedIcon style={{ color: "#2EE6E2" }} className="appbar-icon" />
                                        </IconButton>
                                    </Tooltip>
                                </a>*/}
              {!props.otpDetails[0].isOTPVerified ||
              arrURL[3] === pageURLPart.part4 ? (
                <React.Fragment> </React.Fragment>
              ) : arrURL[3] === pageURLPart.part3 &&
                props.loginDetails[0].forgetPassword ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Tooltip title="Logout" onClick={logout}>
                    <IconButton className="appbar-icon pl-0" aria-label="Logout" a onClick={logout}>
                      {/*<ExitToAppIcon style={{ color: "#2EE6E2" }} className="appbar-icon" />*/}
                      {<PowerSettingsNewIcon style={{ color: "#2EE6E2" }} />}
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </div>
          </div>
        </Toolbar>
        {/* <div className="d-flex my-0 pl-1 bg-dark-blue jc-start">
          <Box display={{ xs: "block", sm: "none" }}>
            {
              //defaultUrl === pageURL.url1 || defaultUrl === pageURL.url2 || defaultUrl === pageURL.url3
              arrURL[3] === "" ||
              arrURL[3] === pageURLPart.part1 ||
              arrURL[3] === pageURLPart.part2 ||
              arrURL[3] === pageURLPart.part4 ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment key={anchor}>
                  <p className="clr-text-header mr-1 my-0">
                    Welcome {props.loginDetails[0].userfirstname}
                  </p>
                </React.Fragment>
              )
            }
          </Box>
        </div>
       */}
      </AppBar>
    </React.Fragment>
  );
}
