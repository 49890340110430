import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function SHPL(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
            
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [personalData, setPersonalData] = useState([]);
    const personalDataTemp = [];

    const [departmentData, setDepartmentData] = useState([]);
    const departmentDataTemp = [];

    const [imageData, setImageData] = useState(null);



    function SetStateAsPerFetched(data) {
       
        //Personal Data
        personalDataTemp.push({
            'firstName': data.personalData.firstName, 'middleName': data.personalData.middleName ? data.personalData.middleName : "",
            'lastName': data.personalData.lastName, 'fatherName': data.personalData.fatherName ? data.personalData.fatherName : (data.personalData.spouseName ? data.personalData.spouseName : ""),
            'gender': data.personalData.genders[0].name, 'maritalStatus': data.personalData.maritalStatuses[0].name,
            'dateOfBirth': data.personalData.dateOfBirthStr, 'registrationNo': data.personalData.registrationNo ? data.personalData.registrationNo : ""

        });

        setPersonalData(personalDataTemp);

        //Department and Sector Data
        departmentDataTemp.push({
            'department': data.department ? data.department : "", 'sector': data.sector ? data.sector : "", 
            'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr

        });

        setDepartmentData(departmentDataTemp);
        setImageData(data.imageContent);


    }


    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);

            }
        }

    }, [encryptedId, props.token]);

    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">
            <div id="donotprintdiv" className="d-flex jc-flex-end mt-1 mb-1 mr-2 no-print" >
                <button className="width-10" onClick={() => window.print()}>Print</button>
            </div>
            <div className="pb-2 mb-1">
                <div className="width-100 d-flex jc-space-bt mt-1">
                    <div className="pl-0">
                        <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo Healthineers" className="siemens-logo-dark" />
                        { /* <img src="../Images/Siemens_Logo_Unit_For_Web_Page.jpg" height="40px" width="180px"></img> */}
                    </div>
                    <div className="mr-1">
                        <img src={`data:image/*;base64,${imageData}`} alt="Candidate Image" height="150" width="140" /><br />
                    </div>
                </div>
                <div className="d-flex jc-center mt-5">
                    <table className="width-90 mt-3 bdr-black border-collapse vertical-middle">
                        <caption className="mb-1 f-12 fw-bold">
                            {/*ON BOARDING DOCUMENTS*/}COVER PAGE
                        </caption>
                        <tbody className="f-12">
                            <tr className="h-40">
                                <td className="bdr-black width-20">NAME</td>
                                <td className="bdr-black">
                                    {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                                </td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">GID</td>
                                <td className="bdr-black">{props.gid}</td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">DATE OF JOINING
                                </td>
                                <td className="bdr-black">
                                    {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}
                                </td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">DIVISION</td>
                                <td className="bdr-black">
                                    {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}
                                </td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">PLACE OF WORK
                                </td>
                                <td className="bdr-black">
                                    {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}
                                </td>
                                </tr>
                        </tbody>
                    </table>
                </div>
                <div className="ml-1 mt-2 pagebreak">
                    <div className="width-100 d-flex jc-space-bt mt-1">
                        <div className="mb-1">
                            <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo Healthineers" className="siemens-logo-dark" />
                        </div>
                    </div>
                    <span className="bdr-header">EMPLOYEES
                        DECLARATION
                    </span>
                    <p className="para-pjq">Duty to Abide by the Law and various Compliance Guidelines</p>
                    <table className="border-collapse width-90 vertical-middle mt-2">
                        <tbody className="f-12">
                            <tr className="h-20">
                                <td className="pl-1">I,  {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                                </td>
                                {/*<td className="pl-1">Roll no :  {personalData.map(personalDataNew => <span>{personalDataNew.registrationNo}</span>)}*/}
                                {/*</td>*/}
                                <td className="pl-1">GID : <span>{props.gid}</span>
                                </td>
                                <td className="pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                            </tr>
                            { /* <tr className="h-40">
                                   <td className="pl-1">
                                    Sector :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.sector}</span>)}</td> 
                                <td className="pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                                </tr>*/ }
                        </tbody>
                    </table>
                    <p className="para-pjq">Hereby confirm that I have been familiarized with the duty to abide by the law in various Compliance
                    Guidelines in
                    performing my professional duties. I have also been familiarized with the rules thereto. The compliance
                    Guidelines and
                    the rules can be viewed via the following homepage intranet.siemens.com/ compliance – helpdesk
                    </p>
                    <p className="para-pjq">I am aware that Corporate Management disapproves of violating the law and that in case of violations,
                    regardless of the
                    sanctions provided by the law; I would be liable to disciplinary measures and consequences.
                    </p>

                    <div className="d-flex mt-2 ml-1 f-12 jc-space-bt">
                        <span className="pt-1">Place: {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}</span>
                        <span className="mt-1 mr-2">______________________________<br />(Signature)</span>
                    </div>
                    <span className="ml-1 f-12">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                    { /* <p className="mt-2 f-12">Name of the Manager (To whom you report administratively)</p> */}
                    <br /> <br />
                    <p className="para-pjq">Original to be submitted to the respective UNIT/PER.<br />
                Original to the filled in individuals personal file, by UNIT/PER.</p>
                </div>

                <div className="ml-1 mt-2 pagebreak">
                    <div className="width-100 d-flex jc-space-bt mt-1">
                        <div className="mb-1 ml-negem-1">
                            <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo Healthineers" className="siemens-logo-dark" />
                        </div>
                    </div>
                    <span className="bdr-header ">EMPLOYEES
                    DECLARATION
                    </span>
                    <p className="para-pjq">Duty to Abide by the Law and the Business Conduct Guidelines</p>
                    <table className="border-collapse width-90 vertical-middle mt-2">
                        <tbody className="f-12">
                            <tr className="h-20">
                                <td className="pl-1">I,  {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                                </td>
                                {/*<td className="pl-1">Roll no :  {personalData.map(personalDataNew => <span>{personalDataNew.registrationNo}</span>)}*/}
                                {/*</td>*/}
                                <td className="pl-1">GID : <span>{props.gid}</span>
                                </td>
                                <td className="pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                            </tr>
                            { /* <tr className="h-40">
                                <td className="pl-1">
                                    Sector :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.sector}</span>)}</td>
                                <td className="pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    <p className="para-pjq">Hereby confirm that I have been familiarized with the duty to abide by the law in performing my
                    professional duties.
                    </p>
                    <p className="para-pjq">I am aware that Corporate Management disapproves of violating the law and that in case of violations,
                    regardless of the
                    sanctions provided by the law. I would be liable to disciplinary measures and consequences to the
                    employment
                    relationship.
                    </p>
                    <p className="para-pjq">I will further respect the provisions of the Business Conduct Guidelines. I have been familiarized with
                    the supplementary rules thereto.
                    </p>
                    <p className="para-pjq">
                        The Business Conduct Guidelines and the supplementary rules can be viewed via the following homepage
                        intranet.siemens.com/compliance – helpdesk.
                    </p>
                    <p className="para-pjq">
                        A hard copy of the Business conduct Guidelines can be obtained from the personnel department upon
                        request.
                    </p>
                    <div className="d-flex mt-2 ml-1 jc-space-bt f-12" >
                        <span className="pt-1">Place: {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}</span>
                        <span className="mt-1 mr-2">______________________________<br />(Signature)</span>
                    </div>
                    <span className="ml-1 f-12">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                    { /* <p className="mt-2 f-12">Name of the Manager (To whom you report administratively)</p> */}
                    <br /> <br />
                    <p className="para-pjq">Original to be submitted to the respective UNIT/PER.<br />
                    Original to the filled in individuals personal file, by UNIT/PER. <br />Renewed every two years.
                    </p>
                </div>
            </div>
            </div>
    );

}