import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
    TextField,
    createMuiTheme,
    ThemeProvider,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader";

const theme = createMuiTheme({
    overrides: {
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#E8F8F8",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "#E8F8F8",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&$hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                border: `2px solid #2EE6E2`,
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2",
            },
        },
        //MuiPickersBasePicker: {
        //    pickerView: {
        //        background: "#FFFFFF"
        //    }
        //},
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPickersBasePicker: {
            container: {
                background: "",
            },
            pickerView: {
                background: "#FFFFFF",
            },
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
        MuiFormHelperText: {
            contained: {
                backgroundColor: "white",
                marginBottom: "-10px !important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important",
            },
        },
    },
});

export default function ResetPassword(props) {
    const [newPassword, setNewPassword] = useState(null);
    const [newAlertText, setNewAlertText] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [confirmAlertText, setConfirmAlertText] = useState("");

    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleMouseDownShowNewPassword = () =>
        setShowNewPassword(!showNewPassword);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () =>
        setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownShowConfirmPassword = () =>
        setShowConfirmPassword(!showConfirmPassword);

    const [hideTickNew, setHideTickNew] = useState(true);
    const [hideExclaimationNew, setHideExclaimationNew] = useState(true);
    const [hideTickConfirm, setHideTickConfirm] = useState(true);
    const [hideExclaimationConfirm, setHideExclaimationConfirm] = useState(true);

    const handleChnageNewPassword = (e) => {
        setNewPassword(e.target.value);
        setNewAlertText("");
        setConfirmAlertText("");
        if (e.target.value != "") {
            if (IsPolicyValidate(e.target.value)) {
                setHideExclaimationNew(true);
                setHideTickNew(false);
            } else {
                setHideExclaimationNew(false);
                setHideTickNew(true);
            }
        } else {
            setHideExclaimationNew(true);
            setHideTickNew(true);
        }
    };

    const handleChnageConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmAlertText("");
        setNewAlertText("");
        if (e.target.value != "") {
            if (IsPolicyValidate(e.target.value)) {
                setHideExclaimationConfirm(true);
                setHideTickConfirm(false);
            } else {
                setHideExclaimationConfirm(false);
                setHideTickConfirm(true);
            }
        } else {
            setHideExclaimationConfirm(true);
            setHideTickConfirm(true);
        }
    };

    //Alerts
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [isLoader, setIsLoader] = useState(false);

    const handleClose = () => {
        if (openType === "Success") {
            setOpen(false);
            props.logout();
        } else {
            setOpen(false);
        }
    };

    var formData = {
        UserId: props.userEncryptedId,
        UserPassword: newPassword,
    };

    var policyText =
        "1. Password must be of atleast 12 characters. 2. Password must contains atleast 1 capital letter. 3. Password must contains atleast 1 number. 4. Password must contains atleast 1 special character(e.g. !#$%&'()*+,-./:;<=>?@[]^`{|}~)";

    function GetPasswordPolicyAsTooltipTitle() {
        return (
            <React.Fragment>
                <ListItemText primary="1. Password must be of atleast 12 characters" />
                <ListItemText primary="2. Password must contains atleast 1 capital letter" />
                <ListItemText primary="3. Password must contains atleast 1 number" />
                <ListItemText primary="4. Password must contains atleast 1 special character(e.g. !#$%&'()*+,-./:;<=>?@[\]^`{|}~)" />
            </React.Fragment>
        );
    }

    function clearStates() {
        setNewPassword("");
        setNewAlertText("");
        setConfirmPassword("");
        setConfirmAlertText("");
        setHideExclaimationConfirm(true);
        setHideTickConfirm(true);
        setHideExclaimationNew(true);
        setHideTickNew(true);
    }

    const clearHandler = () => {
        clearStates();
    };

    function IsPolicyValidate(value) {
        const minMaxLength = /^[\s\S]{12,}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/,
            special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;
        var result =
            upper.test(value) &&
            lower.test(value) &&
            number.test(value) &&
            special.test(value) &&
            minMaxLength.test(value);
        return result;
    }

    const submitHandler = () => {
        setIsLoader(true);
        setHideExclaimationConfirm(true);
        setHideTickConfirm(true);
        setHideExclaimationNew(true);
        setHideTickNew(true);
        if (
            newPassword != null &&
            newPassword != "" &&
            confirmPassword != null &&
            confirmPassword != "" &&
            newPassword === confirmPassword
        ) {
            setNewAlertText("");
            setConfirmAlertText("");
            if (IsPolicyValidate(newPassword)) {
                const token = props.token;

                formData = {
                    UserIdencrypted: props.userEncryptedId,
                    UserPassword: newPassword,
                    UserEmail: props.loginDetails[0].username,
                };
                const options = {
                    method: "post",
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(formData),
                };
                fetch("Login/ChangePassword/", options)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.response) {
                            setIsLoader(false);
                            setOpen(true);
                            setOpenAlert(data.responseMsg);
                            setClassName("clr-dark-blue bg-success");
                            setOpenType("Success");
                        } else {
                            setIsLoader(false);
                            setOpen(true);
                            setOpenAlert(data.responseMsg);
                            setClassName("clr-dark-blue bg-error");
                            setOpenType("Error");
                        }
                    })
                    .catch((error) => {
                        setIsLoader(false);
                        console.log("Error:", error);
                    });
            } else {
                setIsLoader(false);
                setOpen(true);
                setOpenAlert(policyText);
                setClassName("clr-dark-blue bg-alert");
                setOpenType("Alert");
            }
        } else {
            if (
                (newPassword == null || newPassword == "") &&
                (confirmPassword == null || confirmPassword == "")
            ) {
                setIsLoader(false);
                setNewAlertText("Field can not be blank");
                setConfirmAlertText("Field can not be blank");
            } else if (newPassword == null || newPassword == "") {
                setIsLoader(false);
                setNewAlertText("Field can not be blank");
            } else if (confirmPassword == null || confirmPassword == "") {
                setIsLoader(false);
                setConfirmAlertText("Field can not be blank");
            } else if (newPassword !== confirmPassword) {
                setIsLoader(false);
                setNewAlertText("Password mismatch");
                setConfirmAlertText("Password mismatch");
            }
        }
    };

    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", enterKeyDown);

        return () => {
            document.removeEventListener("keydown", enterKeyDown);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div className="master-layout-block">
                <form className="d-flex jc-center mt-5">
                    <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className="d-flex fd-column mt-4 bdr-radius box-shadow align-item-center"
                    >
                        <h3 className="clr-dark-blue  d-flex jc-center">Change Password</h3>
                        {
                            //<Grid item xs={10} sm={10} className="d-flex jc-center Width-100">
                            //    <TextField className="mt-1 lightInputFields"
                            //        id="oldPassword"
                            //        label="Old Password"
                            //        variant="filled"
                            //        fullWidth
                            //        name="Old Password"
                            //        onChange={(e) => setOldPassword(e.target.value)}
                            //    />
                            //</Grid>
                        }
                        <Grid item xs={10} sm={10} className="d-flex jc-center Width-100">
                            <TextField
                                className="lightInputFields mt-1"
                                id="newPassword"
                                type={showNewPassword ? "text" : "password"}
                                fullWidth
                                required
                                label="New Password"
                                variant="filled"
                                name="New Password"
                                value={newPassword}
                                onChange={handleChnageNewPassword}
                                helperText={newAlertText}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownShowNewPassword}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <React.Fragment>
                                {hideTickNew ? (
                                    <React.Fragment></React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Tooltip title="strong" placement="right">
                                            <CheckCircleOutlineOutlinedIcon
                                                style={{
                                                    fontSize: 20,
                                                    marginRight: "-20px",
                                                    marginTop: "2.5rem",
                                                    color: "#6cc070",
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                            <React.Fragment>
                                {hideExclaimationNew ? (
                                    <React.Fragment></React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Tooltip
                                            title={GetPasswordPolicyAsTooltipTitle()}
                                            placement="right"
                                        >
                                            <ErrorOutlineOutlinedIcon
                                                style={{
                                                    fontSize: 20,
                                                    marginRight: "-20px",
                                                    marginTop: "2.5rem",
                                                    color: "red",
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </Grid>
                        <Grid item xs={10} sm={10} className="d-flex jc-center Width-100">
                            <TextField
                                className="lightInputFields mt-1"
                                id="confirmNewPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                label="Confirm New Password"
                                variant="filled"
                                fullWidth
                                required
                                name="Confirm New Password"
                                value={confirmPassword}
                                onChange={handleChnageConfirmPassword}
                                helperText={confirmAlertText}
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownShowConfirmPassword}
                                            >
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <React.Fragment>
                                {hideTickConfirm ? (
                                    <React.Fragment></React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Tooltip title="strong" placement="right">
                                            <CheckCircleOutlineOutlinedIcon
                                                style={{
                                                    fontSize: 20,
                                                    marginRight: "-20px",
                                                    marginTop: "2.5rem",
                                                    color: "#6cc070",
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                            <React.Fragment>
                                {hideExclaimationConfirm ? (
                                    <React.Fragment></React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Tooltip
                                            title={GetPasswordPolicyAsTooltipTitle()}
                                            placement="right"
                                        >
                                            <ErrorOutlineOutlinedIcon
                                                style={{
                                                    fontSize: 20,
                                                    marginRight: "-20px",
                                                    marginTop: "2.5rem",
                                                    color: "red",
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </Grid>

                        <Grid item xs={10} sm={10} className="d-flex mb-2 jc-space-bt">
                            <Button
                                item
                                xs={6}
                                sm={5}
                                className="ns-btn-primary mt-2 mr-1"
                                onClick={clearHandler}
                            >
                                <span>Clear Fields</span>
                            </Button>
                            <Button
                                item
                                xs={6}
                                sm={5}
                                className="ns-btn-primary mt-2 ml-1"
                                onClick={submitHandler}
                            >
                                <span>Change Password</span>
                            </Button>
                        </Grid>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? <Loader /> : <></>}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
