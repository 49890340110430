import { Box, Button, Grid, TextField, ThemeProvider } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CanididateDetailsDHL from "./CandidateDetailsDHL";
import CompensationDetailsDHL from "./CompensationDetailsDHL";
import JobDetailsDHL from "./JobDetailsDHL";
import LetterDetailsDHL from "./LetterDetailsDHL";
import LetterTypeSelectionDHL from "./LetterTypeSelectionDHL";
import {
    getRoundUptoXDecimals,
    themeDHL,
    baseDHLApiURL,
} from "./utilsDHLPrerequisites";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDHLAction } from "../../Redux/Actions/actions";

export default function DHLPrerequisites(props) {


    function getURLParameter(sParam) {
        debugger;
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }

    const storedInputDataDHL = useSelector(state => state.inputDataDHLReducer.inputDataDHL);
    //  function getURLParameter(sParam) {
    //      var sPageURL = window.location.search.substring(1);
    //      var sURLVariables = sPageURL.split("&");
    //      for (var i = 0; i < sURLVariables.length; i++) {
    //          var sParameterName = sURLVariables[i].split("=");
    //          var value = "";
    //          for (var j = 0; j < sParameterName.length; j++) {
    //              if (sParameterName[j] == sParam) {
    //                  for (var k = j + 1; k < sParameterName.length; k++) {
    //                      value = value + sParameterName[k];
    //                  }
    //                  break;
    //              }
    //              //return sParameterName[1];
    //          }

    //    if (!value) {
    //      continue;
    //    } else {
    //      break;
    //    }
    //  }
    //  return value;
    //}
    let encryptedId = getURLParameter("id");
    let data, id;
    const dispatch = useDispatch();
    const [formHeaderText, setFormHeaderText] = useState(
        "Digital HR Letter Pre-Requisites"
    );
    let [loaderProps, setLoaderProps] = useState({
        isEnable: false,
        loaderText: "",
    });

    const handleLoader = (isEnable, text) => {
        setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
    };
    let [dialogPropsALert, setDialogPropsALert] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    let [dialogPropsConfirm, setDialogPropsConfirm] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    const handleDialogOpenAlert = (type, title, content) => {
        setDialogPropsALert({
            ...dialogPropsALert,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };
    const handleCloseAlert = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogPropsALert({
            ...dialogPropsALert,
            open: false,
            className: "bg-light-green clr-white",
            type: "",
            title: "",
            content: "",
        });
        switch (dialogPropsALert?.content) {
            case "Digital Letter details saved successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Digital Letter details updated successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Data saved, workflow updated and compensation proposal sent successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Data saved, workflow updated but compensation proposal sent failed.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Offer sent for Digital Signature successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Compensation Letter uploaded successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Compensation Letter data deleted successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Offer Letter uploaded successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Offer Letter data deleted successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Document uploaded successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            case "Additional Document data deleted successfully.":
                getDigiLetterDetails(encryptedId);
                break;
            default:
                setDialogPropsALert({
                    ...dialogPropsALert,
                    open: false,
                    className: "bg-light-green clr-white",
                    type: "",
                    title: "",
                    content: "",
                });
                break;
        }
    };
    const handleDialogOpenConfirm = (type, title, content) => {
        setDialogPropsConfirm({
            ...dialogPropsConfirm,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };
    const handleConfirm = (e) => {
        if (dialogPropsConfirm?.type !== "Recall Offer Letter") {
            setDialogPropsConfirm({
                ...dialogPropsConfirm,
                open: false,
                className: "bg-light-green clr-white",
                type: "",
                title: "",
                content: "",
            });
        }
        switch (dialogPropsConfirm?.type) {
            case "Save As New":

                saveDHLPrerequisites();


                break;
            case "Update Existing":

                updateDHLPrerequisites();


                break;
            case "Save & Send Negotiation":
                setInputDataToDHL(storedInputDataDHL);
                saveAndSendNegotiation();
                break;
            case "Send Offer for Digital Signature":
                saveAndPrepareOfferDataForDHL();
                break;
            case "Recall Offer Letter":
                if (!objRecallOfferLetter?.reasonOfRecall) {
                    e.preventDefault();
                    setObjRecallOfferLetter({
                        ...objRecallOfferLetter,
                        errorTextReason: "Recall reason is mandatory",
                    });
                } else {
                    debugger;
                    handleLoader(true, "Recalling offer letter ....");
                    objRecallOfferLetter.shubharambhLetterId =
                        inputDataToDHL?.offerLetterUploadId;
                    objRecallOfferLetter.tAEmail = inputDataToDHL?.taemail;
                    objRecallOfferLetter.tAName = inputDataToDHL?.taname;
                    objRecallOfferLetter.errorTextReason = "";
                    setDialogPropsConfirm({
                        ...dialogPropsConfirm,
                        open: false,
                        className: "bg-light-green clr-white",
                        type: "",
                        title: "",
                        content: "",
                    });
                    recallOfferLetterFromDHL(objRecallOfferLetter);
                }
                break;
            default:
                handleCloseConfirm();
                break;
        }
    };
    const handleCloseConfirm = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogPropsConfirm({
            ...dialogPropsConfirm,
            open: false,
            className: "bg-light-green clr-white",
            type: "",
            title: "",
            content: "",
        });
    };
    let [inputDataToDHL, setInputDataToDHL] = useState({
       
        applicantId: getURLParameter("id"),
        userIdencrypted: props.userId,
        firstName: "",
        lastName: "",
        email: "",
        areObj: {},
        taname: "",
        taemail: "",
        address: "",
        dateOfBirth: null,
        mprnumber: "",
        referenceNo: "",
        letterType: 0,
        letterTypeLabel: "",
        letterTypeObj: {},
        letterTemplateType: 0,
        letterTemplateTypeLabel: "",
        letterTemplateTypeObj: {},
        noOfSignatories: 0,
        noOfSignatoriesLabel: "",
        noOfSignatoriesObj: {},
        signatoryDetails: [],
        employeeType: 0,
        employeeTypeLabel: "",
        employeeTypeObj: {},
        jobLocation: 0,
        jobLocationLabel: "",
        jobLocationObj: {},
        jobTitle: 0,
        jobTitleLabel: "",
        jobTitleObj: {},
        businessTitle: "",
        businessUnit: "",
        grip: 0,
        gripLabel: "",
        gripCode: "",
        gripObj: {},
        staff: 0,
        staffLabel: "",
        staffObj: {},
        compensationGrade: 0,
        compensationGradeLabel: "",
        compensationGradeObj: {},
        compensationGradeProfile: 0,
        compensationGradeProfileLabel: "",
        compensationGradeProfileObj: {},
        payPlan: 1,
        payPlanLabel: "Salary Plan",
        payPlanObj: { id: 1, name: "Salary Plan" },
        performencePayPlan: 0,
        performencePayPlanLabel: "",
        performencePayPlanObj: {},
        salaryAmount: 0,
        performencePay: 0,
        targetPay: 0,
        allowancePlan: "",
        allowancePlanLabel: "",
        allowancePlanObj: [],
        allowanceAmountObj: [],
        bfp: 0,
        bp: 0,
        bpa: 0,
        hra: 0,
        totalRetirals: 0,
        pfContri: 0,
        gratuityContri: 0,
        negotiationLetterType: 0,
        negotiationLetterTypeLabel: "",
        negotiationLetterTypeObj: {},
        compensationLetterUploadId: 0,
        isCompensationLetterUploaded: false,
        isNegotiationSent: false,
        digiLetterWorkflowId: 0,
        isCompensationAccepted: true,
        isCompensationRejected: false,
        digiLetterStatusId: 0,
        isOfferAccepted: false,
        isOfferRejected: false,
        offerLetterUploadId: 0,
        isOfferLetteruploaded: false,
        siemensLogo: "../assets/images/sie-logo-petrol-rgb.png",
    });
    let [compensationAmount, setCompensationAmount] = useState({
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    });
    let [compensationWSAAmount, setCompensationWSAAmount] = useState({
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        safpm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    });
    let [insuranceAmount, setInsuranceAmount] = useState({
        ghic: 0.0,
        gpaic: 0.0,
        gtLic: 0.0,
        edli: 0.0,
        esic: "",
    });
    let [compensationFactors, setCompensationFactors] = useState({
        compensationFactorId: 0,
        gripId: 0,
        letterTypeId: 0,
        basicSalaryFactor: 0.0,
        performencePayFactor: 0.0,
        pfFactor: 0.0,
        gratuityFactor: 0.0,
        totalFixedPayFactor: 0.0,
        safFactor: 0.0,
        totalFixedPayWithSafFactor: 0.0,
    });
    let [insuranceData, setInsuranceData] = useState({
        id: 0,
        gripId: 0,
        ghicAmount: 0.0,
        gpaicAmount: 0.0,
        gtLicAmount: 0.0,
        edliAmount: 0.0,
        micAmount: 0.0,
    });
    let errorflag = false;
    let [errorTexts, setErrorTexts] = useState({
        firstName: "",
        lastName: "",
        email: "",
        areCode: "",
        areName: "",
        taName: "",
        taEmail: "",
        letterType: "",
        letterTemplateType: "",
        noOfSignatories: "",
        employeeType: "",
        jobLocation: "",
        jobTitle: "",
        grip: "",
        staff: "",
        compensationGrade: "",
        compensationGradeProfile: "",
        payPlan: "",
        performencePayPlan: "",
        salaryAmount: "",
        performencePay: "",
        targetPay: "",
        allowancePlanObj: {},
        bfp: "",
        totalRetirals: "",
        negotiationLetterType: "",
        address: "",
    });
    function ValidateFormFields(data) {
        if (data.firstName == null || data.firstName == "" || data.lastName == null ||
            data.email == null || data.areObj.areCode == null || data.areObj.areName == null ||
            data.letterType == null || data.letterTypeLabel == null || data.employeeType == null
            || data.jobLocation == null || data.jobTitle == null || data.grip == null || data.staff == null ||
            data.compensationGrade == null || data.compensationGradeProfile == null ||
            data.payPlan == null || data.performencePayPlan == null || data.salaryAmount == null ||
            data.targetPay == null ||
            data.allowanceAmountObj == null || data.address == null || data.dateOfBirth == null) {

            setErrorTexts("Mandatory field");
            return false;

        } else {
            return true;
        }
    }
    const [itemsNoOfSignatories] = useState([
        { value: 0, label: "None" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
    ]);
    function compensationCalculationforPL13_14(value) {
        let tfppa = getRoundUptoXDecimals(
            value * compensationFactors.totalFixedPayFactor,
            0
        );
        let basicpa = getRoundUptoXDecimals(
            tfppa * compensationFactors.basicSalaryFactor,
            0
        );
        let basicpm = getRoundUptoXDecimals(basicpa / 12, 0);
        let pfpm = 0;
        if (basicpm > 15000) {
            pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
        } else {
            // pfpm = 1800;
            pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
        }
        let gratuitypm = getRoundUptoXDecimals(
            basicpm * compensationFactors.gratuityFactor,
            0
        );
        let totalRetiralspm = getRoundUptoXDecimals(pfpm + gratuitypm, 0);
        let totalRetiralspa = getRoundUptoXDecimals(totalRetiralspm * 12, 0);
        let tapa = getRoundUptoXDecimals(tfppa - totalRetiralspa - basicpa, 0);
        let bfppm = getRoundUptoXDecimals(tapa / 12 - 0, 0);
        let bppa = getRoundUptoXDecimals(basicpa + tapa, 0);
        let tfppm = getRoundUptoXDecimals(bppa / 12 + totalRetiralspm, 0);
        let pppa = getRoundUptoXDecimals(
            (tfppa - totalRetiralspa) * compensationFactors.performencePayFactor,
            0
        );
        let elepa = getRoundUptoXDecimals((basicpm / 30) * 22, 0);

        //With SAF
        let tfpWSApa = getRoundUptoXDecimals(
            value * compensationFactors.totalFixedPayWithSafFactor,
            0
        );
        let basicWSApa = getRoundUptoXDecimals(
            tfpWSApa * compensationFactors.basicSalaryFactor,
            0
        );
        let basicWSApm = getRoundUptoXDecimals(basicWSApa / 12, 0);
        let pfWSApm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.pfFactor,
            0
        );
        let gratuityWSApm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.gratuityFactor,
            0
        );
        let safpm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.safFactor,
            0
        );
        let totalRetiralsWSApm = getRoundUptoXDecimals(
            pfWSApm + gratuityWSApm + safpm,
            0
        );
        let totalRetiralsWSApa = getRoundUptoXDecimals(totalRetiralsWSApm * 12, 0);
        let taWSApa = getRoundUptoXDecimals(
            tfpWSApa - totalRetiralsWSApa - basicWSApa,
            0
        );
        let bfpWSApm = getRoundUptoXDecimals(taWSApa / 12 - 0, 0);
        let bpWSApa = getRoundUptoXDecimals(basicWSApa + taWSApa, 0);
        let tfpWSApm = getRoundUptoXDecimals(bpWSApa / 12 + totalRetiralsWSApm, 0);
        let ppWSApa = getRoundUptoXDecimals(
            (tfpWSApa - totalRetiralsWSApa) *
            compensationFactors.performencePayFactor,
            0
        );
        let eleWSApa = getRoundUptoXDecimals((basicWSApm / 30) * 22, 0);

        //Insurances
        let ghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
        let gpaicpa = getRoundUptoXDecimals(
            value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
            0
        );
        let gtLicpa = getRoundUptoXDecimals(
            value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
            0
        );
        let edlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
        let esic =
            getRoundUptoXDecimals(tapa / 12, 0) < 23000 ||
                getRoundUptoXDecimals(taWSApa / 12, 0) < 23000
                ? insuranceData.micAmount
                : " Not applicable as gross salary above ESI eligibility";
        setCompensationAmount({
            ...compensationAmount,
            targetPay: getRoundUptoXDecimals(value, 0),
            basicpa: basicpa,
            basicpm: basicpm,
            tfppa: tfppa,
            tfppm: tfppm,
            bfppm: bfppm,
            pfpm: pfpm,
            gratuitypm: gratuitypm,
            totalRetiralspm: totalRetiralspm,
            totalRetiralspa: totalRetiralspa,
            tapa: tapa,
            bppa: bppa,
            pppa: pppa,
            elepa: elepa,
            hra: 0.0,
            bpa: 0.0,
        });
        setCompensationWSAAmount({
            ...compensationWSAAmount,
            targetPay: getRoundUptoXDecimals(value, 0),
            basicpa: basicWSApa,
            basicpm: basicWSApm,
            tfppa: tfpWSApa,
            tfppm: tfpWSApm,
            bfppm: bfpWSApm,
            pfpm: pfWSApm,
            gratuitypm: gratuityWSApm,
            safpm: safpm,
            totalRetiralspm: totalRetiralsWSApm,
            totalRetiralspa: totalRetiralsWSApa,
            tapa: taWSApa,
            bppa: bpWSApa,
            pppa: ppWSApa,
            elepa: eleWSApa,
        });
        setInsuranceAmount({
            ...insuranceAmount,
            ghic: ghicpa,
            gpaic: gpaicpa,
            gtLic: gtLicpa,
            edli: edlipa,
            esic: esic,
        });

        let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
            if (c.name.includes("Base Flexible Pay p.m.")) {
                c.allowanceAmount = bfppm > 0 ? bfppm : c.allowanceAmount;
            } else if (c.name.includes("Total Retirals p.a.")) {
                c.allowanceAmount =
                    totalRetiralspa > 0 ? totalRetiralspa : c.allowanceAmount;
            }
            return c;
        });
        let inputUpdatedDHL = {
            ...inputDataToDHL,
            salaryAmount: basicpm,
            allowanceAmountObj: allowanceAmount,
            performencePay: pppa,
        };
        // setInputDataToDHL({
        //     ...inputDataToDHL,
        //     salaryAmount: basicpm,
        //     allowanceAmountObj: allowanceAmount,
        //     performencePay: pppa,
        // });
        setInputDataToDHL(inputUpdatedDHL);
        //dispatch(inputDataDHLAction(inputUpdatedDHL));
    }
    //------------------- GBS Calculation for PL10,PL11,PL12 Without SF and Without Performance Pay----------------------------
    function gbsCompensationForBelow15KBasic(value) {
        if (value <= 450000) {

            let gbssupallowance = 0;
            let gbsbfppm = 0;
            let gbspfpm = 0;

            let gbstfppm = getRoundUptoXDecimals(value / 12, 2);
            let gbstfppa = getRoundUptoXDecimals(gbstfppm * 12, 2);
            let gbsbasicpm = getRoundUptoXDecimals(gbstfppm * 0.4, 2);
            let gbsbasicpa = getRoundUptoXDecimals(gbsbasicpm * 12, 2);
            let gbshra = getRoundUptoXDecimals(gbsbasicpm / 2, 2);
            let gbsmealcard = getRoundUptoXDecimals(2200, 0);
            let gbsgratuitypm = getRoundUptoXDecimals(gbsbasicpm * .0425, 2);
            let factor1;
            let factor2;
            if (value > gbsmealcard) {
                factor1 = (gbstfppm - gbsgratuitypm - (1.12 * gbsbasicpm) - gbshra - gbsmealcard);
                factor2 = (gbstfppm - gbsgratuitypm - 1800 - gbsbasicpm - gbshra - gbsmealcard);
            }
            else {
                factor1 = (gbsmealcard - gbstfppm - gbsgratuitypm - (1.12 * gbsbasicpm) - gbshra);
                factor2 = (gbsmealcard - 1800 - gbstfppm - gbsgratuitypm - - gbsbasicpm - gbshra);
            }

            if (((factor1 / 1.12) + gbsbasicpm) <= 15000) {
                gbssupallowance = getRoundUptoXDecimals((factor1 / 1.12), 2);
            } else {
                gbssupallowance = getRoundUptoXDecimals(factor2, 2);
            }
            gbsbfppm = getRoundUptoXDecimals((gbsmealcard + gbssupallowance), 2);
            let gbsbasepaypm = gbsbasicpm + gbsbfppm + gbshra;
            let gbsbasepaypa = getRoundUptoXDecimals(gbsbasepaypm * 12, 2);
            // For GBS Annexure
            let gbsnewpfwage = gbsbasicpm + gbssupallowance;
            if (gbsnewpfwage >= 15000) {
                gbspfpm = 1800;
            }
            else if (gbsnewpfwage < 15000) {
                gbspfpm = getRoundUptoXDecimals((gbsnewpfwage / 100) * 12, 2);

            }

            let gbstotalRetiralspm = getRoundUptoXDecimals(gbspfpm + gbsgratuitypm, 2);
            let gbstotalRetiralspa = getRoundUptoXDecimals(gbstotalRetiralspm * 12, 2);
            let gbstapa = getRoundUptoXDecimals(gbsbasepaypa - gbstotalRetiralspa - gbsbasicpa, 2);
            let gbsbppa = getRoundUptoXDecimals(gbsbasicpa + gbstapa, 2);


            let gbspppa = getRoundUptoXDecimals(
                (gbstfppa - gbstotalRetiralspa) * compensationFactors.performencePayFactor,
                2);
            let gbselepa = getRoundUptoXDecimals((gbsbasicpm / 30) * 22, 2);
            //let gbstfppm = getRoundUptoXDecimals(gbsbppa / 12 + gbstotalRetiralspm, 0);
            //let gbstfppa = getRoundUptoXDecimals(
            //    gbstfppm * 12,
            //    0
            //);

            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                bpa: gbsbasepaypa,
                hra: gbshra,
            });

            //let gbstfpWSApa = getRoundUptoXDecimals(
            //    value * compensationFactors.totalFixedPayWithSafFactor,
            //    0
            //);
            //let gbsbasicWSApa = getRoundUptoXDecimals(
            //    gbstfpWSApa * compensationFactors.basicSalaryFactor,
            //    0
            //);
            //let gbsbasicWSApm = getRoundUptoXDecimals(gbsbasicWSApa / 12, 0);
            ////let gbspfWSApm = getRoundUptoXDecimals(
            ////    gbsbasicWSApm * compensationFactors.pfFactor,
            ////    0
            ////);
            //let gbspfWSApm = 1800;
            //let gbsgratuityWSApm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.gratuityFactor,
            //    0
            //);
            //let gbssafpm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.safFactor,
            //    0
            //);
            //let gbstotalRetiralsWSApm = getRoundUptoXDecimals(
            //    gbspfWSApm + gbsgratuityWSApm + gbssafpm,
            //    0
            //);
            //let gbstotalRetiralsWSApa = getRoundUptoXDecimals(gbstotalRetiralsWSApm * 12, 0);
            //let gbstaWSApa = getRoundUptoXDecimals(
            //    gbstfpWSApa - gbstotalRetiralsWSApa - gbsbasicWSApa,
            //    0
            //);
            //let gbsbfpWSApm = getRoundUptoXDecimals(gbstaWSApa / 12 - 0, 0);
            //let gbsbpWSApa = getRoundUptoXDecimals(gbsbasicWSApa + gbstaWSApa, 0);
            //let gbstfpWSApm = getRoundUptoXDecimals(gbsbpWSApa / 12 + gbstotalRetiralsWSApm, 0);
            //let gbsppWSApa = getRoundUptoXDecimals(
            //    (gbstfpWSApa - gbstotalRetiralsWSApa) *
            //    compensationFactors.performencePayFactor,
            //    0
            //);
            //let gbseleWSApa = getRoundUptoXDecimals((gbsbasicWSApm / 30) * 22, 0);

            //Insurances
            let gbsghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gbsgpaicpa = getRoundUptoXDecimals(
                value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gbsgtLicpa = getRoundUptoXDecimals(
                value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
                0
            );
            let gbsedlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let gbsesic =
                getRoundUptoXDecimals(gbstapa / 12, 0) < 23000
                    ? insuranceData.micAmount
                    : " Not applicable as gross salary above ESI eligibility";
            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                bpa: gbsbasepaypa,
                hra: gbshra,
            });
            //setCompensationWSAAmount({
            //    ...compensationWSAAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicWSApa,
            //    basicpm: gbsbasicWSApm,
            //    tfppa: gbstfpWSApa,
            //    tfppm: gbstfpWSApm,
            //    bfppm: gbsbfpWSApm,
            //    pfpm: gbspfWSApm,
            //    gratuitypm: gbsgratuityWSApm,
            //    safpm: gbssafpm,
            //    totalRetiralspm: gbstotalRetiralsWSApm,
            //    totalRetiralspa: gbstotalRetiralsWSApa,
            //    tapa: gbstaWSApa,
            //    bppa: gbsbpWSApa,
            //    pppa: gbsppWSApa,
            //    elepa: gbseleWSApa,
            //    bpa: gbsbasepaypa,
            //    hra: gbshra,
            //});
            setInsuranceAmount({
                ...insuranceAmount,
                ghic: gbsghicpa,
                gpaic: gbsgpaicpa,
                gtLic: gbsgtLicpa,
                edli: gbsedlipa,
                esic: gbsesic,
            });

            let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = gbsbfppm > 0 ? gbsbfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        gbstotalRetiralspa > 0 ? gbstotalRetiralspa : c.allowanceAmount;
                }
                return c;
            });

            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: gbsbasicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: gbspppa,
            // });

            let inputUpdatedDHL = {
                ...inputDataToDHL,
                salaryAmount: gbsbasicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: gbspppa,
            };
            setInputDataToDHL(inputUpdatedDHL);
            //dispatch(inputDataDHLAction(inputUpdatedDHL));
        }

    }
    /*-------------------------------------------------BELOW 15K GBS Annexure Ends Here-------------------------------------*/
    function compensationCalculation(value) {
        if (value == 510000) {
            let gbstfppa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayFactor,
                0
            );
            compensationFactors.basicSalaryFactor = 0.353;
            let gbsbasicpa = getRoundUptoXDecimals(
                gbstfppa * compensationFactors.basicSalaryFactor,
                0
            );
            let gbsbasicpm = getRoundUptoXDecimals(gbsbasicpa / 12, 0);
            // For GBS Annexure

            let gbspfpm = 1800;
            let gbsgratuitypm = getRoundUptoXDecimals(
                gbsbasicpm * compensationFactors.gratuityFactor,
                0
            );
            let gbstotalRetiralspm = getRoundUptoXDecimals(gbspfpm + gbsgratuitypm, 0);
            let gbstotalRetiralspa = getRoundUptoXDecimals(gbstotalRetiralspm * 12, 0);
            let gbstapa = getRoundUptoXDecimals(gbstfppa - gbstotalRetiralspa - gbsbasicpa, 0);
            let gbsbfppm = getRoundUptoXDecimals(gbstapa / 12 - 0, 0);
            let gbsbppa = getRoundUptoXDecimals(gbsbasicpa + gbstapa, 0);
            let gbstfppm = getRoundUptoXDecimals(gbsbppa / 12 + gbstotalRetiralspm, 0);
            let gbspppa = getRoundUptoXDecimals(
                (gbstfppa - gbstotalRetiralspa) * compensationFactors.performencePayFactor,
                0
            );
            let gbselepa = getRoundUptoXDecimals((gbsbasicpm / 30) * 22, 0);

            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                hra: 0.0,
                bpa: 0.0,
            });

            let gbstfpWSApa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayWithSafFactor,
                0
            );
            let gbsbasicWSApa = getRoundUptoXDecimals(
                gbstfpWSApa * compensationFactors.basicSalaryFactor,
                0
            );
            let gbsbasicWSApm = getRoundUptoXDecimals(gbsbasicWSApa / 12, 0);
            //let gbspfWSApm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.pfFactor,
            //    0
            //);
            let gbspfWSApm = 1800;
            let gbsgratuityWSApm = getRoundUptoXDecimals(
                gbsbasicWSApm * compensationFactors.gratuityFactor,
                0
            );
            let gbssafpm = getRoundUptoXDecimals(
                gbsbasicWSApm * compensationFactors.safFactor,
                0
            );
            let gbstotalRetiralsWSApm = getRoundUptoXDecimals(
                gbspfWSApm + gbsgratuityWSApm + gbssafpm,
                0
            );
            let gbstotalRetiralsWSApa = getRoundUptoXDecimals(gbstotalRetiralsWSApm * 12, 0);
            let gbstaWSApa = getRoundUptoXDecimals(
                gbstfpWSApa - gbstotalRetiralsWSApa - gbsbasicWSApa,
                0
            );
            let gbsbfpWSApm = getRoundUptoXDecimals(gbstaWSApa / 12 - 0, 0);
            let gbsbpWSApa = getRoundUptoXDecimals(gbsbasicWSApa + gbstaWSApa, 0);
            let gbstfpWSApm = getRoundUptoXDecimals(gbsbpWSApa / 12 + gbstotalRetiralsWSApm, 0);
            let gbsppWSApa = getRoundUptoXDecimals(
                (gbstfpWSApa - gbstotalRetiralsWSApa) *
                compensationFactors.performencePayFactor,
                0
            );
            let gbseleWSApa = getRoundUptoXDecimals((gbsbasicWSApm / 30) * 22, 0);

            //Insurances
            let gbsghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gbsgpaicpa = getRoundUptoXDecimals(
                value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gbsgtLicpa = getRoundUptoXDecimals(
                value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
                0
            );
            let gbsedlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let gbsesic =
                getRoundUptoXDecimals(gbstapa / 12, 0) < 23000 ||
                    getRoundUptoXDecimals(gbstaWSApa / 12, 0) < 23000
                    ? insuranceData.micAmount
                    : " Not applicable as gross salary above ESI eligibility";
            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                hra: 0.0,
                bpa: 0.0,
            });
            setCompensationWSAAmount({
                ...compensationWSAAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicWSApa,
                basicpm: gbsbasicWSApm,
                tfppa: gbstfpWSApa,
                tfppm: gbstfpWSApm,
                bfppm: gbsbfpWSApm,
                pfpm: gbspfWSApm,
                gratuitypm: gbsgratuityWSApm,
                safpm: gbssafpm,
                totalRetiralspm: gbstotalRetiralsWSApm,
                totalRetiralspa: gbstotalRetiralsWSApa,
                tapa: gbstaWSApa,
                bppa: gbsbpWSApa,
                pppa: gbsppWSApa,
                elepa: gbseleWSApa,
            });
            setInsuranceAmount({
                ...insuranceAmount,
                ghic: gbsghicpa,
                gpaic: gbsgpaicpa,
                gtLic: gbsgtLicpa,
                edli: gbsedlipa,
                esic: gbsesic,
            });

            let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = gbsbfppm > 0 ? gbsbfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        gbstotalRetiralspa > 0 ? gbstotalRetiralspa : c.allowanceAmount;
                }
                return c;
            });

            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: gbsbasicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: gbspppa,
            // });
            let inputUpdatedDHL = {
                ...inputDataToDHL,
                salaryAmount: gbsbasicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: gbspppa,
            };
            setInputDataToDHL(inputUpdatedDHL);
            //dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
        else {


            //Without SAF
            let tfppa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayFactor,
                0
            );
            let basicpa = getRoundUptoXDecimals(
                tfppa * compensationFactors.basicSalaryFactor,
                0
            );
            let basicpm = getRoundUptoXDecimals(basicpa / 12, 0);
            let pfpm = 0;
            if (basicpm > 15003) {
                pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            } else {
                //pfpm = 1800;
                pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            }
            // let pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            let gratuitypm = getRoundUptoXDecimals(
                basicpm * compensationFactors.gratuityFactor,
                0
            );
            let totalRetiralspm = getRoundUptoXDecimals(pfpm + gratuitypm, 0);
            let totalRetiralspa = getRoundUptoXDecimals(totalRetiralspm * 12, 0);
            let tapa = getRoundUptoXDecimals(tfppa - totalRetiralspa - basicpa, 0);
            let bfppm = getRoundUptoXDecimals(tapa / 12 - 0, 0);
            let bppa = getRoundUptoXDecimals(basicpa + tapa, 0);
            let tfppm = getRoundUptoXDecimals(bppa / 12 + totalRetiralspm, 0);
            let pppa = getRoundUptoXDecimals(
                (tfppa - totalRetiralspa) * compensationFactors.performencePayFactor,
                0
            );
            let elepa = getRoundUptoXDecimals((basicpm / 30) * 22, 0);

            //With SAF
            let tfpWSApa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayWithSafFactor,
                0
            );
            let basicWSApa = getRoundUptoXDecimals(
                tfpWSApa * compensationFactors.basicSalaryFactor,
                0
            );
            let basicWSApm = getRoundUptoXDecimals(basicWSApa / 12, 0);
            let pfWSApm = 0;
            if (basicWSApm > 15003) {
                pfWSApm = getRoundUptoXDecimals(basicWSApm * compensationFactors.pfFactor, 0);
            } else {
                //pfWSApm = 1800;
                pfWSApm = getRoundUptoXDecimals(basicWSApm * compensationFactors.pfFactor, 0);
            }
            //let pfWSApm = getRoundUptoXDecimals(
            //    basicWSApm * compensationFactors.pfFactor,
            //    0
            //);
            let gratuityWSApm = getRoundUptoXDecimals(
                basicWSApm * compensationFactors.gratuityFactor,
                0
            );
            let safpm = getRoundUptoXDecimals(
                basicWSApm * compensationFactors.safFactor,
                0
            );
            let totalRetiralsWSApm = getRoundUptoXDecimals(
                pfWSApm + gratuityWSApm + safpm,
                0
            );
            let totalRetiralsWSApa = getRoundUptoXDecimals(totalRetiralsWSApm * 12, 0);
            let taWSApa = getRoundUptoXDecimals(
                tfpWSApa - totalRetiralsWSApa - basicWSApa,
                0
            );
            let bfpWSApm = getRoundUptoXDecimals(taWSApa / 12 - 0, 0);
            let bpWSApa = getRoundUptoXDecimals(basicWSApa + taWSApa, 0);
            let tfpWSApm = getRoundUptoXDecimals(bpWSApa / 12 + totalRetiralsWSApm, 0);
            let ppWSApa = getRoundUptoXDecimals(
                (tfpWSApa - totalRetiralsWSApa) *
                compensationFactors.performencePayFactor,
                0
            );
            let eleWSApa = getRoundUptoXDecimals((basicWSApm / 30) * 22, 0);

            //Insurances
            let ghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gpaicpa = getRoundUptoXDecimals(
                value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gtLicpa = getRoundUptoXDecimals(
                value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
                0
            );
            let edlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let esic =
                getRoundUptoXDecimals(tapa / 12, 0) < 23000 ||
                    getRoundUptoXDecimals(taWSApa / 12, 0) < 23000
                    ? insuranceData.micAmount
                    : " Not applicable as gross salary above ESI eligibility";
            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: basicpa,
                basicpm: basicpm,
                tfppa: tfppa,
                tfppm: tfppm,
                bfppm: bfppm,
                pfpm: pfpm,
                gratuitypm: gratuitypm,
                totalRetiralspm: totalRetiralspm,
                totalRetiralspa: totalRetiralspa,
                tapa: tapa,
                bppa: bppa,
                pppa: pppa,
                elepa: elepa,
                hra: 0.0,
                bpa: 0.0,
            });
            setCompensationWSAAmount({
                ...compensationWSAAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: basicWSApa,
                basicpm: basicWSApm,
                tfppa: tfpWSApa,
                tfppm: tfpWSApm,
                bfppm: bfpWSApm,
                pfpm: pfWSApm,
                gratuitypm: gratuityWSApm,
                safpm: safpm,
                totalRetiralspm: totalRetiralsWSApm,
                totalRetiralspa: totalRetiralsWSApa,
                tapa: taWSApa,
                bppa: bpWSApa,
                pppa: ppWSApa,
                elepa: eleWSApa,
            });
            setInsuranceAmount({
                ...insuranceAmount,
                ghic: ghicpa,
                gpaic: gpaicpa,
                gtLic: gtLicpa,
                edli: edlipa,
                esic: esic,
            });

            let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = bfppm > 0 ? bfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        totalRetiralspa > 0 ? totalRetiralspa : c.allowanceAmount;
                }
                return c;
            });
            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: basicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: pppa,
            // });

            let inputUpdatedDHL = {
                ...inputDataToDHL,
                salaryAmount: basicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: pppa,
            };
            setInputDataToDHL(inputUpdatedDHL);
            //dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
    }
    const handleChange = (e) => {
        let value = e.target.value;
        let inputUpdatedDHL = {};
        
        switch (e.target.name) {
            case "noOfSignatoriesLabel":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [e.target.name]: itemsNoOfSignatories.find((x) => x.value === value)
                //         ?.label,
                //     noOfSignatoriesValue: parseInt(value),
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [e.target.name]: itemsNoOfSignatories.find((x) => x.value === value)
                        ?.label,
                    noOfSignatoriesValue: parseInt(value),
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "targetPay":
                value = parseFloat(e.target.value);
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...inputDataToDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "joiningBonus":
                value = parseFloat(e.target.value);
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...inputDataToDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "recoveryDuration":
                value = e.target.value > 0 ? parseInt(e.target.value) : 0;
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...inputDataToDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "businessTitle":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...inputDataToDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "businessUnit":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...inputDataToDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "gripCode":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...inputDataToDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };
    const handleAutoCompleteChange = (e, newValue) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        
        switch (elemName) {
            case "employeeType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobLocation":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobTitle":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                //     businessTitle: newValue ? newValue.name : "",
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "grip":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "staff":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "compensationGrade":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "compensationGradeProfile":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "allowancePlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "payPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "performencePayPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "negotiationLetterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "noOfSignatories":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterTemplateType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };
    const handleAutoCompleteTextChange = (e, newText) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        
        switch (elemName) {
            case "employeeType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobLocation":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobTitle":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "grip":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "staff":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "compensationGrade":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "compensationGradeProfile":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "payPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "performencePayPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "negotiationLetterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "noOfSignatories":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterTemplateType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...inputDataToDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                // dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };
    const onChangeSignatoryDetails = (data) => {
        // setInputDataToDHL({
        //     ...inputDataToDHL,
        //     signatoryDetails: [...data],
        // });
        let inputUpdatedDHL = {
            ...inputDataToDHL,
            signatoryDetails: [...data],
        }
        setInputDataToDHL(inputUpdatedDHL);
        //dispatch(inputDataDHLAction(inputUpdatedDHL));
    };

    const handleClickSave = (e) => {
        console.log("Inside Save stored dhl", storedInputDataDHL);
        if (ValidateFormFields(storedInputDataDHL)) {

            handleDialogOpenConfirm(
                "Save As New",
                "Save Confirmation",
                "Are you sure you want to save details as new?"
            );
        }
        else {

            handleDialogOpenAlert("Failed to Save", "Failed to Save Data", "Mandatory fields are not filled!");
        }
    };

    function saveDHLPrerequisites() {
        //inputDataToDHL.applicantIdEncrypted = inputDataToDHL.applicantId;
        //inputDataToDHL.applicantId = "";
        debugger;
        storedInputDataDHL.userIdencrypted = props.userId;
        storedInputDataDHL.applicantIdEncrypted = storedInputDataDHL.applicantId;
        storedInputDataDHL.applicantId = "";

        handleLoader(true, "Saving Digital Letter Prerequisites data....");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: storedInputDataDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Success",
                        "Digital Letter Data Save Success",
                        result.responseMsg
                    );
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Digital Letter Data Save Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                handleDialogOpenAlert("Exception", "Digital Letter Data Save Exception", error);
            });
    }
    const handleClickUpdate = (e) => {
        handleDialogOpenConfirm(
            "Update Existing",
            "Update Confirmation",
            "Are you sure you want to update the existing details?"
        );
    };
    function updateDHLPrerequisites() {
        handleLoader(true, "Updating Digital Letter Prerequisites data....");
        storedInputDataDHL.applicantIdEncrypted = storedInputDataDHL.applicantId;
        storedInputDataDHL.applicantId = "";
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/UpdateDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: storedInputDataDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Success",
                        "Digital Letter Data Update Success",
                        result.responseMsg
                    );
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Digital Letter Data Update Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                handleDialogOpenAlert("Exception", "Digital Letter Data Update Exception", error);
            });
    }
    const handleClickSaveAndSendNegotiation = (e) => {
        handleDialogOpenConfirm(
            "Save & Send Negotiation",
            "Save & Send Negotiation Confirmation",
            "Are you sure you want to update the existing details and send compensation proposal to the candidate?"
        );
    };

    function saveAndSendNegotiation() {
        debugger;
        storedInputDataDHL.applicantIdEncrypted = storedInputDataDHL.applicantId;
        storedInputDataDHL.applicantId = "";
        handleLoader(
            true,
            "Saving Digital Letter Prerequisites data and sending compensation letter...."
        );
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveAndSendNegotiation",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: storedInputDataDHL,
        };
        debugger;
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Success",
                        "Digital Letter Data Save And Send Negotiation Success",
                        result.responseMsg
                    );
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Digital Letter Data Save And Send Negotiation Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                handleDialogOpenAlert(
                    "Exception",
                    "Data Save And Send Negotiation Exception",
                    error
                );
            });
    }
    const handleClickSaveLetterDetails = (e) => {
        alert("Yet to be developed");
        const dataToSend = {
            firstName: inputDataToDHL.firstName,
            lastName: inputDataToDHL.lastName,
            email: inputDataToDHL.email,
            areCode: inputDataToDHL.areCode,
            taName: inputDataToDHL.taName,
            taEmail: inputDataToDHL.taEmail,
            letterType: inputDataToDHL.letterTypeLabel,
            noOfSignatories: inputDataToDHL.noOfSignatoriesLabel,
            signatoryDetails: inputDataToDHL.signatoryDetails,
        };
        console.log("Data To DHL:", dataToSend);
    };
    const handleClickSaveAndSendToDHL = (e) => {
        handleDialogOpenConfirm(
            "Send Offer for Digital Signature",
            "Send Offer Letter Confirmation",
            "Are you sure you want to update the existing details and send offer for digital signature or workflow approval?"
        );
    };
    function saveAndPrepareOfferDataForDHL() {
        inputDataToDHL.applicantIdEncrypted = inputDataToDHL.applicantId;
        inputDataToDHL.applicantId = "";
        handleLoader(true, "Preparing offer data to be sent for Signature....");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveAndSendDataToDHL",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: inputDataToDHL,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    sendOfferToDHL(result.objResult);
                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Send Offer To Digital Letter Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    function sendOfferToDHL(data) {
        handleLoader(true, "Sending offer data for Digital Signature....");
        const token = props.token;
        const options = {
            method: "post",
            url: baseDHLApiURL + "/DHLAPI/SaveShubharambhLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: data,
        };
        axios(options)
            .then((response) => {
                let result = response.data;
                if (result) {
                    handleLoader(false, "");

                    // setInputDataToDHL({
                    //     ...inputDataToDHL,
                    //     digiLetterStatusId: data?.digiLetterStatusId,
                    // });
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterStatusId: data?.digiLetterStatusId,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                    dispatch(inputDataDHLAction(inputUpdatedDHL));

                    handleDialogOpenAlert(
                        "Success",
                        "Send Offer Success",
                        "Offer has been sent For Digital Signature successfully."
                    );

                } else {
                    handleLoader(false, "");

                    handleDialogOpenAlert(
                        "Alert",
                        "Send Offer Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    function recallOfferLetterFromDHL(dataToDHL) {
        debugger;
        handleLoader(true, "Recalling offer letter ....");
        const token = props.token;
        const options = {
            method: "post",
            url: baseDHLApiURL + "/DHLAPI/RecallShubharambhLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: dataToDHL,
        };
        // console.log("data to dhl : ", dataToDHL);
        // handleLoader(false, "");
        axios(options)
            .then((response) => {
                let result = response.data;
                if (result) {
                    handleLoader(false, "");
                    let data = {
                        applicantIdEncrypted: inputDataToDHL?.applicantId,
                        userIdencrypted: inputDataToDHL?.userIdencrypted,
                        offerRecallReason: dataToDHL?.reasonOfRecall,
                    };
                    recallOfferLetter(data);

                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Recall Offer Letter Failed",
                        "There is some issue while recalling offer letter. Please try again or contact to administrator."
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    function recallOfferLetter(data) {
        handleLoader(true, "Updating recall status....");
        //console.log("Recall data : ", data);
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/RecallOfferLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: data,
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    handleLoader(false, "");
                    // setInputDataToDHL({
                    //     ...inputDataToDHL,
                    //     digiLetterStatusId: result?.objResult?.digiLetterStatusId,
                    // });
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterStatusId: result?.objResult?.digiLetterStatusId,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                     dispatch(inputDataDHLAction(inputUpdatedDHL));

                    handleDialogOpenAlert(
                        "Success",
                        "Recall Offer Letter Success",
                        result.responseMsg
                    );

                } else {
                    handleLoader(false, "");
                    handleDialogOpenAlert(
                        "Alert",
                        "Recall Offer Letter Failed",
                        result.responseMsg
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
                //handleDialogOpenAlert("Exception", "Send Offer To DHL Exception", error);
            });
    }
    function setDigiLetterDataState(data) {
        // setInputDataToDHL({
        //     ...inputDataToDHL,
        //     digiLetterDetailsId: data?.digiLetterDetailsId,
        //     applicantId: data?.applicantIdEncrypted,
        //     firstName: data?.firstName,
        //     lastName: data?.lastName,
        //     email: data?.email,
        //     joiningDate: data?.joiningDate,
        //     companyId: data?.companyId,
        //     areObj: {
        //         id: data?.areObj?.companyId,
        //         areCode: data?.areObj?.companyArename,
        //         areName: data?.areObj?.companyAredescription,
        //     },
        //     mprnumber: data?.mprnumber,
        //     referenceNo: data?.referenceNo,
        //     division: data?.division,
        //     taname: data?.taname,
        //     taemail: data?.taemail,
        //     address: data?.address,
        //     dateOfBirth: data?.dateOfBirth,
        //     letterType: data?.letterType,
        //     letterTypeDetails: data?.letterTypeObj,
        //     letterTypeLabel: data?.letterTypeObj
        //         ? data?.letterTypeObj?.letterTypeName
        //         : "",
        //     letterTypeObj: {
        //         id: data?.letterTypeObj?.letterTypeId,
        //         name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
        //     },
        //     noOfSignatories: data?.noOfSignatories,
        //     noOfSignatoriesObj: {
        //         id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
        //         name:
        //             data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
        //     },
        //     noOfSignatoriesLabel:
        //         data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
        //     signatoryDetails: data?.signatoryDetails,
        //     employeeType: data?.employeeType,
        //     employeeTypeLabel: data?.employeeTypeObj
        //         ? data?.employeeTypeObj?.name
        //         : "",
        //     employeeTypeObj: data?.employeeTypeObj,
        //     jobLocation: data?.jobLocation,
        //     jobLocationLabel: data?.jobLocationObj
        //         ? data?.jobLocationObj?.locationCode +
        //         " - " +
        //         data?.jobLocationObj?.cityName +
        //         " - " +
        //         data?.jobLocationObj?.streetName
        //         : "",
        //     jobLocationObj: data?.jobLocationObj
        //         ? {
        //             id: data?.jobLocationObj?.locationCodeId,
        //             name:
        //                 data?.jobLocationObj?.locationCode +
        //                 " - " +
        //                 data?.jobLocationObj?.cityName +
        //                 " - " +
        //                 data?.jobLocationObj?.streetName,
        //         }
        //         : { id: 0, name: "" },
        //     locationObj: data?.jobLocationObj,
        //     jobTitle: data?.jobTitle,
        //     jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
        //     businessTitle: data?.businessTitle
        //         ? data?.businessTitle
        //         : data?.jobTitleObj
        //             ? data?.jobTitleObj?.name
        //             : "",
        //     jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
        //     gripLabel: data?.gripObj ? data?.gripObj?.name : "",
        //     grip: data?.grip,
        //     businessUnit: data?.businessUnit,
        //     gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
        //     gripCode: data?.gripCode,
        //     staff: data?.staff,
        //     staffLabel: data?.staffObj ? data?.staffObj?.name : "",
        //     staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
        //     compensationGrade: data?.compensationGrade,
        //     compensationGradeLabel: data?.compensationGradeObj
        //         ? data?.compensationGradeObj?.name
        //         : "",
        //     compensationGradeObj: data?.compensationGradeObj
        //         ? data?.compensationGradeObj
        //         : { id: 0, name: "" },
        //     compensationGradeProfile: data?.compensationGradeProfile
        //         ? data?.compensationGradeProfile
        //         : { id: 0, name: "" },
        //     compensationGradeProfileLabel: data?.compensationGradeProfileObj
        //         ? data?.compensationGradeProfileObj?.name
        //         : "",
        //     compensationGradeProfileObj: data?.compensationGradeProfileObj,
        //     payPlan: data?.payPlan,
        //     payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
        //     payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
        //     performencePayPlan: data?.performencePayPlan,
        //     performencePayPlanLabel: data?.performencePayPlanObj
        //         ? data?.performencePayPlanObj?.name
        //         : "",
        //     performencePayPlanObj: data?.performencePayPlanObj
        //         ? data?.performencePayPlanObj
        //         : { id: 0, name: "" },
        //     salaryAmount: data?.salaryAmount,
        //     targetPay: data?.targetPay,
        //     performencePay: data?.performencePay,
        //     joiningBonus: data?.joiningBonus,
        //     recoveryDuration: data?.recoveryDuration,
        //     allowancePlanObj:
        //         data?.allowancePlanObj?.length > 0
        //             ? data?.allowancePlanObj
        //             : inputDataToDHL?.allowancePlanObj,
        //     allowanceAmountObj:
        //         data?.allowanceAmountObj?.length > 0
        //             ? data?.allowanceAmountObj
        //             : inputDataToDHL?.allowanceAmountObj,
        //     compensationLetterUploadId: data?.compensationLetterUploadId,
        //     isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
        //     offerLetterUploadId: data?.offerLetterUploadId,
        //     isOfferLetteruploaded: data?.isOfferLetteruploaded,
        //     siemensLogo:
        //         data?.companyId === 1 || data.companyId === 2
        //             ? "../assets/images/Siemens_Logo_Healthineers.jpg"
        //             : "../assets/images/sie-logo-petrol-rgb.png",
        //     digiLetterWorkflowId: data?.digiLetterWorkflowId,
        //     isCompensationAccepted: data?.isCompensationAccepted,
        //     isCompensationRejected: data?.isCompensationRejected,
        //     digiLetterStatusId: data?.digiLetterStatusId,
        //     isOfferAccepted: data?.isOfferAccepted,
        //     isOfferRejected: data?.isOfferRejected,
        //     isSuperannuation: data?.isSuperannuation,
        // });

        let inputUpdatedDHL = {
            ...inputDataToDHL,
            digiLetterDetailsId: data?.digiLetterDetailsId,
            applicantId: data?.applicantIdEncrypted,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            joiningDate: data?.joiningDate,
            companyId: data?.companyId,
            areObj: {
                id: data?.areObj?.companyId,
                areCode: data?.areObj?.companyArename,
                areName: data?.areObj?.companyAredescription,
            },
            mprnumber: data?.mprnumber,
            referenceNo: data?.referenceNo,
            division: data?.division,
            taname: data?.taname,
            taemail: data?.taemail,
            address: data?.address,
            dateOfBirth: data?.dateOfBirth,
            letterType: data?.letterType,
            letterTypeDetails: data?.letterTypeObj,
            letterTypeLabel: data?.letterTypeObj
                ? data?.letterTypeObj?.letterTypeName
                : "",
            letterTypeObj: {
                id: data?.letterTypeObj?.letterTypeId,
                name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
            },
            noOfSignatories: data?.noOfSignatories,
            noOfSignatoriesObj: {
                id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                name:
                    data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            },
            noOfSignatoriesLabel:
                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            signatoryDetails: data?.signatoryDetails,
            employeeType: data?.employeeType,
            employeeTypeLabel: data?.employeeTypeObj
                ? data?.employeeTypeObj?.name
                : "",
            employeeTypeObj: data?.employeeTypeObj,
            jobLocation: data?.jobLocation,
            jobLocationLabel: data?.jobLocationObj
                ? data?.jobLocationObj?.locationCode +
                " - " +
                data?.jobLocationObj?.cityName +
                " - " +
                data?.jobLocationObj?.streetName
                : "",
            jobLocationObj: data?.jobLocationObj
                ? {
                    id: data?.jobLocationObj?.locationCodeId,
                    name:
                        data?.jobLocationObj?.locationCode +
                        " - " +
                        data?.jobLocationObj?.cityName +
                        " - " +
                        data?.jobLocationObj?.streetName,
                }
                : { id: 0, name: "" },
            locationObj: data?.jobLocationObj,
            jobTitle: data?.jobTitle,
            jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
            businessTitle: data?.businessTitle
                ? data?.businessTitle
                : data?.jobTitleObj
                    ? data?.jobTitleObj?.name
                    : "",
            jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
            gripLabel: data?.gripObj ? data?.gripObj?.name : "",
            grip: data?.grip,
            businessUnit: data?.businessUnit,
            gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
            gripCode: data?.gripCode,
            staff: data?.staff,
            staffLabel: data?.staffObj ? data?.staffObj?.name : "",
            staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
            compensationGrade: data?.compensationGrade,
            compensationGradeLabel: data?.compensationGradeObj
                ? data?.compensationGradeObj?.name
                : "",
            compensationGradeObj: data?.compensationGradeObj
                ? data?.compensationGradeObj
                : { id: 0, name: "" },
            compensationGradeProfile: data?.compensationGradeProfile
                ? data?.compensationGradeProfile
                : { id: 0, name: "" },
            compensationGradeProfileLabel: data?.compensationGradeProfileObj
                ? data?.compensationGradeProfileObj?.name
                : "",
            compensationGradeProfileObj: data?.compensationGradeProfileObj,
            payPlan: data?.payPlan,
            payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
            payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
            performencePayPlan: data?.performencePayPlan,
            performencePayPlanLabel: data?.performencePayPlanObj
                ? data?.performencePayPlanObj?.name
                : "",
            performencePayPlanObj: data?.performencePayPlanObj
                ? data?.performencePayPlanObj
                : { id: 0, name: "" },
            salaryAmount: data?.salaryAmount,
            targetPay: data?.targetPay,
            performencePay: data?.performencePay,
            joiningBonus: data?.joiningBonus,
            recoveryDuration: data?.recoveryDuration,
            allowancePlanObj:
                data?.allowancePlanObj?.length > 0
                    ? data?.allowancePlanObj
                    : inputDataToDHL?.allowancePlanObj,
            allowanceAmountObj:
                data?.allowanceAmountObj?.length > 0
                    ? data?.allowanceAmountObj
                    : inputDataToDHL?.allowanceAmountObj,
            compensationLetterUploadId: data?.compensationLetterUploadId,
            isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
            offerLetterUploadId: data?.offerLetterUploadId,
            isOfferLetteruploaded: data?.isOfferLetteruploaded,
            siemensLogo:
                data?.companyId === 1 || data.companyId === 2
                    ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                    : "../assets/images/sie-logo-petrol-rgb.png",
            digiLetterWorkflowId: data?.digiLetterWorkflowId,
            isCompensationAccepted: data?.isCompensationAccepted,
            isCompensationRejected: data?.isCompensationRejected,
            digiLetterStatusId: data?.digiLetterStatusId,
            isOfferAccepted: data?.isOfferAccepted,
            isOfferRejected: data?.isOfferRejected,
            isSuperannuation: data?.isSuperannuation,
        }
        setInputDataToDHL(inputUpdatedDHL);
        //dispatch(inputDataDHLAction(inputUpdatedDHL));
    }
    let [objRecallOfferLetter, setObjRecallOfferLetter] = useState({
        shubharambhLetterId: inputDataToDHL?.offerLetterUploadId,
        tAEmail: inputDataToDHL?.taemail,
        tAName: inputDataToDHL?.taname,
        reasonOfRecall: "",
        errorTextReason: "",
    });
    function getDigiLetterDetails(encryptedId) {
        handleLoader(true, "Fetching data....");
        const token = props.token;
        var stat = localStorage.getItem("Status");
        // id = encryptedId.substr(22);
        id = encryptedId;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    console.log("Inside getDigiLetterDetails", data);
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterDetailsId: data?.digiLetterDetailsId,
                        applicantId: data?.applicantIdEncrypted,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        joiningDate: data?.joiningDate,
                        companyId: data?.companyId,
                        areObj: {
                            id: data?.areObj?.companyId,
                            areCode: data?.areObj?.companyArename,
                            areName: data?.areObj?.companyAredescription,
                        },
                        mprnumber: data?.mprnumber,
                        referenceNo: data?.referenceNo,
                        division: data?.division,
                        taname: data?.taname,
                        taemail: data?.taemail,
                        address: data?.address,
                        dateOfBirth: data?.dateOfBirth,
                        letterType: data?.letterType,
                        letterTypeDetails: data?.letterTypeObj,
                        letterTypeLabel: data?.letterTypeObj
                            ? data?.letterTypeObj?.letterTypeName
                            : "",
                        letterTypeObj: {
                            id: data?.letterTypeObj?.letterTypeId,
                            name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                        },
                        noOfSignatories: data?.noOfSignatories,
                        noOfSignatoriesObj: {
                            id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                            name:
                                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        },
                        noOfSignatoriesLabel:
                            data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        signatoryDetails: data?.signatoryDetails,
                        employeeType: data?.employeeType,
                        employeeTypeLabel: data?.employeeTypeObj
                            ? data?.employeeTypeObj?.name
                            : "",
                        employeeTypeObj: data?.employeeTypeObj,
                        jobLocation: data?.jobLocation,
                        jobLocationLabel: data?.jobLocationObj
                            ? data?.jobLocationObj?.locationCode +
                            " - " +
                            data?.jobLocationObj?.cityName +
                            " - " +
                            data?.jobLocationObj?.streetName
                            : "",
                        jobLocationObj: data?.jobLocationObj
                            ? {
                                id: data?.jobLocationObj?.locationCodeId,
                                name:
                                    data?.jobLocationObj?.locationCode +
                                    " - " +
                                    data?.jobLocationObj?.cityName +
                                    " - " +
                                    data?.jobLocationObj?.streetName,
                            }
                            : { id: 0, name: "" },
                        locationObj: data?.jobLocationObj,
                        jobTitle: data?.jobTitle,
                        jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                        businessTitle: data?.businessTitle
                            ? data?.businessTitle
                            : data?.jobTitleObj
                                ? data?.jobTitleObj?.name
                                : "",
                        jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                        gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                        grip: data?.grip,
                        businessUnit: data?.businessUnit,
                        gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                        gripCode: data?.gripCode,
                        staff: data?.staff,
                        staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                        staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                        compensationGrade: data?.compensationGrade,
                        compensationGradeLabel: data?.compensationGradeObj
                            ? data?.compensationGradeObj?.name
                            : "",
                        compensationGradeObj: data?.compensationGradeObj
                            ? data?.compensationGradeObj
                            : { id: 0, name: "" },
                        compensationGradeProfile: data?.compensationGradeProfile
                            ? data?.compensationGradeProfile
                            : { id: 0, name: "" },
                        compensationGradeProfileLabel: data?.compensationGradeProfileObj
                            ? data?.compensationGradeProfileObj?.name
                            : "",
                        compensationGradeProfileObj: data?.compensationGradeProfileObj,
                        payPlan: data?.payPlan,
                        payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                        payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                        performencePayPlan: data?.performencePayPlan,
                        performencePayPlanLabel: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj?.name
                            : "",
                        performencePayPlanObj: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj
                            : { id: 0, name: "" },
                        salaryAmount: data?.salaryAmount,
                        targetPay: data?.targetPay,
                        performencePay: data?.performencePay,
                        joiningBonus: data?.joiningBonus,
                        recoveryDuration: data?.recoveryDuration,
                        allowancePlanObj:
                            data?.allowancePlanObj?.length > 0
                                ? data?.allowancePlanObj
                                : inputDataToDHL?.allowancePlanObj,
                        allowanceAmountObj:
                            data?.allowanceAmountObj?.length > 0
                                ? data?.allowanceAmountObj
                                : inputDataToDHL?.allowanceAmountObj,
                        compensationLetterUploadId: data?.compensationLetterUploadId,
                        isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                        offerLetterUploadId: data?.offerLetterUploadId,
                        isOfferLetteruploaded: data?.isOfferLetteruploaded,
                        siemensLogo:
                            data?.companyId === 1 || data.companyId === 2
                                ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                                : "../assets/images/sie-logo-petrol-rgb.png",
                        digiLetterWorkflowId: data?.digiLetterWorkflowId,
                        isCompensationAccepted: data?.isCompensationAccepted,
                        isCompensationRejected: data?.isCompensationRejected,
                        digiLetterStatusId: data?.digiLetterStatusId,
                        isOfferAccepted: data?.isOfferAccepted,
                        isOfferRejected: data?.isOfferRejected,
                        isSuperannuation: data?.isSuperannuation,
                    }
                    
                    setInputDataToDHL(inputUpdatedDHL);
                    if (inputUpdatedDHL.staff != null && inputUpdatedDHL.staff > 0)
                        localStorage.setItem("showCompensation1", "true");
                    if (inputUpdatedDHL.letterType != null && inputUpdatedDHL.letterType > 0)
                        localStorage.setItem("showCompensation2", "true");

                    dispatch(inputDataDHLAction(inputUpdatedDHL));
                    
                    setDigiLetterDataState(data);
                    handleLoader(false, "");
                    //alert(stat);
                    //if (stat == "Success") {
                    //    handleDialogOpenAlert(
                    //        "Success",
                    //        "Letter Uploaded Successfully",
                    //        "Letter Uploaded Successfully"
                    //    );
                    //    //alert("Success");
                    //    localStorage.removeItem("Status");
                    //}
                    //else if (stat == "Failed") {
                    //    handleDialogOpenAlert(
                    //        "Failure",
                    //        "Letter Uploaded Failed",
                    //        "Letter Upload Failed"
                    //    );
                    //}
                    handleLoader(false, "");
                }
                handleLoader(false, "");
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
            });
    }
    function getCompensationFactors(grip, letterType) {
        setCompensationProgressProps({
            ...compensationProgressProps,
            isEnable: true,
            loaderText: "Fetching compensation factors....",
        });
        const token = props.token;
        let options = {
            method: "get",
            url: "OnboardingDHLMerge/GetCompensationFactors",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                grip: grip,
                letterType: letterType,
            },
        };
        axios(options)
            .then((response) => {
                if (response.data.result.response) {
                    setCompensationProgressProps({
                        ...compensationProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    let data = response.data.result.objResult;
                    handleLoader(true, "Fetching Allowance Details....");
                    setCompensationFactors({
                        ...compensationFactors,
                        compensationFactorId: data?.compensationFactorId,
                        gripId: data?.gripId,
                        letterTypeId: data?.letterTypeId,
                        basicSalaryFactor: data?.basicSalaryFactor,
                        performencePayFactor: data?.performencePayFactor,
                        pfFactor: data?.pfFactor,
                        gratuityFactor: data?.gratuityFactor,
                        totalFixedPayFactor: data?.totalFixedPayFactor,
                        safFactor: data?.safFactor,
                        totalFixedPayWithSafFactor: data?.totalFixedPayWithSafFactor,
                    });
                    handleLoader(false, "");
                }
                else {
                    setCompensationProgressProps({
                        ...compensationProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    handleDialogOpenAlert(
                        "Alert",
                        "Fetch Compensation Factors Alert",
                        response.data.result.responseMsg
                    );
                }
            })
            .catch((error) => {
                setCompensationProgressProps({
                    ...compensationProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                handleDialogOpenAlert(
                    "Exception",
                    "Fetch Compensation Factors Error",
                    error
                );
            });
    }
    async function getInsuranceData(grip) {
        handleLoader(true, "Fetching Insurance Data");
        const token = props.token;
        let options = {
            method: "get",
            url: "OnboardingDHLMerge/GetInsurancesDataPerGrip",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                grip: grip,
            },
        };
        let response = await axios(options);
        if (response.data.result.response) {
            let data = response.data.result.objResult;
            setInsuranceData({
                ...insuranceData,
                id: data?.id,
                gripId: data?.gripId,
                ghicAmount: data?.ghicAmount,
                gpaicAmount: data?.gpaicAmount,
                gtLicAmount: data?.gtLicAmount,
                edliAmount: data?.edliAmount,
                micAmount: data?.micAmount,
            });
        }
        handleLoader(false, "");
    }

    useEffect(() => {
        debugger;
        dispatch(inputDataDHLAction(inputDataToDHL));
        console.log("DHl PRE Useeffect stored", storedInputDataDHL);
    }, [])
    useEffect(() => {
        
        //dispatch(inputDataDHLAction(inputDataToDHL));
        localStorage.setItem("showCompensation1", "false");
        localStorage.setItem("showCompensation2", "false");
        if (props.token) {
            if (encryptedId) {
                getDigiLetterDetails(encryptedId);
            }
        }
    }, [encryptedId, props.token]);
    //useEffect(() => {
    //    debugger;
    //    if (props.token) {
    //        if (inputDataToDHL?.grip > 0 && inputDataToDHL?.letterType > 0) {
    //            getCompensationFactors(
    //                inputDataToDHL?.grip,
    //                inputDataToDHL?.letterType
    //            );
    //        }
    //    }
    //}, [inputDataToDHL?.grip, inputDataToDHL?.letterType, props.token]);
    //useEffect(() => {
    //    debugger;
    //    if (props.token) {
    //        if (inputDataToDHL?.grip > 0) {
    //            getInsuranceData(inputDataToDHL?.grip);
    //        }
    //    }
    //}, [inputDataToDHL?.grip, props.token]);
    //useEffect(() => {
    //    if (inputDataToDHL?.letterType == 7) {
    //        if (inputDataToDHL?.targetPay && inputDataToDHL.targetPay <= 450000) {

    //            gbsCompensationForBelow15KBasic(inputDataToDHL?.targetPay);
    //        }
    //        else if (inputDataToDHL?.targetPay && inputDataToDHL.targetPay > 450000) {
    //            if (inputDataToDHL?.targetPay && inputDataToDHL?.grip > 10) {
    //                compensationCalculationforPL13_14(inputDataToDHL?.targetPay);
    //            }

    //            else if (inputDataToDHL?.grip <= 10) {
    //                compensationCalculation(inputDataToDHL?.targetPay);
    //            }

    //        }


    //        //else {
    //        //    handleDialogOpenAlert(
    //        //        "Alert",
    //        //        "For GBS Below 15K: Target Pay should be less than " + inputDataToDHL.targetPay + ".",
    //        //        "For GBS Below 15K: Target Pay should be less than " + inputDataToDHL.targetPay + ".",
    //        //    );


    //        //    inputDataToDHL.targetPay = 0.0;
    //        //    inputDataToDHL.allowanceAmountObj[0].allowanceAmount = 0.0;
    //        //    inputDataToDHL.allowanceAmountObj[1].allowanceAmount = 0.0;
    //        //    // let basefppm = 0.0;
    //        //    // let totalretiralperannum = 0.0;
    //        //    // setcompensationAmount({
    //        //    // ...compensationAmount,
    //        //    // bfppm: basefppm,

    //        //    // })
    //        //    inputDataToDHL.salaryAmount = 0.0;


    //        //}


    //    }
    //    else
    //        if (inputDataToDHL?.letterType != 7) {
    //            if (inputDataToDHL?.targetPay && inputDataToDHL?.grip > 10) {
    //                compensationCalculationforPL13_14(inputDataToDHL?.targetPay);
    //            }

    //            else if (inputDataToDHL?.grip <= 10) {
    //                compensationCalculation(inputDataToDHL?.targetPay);
    //            }
    //        }

    //}, [
    //    inputDataToDHL?.targetPay,
    //    inputDataToDHL?.letterType,
    //    inputDataToDHL?.grip,
    //    compensationFactors,
    //]);
    let [compensationProgressProps, setCompensationProgressProps] = useState({
        isEnable: false,
        loaderText: "",
    });
    let [letterDetailsProgressProps, setLetterDetailsProgressProps] = useState({
        isEnable: false,
        loaderText: "",
    });
    const alertDialogComponent = (
        <Dialog
            open={dialogPropsALert?.open}
            onClose={handleCloseAlert}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
        >
            <DialogTitle
                className={dialogPropsALert?.className}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
            >
                {dialogPropsALert?.type}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="clr-dark-blue">
                    {dialogPropsALert?.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-content">
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleCloseAlert}
                    className="ns-btn-secondary-light"
                >
                    <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
    const confirmDialogComponenet = (
        <Dialog
            open={dialogPropsConfirm?.open}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadClear"
        >
            <DialogTitle
                className={dialogPropsConfirm?.className}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
            >
                {dialogPropsConfirm?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="clr-dark-blue">
                    {dialogPropsConfirm?.content}
                    {dialogPropsConfirm?.title?.toLowerCase()?.includes("recall") ? (
                        <TextField
                            className="mt-1 lightInputFields"
                            id="recallReason"
                            label="Reason Of Recall Offer Letter"
                            variant="filled"
                            fullWidth
                            multiline
                            maxRows={4}
                            placeholder="Enter a valid reason for recall the offer letter"
                            name="rejectionReason"
                            value={objRecallOfferLetter?.reasonOfRecall}
                            onChange={(e) =>
                                setObjRecallOfferLetter({
                                    ...objRecallOfferLetter,
                                    reasonOfRecall: e.target.value,
                                })
                            }
                            InputLabelProps={{ shrink: true }}
                            required
                            error={objRecallOfferLetter?.errorTextReason}
                            helperText={objRecallOfferLetter?.errorTextReason}
                            style={{ backgroundColor: "#E8F8F8" }}
                        />
                    ) : (
                        <></>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-content">
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleConfirm}
                    className="ns-btn-secondary-light mr-15px"
                >
                    <span className="f-16 fw-bold clr-white">Confirm</span>
                </Button>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleCloseConfirm}
                    className="ns-btn-secondary-light"
                >
                    <span className="f-16 fw-bold clr-white">Close</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
    return (
        <ThemeProvider theme={themeDHL}>
            <div className="master-layout-block no-print">
                <Box paddingTop={{ xs: "3rem", sm: "2rem" }}>
                    <h3 className="clr-dark-blue d-flex jc-center">{formHeaderText}</h3>
                </Box>
                <form className="d-flex jc-center">
                    <Grid
                        container
                        xs={12}
                        className="d-flex fd-column box-shadow px-2 mb-2 bdr-radius "
                    >
                        <CanididateDetailsDHL
                            token={props.token}
                            inputDataToDHL={inputDataToDHL}
                            setInputDataToDHL={setInputDataToDHL}
                            handleDialogOpenAlert={handleDialogOpenAlert}
                            errorTexts={errorTexts}
                            setErrorTexts={setErrorTexts}
                        />
                        <JobDetailsDHL
                            token={props.token}
                            inputDataToDHL={inputDataToDHL}
                            handleDialogOpenAlert={handleDialogOpenAlert}
                            handleChange={handleChange}
                            handleAutoCompleteChange={handleAutoCompleteChange}
                            handleAutoCompleteTextChange={handleAutoCompleteTextChange}
                            errorTexts={errorTexts}
                            setErrorTexts={setErrorTexts}
                        />
                        <LetterTypeSelectionDHL
                            token={props.token}
                            inputDataToDHL={inputDataToDHL}
                            setInputDataToDHL={setInputDataToDHL}
                            //handleChange={handleChange}
                            handleAutoCompleteChange={handleAutoCompleteChange}
                            handleAutoCompleteTextChange={handleAutoCompleteTextChange}
                            errorTexts={errorTexts}
                            setErrorTexts={setErrorTexts}
                        />
                        <CompensationDetailsDHL
                                token={props.token}
                                inputDataToDHL={inputDataToDHL}
                                setInputDataToDHL={setInputDataToDHL}
                                handleDialogOpenAlert={handleDialogOpenAlert}
                                handleChange={handleChange}
                                handleAutoCompleteChange={handleAutoCompleteChange}
                                handleAutoCompleteTextChange={handleAutoCompleteTextChange}
                                errorTexts={errorTexts}
                                setErrorTexts={setErrorTexts}
                                compensationFactors={compensationFactors}
                                compensationProgressProps={compensationProgressProps}
                                setCompensationProgressProps={setCompensationProgressProps}
                            />

                        {/*{storedInputDataDHL?.employeeType > 0 && storedInputDataDHL?.staff > 0 ? (
                        {/*    <CompensationDetailsDHL*/}
                        {/*        token={props.token}*/}
                        {/*        inputDataToDHL={inputDataToDHL}*/}
                        {/*        setInputDataToDHL={setInputDataToDHL}*/}
                        {/*        handleDialogOpenAlert={handleDialogOpenAlert}*/}
                        {/*        handleChange={handleChange}*/}
                        {/*        handleAutoCompleteChange={handleAutoCompleteChange}*/}
                        {/*        handleAutoCompleteTextChange={handleAutoCompleteTextChange}*/}
                        {/*        errorTexts={errorTexts}*/}
                        {/*        setErrorTexts={setErrorTexts}*/}
                        {/*        compensationFactors={compensationFactors}*/}
                        {/*        compensationProgressProps={compensationProgressProps}*/}
                        {/*        setCompensationProgressProps={setCompensationProgressProps}*/}
                        {/*    />*/}
                        {/*) : (*/}
                        {/*    <p className="clr-red text-center">*/}
                        {/*        <i>*/}
                        {/*            <strong>*/}
                        {/*                *Note: To access compensation details first select valid*/}
                        {/*                employee type and staff specification*/}
                        {/*            </strong>*/}
                        {/*        </i>*/}
                        {/*    </p>*/}
                        {/*)}*/}
                        <Grid
                            container
                            spacing={1}
                            className="d-flex jc-space-around fd-row mt-1"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Button
                                    xs={12}
                                    sm={3}
                                    className="ns-btn-fit-content mt-2"
                                    onClick={handleClickSave}
                                >
                                    Save
                                </Button>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="Width-100 d-flex jc-center mt-1"
                            >
                            </Grid>
                        </Grid>
                        <LetterDetailsDHL
                            token={props.token}
                            inputDataToDHL={inputDataToDHL}
                            setInputDataToDHL={setInputDataToDHL}
                            handleDialogOpenAlert={handleDialogOpenAlert}
                            handleDialogOpenConfirm={handleDialogOpenConfirm}
                            handleChange={handleChange}
                            handleClickSave={handleClickSave}
                            handleAutoCompleteChange={handleAutoCompleteChange}
                            handleAutoCompleteTextChange={handleAutoCompleteTextChange}
                            onChangeSignatoryDetails={onChangeSignatoryDetails}
                            compensationAmount={compensationAmount}
                            compensationWSAAmount={compensationWSAAmount}
                            insuranceAmount={insuranceAmount}
                            letterDetailsProgressProps={letterDetailsProgressProps}
                            setLetterDetailsProgressProps={setLetterDetailsProgressProps}
                            setCompensationProgressProps={setCompensationProgressProps}
                        />
                        <Grid
                            container
                            spacing={1}
                            className="d-flex jc-space-around fd-row mt-1"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="d-flex jc-center Width-100 mb-2"
                            >

                                <Button
                                    xs={12}
                                    sm={3}
                                    disabled={
                                        !inputDataToDHL?.isCompensationAccepted ||
                                        !inputDataToDHL?.isOfferLetteruploaded ||
                                        !inputDataToDHL?.signatoryDetails?.length > 1 ||
                                        /* parseInt(inputDataToDHL?.noOfSignatories) !== inputDataToDHL?.signatoryDetails?.length ||*/
                                        inputDataToDHL?.digiLetterStatusId === 8
                                    }
                                    className="ns-btn-fit-content mt-2 ml-2"
                                    onClick={handleClickSaveAndSendToDHL}
                                >
                                    Send For Signature
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="Width-100 d-flex jc-center mt-1"
                            >
                            </Grid>
                        </Grid>
                        {alertDialogComponent}
                        {confirmDialogComponenet}
                        {loaderProps?.isEnable ? (
                            <Loader loaderText={loaderProps?.loaderText} />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
