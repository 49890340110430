import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function STSPL(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
            
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [personalData, setPersonalData] = useState([]);
    const personalDataTemp = [];

    const [departmentData, setDepartmentData] = useState([]);
    const departmentDataTemp = [];

    const [imageData, setImageData] = useState(null);
    const [companyName, setCompanyName] = useState('');

    function SetStateAsPerFetched(data) {
        //Personal Data
        personalDataTemp.push({
            'firstName': data.personalData.firstName, 'middleName': data.personalData.middleName ? data.personalData.middleName : "",
            'lastName': data.personalData.lastName, 'fatherName': data.personalData.fatherName ? data.personalData.fatherName : (data.personalData.spouseName ? data.personalData.spouseName : ""),
            'gender': data.personalData.genders[0].name, 'maritalStatus': data.personalData.maritalStatuses[0].name,
            'dateOfBirth': data.personalData.dateOfBirthStr, 'registrationNo': data.personalData.registrationNo ? data.personalData.registrationNo : ""

        });

        setPersonalData(personalDataTemp);

        //Department and Sector Data
        departmentDataTemp.push({
            'department': data.department ? data.department : "", 'sector': data.sector ? data.sector : "",
            'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr

        });

        setDepartmentData(departmentDataTemp);
        setImageData(data.imageContent);
        setCompanyName(data.companyName);
    }


    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);
            }
        }
    }, [encryptedId, props.token, props.gid]);


    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">
            <div id="donotprintdiv" className="d-flex jc-flex-end mt-1 mb-1 mr-2 no-print" >
                <button className="width-10" onClick={() => window.print()}>Print</button>
            </div>
            <div className=" pb-2 mb-1">
                <div className="width-100 d-flex jc-space-bt mt-1">
                    <div className="pl-0">
                        <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                    </div>
                    <div className="mr-1">
                        <img src={`data:image/*;base64,${imageData}`} alt="Candidate Image" height="150" width="140" /><br />
                    </div>
                </div>
                <div className="d-flex jc-center mt-5">
                    <table className="width-90 mt-3 bdr-black border-collapse vertical-middle">
                        <caption className="mb-1 f-12 fw-bold">
                            {/*ON BOARDING DOCUMENTS*/}COVER PAGE
                        </caption>
                        <tbody className="f-12">
                            <tr className="h-40">
                                <td className="bdr-black width-20">NAME</td>
                                <td className="bdr-black">
                                    {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                                </td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">GID</td>
                                <td className="bdr-black">{props.gid}</td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">DATE OF JOINING
                                </td>
                                <td className="bdr-black">
                                    {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}
                                </td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">DIVISION</td>
                                <td className="bdr-black">
                                    {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}
                                </td>
                            </tr>
                            <tr className="h-40">
                                <td className="bdr-black">PLACE OF WORK
                                </td>
                                <td className="bdr-black">
                                    {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="ml-1 mt-2 pagebreak">
                    <div className="width-100 d-flex jc-space-bt mt-1">
                        <div className="mb-1 ml-negem-1">
                            <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                        </div>
                    </div>
                    <span className="bdr-header">EMPLOYEES
                    DECLARATION
                    </span>
                    <p className="para-pjq">Duty to Abide by the Law and various Compliance Guidelines</p>
                    <table className=" border-collapse width-90 vertical-middle mt-2">
                        <tbody className="f-12">
                            <tr className="h-20">
                                <td className="pl-1">I,  {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                                </td>
                                {/*<td className="pl-1">GID : {personalData.map(personalDataNew => <span>{personalDataNew.registrationNo}</span>)}*/}
                                {/*</td>*/}
                                <td className="pl-1">GID : <span>{props.gid}</span>
                                </td>
                                <td className="pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                            </tr>
                            { /*<tr className="h-40">
                                <td className=" pl-1">
                                    Sector :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.sector}</span>)}</td>
                                <td className=" pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                            </tr> */}
                        </tbody>
                    </table>
                    <p className="para-pjq">Hereby confirm that I have been familiarized with the duty to abide by the law in various Compliance
                    Guidelines in
                    performing my professional duties. I have also been familiarized with the rules thereto. The compliance
                    Guidelines and
                    the rules can be viewed via the following homepage intranet.siemens.com/ compliance – helpdesk
                    </p>
                    <p className="para-pjq">I am aware that Corporate Management disapproves of violating the law and that in case of violations,
                    regardless of the
                    sanctions provided by the law; I would be liable to disciplinary measures and consequences.
                    </p>

                    <div className="d-flex mt-2 ml-1 f-12 jc-space-bt">
                        <span className="pt-1">Place: {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}</span>
                        <span className="mt-1 mr-2">______________________________<br />(Signature)</span>
                    </div>
                    <span className="ml-1 f-12">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                    {/* <p className="mt-2 f-12">Name of the Manager (To whom you report administratively)</p> */}
                    <br /> <br />
                    <p className="para-pjq ">Original to be submitted to the respective UNIT/PER.<br />
                Original to the filled in individuals personal file, by UNIT/PER.</p>
                </div>

                <div className="ml-1 mt-2 pagebreak">
                    <div className="width-100 d-flex jc-space-bt mt-1">
                        <div className="mb-1 ml-negem-1">
                            <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                        </div>
                    </div>
                    <span className="bdr-header">EMPLOYEES
                    DECLARATION
                    </span>
                    <p className="para-pjq">Duty to Abide by the Law and the Business Conduct Guidelines</p>
                    <table className=" border-collapse width-90 vertical-middle mt-2">
                        <tbody className="f-12">
                            <tr className="h-20">
                                <td className="pl-1">I,   {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                                </td>
                                {/*<td className="pl-1">GID :  {personalData.map(personalDataNew => <span>{personalDataNew.registrationNo}</span>)}*/}
                                {/*</td>*/}
                                <td className="pl-1">GID : <span>{props.gid}</span>
                                </td>
                                <td className="pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                            </tr>
                            { /*<tr className="h-40">
                                <td className=" pl-1">
                                    Sector  {departmentData.map(departmentDataNew => <span>{departmentDataNew.sector}</span>)}</td>
                                <td className=" pl-1">
                                    Division :  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}</td>
                                </tr> */}
                        </tbody>
                    </table>
                    <p className="para-pjq">Hereby confirm that I have been familiarized with the duty to abide by the law in performing my
                    professional duties.
                    </p>
                    <p className="para-pjq">I am aware that Corporate Management disapproves of violating the law and that in case of violations,
                    regardless of the
                    sanctions provided by the law. I would be liable to disciplinary measures and consequences to the
                    employment
                    relationship.
                    </p>
                    <p className="para-pjq">I will further respect the provisions of the Business Conduct Guidelines. I have been familiarized with
                    the supplementary rules thereto.
                    </p>
                    <p className="para-pjq">
                        The Business Conduct Guidelines and the supplementary rules can be viewed via the following homepage
                        intranet.siemens.com/compliance – helpdesk.
                    </p>
                    <p className="para-pjq">
                        A hard copy of the Business conduct Guidelines can be obtained from the personnel department upon
                        request.
                    </p>
                    <div className="d-flex mt-2 ml-1 f-12 jc-space-bt" >
                        <span className="pt-1">Place: {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}</span>
                        <span className="mt-1 mr-2">______________________________<br />(Signature)</span>
                    </div>
                    <span className="ml-1 f-12">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                    {/* <p className="mt-2 f-12">Name of the Manager (To whom you report administratively)</p> */}
                    <br /> <br />
                    <p className="para-pjq">Original to be submitted to the respective UNIT/PER.<br />
                    Original to the filled in individuals personal file, by UNIT/PER. <br />Renewed every two years.
                    </p>
                </div>

                <div className="ml-1 mt-2 pagebreak">
                    <div className="width-100 d-flex jc-space-bt mt-1">
                        <div className="mb-1 ml-negem-1">
                            <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                        </div>
                    </div>
                    <div className="d-flex jc-center fw-bold f-14 text-uppercase">
                        <span>TERMS AND CONDITIONS OF EMPLOYMENT WITH {companyName} IN RELATION TO</span><br />
                    </div>
                    <div className="d-flex jc-center fw-bold f-14">
                        <span>INVENTIONS MADE BY THE EMPLOYEE</span>
                    </div>
                    <p className="para-pjq">
                        The employee shall disclose to the Company promptly in writing any invention (the word invention is to be understood
                        to mean anything which might be capable of protection, in any country, against copying by a patent, a registered
                        design, copyright or otherwise) which may occur to him either alone or in conjunction with any other person during
                        his employment with us with the exception of inventions which he/she knows to be not applicable to the fields of
                        activity of the Siemens Group of Companies.
                    </p>
                    <p className="para-pjq">
                        The employee shall inform the Company at the commencement of his employment with the Company of all inventions
                        previously made by him, which he is not precluded by contract from disclosing to the Company, and for which an
                        application for a patent or for a registered design has not yet been made.
                    </p>
                    <p className="para-pjq">
                        The Company will be free to adopt any of the employee’s inventions disclosed to the Company, at the commencement of
                        his employment with the Company and conceived during his employment with the Company and to assign to others the
                        right to adopt it and the Company will be absolutely entitled to any industrial or commercial protection rights
                        anywhere in the world including rights arising from the obtaining of letters of patent or design registration in
                        respect of such invention. The Company will notify to the employee if and to what extent the Company will adopt the
                        said rights.
                    </p>
                    <p className="para-pjq">
                        The employee shall treat all information relating to any such invention as confidential and disclose it only to his
                        superiors or any such other person as his superiors direct. The employee shall not publish, except with the written
                        consent of the Company any information in relation to any such invention:
                    </p>
                    <p className="para-pjq">
                        I accept the above conditions as condition of my employment with {companyName} and undertake to abide by them while I
                        remain in the employment of the Company.
                    </p>

                    <p className="mt-2 ml-1 f-12">Name:  {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}</p>
                    <p className="ml-1 f-12">GID : {props.gid}</p>
                    <p className="ml-1 f-12">Signature:</p>

                </div>

                <div className="ml-1 mt-2 pagebreak">
                    <div className="width-100 d-flex jc-space-bt mt-1">
                        <div className="mb-1">
                            <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                        </div>
                    </div>
                    <div className="d-flex jc-center mb-0">
                        <h5>Annexure III</h5>
                    </div>
                    <p className="d-flex jc-center m-0 f-14 fw-bold">Terms & Conditions of Employment</p>

                    <p className="para-pjq">
                        Employee shall observe and conform to such duties, directions and instructions as communicated to him by the Company
                        and those in authority over him.
                    </p>
                    <p className="para-pjq">
                        Employee shall not at any time hereafter, without the consent in writing of the Company except under legal process,
                        divulge or utilize any matter relating to the Company's transactions or dealings, which are of confidential nature.
                    </p>
                    <p className="para-pjq">
                        Employee shall not use any of the designs, drawings, software, literature, and machines etc. of the Company for any
                        purpose other than Company's business.
                    </p>
                    <p className="para-pjq">
                        All software including packages as well as its associated documentation developed by the employee in the course of
                        the duties shall be sole and exclusive property of the Company.
                    </p>
                    <p className="para-pjq">
                        Employee shall be true and faithful to the Company in all his accounts, dealing and transactions, relating to the
                        business of the Company and shall at all times, when required, render a true and just account thereof to the Company
                        or such persons as shall be authorized to receive the sum
                    </p>
                    <p className="para-pjq">
                        Employee shall not during the continuance of his employment, without the consent of the Company in writing, be
                        employed or interested, directly or indirectly in any other trade or business, employment or occupation whatsoever
                        and will devote the whole of his time and attention to his dutie
                    </p>
                    <p className="para-pjq">
                        Employee shall be responsible for safekeeping and return, in good condition and order, of all Company's property,
                        which may be in his use, custody or charge.
                    </p>
                    <p className="para-pjq">
                        A high standard code of conduct is expected from an employee and any behavior reflecting unfavorably on him or the
                        Company is questionable and liable for disciplinary action. Employee shall also ensure compliance to the Business
                        Conduct Guidelines (BCG) that are globally binding rules for all employees applicable across Siemens companies, the
                        details of which are available on the HR Intranet. In addition, The BCG declaration form (available on the HR
                        intranet) is required to be signed by every employee upon joining/promotion and once in every two years thereafter.
                    </p>
                    <p className="para-pjq">
                        Employee shall abide by the information security policy of the organization and adhere to it.
                    </p>
                    <p className="para-pjq">
                        At the time of separation from the services of the Company, the employee is expected to settle all his outstanding
                        dues towards the Company including the Liquidated Damages, if any, etc. at once. In case of any default/ delay in
                        settlement of outstanding dues, the Company will be free to recover such dues by appropriate means, with interest at
                        the market rate.
                    </p>
                    <p className="para-pjq">
                        Employee shall also be required to abide by terms and conditions in addition to those mentioned above which are in
                        force for the time being, or may be framed from time to time.
                    </p>
                    <p className="para-pjq">
                        I confirm and accept the above
                    </p>

                    <p className="ml-1 f-12">_________________________<br />Signature</p>

                    <p className="mt-2 ml-1 f-12">Name:  {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}</p>
                    <p className="ml-1 f-12">GID : {props.gid}</p>
                    <p className="ml-1 f-12">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</p>

                </div>
            </div>
        </div >
    );

}