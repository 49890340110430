import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios'
import { Alert } from '@material-ui/lab';
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                '&$disabled': {
                    backgroundColor: '#454545 !important',
                },
            }
        },
        MuiFilledInput: {
            root: {
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8"
                },
                "&$disabled": {
                    "pointerEvent": "none",
                    "color": "#707080",
                    "backgroundColor": "#001035",
                    "border": "2px solid #707070",
                    "borderRadius": "0",
                    "&:before": {
                        "borderBottom": "1px solid #001035",
                    },
                    "&:hover:before": {
                        "borderBottom": "1px solid #001035",
                        "borderBottomStyle": "solid"
                    }
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                backgroundColor: "#E8F8F8",

            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                '&$disabled': {
                    backgroundColor: '#454545 !important',
                },
            },
        },
        MuiTabs: {
            scroller: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            }
        },

        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC"
                }
            }
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68"
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {

                    backgroundColor: "#CCF5F5",
                }
            }
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949"
                },
                "&:after": {
                    borderBottom: "2px solid #002949"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2"
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5"
            }
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            }
        },
        MuiPaper: {
            rounded: {
                border: `1px solid #FFFFFF`,
                marginBottom: "5px",
                color: "#000028"

            }
        },
        MuiToolbar: {
            root: {
                background: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none"
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`
                }
            },
            menuItem: {
                color: "#FFFFFF"
            },
            selectIcon: {
                color: "#00CCCC"
            },
            input: {
                backgroundColor: "#000028"
            }
        },
        MuiTypography: {
            caption: {
                color: "#000028"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#000028",
                "&$focused": {
                    color: "#000028",
                },
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
                "& div:hover": {
                    color: "#00CCCC"
                }
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            }
        },
        MuiTableCell: {
            root: {
                color: "#000028 !important",
                background: "#E8F8F8 !important",
                padding: "10px"
            },
            body: {
                background: "#FFFFFF",
                color: "#000028"
            },
            head: {
                lineHeight: "1rem"
            }
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px"
            }
        },
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#000028"
            },
            container: {
                background: "#000028"
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF"
            },
            switchHeader: {
                color: "#FFFFFF"
            },
            daysHeader: {
                color: "#FFFFFF"
            },
            dayLabel: {
                color: "#FFFFFF"
            }
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF"
            }
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: "#000028"
        //    }
        //},
        MuiDialogActions: {
            root: {
                background: "#000028"
            }
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF"
            }
        },
        MuiTable: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MTableToolbar: {
            searchField: {
                paddingLeft: "0px !important",
            }
        }
    }
});
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const api = axios.create({
    baseURL: `https://reqres.in/api`
})




function MediClaimEnrollment(props) {
    const [data, setData] = useState([]); //table data

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState('');
    const [openClassName, setClassName] = useState('');
    const [openType, setOpenType] = useState('');

    const handleClose = () => {

        GetMediClaimData(props.id);
        setOpen(false);
        //window.location.reload()
    };

    //fetch Relationship
    const [itemsRelationship, setItemsRelationship] = useState([]);
    async function getRelationship() {
        const response = await fetch("OnBoardingForm/GetAllRelationship");
        const jsonItems = await response.json();
        setItemsRelationship(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    }

    var columns = [
        { title: 'Row ID', field: 'mediClaimDetailsId', hidden: true },
        { title: 'Applicant ID*', field: 'applicantId', hidden: true },
        { title: 'Name', field: 'nomineeName' },
        {
            title: 'Date Of Birth*', field: 'birthDate', type: 'date',

        },
        {
            title: 'Relationship*', field: 'relationship',
            editComponent: ({ value, onChange, rowData }) => (
                <Select
                    value={value}
                    onChange={(event) => onChange(event.target.value)}>
                    {itemsRelationship.map(item => <MenuItem
                        key={item.value}
                        value={item.value}
                    >
                        {item.label}</MenuItem>)}
                </Select>
            )
        },

    ];

    //for error handling
    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])
    const handleErrorClose = (e) => {
        setIserror(false);
    }

    useEffect(() => {


        getRelationship();


    }, []);

    //useEffect(() => {
    //    api.get("/users")
    //        .then(res => {
    //            setData(res.data.data)
    //        })
    //        .catch(error => {
    //            console.log("Error")
    //        })
    //}, [])


    function GetMediClaimData(id) {
        axios.get('/OnBoardingForm/GetMediClaimData/' + id)
            .then((res) => {
                setData(res.data)

            })
            .catch(error => {
                console.log("Error")
            })
    }

    useEffect(() => {
        if (props.id != null && props.id != undefined) {
            GetMediClaimData(props.id);
        }

    }, [props.id])

    //useEffect(() => {
    //    axios.get('/OnBoardingForm/GetMediClaimData/' + props.id)
    //        .then((res) => {
    //            setData(res.data)
    //        })
    //        .catch(error => {
    //            console.log("Error")
    //        })

    //}, [])


    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []
        if (newData.name === "") {
            errorList.push("Please enter Name")
        }
        if (newData.dateOfBirth === "") {
            errorList.push("Please enter Date Of Birth")
        }
        if (newData.relationship === "") {
            errorList.push("Please enter Relationship")
        }

        newData.mediClaimDetailsId = oldData.tableData.id;
        newData.applicantId = props.id;
        newData.userId = props.loginDetail[0].userid;

        if (errorList.length < 1) {
            //api.patch("/users/" + newData.id, newData)
            //    .then(res => {
            //        const dataUpdate = [...data];
            //        const index = oldData.tableData.id;
            //        dataUpdate[index] = newData;
            //        setData([...dataUpdate]);
            //        resolve()
            //        setIserror(false)
            //        setErrorMessages([])
            //    })

            axios.put('/OnBoardingForm/UpdateMediClaimData', newData)

                .then(response => {
                    if (response.data.response) {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-success");
                        setOpenType("Success");
                        resolve();
                    }
                    else {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-error");
                        setOpenType("Error");
                        resolve();
                    }
                })
                .catch(error => {
                    setErrorMessages(["Update failed! Server error"])
                    setIserror(true)
                    resolve()

                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()

        }

    }

    const handleRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
        if (newData.nomineeName === undefined) {
            errorList.push("Please enter Name")
        }
        if (newData.birthDate === undefined) {
            errorList.push("Please enter Date Of Birth")
        }
        if (newData.relationship === undefined) {
            errorList.push("Please enter Relationship")
        }

        newData.applicantId = props.id;
        newData.userId = props.loginDetail[0].userid;

        if (errorList.length < 1) { //no error
            //api.post("/users", newData)
            //    .then(res => {
            //        let dataToAdd = [...data];
            //        dataToAdd.push(newData);
            //        setData(dataToAdd);
            //        resolve()
            //        setErrorMessages([])
            //        setIserror(false)
            //    })

            axios.post('/OnBoardingForm/SaveMediClaimData', newData)
                .then(response => {
                    if (response.data.response) {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-success");
                        setOpenType("Success");
                        resolve();
                    }
                    else {
                        setOpen(true);
                        setOpenAlert(response.data.responseMsg);
                        setClassName("clr-dark-blue bg-error");
                        setOpenType("Error");
                        resolve();
                    }
                })

                .catch(error => {
                    setErrorMessages(["Cannot add data. Server error!"])
                    setIserror(true)
                    resolve()
                })
        } else {
            setErrorMessages(errorList)
            setIserror(true)
            resolve()
        }


    }

    const handleRowDelete = (oldData, resolve) => {

        //api.delete("/users/" + oldData.id)
        //    .then(res => {
        //        const dataDelete = [...data];
        //        const index = oldData.tableData.id;
        //        dataDelete.splice(index, 1);
        //        setData([...dataDelete]);
        //        resolve()
        //    })

        oldData.mediClaimDetailsId = oldData.tableData.id;
        oldData.applicantId = props.id;

        axios.put('/OnBoardingForm/DeleteMediClaimData', oldData)

            .then(response => {
                if (response.data.response) {
                    setOpen(true);
                    setOpenAlert(response.data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    resolve();
                }
                else {
                    setOpen(true);
                    setOpenAlert(response.data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    resolve();
                }
            })

            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    var formDetails = {
        
        'UserId': props.userId,
        'ApplicantId': props.id
        
    }

    const selectFamilyHandler = (event) => {

        formDetails.ApplicantId = props.id;
        formDetails.UserId = props.userId;
    
        
            SelectFamilyData(formDetails);
        


        //setOpen(true);
        //setOpenAlert("Yet to be developed");
        //setClassName("bg-light-green clr-white");
        //setOpenType("");

        //alert("11");
    }

    async function SelectFamilyData(formDetails) {
        const options = {
            method: 'put',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDetails)
        }
        await fetch("OnBoardingForm/SelectFamilyData/", options)
            .then(response => response.json())
            .then(data => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    // alert(data.responseMsg);
                    
                }
                else {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch(error => console.log("Error:", error));
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="width-100">
                <Grid container spacing={1}>
                    <p className="f-12">
                        Note :COST FOR MEDICAL INSURANCE NOMINATION FOR PARENTS HAS TO BE BORNE BY EMPLOYEE. Once enrolled, enrollment can’t be changed in 4 years. Enrollment can be done later on joining through Employee Self Service in Nexus Tool. <a>Click here</a> for rates
                        By default, Spouse and 2 children up to age 25 are covered under company medical insurance</p>
                    <Grid item xs={12}>
                        <div>
                            {iserror &&
                                <Alert severity="error" onClose={handleErrorClose}>
                                    {errorMessages.map((msg, i) => {
                                        return <div key={i}>{msg}</div>
                                    })}
                                </Alert>
                            }
                        </div>
                        <MaterialTable
                            title="MediClaim Enrollment"
                            columns={columns}
                            data={data}
                            localization={{
                                header: {
                                    actions: ''
                                }
                            }}
                            options={{
                                search: false,
                                toolbar: props.disableValue === true ? false : true
                            }}
                            icons={tableIcons}
                            editable={{
                                isEditable: rowData => props.disableValue === false,
                                isDeletable: rowData => props.disableValue === false,

                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        handleRowUpdate(newData, oldData, resolve);

                                    }),
                                onRowAdd: (newData) =>
                                    new Promise((resolve) => {
                                        handleRowAdd(newData, resolve)
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        handleRowDelete(oldData, resolve)
                                    }),
                            }}
                        />
                    </Grid>
                    <div className="d-flex jc-center">
                        <Button item xs={12} sm={6} className="ns-btn-primary mt-2" onClick={selectFamilyHandler}>
                            <span>Select Family Data</span>
                        </Button>
                    </div>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                        id="templateUploadSuccess">
                        <DialogTitle className={openClassName} style={{ cursor: 'move' }} id="draggable-dialog-title">
                            {openType}
                        </DialogTitle>
                        <DialogContent className="bg-content">
                            <DialogContentText className="clr-black">
                                {openAlert}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="bg-popup">
                            <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                                <span className="f-16 fw-bold">OK</span>
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default MediClaimEnrollment;