import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
//import Select from '@material-ui/core/Select';
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "10px",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersMonth: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      // root: {
      //   fontSize: "0.6rem !important",
      //   backgroundColor: "#ebfbfb !important",
      //   marginBottom: "-7px ​!important",
      //   marginLeft: "0px !important",
      //   marginRight: "0px !important",
      //   color: "red !important",
      //   lineHeight: "1.2 !important",
      // },
      root: {
        position: "absolute",
        marginTop: "-5px",
        marginLeft: "-2px",
        fontSize: "10px",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function Education(props) {
  //var columns = [
  //    { title: "id", field: "id", hidden: true },
  //    { title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.first_name} /> },
  //    { title: "First name", field: "first_name" },
  //    { title: "Last name", field: "last_name" },
  //    { title: "email", field: "email" }
  //]
  const [data, setData] = useState([]); //table data

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");

  var day = 60 * 60 * 24 * 1000;

  const handleClose = () => {
    if (openType === "Success") {
      GetEducationData(props.id);
    }
    setOpen(false);
    //window.location.reload()
  };

  //fetch Educational Est.
  const [itemsEducationalEst, setItemsEducationalEst] = useState([]);
  async function getEducationalEst() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllEducationalEst", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsEducationalEst(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Certification
  const [itemsCertification, setItemsCertification] = useState([]);
  async function getCertification() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllCertification", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsCertification(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Educational Training
  const [itemsEducationalTraining, setItemsEducationalTraining] = useState([]);
  async function getEducationalTraining() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllEducationalTraining", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsEducationalTraining(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Education Type
  const [itemsEducationType, setItemsEducationType] = useState([]);
  async function getEducationType() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllEducationType", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsEducationType(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Specialization1
  const [itemsSpecialization1, setItemsSpecialization1] = useState([]);
  async function getSpecialization1() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllSpecialization1", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsSpecialization1(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Specialization2
  const [itemsSpecialization2, setItemsSpecialization2] = useState([]);
  async function getSpecialization2() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllSpecialization2", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsSpecialization2(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Country
  const [itemsCountry, setItemsCountry] = useState([]);
  async function getCountry() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllCountries", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsCountry(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch isHigherEducation
  const [itemsHigherEducation, setItemsHigherEducation] = useState([]);
  async function getHigherEducation() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetYesOrNo", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsHigherEducation(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch isMarksheetAvailable
  const [itemsMarksheetAvailable, setItemsMarksheetAvailable] = useState([]);
  async function getMarksheetAvailable() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetYesOrNo", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsMarksheetAvailable(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Education Degrees
  const [itemsEducationDegree, setItemsEducationDegree] = useState([]);
  async function getEducationDegrees() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllEducationDegrees", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsEducationDegree(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Fields Of Study
  const [itemsFieldOfStudy, setItemsFieldOfStudy] = useState([]);
  async function getFieldsOfStudy() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllFieldsOfStudy", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsFieldOfStudy(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  ////fetch MarksheetAvailableComments
  //const [itemsMarksheetAvailableComments, setItemsMarksheetAvailableComments] = useState([]);
  //async function getMarksheetAvailableComments() {
  //    const response = await fetch("PreHiringForm/GetMarksheetAvailableCommentsOnPageLoad");
  //    const jsonItems = await response.json();
  //    setItemsMarksheetAvailableComments(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
  //}

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    //else if (characterCode.includes(".")) return

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  function ValidateEducationalEst(rowData) {
    return rowData.educationalEst === null || rowData.educationalEst === ""
      ? false
      : true;
  }
  function ValidateCertificate(rowData) {
    return rowData.certificate === null || rowData.certificate === ""
      ? false
      : true;
  }
  function ValidateTraining(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }
  function ValidateEducationType(rowData) {
    return rowData.educationType === null || rowData.educationType === ""
      ? false
      : true;
  }
  function ValidateSpecialization1(rowData) {
    return rowData.specialization1 === null || rowData.specialization1 === ""
      ? false
      : true;
  }
  function ValidateCountry(rowData) {
    return rowData.country === null || rowData.country === "" ? false : true;
  }
  function ValidateCourseDurationMonths(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : !isIntegerOf2Digits(value)
      ? { isValid: false, helperText: "Only integers of 2 digits" }
      : { isValid: true, helperText: "" };
  }


  function isIntegerOf2Digits(value) {
    //const re = /^[0-9]{3,10}$/;
    const re = /^\d{0,2}$/;
    return re.test(value);
  }
  function ValidateEducationDegree(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateSpecialization2(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateHigherEducation(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateMarksheetAvailable(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}

  function ValidatePeriodFrom(rowData) {
    return !rowData.dateOfJoining
      ? { isValid: false, helperText: "*Required" }
      : !rowData.dateOfLeaving &&
        new Date(rowData.dateOfJoining) > new Date(rowData.dateOfLeaving)
      ? { isValid: false, helperText: "Must be less than date of leaving" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidatePeriodTo(rowData) {
    return !rowData.dateOfLeaving
      ? { isValid: false, helperText: "*Required" }
      : !rowData.dateOfJoining &&
        new Date(rowData.dateOfJoining) > new Date(rowData.dateOfLeaving)
      ? { isValid: false, helperText: "Must be greater than date of joining" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateCourseDurationYears(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : !isIntegerOf2Digits(value)
      ? { isValid: false, helperText: "Only integers of 2 digits" }
      : { isValid: true, helperText: "" };
  }{}

  function ValidateMarksheetSubmissionDate(rowData) {
    return rowData.marksheetAvailable &&
      (rowData.marksheetAvailable === "No" ||
        rowData.marksheetAvailable.label === "No") &&
      !rowData.marksheetSubmissionDate
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateInstitueName(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateTotalMarks(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidateMarksObtained(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}
  function ValidatePercentage(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }{}

  var columns = [
    { title: "Row ID", field: "employeeEducationId", hidden: true },
    { title: "Applicant ID*", field: "applicantId", hidden: true },

    {
      title: "StartDate*",
      field: "dateOfJoining",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      validate: (rowData) => ValidatePeriodFrom(rowData),
      editComponent: (props) => {
        const dateFrom = props.rowData.dateOfJoining;
        const dateTo = props.rowData.dateOfLeaving;
        let errorText = "";
        var errorState = false;
        if (dateFrom === null || dateFrom === "") {
          errorState = true;
          errorText = "*Required";
        } else {
          const dtFrom = new Date(dateFrom);
          const dtTo = new Date(dateTo);
          if (dateTo !== null && dateTo !== "" && dtFrom > dtTo) {
            errorText = "Must be less than end date";
            errorState = true;
          } else {
            errorText = "";
            errorState = false;
          }
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              autoOk
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              clearable
              views={["year", "month", "date"]}
              disableFuture
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    {
      title: "EndDate*",
      field: "dateOfLeaving",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      validate: (rowData) => ValidatePeriodTo(rowData),
      editComponent: (props) => {
        const dateFrom = props.rowData.dateOfJoining;
        const dateTo = props.rowData.dateOfLeaving;
        let errorText = "";
        var errorState = false;
        if (dateTo === null || dateTo === "") {
          errorText = "*Required";
          errorState = true;
        } else {
          const dtFrom = new Date(dateFrom);
          const dtTo = new Date(dateTo);
          if (dateFrom !== null && dateFrom !== "" && dtFrom > dtTo) {
            errorText = "Must be ahead of start date";
            errorState = true;
          } else {
            errorText = "";
            errorState = false;
          }
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              autoOk
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              minDate={
                props.rowData.dateOfJoining
                  ? new Date(props.rowData.dateOfJoining).getTime() + day
                  : null
              }
              clearable
              views={["year", "month", "date"]}
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    {
      title: "Education Degree*",
      field: "educationDegree",
      validate: (rowData) => ValidateEducationDegree(rowData.educationDegree),
      editComponent: (props) => {
        const educationDegreeData = props.rowData.educationDegreeData
          ? props.rowData.educationDegreeData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const educationDegree = props.rowData.educationDegree;
        const customStyles =
          educationDegree !== undefined &&
          educationDegree !== null &&
          educationDegree !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsEducationDegree}
            value={
              typeof props.value === "string"
                ? educationDegreeData
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    
    {
      title: "Educational Est.*",
      field: "educationalEst",
      hidden: true,
      //validate: (rowData) => ValidateEducationalEst(rowData),
      editComponent: (props) => {
        const educationalEstData = props.rowData.educationalEstData
          ? props.rowData.educationalEstData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const educationalEst = props.rowData.educationalEst;
        const customStyles =
          educationalEst !== undefined &&
          educationalEst !== null &&
          educationalEst !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsEducationalEst}
            value={
              typeof props.value === "string" ? educationalEstData : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Certification*",
      field: "certificate",
      hidden: true,
      //validate: (rowData) => ValidateCertificate(rowData),
      editComponent: (props) => {
        const certificateData = props.rowData.certificateData
          ? props.rowData.certificateData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const certificate = props.rowData.certificate;
        const customStyles =
          certificate !== undefined &&
          certificate !== null &&
          certificate !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsCertification}
            value={
              typeof props.value === "string" ? certificateData : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Educational Training*",
      field: "training",
      hidden: true,
      //validate: (rowData) => ValidateTraining(rowData.training),
      editComponent: (props) => {
        const trainingData = props.rowData.trainingData
          ? props.rowData.trainingData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const training = props.rowData.training;
        const customStyles =
          training !== undefined && training !== null && training !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsEducationalTraining}
            value={typeof props.value === "string" ? trainingData : props.value}
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Institute Or School Name*",
      field: "instituteCollege",
      validate: (rowData) => ValidateInstitueName(rowData.instituteCollege),
    },
    {
      title: "Education Type*",
      field: "educationType",
      hidden: true,
      //validate: (rowData) => ValidateEducationType(rowData),
      editComponent: (props) => {
        const educationTypeData = props.rowData.educationTypeData
          ? props.rowData.educationTypeData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const educationType = props.rowData.educationType;
        const customStyles =
          educationType !== undefined &&
          educationType !== null &&
          educationType !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsEducationType}
            value={
              typeof props.value === "string" ? educationTypeData : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Specialization 1*",
      field: "specialization1",
      hidden: true,
      //validate: (rowData) => ValidateSpecialization1(rowData),
      editComponent: (props) => {
        const specialization1Data = props.rowData.specialization1Data
          ? props.rowData.specialization1Data.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const specialization1 = props.rowData.specialization1;
        const customStyles =
          specialization1 !== undefined &&
          specialization1 !== null &&
          specialization1 !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsSpecialization1}
            value={
              typeof props.value === "string"
                ? specialization1Data
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Specialization (Technical Or Non-Technical)*",
      field: "specialization2",
      validate: (rowData) => ValidateSpecialization2(rowData.specialization2),
      editComponent: (props) => {
        const specialization2Data = props.rowData.specialization2Data
          ? props.rowData.specialization2Data.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const specialization2 = props.rowData.specialization2;
        const customStyles = specialization2
          ? {
              control: (provided, state) => ({
                ...provided,
              }),
            }
          : {
              control: (provided, state) => ({
                ...provided,
                borderColor: "red !important",
              }),
            };
        return (
          <Select
            options={itemsSpecialization2}
            value={
              typeof props.value === "string"
                ? specialization2Data
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Country Of Education*",
      field: "country",
      hidden: true,
      //validate: (rowData) => ValidateCountry(rowData),
      editComponent: (props) => {
        const countryData = props.rowData.countryData
          ? props.rowData.countryData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const country = props.rowData.country;
        const customStyles =
          country !== undefined && country !== null && country !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsCountry}
            value={typeof props.value === "string" ? countryData : props.value}
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Course Duration (Months)*",
      field: "courseDurationMonths",
      hidden: true,
      type: "numeric",
      //validate: (rowData) => ValidateCourseDurationMonths(rowData),
      editComponent: (props) => {
        const courseDurationMonths = props.rowData.courseDurationMonths;
        let errorText = "";
        var errorState = false;
        if (courseDurationMonths === null || courseDurationMonths === "") {
          errorState = true;
          errorText = "*Required";
        } else {
          errorText = "";
          errorState = false;
        }

        return (
          <TextField
            defaultValue={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            type="number"
            onKeyDown={handleKeypress}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },
    {
      title: "Course Duration (Years)*",
      field: "courseDurationYears",
      type: "numeric",
      validate: (rowData) =>
        ValidateCourseDurationYears(rowData.courseDurationYears),
      // editComponent: (props) => {
      //   const courseDurationYears = props.rowData.courseDurationYears;
      //   let errorText = "";
      //   var errorState = false;
      //   if (courseDurationYears === null || courseDurationYears === "") {
      //     errorState = true;
      //     errorText = "*Required";
      //   } else {
      //     errorText = "";
      //     errorState = false;
      //   }

      //   return (
      //     <TextField
      //       defaultValue={props.value}
      //       onChange={(e) => props.onChange(e.target.value)}
      //       type="number"
      //       onKeyDown={handleKeypress}
      //       helperText={errorText}
      //       error={errorState}
      //     />
      //   );
      // },
    },
    {
      title: "Total Marks*",
      field: "marksTotal",
      validate: (rowData) => ValidateTotalMarks(rowData.marksTotal),
    },
    {
      title: "Marks Obtained*",
      field: "marksObtained",
      validate: (rowData) => ValidateMarksObtained(rowData.marksObtained),
    },
    {
      title: "Percentage*",
      field: "percentageGrade",
      validate: (rowData) => ValidatePercentage(rowData.percentageGrade),
    },
    {
      title: "Is This Your Highest Education*",
      field: "higherEducation",
      editComponent: (props) => {
        const objHighest = data.find((c) => c.higherEducation === "Yes");
        const objHighestOnEdit = data.find(
          (c) =>
            c.higherEducation === "Yes" &&
            c.employeeEducationId !== props.rowData.employeeEducationId
        );
        const higherEducationData = props.rowData.higherEducationData
          ? props.rowData.higherEducationData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : !props.rowData.employeeEducationId && objHighest
          ? itemsHigherEducation.find((c) => c.value === 2)
          : null;
        const higherEducation =
          !props.rowData.employeeEducationId && objHighest
            ? higherEducationData
            : props.rowData.higherEducation;
        const customStyles = higherEducation
          ? {
              control: (provided, state) => ({
                ...provided,
              }),
            }
          : {
              control: (provided, state) => ({
                ...provided,
                borderColor: "red !important",
              }),
            };
            const value =
              typeof props.value === "string" ||
              (!props.rowData.employeeEducationId && objHighest)
                ? higherEducationData
                : props.value;
                if (!props.rowData.employeeEducationId && objHighest) {
                  props.rowData.higherEducation = value;
                }
        return (
          <Select
            options={itemsHigherEducation}
            value={value}
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
            isDisabled={
              (!props.rowData.employeeEducationId && objHighest) ||
              objHighestOnEdit
            }
          />
        );
      },
      validate: (rowData) => ValidateHigherEducation(rowData.higherEducation),
    },

    {
      title: "Is Marksheet & certificate available*",
      field: "marksheetAvailable",
      validate: (rowData) =>
        ValidateMarksheetAvailable(rowData.marksheetAvailable),
      editComponent: (props) => {
        const marksheetAvailableData = props.rowData.marksheetAvailableData
          ? props.rowData.marksheetAvailableData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const marksheetAvailable = props.rowData.marksheetAvailable;
        const customStyles = marksheetAvailable
          ? {
              control: (provided, state) => ({
                ...provided,
              }),
            }
          : {
              control: (provided, state) => ({
                ...provided,
                borderColor: "red !important",
              }),
            };
        return (
          <Select
            options={itemsMarksheetAvailable}
            value={
              typeof props.value === "string"
                ? marksheetAvailableData
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
      },
    {
      title: "Marksheet & certificate submission date",
      field: "marksheetSubmissionDate",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      //initialEditValue: 'If No, Please fill: I hereby mention that I will submit the marksheet / certificate on or before ____________________. In case, I am unable to provide the marksheet / certificate on or before ____________________, company can take any suitable action against me.'
      validate: (rowData) => ValidateMarksheetSubmissionDate(rowData),
      editComponent: (props) => {
        const marksheetAvailable = props.rowData.marksheetAvailable;
        const marksheetSubmissionDate = props.rowData.marksheetSubmissionDate;

        let errorText = "";
        var errorState = false;
        if (
          marksheetAvailable &&
          (marksheetAvailable === "No" || marksheetAvailable.label === "No") &&
          !marksheetSubmissionDate
        ) {
          errorState = true;
          errorText = "*Required";
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              autoOk
              //value={(props.value == undefined ? null : props.value)}
              value={
                (typeof marksheetAvailable === "string" &&
                  marksheetAvailable === "Yes") ||
                (typeof marksheetAvailable === "object" &&
                  marksheetAvailable &&
                  marksheetAvailable.label === "Yes")
                  ? null
                  : props.value == undefined
                  ? null
                  : props.value
              }
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              clearable
              views={["year", "month", "date"]}
              disablePast
              helperText={errorText}
              error={errorState}
              disabled={
                (typeof marksheetAvailable === "string" &&
                  marksheetAvailable === "Yes") ||
                (typeof marksheetAvailable === "object" &&
                  marksheetAvailable &&
                  marksheetAvailable.label === "Yes")
                  ? true
                  : false
              }
            />
          </MuiPickersUtilsProvider>
        );
      },
      },
      {
          title: "FieldOfStudy",
          field: "fieldOfStudy",
          //validate: (rowData) => ValidateEducationalEst(rowData),
          editComponent: (props) => {
              const fieldOfStudyData = props.rowData.fieldOfStudyData
                  ? props.rowData.fieldOfStudyData.map(({ id, name }) => ({
                      label: name,
                      value: id,
                  }))
                  : null;
              const fieldOfStudy = props.rowData.fieldOfStudy;
              // const customStyles =
              //   fieldOfStudy !== undefined &&
              //   fieldOfStudy !== null &&
              //   fieldOfStudy !== ""
              //     ? {
              //         control: (provided, state) => ({
              //           ...provided,
              //         }),
              //       }
              //     : {
              //         control: (provided, state) => ({
              //           ...provided,
              //           borderColor: "red !important",
              //         }),
              //       };
              const customStyles = {
                  control: (provided, state) => ({
                      ...provided,
                  }),
              };
              return (
                  <Select
                      options={itemsFieldOfStudy}
                      value={
                          typeof props.value === "string" ? fieldOfStudyData : props.value
                      }
                      onChange={(opt) => {
                          props.onChange(opt);
                      }}
                      isClearable={true}
                      styles={customStyles}
                  />
              );
          },
      },
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const handleErrorClose = (e) => {
    setIserror(false);
  };
  const [isLoader, setIsLoader] = useState(false);

  //useEffect(() => {

  //    getEducationalEst();
  //    getCertification();
  //    getEducationalTraining();
  //    getEducationType();
  //    getSpecialization1();
  //    getSpecialization2();
  //    getCountry();
  //    getHigherEducation();
  //    getMarksheetAvailable();
  //    //getMarksheetAvailableComments();

  //}, []);

  //useEffect(() => {
  //    api.get("/users")
  //        .then(res => {
  //            setData(res.data.data)
  //        })
  //        .catch(error => {
  //            console.log("Error")
  //        })
  //}, [])

  function GetEducationData(id) {
    setIsLoader(true);
      const token = props.token;   
    axios
      .get("/PreHiringForm/GetEducationData/" + id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error : ", error);
      });
  }
  useEffect(() => {
    if (props.token) {
      getEducationalEst();
      getCertification();
      getEducationalTraining();
      getEducationType();
      getSpecialization1();
      getSpecialization2();
      getCountry();
      getHigherEducation();
      getMarksheetAvailable();
      getEducationDegrees();
      getFieldsOfStudy();
    }
  }, [props.token]);
  useEffect(() => {
    if (props.token) {
      if (props.id != null && props.id != undefined) {
        GetEducationData(props.id);
      }
    }
  }, [props.id, props.token]);

  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    //validation
    let errorList = [];
    let objValidDOJ=ValidatePeriodFrom(newData);
    let objValidDOL=ValidatePeriodTo(newData);
    let objValidInstitute=ValidateInstitueName(newData.instituteCollege);
    let objValidSpecialization=ValidateSpecialization2(newData.specialization2);
    let objValidCourseDurationYears=ValidateCourseDurationYears(newData.courseDurationYears);
    let objValidTotalMarks=ValidateTotalMarks(newData.marksTotal);
    let objValidMarksObtained=ValidateMarksObtained(newData.marksObtained);
    let objValidPercentage=ValidatePercentage(newData.percentageGrade);
    let objValidHigherEducation=ValidateHigherEducation(newData.higherEducation);
    let objValidMarksSheetAvailable=ValidateMarksheetAvailable(newData.marksheetAvailable);
    let objValidMarksheetSumitDate=ValidateMarksheetSubmissionDate(newData);
    let objValidEducationDegree=ValidateEducationDegree(newData.educationDegree);

    if (!objValidDOJ.isValid) {
      errorList.push("Date Of Joining : " + objValidDOJ.helperText);
    }
    if (!objValidDOL.isValid) {
      errorList.push("Date Of Leaving : " + objValidDOL.helperText);
    }
    if (!objValidInstitute.isValid) {
      errorList.push("Institute Or School name : " + objValidInstitute.helperText);
    }
    if (!objValidSpecialization.isValid) {
      errorList.push("Specialization(Technical/Non-Techinical) : " + objValidSpecialization.helperText);
    }
    if (!objValidCourseDurationYears.isValid) {
      errorList.push("Course Duration in Years : " + objValidCourseDurationYears.helperText);
    }
    if (!objValidTotalMarks.isValid) {
      errorList.push("Total Marks : " + objValidTotalMarks.helperText);
    }
    if (!objValidMarksObtained.isValid) {
      errorList.push("Marks Obtained : " + objValidMarksObtained.helperText);
    }
    if (!objValidPercentage.isValid) {
      errorList.push("Percentage Grade : " + objValidPercentage.helperText);
    }
    if (!objValidHigherEducation.isValid) {
      errorList.push("Is Higher Education? : " + objValidHigherEducation.helperText);
    }
    if (!objValidMarksSheetAvailable.isValid) {
      errorList.push("Is Marksheet Available? : " + objValidMarksSheetAvailable.helperText);
    }
    if (!objValidMarksheetSumitDate.isValid) {
      errorList.push("Marksheet Submission Date : " + objValidMarksheetSumitDate.helperText);
    }
    if (!objValidEducationDegree.isValid) {
      errorList.push("Education Degree : " + objValidEducationDegree.helperText);
    }

    //added for formatting of newData start
    // const educationalEst =
    //   newData.educationalEst === null
    //     ? null
    //     : typeof newData.educationalEst === "object"
    //     ? newData.educationalEst.label
    //     : newData.educationalEst;
    // const certificate =
    //   newData.certificate === null
    //     ? null
    //     : typeof newData.certificate === "object"
    //     ? newData.certificate.label
    //     : newData.certificate;
    // const training =
    //   newData.training === null
    //     ? null
    //     : typeof newData.training === "object"
    //     ? newData.training.label
    //     : newData.training;
    // const educationType =
    //   newData.educationType === null
    //     ? null
    //     : typeof newData.educationType === "object"
    //     ? newData.educationType.label
    //     : newData.educationType;
    // const specialization1 =
    //   newData.specialization1 === null
    //     ? null
    //     : typeof newData.specialization1 === "object"
    //     ? newData.specialization1.label
    //     : newData.specialization1;
    // const country =
    //   newData.country === null
    //     ? null
    //     : typeof newData.country === "object"
    //     ? newData.country.label
    //     : newData.country;
    // end

    //newData.educationalEst = educationalEst;
    //newData.certificate = certificate;
    //newData.training = training;
    //newData.educationType = educationType;
    //newData.specialization1 = specialization1;
    //newData.country = country;


    //newData.employeeEducationId = oldData.tableData.id;

    if (errorList.length < 1) {
      const educationDegree =
      newData.educationDegree === null
        ? null
        : typeof newData.educationDegree === "object"
        ? newData.educationDegree.label
        : newData.educationDegree;
    const fieldOfStudy =
      newData.fieldOfStudy === null
        ? null
        : typeof newData.fieldOfStudy === "object"
        ? newData.fieldOfStudy.label
        : newData.fieldOfStudy;
    const specialization2 =
      newData.specialization2 === null
        ? null
        : typeof newData.specialization2 === "object"
        ? newData.specialization2.label
        : newData.specialization2;
    const higherEducation =
      newData.higherEducation === null
        ? null
        : typeof newData.higherEducation === "object"
        ? newData.higherEducation.label
        : newData.higherEducation;
    const marksheetAvailable =
      newData.marksheetAvailable === null
        ? null
        : typeof newData.marksheetAvailable === "object"
        ? newData.marksheetAvailable.label
        : newData.marksheetAvailable;

    newData.educationDegree = educationDegree;
    newData.fieldOfStudy = fieldOfStudy;
    newData.specialization2 = specialization2;
    newData.higherEducation = higherEducation;
    newData.marksheetAvailable = marksheetAvailable;

    newData.applicantId = props.id;
   //newData.userId = props.loginDetail[0].userid;
        newData.userId = props.userId;
        newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
      const token = props.token;
      axios
        .put("/PreHiringForm/UpdateEducationData", newData, {
          headers: { Authorization: "Bearer " + token },
        })

        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })

        .catch((error) => {
          reject();
          setErrorMessages(["Update failed! Server error" + error]);
          setIserror(true);

          //resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      //resolve()
      reject();
    }
  };

  const handleRowAdd = (newData, resolve, reject) => {
    //validation

    let errorList = [];
    let objValidDOJ=ValidatePeriodFrom(newData);
    let objValidDOL=ValidatePeriodTo(newData);
    let objValidInstitute=ValidateInstitueName(newData.instituteCollege);
    let objValidSpecialization=ValidateSpecialization2(newData.specialization2);
    let objValidCourseDurationYears=ValidateCourseDurationYears(newData.courseDurationYears);
    let objValidTotalMarks=ValidateTotalMarks(newData.marksTotal);
    let objValidMarksObtained=ValidateMarksObtained(newData.marksObtained);
    let objValidPercentage=ValidatePercentage(newData.percentageGrade);
    let objValidHigherEducation=ValidateHigherEducation(newData.higherEducation);
    let objValidMarksSheetAvailable=ValidateMarksheetAvailable(newData.marksheetAvailable);
    let objValidMarksheetSumitDate=ValidateMarksheetSubmissionDate(newData);
    let objValidEducationDegree=ValidateEducationDegree(newData.educationDegree);

    if (!objValidDOJ.isValid) {
      errorList.push("Date Of Joining : " + objValidDOJ.helperText);
    }
    if (!objValidDOL.isValid) {
      errorList.push("Date Of Leaving : " + objValidDOL.helperText);
    }
    if (!objValidInstitute.isValid) {
      errorList.push("Institute Or School name : " + objValidInstitute.helperText);
    }
    if (!objValidSpecialization.isValid) {
      errorList.push("Specialization(Technical/Non-Techinical) : " + objValidSpecialization.helperText);
    }
    if (!objValidCourseDurationYears.isValid) {
      errorList.push("Course Duration in Years : " + objValidCourseDurationYears.helperText);
    }
    if (!objValidTotalMarks.isValid) {
      errorList.push("Total Marks : " + objValidTotalMarks.helperText);
    }
    if (!objValidMarksObtained.isValid) {
      errorList.push("Marks Obtained : " + objValidMarksObtained.helperText);
    }
    if (!objValidPercentage.isValid) {
      errorList.push("Percentage Grade : " + objValidPercentage.helperText);
    }
    if (!objValidHigherEducation.isValid) {
      errorList.push("Is Higher Education? : " + objValidHigherEducation.helperText);
    }
    if (!objValidMarksSheetAvailable.isValid) {
      errorList.push("Is Marksheet Available? : " + objValidMarksSheetAvailable.helperText);
    }
    if (!objValidMarksheetSumitDate.isValid) {
      errorList.push("Marksheet Submission Date : " + objValidMarksheetSumitDate.helperText);
    }
    if (!objValidEducationDegree.isValid) {
      errorList.push("Education Degree : " + objValidEducationDegree.helperText);
    }

    ////added for formatting of newData start
    // const educationalEst =
    //   newData.educationalEst === null
    //     ? null
    //     : typeof newData.educationalEst === "object"
    //     ? newData.educationalEst.label
    //     : newData.educationalEst;
    // const certificate =
    //   newData.certificate === null
    //     ? null
    //     : typeof newData.certificate === "object"
    //     ? newData.certificate.label
    //     : newData.certificate;
    // const training =
    //   newData.training === null
    //     ? null
    //     : typeof newData.training === "object"
    //     ? newData.training.label
    //     : newData.training;
    // const educationType =
    //   newData.educationType === null
    //     ? null
    //     : typeof newData.educationType === "object"
    //     ? newData.educationType.label
    //     : newData.educationType;
    // const specialization1 =
    //   newData.specialization1 === null
    //     ? null
    //     : typeof newData.specialization1 === "object"
    //     ? newData.specialization1.label
    //     : newData.specialization1;
    // const country =
    //   newData.country === null
    //     ? null
    //     : typeof newData.country === "object"
    //     ? newData.country.label
    //     : newData.country;

    //newData.educationalEst = educationalEst;
    //newData.certificate = certificate;
    //newData.training = training;
    //newData.educationType = educationType;
    //newData.specialization1 = specialization1;
    //newData.country = country;

    if (errorList.length < 1) {
      const educationDegree =
      newData.educationDegree === null
        ? null
        : typeof newData.educationDegree === "object"
        ? newData.educationDegree.label
        : newData.educationDegree;
    const fieldOfStudy =
      newData.fieldOfStudy === null
        ? null
        : typeof newData.fieldOfStudy === "object"
        ? newData.fieldOfStudy.label
        : newData.fieldOfStudy;
    const specialization2 =
      newData.specialization2 === null
        ? null
        : typeof newData.specialization2 === "object"
        ? newData.specialization2.label
        : newData.specialization2;
    const higherEducation =
      newData.higherEducation === null
        ? null
        : typeof newData.higherEducation === "object"
        ? newData.higherEducation.label
        : newData.higherEducation;
    const marksheetAvailable =
      newData.marksheetAvailable === null
        ? null
        : typeof newData.marksheetAvailable === "object"
        ? newData.marksheetAvailable.label
        : newData.marksheetAvailable;

    newData.educationDegree = educationDegree;
    newData.fieldOfStudy = fieldOfStudy;
    newData.specialization2 = specialization2;
    newData.higherEducation = higherEducation;
    newData.marksheetAvailable = marksheetAvailable;

    newData.applicantId = props.id;
    newData.userId = props.userId;          
    newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
      const token = props.token;
      axios
        .post("/PreHiringForm/SaveEducationData", newData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
          // window.location.reload()
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
      reject();
    }
  };

  const handleRowDelete = (oldData, resolve, reject) => {
    //api.delete("/users/" + oldData.id)
    //    .then(res => {
    //        const dataDelete = [...data];
    //        const index = oldData.tableData.id;
    //        dataDelete.splice(index, 1);
    //        setData([...dataDelete]);
    //        resolve()
    //    })

    oldData.employeeEducationId = oldData.tableData.id;
    oldData.applicantId = props.id;
    const token = props.token;
    axios
      .put("/PreHiringForm/DeleteEducationData", oldData, {
        headers: { Authorization: "Bearer " + token },
      })

      .then((response) => {
        if (response.data.response) {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          resolve();
          setIserror(false);
          setErrorMessages([]);
        } else {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          //resolve();
          reject();
          setIserror(false);
          setErrorMessages([]);
        }
        // window.location.reload()
      })
      .catch((error) => {
        reject();
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        //resolve()
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <Grid container spacing={1}>
          <p className="f-12">
            Note : Education should be Entered From Highest to Lowest
          </p>
          <Grid item xs={12}>
            <div>
              {iserror && (
                <Alert severity="error" onClose={handleErrorClose}>
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              title="Education Details"
              columns={columns}
              data={data}
              isLoading={isLoader}
              components={{
                OverlayLoading: (props) => <Loader {...props} />,
              }}
              icons={tableIcons}
              localization={{
                header: {
                  actions: "",
                },
              }}
              options={{
                search: false,
                toolbar: props.disableValue === true ? false : true,
              }}
              editable={{
                isEditable: (rowData) => props.disableValue === false,
                isDeletable: (rowData) => props.disableValue === false,

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(newData, oldData, resolve, reject);
                  }),

                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newData, resolve, reject);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldData, resolve, reject);
                  }),
              }}
            />

            <p className="clr-red f-12">
              Disclaimer : "If Marksheet & certificate is not available: I
              hereby mention that I will submit the marksheet / certificate on
              or before the selected submission date. In case, I am unable to
              provide the marksheet / certificate on or before the selected
              date, company can take any suitable action against me.
            </p>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle className={openClassName} id="draggable-dialog-title">
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Education;
