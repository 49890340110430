import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
    baseDHLApiURL,
    getCustomFormattedDate,
    getRoundUptoXDecimals,
    INRInWords,
    renderValueWithThousandSeparator,
    tableIcons,
    tagsToBeReplaced,
} from "./utilsDHLPrerequisites";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDHLAction } from "../../Redux/Actions/actions";

export default function LetterDetailsDHL(props) {

    const [saveFlag, setSaveFlag] = useState(true);
    const [activeFlag, setActiveFlag] = useState(true);
    let [loaderProps, setLoaderProps] = useState({
        isEnable: false,
        loaderText: "",
    });

    const handleLoader = (isEnable, text) => {
        setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
    };
    const storedInputDataDHL = useSelector(state => state.inputDataDHLReducer.inputDataDHL);
    const storedCompensationAmount = useSelector(state => state.compensationDataReducer.compensationDetails);
    const storedcompensationWSAAmount = useSelector(state => state.compensationWSADataReducer.compensationWSADetails);
    const storedInsuranceAmount = useSelector(state => state.insuranceDetailsReducer.insuranceDetails);
    const storedInsuranceData = useSelector(state => state.insuranceDataReducer.insuranceData);

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            var value = "";
            for (var j = 0; j < sParameterName.length; j++) {
                if (sParameterName[j] == sParam) {
                    for (var k = j + 1; k < sParameterName.length; k++) {
                        value = value + sParameterName[k];
                    }
                    break;
                }
                //return sParameterName[1];
            }

            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }
    let encryptedId = getURLParameter("id");
    let data, id;
    const dispatch = useDispatch();
    const ProgressComponent = (
        <h4
            style={{
                fontStyle: "italic",
                color: "#2EE6E2",
                textAlign: "center",
            }}
        >
            <CircularProgress style={{ height: `25px`, width: `25px` }} />
            {props?.letterDetailsProgressProps?.loaderText}
        </h4>
    );
    const [itemsSignatoriesFromDHL, setItemSignatoriesFromDHL] = useState([]);
    const [signatoryData, setSignatoryData] = useState([]);

    let [offerUploadedData, setOfferUploadedData] = useState({
        offerLetterUploadId: 0,
        applicantId: storedInputDataDHL?.applicantId,
        userId: storedInputDataDHL?.userIdencrypted,
        //userIdencrypted: storedInputDataDHL?.userIdencrypted,
        offerFileName: "",
        offerFileUrl: "",
    });
    let [compenstionUploadedData, setCompensationUploadedData] = useState({
        compensationLetterUploadId: 0,
        applicantId: storedInputDataDHL?.applicantId,
        //userId: props?.inputDataToDHL?.userIdencrypted,
        userId: storedInputDataDHL.userIdencrypted,
        compensationFileName: "",
        compensationFileUrl: "",
    });
    let [documentUploadedData, setDocumentUploadedData] = useState({
        documentUploadId: 0,
        applicantId: storedInputDataDHL?.applicantId,
        //userId: storedInputDataDHL?.userIdencrypted,
        userId: props.UserEncryptedId,
        documentFileName: "",
        documentFileUrl: "",
    });
    let [dialogPropsConfirm, setDialogPropsConfirm] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    let [allLetterTypeData, setAllLetterTypeData] = useState([]);
    let [allAdditionalDocumentData, setallAdditionalDocumentData] = useState([]);
    async function getLetterTypes() {
        handleLoader(true, "Loading Letter Types");
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetAllLetterTypes",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
        };
        const response = await axios(options);
        setAllLetterTypeData(response.data);
        handleLoader(false, "");
    }

    async function getAdditionalDocumentTypes() {
        handleLoader(true, "Loading Document Types");
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetAdditionalDocumentTypes",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
        };
        const response = await axios(options);
        setallAdditionalDocumentData(response.data);
        handleLoader(false, "");
    }

    const [itemsNoOfSignatories, setItemsNoOfSignatories] = useState([]);
    var defaultvalue = { id: 2, name: "2" }
    function getNoOfSignatories() {
        const signatorycount = [
            { id: 1, name: "1" },
            { id: 2, name: "2" },
        ]
        setItemsNoOfSignatories(signatorycount);
        return signatorycount.length;
    }
    // const [itemsFinalLetterTemplates, setItemsFinalLetterTemplates] = useState(
    //   []
    // );
    // function getLetterTemplates() {
    //   setItemsFinalLetterTemplates(
    //     props?.inputDataToDHL?.letterTypeObj?.name === "Offer Letter"
    //       ? offerLetterTemplates
    //       : appointmentLetterTemplates
    //   );
    // }
    // const [itemsSignatoriesGid, setItemSignatoriesGid] = useState([]);
    // function getSignatoriesGid() {
    //   setItemSignatoriesGid([
    //     { value: 1, label: "Z0036MKD" },
    //     { value: 2, label: "Z0036Z2Z-S01" },
    //     { value: 3, label: "Z0036Z3B-S01" },
    //     { value: 4, label: "Z0043K6K" },
    //   ]);
    // }
    function isSignatoryExists(value) {
        const obj = signatoryData.find((c) => c.signatoryGid == value);
        return obj ? true : false;
    }
    function isSignatoryExists(value, id) {
        const obj = signatoryData.find((c) => c.signatoryGid == value);
        return obj && obj.signatoryDetailsId !== id ? true : false;
    }
    function renderSignatoryGid(value) {
        let obj = {};
        if (itemsSignatoriesDDL && itemsSignatoriesDDL.length > 0) {
            obj = itemsSignatoriesDDL.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function validateSignatoryGid(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : (!id &&
                isSignatoryExists(typeof value === "number" ? value : value.value)) ||
                (id &&
                    isSignatoryExists(
                        typeof value === "number" ? value : value.value,
                        id
                    ))
                ? { isValid: false, helperText: "Already exists" }
                : { isValid: true, helperText: "" };
    }
    function getSignatoryGidDDLComponent(objProps) {
        let customStylesDefault = {
            control: (provided, state) => ({
                ...provided,
                border: "none",
                borderBottom: `1px solid #00B3B3`,
                borderRadius: 0,
                cursor: "text",
                boxShadow: "0 0 0 0 transparent !important",
                minHeight: 0,
                backgroundColor: "#e8f8f8",
                ":hover": {
                    border: "none",
                    borderBottom: `1px solid #00B3B3`,
                    borderRadius: 0,
                    boxShadow: "0 0 0 0 transparent !important",
                },
            }),
            singleValue: (provided, state) => ({
                ...provided,
                textAlign: "left",
            }),
            input: (provided, state) => ({
                ...provided,
                textAlign: "left",
                padding: 0,
            }),
            menu: (provided, state) => ({
                ...provided,
                textAlign: "left",
                backgroundColor: `var(--select-active-bg-color)`,
                color: `var(--select-active-text-color)`,
            }),
            menuList: (provided, state) => ({
                ...provided,
                color: `var(--select-active-text-color)`,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
            }),
            clearIndicator: (provided, state) => ({
                ...provided,
                padding: "1px !important",
                cursor: "pointer",
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: "1px !important",
                cursor: "pointer",
            }),
        };
        let customStyleError = {
            ...customStylesDefault,
            control: (provided, state) => ({
                ...provided,
                border: "none",
                borderBottom: `1px solid var(--red)`,
                borderRadius: 0,
                cursor: "text",
                boxShadow: "0 0 0 0 transparent !important",
                minHeight: 0,
                backgroundColor: "#e8f8f8",
                ":hover": {
                    border: "none",
                    borderBottom: `1px solid var(--red)`,
                    borderRadius: 0,
                    boxShadow: "0 0 0 0 transparent !important",
                },
            }),
        };
        const signatoryGidData = objProps.rowData.signatoryGid
            ? itemsSignatoriesDDL.find(
                (x) => x.value === objProps.rowData.signatoryGid
            )
            : null;
        const objValidate = validateSignatoryGid(
            objProps.value,
            objProps.rowData.signatoryDetailsId
        );
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: `var(--side-drawer-bg-color)`,
                            primary25: `var(--app-permission-list-item-color)`,
                            primary50: `var(--app-permission-list-item-color)`,
                        },
                    })}
                    options={itemsSignatoriesDDL}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? signatoryGidData
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt);
                    }}
                    isClearable
                    styles={
                        objValidate && !objValidate.isValid
                            ? customStyleError
                            : customStylesDefault
                    }
                    placeholder="Type GID"
                />
                <FormHelperText>
                    {objValidate && !objValidate.isValid ? objValidate.helperText : ""}
                </FormHelperText>
            </FormControl>
        );
    }

    function isPositionExists(value) {
        const obj = signatoryData.find((c) => c.signatoryGid == value);
        return obj ? true : false;
    }
    function isPositionExists(value, id) {
        const obj = signatoryData.find((c) => c.signatoryGid == value);
        return obj && obj.signatoryDetailsId !== id ? true : false;
    }
    function validateSignatoryPosition(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !(value === "LHS" || value === "RHS")
                ? { isValid: false, helperText: "Invalid position" }
                : signatoryData &&
                    signatoryData.findIndex((x) => x.signatoryDetailsId === id) === 0 &&
                    value &&
                    value === "RHS"
                    ? { isValid: false, helperText: '"LHS" expected' }
                    : signatoryData &&
                        signatoryData.findIndex((x) => x.signatoryDetailsId === id) === 1 &&
                        value &&
                        value === "LHS"
                        ? { isValid: false, helperText: '"RHS" expected' }
                        : (!id && isPositionExists(value)) || (id && isPositionExists(value))
                            ? { isValid: false, helperText: "Already assigned" }
                            : { isValid: true, helperText: "" };
    }
    let columns = [
        { title: "Row Id", field: "signatoryDetailsId", hidden: true },
        {
            title: "Signatory Email(GID)",
            field: "signatoryGid",
            render: (rowData) => (
                <span>
                    {renderSignatoryGid(
                        typeof rowData.signatoryGid === "string"
                            ? rowData.signatoryGid
                            : rowData.signatoryGid.value
                    )}
                </span>
            ),
            validate: (rowData) =>
                validateSignatoryGid(rowData.signatoryGid, rowData.signatoryDetailsId),
            editComponent: (props) => getSignatoryGidDDLComponent(props),
            customFilterAndSearch: (term, rowData) =>
                renderSignatoryGid(
                    typeof rowData.signatoryGid === "string"
                        ? rowData.signatoryGid
                        : rowData.signatoryGid.value
                )
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Signatory Position",
            field: "signatoryPosition",
            type: "text",
            editable: "onUpdate",
            initialEditValue: getDefaultPositionValue(),
            validate: (rowData) =>
                validateSignatoryPosition(
                    rowData.signatoryPosition,
                    rowData.signatoryDetailsId
                ),
        },
        { title: "Signatory Designation", field: "signatoryDesignation" },
        {
            title: "Digital Signature",
            field: "isDigital",
            type: "boolean",
            initialEditValue: false,
        },
    ];

    function getDefaultPositionValue() {
        return signatoryData && signatoryData.length > 0 ? "RHS" : "LHS";
    }

    //const handleAutoCompleteChange = (e, newValue) => {
    //    props.handleAutoCompleteChange(e, newValue);
    //};
    //const handleAutoCompleteTextChange = (e, newText) => {
    //    props.handleAutoCompleteTextChange(e, newText);
    //};

    //const handleChange = (e) => {
    //    props.handleChange(e);
    //};

    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        props.setLetterDetailsProgressProps({
            ...props?.letterDetailsProgressProps,
            isEnable: false,
            loaderText: "Updating signatory details....",
        });
        //newData.userId = props?.inputDataToDHL?.userIdencrypted;
        //newData.userId = props.inputDataToDHL?.userIdencrypted;
        // newData.applicantId = props?.inputDataToDHL?.applicantId;
        //newData.applicantIdEncrypted = props?.inputDataToDHL?.applicantId;
        newData.applicantIdEncrypted = storedInputDataDHL?.applicantId;
        //newData.userIdencrypted = props?.inputDataToDHL?.applicantId;
        newData.userIdEncrypted = storedInputDataDHL?.userIdencrypted;
        newData.signatoryGid =
            typeof newData.signatoryGid == "object"
                ? newData.signatoryGid.value
                : newData.signatoryGid;
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/UpdateSignatoryData",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: newData,
        };
        
        axios(options)
            .then((response) => {
                let data = response.data;
                if (data.response) {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    getSignatoryDetails(storedInputDataDHL?.applicantId);
                    resolve();
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Signatory Details Update Alert",
                        data?.responseMsg
                    );
                    reject();
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props?.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                console.log(error);
                reject();
            });
    };
    const handleClickSaveAndSendNegotiation = (e) => {
        props.handleDialogOpenConfirm(
            "Save & Send Negotiation",
            "Save & Send Negotiation Confirmation",
            "Are you sure you want to update the existing details and send compensation proposal to the candidate?"
        );
    };

    const handleRowAdd = (newData, resolve, reject) => {
        props.setLetterDetailsProgressProps({
            ...props?.letterDetailsProgressProps,
            isEnable: false,
            loaderText: "Saving signatory details....",
        });
        debugger;
        //newData.userId = props?.inputDataToDHL?.userIdencrypted;
        if (newData.length == 2) { setSaveFlag(false); }
        else { setSaveFlag(true); }
        newData.UserIdEncrypted = storedInputDataDHL.userIdencrypted;
        newData.applicantIdEncrypted = storedInputDataDHL?.applicantId;
        newData.applicantId = "";
        newData.signatoryGid =
            typeof newData.signatoryGid == "object"
                ? newData.signatoryGid.value
                : newData.signatoryGid;

        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/SaveSignatoryData",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: newData,
        };
        axios(options)
            .then((response) => {
                let data = response.data;
                if (data.response) {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    getSignatoryDetails(storedInputDataDHL?.applicantId);
                    resolve();
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Signatory Details Save Alert",
                        data?.responseMsg
                    );
                    reject();
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props?.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                console.log(error);
                reject();
            });
    };
    const handleRowDelete = (oldData, resolve, reject) => {
        props.setLetterDetailsProgressProps({
            ...props?.letterDetailsProgressProps,
            isEnable: false,
            loaderText: "Deleting signatory details....",
        });
        //oldData.userId = props?.inputDataToDHL?.userIdencrypted;
        oldData.userId = storedInputDataDHL.UserEncryotedId;
        oldData.applicantId = storedInputDataDHL?.applicantId;
        oldData.signatoryGid =
            typeof oldData.signatoryGid == "object"
                ? oldData.signatoryGid.value
                : oldData.signatoryGid;
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/DeleteSignatoryData",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: oldData,
        };
        axios(options)
            .then((response) => {
                let data = response.data;
                if (data.response) {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    getSignatoryDetails(storedInputDataDHL?.applicantId);
                    resolve();
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Signatory Details Delete Alert",
                        data?.responseMsg
                    );
                    reject();
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props?.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                console.log(error);
                reject();
            });
    };
    function getSignatoryDetails(id) {
        props.setLetterDetailsProgressProps({
            ...props?.letterDetailsProgressProps,
            isEnable: true,
            loaderText: "Fetching signatoy data....",
        });
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetSignatoryData",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let data = response.data;
                if (data && data.length > 0) {
                    if (data.length == 2) {
                        setSaveFlag(false);
                    }
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    setSignatoryData(data);
                    props.onChangeSignatoryDetails(data);
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props?.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    setSignatoryData([]);
                    setSaveFlag(true);
                    props.onChangeSignatoryDetails([]);
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props?.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                console.log(error);
            });
    }
    function getDigiLetterDetails(encryptedId) {

        handleLoader(true, "Fetching data....");
        const token = props.token;
        var stat = localStorage.getItem("Status");
        // id = encryptedId.substr(22);
        id = encryptedId;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    console.log("Inside getDigiLetterDetails", data);
                    // setInputDataToDHL({
                    //     ...inputDataToDHL,
                    //     digiLetterDetailsId: data?.digiLetterDetailsId,
                    //     applicantId: data?.applicantIdEncrypted,
                    //     firstName: data?.firstName,
                    //     lastName: data?.lastName,
                    //     email: data?.email,
                    //     joiningDate: data?.joiningDate,
                    //     companyId: data?.companyId,
                    //     areObj: {
                    //         id: data?.areObj?.companyId,
                    //         areCode: data?.areObj?.companyArename,
                    //         areName: data?.areObj?.companyAredescription,
                    //     },
                    //     mprnumber: data?.mprnumber,
                    //     referenceNo: data?.referenceNo,
                    //     division: data?.division,
                    //     taname: data?.taname,
                    //     taemail: data?.taemail,
                    //     address: data?.address,
                    //     dateOfBirth: data?.dateOfBirth,
                    //     letterType: data?.letterType,
                    //     letterTypeDetails: data?.letterTypeObj,
                    //     letterTypeLabel: data?.letterTypeObj
                    //         ? data?.letterTypeObj?.letterTypeName
                    //         : "",
                    //     letterTypeObj: {
                    //         id: data?.letterTypeObj?.letterTypeId,
                    //         name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                    //     },
                    //     noOfSignatories: data?.noOfSignatories,
                    //     noOfSignatoriesObj: {
                    //         id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                    //         name:
                    //             data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                    //     },
                    //     noOfSignatoriesLabel:
                    //         data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                    //     signatoryDetails: data?.signatoryDetails,
                    //     employeeType: data?.employeeType,
                    //     employeeTypeLabel: data?.employeeTypeObj
                    //         ? data?.employeeTypeObj?.name
                    //         : "",
                    //     employeeTypeObj: data?.employeeTypeObj,
                    //     jobLocation: data?.jobLocation,
                    //     jobLocationLabel: data?.jobLocationObj
                    //         ? data?.jobLocationObj?.locationCode +
                    //         " - " +
                    //         data?.jobLocationObj?.cityName +
                    //         " - " +
                    //         data?.jobLocationObj?.streetName
                    //         : "",
                    //     jobLocationObj: data?.jobLocationObj
                    //         ? {
                    //             id: data?.jobLocationObj?.locationCodeId,
                    //             name:
                    //                 data?.jobLocationObj?.locationCode +
                    //                 " - " +
                    //                 data?.jobLocationObj?.cityName +
                    //                 " - " +
                    //                 data?.jobLocationObj?.streetName,
                    //         }
                    //         : { id: 0, name: "" },
                    //     locationObj: data?.jobLocationObj,
                    //     jobTitle: data?.jobTitle,
                    //     jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                    //     businessTitle: data?.businessTitle
                    //         ? data?.businessTitle
                    //         : data?.jobTitleObj
                    //             ? data?.jobTitleObj?.name
                    //             : "",
                    //     jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                    //     gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                    //     grip: data?.grip,
                    //     businessUnit: data?.businessUnit,
                    //     gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                    //     gripCode: data?.gripCode,
                    //     staff: data?.staff,
                    //     staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                    //     staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                    //     compensationGrade: data?.compensationGrade,
                    //     compensationGradeLabel: data?.compensationGradeObj
                    //         ? data?.compensationGradeObj?.name
                    //         : "",
                    //     compensationGradeObj: data?.compensationGradeObj
                    //         ? data?.compensationGradeObj
                    //         : { id: 0, name: "" },
                    //     compensationGradeProfile: data?.compensationGradeProfile
                    //         ? data?.compensationGradeProfile
                    //         : { id: 0, name: "" },
                    //     compensationGradeProfileLabel: data?.compensationGradeProfileObj
                    //         ? data?.compensationGradeProfileObj?.name
                    //         : "",
                    //     compensationGradeProfileObj: data?.compensationGradeProfileObj,
                    //     payPlan: data?.payPlan,
                    //     payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                    //     payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                    //     performencePayPlan: data?.performencePayPlan,
                    //     performencePayPlanLabel: data?.performencePayPlanObj
                    //         ? data?.performencePayPlanObj?.name
                    //         : "",
                    //     performencePayPlanObj: data?.performencePayPlanObj
                    //         ? data?.performencePayPlanObj
                    //         : { id: 0, name: "" },
                    //     salaryAmount: data?.salaryAmount,
                    //     targetPay: data?.targetPay,
                    //     performencePay: data?.performencePay,
                    //     joiningBonus: data?.joiningBonus,
                    //     recoveryDuration: data?.recoveryDuration,
                    //     allowancePlanObj:
                    //         data?.allowancePlanObj?.length > 0
                    //             ? data?.allowancePlanObj
                    //             : inputDataToDHL?.allowancePlanObj,
                    //     allowanceAmountObj:
                    //         data?.allowanceAmountObj?.length > 0
                    //             ? data?.allowanceAmountObj
                    //             : inputDataToDHL?.allowanceAmountObj,
                    //     compensationLetterUploadId: data?.compensationLetterUploadId,
                    //     isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                    //     offerLetterUploadId: data?.offerLetterUploadId,
                    //     isOfferLetteruploaded: data?.isOfferLetteruploaded,
                    //     siemensLogo:
                    //         data?.companyId === 1 || data.companyId === 2
                    //             ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                    //             : "../assets/images/sie-logo-petrol-rgb.png",
                    //     digiLetterWorkflowId: data?.digiLetterWorkflowId,
                    //     isCompensationAccepted: data?.isCompensationAccepted,
                    //     isCompensationRejected: data?.isCompensationRejected,
                    //     digiLetterStatusId: data?.digiLetterStatusId,
                    //     isOfferAccepted: data?.isOfferAccepted,
                    //     isOfferRejected: data?.isOfferRejected,
                    //     isSuperannuation: data?.isSuperannuation,
                    // });
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterDetailsId: data?.digiLetterDetailsId,
                        applicantId: data?.applicantIdEncrypted,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        joiningDate: data?.joiningDate,
                        companyId: data?.companyId,
                        areObj: {
                            id: data?.areObj?.companyId,
                            areCode: data?.areObj?.companyArename,
                            areName: data?.areObj?.companyAredescription,
                        },
                        mprnumber: data?.mprnumber,
                        referenceNo: data?.referenceNo,
                        division: data?.division,
                        taname: data?.taname,
                        taemail: data?.taemail,
                        address: data?.address,
                        dateOfBirth: data?.dateOfBirth,
                        letterType: data?.letterType,
                        letterTypeDetails: data?.letterTypeObj,
                        letterTypeLabel: data?.letterTypeObj
                            ? data?.letterTypeObj?.letterTypeName
                            : "",
                        letterTypeObj: {
                            id: data?.letterTypeObj?.letterTypeId,
                            name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                        },
                        noOfSignatories: data?.noOfSignatories,
                        noOfSignatoriesObj: {
                            id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                            name:
                                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        },
                        noOfSignatoriesLabel:
                            data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        signatoryDetails: data?.signatoryDetails,
                        employeeType: data?.employeeType,
                        employeeTypeLabel: data?.employeeTypeObj
                            ? data?.employeeTypeObj?.name
                            : "",
                        employeeTypeObj: data?.employeeTypeObj,
                        jobLocation: data?.jobLocation,
                        jobLocationLabel: data?.jobLocationObj
                            ? data?.jobLocationObj?.locationCode +
                            " - " +
                            data?.jobLocationObj?.cityName +
                            " - " +
                            data?.jobLocationObj?.streetName
                            : "",
                        jobLocationObj: data?.jobLocationObj
                            ? {
                                id: data?.jobLocationObj?.locationCodeId,
                                name:
                                    data?.jobLocationObj?.locationCode +
                                    " - " +
                                    data?.jobLocationObj?.cityName +
                                    " - " +
                                    data?.jobLocationObj?.streetName,
                            }
                            : { id: 0, name: "" },
                        locationObj: data?.jobLocationObj,
                        jobTitle: data?.jobTitle,
                        jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                        businessTitle: data?.businessTitle
                            ? data?.businessTitle
                            : data?.jobTitleObj
                                ? data?.jobTitleObj?.name
                                : "",
                        jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                        gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                        grip: data?.grip,
                        businessUnit: data?.businessUnit,
                        gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                        gripCode: data?.gripCode,
                        staff: data?.staff,
                        staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                        staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                        compensationGrade: data?.compensationGrade,
                        compensationGradeLabel: data?.compensationGradeObj
                            ? data?.compensationGradeObj?.name
                            : "",
                        compensationGradeObj: data?.compensationGradeObj
                            ? data?.compensationGradeObj
                            : { id: 0, name: "" },
                        compensationGradeProfile: data?.compensationGradeProfile
                            ? data?.compensationGradeProfile
                            : { id: 0, name: "" },
                        compensationGradeProfileLabel: data?.compensationGradeProfileObj
                            ? data?.compensationGradeProfileObj?.name
                            : "",
                        compensationGradeProfileObj: data?.compensationGradeProfileObj,
                        payPlan: data?.payPlan,
                        payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                        payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                        performencePayPlan: data?.performencePayPlan,
                        performencePayPlanLabel: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj?.name
                            : "",
                        performencePayPlanObj: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj
                            : { id: 0, name: "" },
                        salaryAmount: data?.salaryAmount,
                        targetPay: data?.targetPay,
                        performencePay: data?.performencePay,
                        joiningBonus: data?.joiningBonus,
                        recoveryDuration: data?.recoveryDuration,
                        allowancePlanObj:
                            data?.allowancePlanObj?.length > 0
                                ? data?.allowancePlanObj
                                : inputDataToDHL?.allowancePlanObj,
                        allowanceAmountObj:
                            data?.allowanceAmountObj?.length > 0
                                ? data?.allowanceAmountObj
                                : inputDataToDHL?.allowanceAmountObj,
                        compensationLetterUploadId: data?.compensationLetterUploadId,
                        isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                        offerLetterUploadId: data?.offerLetterUploadId,
                        isOfferLetteruploaded: data?.isOfferLetteruploaded,
                        siemensLogo:
                            data?.companyId === 1 || data.companyId === 2
                                ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                                : "../assets/images/sie-logo-petrol-rgb.png",
                        digiLetterWorkflowId: data?.digiLetterWorkflowId,
                        isCompensationAccepted: data?.isCompensationAccepted,
                        isCompensationRejected: data?.isCompensationRejected,
                        digiLetterStatusId: data?.digiLetterStatusId,
                        isOfferAccepted: data?.isOfferAccepted,
                        isOfferRejected: data?.isOfferRejected,
                        isSuperannuation: data?.isSuperannuation,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                    
                    dispatch(inputDataDHLAction(inputUpdatedDHL));

                    //setDigiLetterDataState(data);
                    handleLoader(false, "");
                    //alert(stat);
                    if (stat == "Success") {
                        //handleDialogOpenAlert(
                        //    "Success",
                        //    "Letter Uploaded Successfully",
                        //    "Letter Uploaded Successfully"
                        //);
                        //alert("Success");
                        localStorage.removeItem("Status");
                    }
                    else if (stat == "Failed") {
                        //handleDialogOpenAlert(
                        //    "Failure",
                        //    "Letter Uploaded Failed",
                        //    "Letter Upload Failed"
                        //);
                    }
                    console.log("Inside 1 getDigiLetterDetails", data);
                    handleLoader(false, "");
                }
                console.log("Inside 2 getDigiLetterDetails", result);
                handleLoader(false, "");
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
            });
    }
    function replaceTags(value) {
        debugger;
        tagsToBeReplaced.forEach((c) => {
            let count = value.match(new RegExp(c, "gi") || [])?.length;
            if (count > 0) {
                for (let i = 0; i < count; i++) {
                    switch (c) {
                        case "{FirstName}":
                            value = value?.replace(c, storedInputDataDHL?.firstName);
                            break;
                        case "{LastName}":
                            value = value?.replace(c, storedInputDataDHL?.lastName);
                            break;
                        case "{SystemDate}":
                            value = value?.replace(c, getCustomFormattedDate(new Date(), ""));
                            break;
                        case "{JobTitle}":
                            value = value?.replace(c, storedInputDataDHL?.businessTitle);
                            break;
                        case "{PositionLevel}":
                            let positionLevel = storedInputDataDHL?.gripObj?.name;
                            value = value?.replace(
                                c,
                                positionLevel?.substr(0, positionLevel?.indexOf("-") - 1)
                            );
                            break;
                        case "{Grip}":
                            value = value?.replace(c, storedInputDataDHL?.gripCode);
                            break;
                        case "{Division}":
                            value = value?.replace(c, storedInputDataDHL?.division);
                            break;
                        case "{ARECode}":
                            value = value?.replace(c, storedInputDataDHL?.areObj?.areCode);
                            break;
                        case "{AREName}":
                            value = value?.replace(c, storedInputDataDHL?.areObj?.areName);
                            break;
                        case "{BasicSalary}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(storedCompensationAmount?.basicpm, 0)
                                )
                            );
                            break;
                        case "{BFP}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    //getRoundUptoXDecimals(props?.compensationAmount?.bfppm, 0)
                                    getRoundUptoXDecimals(storedCompensationAmount?.bfppm, 0)
                                )
                            );
                            break;
                        case "{TotalAllowances}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(storedCompensationAmount?.tapa / 12, 0)
                                )
                            );
                            break;
                        case "{TotalAllowance}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(storedCompensationAmount?.bfppm + storedCompensationAmount?.basicpm / 2, 0)
                                )
                            );
                            break;
                        case "{HRA}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        storedCompensationAmount?.basicpm / 2, 0)
                                )
                            );
                            break;
                        case "{BPA}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    //getRoundUptoXDecimals(props ?. compensationAmount?.targetPay, 0)
                                    //getRoundUptoXDecimals(props?.compensationAmount?.bpa, 0)
                                    getRoundUptoXDecimals(
                                        (storedCompensationAmount?.basicpm +
                                            (storedCompensationAmount?.bfppm + (storedCompensationAmount?.basicpm / 2))) * 12, 0
                                    )
                                )
                            );

                            break;
                        case "{BPPM}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        storedCompensationAmount?.basicpm +
                                        (storedCompensationAmount?.bfppm + (storedCompensationAmount?.basicpm / 2)), 0
                                    )
                                )
                            );
                            break;
                        case "{BP}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        (storedCompensationAmount?.basicpm +
                                        storedCompensationAmount?.tapa / 12),
                                       0
                                    )
                                )
                            );
                            break;
                        case "{PF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(storedCompensationAmount?.pfpm, 0)
                                )
                            );
                            break;
                        case "{Gratuity}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(storedCompensationAmount?.gratuitypm, 0)
                                )
                            );
                            break;
                        case "{TotalRetirals}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(getRoundUptoXDecimals(
                                    storedCompensationAmount?.totalRetiralspm, 0)
                                )
                            );
                            break;
                        case "{TFPpm}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        storedCompensationAmount?.basicpm +
                                        storedCompensationAmount?.tapa / 12 + storedCompensationAmount?.totalRetiralspm,
                                        0
                                    )
                                )
                            );
                            break;
                        case "{TotalFixedPaypm}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        storedCompensationAmount?.basicpm +
                                        (storedCompensationAmount?.bfppm + (storedCompensationAmount?.basicpm / 2)) + storedCompensationAmount?.totalRetiralspm, 0
                                    )

                                )
                            );
                            break;
                        case "{TotalFixedPaypa}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(getRoundUptoXDecimals(
                                        (storedCompensationAmount?.basicpm +
                                            (storedCompensationAmount?.bfppm + (storedCompensationAmount?.basicpm / 2)) + storedCompensationAmount?.totalRetiralspm), 2
                                    ) * 12, 0)

                                )
                            );
                            break;
                        case "{TFPpa}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    // props?.compensationAmount?.tfppa
                                    storedCompensationAmount?.targetPay -
                                    storedCompensationAmount?.pppa
                                )
                            );
                            break;
                        case "{PP}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedCompensationAmount?.pppa
                                )
                            );
                            break;
                        case "{TargetPay}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedCompensationAmount?.targetPay
                                )
                            );
                            break;
                        case "{ELpa}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(storedCompensationAmount?.elepa, 0)
                                )
                            );
                            break;
                        case "{WOSAF}":
                            value = value?.replace(
                                c,
                                storedInputDataDHL?.isSuperannuation === true
                                    ? "NO"
                                    : storedInputDataDHL?.isSuperannuation === false
                                        ? "YES"
                                        : ""
                            );
                            break;
                        case "{WSAF}":
                            value = value?.replace(
                                c,
                                storedInputDataDHL?.isSuperannuation === true
                                    ? "YES"
                                    : storedInputDataDHL?.isSuperannuation === false
                                        ? "NO"
                                        : ""
                            );
                            break;
                        case "{BasicSalarySAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.basicpm
                                )
                            );
                            break;
                        case "{BFPSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.bfppm
                                )
                            );
                            break;
                        case "{TotalAllowancesSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        storedcompensationWSAAmount?.tapa / 12,
                                        0
                                    )
                                )
                            );
                            break;
                        case "{BPSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        (storedcompensationWSAAmount?.basicpm +
                                        storedcompensationWSAAmount?.tapa / 12),
                                        0
                                    )
                                )
                            );
                            break;
                        case "{PFSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.pfpm
                                )
                            );
                            break;
                        case "{SAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.safpm
                                )
                            );
                            break;
                        case "{GratuitySAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.gratuitypm
                                )
                            );
                            break;
                        case "{TotalRetiralsSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.totalRetiralspm
                                )
                            );
                            break;
                        case "{TFPpmSAF}":
                            //value = value?.replace(
                            //  c,
                            //  renderValueWithThousandSeparator(
                            //    props?.compensationWSAAmount?.tfppm
                            //  )
                            //);
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    getRoundUptoXDecimals(
                                        storedcompensationWSAAmount?.basicpm +
                                        storedcompensationWSAAmount?.tapa / 12 + storedcompensationWSAAmount?.totalRetiralspm,
                                        0
                                    )
                                )
                            );
                            break;
                        case "{TFPpaSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    // props?.compensationWSAAmount?.tfppa
                                    storedcompensationWSAAmount?.targetPay -
                                    storedcompensationWSAAmount?.pppa
                                )
                            );
                            break;
                        case "{PPSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.pppa
                                )
                            );
                            break;
                        case "{TargetPaySAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.targetPay
                                )
                            );
                            break;
                        case "{ELpaSAF}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(
                                    storedcompensationWSAAmount?.elepa
                                )
                            );
                            break;
                        case "{HIPCpa}":
                            console.log(insuranceData);
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(getRoundUptoXDecimals(storedInsuranceData?.ghicAmount, 0))
                            );
                            console.log(props);
                            break;
                        case "{PAICpa}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(getRoundUptoXDecimals(storedInsuranceAmount?.gpaic, 0))
                            );
                            break;
                        case "{TLICpa}":
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(getRoundUptoXDecimals(storedInsuranceAmount?.gtLic, 0))
                            );
                            break;
                        case "{EDLIC}":
                            console.log(props);
                            value = value?.replace(
                                c,
                                renderValueWithThousandSeparator(getRoundUptoXDecimals(storedInsuranceData?.edliAmount, 0))
                            );
                            console.log(props);
                            break;
                        case "{ESIC}":
                            value = value?.replace(
                                c,

                                storedInsuranceAmount?.esic
                            );
                            break;
                        case "{SiemensLogo}":
                            value = value?.replace(c, storedInputDataDHL?.siemensLogo);
                            break;
                        case "{TAName}":
                            value = value?.replace(c, storedInputDataDHL?.taname);
                            break;
                        case "{TAEmail}":
                            value = value?.replace(c, storedInputDataDHL?.taemail);
                            break;
                        case "{Address}":
                            value = value?.replace(c, storedInputDataDHL?.address);
                            break;
                        case "{JoiningDate}":
                            value = value?.replace(
                                c,
                                getCustomFormattedDate(storedInputDataDHL?.joiningDate, "")
                            );
                            break;
                        case "{TargetPayInWords}":
                            value = value?.replace(
                                c,
                                INRInWords(storedInputDataDHL?.targetPay)
                            );
                            break;
                        case "{BusinessUnit}":
                            value = value?.replace(c, storedInputDataDHL?.businessUnit);
                            break;
                        case "{JoiningBonusClause}":
                            value = value?.replace(
                                c,
                                storedInputDataDHL?.joiningBonus
                                    ? "In addition, you are also entitled to a one time joining bonus of <b>Rs.{JoiningBonusAmount}/- (Rupees {JoiningBonusAmountInWords} Only)</b> and/or relocation expenses. We reserve the right to recover the joining bonus and/or relocation expenses if you leave our service within <b>{NumberOfYears}</b> year(s) of joining."
                                    : ""
                            );
                            break;
                        case "{JoiningBonusAmount}":
                            value = value?.replace(c, renderValueWithThousandSeparator(storedInputDataDHL?.joiningBonus));
                            break;
                        case "{JoiningBonusAmountInWords}":
                            value = value?.replace(
                                c,
                                INRInWords(storedInputDataDHL?.joiningBonus)
                            );
                            break;
                        case "{NumberOfYears}":
                            value = value?.replace(c, storedInputDataDHL?.recoveryDuration);
                            break;
                        case "{JobLocation}":
                            value = value?.replace(
                                c,
                                storedInputDataDHL?.locationObj?.cityName
                            );
                            break;
                        case "{OfficeLocation}":
                            value = value?.replace(
                                c,
                                storedInputDataDHL?.locationObj?.cityName
                            );
                            break;
                        case "{DateOfBirth}":
                            value = value?.replace(
                                c,
                                getCustomFormattedDate(
                                    storedInputDataDHL?.dateOfBirth,
                                    "DOB"
                                )
                            );
                            break;
                        case "{PositionLevelClauseGBS}":
                            let posLevel = storedInputDataDHL?.gripObj?.name;


                            var value1 = posLevel?.substr(0, posLevel?.indexOf("-") - 1)
                            var pl = parseInt(value1);
                            value = value?.replace(
                                c,
                                (pl >= 7 && pl <= 14) ? "two months" : (pl <= 6) ? "three months" : ""
                            );

                            break;
                        case "{PositionLevelClauseAdvanta}":
                            let poLevel = storedInputDataDHL?.gripObj?.name;


                            var value1 = poLevel?.substr(0, poLevel?.indexOf("-") - 1)
                            var pl = parseInt(value1);
                            value = value?.replace(
                                c,
                                (pl == 12) ? "one-year" : "six months"
                            );

                            break;

                        case "{Folder ID/Person ID}":
                            value = value?.replace(
                                c,
                                storedInputDataDHL?.mprnumber +
                                " / " +
                                storedInputDataDHL?.referenceNo
                            );
                            break;
                        case "{BusinessSignatoryName}":
                            value = value?.replace(c, "From DHL Application");
                            break;
                        case "{FullBusinessSignature}":
                            var gid1 = storedInputDataDHL?.signatoryDetails[1].signatoryGid;
                            var signatoryname1 = itemsSignatoriesFromDHL.find((c) => c.gid == gid1)
                            value = value?.replace(c, signatoryname1.firstName + " " + signatoryname1.lastName);
                            break;
                        case "{HR SignatoryName}":
                            value = value?.replace(c, "From DHL Application");
                            break;
                        case "{FullHRSignature}":
                            var gid2 = storedInputDataDHL?.signatoryDetails[0].signatoryGid;
                            var signatoryname2 = itemsSignatoriesFromDHL.find((c) => c.gid == gid2)
                            value = value?.replace(c, signatoryname2.firstName + " " + signatoryname2.lastName);
                            break;
                        case "{FullBusinessDesignation}":
                            var businessDesignation = storedInputDataDHL?.signatoryDetails[1].signatoryDesignation;

                            value = value?.replace(c, businessDesignation);
                            break;
                        case "{FullHRDesignation}":
                            var hrDesignation = storedInputDataDHL?.signatoryDetails[0].signatoryDesignation;

                            value = value?.replace(c, hrDesignation);
                            break;
                        default:
                            value = value;
                            break;
                    }
                }
            }
        });
        return value;
    }
    function getHTML(data, type) {
        
        let html =
            type === "Compensation"
                ? data?.find(
                    (x) => x.letterTypeId === storedInputDataDHL?.letterType
                )?.compensationLetterContentHtml
                : data?.find(
                    (x) => x.letterTypeId === storedInputDataDHL?.letterType
                )?.offerLetterContentHtml;

        let pdf = type === "Compensation"
            ? data?.find(
                (x) => x.letterTypeId === storedInputDataDHL?.letterType
            )?.compensationLetterContent
            : data?.find(
                (x) => x.letterTypeId === storedInputDataDHL?.letterType
            )?.offerLetterContent;
        return {
            __html: replaceTags(html),
            __pdf: replaceTags(pdf),
        };
    }
    //function getData(data, type) {
    //   if (type = "Document") {
    //        type = data?.find(
    //            (x) => x.letterTypeId === props?.inputDataToDHL?.letterType
    //        )?.documentFileUrl
    //    }
    //    return {
    //        __html: documentFileUrl,
    //    };
    //}
    let [inputDataToDHL, setInputDataToDHL] = useState({
        applicantId: getURLParameter("id"),
        userIdencrypted: props.userId,
        firstName: "",
        lastName: "",
        email: "",
        areObj: {},
        taname: "",
        taemail: "",
        address: "",
        dateOfBirth: null,
        mprnumber: "",
        referenceNo: "",
        letterType: 0,
        letterTypeLabel: "",
        letterTypeObj: {},
        letterTemplateType: 0,
        letterTemplateTypeLabel: "",
        letterTemplateTypeObj: {},
        noOfSignatories: 0,
        noOfSignatoriesLabel: "",
        noOfSignatoriesObj: {},
        signatoryDetails: [],
        employeeType: 0,
        employeeTypeLabel: "",
        employeeTypeObj: {},
        jobLocation: 0,
        jobLocationLabel: "",
        jobLocationObj: {},
        jobTitle: 0,
        jobTitleLabel: "",
        jobTitleObj: {},
        businessTitle: "",
        businessUnit: "",
        grip: 0,
        gripLabel: "",
        gripCode: "",
        gripObj: {},
        staff: 0,
        staffLabel: "",
        staffObj: {},
        compensationGrade: 0,
        compensationGradeLabel: "",
        compensationGradeObj: {},
        compensationGradeProfile: 0,
        compensationGradeProfileLabel: "",
        compensationGradeProfileObj: {},
        payPlan: 1,
        payPlanLabel: "Salary Plan",
        payPlanObj: { id: 1, name: "Salary Plan" },
        performencePayPlan: 0,
        performencePayPlanLabel: "",
        performencePayPlanObj: {},
        salaryAmount: 0,
        performencePay: 0,
        targetPay: 0,
        allowancePlan: "",
        allowancePlanLabel: "",
        allowancePlanObj: [],
        allowanceAmountObj: [],
        bfp: 0,
        bp: 0,
        bpa: 0,
        hra: 0,
        totalRetirals: 0,
        pfContri: 0,
        gratuityContri: 0,
        negotiationLetterType: 0,
        negotiationLetterTypeLabel: "",
        negotiationLetterTypeObj: {},
        compensationLetterUploadId: 0,
        isCompensationLetterUploaded: false,
        isNegotiationSent: false,
        digiLetterWorkflowId: 0,
        isCompensationAccepted: true,
        isCompensationRejected: false,
        digiLetterStatusId: 0,
        isOfferAccepted: false,
        isOfferRejected: false,
        offerLetterUploadId: 0,
        isOfferLetteruploaded: false,
        siemensLogo: "../assets/images/sie-logo-petrol-rgb.png",
    });
    let [compensationAmount, setCompensationAmount] = useState({
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    });
    let [compensationWSAAmount, setCompensationWSAAmount] = useState({
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        safpm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    });
    let [insuranceAmount, setInsuranceAmount] = useState({
        ghic: 0.0,
        gpaic: 0.0,
        gtLic: 0.0,
        edli: 0.0,
        esic: "",
    });
    let [compensationFactors, setCompensationFactors] = useState({
        compensationFactorId: 0,
        gripId: 0,
        letterTypeId: 0,
        basicSalaryFactor: 0.0,
        performencePayFactor: 0.0,
        pfFactor: 0.0,
        gratuityFactor: 0.0,
        totalFixedPayFactor: 0.0,
        safFactor: 0.0,
        totalFixedPayWithSafFactor: 0.0,
    });
    let [insuranceData, setInsuranceData] = useState({
        id: 0,
        gripId: 0,
        ghicAmount: 0.0,
        gpaicAmount: 0.0,
        gtLicAmount: 0.0,
        edliAmount: 0.0,
        micAmount: 0.0,
    });

    function compensationCalculationforPL13_14(value) {
        let tfppa = getRoundUptoXDecimals(
            value * compensationFactors.totalFixedPayFactor,
            0
        );
        let basicpa = getRoundUptoXDecimals(
            tfppa * compensationFactors.basicSalaryFactor,
            0
        );
        let basicpm = getRoundUptoXDecimals(basicpa / 12, 0);
        let pfpm = 0;
        if (basicpm > 15000) {
            pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
        } else {
            // pfpm = 1800;
            pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
        }
        let gratuitypm = getRoundUptoXDecimals(
            basicpm * compensationFactors.gratuityFactor,
            0
        );
        let totalRetiralspm = getRoundUptoXDecimals(pfpm + gratuitypm, 0);
        let totalRetiralspa = getRoundUptoXDecimals(totalRetiralspm * 12, 0);
        let tapa = getRoundUptoXDecimals(tfppa - totalRetiralspa - basicpa, 0);
        let bfppm = getRoundUptoXDecimals(tapa / 12 - 0, 0);
        let bppa = getRoundUptoXDecimals(basicpa + tapa, 0);
        let tfppm = getRoundUptoXDecimals(bppa / 12 + totalRetiralspm, 0);
        let pppa = getRoundUptoXDecimals(
            (tfppa - totalRetiralspa) * compensationFactors.performencePayFactor,
            0
        );
        let elepa = getRoundUptoXDecimals((basicpm / 30) * 22, 0);

        //With SAF
        let tfpWSApa = getRoundUptoXDecimals(
            value * compensationFactors.totalFixedPayWithSafFactor,
            0
        );
        let basicWSApa = getRoundUptoXDecimals(
            tfpWSApa * compensationFactors.basicSalaryFactor,
            0
        );
        let basicWSApm = getRoundUptoXDecimals(basicWSApa / 12, 0);
        let pfWSApm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.pfFactor,
            0
        );
        let gratuityWSApm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.gratuityFactor,
            0
        );
        let safpm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.safFactor,
            0
        );
        let totalRetiralsWSApm = getRoundUptoXDecimals(
            pfWSApm + gratuityWSApm + safpm,
            0
        );
        let totalRetiralsWSApa = getRoundUptoXDecimals(totalRetiralsWSApm * 12, 0);
        let taWSApa = getRoundUptoXDecimals(
            tfpWSApa - totalRetiralsWSApa - basicWSApa,
            0
        );
        let bfpWSApm = getRoundUptoXDecimals(taWSApa / 12 - 0, 0);
        let bpWSApa = getRoundUptoXDecimals(basicWSApa + taWSApa, 0);
        let tfpWSApm = getRoundUptoXDecimals(bpWSApa / 12 + totalRetiralsWSApm, 0);
        let ppWSApa = getRoundUptoXDecimals(
            (tfpWSApa - totalRetiralsWSApa) *
            compensationFactors.performencePayFactor,
            0
        );
        let eleWSApa = getRoundUptoXDecimals((basicWSApm / 30) * 22, 0);

        //Insurances
        let ghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
        let gpaicpa = getRoundUptoXDecimals(
            value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
            0
        );
        let gtLicpa = getRoundUptoXDecimals(
            value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
            0
        );
        let edlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
        let esic =
            ((getRoundUptoXDecimals(tapa / 12, 0) < 21000) ||
                (getRoundUptoXDecimals(taWSApa / 12, 0) < 21000))
                ? "Eligible as gross salary below ESI eligibility"
                : " Not applicable as gross salary above ESI eligibility";
        debugger;
        setCompensationAmount({
            ...compensationAmount,
            targetPay: getRoundUptoXDecimals(value, 0),
            basicpa: basicpa,
            basicpm: basicpm,
            tfppa: tfppa,
            tfppm: tfppm,
            bfppm: bfppm,
            pfpm: pfpm,
            gratuitypm: gratuitypm,
            totalRetiralspm: totalRetiralspm,
            totalRetiralspa: totalRetiralspa,
            tapa: tapa,
            bppa: bppa,
            pppa: pppa,
            elepa: elepa,
            hra: 0.0,
            bpa: 0.0,
        });
        setCompensationWSAAmount({
            ...compensationWSAAmount,
            targetPay: getRoundUptoXDecimals(value, 0),
            basicpa: basicWSApa,
            basicpm: basicWSApm,
            tfppa: tfpWSApa,
            tfppm: tfpWSApm,
            bfppm: bfpWSApm,
            pfpm: pfWSApm,
            gratuitypm: gratuityWSApm,
            safpm: safpm,
            totalRetiralspm: totalRetiralsWSApm,
            totalRetiralspa: totalRetiralsWSApa,
            tapa: taWSApa,
            bppa: bpWSApa,
            pppa: ppWSApa,
            elepa: eleWSApa,
        });
        setInsuranceAmount({
            ...insuranceAmount,
            ghic: ghicpa,
            gpaic: gpaicpa,
            gtLic: gtLicpa,
            edli: edlipa,
            esic: esic,
        });

        let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
            if (c.name.includes("Base Flexible Pay p.m.")) {
                c.allowanceAmount = bfppm > 0 ? bfppm : c.allowanceAmount;
            } else if (c.name.includes("Total Retirals p.a.")) {
                c.allowanceAmount =
                    totalRetiralspa > 0 ? totalRetiralspa : c.allowanceAmount;
            }
            return c;
        });
        let inputUpdatedDHL = {
            ...inputDataToDHL,
            salaryAmount: basicpm,
            allowanceAmountObj: allowanceAmount,
            performencePay: pppa,
        };
        // setInputDataToDHL({
        //     ...inputDataToDHL,
        //     salaryAmount: basicpm,
        //     allowanceAmountObj: allowanceAmount,
        //     performencePay: pppa,
        // });
        // setInputDataToDHL(inputUpdatedDHL);
        //dispatch(inputDataDHLAction(inputUpdatedDHL));
    }
    //------------------- GBS Calculation for PL10,PL11,PL12 Without SF and Without Performance Pay----------------------------
    function gbsCompensationForBelow15KBasic(value) {
        if (value <= 450000) {

            let gbssupallowance = 0;
            let gbsbfppm = 0;
            let gbspfpm = 0;

            let gbstfppm = getRoundUptoXDecimals(value / 12, 2);
            let gbstfppa = getRoundUptoXDecimals(gbstfppm * 12, 2);
            let gbsbasicpm = getRoundUptoXDecimals(gbstfppm * 0.4, 2);
            let gbsbasicpa = getRoundUptoXDecimals(gbsbasicpm * 12, 2);
            let gbshra = getRoundUptoXDecimals(gbsbasicpm / 2, 2);
            let gbsmealcard = getRoundUptoXDecimals(2200, 0);
            let gbsgratuitypm = getRoundUptoXDecimals(gbsbasicpm * .0425, 2);
            let factor1;
            let factor2;
            if (value > gbsmealcard) {
                factor1 = (gbstfppm - gbsgratuitypm - (1.12 * gbsbasicpm) - gbshra - gbsmealcard);
                factor2 = (gbstfppm - gbsgratuitypm - 1800 - gbsbasicpm - gbshra - gbsmealcard);
            }
            else {
                factor1 = (gbsmealcard - gbstfppm - gbsgratuitypm - (1.12 * gbsbasicpm) - gbshra);
                factor2 = (gbsmealcard - 1800 - gbstfppm - gbsgratuitypm - - gbsbasicpm - gbshra);
            }

            if (((factor1 / 1.12) + gbsbasicpm) <= 15000) {
                gbssupallowance = getRoundUptoXDecimals((factor1 / 1.12), 2);
            } else {
                gbssupallowance = getRoundUptoXDecimals(factor2, 2);
            }
            gbsbfppm = getRoundUptoXDecimals((gbsmealcard + gbssupallowance), 2);
            let gbsbasepaypm = gbsbasicpm + gbsbfppm + gbshra;
            let gbsbasepaypa = getRoundUptoXDecimals(gbsbasepaypm * 12, 2);
            // For GBS Annexure
            let gbsnewpfwage = gbsbasicpm + gbssupallowance;
            if (gbsnewpfwage >= 15000) {
                gbspfpm = 1800;
            }
            else if (gbsnewpfwage < 15000) {
                gbspfpm = getRoundUptoXDecimals((gbsnewpfwage / 100) * 12, 2);

            }

            let gbstotalRetiralspm = getRoundUptoXDecimals(gbspfpm + gbsgratuitypm, 2);
            let gbstotalRetiralspa = getRoundUptoXDecimals(gbstotalRetiralspm * 12, 2);
            let gbstapa = getRoundUptoXDecimals(gbsbasepaypa - gbstotalRetiralspa - gbsbasicpa, 2);
            let gbsbppa = getRoundUptoXDecimals(gbsbasicpa + gbstapa, 2);


            let gbspppa = getRoundUptoXDecimals(
                (gbstfppa - gbstotalRetiralspa) * compensationFactors.performencePayFactor,
                2);
            let gbselepa = getRoundUptoXDecimals((gbsbasicpm / 30) * 22, 2);
            //let gbstfppm = getRoundUptoXDecimals(gbsbppa / 12 + gbstotalRetiralspm, 0);
            //let gbstfppa = getRoundUptoXDecimals(
            //    gbstfppm * 12,
            //    0
            //);

            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                bpa: gbsbasepaypa,
                hra: gbshra,
            });

            //let gbstfpWSApa = getRoundUptoXDecimals(
            //    value * compensationFactors.totalFixedPayWithSafFactor,
            //    0
            //);
            //let gbsbasicWSApa = getRoundUptoXDecimals(
            //    gbstfpWSApa * compensationFactors.basicSalaryFactor,
            //    0
            //);
            //let gbsbasicWSApm = getRoundUptoXDecimals(gbsbasicWSApa / 12, 0);
            ////let gbspfWSApm = getRoundUptoXDecimals(
            ////    gbsbasicWSApm * compensationFactors.pfFactor,
            ////    0
            ////);
            //let gbspfWSApm = 1800;
            //let gbsgratuityWSApm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.gratuityFactor,
            //    0
            //);
            //let gbssafpm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.safFactor,
            //    0
            //);
            //let gbstotalRetiralsWSApm = getRoundUptoXDecimals(
            //    gbspfWSApm + gbsgratuityWSApm + gbssafpm,
            //    0
            //);
            //let gbstotalRetiralsWSApa = getRoundUptoXDecimals(gbstotalRetiralsWSApm * 12, 0);
            //let gbstaWSApa = getRoundUptoXDecimals(
            //    gbstfpWSApa - gbstotalRetiralsWSApa - gbsbasicWSApa,
            //    0
            //);
            //let gbsbfpWSApm = getRoundUptoXDecimals(gbstaWSApa / 12 - 0, 0);
            //let gbsbpWSApa = getRoundUptoXDecimals(gbsbasicWSApa + gbstaWSApa, 0);
            //let gbstfpWSApm = getRoundUptoXDecimals(gbsbpWSApa / 12 + gbstotalRetiralsWSApm, 0);
            //let gbsppWSApa = getRoundUptoXDecimals(
            //    (gbstfpWSApa - gbstotalRetiralsWSApa) *
            //    compensationFactors.performencePayFactor,
            //    0
            //);
            //let gbseleWSApa = getRoundUptoXDecimals((gbsbasicWSApm / 30) * 22, 0);

            //Insurances
            let gbsghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gbsgpaicpa = getRoundUptoXDecimals(
                value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gbsgtLicpa = getRoundUptoXDecimals(
                value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
                0
            );
            let gbsedlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let gbsesic =
                getRoundUptoXDecimals(gbstapa / 12, 0) < 23200 ?
            "Eligible as gross salary below ESI eligibility"
                    : " Not applicable as gross salary above ESI eligibility";
            debugger;
            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                bpa: gbsbasepaypa,
                hra: gbshra,
            });
            //setCompensationWSAAmount({
            //    ...compensationWSAAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicWSApa,
            //    basicpm: gbsbasicWSApm,
            //    tfppa: gbstfpWSApa,
            //    tfppm: gbstfpWSApm,
            //    bfppm: gbsbfpWSApm,
            //    pfpm: gbspfWSApm,
            //    gratuitypm: gbsgratuityWSApm,
            //    safpm: gbssafpm,
            //    totalRetiralspm: gbstotalRetiralsWSApm,
            //    totalRetiralspa: gbstotalRetiralsWSApa,
            //    tapa: gbstaWSApa,
            //    bppa: gbsbpWSApa,
            //    pppa: gbsppWSApa,
            //    elepa: gbseleWSApa,
            //    bpa: gbsbasepaypa,
            //    hra: gbshra,
            //});
            setInsuranceAmount({
                ...insuranceAmount,
                ghic: gbsghicpa,
                gpaic: gbsgpaicpa,
                gtLic: gbsgtLicpa,
                edli: gbsedlipa,
                esic: gbsesic,
            });

            let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = gbsbfppm > 0 ? gbsbfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        gbstotalRetiralspa > 0 ? gbstotalRetiralspa : c.allowanceAmount;
                }
                return c;
            });

            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: gbsbasicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: gbspppa,
            // });

            let inputUpdatedDHL = {
                ...inputDataToDHL,
                salaryAmount: gbsbasicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: gbspppa,
            };
            //setInputDataToDHL(inputUpdatedDHL);
            //dispatch(inputDataDHLAction(inputUpdatedDHL));
        }

    }
    /*-------------------------------------------------BELOW 15K GBS Annexure Ends Here-------------------------------------*/
    function compensationCalculation(value) {
        if (value == 510000) {
            let gbstfppa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayFactor,
                0
            );
            compensationFactors.basicSalaryFactor = 0.353;
            let gbsbasicpa = getRoundUptoXDecimals(
                gbstfppa * compensationFactors.basicSalaryFactor,
                0
            );
            let gbsbasicpm = getRoundUptoXDecimals(gbsbasicpa / 12, 0);
            // For GBS Annexure

            let gbspfpm = 1800;
            let gbsgratuitypm = getRoundUptoXDecimals(
                gbsbasicpm * compensationFactors.gratuityFactor,
                0
            );
            let gbstotalRetiralspm = getRoundUptoXDecimals(gbspfpm + gbsgratuitypm, 0);
            let gbstotalRetiralspa = getRoundUptoXDecimals(gbstotalRetiralspm * 12, 0);
            let gbstapa = getRoundUptoXDecimals(gbstfppa - gbstotalRetiralspa - gbsbasicpa, 0);
            let gbsbfppm = getRoundUptoXDecimals(gbstapa / 12 - 0, 0);
            let gbsbppa = getRoundUptoXDecimals(gbsbasicpa + gbstapa, 0);
            let gbstfppm = getRoundUptoXDecimals(gbsbppa / 12 + gbstotalRetiralspm, 0);
            let gbspppa = getRoundUptoXDecimals(
                (gbstfppa - gbstotalRetiralspa) * compensationFactors.performencePayFactor,
                0
            );
            let gbselepa = getRoundUptoXDecimals((gbsbasicpm / 30) * 22, 0);

            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                hra: 0.0,
                bpa: 0.0,
            });

            let gbstfpWSApa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayWithSafFactor,
                0
            );
            let gbsbasicWSApa = getRoundUptoXDecimals(
                gbstfpWSApa * compensationFactors.basicSalaryFactor,
                0
            );
            let gbsbasicWSApm = getRoundUptoXDecimals(gbsbasicWSApa / 12, 0);
            //let gbspfWSApm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.pfFactor,
            //    0
            //);
            let gbspfWSApm = 1800;
            let gbsgratuityWSApm = getRoundUptoXDecimals(
                gbsbasicWSApm * compensationFactors.gratuityFactor,
                0
            );
            let gbssafpm = getRoundUptoXDecimals(
                gbsbasicWSApm * compensationFactors.safFactor,
                0
            );
            let gbstotalRetiralsWSApm = getRoundUptoXDecimals(
                gbspfWSApm + gbsgratuityWSApm + gbssafpm,
                0
            );
            let gbstotalRetiralsWSApa = getRoundUptoXDecimals(gbstotalRetiralsWSApm * 12, 0);
            let gbstaWSApa = getRoundUptoXDecimals(
                gbstfpWSApa - gbstotalRetiralsWSApa - gbsbasicWSApa,
                0
            );
            let gbsbfpWSApm = getRoundUptoXDecimals(gbstaWSApa / 12 - 0, 0);
            let gbsbpWSApa = getRoundUptoXDecimals(gbsbasicWSApa + gbstaWSApa, 0);
            let gbstfpWSApm = getRoundUptoXDecimals(gbsbpWSApa / 12 + gbstotalRetiralsWSApm, 0);
            let gbsppWSApa = getRoundUptoXDecimals(
                (gbstfpWSApa - gbstotalRetiralsWSApa) *
                compensationFactors.performencePayFactor,
                0
            );
            let gbseleWSApa = getRoundUptoXDecimals((gbsbasicWSApm / 30) * 22, 0);

            //Insurances
            let gbsghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gbsgpaicpa = getRoundUptoXDecimals(
                value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gbsgtLicpa = getRoundUptoXDecimals(
                value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
                0
            );
            let gbsedlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let gbsesic =
                (getRoundUptoXDecimals(gbstapa / 12, 0) < 23200 ||
                    getRoundUptoXDecimals(gbstaWSApa / 12, 0) < 23200)                   
                    ? "Eligible as gross salary below ESI eligibility"
                    : " Not applicable as gross salary above ESI eligibility";
            debugger;
            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                hra: 0.0,
                bpa: 0.0,
            });
            setCompensationWSAAmount({
                ...compensationWSAAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicWSApa,
                basicpm: gbsbasicWSApm,
                tfppa: gbstfpWSApa,
                tfppm: gbstfpWSApm,
                bfppm: gbsbfpWSApm,
                pfpm: gbspfWSApm,
                gratuitypm: gbsgratuityWSApm,
                safpm: gbssafpm,
                totalRetiralspm: gbstotalRetiralsWSApm,
                totalRetiralspa: gbstotalRetiralsWSApa,
                tapa: gbstaWSApa,
                bppa: gbsbpWSApa,
                pppa: gbsppWSApa,
                elepa: gbseleWSApa,
            });
            setInsuranceAmount({
                ...insuranceAmount,
                ghic: gbsghicpa,
                gpaic: gbsgpaicpa,
                gtLic: gbsgtLicpa,
                edli: gbsedlipa,
                esic: gbsesic,
            });

            let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = gbsbfppm > 0 ? gbsbfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        gbstotalRetiralspa > 0 ? gbstotalRetiralspa : c.allowanceAmount;
                }
                return c;
            });

            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: gbsbasicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: gbspppa,
            // });
            let inputUpdatedDHL = {
                ...inputDataToDHL,
                salaryAmount: gbsbasicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: gbspppa,
            };
            //setInputDataToDHL(inputUpdatedDHL);
            //dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
        else {


            //Without SAF
            let tfppa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayFactor,
                0
            );
            let basicpa = getRoundUptoXDecimals(
                tfppa * compensationFactors.basicSalaryFactor,
                0
            );
            let basicpm = getRoundUptoXDecimals(basicpa / 12, 0);
            let pfpm = 0;
            if (basicpm > 15003) {
                pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            } else {
                //pfpm = 1800;
                pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            }
            // let pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            let gratuitypm = getRoundUptoXDecimals(
                basicpm * compensationFactors.gratuityFactor,
                0
            );
            let totalRetiralspm = getRoundUptoXDecimals(pfpm + gratuitypm, 0);
            let totalRetiralspa = getRoundUptoXDecimals(totalRetiralspm * 12, 0);
            let tapa = getRoundUptoXDecimals(tfppa - totalRetiralspa - basicpa, 0);
            let bfppm = getRoundUptoXDecimals(tapa / 12 - 0, 0);
            let bppa = getRoundUptoXDecimals(basicpa + tapa, 0);
            let tfppm = getRoundUptoXDecimals(bppa / 12 + totalRetiralspm, 0);
            let pppa = getRoundUptoXDecimals(
                (tfppa - totalRetiralspa) * compensationFactors.performencePayFactor,
                0
            );
            let elepa = getRoundUptoXDecimals((basicpm / 30) * 22, 0);

            //With SAF
            let tfpWSApa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayWithSafFactor,
                0
            );
            let basicWSApa = getRoundUptoXDecimals(
                tfpWSApa * compensationFactors.basicSalaryFactor,
                0
            );
            let basicWSApm = getRoundUptoXDecimals(basicWSApa / 12, 0);
            let pfWSApm = 0;
            if (basicWSApm > 15003) {
                pfWSApm = getRoundUptoXDecimals(basicWSApm * compensationFactors.pfFactor, 0);
            } else {
                //pfWSApm = 1800;
                pfWSApm = getRoundUptoXDecimals(basicWSApm * compensationFactors.pfFactor, 0);
            }
            //let pfWSApm = getRoundUptoXDecimals(
            //    basicWSApm * compensationFactors.pfFactor,
            //    0
            //);
            let gratuityWSApm = getRoundUptoXDecimals(
                basicWSApm * compensationFactors.gratuityFactor,
                0
            );
            let safpm = getRoundUptoXDecimals(
                basicWSApm * compensationFactors.safFactor,
                0
            );
            let totalRetiralsWSApm = getRoundUptoXDecimals(
                pfWSApm + gratuityWSApm + safpm,
                0
            );
            let totalRetiralsWSApa = getRoundUptoXDecimals(totalRetiralsWSApm * 12, 0);
            let taWSApa = getRoundUptoXDecimals(
                tfpWSApa - totalRetiralsWSApa - basicWSApa,
                0
            );
            let bfpWSApm = getRoundUptoXDecimals(taWSApa / 12 - 0, 0);
            let bpWSApa = getRoundUptoXDecimals(basicWSApa + taWSApa, 0);
            let tfpWSApm = getRoundUptoXDecimals(bpWSApa / 12 + totalRetiralsWSApm, 0);
            let ppWSApa = getRoundUptoXDecimals(
                (tfpWSApa - totalRetiralsWSApa) *
                compensationFactors.performencePayFactor,
                0
            );
            let eleWSApa = getRoundUptoXDecimals((basicWSApm / 30) * 22, 0);

            //Insurances
            let ghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gpaicpa = getRoundUptoXDecimals(
                value < insuranceData.gpaicAmount ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gtLicpa = getRoundUptoXDecimals(
                value < insuranceData.gtLicAmount ? insuranceData.gtLicAmount : value,
                0
            );
            let edlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let esic =
               ( getRoundUptoXDecimals(tapa / 12, 0) < 23200 ||
                    getRoundUptoXDecimals(taWSApa / 12, 0) < 23200)
                    ?"Eligible as gross salary below ESI eligibility"
                    : " Not applicable as gross salary above ESI eligibility";
            debugger;
            setCompensationAmount({
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: basicpa,
                basicpm: basicpm,
                tfppa: tfppa,
                tfppm: tfppm,
                bfppm: bfppm,
                pfpm: pfpm,
                gratuitypm: gratuitypm,
                totalRetiralspm: totalRetiralspm,
                totalRetiralspa: totalRetiralspa,
                tapa: tapa,
                bppa: bppa,
                pppa: pppa,
                elepa: elepa,
                hra: 0.0,
                bpa: 0.0,
            });
            setCompensationWSAAmount({
                ...compensationWSAAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: basicWSApa,
                basicpm: basicWSApm,
                tfppa: tfpWSApa,
                tfppm: tfpWSApm,
                bfppm: bfpWSApm,
                pfpm: pfWSApm,
                gratuitypm: gratuityWSApm,
                safpm: safpm,
                totalRetiralspm: totalRetiralsWSApm,
                totalRetiralspa: totalRetiralsWSApa,
                tapa: taWSApa,
                bppa: bpWSApa,
                pppa: ppWSApa,
                elepa: eleWSApa,
            });
            setInsuranceAmount({
                ...insuranceAmount,
                ghic: ghicpa,
                gpaic: gpaicpa,
                gtLic: gtLicpa,
                edli: edlipa,
                esic: esic,
            });

            let allowanceAmount = inputDataToDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = bfppm > 0 ? bfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        totalRetiralspa > 0 ? totalRetiralspa : c.allowanceAmount;
                }
                return c;
            });
            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: basicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: pppa,
            // });

            let inputUpdatedDHL = {
                ...inputDataToDHL,
                salaryAmount: basicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: pppa,
            };
            //setInputDataToDHL(inputUpdatedDHL);
            //dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
    }
    function getDigiLetterDetails(encryptedId) {
        
        handleLoader(true, "Fetching data....");
        const token = props.token;
        var stat = localStorage.getItem("Status");
        // id = encryptedId.substr(22);
        id = encryptedId;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    console.log("Inside getDigiLetterDetails", data);
                    // setInputDataToDHL({
                    //     ...inputDataToDHL,
                    //     digiLetterDetailsId: data?.digiLetterDetailsId,
                    //     applicantId: data?.applicantIdEncrypted,
                    //     firstName: data?.firstName,
                    //     lastName: data?.lastName,
                    //     email: data?.email,
                    //     joiningDate: data?.joiningDate,
                    //     companyId: data?.companyId,
                    //     areObj: {
                    //         id: data?.areObj?.companyId,
                    //         areCode: data?.areObj?.companyArename,
                    //         areName: data?.areObj?.companyAredescription,
                    //     },
                    //     mprnumber: data?.mprnumber,
                    //     referenceNo: data?.referenceNo,
                    //     division: data?.division,
                    //     taname: data?.taname,
                    //     taemail: data?.taemail,
                    //     address: data?.address, 
                    //     dateOfBirth: data?.dateOfBirth,
                    //     letterType: data?.letterType,
                    //     letterTypeDetails: data?.letterTypeObj,
                    //     letterTypeLabel: data?.letterTypeObj
                    //         ? data?.letterTypeObj?.letterTypeName
                    //         : "",
                    //     letterTypeObj: {
                    //         id: data?.letterTypeObj?.letterTypeId,
                    //         name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                    //     },
                    //     noOfSignatories: data?.noOfSignatories,
                    //     noOfSignatoriesObj: {
                    //         id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                    //         name:
                    //             data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                    //     },
                    //     noOfSignatoriesLabel:
                    //         data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                    //     signatoryDetails: data?.signatoryDetails,
                    //     employeeType: data?.employeeType,
                    //     employeeTypeLabel: data?.employeeTypeObj
                    //         ? data?.employeeTypeObj?.name
                    //         : "",
                    //     employeeTypeObj: data?.employeeTypeObj,
                    //     jobLocation: data?.jobLocation,
                    //     jobLocationLabel: data?.jobLocationObj
                    //         ? data?.jobLocationObj?.locationCode +
                    //         " - " +
                    //         data?.jobLocationObj?.cityName +
                    //         " - " +
                    //         data?.jobLocationObj?.streetName
                    //         : "",
                    //     jobLocationObj: data?.jobLocationObj
                    //         ? {
                    //             id: data?.jobLocationObj?.locationCodeId,
                    //             name:
                    //                 data?.jobLocationObj?.locationCode +
                    //                 " - " +
                    //                 data?.jobLocationObj?.cityName +
                    //                 " - " +
                    //                 data?.jobLocationObj?.streetName,
                    //         }
                    //         : { id: 0, name: "" },
                    //     locationObj: data?.jobLocationObj,
                    //     jobTitle: data?.jobTitle,
                    //     jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                    //     businessTitle: data?.businessTitle
                    //         ? data?.businessTitle
                    //         : data?.jobTitleObj
                    //             ? data?.jobTitleObj?.name
                    //             : "",
                    //     jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                    //     gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                    //     grip: data?.grip,
                    //     businessUnit: data?.businessUnit,
                    //     gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                    //     gripCode: data?.gripCode,
                    //     staff: data?.staff,
                    //     staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                    //     staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                    //     compensationGrade: data?.compensationGrade,
                    //     compensationGradeLabel: data?.compensationGradeObj
                    //         ? data?.compensationGradeObj?.name
                    //         : "",
                    //     compensationGradeObj: data?.compensationGradeObj
                    //         ? data?.compensationGradeObj
                    //         : { id: 0, name: "" },
                    //     compensationGradeProfile: data?.compensationGradeProfile
                    //         ? data?.compensationGradeProfile
                    //         : { id: 0, name: "" },
                    //     compensationGradeProfileLabel: data?.compensationGradeProfileObj
                    //         ? data?.compensationGradeProfileObj?.name
                    //         : "",
                    //     compensationGradeProfileObj: data?.compensationGradeProfileObj,
                    //     payPlan: data?.payPlan,
                    //     payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                    //     payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                    //     performencePayPlan: data?.performencePayPlan,
                    //     performencePayPlanLabel: data?.performencePayPlanObj
                    //         ? data?.performencePayPlanObj?.name
                    //         : "",
                    //     performencePayPlanObj: data?.performencePayPlanObj
                    //         ? data?.performencePayPlanObj
                    //         : { id: 0, name: "" },
                    //     salaryAmount: data?.salaryAmount,
                    //     targetPay: data?.targetPay,
                    //     performencePay: data?.performencePay,
                    //     joiningBonus: data?.joiningBonus,
                    //     recoveryDuration: data?.recoveryDuration,
                    //     allowancePlanObj:
                    //         data?.allowancePlanObj?.length > 0
                    //             ? data?.allowancePlanObj
                    //             : inputDataToDHL?.allowancePlanObj,
                    //     allowanceAmountObj:
                    //         data?.allowanceAmountObj?.length > 0
                    //             ? data?.allowanceAmountObj
                    //             : inputDataToDHL?.allowanceAmountObj,
                    //     compensationLetterUploadId: data?.compensationLetterUploadId,
                    //     isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                    //     offerLetterUploadId: data?.offerLetterUploadId,
                    //     isOfferLetteruploaded: data?.isOfferLetteruploaded,
                    //     siemensLogo:
                    //         data?.companyId === 1 || data.companyId === 2
                    //             ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                    //             : "../assets/images/sie-logo-petrol-rgb.png",
                    //     digiLetterWorkflowId: data?.digiLetterWorkflowId,
                    //     isCompensationAccepted: data?.isCompensationAccepted,
                    //     isCompensationRejected: data?.isCompensationRejected,
                    //     digiLetterStatusId: data?.digiLetterStatusId,
                    //     isOfferAccepted: data?.isOfferAccepted,
                    //     isOfferRejected: data?.isOfferRejected,
                    //     isSuperannuation: data?.isSuperannuation,
                    // });
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterDetailsId: data?.digiLetterDetailsId,
                        applicantId: data?.applicantIdEncrypted,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        joiningDate: data?.joiningDate,
                        companyId: data?.companyId,
                        areObj: {
                            id: data?.areObj?.companyId,
                            areCode: data?.areObj?.companyArename,
                            areName: data?.areObj?.companyAredescription,
                        },
                        mprnumber: data?.mprnumber,
                        referenceNo: data?.referenceNo,
                        division: data?.division,
                        taname: data?.taname,
                        taemail: data?.taemail,
                        address: data?.address,
                        dateOfBirth: data?.dateOfBirth,
                        letterType: data?.letterType,
                        letterTypeDetails: data?.letterTypeObj,
                        letterTypeLabel: data?.letterTypeObj
                            ? data?.letterTypeObj?.letterTypeName
                            : "",
                        letterTypeObj: {
                            id: data?.letterTypeObj?.letterTypeId,
                            name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                        },
                        noOfSignatories: data?.noOfSignatories,
                        noOfSignatoriesObj: {
                            id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                            name:
                                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        },
                        noOfSignatoriesLabel:
                            data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        signatoryDetails: data?.signatoryDetails,
                        employeeType: data?.employeeType,
                        employeeTypeLabel: data?.employeeTypeObj
                            ? data?.employeeTypeObj?.name
                            : "",
                        employeeTypeObj: data?.employeeTypeObj,
                        jobLocation: data?.jobLocation,
                        jobLocationLabel: data?.jobLocationObj
                            ? data?.jobLocationObj?.locationCode +
                            " - " +
                            data?.jobLocationObj?.cityName +
                            " - " +
                            data?.jobLocationObj?.streetName
                            : "",
                        jobLocationObj: data?.jobLocationObj
                            ? {
                                id: data?.jobLocationObj?.locationCodeId,
                                name:
                                    data?.jobLocationObj?.locationCode +
                                    " - " +
                                    data?.jobLocationObj?.cityName +
                                    " - " +
                                    data?.jobLocationObj?.streetName,
                            }
                            : { id: 0, name: "" },
                        locationObj: data?.jobLocationObj,
                        jobTitle: data?.jobTitle,
                        jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                        businessTitle: data?.businessTitle
                            ? data?.businessTitle
                            : data?.jobTitleObj
                                ? data?.jobTitleObj?.name
                                : "",
                        jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                        gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                        grip: data?.grip,
                        businessUnit: data?.businessUnit,
                        gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                        gripCode: data?.gripCode,
                        staff: data?.staff,
                        staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                        staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                        compensationGrade: data?.compensationGrade,
                        compensationGradeLabel: data?.compensationGradeObj
                            ? data?.compensationGradeObj?.name
                            : "",
                        compensationGradeObj: data?.compensationGradeObj
                            ? data?.compensationGradeObj
                            : { id: 0, name: "" },
                        compensationGradeProfile: data?.compensationGradeProfile
                            ? data?.compensationGradeProfile
                            : { id: 0, name: "" },
                        compensationGradeProfileLabel: data?.compensationGradeProfileObj
                            ? data?.compensationGradeProfileObj?.name
                            : "",
                        compensationGradeProfileObj: data?.compensationGradeProfileObj,
                        payPlan: data?.payPlan,
                        payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                        payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                        performencePayPlan: data?.performencePayPlan,
                        performencePayPlanLabel: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj?.name
                            : "",
                        performencePayPlanObj: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj
                            : { id: 0, name: "" },
                        salaryAmount: data?.salaryAmount,
                        targetPay: data?.targetPay,
                        performencePay: data?.performencePay,
                        joiningBonus: data?.joiningBonus,
                        recoveryDuration: data?.recoveryDuration,
                        allowancePlanObj:
                            data?.allowancePlanObj?.length > 0
                                ? data?.allowancePlanObj
                                : inputDataToDHL?.allowancePlanObj,
                        allowanceAmountObj:
                            data?.allowanceAmountObj?.length > 0
                                ? data?.allowanceAmountObj
                                : inputDataToDHL?.allowanceAmountObj,
                        compensationLetterUploadId: data?.compensationLetterUploadId,
                        isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                        offerLetterUploadId: data?.offerLetterUploadId,
                        isOfferLetteruploaded: data?.isOfferLetteruploaded,
                        siemensLogo:
                            data?.companyId === 1 || data.companyId === 2
                                ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                                : "../assets/images/sie-logo-petrol-rgb.png",
                        digiLetterWorkflowId: data?.digiLetterWorkflowId,
                        isCompensationAccepted: data?.isCompensationAccepted,
                        isCompensationRejected: data?.isCompensationRejected,
                        digiLetterStatusId: data?.digiLetterStatusId,
                        isOfferAccepted: data?.isOfferAccepted,
                        isOfferRejected: data?.isOfferRejected,
                        isSuperannuation: data?.isSuperannuation,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                    // dispatch(inputDataDHLAction(inputUpdatedDHL));

                    //setDigiLetterDataState(data);
                    handleLoader(false, "");
                    //alert(stat);
                    if (stat == "Success") {
                        //handleDialogOpenAlert(
                        //    "Success",
                        //    "Letter Uploaded Successfully",
                        //    "Letter Uploaded Successfully"
                        //);
                        //alert("Success");
                        localStorage.removeItem("Status");
                    }
                    else if (stat == "Failed") {
                        //handleDialogOpenAlert(
                        //    "Failure",
                        //    "Letter Uploaded Failed",
                        //    "Letter Upload Failed"
                        //);
                    }
                    console.log("Inside 1 getDigiLetterDetails", data);
                    handleLoader(false, "");
                }
                console.log("Inside 2 getDigiLetterDetails", result);
                handleLoader(false, "");
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
            });
    }
    function getCompensationFactors(grip, letterType) {
        //setCompensationProgressProps({
        //    ...compensationProgressProps,
        //    isEnable: true,
        //    loaderText: "Fetching compensation factors....",
        //});
        const token = props.token;
        let options = {
            method: "get",
            url: "OnboardingDHLMerge/GetCompensationFactors",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                grip: grip,
                letterType: letterType,
            },
        };
        axios(options)
            .then((response) => {
                if (response.data.result.response) {
                    //setCompensationProgressProps({
                    //    ...compensationProgressProps,
                    //    isEnable: false,
                    //    loaderText: "",
                    //});
                    let data = response.data.result.objResult;
                    /*  handleLoader(true, "Fetching Allowance Details....");*/
                    setCompensationFactors({
                        ...compensationFactors,
                        compensationFactorId: data?.compensationFactorId,
                        gripId: data?.gripId,
                        letterTypeId: data?.letterTypeId,
                        basicSalaryFactor: data?.basicSalaryFactor,
                        performencePayFactor: data?.performencePayFactor,
                        pfFactor: data?.pfFactor,
                        gratuityFactor: data?.gratuityFactor,
                        totalFixedPayFactor: data?.totalFixedPayFactor,
                        safFactor: data?.safFactor,
                        totalFixedPayWithSafFactor: data?.totalFixedPayWithSafFactor,
                    });
                    handleLoader(false, "");
                }
                //else {
                //    //setCompensationProgressProps({
                //    //    ...compensationProgressProps,
                //    //    isEnable: false,
                //    //    loaderText: "",
                //    //});
                //    //handleDialogOpenAlert(
                //    //    "Alert",
                //    //    "Fetch Compensation Factors Alert",
                //    //    response.data.result.responseMsg
                //    //);
                //}
            })
            .catch((error) => {
                //setCompensationProgressProps({
                //    ...compensationProgressProps,
                //    isEnable: false,
                //    loaderText: "",
                /* });*/
                //handleDialogOpenAlert(
                //    "Exception",
                //    "Fetch Compensation Factors Error",
                //    error
                //);
            });
    }
    async function getInsuranceData(grip) {
        //handleLoader(true, "Fetching Insurance Data");
        
        const token = props.token;
        let options = {
            method: "get",
            url: "OnboardingDHLMerge/GetInsurancesDataPerGrip",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                grip: grip,
            },
        };
        let response = await axios(options);

        if (response.data.result.response) {
            
            let data = response.data.result.objResult;
            console.log(data);
            setInsuranceData({
                ...insuranceData,
                id: data?.id,
                gripId: data?.gripId,
                ghicAmount: data?.ghicAmount,
                gpaicAmount: data?.gpaicAmount,
                gtLicAmount: data?.gtLicAmount,
                edliAmount: data?.edliAmount,
                micAmount: data?.micAmount,
            });
        }

        console.log(insuranceData);
        //handleLoader(false, "");
    }
    const [bfpValue, setBFPValue] = useState("");
    function fetchCompensationLetterValues() {
        
        if (storedInputDataDHL != null || storedInputDataDHL != undefined) {
            const allowanceObj = storedInputDataDHL.allowanceAmountObj;
            allowanceObj.map((item) => {
                if (item.name == "Base Flexible Pay p.m.") 
                    setBFPValue(item.allowanceAmount);
            })
        }
    }
    useEffect(() => {
        fetchCompensationLetterValues();
    },[])
    useEffect(() => {
        
        // dispatch(inputDataDHLAction(inputDataToDHL));
        if (props.token) {
            if (encryptedId) {
                getDigiLetterDetails(encryptedId);
            }
        }
    }, [encryptedId, props.token]);
    useEffect(() => {
        if (props.token) {
            if (inputDataToDHL?.grip > 0 && inputDataToDHL?.letterType > 0) {
                getCompensationFactors(
                    inputDataToDHL?.grip,
                    inputDataToDHL?.letterType
                );
            }
        }
    }, [inputDataToDHL?.grip, inputDataToDHL?.letterType, props.token]);
    useEffect(() => {
        

        if (props.token) {
            if (inputDataToDHL?.grip > 0) {
                getInsuranceData(inputDataToDHL?.grip);
                console.log(insuranceData);
            }
        }
    }, [inputDataToDHL?.grip, props.token]);
    useEffect(() => {
        if (inputDataToDHL?.letterType == 7) {
            if (inputDataToDHL?.targetPay && inputDataToDHL.targetPay <= 450000) {

                gbsCompensationForBelow15KBasic(inputDataToDHL?.targetPay);
            }
            else if (inputDataToDHL?.targetPay && inputDataToDHL.targetPay > 450000) {
                if (inputDataToDHL?.targetPay && inputDataToDHL?.grip > 10) {
                    compensationCalculationforPL13_14(inputDataToDHL?.targetPay);
                }

                else if (inputDataToDHL?.grip <= 10) {
                    compensationCalculation(inputDataToDHL?.targetPay);
                }

            }


            //else {
            //    handleDialogOpenAlert(
            //        "Alert",
            //        "For GBS Below 15K: Target Pay should be less than " + inputDataToDHL.targetPay + ".",
            //        "For GBS Below 15K: Target Pay should be less than " + inputDataToDHL.targetPay + ".",
            //    );


            //    inputDataToDHL.targetPay = 0.0;
            //    inputDataToDHL.allowanceAmountObj[0].allowanceAmount = 0.0;
            //    inputDataToDHL.allowanceAmountObj[1].allowanceAmount = 0.0;
            //    // let basefppm = 0.0;
            //    // let totalretiralperannum = 0.0;
            //    // setcompensationAmount({
            //    // ...compensationAmount,
            //    // bfppm: basefppm,

            //    // })
            //    inputDataToDHL.salaryAmount = 0.0;


            //}


        }
        else
            if (inputDataToDHL?.letterType != 7) {
                if (inputDataToDHL?.targetPay && inputDataToDHL?.grip > 10) {
                    compensationCalculationforPL13_14(inputDataToDHL?.targetPay);
                }

                else if (inputDataToDHL?.grip <= 10) {
                    compensationCalculation(inputDataToDHL?.targetPay);
                }
            }

    }, [
        inputDataToDHL?.targetPay,
        inputDataToDHL?.letterType,
        inputDataToDHL?.grip,
        compensationFactors,
    ]);
    function handlePreview(e, type, path) {
        

        switch (type) {
            case "Compensation":
                localStorage.setItem(
                    "LetterHtml",
                    getHTML(allLetterTypeData, type)?.__html
                );
                localStorage.setItem(
                    "LetterPdf",
                    getHTML(allLetterTypeData, type)?.__pdf
                );

                localStorage.setItem(
                    "Type",
                    "Compensation"
                );
                localStorage.setItem("UserId", storedInputDataDHL.userIdencrypted);

                //localStorage.setItem("UserId", props?.userIdencrypted);
                localStorage.setItem("ApplicantId", getURLParameter("id"));
                localStorage.setItem("routePath", path);
                // window.open("/PreviewLetters", "_blank");
                window.open("/PreviewLetters", "_self");
                break;
            case "Offer":
                localStorage.setItem(
                    "LetterHtml",
                    getHTML(allLetterTypeData, type)?.__html
                );
                localStorage.setItem(
                    "LetterPdf",
                    getHTML(allLetterTypeData, type)?.__pdf
                );
                localStorage.setItem(
                    "Type",
                    "Offer"
                );
                localStorage.setItem("UserId", storedInputDataDHL.user);
                //localStorage.setItem("UserId", props?.userIdencrypted);
                localStorage.setItem("ApplicantId", storedInputDataDHL?.applicantId);
                localStorage.setItem("routePath", path);
                // window.open("/PreviewLetters", "_blank");
                window.open("/PreviewLetters", "_self");
                break;
            default:
                break;
        }
    }

    let [inputFileValue, setInputFileValue] = useState(Date.now);
    let [selectedFile, setSelectedFile] = useState(Date.now);
    function setOfferUploadState(data) {
        setOfferUploadedData({
            ...offerUploadedData,
            offerLetterUploadId: data?.offerLetterUploadId,
            applicantId: data?.applicantId,
            userId: props.UserEncryptedId,
            offerFileName: data?.offerFileName,
            offerFileUrl: data?.offerFileUrl,
        });
    }
    function setCompensationUploadState(data) {
        debugger;
        setCompensationUploadedData({
            ...compenstionUploadedData,
            compensationLetterUploadId: data?.compensationLetterUploadId,
            applicantId: data?.applicantId,
            userId: props.UserEncryptedId,
            compensationFileName: data?.compensationFileName,
            compensationFileUrl: data?.compensationFileUrl,
        });
    }
    function setDocumentUploadState(data) {
        setDocumentUploadedData({
            ...documentUploadedData,
            documentUploadId: data?.documentUploadId,
            applicantId: data?.applicantId,
            userId: props.UserEncryotedId,
            documentFileName: data?.documentFileName,
            documentFileUrl: data?.documentFileUrl,
        });
    }
    function uploadCompensationLetter(uploadData) {
        handleLoader(true, "Uploading Compensation Letter");

        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/UploadCompensationLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: uploadData,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    //let data = res.objResult;
                    getUploadedCompensationLetterDetails(
                        storedInputDataDHL?.applicantId
                    );
                    props.handleDialogOpenAlert(
                        "Success",
                        "Upload Compensation Letter Success",
                        res.responseMsg
                    );
                    //setCompensationUploadState(data);
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Upload Compensation Letter Alert",
                        res.responseMsg
                    );
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                props.handleDialogOpenAlert(
                    "Exception",
                    "Upload Compensation Letter Error",
                    error
                );
            });
    }
    function uploadOfferLetter(uploadData) {
        handleLoader(true, "Uploading Offer letter");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/UploadOfferLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: uploadData,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    //let data = res.objResult;
                    getUploadedOfferLetterDetails(storedInputDataDHL?.applicantId);
                    props.handleDialogOpenAlert(
                        "Success",
                        "Upload Offer Letter Success",
                        res.responseMsg
                    );
                    //setOfferUploadState(data);
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Upload Offer Letter Alert",
                        res.responseMsg
                    );
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                props.handleDialogOpenAlert(
                    "Exception",
                    "Upload Offer Letter Error",
                    error
                );
            });
    }

    function uploadAdditionalDocument(uploadData) {
        handleLoader(true, "Uploading Document");
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/UploadAdditionalDocument",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: uploadData,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    //let data = res.objResult;
                    getUploadedDocumentDetails(storedInputDataDHL?.applicantId);
                    props.handleDialogOpenAlert(
                        "Success",
                        "Upload Document Success",
                        res.responseMsg
                    );
                    //setOfferUploadState(data);
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Upload Document Alert",
                        res.responseMsg
                    );
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                props.handleDialogOpenAlert(
                    "Exception",
                    "Upload Document Error",
                    error
                );
            });
    }
    function deleteUploadedCompensationLetterDetails(data) {
        debugger;
        handleLoader(true, "Deleting...");
        const compensationLetters = data;
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/DeleteCompensationLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: compensationLetters,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    let data = res.objResult;
                    getUploadedCompensationLetterDetails(
                        storedInputDataDHL?.applicantId
                    );
                    props.handleDialogOpenAlert(
                        "Success",
                        "Delete Compensation Letter Success",
                        res.responseMsg
                    );
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Delete Compensation Letter ALert",
                        res.responseMsg
                    );
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                props.handleDialogOpenAlert(
                    "Exception",
                    "Delete Compensation Letter Error",
                    error
                );
            });
        handleLoader(false, "");
    }
    function deleteUploadedOfferLetterDetails(data) {
        debugger;
        handleLoader(true, "Deleting...");
        const offerLetters = data;
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/DeleteOfferLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: offerLetters,
        }
            ;
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    let data = res.objResult;
                    getUploadedOfferLetterDetails(storedInputDataDHL?.applicantId);
                    props.handleDialogOpenAlert(
                        "Success",
                        "Delete Offer Letter Success",
                        res.responseMsg
                    );
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Delete Offer Letter ALert",
                        res.responseMsg
                    );
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                props.handleDialogOpenAlert(
                    "Exception",
                    "Delete Offer Letter Error",
                    error
                );
            });
        handleLoader(false, "");
    }
    function deleteUploadedAdditionalDocumentDetails(data) {
        handleLoader(true, "Deleting...");
        const token = props.token;
        const options = {
            method: "put",
            url: "OnboardingDHLMerge/DeleteAdditionalDocument",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: data,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    let data = res.objResult;
                    getUploadedDocumentDetails(storedInputDataDHL?.applicantId);
                    props.handleDialogOpenAlert(
                        "Success",
                        "Delete Offer Additinal Document Success",
                        res.responseMsg
                    );
                } else {
                    props.setLetterDetailsProgressProps({
                        ...props.letterDetailsProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    props.handleDialogOpenAlert(
                        "Alert",
                        "Delete Additional Document ALert",
                        res.responseMsg
                    );
                }
            })
            .catch((error) => {
                props.setLetterDetailsProgressProps({
                    ...props.letterDetailsProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                props.handleDialogOpenAlert(
                    "Exception",
                    "Delete Additional Document Error",
                    error
                );
            });
        handleLoader(false, "");
    }
    function getUploadedCompensationLetterDetails(id) {
        debugger;
        handleLoader(true, "Fetching Details");
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetUploadedCompensationLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: { id: id },
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    let data = res.objResult;
                    setCompensationUploadState(data);
                } else {
                    setCompensationUploadedData({
                        ...compenstionUploadedData,
                        compensationLetterUploadId: 0,
                    });
                }
            })
            .catch((error) => console.log(error));
        handleLoader(false, "");
    }
    function getUploadedOfferLetterDetails(id) {
        handleLoader(true, "Fetching Details");
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetUploadedOfferLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: { id: id },
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    let data = res.objResult;
                    setOfferUploadState(data);
                } else {
                    setOfferUploadedData({
                        ...offerUploadedData,
                        offerLetterUploadId: 0,

                    });
                    setActiveFlag(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        handleLoader(false, "");
    }
    function getUploadedDocumentDetails(id) {
        handleLoader(true, "Fetching Details");
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetUploadedDocumentDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: { id: id },
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    let data = res.objResult;
                    setDocumentUploadState(data);
                } else {
                    setDocumentUploadState({
                        ...documentUploadedData,
                        documentUploadId: 0,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
        handleLoader(false, "");
    }
    function handleUploadFile(e, type) {

        let uploadData = new FormData();
        uploadData.append(
            "ApplicantId",
            storedInputDataDHL?.applicantId
                ? storedInputDataDHL?.applicantId
                : 0
        );
        uploadData.append("UserId", props.userIdencrypted);
        uploadData.append("files", selectedFile);
        props.setLetterDetailsProgressProps({
            ...props.letterDetailsProgressProps,
            isEnable: true,
            loaderText: "Upload Letter in progress....",
        });
        switch (type) {
            case "Compensation":
                uploadCompensationLetter(uploadData);
                break;
            case "Offer":
                uploadOfferLetter(uploadData);
                break;
            case "Document":
                uploadAdditionalDocument(uploadData);
                break;
            default:
                break;
        }
    }
    function handleDeleteUploadedLetter(e, type) {
        debugger;
        props.setLetterDetailsProgressProps({
            ...props.letterDetailsProgressProps,
            isEnable: true,
            loaderText: "Delete Letter in progress....",
        });
        switch (type) {
            case "Compensation":
                deleteUploadedCompensationLetterDetails(compenstionUploadedData);
                break;
            case "Offer":
                deleteUploadedOfferLetterDetails(offerUploadedData);
                break;
            case "Document":
                deleteUploadedAdditionalDocumentDetails(documentUploadedData);
                break;
            default:
                break;
        }
    }
    function getUploadAddornmentCompensation() {
        return (
            <IconButton
                // title={
                //   "Upload " +
                //   (props?.inputDataToDHL?.letterTypeObj?.name
                //     ? props?.inputDataToDHL?.letterTypeObj?.name
                //     : "")
                // }
                title="Upload Compensation Letter"
                onClick={(e) => handleUploadFile(e, "Compensation")}
                disabled={!selectedFile}
            >
                <UploadIcon />
            </IconButton>
        );
    }
    function getUploadAddornmentOffer() {
        return (
            <IconButton
                // title={
                //   "Upload " +
                //   (props?.inputDataToDHL?.letterTypeObj?.name
                //     ? props?.inputDataToDHL?.letterTypeObj?.name
                //     : "")
                // }
                title="Upload Offer Letter"
                onClick={(e) => handleUploadFile(e, "Offer")}
                disabled={!selectedFile}
            >
                <UploadIcon />
            </IconButton>
        );
    }

    function getUploadAddornmentDocument() {
        return (
            <IconButton
                // title={
                //   "Upload " +
                //   (props?.inputDataToDHL?.letterTypeObj?.name
                //     ? props?.inputDataToDHL?.letterTypeObj?.name
                //     : "")
                // }
                title="Upload Additional Document"
                onClick={(e) => handleUploadFile(e, "Document")}
                disabled={!selectedFile}
            >
                <UploadIcon />
            </IconButton>
        );
    }
    const handleCloseConfirm = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogPropsConfirm({
            ...dialogPropsConfirm,
            open: false,
            className: "bg-light-green clr-white",
            type: "",
            title: "",
            content: "",
        });
    };
    const handleConfirm = (e) => {
        if (dialogPropsConfirm?.type !== "Recall Offer Letter") {
            setDialogPropsConfirm({
                ...dialogPropsConfirm,
                open: false,
                className: "bg-light-green clr-white",
                type: "",
                title: "",
                content: "",
            });
        }
        switch (dialogPropsConfirm?.type) {
            default:
                handleCloseConfirm();
                break;
        }
    };
    const confirmDialogComponenet = (
        <Dialog
            open={dialogPropsConfirm?.open}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadClear"
        >
            <DialogTitle
                className={dialogPropsConfirm?.className}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
            >
                {dialogPropsConfirm?.title}
            </DialogTitle>
            <DialogActions className="bg-content">
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleConfirm}
                    className="ns-btn-secondary-light mr-15px"
                >
                    <span className="f-16 fw-bold clr-white">Confirm</span>
                </Button>
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleCloseConfirm}
                    className="ns-btn-secondary-light"
                >
                    <span className="f-16 fw-bold clr-white">Close</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
    const handleFileChange = (e) => {
        if (e.target.files[0]?.type !== "application/pdf") {
            props.handleDialogOpenAlert(
                "Alert",
                "Upload Alert",
                "Only PDF file is allowed"
            );
            setInputFileValue(Date.now);
        }
        setSelectedFile(e.target.files[0]);
    };
    const handleFileTypeChange = (e) => {
        if (e.target.files[0]?.type == "application/x-msg") {
            props.handleDialogOpenAlert(
                "Alert",
                "Upload Alert",
                "Please select valid file"
            );
            setInputFileValue(Date.now);
        }
        setSelectedFile(e.target.files[0]);
    };
    useEffect(() => {
        if (props) {
            getLetterTypes();
            getAdditionalDocumentTypes();
            getNoOfSignatories();

            // getSignatoriesGid();
            // if (props?.inputDataToDHL?.letterType) {
            //   getLetterTemplates();
            // }
            if (storedInputDataDHL?.applicantId) {
                getUploadedCompensationLetterDetails(
                    storedInputDataDHL?.applicantId
                );
                getUploadedOfferLetterDetails(storedInputDataDHL?.applicantId);
                getUploadedDocumentDetails(storedInputDataDHL?.applicantId);
                getSignatoryDetails(storedInputDataDHL?.applicantId);
            }
        }
    }, [props.token, storedInputDataDHL.applicantId]);
    useEffect(() => {
        if (storedInputDataDHL?.noOfSignatories > 0) {
            var obj = itemsNoOfSignatories.find(
                (c) => c.id === storedInputDataDHL?.noOfSignatories
            );
            props.setInputDataToDHL({
                ...props.inputDataToDHL,
                noOfSignatoriesLabel: obj ? obj?.name : "",
                noOfSignatoriesObj: obj,
            });
        }
    }, [storedInputDataDHL?.noOfSignatories]);


    const [itemsSignatoriesDDL, setItemSignatoriesDDL] = useState([]);
    function getSignatoriesFromDHL() {
        handleLoader(true, "Get Signatories");
        const options = {
            method: "get",
            mode: "cors",
            url: baseDHLApiURL + "/DHLAPI/GetSignatories",
            headers: {
                "Access-Control-Allow-Credentials": true,
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                //Authorization: "Bearer " + siteToken,
            },
        };
        axios(options)
            //.then((response) => response.json())
            .then((response) => {
                if (response.data.result.response) {
                    let data = response.data.result.objResult;
                    setItemSignatoriesFromDHL(data);
                    setItemSignatoriesDDL(
                        data.map((c) => ({ value: c?.gid, label: c?.displayName }))
                    );
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        handleLoader(false, "");
    }
    function handleRecallOfferLetter(e) {
        props.handleDialogOpenConfirm(
            "Recall Offer Letter",
            "Recall Offer Letter Confirmation",
            "Are you sure you want to recall the offer letter? If yes please proceed with reason."
        );
    }

    useEffect(() => {
        getSignatoriesFromDHL();
    }, []);



    return (
        <>
            {props?.letterDetailsProgressProps?.isEnable ? (
                ProgressComponent
            ) : (
                <Grid container spacing={1} className="d-flex jc-space-around fd-row">
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="d-flex jc-center Width-100 mt-1"
                    >
                        <h5 className="clr-dark-blue">Letter Details</h5>
                    </Grid>
                    {/* <Grid item xs={12} sm={3} className="Width-100">
          <Autocomplete
            disableClearable={true}
            className="lightInputFields hover-lightblue mt-1"
            id="letterTemplateType"
            value={props.inputDataToDHL.letterTemplateTypeObj}
            inputValue={props.inputDataToDHL.letterTemplateTypeLabel}
            options={itemsFinalLetterTemplates}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  (props?.inputDataToDHL?.letterTypeObj?.name
                    ? props?.inputDataToDHL?.letterTypeObj?.name
                    : "") + " Template Type"
                }
                name="letterTemplateType"
                variant="filled"
                required
                error={
                  props.inputDataToDHL.letterTemplateTypeErrorText &&
                  props.inputDataToDHL.letterTemplateTypeErrorText.length > 0
                }
                helperText={props.inputDataToDHL.letterTemplateTypeErrorText}
                style={{ color: "#f00" }}
                disabled={!props?.inputDataToDHL?.letterType}
              />
            )}
          />
        </Grid> */}
                    <Grid item xs={12} sm={6} className="Width-100 d-flex jc-center mt-1">
                        <Button
                            className="ns-btn-fit-content mt-075 Width-fit Height-fit"
                            disabled={!storedInputDataDHL?.letterType > 0 || storedInputDataDHL?.isCompensationLetterUploaded}
                            // title={
                            //   "Preview " +
                            //   (props?.inputDataToDHL?.letterTypeObj?.name
                            //     ? props?.inputDataToDHL?.letterTypeObj?.name
                            //     : "")
                            // }
                            title="Compensation Letter"
                            onClick={(e) => handlePreview(e, "Compensation", window.location.href)}
                        >
                            Compensation Letter
                        </Button>
                    </Grid>
                    {compenstionUploadedData?.compensationLetterUploadId > 0 ? (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 d-flex jc-center mt-1"
                        >
                            <a
                                className="mt-1"
                                href={compenstionUploadedData?.compensationFileUrl}
                                target="_blank"
                            >
                                <Tooltip
                                    title="Open Uploaded Compensation Letter"
                                    placement="top"
                                >
                                    <span
                                        className="f-14 fw-bold clr-dark-blue"
                                        style={{
                                            maxWidth: "100%",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {/* {compenstionUploadedData?.compensationFileName} */}
                                        Compensation Letter uploaded successfully
                                    </span>
                                </Tooltip>
                            </a>
                            <Tooltip
                                title="Delete Uploaded Compensation Letter"
                                placement="top"
                            >
                                <IconButton
                                    onClick={(e) => handleDeleteUploadedLetter(e, "Compensation")}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        color="secondary"
                                        style={{ fontSize: 15 }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 mt-1 d-flex jc center"
                        >
                            <TextField
                                style={{ display: "none" }}
                                id="fileUploadMaster"
                                variant="filled"
                                // label={
                                //   "Upload " +
                                //   (props?.inputDataToDHL?.letterTypeObj?.name
                                //     ? props?.inputDataToDHL?.letterTypeObj?.name
                                //     : "File")
                                // }
                                label="Upload Compensation Letter"
                                fullWidth
                                type="file"
                                disabled={!storedInputDataDHL?.letterType > 0}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {getUploadAddornmentCompensation()}
                                        </InputAdornment>
                                    ),
                                }}
                                key={inputFileValue}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleFileChange}
                            />
                        </Grid>
                    )}
                    <Grid
                        container
                        spacing={1}
                        className="d-flex jc-space-around fd-row mt-1"
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="d-flex jc-center Width-100 mt-1"
                        >
                            <Button
                                xs={12}
                                sm={3}
                                className="ns-btn-fit-content mt-2 ml-2"
                                disabled={!storedInputDataDHL?.isCompensationLetterUploaded}
                                onClick={handleClickSaveAndSendNegotiation}
                            >
                                Send Compensation Proposal
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 d-flex jc-center mt-1"
                        >
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="d-flex jc-center Width-100 mt-1"
                    >
                        <h5 className="clr-dark-blue">Signatory Details</h5>
                    </Grid>

                    <>
                        <Grid item xs={12} sm={12} className="jc-center Width-100 mt-1">
                            <table>
                                <tbody>
                                    <tr className="d-flex jc-space-bt f-14 font-italic mt-1">
                                        <td></td>
                                        <td className="clr-dark-blue">
                                            {/***Note : Please provide GID on <span className="fw-bold">In Company Manager</span> text field*/}
                                        </td>
                                        <td className="clr-red fw-bold">
                                            *Mandatory : LHS should be HR and RHS should be Business.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <MaterialTable
                                title="Signatory Details"
                                columns={columns}
                                data={signatoryData}
                                icons={tableIcons}
                                //   isLoading
                                localization={{
                                    header: {
                                        actions: "",
                                    },
                                }}
                                options={{
                                    search: false,

                                    showTitle: false,
                                    draggable: false,
                                    sorting: false,
                                }}
                                components={{
                                    Pagination: (props) => <></>,
                                }}
                                editable={{
                                    isEditable: (rowData) => !props.disableValue,
                                    isDeletable: (rowData) => !props.disableValue,

                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate(newData, oldData, resolve, reject);
                                        }),
                                    onRowAdd: (newData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowAdd(newData, resolve, reject);
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDelete(oldData, resolve, reject);
                                        }),
                                }}
                            />
                        </Grid>
                    </>

                    {!signatoryData?.length > 0 ? (

                        <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
                            <p className="clr-red font-italic f-12 fw-bold">
                                *Signatory details mandatory to preview offer letter
                            </p>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid
                        container
                        spacing={1}
                        className="d-flex jc-space-around fd-row mt-1"
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="d-flex jc-center Width-100 mt-1"
                        >
                            <Button
                                xs={12}
                                sm={3}
                                className="ns-btn-fit-content mt-2"
                                disabled={saveFlag}
                                onClick={props.handleClickSave}
                            >
                                Save
                            </Button>

                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 d-flex jc-center mt-1"
                        >
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="d-flex jc-center Width-100 mt-1">
                        <Button
                            className="ns-btn-fit-content mt-075"
                            disabled={
                                !storedInputDataDHL?.isCompensationAccepted ||

                                saveFlag || activeFlag
                            }

                            title={"Final Contract Letter"}
                            onClick={(e) => handlePreview(e, "Offer", window.location.href)}
                        >
                            Final Contract Letter
                        </Button>

                    </Grid>

                    {offerUploadedData?.offerLetterUploadId > 0 ? (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 d-flex jc-center mt-1"
                        >
                            <a
                                className="mt-1"
                                href={offerUploadedData?.offerFileUrl}
                                target="_blank"
                            >
                                <Tooltip title="Open Uploaded Offer Letter" placement="top">
                                    <span
                                        className="f-14 fw-bold clr-dark-blue"
                                        style={{
                                            maxWidth: "100%",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {/* {offerUploadedData?.offerFileName} */}
                                        Offer Letter uploaded successfully
                                    </span>
                                </Tooltip>
                            </a>
                            <Tooltip title="Delete Uploaded Offer Letter" placement="top">
                                <IconButton
                                    onClick={(e) => handleDeleteUploadedLetter(e, "Offer")}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        color="secondary"
                                        style={{ fontSize: 15 }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} className="Width-100 mt-1">
                            <TextField
                                style={{ display: "none" }}
                            // id="fileUploadMaster"
                            //variant="filled"
                            // label={
                            //   "Upload " +
                            //   (props?.inputDataToDHL?.letterTypeObj?.name
                            //     ? props?.inputDataToDHL?.letterTypeObj?.name
                            //     : "File")
                            // }
                            //label="Upload Offer Letter"
                            // fullWidth
                            // type="file"
                            //disabled={!props?.inputDataToDHL?.letterType}
                            /* InputProps={{
                                 endAdornment: (
                                     <InputAdornment position="end">
                                         {getUploadAddornmentOffer()}
                                     </InputAdornment>
                                 ),
                             }}*/
                            //key={inputFileValue}
                            //InputLabelProps={{ shrink: true }}
                            //onChange={handleFileChange}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} className="Width-100 d-flex jc-center mt-1">
                        <a
                            className="mt-1"
                            href={documentUploadedData?.documentFileUrl}
                            target="_blank"
                        >
                            <Button
                                className="ns-btn-fit-content mt-075 Width-fit Height-fit"
                                disabled={!storedInputDataDHL?.letterType > 0}
                                // title={
                                //   "Preview " +
                                //   (props?.inputDataToDHL?.letterTypeObj?.name
                                //     ? props?.inputDataToDHL?.letterTypeObj?.name
                                //     : "")
                                // }
                                title="View Additional Document"

                            >
                                View Additional Document
                            </Button>
                        </a>
                    </Grid>
                    {documentUploadedData?.documentUploadId > 0 ? (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 d-flex jc-center mt-1"
                        >
                            <a
                                className="mt-1"
                                href={documentUploadedData?.documentFileUrl}
                                target="_blank"
                            >
                                <Tooltip
                                    title="Open Uploaded Additional Document"
                                    placement="top"
                                >
                                    <span
                                        className="f-14 fw-bold clr-dark-blue"
                                        style={{
                                            maxWidth: "100%",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {/* {compenstionUploadedData?.compensationFileName} */}
                                        Document uploaded successfully
                                    </span>

                                </Tooltip>
                            </a>
                            <Tooltip
                                title="Delete Uploaded Additional Document"
                                placement="top"
                            >
                                <IconButton
                                    onClick={(e) => handleDeleteUploadedLetter(e, "Document")}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        color="secondary"
                                        style={{ fontSize: 15 }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="Width-100 mt-1 d-flex jc center"
                        >
                            <TextField
                                id="fileUploadMaster"
                                variant="filled"
                                // label={
                                //   "Upload " +
                                //   (props?.inputDataToDHL?.letterTypeObj?.name
                                //     ? props?.inputDataToDHL?.letterTypeObj?.name
                                //     : "File")
                                // }
                                label="Upload Additional Document"
                                fullWidth
                                type="file"
                                disabled={!storedInputDataDHL?.letterType > 0}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {getUploadAddornmentDocument()}

                                        </InputAdornment>
                                    ),
                                }}

                                key={inputFileValue}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleFileTypeChange}

                            />



                        </Grid>
                    )}
                    <Grid
                        container
                        spacing={1}
                        className="d-flex jc-space-around fd-column mt-1"
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="d-flex jc-center Width-100 mt-1"
                        >
                            {storedInputDataDHL?.digiLetterStatusId === 8 ? (
                                <Button
                                    className="ns-btn-fit-content mt-075 ml-2"
                                    disabled={!storedInputDataDHL?.letterType > 0}
                                    title="Recall Offer Letter"
                                    onClick={(e) => handleRecallOfferLetter(e)}
                                >
                                    Recall Offer Letter
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Grid>

                    </Grid>
                    {confirmDialogComponenet}
                </Grid>
            )}
        </>
    );
}
