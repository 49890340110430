import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDHLAction } from "../../Redux/Actions/actions";
import axios from "axios";

export default function JobDetailsDHL(props) {
  //fetch Employee Type
    const [itemsEmployeeType, setItemsEmployeeType] = useState([]);
    const dispatch = useDispatch();
    const storedInputDataDHL = useSelector(state => state.inputDataDHLReducer.inputDataDHL);
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }

    let encryptedId = getURLParameter("id");
    let [dialogPropsALert, setDialogPropsALert] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    let [dialogPropsConfirm, setDialogPropsConfirm] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    const handleDialogOpenAlert = (type, title, content) => {
        setDialogPropsALert({
            ...dialogPropsALert,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };

    let [loaderProps, setLoaderProps] = useState({
        isEnable: false,
        loaderText: "",
    });
    const handleLoader = (isEnable, text) => {
        setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
    };
    let [inputDataToDHL, setInputDataToDHL] = useState({
        applicantId: getURLParameter("id"),
        userIdencrypted: props.userId,
        firstName: "",
        lastName: "",
        email: "",
        areObj: {},
        taname: "",
        taemail: "",
        address: "",
        dateOfBirth: null,
        mprnumber: "",
        referenceNo: "",
        letterType: 0,
        letterTypeLabel: "",
        letterTypeObj: {},
        letterTemplateType: 0,
        letterTemplateTypeLabel: "",
        letterTemplateTypeObj: {},
        noOfSignatories: 0,
        noOfSignatoriesLabel: "",
        noOfSignatoriesObj: {},
        signatoryDetails: [],
        employeeType: 0,
        employeeTypeLabel: "",
        employeeTypeObj: {},
        jobLocation: 0,
        jobLocationLabel: "",
        jobLocationObj: {},
        jobTitle: 0,
        jobTitleLabel: "",
        jobTitleObj: {},
        businessTitle: "",
        businessUnit: "",
        grip: 0,
        gripLabel: "",
        gripCode: "",
        gripObj: {},
        staff: 0,
        staffLabel: "",
        staffObj: {},
        compensationGrade: 0,
        compensationGradeLabel: "",
        compensationGradeObj: {},
        compensationGradeProfile: 0,
        compensationGradeProfileLabel: "",
        compensationGradeProfileObj: {},
        payPlan: 1,
        payPlanLabel: "Salary Plan",
        payPlanObj: { id: 1, name: "Salary Plan" },
        performencePayPlan: 0,
        performencePayPlanLabel: "",
        performencePayPlanObj: {},
        salaryAmount: 0,
        performencePay: 0,
        targetPay: 0,
        allowancePlan: "",
        allowancePlanLabel: "",
        allowancePlanObj: [],
        allowanceAmountObj: [],
        bfp: 0,
        bp: 0,
        bpa: 0,
        hra: 0,
        totalRetirals: 0,
        pfContri: 0,
        gratuityContri: 0,
        negotiationLetterType: 0,
        negotiationLetterTypeLabel: "",
        negotiationLetterTypeObj: {},
        compensationLetterUploadId: 0,
        isCompensationLetterUploaded: false,
        isNegotiationSent: false,
        digiLetterWorkflowId: 0,
        isCompensationAccepted: true,
        isCompensationRejected: false,
        digiLetterStatusId: 0,
        isOfferAccepted: false,
        isOfferRejected: false,
        offerLetterUploadId: 0,
        isOfferLetteruploaded: false,
        siemensLogo: "../assets/images/sie-logo-petrol-rgb.png",
    });

  async function getEmployeeTypes() {
    const token = props.token;
    const response = await fetch("HRDetails/GetEmployeeTypes", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemEmployeeTypes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsEmployeeType(jsonItems);
  }
  //fetch Job Title
  const [itemsJobTitle, setItemsJobTitle] = useState([]);
  async function getJobTitle() {
    const token = props.token;
    const response = await fetch("HRDetails/GetJobTitle", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsJobTitle(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsJobTitle(jsonItems);
  }
  //fetch Grip Levels
  const [itemGripLevels, setItemGripLevels] = useState([]);
  async function getGripLevels() {
    const token = props.token;
    const response = await fetch("HRDetails/GetGripLevels", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemGripLevels(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemGripLevels(jsonItems);
  }
  //fetch Staff Spefications
  const [itemStaffSpefications, setItemStaffSpefications] = useState([]);
  async function getStaffSpefications() {
    const token = props.token;
    const response = await fetch("HRDetails/GetStaffSpecifications", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemStaffSpefications(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemStaffSpefications(jsonItems);
  }
  //fetch Location Code
  const [itemsLocationCode, setItemsLocationCode] = useState([]);
  async function getLocationCode() {
    const token = props.token;
    const response = await fetch("HRDetails/GetAllLocationCode", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsLocationCode(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsLocationCode(jsonItems);
    }
    const [itemsNoOfSignatories] = useState([
        { value: 0, label: "None" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
    ]);
    const handleChange = (e) => {
        let value = e.target.value;
        let inputUpdatedDHL = {};
        switch (e.target.name) {
            case "noOfSignatoriesLabel":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [e.target.name]: itemsNoOfSignatories.find((x) => x.value === value)
                //         ?.label,
                //     noOfSignatoriesValue: parseInt(value),
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [e.target.name]: itemsNoOfSignatories.find((x) => x.value === value)
                        ?.label,
                    noOfSignatoriesValue: parseInt(value),
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "targetPay":
                value = parseFloat(e.target.value);
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "joiningBonus":
                value = parseFloat(e.target.value);
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "recoveryDuration":
                value = e.target.value > 0 ? parseInt(e.target.value) : 0;
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "businessTitle":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "businessUnit":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "gripCode":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };

    function setDigiLetterDataState(data) {
        

        let inputUpdatedDHL = {
            ...inputDataToDHL,
            digiLetterDetailsId: data?.digiLetterDetailsId,
            applicantId: data?.applicantIdEncrypted,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            joiningDate: data?.joiningDate,
            companyId: data?.companyId,
            areObj: {
                id: data?.areObj?.companyId,
                areCode: data?.areObj?.companyArename,
                areName: data?.areObj?.companyAredescription,
            },
            mprnumber: data?.mprnumber,
            referenceNo: data?.referenceNo,
            division: data?.division,
            taname: data?.taname,
            taemail: data?.taemail,
            address: data?.address,
            dateOfBirth: data?.dateOfBirth,
            letterType: data?.letterType,
            letterTypeDetails: data?.letterTypeObj,
            letterTypeLabel: data?.letterTypeObj
                ? data?.letterTypeObj?.letterTypeName
                : "",
            letterTypeObj: {
                id: data?.letterTypeObj?.letterTypeId,
                name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
            },
            noOfSignatories: data?.noOfSignatories,
            noOfSignatoriesObj: {
                id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                name:
                    data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            },
            noOfSignatoriesLabel:
                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            signatoryDetails: data?.signatoryDetails,
            employeeType: data?.employeeType,
            employeeTypeLabel: data?.employeeTypeObj
                ? data?.employeeTypeObj?.name
                : "",
            employeeTypeObj: data?.employeeTypeObj,
            jobLocation: data?.jobLocation,
            jobLocationLabel: data?.jobLocationObj
                ? data?.jobLocationObj?.locationCode +
                " - " +
                data?.jobLocationObj?.cityName +
                " - " +
                data?.jobLocationObj?.streetName
                : "",
            jobLocationObj: data?.jobLocationObj
                ? {
                    id: data?.jobLocationObj?.locationCodeId,
                    name:
                        data?.jobLocationObj?.locationCode +
                        " - " +
                        data?.jobLocationObj?.cityName +
                        " - " +
                        data?.jobLocationObj?.streetName,
                }
                : { id: 0, name: "" },
            locationObj: data?.jobLocationObj,
            jobTitle: data?.jobTitle,
            jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
            businessTitle: data?.businessTitle
                ? data?.businessTitle
                : data?.jobTitleObj
                    ? data?.jobTitleObj?.name
                    : "",
            jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
            gripLabel: data?.gripObj ? data?.gripObj?.name : "",
            grip: data?.grip,
            businessUnit: data?.businessUnit,
            gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
            gripCode: data?.gripCode,
            staff: data?.staff,
            staffLabel: data?.staffObj ? data?.staffObj?.name : "",
            staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
            compensationGrade: data?.compensationGrade,
            compensationGradeLabel: data?.compensationGradeObj
                ? data?.compensationGradeObj?.name
                : "",
            compensationGradeObj: data?.compensationGradeObj
                ? data?.compensationGradeObj
                : { id: 0, name: "" },
            compensationGradeProfile: data?.compensationGradeProfile
                ? data?.compensationGradeProfile
                : { id: 0, name: "" },
            compensationGradeProfileLabel: data?.compensationGradeProfileObj
                ? data?.compensationGradeProfileObj?.name
                : "",
            compensationGradeProfileObj: data?.compensationGradeProfileObj,
            payPlan: data?.payPlan,
            payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
            payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
            performencePayPlan: data?.performencePayPlan,
            performencePayPlanLabel: data?.performencePayPlanObj
                ? data?.performencePayPlanObj?.name
                : "",
            performencePayPlanObj: data?.performencePayPlanObj
                ? data?.performencePayPlanObj
                : { id: 0, name: "" },
            salaryAmount: data?.salaryAmount,
            targetPay: data?.targetPay,
            performencePay: data?.performencePay,
            joiningBonus: data?.joiningBonus,
            recoveryDuration: data?.recoveryDuration,
            allowancePlanObj:
                data?.allowancePlanObj?.length > 0
                    ? data?.allowancePlanObj
                    : inputDataToDHL?.allowancePlanObj,
            allowanceAmountObj:
                data?.allowanceAmountObj?.length > 0
                    ? data?.allowanceAmountObj
                    : inputDataToDHL?.allowanceAmountObj,
            compensationLetterUploadId: data?.compensationLetterUploadId,
            isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
            offerLetterUploadId: data?.offerLetterUploadId,
            isOfferLetteruploaded: data?.isOfferLetteruploaded,
            siemensLogo:
                data?.companyId === 1 || data.companyId === 2
                    ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                    : "../assets/images/sie-logo-petrol-rgb.png",
            digiLetterWorkflowId: data?.digiLetterWorkflowId,
            isCompensationAccepted: data?.isCompensationAccepted,
            isCompensationRejected: data?.isCompensationRejected,
            digiLetterStatusId: data?.digiLetterStatusId,
            isOfferAccepted: data?.isOfferAccepted,
            isOfferRejected: data?.isOfferRejected,
            isSuperannuation: data?.isSuperannuation,
        }
        setInputDataToDHL(inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));
    }

    function getDigiLetterDetails(encryptedId) {

        handleLoader(true, "Fetching data....");
        const token = props.token;
        var stat = localStorage.getItem("Status");
        // id = encryptedId.substr(22);
        let id = encryptedId;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    console.log("Inside getDigiLetterDetails", data);
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterDetailsId: data?.digiLetterDetailsId,
                        applicantId: data?.applicantIdEncrypted,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        joiningDate: data?.joiningDate,
                        companyId: data?.companyId,
                        areObj: {
                            id: data?.areObj?.companyId,
                            areCode: data?.areObj?.companyArename,
                            areName: data?.areObj?.companyAredescription,
                        },
                        mprnumber: data?.mprnumber,
                        referenceNo: data?.referenceNo,
                        division: data?.division,
                        taname: data?.taname,
                        taemail: data?.taemail,
                        address: data?.address,
                        dateOfBirth: data?.dateOfBirth,
                        letterType: data?.letterType,
                        letterTypeDetails: data?.letterTypeObj,
                        letterTypeLabel: data?.letterTypeObj
                            ? data?.letterTypeObj?.letterTypeName
                            : "",
                        letterTypeObj: {
                            id: data?.letterTypeObj?.letterTypeId,
                            name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                        },
                        noOfSignatories: data?.noOfSignatories,
                        noOfSignatoriesObj: {
                            id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                            name:
                                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        },
                        noOfSignatoriesLabel:
                            data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        signatoryDetails: data?.signatoryDetails,
                        employeeType: data?.employeeType,
                        employeeTypeLabel: data?.employeeTypeObj
                            ? data?.employeeTypeObj?.name
                            : "",
                        employeeTypeObj: data?.employeeTypeObj,
                        jobLocation: data?.jobLocation,
                        jobLocationLabel: data?.jobLocationObj
                            ? data?.jobLocationObj?.locationCode +
                            " - " +
                            data?.jobLocationObj?.cityName +
                            " - " +
                            data?.jobLocationObj?.streetName
                            : "",
                        jobLocationObj: data?.jobLocationObj
                            ? {
                                id: data?.jobLocationObj?.locationCodeId,
                                name:
                                    data?.jobLocationObj?.locationCode +
                                    " - " +
                                    data?.jobLocationObj?.cityName +
                                    " - " +
                                    data?.jobLocationObj?.streetName,
                            }
                            : { id: 0, name: "" },
                        locationObj: data?.jobLocationObj,
                        jobTitle: data?.jobTitle,
                        jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                        businessTitle: data?.businessTitle
                            ? data?.businessTitle
                            : data?.jobTitleObj
                                ? data?.jobTitleObj?.name
                                : "",
                        jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                        gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                        grip: data?.grip,
                        businessUnit: data?.businessUnit,
                        gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                        gripCode: data?.gripCode,
                        staff: data?.staff,
                        staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                        staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                        compensationGrade: data?.compensationGrade,
                        compensationGradeLabel: data?.compensationGradeObj
                            ? data?.compensationGradeObj?.name
                            : "",
                        compensationGradeObj: data?.compensationGradeObj
                            ? data?.compensationGradeObj
                            : { id: 0, name: "" },
                        compensationGradeProfile: data?.compensationGradeProfile
                            ? data?.compensationGradeProfile
                            : { id: 0, name: "" },
                        compensationGradeProfileLabel: data?.compensationGradeProfileObj
                            ? data?.compensationGradeProfileObj?.name
                            : "",
                        compensationGradeProfileObj: data?.compensationGradeProfileObj,
                        payPlan: data?.payPlan,
                        payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                        payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                        performencePayPlan: data?.performencePayPlan,
                        performencePayPlanLabel: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj?.name
                            : "",
                        performencePayPlanObj: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj
                            : { id: 0, name: "" },
                        salaryAmount: data?.salaryAmount,
                        targetPay: data?.targetPay,
                        performencePay: data?.performencePay,
                        joiningBonus: data?.joiningBonus,
                        recoveryDuration: data?.recoveryDuration,
                        allowancePlanObj:
                            data?.allowancePlanObj?.length > 0
                                ? data?.allowancePlanObj
                                : inputDataToDHL?.allowancePlanObj,
                        allowanceAmountObj:
                            data?.allowanceAmountObj?.length > 0
                                ? data?.allowanceAmountObj
                                : inputDataToDHL?.allowanceAmountObj,
                        compensationLetterUploadId: data?.compensationLetterUploadId,
                        isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                        offerLetterUploadId: data?.offerLetterUploadId,
                        isOfferLetteruploaded: data?.isOfferLetteruploaded,
                        siemensLogo:
                            data?.companyId === 1 || data.companyId === 2
                                ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                                : "../assets/images/sie-logo-petrol-rgb.png",
                        digiLetterWorkflowId: data?.digiLetterWorkflowId,
                        isCompensationAccepted: data?.isCompensationAccepted,
                        isCompensationRejected: data?.isCompensationRejected,
                        digiLetterStatusId: data?.digiLetterStatusId,
                        isOfferAccepted: data?.isOfferAccepted,
                        isOfferRejected: data?.isOfferRejected,
                        isSuperannuation: data?.isSuperannuation,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                    dispatch(inputDataDHLAction(inputUpdatedDHL));

                    setDigiLetterDataState(data);
                    handleLoader(false, "");
                    //alert(stat);
                    if (stat == "Success") {
                        handleDialogOpenAlert(
                            "Success",
                            "Letter Uploaded Successfully",
                            "Letter Uploaded Successfully"
                        );
                        //alert("Success");
                        localStorage.removeItem("Status");
                    }
                    else if (stat == "Failed") {
                        handleDialogOpenAlert(
                            "Failure",
                            "Letter Uploaded Failed",
                            "Letter Upload Failed"
                        );
                    }
                    console.log("Inside 1 getDigiLetterDetails", data);
                    handleLoader(false, "");
                }
                console.log("Inside 2 getDigiLetterDetails", result);
                handleLoader(false, "");
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
            });
    }
    const handleAutoCompleteChange = (e, newValue) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        switch (elemName) {
            case "employeeType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobLocation":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobTitle":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                //     businessTitle: newValue ? newValue.name : "",
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "grip":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "staff":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                localStorage.setItem("showCompensation1", "true");
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
        
        console.log("Handle auto complete change", inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));
            
    };
    const handleAutoCompleteTextChange = (e, newText) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        switch (elemName) {
            case "employeeType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobLocation":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "jobTitle":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "grip":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "staff":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
        
        console.log("Handle auto complete Text change", inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));

    };
    useEffect(() => {
        console.log("Job Detaoils DHL useeffect", storedInputDataDHL);
    if (props) {
      getEmployeeTypes();
      getLocationCode();
      getGripLevels();
      getStaffSpefications();
      getJobTitle();
    }
  }, [props.token]);
    useEffect(() => {
        if (props.token) {
            if (encryptedId) {
                getDigiLetterDetails(encryptedId);
            }
        }
    }, [encryptedId, props.token]);
  return (
    <>
      <Grid container spacing={1} className="d-flex jc-space-around fd-row">
        <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
          <h5 className="clr-dark-blue">Job Details</h5>
        </Grid>

        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <Autocomplete
            disableClearable={true}
            disabled
            className="lightInputFields hover-lightblue mt-1"
                      id="employeeType"
                      value={storedInputDataDHL.employeeTypeObj}
                      inputValue={storedInputDataDHL.employeeTypeLabel}
            options={itemsEmployeeType}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employee Type"
                name="employeeType"
                variant="filled"
                required
                error={
                    storedInputDataDHL.employeeTypeErrorText &&
                    storedInputDataDHL.employeeTypeErrorText.length > 0
                }
                    helperText={storedInputDataDHL.employeeTypeErrorText}
                style={{ color: "#f00" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="d-flex Width-100">
          <Autocomplete
            disableClearable={true}
            className="lightInputFields hover-lightblue mt-1"
            id="jobLocation"
                      value={storedInputDataDHL.jobLocationObj}
                      inputValue={storedInputDataDHL.jobLocationLabel}
            options={itemsLocationCode}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job Location"
                name="jobLocation"
                variant="filled"
                required
                error={
                    storedInputDataDHL.employeeTypeErrorText &&
                    storedInputDataDHL.employeeTypeErrorText.length > 0
                }
                    helperText={storedInputDataDHL.employeeTypeErrorText}
                style={{ color: "#f00" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <Autocomplete
            disableClearable={true}
            className="lightInputFields hover-lightblue mt-1"
            id="jobTitle"
                      value={storedInputDataDHL.jobTitleObj}
                      inputValue={storedInputDataDHL.jobTitleLabel}
            options={itemsJobTitle}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grip Title"
                name="jobTitle"
                variant="filled"
                required
                error={
                    storedInputDataDHL.jobTitleErrorText &&
                    storedInputDataDHL.jobTitleErrorText.length > 0
                }
                    helperText={storedInputDataDHL.jobTitleErrorText}
                style={{ color: "#f00" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <TextField
            className="mt-1 lightInputFields"
            id="businessTitle"
            label="Business Title"
            variant="filled"
            fullWidth
            name="businessTitle"
            required
                      value={storedInputDataDHL.businessTitle}
            onChange={handleChange}
            inputProps={{ maxLength: 150 }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {/*<Grid item xs={12} sm={3} className="d-flex Width-100">*/}
        {/*  <TextField*/}
        {/*    className="mt-1 lightInputFields"*/}
        {/*    id="businessUnit"*/}
        {/*    label="Business Unit"*/}
        {/*    variant="filled"*/}
        {/*    fullWidth*/}
        {/*    name="businessUnit"*/}
        {/*    required*/}
        {/*    value={props.inputDataToDHL.businessUnit}*/}
        {/*    onChange={handleChange}*/}
        {/*    inputProps={{ maxLength: 450 }}*/}
        {/*    InputLabelProps={{ shrink: true }}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <Autocomplete
            disableClearable={true}
            className="lightInputFields hover-lightblue mt-1"
            id="grip"
                      value={storedInputDataDHL.gripObj}
                      inputValue={storedInputDataDHL.gripLabel}
            options={itemGripLevels}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grip Level"
                name="grip"
                variant="filled"
                required
                error={
                    storedInputDataDHL.gripErrorText &&
                    storedInputDataDHL.gripErrorText.length > 0
                }
                    helperText={storedInputDataDHL.gripErrorText}
                style={{ color: "#f00" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <TextField
            className="mt-1 lightInputFields"
            id="gripCode"
            label="Grip Code"
            variant="filled"
            fullWidth
            name="gripCode"
            required
                      value={storedInputDataDHL.gripCode}
            onChange={handleChange}
            inputProps={{ maxLength: 80 }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <Autocomplete
            disableClearable={true}
            className="lightInputFields hover-lightblue mt-1"
            id="staff"
                      value={storedInputDataDHL.staffObj}
                      inputValue={storedInputDataDHL.staffLabel}
            options={itemStaffSpefications}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Staff Specification"
                name="staff"
                variant="filled"
                required
                error={
                    storedInputDataDHL.staffErrorText &&
                    storedInputDataDHL.staffErrorText.length > 0
                }
                    helperText={storedInputDataDHL.staffErrorText}
                style={{ color: "#f00" }}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
