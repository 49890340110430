import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import SHPL from './SHPL'
import STSPL from './STSPL'
import Gratuity from './Gratuity'
import SLLIC from './SLLIC'
import Superannuation from './Superannuation'
import FORM2 from './FORM2'
import EmployeeConsentForm from './EmployeeConsentForm'

export default function DownloadDocuments(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];

            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
           
        }
        return value;
    }
    var id = getURLParameter('id');
    const [areId, setAREId] = useState(null);
    const [areCode, setARECode] = useState(null);
    const [areName, setAREName] = useState(null);
    const [areNameWD, setARENameWD] = useState(null);
    const [gid, setGid] = useState(null);

    function SetAREStateAsPerFetched(areData) {
        setAREId(areData.companyId);
        setARECode(areData.companyArename);
        setAREName(areData.companyAredescription);
    }
    async function getEmployeeAREId(id) {
        const token = props.token;
        await fetch("ApplicationForm/GetApplicantAREDetails/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetAREStateAsPerFetched(data);
            })
            .catch(error => console.log("Error:", error));
    }
    async function getEmployeeGID(id) {
        const token = props.token;
        await fetch("HRDetails/GetCandidateGID/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.text())
            .then(data => {
                setGid(data);
            })
            .catch(error => console.log("Error:", error));
    }
    useEffect(() => {
        if (props.token) {
            if (id != null && id != "" && id != undefined) {
                getEmployeeAREId(id);
                getEmployeeGID(id)
            }
        }
    }, [id, props.token]);

    return (
        <React.Fragment>
            {
                (areId != null && areId != '' && areId != undefined)
                    ?
                    <React.Fragment>
                        {
                            (areId != 1 && areId != 2)
                                ?
                                <STSPL AREName={areName} token={props.token} gid={gid} />
                                :
                                [
                                <SHPL AREName={areName} token={props.token} gid={gid} />,
                                    <EmployeeConsentForm AREName={areName} token={props.token} gid={gid} />
                                ]
                                
                        }
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
            }
            <SLLIC AREID={areId} AREName={areName} token={props.token} gid={gid}/>
            <Superannuation AREID={areId} AREName={areName} token={props.token} gid={gid}/>
            <FORM2 AREID={areId} AREName={areName} token={props.token} gid={gid}/>
            <Gratuity AREID={areId} AREName={areName} token={props.token} gid={gid}/>
        </React.Fragment >
    );

}