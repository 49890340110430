import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function Gratuity(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
           
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [gratuity, setGratuity] = useState([]);
    const gratuityTemp = [];

    const [personalData, setPersonalData] = useState([]);
    const personalDataTemp = [];

    const [communicationData, setCommunicationData] = useState([]);
    const communicationDataTemp = [];

    const [departmentData, setDepartmentData] = useState([]);
    const departmentDataTemp = [];

    function SetStateAsPerFetched(data) {
        //Gratuity Data
        for (var i = 0; i < data.gratuityData.length; i++) {
            gratuityTemp.push({
                'firstNameNominee': data.gratuityData[i].firstNameNominee, 'middleNameNominee': data.gratuityData[i].middleNameNominee ? data.gratuityData[i].middleNameNominee : "",
                'lastNameNominee': data.gratuityData[i].lastNameNominee, 'dob': data.gratuityData[i].dobStr,
                'relationWithEmployee': data.gratuityData[i].relationWithEmployee, 'address': data.gratuityData[i].address,
                'proportionShared': data.gratuityData[i].proportionShared, 'gaurdianName': data.gratuityData[i].gaurdianName ? data.gratuityData[i].gaurdianName : "",
                'gaurdianAddress': data.gratuityData[i].gaurdianAddress ? data.gratuityData[i].gaurdianAddress : "", 'validationOfMinorYesOrNo': data.gratuityData[i].validationOfMinorYesOrNo,
                'count': i + 1

            });
        }
        setGratuity(gratuityTemp);

        //Personal Data
        personalDataTemp.push({
            'firstName': data.personalData.firstName, 'middleName': data.personalData.middleName ? data.personalData.middleName : "",
            'lastName': data.personalData.lastName, 'registrationNo': data.personalData.registrationNo ? data.personalData.registrationNo : "",
            'gender': data.personalData.genders[0].name, 'religion': data.personalData.religions[0].name, 'maritalStatus': data.personalData.maritalStatuses[0].name,
            'fatherName': data.personalData.fatherName ? data.personalData.fatherName : (data.personalData.spouseName ? data.personalData.spouseName : "")

        });

        setPersonalData(personalDataTemp);

        //Communication Data
        communicationDataTemp.push({
            'premanentAddress1': data.communicationData.premanentAddress1, 'permanentAddress2': data.communicationData.permanentAddress2,
            'permanentAddress3': data.communicationData.permanentAddress3 ? data.communicationData.permanentAddress3 : ""

        });

        setCommunicationData(communicationDataTemp);

        //Department and Sector Data
        departmentDataTemp.push({
            'department': data.department ? data.department : "", 'sector': data.sector ? data.sector : "",
            'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr

        });

        setDepartmentData(departmentDataTemp);

    }


    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);

            }
        }

    }, [encryptedId, props.token]);


    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">
            <table className="width-100">
                <thead>
                    <tr className="d-flex">
                        <th>
                            {
                                (props.AREID == 1 || props.AREID == 2)
                                    ?
                                    <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                                    :
                                    <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="d-flex jc-center">
                        <td>
                            <b className="f-14 fw-bold">PAYMENT OF GRATUITY (CENTRAL) RULES- 1972</b>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-center">
                            <strong className="f-12">(See sub-rule(1) of rule 6)</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex text-center jc-space-bt">
                                <span className="bdr-black width-10 f-12 h-max p-1e">
                                    TO BE FILLED IN TRIPLICATE
                                    </span>
                                <p className="mt-1 mr-10e f-14 fw-bold text-uppercase text-underline">FORM-F<br />Nomination</p>
                                <p></p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-flex-end f-14">
                                <span className="mb-0">
                                    Office/Works {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.placeOfWork}<br />Office</span>)}
                                    <br />
                                    Roll / Token No: <span className="bdr-light-grey">{props.gid}</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-flex-start f-12">
                                <span className="mb-0 mr-2">To,<br />
                            The Trustees,<br />
                            ………………………………….<br />
                            …………………………………………..<br />
                            ……………………………………………..</span>
                            </div>
                        </td>
                    </tr>
                    <tr className="f-12">
                        <td>
                            <div className="d-flex mt-1 ml-3">
                                <span className="mt-1" >

                                    <table><tbody><tr className="d-flex jc-space-bt text-center">
                                        <td>1. MR/MRS/MISS</td>
                                        <td>{personalData.map(personalDataNew => <span className="bdr-bottom-black">{" " + personalDataNew.lastName}</span>)} <br /> (SURNAME)</td>
                                        <td>{personalData.map(personalDataNew => <span className="bdr-bottom-black">{" " + personalDataNew.firstName}</span>)} <br /> (OWN' FIRST NAME)</td>
                                        <td>{personalData.map(personalDataNew => <span className="bdr-bottom-black">{" " + personalDataNew.fatherName}</span>)} <br /> (FATHER'S/HUSBAND'S NAME)</td>
                                    </tr></tbody></table>
                                whose particulars are given in the statement below. hereby nominate the person(s)
                                mentioned below to
                                recieve the, Gratuity payable after my death as also the Gratuity standing to my
                                credit in the event of my
                                death before that amount has become payable, or having become payable has not been
                                paid and direct that
                                the said amount of Gratuity shall be paid in proportion indicated against the
                            name(s) of the nominee(s).</span>
                            </div>
                            <div className="d-flex mt-1 ml-3">
                                <span className=" mt-1">2. I hereby certify that the person(s) mentioned is/are
                                member(s) of
                                my family
                                within the meaning of clause
                            (h) of section(2)* of the Payment of Gratuity Act, 1972.</span>
                            </div>
                            <div className="d-flex mt-1 ml-3">
                                <span className=" mt-1">3. I hereby declare that I have no family within the meaning of
                                clause (h) of
                            section(2) * of the said Act.</span>
                            </div>
                            <div className="d-flex mt-1 ml-3">
                                <span className=" mt-1">4. (a) My father/mother/parents is/are not dependent on me
                            (b) My husband's father/mother/parents is/are not dependent on my husband.</span>
                            </div>
                            <div className="d-flex mt-1 ml-3">
                                <span className=" mt-1">5. I have excluded my husband from my family by a notice dated
                                the
                                /exclusiondate/
                                to the controlling
                            authority in terms of the provision to clause(h) of section2 of the said Act.</span>
                            </div>
                            <div className="d-flex mt-1 ml-3">
                                <span className=" mt-1">6. Nomination made herein invalidates my previous
                            nomination</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-center">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-center">
                                <p className="ml-2 f-14 fw-bold">NOMINEE(S)</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table className="bdr-black f-12 border-collapse width-100">
                                <tbody>
                                    <tr>
                                        <td className="bdr-black" >Sr. No</td>
                                        <td className="bdr-black" >Name of the Nominee(s)</td>
                                        <td className="bdr-black" >Full Address of the Nominee(s)</td>
                                        <td className="bdr-black" >Date of Birth of Nominee</td>
                                        <td className="bdr-black" >Relationship with the Employee</td>
                                        <td className="bdr-black" >Proportion by
                                        Which the Gratuity
                                    will be shared</td>
                                        <td className="bdr-black" >If the Nominee is minor, name, relationship and address
                                        of the gaurdian who may receive the
                                    amount during the minority of the nominee</td>
                                    </tr>
                                    <tr>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{gratuityNew.count}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{gratuityNew.firstNameNominee + " " + gratuityNew.middleNameNominee + " " + gratuityNew.lastNameNominee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{gratuityNew.address}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{gratuityNew.dob}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{gratuityNew.relationWithEmployee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{gratuityNew.proportionShared}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {gratuity.map(gratuityNew => <div><br />{"Guardian Name: " + gratuityNew.gaurdianName + " , Guardian Address: " + gratuityNew.gaurdianAddress}<br /><br /></div>)}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr className="l-40 f-12" >
                        <td>
                            * Sec. 2(h) 'Family'
                        </td>
                    </tr>
                    <tr className="l-40 f-12">
                        <td>
                            i) In the case of a male employee, himself; his wife, his children, whether married or unmarried, his
                            dependent parents and the window and children of his predeceased son, if any.
                        </td>
                    </tr>
                    <tr className="f-12">
                        <td>
                            ii) In the case of a female employee herself, her husband, her children, whether married or unmarried.
                            Her
                            dependent parents and the dependent parents of her husband and the widow and children of her predeceased
                            son, if any. (A
                            female employee can exclude, the husband and his dependent parents by a notice in writing)
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="d-flex jc-center mb-0 pagebreak">
                {/*<h4>STATEMENT</h4>*/}
                <p className="ml-2 f-14 fw-bold">STATEMENT</p>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">1. Name of employee in full : {personalData.map(personalDataNew => <span className="text-underline">{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">2. Sex : {personalData.map(personalDataNew => <span className="text-underline">{personalDataNew.gender}</span>)}</span>
                <span className="f-12 ml-3 mt-05"> 3. Religion : {personalData.map(personalDataNew => <span className="text-underline">{personalDataNew.religion}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">4. Whether unmarried/married/widow/widower : {personalData.map(personalDataNew => <span className="text-underline">{personalDataNew.maritalStatus}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">5. Department/Branch/Section where employed : {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.department}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                {/* <span className="f-12 mt-05">6. Post held with Token or Roll No. : {personalData.map(personalDataNew => <span className="text-underline">{personalDataNew.registrationNo}</span>)}</span> */}
                <span className="f-12 mt-05">6. Post held with Token or GID : {<span className="text-underline">{props.gid}</span>}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">7. Date of appointment : {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.joiningDate}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">8. Permanent Address : {communicationData.map(communicationDataNew => <span className="text-underline">{communicationDataNew.premanentAddress1 + ", " + communicationDataNew.permanentAddress2 + ", " + communicationDataNew.permanentAddress3}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">Place: {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.placeOfWork}</span>)}</span>
            </div>
            <div className="d-flex mt-05 ml-3">
                <span className="f-12 mt-05">Date: {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.joiningDate}</span>)}</span>
            </div>
            <table className="width-100">
                <tbody>
                    <tr>
                        <td>
                            <div className="d-flex jc-flex-end mt-1 mr-3 f-12">
                                <span className="mt-1 mr-3">Signature/ Thumb impress of employee</span>
                            </div>
                        </td>
                    </tr>
                    <tr className="l-40 f-12">
                        <td>
                            Nomination signed/ thumb impressed before me
                        </td>
                    </tr>
                    <tr className="l-40 f-12">
                        <td>
                            Name in full and full address of witnesses: Signature of witnesses:
                        </td>
                    </tr>
                    <tr className="l-40 f-12">
                        <td>
                            1. Name : <br />

                            Address : <br />

                            2. Name : <br />

                            Address :

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-center mt-1 mb-1">
                                <p className="m-0 bdr-header f-12 fw-bold" >CERTIFICATE BY THE EMPLOYER</p>
                            </div>
                        </td>
                    </tr>
                    <tr className="l-10 f-12">
                        <td>
                            Certified that the particulars of the above nomination have been verified and recorded in this
                            Establishment.
                </td>
                    </tr>
                    <tr className="l-40 f-12">
                        <td>
                            Employer's Reference No. if any
                        </td>
                    </tr>
                    <tr className="d-flex jc-flex-end f-12">
                        <td>
                            Signature of the Employer/ Office authorized<br />
                            <br />

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-space-bt mt-1 ml-3 f-12">
                                <span className=" mt-1">Date: </span>
                                <span className=" mt-1 mr-5">Establishment of rubber stamp thereof</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-center mt-1 f-12 fw-bold">
                                <h4 className="m-0 bdr-header">ACKNOWLEDGMENT BY THE EMPLOYEE</h4>
                            </div>
                        </td>
                    </tr>
                    <tr className="l-40 f-12">
                        <td>
                            Received the duplicate copy of nomination in Form 'F' filed by me and duly certified by the employer
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex jc-space-bt mt-1 ml-3 f-12">
                                <span className=" mt-1">Date: {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                                <span className=" mt-1 mr-3">Signature of the Employee</span>
                            </div>
                        </td>
                    </tr>
                    <tr className="l-30 f-12">
                        <td>
                            Note: Strike out the words/ paragraph not applicable
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    );

}