import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { duration } from "@material-ui/core";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function ManageProbationPeriod(props) {
  const [probationDetailsId, setProbationDetailsId] = useState("");
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [probationType, setProbationType] = useState("");
  const [probationTypeObj, setProbationTypeObj] = useState("");
  const [probationTypeText, setProbationTypeText] = useState("");
  const [probationReason, setProbationReason] = useState("");
  const [probationReasonObj, setProbationReasonObj] = useState("");
  const [probationReasonText, setProbationReasonText] = useState("");
  const [probationStartDate, setProbationStartDate] = useState(null);
  const [probationEndDate, setProbationEndDate] = useState(null);
  const [probationDuration, setProbationDuration] = useState("");
  const [probationUnit, setProbationUnit] = useState("");

  //Error States and Msg
  const [errorProbationType, setErrorProbationType] = useState(false);
  const [errorTextProbationType, setErrorTextProbationType] = useState("");
  const [errorProbationReason, setErrorProbationReason] = useState(false);
  const [errorTextProbationReason, setErrorTextProbationReason] = useState("");
  const [errorProbationStartDate, setErrorProbationStartDate] = useState(false);
  const [errorTextProbationStartDate, setErrorTextProbationStartDate] =
    useState("");
  const [errorProbationDuration, setErrorProbationDuration] = useState(false);
  const [errorTextProbationDuration, setErrorTextProbationDuration] =
    useState("");

  //Alerts
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  function AllowMonthNumbers(value) {
    var exp = /^([0-9]|1[012])$/g;
    if (value == 0) {
      return value;
    } else {
      if (exp.test(value)) {
        return value;
      } else {
        return "";
      }
    }
  }

  function ReplaceChars(value) {
    return value.replace(/\D/g, "");
  }

  const handleChangeProbationType = (event, newValue) => {
    setProbationType(newValue ? newValue.id : 0);
    setProbationTypeObj(newValue);
  };
  const handleChangeProbationTypeInput = (event, newValueInput) => {
    setProbationTypeText(newValueInput);
  };

  const handleChangeProbationReason = (event, newValue) => {
    setProbationReason(newValue ? newValue.id : 0);
    setProbationReasonObj(newValue);
  };
  const handleChangeProbationReasonInput = (event, newValueInput) => {
    setProbationReasonText(newValueInput);
  };

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (
      characterCode === "Backspace" ||
      characterCode === "ArrowUp" ||
      characterCode === "ArrowDown" ||
      characterCode === "ArrowRight" ||
      characterCode === "ArrowLeft"
    )
      return;
    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  function addMonths(date, months) {
    let newDate = new Date(date);
    //var day = newDate.getDate();
    newDate.setDate(newDate.getDate() - 1);
    newDate.setMonth(newDate.getMonth() + months);
    //if (newDate.getDate() != day)
    //    newDate.setDate(0);
    return newDate;
  }

  function monthDiff(dateFrom, dateTo) {
    let dateStart = new Date(dateFrom);
    let dateEnd = new Date(dateTo);

    return (
      dateEnd.getMonth() -
      dateStart.getMonth() +
      12 * (dateEnd.getFullYear() - dateStart.getFullYear())
    );
  }

  function dateChangeEvent(dateValue, label) {
    if (label === "Probation Start date") {
      setProbationEndDate(null);
      setProbationStartDate(dateValue);
      var dateEnd = dateValue;

      if (dateEnd != undefined && dateEnd != null && dateEnd != "") {
        var resultEndDate = addMonths(dateEnd, 6).toString();
        setProbationEndDate(new Date(resultEndDate));
        setProbationDuration(6);
      }
    } else if (label === "Probation End date") {
      setProbationEndDate(dateValue);
      setProbationDuration("");

      if (
        probationStartDate != undefined &&
        probationStartDate != null &&
        probationStartDate != "" &&
        dateValue != undefined &&
        dateValue != null &&
        dateValue != ""
      ) {
        var probDuration = monthDiff(probationStartDate, dateValue);
        setProbationDuration(probDuration);
      }
    }
  }

  var formData = {
    ApplicantId: props.id,
    UserId: props.userId,
    ProbationTypeId: probationType,
    ProbationReasonId: probationReason,
    ProbationStartDate: probationStartDate,
    ProbationEndDate: probationEndDate,
    ProbationDuration: probationDuration,
    ProbationUnits: probationUnit,
  };

  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false;
    if (data.ProbationTypeId == null || data.ProbationTypeId == "") {
      setErrorProbationType(true);
      setErrorTextProbationType("Mandatory field");
      a = true;
    } else {
      setErrorProbationType(false);
      setErrorTextProbationType("");
      a = false;
    }
    if (data.ProbationReasonId == null || data.ProbationReasonId == "") {
      setErrorProbationReason(true);
      setErrorTextProbationReason("Mandatory field");
      b = true;
    } else {
      setErrorProbationReason(false);
      setErrorTextProbationReason("");
      b = false;
    }
    if (data.ProbationStartDate == null || data.ProbationStartDate == "") {
      setErrorProbationStartDate(true);
      setErrorTextProbationStartDate("Mandatory field");
      c = true;
    } else {
      setErrorProbationStartDate(false);
      setErrorTextProbationStartDate("");
      c = false;
    }

    if (
      data.ProbationDuration != null &&
      data.ProbationDuration != undefined &&
      data.ProbationDuration != "" &&
      data.ProbationDuration > 12
    ) {
      setErrorProbationDuration(true);
      setErrorTextProbationDuration(
        "Probation Duration should be less than 12"
      );
      d = true;
    } else {
      setErrorProbationDuration(false);
      setErrorTextProbationDuration("");
      d = false;
    }

    if (a || b || c || d) {
      return false;
    } else {
      return true;
    }
  }

  function clearStates() {
    if (props.roleId && props.roleId == 1) {
      setProbationType("");
      setProbationTypeObj("");
      setProbationTypeText("");
      setProbationReason("");
      setProbationReasonObj("");
      setProbationReasonText("");
    }
    setProbationStartDate(null);
    setProbationEndDate(null);
    setProbationDuration("");
    setProbationUnit("");
  }

  const submitHandler = () => {
    setIsLoader(true);
    formData = {
      ApplicantId: props.id,
      UserId: props.userId,
      ProbationTypeId: probationType,
      ProbationReasonId: probationReason,
      ProbationStartDate: probationStartDate,
      ProbationEndDate: probationEndDate,
      ProbationDuration: probationDuration,
      ProbationUnits: probationUnit,
    };
    if (ValidateFormFields(formData)) {
      if (
        probationDetailsId != null &&
        probationDetailsId > 0 &&
        probationDetailsId != undefined
      ) {
        UpdateEmployeeProbationDetails(formData);
      } else {
        SaveEmployeeProbationDetails(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  const clearHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  //Save Employee Probation Details
  function SaveEmployeeProbationDetails(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/SaveProbationPeriodDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Update Employee Probation Details
  function UpdateEmployeeProbationDetails(formData) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/UpdateProbationPeriodDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //Set All States
  function SetStateAsPerFetched(data) {
    setProbationDetailsId(data.probationDetailsId);
    setProbationType(data.probationTypeId ? data.probationTypeId : 1);
    if (data.probationTypeObj[0].id > 0) {
      setProbationTypeObj(data.probationTypeObj[0]);
      setProbationTypeText(data.probationTypeObj[0].name);
    } else {
      setProbationType(1);
      setProbationTypeObj({ id: 1, name: "Hire" });
      setProbationTypeText("Hire");
    }
    setProbationReason(data.probationReasonId ? data.probationReasonId : 1);
    if (data.probationReasonObj[0].id > 0) {
      setProbationReasonObj(data.probationReasonObj[0]);
      setProbationReasonText(data.probationReasonObj[0].name);
    } else {
      setProbationReason(1);
      setProbationReasonObj({ id: 1, name: "Add" });
      setProbationReasonText("Add");
    }
    setProbationUnit(data.probationUnits);
    setProbationStartDate(
      data.probationStartDate ? data.probationStartDate : props.HireDate
    );
    if (data.probationEndDate) {
      setProbationEndDate(data.probationEndDate);
    } else {
      if (data.probationStartDate) {
        setProbationEndDate(addMonths(data.probationStartDate, 6));
      } else {
        setProbationEndDate(addMonths(props.HireDate, 6));
      }
    }
    if (data.probationDuration || data.probationDuration == 0) {
      setProbationDuration(data.probationDuration);
    } else {
      setProbationDuration(6);
    }
    setIsLoader(false);
  }

  //Change Button Text
  function getButtonTextSave(id) {
    id != null && id > 0 && id != undefined
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  //fetch Probation Types
  const [itemProbationTypes, setItemProbationTypes] = useState([]);
  async function getProbationTypes() {
    const token = props.token;
    const response = await fetch("HRDetails/GetProbationTypes", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemProbationTypes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemProbationTypes(jsonItems);
  }
  //fetch Probation Reasons
  const [itemProbationReasons, setItemProbationReasons] = useState([]);
  async function getProbationReasons() {
    const token = props.token;
    const response = await fetch("HRDetails/GetProbationReasons", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemProbationReasons(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemProbationReasons(jsonItems);
  }

  //Get Employee Probation Details On Page Load
  function getEmployeeProbationDetailsOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("HRDetails/GetProbationPeriodDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getEmployeeProbationDetailsOnLoad(props.id);
      }
    }
    getButtonTextSave(probationDetailsId);
  }, [props.id, probationDetailsId, props.token, props.HireDate]);

  useEffect(() => {
    if (props.token) {
      getProbationTypes();
      getProbationReasons();
    }
  }, [props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label" required={true}>Probation Type</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={probationType}
                  //        onChange={handleChangeProbationType}
                  //    >
                  //        {itemProbationTypes.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={probationTypeObj}
                  inputValue={probationTypeText}
                  options={itemProbationTypes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeProbationType}
                  onInputChange={handleChangeProbationTypeInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Probation Type"
                      variant="filled"
                      required
                      error={errorProbationType}
                      helperText={errorTextProbationType}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label" required={true}>Probation Reason</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={probationReason}
                  //        onChange={handleChangeProbationReason}
                  //    >
                  //        {itemProbationReasons.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={probationReasonObj}
                  inputValue={probationReasonText}
                  options={itemProbationReasons}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeProbationReason}
                  onInputChange={handleChangeProbationReasonInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Probation Reason"
                      variant="filled"
                      required
                      error={errorProbationReason}
                      helperText={errorTextProbationReason}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Probation Start date"
                  label="Probation Start date"
                  value={probationStartDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  //disabled={props.disableOnceCompleted ? props.disableOnceCompleted : false}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                  required={true}
                  error={errorProbationStartDate}
                  helperText={errorTextProbationStartDate}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Probation End date"
                  label="Probation End date"
                  value={probationEndDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : false
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="probationDuration"
                  fullWidth
                  type="text"
                  label="Probation Duration(months)"
                  variant="filled"
                  name="Probation Duration(Number)"
                  value={probationDuration}
                  //onKeyDown={handleKeypress}
                  onChange={(e) =>
                    setProbationDuration(
                      AllowMonthNumbers(ReplaceChars(e.target.value))
                    )
                  }
                  inputProps={{
                    //min: 1,
                    //max: 12,
                    maxLength: 2,
                  }}
                  error={errorProbationDuration}
                  helperText={errorTextProbationDuration}
                  disabled={props.disableOnceCompleted}
                />
              </Grid>
              {/* <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField className="lightInputFields mt-1"
                                    id="probationUnit"
                                    fullWidth
                                    type="number"
                                    label="Probation Unit(months)"
                                    variant="filled"
                                    name="Probation Unit(months)"
                                    value={probationUnit}
                                    onKeyDown={handleKeypress}
                                    onChange={(e) => setProbationUnit(e.target.value)}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid> */}

              <Grid item xs={12} className="d-flex jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={clearHandler}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={submitHandler}
                >
                  <span>{buttonTextSave}</span>
                </Button>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
