import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8",
    //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function MaintainEmployeeContract(props) {
  const [employeeContractDetailsId, setEmployeeContractDetailsId] =
    useState("");
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [contractType, setContractType] = useState("");
  const [contractTypeObj, setContractTypeObj] = useState("");
  const [contractTypeText, setContractTypeText] = useState("");
  const [contractStartDate, setContractStartDate] = useState(null);
  const [contractEndDate, setContractEndDate] = useState(null);
  const [contractStatus, setContractStatus] = useState("");
  const [contractStatusObj, setContractStatusObj] = useState("");
  const [contractStatusText, setContractStatusText] = useState("");
  const [contractReason, setContractReason] = useState("");
  const [contractReasonObj, setContractReasonObj] = useState("");
  const [contractReasonText, setContractReasonText] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [isRequiredContractEndDate, setIsRequiredContractEndDate] =
    useState(false);

  //Error State and Msg
  const [errorEffectiveDate, setErrorEffectiveDate] = useState(false);
  const [errorTextEffectiveDate, setErrorTextEffectiveDate] = useState("");
  const [errorContractType, setErrorContractType] = useState(false);
  const [errorTextContractType, setErrorTextContractType] = useState("");
  const [errorContractStartDate, setErrorContractStartDate] = useState(false);
  const [errorTextContractStartDate, setErrorTextContractStartDate] =
    useState("");
  const [errorContractEndDate, setErrorContractEndDate] = useState(false);
  const [errorTextContractEndDate, setErrorTextContractEndDate] = useState("");
  const [errorContractStatus, setErrorContractStatus] = useState(false);
  const [errorTextContractStatus, setErrorTextContractStatus] = useState("");

  //Alerts
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  function dateChangeEvent(dateValue, label) {
    if (label === "Effective Date") {
      setEffectiveDate(dateValue);
    } else if (label === "Contract Start Date") {
      setContractStartDate(dateValue);
    } else if (label === "Contract End Date") {
      setContractEndDate(dateValue);
    }
  }

  var formData = {
    ApplicantId: props.id,
    UserId: props.userId,
    EffectiveDate: effectiveDate,
    ContractTypeId: contractType,
    ContractStartDate: contractStartDate,
    ContractEndDate: contractEndDate,
    ContractStatusId: contractStatus,
    ContractReasonId: contractReason,
  };

  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false,
      e = false;
    if (data.EffectiveDate == null || data.EffectiveDate == "") {
      setErrorEffectiveDate(true);
      setErrorTextEffectiveDate("Mandatory field");
      a = true;
    } else {
      setErrorEffectiveDate(false);
      setErrorTextEffectiveDate("");
      a = false;
    }
    if (data.ContractTypeId == null || data.ContractTypeId == "") {
      setErrorContractType(true);
      setErrorTextContractType("Mandatory field");
      b = true;
    } else {
      setErrorContractType(false);
      setErrorTextContractType("");
      b = false;
    }
    if (data.ContractStartDate == null || data.ContractStartDate == "") {
      setErrorContractStartDate(true);
      setErrorTextContractStartDate("Mandatory field");
      c = true;
    } else {
      setErrorContractStartDate(false);
      setErrorTextContractStartDate("");
      c = false;
    }
    if (data.ContractStatusId == null || data.ContractStatusId == "") {
      setErrorContractStatus(true);
      setErrorTextContractStatus("Mandatory field");
      d = true;
    } else {
      setErrorContractStatus(false);
      setErrorTextContractStatus("");
      d = false;
    }
    if (employeeType != null && employeeType != "" && employeeType != 1) {
      if (data.ContractEndDate == null || data.ContractEndDate == "") {
        setErrorContractEndDate(true);
        setErrorTextContractEndDate("Mandatory field");
        e = true;
      } else {
        setErrorContractEndDate(false);
        setErrorTextContractEndDate("");
        e = false;
      }
    } else {
      setErrorContractEndDate(false);
      setErrorTextContractEndDate("");
      e = false;
    }
    if (a || b || c || d || e) {
      return false;
    } else {
      return true;
    }
  }

  function clearStates() {
    if (props.roleId && props.roleId == 1) {
      setEffectiveDate(null);
      setContractStartDate(null);
    }
    setContractType("");
    setContractTypeObj("");
    setContractTypeText("");
    setContractEndDate(null);
    setContractStatus("");
    setContractStatusObj("");
    setContractStatusText("");
    setContractReason("");
    setContractReasonObj("");
    setContractReasonText("");
  }

  const handleContractType = (event, newValue) => {
    setContractTypeObj(newValue);
    setContractType(newValue ? newValue.id : 0);
  };
  const handleContractTypeInput = (event, newInputValue) => {
    setContractTypeText(newInputValue);
  };

  const handleContractStatus = (event, newValue) => {
    setContractStatusObj(newValue);
    setContractStatus(newValue ? newValue.id : 0);
  };
  const handleContractStatusInput = (event, newInputValue) => {
    setContractStatusText(newInputValue);
  };

  const handleContractReason = (event, newValue) => {
    setContractReasonObj(newValue);
    setContractReason(newValue ? newValue.id : 0);
  };
  const handleContractReasonInput = (event, newInputValue) => {
    setContractReasonText(newInputValue);
  };

  const submitHandler = () => {
    setIsLoader(true);
    formData = {
      ApplicantId: props.id,
      UserId: props.userId,
      EffectiveDate: effectiveDate,
      ContractTypeId: contractType,
      ContractStartDate: contractStartDate,
      ContractEndDate: contractEndDate,
      ContractStatusId: contractStatus,
      ContractReasonId: contractReason,
    };
    if (ValidateFormFields(formData)) {
      if (
        employeeContractDetailsId != null &&
        employeeContractDetailsId > 0 &&
        employeeContractDetailsId != undefined
      ) {
        UpdateEmployeeContractDetails(formData);
      } else {
        SaveEmployeeContractDetails(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  const clearHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //Save Employee Contract Details
  function SaveEmployeeContractDetails(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/SaveEmployeeContractDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Update Employee Contract Details
  function UpdateEmployeeContractDetails(formData) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/UpdateEmployeeContractDetails/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Set All States
  function SetStateAsPerFetched(data) {
    setEmployeeContractDetailsId(data.employeeContractDetailsId);
    setEffectiveDate(data.effectiveDate ? data.effectiveDate : props.HireDate);
    setContractType(data.contractTypeId);
    if (data.contractTypeObj[0].id) {
      setContractTypeObj(data.contractTypeObj[0]);
      setContractTypeText(data.contractTypeObj[0].name);
    }
    setContractStartDate(
      data.contractStartDate ? data.contractStartDate : props.HireDate
    );
    setContractEndDate(data.contractEndDate);
    setContractStatus(data.contractStatusId);
    if (data.contractStatusObj[0].id) {
      setContractStatusObj(data.contractStatusObj[0]);
      setContractStatusText(data.contractStatusObj[0].name);
    }
    setContractReason(data.contractReasonId);
    if (data.contractReasonObj[0].id) {
      setContractReasonObj(data.contractReasonObj[0]);
      setContractReasonText(data.contractReasonObj[0].name);
    }
    setIsLoader(false);
  }

  //Change Button Text
  function getButtonTextSave(id) {
    id != null && id > 0 && id != undefined
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  //fetch Contract Types
  const [itemContractTypes, setItemContractTypes] = useState([]);
  async function getContractTypes() {
    const token = props.token;
    const response = await fetch("HRDetails/GetEmployeeContractTypes", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemContractTypes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemContractTypes(jsonItems);
  }

  //fetch Contract Status
  const [itemContractStatus, setItemContractStatus] = useState([]);
  async function getCompanyStatus() {
    const token = props.token;
    const response = await fetch("HRDetails/GetContractStatus", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemContractStatus(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemContractStatus(jsonItems);
  }

  //fetch Contract Reason
  const [itemContractReason, setItemContractReason] = useState([]);
  async function getContractReason() {
    const token = props.token;
    const response = await fetch("HRDetails/GetContractReason", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemContractReason(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemContractReason(jsonItems);
  }

  //Get Employee Contract Details On Page Load
  function getEmployeeContractDetailsOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("HRDetails/GetEmployeeContractDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  //Get EmployeeType from RegistrationData On Page Load
  function getEmployeeTypeDetailsOnLoad(id) {
    const token = props.token;
    fetch("HRDetails/GetEmployeeType/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setEmployeeType(data.id);
          data.id && data.id != 1
            ? setIsRequiredContractEndDate(true)
            : setIsRequiredContractEndDate(false);
        } else {
          console.log("Error at getEmployeeTypeDetailsOnLoad");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getEmployeeContractDetailsOnLoad(props.id);
        getEmployeeTypeDetailsOnLoad(props.id);
      }
    }
    getButtonTextSave(employeeContractDetailsId);
  }, [props.id, employeeContractDetailsId, props.token, props.HireDate]);

  useEffect(() => {
    if (props.token) {
      getContractTypes();
      getCompanyStatus();
      getContractReason();
    }
  }, [props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Effective Date"
                  label="Effective Date"
                  value={effectiveDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                  required={true}
                  error={errorEffectiveDate}
                  helperText={errorTextEffectiveDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label" required={true}>Contract Type</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={contractType}
                  //        onChange={handleContractType}
                  //    >
                  //        {itemContractTypes.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={contractTypeObj}
                  inputValue={contractTypeText}
                  options={itemContractTypes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleContractType}
                  onInputChange={handleContractTypeInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contract Type"
                      variant="filled"
                      required
                      error={errorContractType}
                      helperText={errorTextContractType}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Contract Start Date"
                  label="Contract Start Date"
                  value={contractStartDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.roleId && props.roleId == 1
                      ? false
                      : true
                  }
                  required={true}
                  error={errorContractStartDate}
                  helperText={errorTextContractStartDate}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <FormDatePicker
                  placeholder="Contract End Date"
                  label="Contract End Date"
                  value={contractEndDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={false}
                  disableBefore={"01-Sep-2021"}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : false
                  }
                  required={isRequiredContractEndDate}
                  error={errorContractEndDate}
                  helperText={errorTextContractEndDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label" required={true}>Contract Status</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={contractStatus}
                  //        onChange={handleContractStatus}
                  //    >
                  //        {itemContractStatus.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={contractStatusObj}
                  inputValue={contractStatusText}
                  options={itemContractStatus}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleContractStatus}
                  onInputChange={handleContractStatusInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contract Status"
                      variant="filled"
                      required
                      error={errorContractStatus}
                      helperText={errorTextContractStatus}
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label">Employee Contract Reason</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={contractReason}
                  //        onChange={handleContractReason}
                  //    >
                  //        {itemContractReason.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={contractReasonObj}
                  inputValue={contractReasonText}
                  options={itemContractReason}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleContractReason}
                  onInputChange={handleContractReasonInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee Contract Reason"
                      variant="filled"
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                />
              </Grid>

              <Grid item xs={12} className="d-flex jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={clearHandler}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableHRAction
                  }
                  onClick={submitHandler}
                >
                  <span>{buttonTextSave}</span>
                </Button>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
