import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function SLLIC(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
            
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [lifeInsurance, setLifeInsurance] = useState([]);
    const lifeInsuranceTemp = [];

    const [personalAccident, setPersonalAccident] = useState([]);
    const personalAccidentTemp = [];

    const [personalData, setPersonalData] = useState([]);
    const personalDataTemp = [];

    const [departmentData, setDepartmentData] = useState([]);
    const departmentDataTemp = [];

   
    function SetStateAsPerFetched(data) {
        //Life Insurance Data
        for (var i = 0; i < data.lifeInsuranceData.length; i++) {
            lifeInsuranceTemp.push({
                'firstNameNominee': data.lifeInsuranceData[i].firstNameNominee, 'middleNameNominee': data.lifeInsuranceData[i].middleNameNominee ? data.lifeInsuranceData[i].middleNameNominee : "",
                'lastNameNominee': data.lifeInsuranceData[i].lastNameNominee, 'dob': data.lifeInsuranceData[i].dobStr,
                'relationWithEmployee': data.lifeInsuranceData[i].relationWithEmployee, 'nomineeAddress': data.lifeInsuranceData[i].nomineeAddress,
                'sharePercentage': data.lifeInsuranceData[i].sharePercentage, 'guardianName': data.lifeInsuranceData[i].guardianName ? data.lifeInsuranceData[i].guardianName : "",
                'guardianAddress': data.lifeInsuranceData[i].guardianAddress ? data.lifeInsuranceData[i].guardianAddress : "", 'validationOfMinorYesOrNo': data.lifeInsuranceData[i].validationOfMinorYesOrNo,
                'count': i + 1

            });
        }
        setLifeInsurance(lifeInsuranceTemp);


        //Personal Accident Data
        for (var i = 0; i < data.personalAccidentData.length; i++) {
            personalAccidentTemp.push({
                'firstNameNominee': data.personalAccidentData[i].firstNameNominee, 'middleNameNominee': data.personalAccidentData[i].middleNameNominee ? data.personalAccidentData[i].middleNameNominee : "",
                'lastNameNominee': data.personalAccidentData[i].lastNameNominee, 'dob': data.personalAccidentData[i].dobStr,
                'relationWithEmployee': data.personalAccidentData[i].relationWithEmployee, 'nomineeAddress': data.personalAccidentData[i].nomineeAddress,
                'guardianName': data.personalAccidentData[i].guardianName ? data.personalAccidentData[i].guardianName : "",
                'guardianAddress': data.personalAccidentData[i].guardianAddress ? data.personalAccidentData[i].guardianAddress : "", 'validationOfMinorYesOrNo': data.personalAccidentData[i].validationOfMinorYesOrNo,
                'sharePercentage': data.personalAccidentData[i].sharePercentage, 'count': i + 1

            });
        }
        setPersonalAccident(personalAccidentTemp);

        //Personal Data
        personalDataTemp.push({
            'firstName': data.personalData.firstName, 'middleName': data.personalData.middleName ? data.personalData.middleName : "",
            'lastName': data.personalData.lastName, 'fatherName': data.personalData.fatherName ? data.personalData.fatherName : (data.personalData.spouseName ? data.personalData.spouseName : ""),
            'gender': data.personalData.genders[0].name, 'maritalStatus': data.personalData.maritalStatuses[0].name,
            'dateOfBirth': data.personalData.dateOfBirthStr, 'registrationNo': data.personalData.registrationNo ? data.personalData.registrationNo : ""

        });

        setPersonalData(personalDataTemp);

        //Department and Sector Data
        departmentDataTemp.push({
            'department': data.department ? data.department : "", 'sector': data.sector ? data.sector : "",
            'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr

        });

        setDepartmentData(departmentDataTemp);

    }


    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);

            }
        }
    }, [encryptedId, props.token]);

    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">

            <table className="width-100 ">
                <thead>
                    <tr className="d-flex">
                        <th>
                            {
                                (props.AREID == 1 || props.AREID == 2)
                                    ?
                                    <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                                    :
                                    <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                            }
                        </th>
                    </tr>
                </thead>
                <tbody className="f-12">
                    <tr>
                        <td className="d-flex jc-center">
                            <span className="f-14 fw-bold text-underline">
                                Group Term Life Insurance Coverage Scheme</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-center">
                            <span className="f-12 fw-bold text-underline">
                                Nomination Form
                                </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-2e fw-bold text-underline">
                                Sub: Nomination under the Group Term Life Insurance Coverage Scheme
                                </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">I hereby nominate the person(s)
                            mentioned below to receive the benefits under this scheme in the event of my death and
                            cancel any nominations made by me previously.</span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">I hereby declare that the
                            person(s) nominated is / are not a member(s) of my family as defined in the scheme. (*Please
                                strike off whichever is not applicable)</span>
                        </td>
                    </tr >
                    <tr>
                        <td className="d-flex jc-center">
                            <table className="bdr-black border-collapse width-80 text-center mt-2">
                                <tbody className="f-12">
                                    <tr className="bg-grey-ccc">
                                        <td className="bdr-black">Full Name of Nominee(s)</td>
                                        <td className="bdr-black">Relationship with Employee</td>
                                        <td className="bdr-black">Date of Birth</td>
                                        <td className="bdr-black">Share in
                                            Percentage</td>
                                    </tr>
                                    <tr className="">
                                        <td className="bdr-black text-center">
                                            {lifeInsurance.map(lifeInsuranceNew => <div><br />{lifeInsuranceNew.firstNameNominee + " " + lifeInsuranceNew.middleNameNominee + " " + lifeInsuranceNew.lastNameNominee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {lifeInsurance.map(lifeInsuranceNew => <div><br />{lifeInsuranceNew.relationWithEmployee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {lifeInsurance.map(lifeInsuranceNew => <div><br />{lifeInsuranceNew.dob}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {lifeInsurance.map(lifeInsuranceNew => <div><br />{lifeInsuranceNew.sharePercentage}<br /><br /></div>)}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-center">
                            <table className="bdr-black border-collapse width-60 text-center mt-2">
                                <tbody className="f-12">
                                    <tr className="bg-grey-ccc">
                                        <td className="bdr-black">Nominee’ s Address</td>
                                        <td className="bdr-black">Guardian’s Address ( In case of Minors)</td>
                                    </tr>
                                    <tr className="">
                                        <td className="bdr-black text-center ">
                                            {lifeInsurance.map(lifeInsuranceNew => <div><br />{lifeInsuranceNew.nomineeAddress}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center ">
                                            {lifeInsurance.map(lifeInsuranceNew => <div><br />{lifeInsuranceNew.guardianAddress}<br /><br /></div>)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-space-bt">
                            <div className="mt-1 ml-3 f-14 font-italic fw-bold text-underline">Employee’s Details</div>
                        </td>
                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-40">
                        <td className="ml-3">Full Name: {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                        </td>
                        {/*<td className="f-14 fw-bold">GID :  {personalData.map(personalDataNew => <span>{personalDataNew.registrationNo}</span>)}*/}
                        {/*</td>*/}
                        <td className="pl-2">GID : <span>{props.gid}</span>
                        </td>
                        <td></td>
                        <td className="mr-3">Unit/Dept:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}
                        </td>
                        <td></td>
                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-50 text-underline">
                        <td className="ml-3">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}
                        </td>
                        <td>Place:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}
                        </td>
                        <td></td>
                        <td className="mR-5">Signature of Employee_______</td>
                        <td></td>
                    </tr>



                    <tr className="d-flex f-12 fw-bold jc-space-bt l-40 text-underline">
                        <td className="mt-2" style={{ marginLeft: '218px' }}>Witness 1
                        </td>
                        <td className="pl-2 mt-2" style={{ marginLeft: '100px' }}>Witness 2
                        </td>
                        <td></td>
                        
                    </tr>

                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">Name :
                        </td>
                        
                        <td className="pl-2">Name :
                        </td>
                        <td></td>
                        
                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">GID :
                        </td>

                        <td className="pl-2">GID :
                        </td>
                        <td></td>

                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">Address :
                        </td>

                        <td className="pl-2">Address :
                        </td>
                        <td></td>

                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">Signature :
                        </td>

                        <td className="pl-2">Signature :
                        </td>
                        <td></td>

                    </tr>


                    <tr>
                        <td className="d-flex jc-center">
                            <div className="mt-1 f-12 fw-bold text-underline" >
                                FOR THE USE OF PERSONNEL DEPARTMENT
                                </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-flex-start" >
                            <span className="f-12 mt-1" >Particulars mentioned in the above are
                            correct. The employee is eligible to be covered under the scheme. His nomination
                            is received by us on {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex mt-1 mb-1 ml-1 jc-flex-end">
                                <span className="mr-2 f-12 fw-bold">_________________<br />Signature
                                    of Unit Per</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className=" pagebreak">

                {
                    (props.AREID == 1 || props.AREID == 2)
                        ?
                        <img src={"../assets/images/Siemens_Logo_Healthineers.jpg"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                        :
                        <img src={"../assets/images/sie-logo-petrol-rgb.png"} alt="Siemens Company Logo" className="siemens-logo-dark" />
                }

                
                <div className="mt-1 f-16 fw-bold d-flex jc-center">
                    <span className="f-14 fw-bold text-underline m-0">Group Personal Accident Scheme</span>
                </div>
                <div className="f-14 fw-bold d-flex jc-center">
                    <span className="f-12 fw-bold text-underline m-0">Nomination Form</span>
                </div>
                <div className="mt-2e">
                    <span className="f-12 fw-bold text-underline ml-1">Sub: Nomination under the Group Personal Accident Coverage Scheme</span>
                </div>

                <div className="mt-2e">
                    <span className="f-12 ml-1">I hereby nominate the person(s) mentioned below to receive the benefits under this scheme in the event of my death and
                    cancel any nominations made by me previously.</span>
                </div>
                <div className="mt-0">
                    <span className="f-12 ml-1">I hereby declare that the person(s) nominated is / are not a member(s) of my family as defined in the scheme.</span>
                </div>
                <div className="mt-0">
                    <span className="f-12 ml-1">(*Please strike off whichever is not applicable)</span>
                </div>
                 
            </div>
            <table className="width-100">
                <tbody className="f-12">
                    <tr>
                        <td className="d-flex jc-center">
                            <table className="bdr-black border-collapse width-80 text-center mt-2">
                                <tbody className="f-12">
                                    <tr className="bg=grey-ccc">
                                        <td className="bdr-black">Full Name of Nominee(s)</td>
                                        <td className="bdr-black">Relationship with Employee</td>
                                        <td className="bdr-black">Date of Birth</td>
                                        <td className="bdr-black">Share in
                                            Percentage</td>
                                    </tr>
                                    <tr className="">
                                        <td className="bdr-black text-center">
                                            {personalAccident.map(personalAccidentNew => <div><br />{personalAccidentNew.firstNameNominee + " " + personalAccidentNew.middleNameNominee + " " + personalAccidentNew.lastNameNominee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {personalAccident.map(personalAccidentNew => <div><br />{personalAccidentNew.relationWithEmployee}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {personalAccident.map(personalAccidentNew => <div><br />{personalAccidentNew.dob}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {personalAccident.map(personalAccidentNew => <div><br />{personalAccidentNew.sharePercentage}<br /><br /></div>)}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-center">
                            <table className="bdr-black border-collapse width-60 text-center mt-2">
                                <tbody className="f-12">
                                    <tr className="bg=grey-ccc">
                                        <td className="bdr-black">Nominee’ s Address</td>
                                        <td className="bdr-black">Guardian’s Address ( In case of Minors)</td>
                                    </tr>
                                    <tr className="">
                                        <td className="bdr-black text-center">
                                            {personalAccident.map(personalAccidentNew => <div><br />{personalAccidentNew.nomineeAddress}<br /><br /></div>)}
                                        </td>
                                        <td className="bdr-black text-center">
                                            {personalAccident.map(personalAccidentNew => <div><br />{personalAccidentNew.guardianAddress}<br /><br /></div>)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-space-bt">
                            <div className="mt-1 ml-3 font-italic f-12 fw-bold text-underline" >Employee’s Details</div>
                        </td>
                    </tr >
                    <tr className="d-flex fw-bold f-12 jc-space-bt l-40">
                        <td className="ml-3">Full Name: {personalData.map(personalDataNew => <span>{personalDataNew.firstName + " " + personalDataNew.middleName + " " + personalDataNew.lastName}</span>)}
                        </td>
                        {/*<td className="f-14 fw-bold">GID :  {personalData.map(personalDataNew => <span>{personalDataNew.registrationNo}</span>)}*/}
                        {/*</td>*/}
                        <td className="pl-2">GID : <span>{props.gid}</span>
                        </td>
                        <td></td>
                        <td className="mr-3">Unit/Dept: {departmentData.map(departmentDataNew => <span>{departmentDataNew.department}</span>)}
                        </td>
                        <td></td>
                    </tr>
                    <tr className="d-flex fw-bold f-12 jc-space-bt l-50 text-underline">
                        <td className="ml-3">Date:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}
                        </td>
                        <td className="">Place:  {departmentData.map(departmentDataNew => <span>{departmentDataNew.placeOfWork}</span>)}
                        </td>
                        <td></td>
                        <td className="mR-5">Signature of Employee_______</td>
                        <td></td>
                    </tr>


                    <tr className="d-flex f-12 fw-bold jc-space-bt l-40 text-underline">
                        <td className="mt-2" style={{ marginLeft: '218px' }}>Witness 1
                        </td>
                        <td className="pl-2 mt-2" style={{ marginLeft: '100px' }}>Witness 2
                        </td>
                        <td></td>

                    </tr>

                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">Name :
                        </td>

                        <td className="pl-2">Name :
                        </td>
                        <td></td>

                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">GID :
                        </td>

                        <td className="pl-2">GID :
                        </td>
                        <td></td>

                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">Address :
                        </td>

                        <td className="pl-2">Address :
                        </td>
                        <td></td>

                    </tr>
                    <tr className="d-flex f-12 fw-bold jc-space-bt l-25">
                        <td className="ml-3">Signature :
                        </td>

                        <td className="pl-2">Signature :
                        </td>
                        <td></td>

                    </tr>



                    <tr>
                        <td className="d-flex jc-center">
                            <div className="mt-1 f-12 fw-bold text-underline">
                                FOR THE USE OF PERSONNEL DEPARTMENT
                                </div>
                        </td>
                    </tr >
                    <tr>
                        <td className="d-flex jc-flex-start">
                            <span className="f-12 mt-1">Particulars mentioned in the
                            above are
                            correct. The employee is eligible to be covered under the scheme. His nomination
                                is received by us on {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}</span>
                        </td>
                    </tr >
                    <tr>
                        <td>
                            <div className="d-flex mt-2 mb-1 ml-1 jc-flex-end">
                                <span className="mr-2 fw-bold f-12">_________________<br />Signature
                                    of Unit Per</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    );

}