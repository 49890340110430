import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  TextField,
  createMuiTheme,
  ThemeProvider,
  InputAdornment,
} from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { makeStyles } from "@material-ui/core/styles";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
      adornedEnd: {
        backgroundColor: "#E8F8F8",
        //padding:"12px 0 0 12px !important",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8 !important",
        },
        //"&&&&:hover": {
        //    backgroundColor: "#E8F8F8"
        //},
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
    },
    //MuiAutocomplete: {
    //    root: {
    //        background: "#E8F8F8",
    //        '&$disabled': {
    //            backgroundColor: '#454545 !important',
    //        },
    //        padding: "0 !important",
    //        height: "55px !important",
    //    },
    //},
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function DetailsEnteredByOBTeam(props) {
  var id, applicantId;
  const [obtEnteredDetailsId, setOBTEnteredDetailsId] = useState("");
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [requiredEmployeeJoinDelayed, setRequiredEmployeeJoinDelayed] =
    useState(false);
  const [requiredNewJoiningDate, setRequiredNewJoiningDate] = useState(false);
  const [employeeJoinedYesNo, setEmployeeJoinedYesNo] = useState("");
  const [employeeJoinedYesNoObj, setEmployeeJoinedYesNoObj] = useState("");
  const [employeeJoinedYesNoText, setEmployeeJoinedYesNoText] = useState("");
  const [employeeJoinDelayedYesNo, setEmployeeJoinDelayedYesNo] = useState("");
  const [employeeJoinDelayedYesNoObj, setEmployeeJoinDelayedYesNoObj] =
    useState("");
  const [employeeJoinDelayedYesNoText, setEmployeeJoinDelayedYesNoText] =
    useState("");
  const [newJoiningDate, setNewJoiningDate] = useState(null);
  const [oldJoiningDate, setOldJoiningDate] = useState(null);
  const [gid, setGid] = useState("");

  //Error States and Msg
  const [errorEmployeeJoined, setErrorEmployeeJoined] = useState(false);
  const [errorTextEmployeeJoined, setErrorTextEmployeeJoined] = useState("");
  const [errorEmployeeJoinDelayed, setErrorEmployeeJoinDelayed] =
    useState(false);
  const [errorTextEmployeeJoinDelayed, setErrorTextEmployeeJoinDelayed] =
    useState("");
  const [errorNewJoiningDate, setErrorNewJoiningDate] = useState(false);
  const [errorTextNewJoiningDate, setErrorTextNewJoiningDate] = useState("");
  const [errorGid, setErrorGid] = useState(false);
  const [errorTextGid, setErrorTextGid] = useState("");
  const [isGidValid, setIsGidValid] = useState(null);
  const [disableGid, setDisableGid] = useState(false);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openTypeCategory, setOpenTypeCategory] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  function dateChangeEvent(dateValue, label) {
    if (label === "New Joining Date") {
      setNewJoiningDate(dateValue);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (openTypeCategory === "MarkAsCompleted") {
      formDetails.ApplicantId = props.id;
      formDetails.UserId = props.userId;
      formDetails.EmployeeJoinedYesNo = employeeJoinedYesNo;
      formDetails.EmployeeJoinDelayedYesNo = employeeJoinDelayedYesNo;
      formDetails.NewJoiningDate = newJoiningDate;
      formDetails.Gid = gid;
      MarkAsCompletedOBTeam(formDetails);
    } else {
      clearStates();
    }
    setOpenConfirm(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDownloadDocuments = () => {
    window.location.href = "/DownloadDocuments?id=" + props.id;
  };
  const handleMarkAsCompleted = () => {
    formDetails.ApplicantId = props.id;
    formDetails.UserId = props.userId;
    formDetails.EmployeeJoinedYesNo = employeeJoinedYesNo;
    formDetails.EmployeeJoinDelayedYesNo = employeeJoinDelayedYesNo;
    formDetails.NewJoiningDate = newJoiningDate;
    formDetails.Gid = gid;
    if (ValidateFormFields(formDetails)) {
      setOpenConfirm(true);
      setOpenAlertConfirm(
        "Please note, once you mark this hire workflow as complete you won't be able to revert. Are you sure?"
      );
      setClassName("clr-white bg-alert");
      setOpenType("Confirmation");
      setOpenTypeCategory("MarkAsCompleted");
    } else {
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };
  const handleChangeGid = (event) => {
    setGid(event.target.value);
    setErrorGid(false);
    setErrorTextGid("");
    if (
      event.target.value != null &&
      event.target.value != "" &&
      event.target.value != undefined
    ) {
      if (!IsGIDValid(event.target.value) || event.target.value.length < 8) {
        setIsGidValid(false);
      } else {
        setIsGidValid(true);
      }
    } else {
      setIsGidValid(null);
    }
  };

  const handleChangeEmployeeJoined = (event, newValue) => {
    setEmployeeJoinedYesNoObj(newValue);
    setEmployeeJoinedYesNo(newValue ? newValue.id : 0);
    if (newValue != null && newValue != "") {
      if (newValue.id == 2) {
        setRequiredEmployeeJoinDelayed(true);
      } else {
        setRequiredEmployeeJoinDelayed(false);
        setEmployeeJoinDelayedYesNo("");
        setEmployeeJoinDelayedYesNoObj("");
        setEmployeeJoinDelayedYesNoText("");
        setNewJoiningDate(null);
      }
    } else {
      setRequiredEmployeeJoinDelayed(false);
      setEmployeeJoinDelayedYesNo("");
      setEmployeeJoinDelayedYesNoObj("");
      setEmployeeJoinDelayedYesNoText("");
      setNewJoiningDate(null);
    }
  };
  const handleChangeEmployeeJoinedInput = (event, newInputValue) => {
    setEmployeeJoinedYesNoText(newInputValue);
  };

  const handleChangeEmployeeJoinDelayed = (event, newValue) => {
    setEmployeeJoinDelayedYesNoObj(newValue);
    setEmployeeJoinDelayedYesNo(newValue ? newValue.id : 0);
    if (newValue != null && newValue != "") {
      if (newValue.id == 1) {
        setRequiredNewJoiningDate(true);
      } else {
        setRequiredNewJoiningDate(false);
        setNewJoiningDate(null);
      }
      if (newValue.id == 2) {
        setGid("");
        setDisableGid(true);
      } else {
        setDisableGid(false);
      }
    } else {
      setRequiredNewJoiningDate(false);
      setNewJoiningDate(null);
      setDisableGid(false);
    }
  };
  const handleChangeEmployeeJoinDelayedInput = (event, newInputValue) => {
    setEmployeeJoinDelayedYesNoText(newInputValue);
  };

  var formDetails = {
    ObtenteredDetailsId: obtEnteredDetailsId,
    UserId: props.userId,
    ApplicantId: props.id,
    EmployeeJoinedYesNo: employeeJoinedYesNo,
    EmployeeJoinDelayedYesNo: employeeJoinDelayedYesNo,
    NewJoiningDate: newJoiningDate,
    OldJoiningDate: oldJoiningDate,
    Gid: gid,
  };

  const submitHandler = (event) => {
    setIsLoader(true);
    formDetails.ApplicantId = props.id;
    formDetails.UserId = props.userId;
    formDetails.EmployeeJoinedYesNo = employeeJoinedYesNo;
    formDetails.EmployeeJoinDelayedYesNo = employeeJoinDelayedYesNo;
    formDetails.NewJoiningDate = newJoiningDate;
    formDetails.OldJoiningDate = oldJoiningDate;
    formDetails.Gid = gid;
    if (ValidateFormFields(formDetails)) {
      if (
        obtEnteredDetailsId != null &&
        obtEnteredDetailsId != "" &&
        obtEnteredDetailsId > 0
      ) {
        UpdateDetailsEnteredByOBTeam(formDetails);
      } else {
        SaveDetailsEnteredByOBTeam(formDetails);
        //console.log(formDetails);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };

  //Validation
  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false;
    if (data.EmployeeJoinedYesNo == null || data.EmployeeJoinedYesNo == "") {
      setErrorEmployeeJoined(true);
      setErrorTextEmployeeJoined("Mandatory field");
      a = true;
    } else {
      setErrorEmployeeJoined(false);
      setErrorTextEmployeeJoined("");
      a = false;
    }
    if (
      data.EmployeeJoinedYesNo != null &&
      data.EmployeeJoinedYesNo != "" &&
      data.EmployeeJoinedYesNo == 2
    ) {
      if (
        data.EmployeeJoinDelayedYesNo == null ||
        data.EmployeeJoinDelayedYesNo == ""
      ) {
        setErrorEmployeeJoinDelayed(true);
        setErrorTextEmployeeJoinDelayed("Mandatory field");
        b = true;
      } else {
        setErrorEmployeeJoinDelayed(false);
        setErrorTextEmployeeJoinDelayed("");
        b = false;
      }
    } else {
      setErrorEmployeeJoinDelayed(false);
      setErrorEmployeeJoinDelayed("");
      b = false;
    }
    if (
      data.EmployeeJoinDelayedYesNo != null &&
      data.EmployeeJoinDelayedYesNo != "" &&
      data.EmployeeJoinDelayedYesNo == 1 &&
      data.EmployeeJoinedYesNo != null &&
      data.EmployeeJoinedYesNo != "" &&
      data.EmployeeJoinedYesNo == 2
    ) {
      if (data.NewJoiningDate == null || data.NewJoiningDate == "") {
        setErrorNewJoiningDate(true);
        setErrorTextNewJoiningDate("Mandatory field");
        c = true;
      } else {
        setErrorNewJoiningDate(false);
        setErrorTextNewJoiningDate("");
        c = false;
      }
    } else {
      setErrorNewJoiningDate(false);
      setErrorTextNewJoiningDate("");
      c = false;
    }
    if (data.Gid != null && data.Gid != "" && data.Gid != undefined) {
      if (!IsGIDValid(data.Gid)) {
        setErrorGid(true);
        setErrorTextGid("Can't start with no(s) or contain special character");
        d = true;
      } else if (data.Gid.length < 8) {
        setErrorGid(true);
        setErrorTextGid("Must be of minimum 8 charaters");
        d = true;
      } else {
        setErrorGid(false);
        setErrorTextGid("");
        d = false;
      }
    } else {
      setErrorGid(false);
      setErrorTextGid("");
      d = false;
    }
    if (a || b || c || d) {
      return false;
    } else {
      return true;
    }
  }

  function IsGIDValid(value) {
    const exp = /^[a-zA-Z]{1}[a-zA-Z0-9\-]*$/;
    return exp.test(value);
  }

  function SaveDetailsEnteredByOBTeam(formDetails) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formDetails),
    };
    fetch("HRDetails/SaveDetailEnteredByOBTeam/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }
  function UpdateDetailsEnteredByOBTeam(formDetails) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formDetails),
    };
    fetch("HRDetails/UpdateDetailEnteredByOBTeam/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }
  function MarkAsCompletedOBTeam(formDetails) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formDetails),
    };
    fetch("HRDetails/MarkAsCompleted/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetchedAfterComplete(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  const clearHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  function clearStates() {
    setEmployeeJoinedYesNo("");
    setEmployeeJoinedYesNoObj("");
    setEmployeeJoinedYesNoText("");
    setEmployeeJoinDelayedYesNo("");
    setEmployeeJoinDelayedYesNoObj("");
    setEmployeeJoinDelayedYesNoText("");
    setGid("");
    setIsGidValid(null);
    setNewJoiningDate(null);
    setRequiredEmployeeJoinDelayed(false);
    setRequiredNewJoiningDate(false);
  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //fetch YesNo Options
  const [itemsYesNo, setItemsYesNo] = useState([]);
  async function getYesNoOptions() {
    const token = props.token;
    const response = await fetch("HRDetails/GetYesNoOptions", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsYesNo(jsonItems);
  }

  //Change Button Text
  function getButtonTextSave(obtEnteredDetailsId) {
    obtEnteredDetailsId != null && obtEnteredDetailsId > 0
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  //Set All States
  function SetStateAsPerFetched(data) {
    setOBTEnteredDetailsId(data.obtenteredDetailsId);
    setEmployeeJoinedYesNo(data.employeeJoinedYesNo);
    if (data.employeeJoinedYesNoObj[0].id > 0) {
      setEmployeeJoinedYesNoObj(data.employeeJoinedYesNoObj[0]);
      setEmployeeJoinedYesNoText(data.employeeJoinedYesNoObj[0].name);
    }
    setEmployeeJoinDelayedYesNo(data.employeeJoinDelayedYesNo);
    if (data.employeeJoinDelayedYesNoObj[0].id > 0) {
      setEmployeeJoinDelayedYesNoObj(data.employeeJoinDelayedYesNoObj[0]);
      setEmployeeJoinDelayedYesNoText(data.employeeJoinDelayedYesNoObj[0].name);
    }
    data.newJoiningDate
      ? setNewJoiningDate(data.newJoiningDate)
      : setNewJoiningDate(props.HireDate);
    data.newJoiningDate
      ? setOldJoiningDate(data.newJoiningDate)
      : setOldJoiningDate(props.HireDate);

    data.employeeJoinedYesNo != null &&
    data.employeeJoinedYesNo != "" &&
    data.employeeJoinedYesNo == 2
      ? setRequiredEmployeeJoinDelayed(true)
      : setRequiredEmployeeJoinDelayed(false);

    data.employeeJoinedYesNo != null &&
    data.employeeJoinedYesNo != "" &&
    data.employeeJoinedYesNo == 2 &&
    data.employeeJoinDelayedYesNo != null &&
    data.employeeJoinDelayedYesNo != "" &&
    data.employeeJoinDelayedYesNo == 1
      ? setRequiredNewJoiningDate(true)
      : setRequiredNewJoiningDate(false);
    setGid(data.gid);
    setIsGidValid(null);
    if (data.employeeJoinDelayedYesNo == 2) {
      setGid("");
      setDisableGid(true);
    } else {
      setDisableGid(false);
    }
    setIsLoader(false);
  }

  function SetStateAsPerFetchedAfterComplete(data) {
    setOBTEnteredDetailsId(data.obtenteredDetailsId);
    setEmployeeJoinedYesNo(data.employeeJoinedYesNo);
    if (data.employeeJoinedYesNoObj[0].id > 0) {
      setEmployeeJoinedYesNoObj(data.employeeJoinedYesNoObj[0]);
      setEmployeeJoinedYesNoText(data.employeeJoinedYesNoObj[0].name);
    }
    setEmployeeJoinDelayedYesNo(data.employeeJoinDelayedYesNo);
    if (data.employeeJoinDelayedYesNoObj[0].id > 0) {
      setEmployeeJoinDelayedYesNoObj(data.employeeJoinDelayedYesNoObj[0]);
      setEmployeeJoinDelayedYesNoText(data.employeeJoinDelayedYesNoObj[0].name);
    }
    data.newJoiningDate
      ? setNewJoiningDate(data.newJoiningDate)
      : setNewJoiningDate(props.HireDate);
    data.newJoiningDate
      ? setOldJoiningDate(data.newJoiningDate)
      : setOldJoiningDate(props.HireDate);

    data.employeeJoinedYesNo != null &&
    data.employeeJoinedYesNo != "" &&
    data.employeeJoinedYesNo == 2
      ? setRequiredEmployeeJoinDelayed(true)
      : setRequiredEmployeeJoinDelayed(false);

    data.employeeJoinedYesNo != null &&
    data.employeeJoinedYesNo != "" &&
    data.employeeJoinedYesNo == 2 &&
    data.employeeJoinDelayedYesNo != null &&
    data.employeeJoinDelayedYesNo != "" &&
    data.employeeJoinDelayedYesNo == 1
      ? setRequiredNewJoiningDate(true)
      : setRequiredNewJoiningDate(false);
    setGid(data.gid);
    setIsGidValid(null);
    if (data.employeeJoinDelayedYesNo == 2) {
      setGid("");
      setDisableGid(true);
    } else {
      setDisableGid(false);
    }
    setIsLoader(false);
    props.callback(data.statusId);
  }

  //Get Details Entered By OB Team On Page Load
  function getDetailsEnteredByOBTeamOnLoad(applicantId) {
    setIsLoader(true);
    const token = props.token;
    id = applicantId;
    fetch("HRDetails/GetDetailsEnteredByOBTeam/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  function GetInvalidGIDTooltipTitle() {
    return (
      <React.Fragment>
        <ListItemText primary="1. Must be of minimum 8 characters" />
        <ListItemText primary="2. Can not start with any numbers or special characters" />
        <ListItemText primary="3. Can not contain special characters" />
      </React.Fragment>
    );
  }

  function GetGIDAdornComponent() {
    return isGidValid != null ? (
      <React.Fragment>
        <InputAdornment position="end">
          <Tooltip
            title={isGidValid ? "Valid" : GetInvalidGIDTooltipTitle()}
            placement="top"
          >
            {isGidValid ? (
              <CheckCircleOutlineOutlinedIcon style={{ color: "#6cc070" }} />
            ) : (
              <ErrorOutlineOutlinedIcon style={{ color: "red" }} />
            )}
          </Tooltip>
        </InputAdornment>
      </React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  }

  useEffect(() => {
    if (props.token) {
      getYesNoOptions();

        if (props.id != undefined && props.id != "") {
        getDetailsEnteredByOBTeamOnLoad(props.id);
      }
    }
    getButtonTextSave(obtEnteredDetailsId);
  }, [props.id, obtEnteredDetailsId, props.token, props.HireDate]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            <Grid container spacing={2} className="d-flex jc-flex-end fd-row">
              <p className="clr-dark-blue f-12 font-italic">
                *Note:- Details need to be saved atleast once to mark the hire
                workflow as completed
              </p>
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid
                item
                xs={12}
                sm={3}
                className="d-flex jc-center Width-100 mt-1"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={employeeJoinedYesNoObj}
                  inputValue={employeeJoinedYesNoText}
                  options={itemsYesNo}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={handleChangeEmployeeJoined}
                  onInputChange={handleChangeEmployeeJoinedInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee Joined"
                      variant="filled"
                      required
                      error={errorEmployeeJoined}
                      helperText={errorTextEmployeeJoined}
                      disabled={
                        props.disableOnceCompleted
                          ? props.disableOnceCompleted
                          : false
                      }
                    />
                  )}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : false
                  }
                />
              </Grid>
              {employeeJoinedYesNo != null &&
              employeeJoinedYesNo != "" &&
              employeeJoinedYesNo == 2 ? (
                <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                  <Autocomplete
                    className="lightInputFields hover-lightblue"
                    id="combo-box-demo"
                    value={employeeJoinDelayedYesNoObj}
                    inputValue={employeeJoinDelayedYesNoText}
                    options={itemsYesNo}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    onChange={handleChangeEmployeeJoinDelayed}
                    onInputChange={handleChangeEmployeeJoinDelayedInput}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee Joining Delayed"
                        variant="filled"
                        required={requiredEmployeeJoinDelayed}
                        error={errorEmployeeJoinDelayed}
                        helperText={errorTextEmployeeJoinDelayed}
                        disabled={
                          props.disableOnceCompleted
                            ? props.disableOnceCompleted
                            : false
                        }
                      />
                    )}
                    disabled={
                      props.disableOnceCompleted
                        ? props.disableOnceCompleted
                        : false
                    }
                  />
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {employeeJoinedYesNo != null &&
              employeeJoinedYesNo != "" &&
              employeeJoinedYesNo == 2 &&
              employeeJoinDelayedYesNo != null &&
              employeeJoinDelayedYesNo != "" &&
              employeeJoinDelayedYesNo == 1 ? (
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <FormDatePicker
                    placeholder="New Joining Date"
                    label="New Joining Date"
                    value={newJoiningDate}
                    onChange={dateChangeEvent}
                    disablefuturedate={false}
                    disablepastdate={true}
                    disableBefore={"01-Sep-2021"}
                    disabled={
                      props.disableOnceCompleted
                        ? props.disableOnceCompleted
                        : false
                    }
                    required={requiredNewJoiningDate}
                    error={errorNewJoiningDate}
                    helperText={errorTextNewJoiningDate}
                  />
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <Grid item xs={12} sm={3} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  required={false}
                  id="gid"
                  fullWidth
                  label="Enter valid GID"
                  variant="filled"
                  name="GID"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : disableGid
                  }
                  inputProps={{
                    maxLength: 12,
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                  InputProps={{
                    endAdornment: GetGIDAdornComponent(),
                  }}
                  value={gid}
                  onChange={handleChangeGid}
                  error={errorGid}
                  helperText={errorTextGid}
                />
              </Grid>

              <Grid item xs={12} className="d-flex jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                  onClick={clearHandler}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.disableValue
                  }
                  onClick={submitHandler}
                >
                  <span>{buttonTextSave}</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  onClick={handleDownloadDocuments}
                  disabled={
                    props.disableOnceCompleted
                      ? true
                      : props.status < 5
                      ? true
                      : false
                  }
                >
                  <span>PJQ Form</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  onClick={handleMarkAsCompleted}
                  disabled={
                    props.disableOnceCompleted
                      ? props.disableOnceCompleted
                      : props.status && props.status < 6
                      ? true
                      : obtEnteredDetailsId && obtEnteredDetailsId > 0
                      ? false
                      : true
                  }
                >
                  <span>Mark As Completed</span>
                </Button>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
