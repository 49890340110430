import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { alpha, withStyles, useTheme } from '@material-ui/core/styles';
import {
  TextField,
  createMuiTheme,
  ThemeProvider,
  Checkbox,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import CandidateData from "../CandidateData";
import AppHeader from "../AppHeader";
import { id } from "date-fns/esm/locale";
import { ca } from "date-fns/locale";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Tooltip from "@material-ui/core/Tooltip";
import FormDatePicker from "../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Loader from "../../components/Loader";
const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    //MuiAutocomplete: {
    //    root: {
    //        background: "#E8F8F8",
    //        '&$disabled': {
    //            backgroundColor: '#454545 !important',
    //        },
    //        padding: "0 !important",
    //        height: "55px !important",
    //    },
    //},
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#00CCCC",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    //MuiPickersBasePicker: {
    //    pickerView: {
    //        background: "#000028"
    //    },
    //    background: "#000028"
    //},
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function CandidateRegistration(props) {
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
        //var sParameterName = sURLVariables[i].split("=");
        var sParameterName = sURLVariables[i].split("id=");
        var value = sParameterName[1];
      //var value = "";
      //for (var j = 0; j < sParameterName.length; j++) {
      //  if (sParameterName[j] == sParam) {
      //    for (var k = j + 1; k < sParameterName.length; k++) {
      //      value = value + sParameterName[k];
      //    }
      //    break;
      //  }
      //  //return sParameterName[1];
      //  }

      if (!value) {
        continue;
      } else {
        break;
      }
    }
    return value;
  }
  function IsValidEmail(value) {
    const exp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return exp.test(String(value).toLowerCase());
  }
  function IsValidMobileNo(value) {
    const exp = /^\d{10}$/;
    return exp.test(value);
  }
  function ReplaceChars(value) {
    return value.replace(/\D/g, "");
  }
  var encryptedId = getURLParameter("id");
  var id, data;
  const [buttonTextSubmit, setButtonTextSubmit] = useState("Register");
  const [formHeaderText, setFormHeaderText] = useState(
    "Candidate Registration"
  );
  const [employeeIdEncrypted, setEmployeeIdEncrypted] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [postAppliedFor, setPostAppliedFor] = useState("");
  const [MPRNumber, setMPRNumber] = useState("");
  const [comments, setComments] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeTypeObj, setEmployeeTypeObj] = useState("");
  const [employeeTypeText, setEmployeeTypeText] = useState("");
  const [company, setCompany] = useState("");
    const [companyObj, setCompanyObj] = useState("");
  
  const [companyText, setCompanyText] = useState("");
    const [joiningDate, setJoiningDate] = useState(null);
  const [changeJoiningDate, setChangeJoiningDate] = useState(null);
  const [employeeSubGroup, setEmployeeSubGroup] = useState("");
  const [grade, setGrade] = useState("");
  const [hiringManager, setHiringManager] = useState("");
  const [hiringManagerEmail, setHiringManagerEmail] = useState("");
  const [isValidHMEmail, setIsValidHMEMail] = useState(null);
  const [disableCompanyddl, setDisableCompanyddl] = useState(false);
  let [isDigitalLetter, setIsDigitalLetter] = useState(true);
  let [referenceNo, setReferenceNo] = useState("");
  let [division, setDivision] = useState("");
  let [contractLetterDetails, setContractLetterDetails] = useState({});

  //Error States and Msg
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorTextFirstName, setErrorTextFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorTextLastName, setErrorTextLastName] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState("");
  const [errorPostAppliedFor, setErrorPostAppliedFor] = useState(false);
  const [errorTextPostAppliedFor, setErrorTextPostAppliedFor] = useState("");
  const [errorMPRNumber, setErrorMPRNumber] = useState(false);
  const [errorTextMPRNumber, setErrorTextMPRNumber] = useState("");
  const [errorTextReferenceNo, setErrorTextReferenceNo] = useState("");
  const [errorTextDivision, setErrorTextDivision] = useState("");
  const [errorEmployeeType, setErrorEmployeeType] = useState(false);
  const [errorTextEmployeeType, setErrorTextEmployeeType] = useState("");
  const [errorCompany, setErrorCompany] = useState(false);
  const [errorTextCompany, setErrorTextCompany] = useState("");
  const [errorTextJoiningDate, setErrorTextJoiningDate] = useState("");
  const [errorHiringManager, setErrorHiringManager] = useState(false);
  const [errorTextHiringManager, setErrorTextHiringManager] = useState("");
  const [errorHiringManagerEmail, setErrorHiringManagerEmail] = useState(false);
  const [errorTextHiringManagerEmail, setErrorTextHiringManagerEmail] =
    useState("");

  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  //function dateChangeEvent(dateValue, label) {
  //  if (label === "Joining Date") {
  //    setJoiningDate(dateValue);
  //  }
  //}
    function dateChangeEvent(dateValue, label) {
        if (label === "Joining Date" && dateValue.toLocaleDateString() != "10/24/2022") {
            setJoiningDate(dateValue);
            setChangeJoiningDate(dateValue);
        }
        else {
            setChangeJoiningDate(dateValue);
            ///* dateValue = new Date(dateValue.setDate(dateValue.getDate() + 1)).toISOString();*/
            let date = new Date(dateValue);
            date = date.setDate(date.getDate() + 1);
            ///*joiningDate = date.setDate(date.getDate() + 1);*/
            setJoiningDate(date);


        }
    }

  function SetStateAsPerFetched(data) {
    setEmployeeIdEncrypted(data.employeeIdencrypted);
    setFirstName(data.firstName);
    setMiddleName(data.middleName);
    setLastName(data.lastName);
    setEmail(data.userEmail);
    setPostAppliedFor(data.postAppliedFor);
    setEmployeeType(data.employeeTypeId);
    if (data.employeeTypeObj[0].id > 0) {
      setEmployeeTypeObj(data.employeeTypeObj[0]);
      setEmployeeTypeText(data.employeeTypeObj[0].name);
    }
    setCompany(data.companyId);
    if (data.companyObj[0].id > 0) {
      setCompanyObj(data.companyObj[0]);
      setCompanyText(data.companyObj[0].name);
    }
    //getHiringDates(data.companyId);
      setJoiningDate(data.hiringDate);
      setChangeJoiningDate(data.hiringDate);
    //getEmployeeSubGroups(data.companyId);
    //setEmployeeSubGroup(data.subgroupId);
    setMPRNumber(data.mprnumber);
    setReferenceNo(data.referenceNo);
    setDivision(data.divisionName);
    //getGrades(data.subgroupId);
    setGrade(data.gradeId);
    setHiringManager(data.hiringManagerName);
    setHiringManagerEmail(data.hiringManagerEmail);
    setIsDigitalLetter(data.isDigitalLetter);
    setComments(data.comments);
    setButtonTextSubmit("REGISTER");
    setFormHeaderText("Update Candidate");

    setIsLoader(false);
  }

  async function GetCandidateDetailsById(encryptedId) {
    const token = props.token;
    //id = encryptedId.substr(22);
    id = encryptedId;
    await fetch("Employee/GetCandidateDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => console.log("Error:", error));
  }

  const changeEventFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const changeEventMiddleName = (event) => {
    setMiddleName(event.target.value);
  };
  const changeEventLastName = (event) => {
    setLastName(event.target.value);
  };
  const changeEventEmail = (event) => {
    setEmail(event.target.value);
  };
  const changeEventPostAppliedFor = (event) => {
    setPostAppliedFor(event.target.value);
  };
  const changeEventMPRNumber = (event) => {
    setMPRNumber(event.target.value);
  };
  const changeEventReferenceNumber = (event) => {
    setReferenceNo(event.target.value);
  };
  const changeEventDivision = (event) => {
    setDivision(event.target.value);
  };
  const changeEventHiringManager = (event) => {
    setHiringManager(event.target.value);
  };
  const changeEventHiringManagerEmail = (event) => {
    setHiringManagerEmail(event.target.value);
    if (event.target.value != "") {
      if (!IsValidEmail(event.target.value)) {
        setIsValidHMEMail(false);
      } else {
        setIsValidHMEMail(true);
      }
    } else {
      setIsValidHMEMail(null);
    }
  };
  const changeEventComments = (event) => {
    setComments(event.target.value);
  };
  const changeEventEmployeeType = (event, newValue) => {
    setEmployeeType(newValue ? newValue.id : 0);
    setEmployeeTypeObj(newValue);
  };
  const changeEventEmployeeTypeInput = (event, newValueInput) => {
    setEmployeeTypeText(newValueInput);
  };
    const changeEventCompany = (event, newValue) => {
        debugger;
        if (newValue.id != 5) {
            setIsDigitalLetter(false);
        }
    setCompany(newValue ? newValue.id : 0);
    setCompanyObj(newValue);
    //id = event.target.value;
    //getHiringDates(id);
    //getEmployeeSubGroups(id);
  };
  const changeEventCompanyInput = (event, newValueInput) => {
    setCompanyText(newValueInput);
  };
  const changeEventIsDigitalLetter = (event) => {
    setIsDigitalLetter(event.target.checked);
    };
    
  //const changeEventEmployeeSubGroup = (event) => {
  //    setEmployeeSubGroup(event.target.value);
  //    id = event.target.value;
  //    getGrades(id);
  //};
  //const changeEventEmployeeGrade = (event) => {
  //    setGrade(event.target.value);
  //};

  const handleClose = () => {
    setOpen(false);
    if (openType === "Success") {
      window.location.href = "/CandidateData";
    }
  };

  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

    const clickEventRegisterCandidate = () => {
        setIsLoader(true);
        setJoiningDate(null);
        setJoiningDate(changeJoiningDate);
    const formData = {
      EmployeeIdencrypted: employeeIdEncrypted,
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      UserEmail: email,
      PostAppliedFor: postAppliedFor,
      EmployeeTypeId: employeeType,
      CompanyId: company,
      Mprnumber: MPRNumber,
      ReferenceNo: referenceNo,
      DivisionName: division,
        HiringDate: changeJoiningDate,
      SubgroupId: employeeSubGroup,
      GradeId: grade,
      HiringManagerName: hiringManager,
        HiringManagerEmail: hiringManagerEmail,
        IsDigitalLetter: isDigitalLetter,
        Comments: comments,
        UserIdencrypted: props.userEncryptedId,
    };
    if (ValidateFormFields(formData)) {
      var candidateData = JSON.stringify(formData);
      RegisterCandidate(candidateData);
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("bg-red clr-white");
      setOpenType("Alert");
    }
    //return (
    //    <CandidateData />
    //);
  };
  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false,
      e = false,
      f = false,
      g = false,
      h = false,
      i = false,
      j = false,
      k = false,
      l = false;
    if (data.FirstName == null || data.FirstName == "") {
      setErrorFirstName(true);
      setErrorTextFirstName("Mandatory field");
      a = true;
    } else {
      setErrorFirstName(false);
      setErrorTextFirstName("");
      a = false;
    }
    if (data.LastName == null || data.LastName == "") {
      setErrorLastName(true);
      setErrorTextLastName("Mandatory field");
      b = true;
    } else {
      setErrorLastName(false);
      setErrorTextLastName("");
      b = false;
    }
    if (data.UserEmail == null || data.UserEmail == "") {
      setErrorEmail(true);
      setErrorTextEmail("Mandatory field");
      c = true;
    } else {
      if (!IsValidEmail(data.UserEmail)) {
        setErrorEmail(true);
        setErrorTextEmail("Email format invalid");
        c = true;
      } else {
        setErrorEmail(false);
        setErrorTextEmail("");
        c = false;
      }
    }
    if (data.Mprnumber == null || data.Mprnumber == "") {
      setErrorMPRNumber(true);
      setErrorTextMPRNumber("Mandatory field");
      d = true;
    } else {
      setErrorMPRNumber(false);
      setErrorTextMPRNumber("");
      d = false;
    }
    if (data.PostAppliedFor == null || data.PostAppliedFor == "") {
      setErrorPostAppliedFor(true);
      setErrorTextPostAppliedFor("Mandatory field");
      e = true;
    } else {
      setErrorPostAppliedFor(false);
      setErrorTextPostAppliedFor("");
      e = false;
    }
    if (data.EmployeeTypeId == null || data.EmployeeTypeId == "") {
      setErrorEmployeeType(true);
      setErrorTextEmployeeType("Mandatory field");
      f = true;
    } else {
      setErrorEmployeeType(false);
      setErrorTextEmployeeType("");
      f = false;
    }
    if (data.CompanyId == null || data.CompanyId == "") {
      setErrorCompany(true);
      setErrorTextCompany("Mandatory field");
      g = true;
    } else {
      setErrorCompany(false);
      setErrorTextCompany("");
      g = false;
    }
    if (data.HiringDate == null || data.HiringDate == "") {
      setErrorTextJoiningDate("Mandatory field");
      h = true;
    } else {
      setErrorTextJoiningDate("");
      h = false;
    }
    if (data.HiringManagerName == null || data.HiringManagerName == "") {
      setErrorHiringManager(true);
      setErrorTextHiringManager("Mandatory field");
      i = true;
    } else {
      setErrorHiringManager(false);
      setErrorTextHiringManager("");
      i = false;
    }
    if (data.HiringManagerEmail == null || data.HiringManagerEmail == "") {
      setErrorHiringManagerEmail(true);
      setErrorTextHiringManagerEmail("Mandatory field");
      j = true;
    } else {
      if (!IsValidEmail(data.HiringManagerEmail)) {
        setErrorHiringManagerEmail(true);
        setErrorTextHiringManagerEmail("Email format invalid");
        j = true;
      } else {
        setErrorHiringManagerEmail(false);
        setErrorTextHiringManagerEmail("");
        j = false;
      }
    }

    if (data.ReferenceNo == null || data.ReferenceNo == "") {
      setErrorTextReferenceNo("Mandatory field");
      k = true;
    } else {
      setErrorTextReferenceNo("");
      k = false;
    }
    if (!data.DivisionName) {
      setErrorTextDivision("Mandatory field");
      k = true;
    } else {
      setErrorTextDivision("");
      k = false;
    }
    if (a || b || c || d || e || f || g || h || i || j || k || l) {
      return false;
    } else {
      return true;
    }
  }
  async function RegisterCandidate(candidateData) {
    if (buttonTextSubmit == "Register") {
      const token = props.token;
      const options = {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: candidateData,
      };
      await fetch("Employee/RegisterCandidate/", options)
        .then((response) => response.json())
        .then((data) => {
          if (data.response) {
            setIsLoader(false);
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-light-green clr-white");
            setOpenType("Success");
            //alert("Candidate has been registered successfully.");
            //window.location.href = '/CandidateData';
          } else {
            setIsLoader(false);
            setContractLetterDetails(data?.contractLetterDetails);
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-light-green clr-white");
            setOpenType("Alert");
            //alert("Candidate registration failed.");
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.log("Error:", error);
        });
    } else if (buttonTextSubmit =="REGISTER") {
      const token = props.token;
      const options = {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: candidateData,
      };
      await fetch("Employee/UpdateCandidateDetails/", options)
        .then((response) => response.json())
        .then((data) => {
          if (data.response) {
            setIsLoader(false);
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-light-green clr-white");
            setOpenType("Success");
            //alert("Candidate data has been updated successfully.");
            //window.location.href = '/CandidateData';
          } else {
            setIsLoader(false);
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-light-green clr-white");
            setOpenType("Alert");
            //alert("Candidate data update failed.");
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.log("Error:", error);
        });
    }
  }

  function clearStates() {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setPostAppliedFor("");
    setEmployeeType("");
    setEmployeeTypeObj("");
    setEmployeeTypeText("");
    setCompany("");
    setCompanyObj("");
    setCompanyText("");
    setMPRNumber("");
    setJoiningDate(null);
    setEmployeeSubGroup("");
    setGrade("");
    setHiringManager("");
    setHiringManagerEmail("");
    setComments("");
  }

  const clickEventClearData = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("bg-light-green clr-white");
    setOpenType("Confirmation");
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //fetch EmployeeTypes
  const [itemsEmployeeType, setItemsEmployeeType] = useState([]);
  async function getEmployeeTypes() {
    const token = props.token;
    const response = await fetch("Employee/GetAllEmployeeTypes", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsEmployeeType(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsEmployeeType(jsonItems);
  }
  //fetch Companies
  const [itemsCompany, setItemsCompany] = useState([]);
  //async function getCompanies() {
  //  const token = props.token;
  //  const response = await fetch("Employee/GetAllCompanies", {
  //    headers: { Authorization: "Bearer " + token },
  //  });
  //  const jsonItems = await response.json();
  //  //setItemsCompany(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
  //  setItemsCompany(jsonItems);
  //}

  async function getCompanies(id) {
    const token = props.token;
    const response = await fetch(
      "Employee/GetAllCompaniesForCandidateARE/" + id,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    const jsonItems = await response.json();
    //setItemsCompany(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsCompany(jsonItems);
  }

  //fetch HiringDates
  const [itemsHiringDate, setItemsHiringDate] = useState([]);
  async function getHiringDates(id) {
    const response = await fetch("Employee/GetAllEmployeeHiringDates/" + id);
    const jsonItems = await response.json();
    setItemsHiringDate(
      jsonItems.map(({ id, date }) => ({ label: date, value: id }))
    );
  }
  //fetch EmployeeSubgroups
  const [itemsEmployeeSubGroup, setItemsEmployeeSubGroup] = useState([]);
  async function getEmployeeSubGroups(id) {
    const response = await fetch("Employee/GetAllEmployeeSubGroups/" + id);
    const jsonItems = await response.json();
    setItemsEmployeeSubGroup(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }
  //fetch Grades
  const [itemsGrade, setItemsGrade] = useState([]);
  async function getGrades(id) {
    const response = await fetch("Employee/GetAllEmployeeGrades/" + id);
    const jsonItems = await response.json();
    setItemsGrade(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }
  function AREWiseSetCompanyState(are, role) {
    if (role == 2 || role == 4) {
      if (are != 9) {
        setCompany(are);
        getCompanyObjById(are).then((data) => {
          if (data) {
            setCompanyObj(data);
            setCompanyText(data.name);
          }
        });
        setDisableCompanyddl(true);
      } else {
        setDisableCompanyddl(false);
      }
    } else if (role == 1) {
      setDisableCompanyddl(false);
    }
  }
  async function getCompanyObjById(id) {
    const token = props.token;
    return await fetch("Employee/GetAllCompanies", {
      headers: { Authorization: "Bearer " + token },
    }).then((response) => {
      return response
        .json()
        .then((data) => {
          return data.find((c) => c.id === id);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    //const jsonItems = await response.json();
    ////setItemsCompany(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    //const jsonItemsFiltered = await jsonItems.find(c => c.id === id);
    } 
  useEffect(() => {
    setIsLoader(true);

    if (props.token) {
        getEmployeeTypes();
        getCompanies(props.userEncryptedId);
      //getCompanies();
      //getHiringDates(id);
      //getEmployeeSubGroups(id);
      //getGrades(id);
      if (
        encryptedId != undefined &&
        encryptedId != "" &&
        encryptedId != null
      ) {
        GetCandidateDetailsById(encryptedId);
      } else {
        setIsLoader(false);
      }
    } else {
      setIsLoader(false);
    }

    if (props.roleId && props.AREId) {
      AREWiseSetCompanyState(props.AREId, props.roleId);
    }
  }, [encryptedId, props.token, props.AREId, props.roleId]);

  return (
    <ThemeProvider theme={theme}>
      <div className="master-layout-block">
        <Box paddingTop={{ xs: "5rem", sm: "4rem" }}>
          <h3 className="clr-dark-blue d-flex jc-center">{formHeaderText}</h3>
        </Box>
        <form className="d-flex jc-center">
          <Grid
            container
            item
            xs={12}
            spacing={1}
            className="d-flex fd-column box-shadow px-2 mb-2 bdr-radius "
          >
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid item xs={12} sm={4} className="d-flex  Width-100">
                <TextField
                  className="mt-1 lightInputFields"
                  id="firstName"
                  label="First Name"
                  variant="filled"
                  fullWidth
                  name="First Name"
                  value={firstName}
                  onChange={changeEventFirstName}
                  required
                  error={errorFirstName}
                  helperText={errorTextFirstName}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex  Width-100">
                <TextField
                  className="mt-1 lightInputFields"
                  id="middleName"
                  label="Middle Name"
                  variant="filled"
                  fullWidth
                  name="Middle Name"
                  value={middleName}
                  onChange={changeEventMiddleName}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="lastName"
                  fullWidth
                  label="Last Name"
                  variant="filled"
                  name="Last Name"
                  value={lastName}
                  onChange={changeEventLastName}
                  required
                  error={errorLastName}
                  helperText={errorTextLastName}
                />
              </Grid>

              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="email"
                  label="Email"
                  variant="filled"
                  fullWidth
                  name="Email"
                  value={email}
                  onChange={changeEventEmail}
                  required
                  error={errorEmail}
                  helperText={errorTextEmail}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="postApplied"
                  label="Post Applied For"
                  variant="filled"
                  fullWidth
                  name="Post Applied For"
                  value={postAppliedFor}
                  onChange={changeEventPostAppliedFor}
                  required
                  error={errorPostAppliedFor}
                  helperText={errorTextPostAppliedFor}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label">Employee Type</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="ddlEmployeeType"
                  //        value={employeeType}
                  //        onChange={changeEventEmployeeType}
                  //    >
                  //        {itemsEmployeeType.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={employeeTypeObj}
                  inputValue={employeeTypeText}
                  options={itemsEmployeeType}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={changeEventEmployeeType}
                  onInputChange={changeEventEmployeeTypeInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee Type"
                      variant="filled"
                      required
                      error={errorEmployeeType}
                      helperText={errorTextEmployeeType}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={2} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="mprNumber"
                  label="Avature JOB ID"
                  variant="filled"
                  fullWidth
                  name="MPR Number"
                  value={MPRNumber}
                  onChange={changeEventMPRNumber}
                  required
                  error={errorMPRNumber}
                  helperText={errorTextMPRNumber}
                />
              </Grid>
                          <Grid item xs={12} sm={2} className="d-flex Width-100">
                              <div className="lightInputFieldsDivv">
                <TextField
                  className="lightInputFields mt-1"
                  id="referrenceNo"
                  label="Reference/Personal Id"
                  variant="filled"
                  fullWidth
                  name="Reference Number"
                  value={referenceNo}
                  onChange={changeEventReferenceNumber}
                  required
                  error={errorTextReferenceNo}
                  helperText={errorTextReferenceNo}
                                  />
                                  </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                className="d-flex mt-1 jc-center Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-filled-label">Company</InputLabel>
                  //    <Select
                  //        labelId="demo-simple-select-filled-label"
                  //        id="ddlCompany"
                  //        value={company}
                  //        onChange={changeEventCompany}
                  //    >
                  //        {itemsCompany.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={companyObj}
                  inputValue={companyText}
                  options={itemsCompany}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={changeEventCompany}
                  onInputChange={changeEventCompanyInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      variant="filled"
                      required
                      error={errorCompany}
                      helperText={errorTextCompany}
                    />
                  )}
                  disabled={disableCompanyddl}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="division"
                  label="Division"
                  variant="filled"
                  fullWidth
                  name="division"
                  value={division}
                  onChange={changeEventDivision}
                  required
                  error={errorTextDivision}
                  helperText={errorTextDivision}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel id="demo-simple-select-label">Joining Date</InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="ddlJoiningDate"
                  //        value={joiningDate}
                  //        onChange={changeEventJoiningDate}
                  //    >
                  //        {itemsHiringDate.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                              <FormDatePicker
                                  placeholder="Enter Tentative Joining Date"
                                  label="Tentative Joining Date"
                                  value={changeJoiningDate}
                                  onChange={dateChangeEvent}
                                  disablefuturedate={false}
                                  disablepastdate={true}
                                  disableBefore={"01-Sep-2021"}
                                  /*isSpecificDateDisable=*/
                                      /*(props.roleId && props.roleId == 1 ? false : true) */
                                 
                              
                                
                  required={true}
                  helperText={errorTextJoiningDate}
                              />
                              
                            
              </Grid>

              {/*<Grid item xs={12} sm={4} className="d-flex jc-center mt-1 Width-100">
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Employee Sub Group</InputLabel>
                                    <Select className="lightInputFields"
                                        labelId="demo-simple-select-label"
                                        id="ddlSubGroup"
                                        value={employeeSubGroup}
                                        onChange={changeEventEmployeeSubGroup}
                                    >
                                        {itemsEmployeeSubGroup.map(item => (
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex mt-1 jc-center Width-100">
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-filled-label">Grade</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={grade}
                                        onChange={changeEventEmployeeGrade}
                                    >
                                        {itemsGrade.map(item => (
                                            <MenuItem
                                                key={item.value}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>*/}
              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="hiringManager"
                  label="Hiring Manager"
                  variant="filled"
                  fullWidth
                  name="hiringManager"
                  value={hiringManager}
                  onChange={changeEventHiringManager}
                  required
                  error={errorHiringManager}
                  helperText={errorTextHiringManager}
                />
              </Grid>

              <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="hiringManagerEmail"
                  label="Hiring Manager Email"
                  variant="filled"
                  fullWidth
                  name="hringManagerEmail"
                  value={hiringManagerEmail}
                  onChange={changeEventHiringManagerEmail}
                  required
                  error={errorHiringManagerEmail}
                  helperText={errorTextHiringManagerEmail}
                />
                {isValidHMEmail != null ? (
                  isValidHMEmail ? (
                    <React.Fragment>
                      <Tooltip title="Valid" placement="top">
                        <CheckCircleOutlineOutlinedIcon
                          style={{
                            fontSize: 20,
                            marginRight: "-20px",
                            marginTop: "2.5rem",
                            color: "#6cc070",
                          }}
                        />
                      </Tooltip>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Tooltip title="Invalid Email Format" placement="top">
                        <ErrorOutlineOutlinedIcon
                          style={{
                            fontSize: 20,
                            marginRight: "-20px",
                            marginTop: "2.5rem",
                            color: "red",
                          }}
                        />
                      </Tooltip>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid>
              <Grid item xs={12} sm={5} className="d-flex jc-center Width-100">
                <TextareaAutosize
                  className="lightInputFields cst-textarea mt-1"
                  id="comments"
                  rowsMin={3}
                  placeholder="Comments"
                  value={comments}
                  onChange={changeEventComments}
                />
              </Grid>
                          {
                              company == 5 ?
                                  <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
                                      <h5>
                                          <Checkbox
                                              checked={isDigitalLetter}
                                              onChange={changeEventIsDigitalLetter}
                                          />
                                          <i>
                                              Please tick the checkbox if you want to go with online
                                              contract letters to candidate
                                          </i>
                                      </h5>
                                  </Grid> : <></>
                          }
                         
            </Grid>
            <div className="d-flex mb-2 jc-center">
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2"
                onClick={clickEventClearData}
              >
                <span>Clear</span>
              </Button>
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2 ml-2"
                onClick={clickEventRegisterCandidate}
              >
                <span>{buttonTextSubmit}</span>
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                  {openAlert?.includes(
                    "Email already registered with another user."
                  ) ? (
                    <>
                      {contractLetterDetails ? (
                        <>
                          <h5>Digital Contract Letter Details:</h5>
                          Letter Workflow Status :{" "}
                          <b>{contractLetterDetails?.digiLetterStatus}</b>
                          <ul>
                            <li>
                              Compensation Letter Details --
                              <ul>
                                <li>
                                  Acceptance Status -{" "}
                                  <b>
                                    {contractLetterDetails?.isCompensationAccepted
                                      ? "Accepted On : " +
                                        moment(
                                          contractLetterDetails?.compensationAcceptedOn
                                        ).format("DD-MMM-YYYY hh:mm a")
                                      : "Compensation acceptance pending"}
                                  </b>
                                </li>
                                <li>
                                  Rejection Status -{" "}
                                  <b>
                                    {contractLetterDetails?.isCompensationRejected ? (
                                      <ul>
                                        <li>
                                          {"Rejected On : " +
                                            moment(
                                              contractLetterDetails?.compensationAcceptedOn
                                            ).format("DD-MMM-YYYY hh:mm a")}
                                        </li>
                                        <li>
                                          {"Reason : " +
                                            contractLetterDetails?.compensationRejectionComments}
                                        </li>
                                      </ul>
                                    ) : (
                                      "No rejection happened."
                                    )}
                                  </b>
                                </li>
                              </ul>
                            </li>
                            <li>
                              Offer Letter Details --
                              <ul>
                                <li>
                                  Acceptance Status -{" "}
                                  <b>
                                    {contractLetterDetails?.isOfferAccepted
                                      ? "Accepted On : " +
                                        moment(
                                          contractLetterDetails?.offerAcceptedOn
                                        ).format("DD-MMM-YYYY hh:mm a")
                                      : "Offer acceptance pending."}
                                  </b>
                                </li>
                                <li>
                                  Rejection Status -{" "}
                                  <b>
                                    {contractLetterDetails?.isOfferRejected ? (
                                      <ul>
                                        <li>
                                          {"Rejected On : " +
                                            moment(
                                              contractLetterDetails?.offerAcceptedOn
                                            ).format("DD-MMM-YYYY hh:mm a")}
                                        </li>
                                        <li>
                                          {"Reason : " +
                                            contractLetterDetails?.offerRejectionComments}
                                        </li>
                                      </ul>
                                    ) : (
                                      "No rejection happened."
                                    )}
                                  </b>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <p>No digital contract letter details found.</p>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light mr-15px"
                >
                  <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold clr-white">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
