import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
    flexDirection: "column",
  },
}));

export default function Loader(props) {
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      aria-labelledby="draggable-dialog-title"
      id="appLoader"
      BackdropProps={{
        style: { opacity: ".75" },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          borderColor: "transparent",
        },
      }}
    >
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          //color: "#2ee6e2 !important",
        }}
      >
        <CircularProgress style={{ color: "#2ee6e2" }} />
        <h5
          style={{
            fontStyle: "italic",
            color: "#2ee6e2",
          }}
        >
          {props.loaderText ? props.loaderText : "Loading...."}
        </h5>
      </DialogContent>
    </Dialog>
  );
}
