import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonalData from "./PreHiringComponents/PersonalData";
import CommunicationData from "./PreHiringComponents/CommunicationData";
import HiringInfo from "./PreHiringComponents/HiringInfo";
import Education from "./PreHiringComponents/Education";
import FlexiHoliday from "../FlexiHoliday/FlexiHoliday";

import ProfessionalTraining from "./PreHiringComponents/ProfessionalTraining";
import PreviousEmploymentHistory from "./PreHiringComponents/PreviousEmploymentHistory";
import Language from "./PreHiringComponents/Languages";
import Reference from "./PreHiringComponents/Reference";
import GeneralBackground from "./PreHiringComponents/GeneralBackground";
import Declaration from "./PreHiringComponents/Declaration";
import PersonalDataOnboarding from "./OnBoardingFormComponents/PersonalDataOnbarding";
import MedicalData from "./OnBoardingFormComponents/MedicalData";
import FamilyData from "./OnBoardingFormComponents/FamilyData";
import DependentData from "./OnBoardingFormComponents/DependentData";
import MediClaimEnrollment from "./OnBoardingFormComponents/MediClaimEnrollment";
import ProvidentFundNomination from "./OnBoardingFormComponents/ProvidentFundNomination";
import GratuityNomination from "./OnBoardingFormComponents/GratuityNomination";
import PensionScheme from "./OnBoardingFormComponents/PensionScheme";
import SuperAnnuationNomination from "./OnBoardingFormComponents/SuperAnnuationNomination";
import LifeInsuranceNomination from "./OnBoardingFormComponents/LifeInsuranceNomination";
import PersonalAccidentNomination from "./OnBoardingFormComponents/PersonalAccidentNomination";
import TaxRegime from "./OnBoardingFormComponents/TaxRegime";
import JobDetails from "./HRDetails/JobDetails";
import CompensationDetails from "./HRDetails/CompensationDetails";
import AssignOrganization from "./HRDetails/AssignOrg";
import MaintainEmployeeContract from "./HRDetails/MaintainEmployeeContract";
import EditServicesDate from "./HRDetails/EditServicesDate";
import AssignCollectiveAgreemment from "./HRDetails/AssignCollectiveAgreement";
import ManageProbationPeriod from "./HRDetails/ManageProbationPeriod";
import UploadSupportingDocuments from "./HRDetails/UploadSupportingDocuments";
import DetailsEnteredByOBTeam from "./HRDetails/DetailsEnteredByOBTeam";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RejectButton from "../components/RejectButton";
import Loader from "../../components/Loader";
import ContractLetters from "./ContractLetters/ContractLetters";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#E8F8F8",
                width: "100%",
            },
        },
        MuiDialogTitle: {
            root: {
                padding: "8px 24px",
            },
        },
        PrivateTabIndicator: {
            colorPrimary: {
                backgroundColor: "#00B3B3",
            },
        },
        PrivateSwitchBase: {
            root: {
                padding: "1px",
            },
        },
        MuiTabs: {
            scroller: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
            flexContainer: {
                justifyContent: "center",
            },
        },
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },

        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "10px",
                background: "#FFFFFF",
            },
            elevation4: {
                boxShadow: "0px 0px 0px 0px #fff !important",
            },
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#000028",
            },
        },
        MuiPickersBasePicker: {
            container: {
                background: "",
            },
            pickerView: {
                background: "#FFFFFF",
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: ""
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
    },
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value != index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value == index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: "4rem",
    },
}));

export default function ApplicationForm(props) {
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            //var sParameterName = sURLVariables[i].split("=");
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var value = "";
            //for (var j = 0; j < sParameterName.length; j++) {
            //  if (sParameterName[j] == sParam) {
            //    for (var k = j + 1; k < sParameterName.length; k++) {
            //      value = value + sParameterName[k];
            //    }
            //    break;
            //  }
            //  //return sParameterName[1];
            //  }

            if (!value) {

                continue;

            }

            else {

                break;

            }

        }
        return value;
    }

    var encryptedId = getURLParameter("id");
    var applicantId =
        encryptedId != undefined && encryptedId != null && encryptedId != ""
            ? encryptedId
            : props.applicantId;
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [valueDisabled, setValueDisabled] = useState(false);
    const [approveButtonDisable, setApproveButtonDisable] = useState(false);
    const [rejectButtonDisable, setRejectButtonDisable] = useState(false);
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
    const [approvalCommentsDisable, setApprovalCommentsDisable] = useState(false);
    const [rejectionCommentsDisable, setRejectionCommentsDisable] =
        useState(false);
    const [workflowActionId, setWorkflowActionId] = useState("");
    const [status, setStatus] = useState("");
    const [isApproved, setIsApproved] = useState(null);
    const [approvedBy, setApprovedBy] = useState("");
    const [approvedOn, setApprovedOn] = useState(null);
    const [approvalComments, setApprovalComments] = useState("");
    const [isRejected, setIsRejected] = useState(null);
    const [rejectedBy, setRejectedBy] = useState("");
    const [rejectedOn, setRejectedOn] = useState(null);
    const [disableHRAction, setDisableHRAction] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [applicantARE, setApplicantARE] = useState(false);
    const [applicantGID, setApplicantGID] = useState(false);

    const [rejectionComments, setRejectionComments] = useState("");
    const [staffSpecification, setStaffSpecification] = useState(null);

    const [open, setOpen] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    function setLoader(value) {
        setIsLoader(value);
    }

    var actionsDetails = {
        ApplicantId: applicantId,
        IsApproved: isApproved,
        ApprovalComments: approvalComments,
        IsRejected: isRejected,
        RejectionComments: rejectionComments,
        ApprovedBy: approvedBy,
        ApprovedOn: approvedOn,
        RejectedBy: rejectedBy,
        RejectedOn: rejectedOn,
        StatusId: status,
        UserId: props.userIdencrypted,
        UserIdencrypted: props.userIdencrypted
    };

    //Alerts
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        setIsLoader(true);
        setOpenConfirm(false);
        actionsDetails = {
            ApplicantId: applicantId,
            IsApproved: isApproved,
            ApprovalComments: approvalComments,
            IsRejected: isRejected,
            RejectionComments: rejectionComments,
            ApprovedBy: approvedBy,
            ApprovedOn: approvedOn,
            RejectedBy: rejectedBy,
            RejectedOn: rejectedOn,
            StatusId: status,
            UserId: props.userIdencrypted,
            UserIdencrypted: props.userIdencrypted
        };
        if (props.roleId == 1) {
            ApproveApplicationFormByOT(actionsDetails);
        } else if (props.roleId == 2 || props.roleId == 4) {
            ApproveApplicationFormByTA(actionsDetails);
        } else if (props.roleId == 3) {
            SubmitApplicationForm(actionsDetails);
        }
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleChangeAccord = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    var ids = {
        ApplicantId: applicantId,
        UserIdEncrypted: props.userIdencrypted,
    };

    const previousHandler = () => {
        var prevVal = value - 1;
        setValue(prevVal);
        setIsLoader(false);
    };
    const rejectHandler = (e) => {
        setIsLoader(true);
        if (rejectionComments != "" && rejectionComments != null) {
            actionsDetails = {
                ApplicantId: applicantId,
                IsApproved: isApproved,
                ApprovalComments: approvalComments,
                IsRejected: isRejected,
                RejectionComments: rejectionComments,
                ApprovedBy: approvedBy,
                ApprovedOn: approvedOn,
                RejectedBy: rejectedBy,
                RejectedOn: rejectedOn,
                StatusId: status,
                //UserId: props.userId   
                UserIdencrypted: props.userIdencrypted
            };

            if (props.roleId == 1) {
                RejectApplicationFormByOT(actionsDetails);
            } else if (props.roleId == 2 || props.roleId == 4) {
                RejectApplicationFormByTA(actionsDetails);
            }
        } else {
            e.preventDefault();
            setIsLoader(false);
            setOpen(true);
            setOpenAlert("Rejection Comments can not be blank.");
            setClassName("clr-white bg-alert");
            setOpenType("Alert");
        }
    };
    const approveHandler = (e) => {
        setIsLoader(true);
        if (approvalComments != "" && approvalComments != null) {
            const token = props.token;
            fetch("ApplicationForm/GetApplicationFormDataIds/" + applicantId, {
                headers: { Authorization: "Bearer " + token },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.response) {
                        setOpenConfirm(true);
                        setOpenAlertConfirm(data.responseMsg);
                        setClassName("clr-white bg-alert");
                        setOpenType("Confirmation");
                    } else {
                        setIsLoader(false);
                        setOpen(true);
                        if (data.responseMsg != null) {
                            setOpenAlert(data.responseMsg);
                        }
                        else {
                            setOpenAlert("Please fill Job details");
                        }

                        setClassName("clr-white bg-error");
                        setOpenType("Warning");
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    console.log("Error:", error);
                });
        } else {
            e.preventDefault();
            setIsLoader(false);
            setOpen(true);
            setOpenAlert("Approval Comments can not be blank.");
            setClassName("clr-white bg-alert");
            setOpenType("Alert");
        }
    };
    const submitHandler = (e) => {
        setIsLoader(true);
        const token = props.token;
        fetch("ApplicationForm/GetApplicationFormDataIdsCandidate/" + applicantId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpenConfirm(true);
                    setOpenAlertConfirm(data.responseMsg);
                    setClassName("clr-white bg-alert");
                    setOpenType("Confirmation");
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-white bg-error");
                    setOpenType("Warning");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    };

    const nextHandler = () => {
        var nextVal = value + 1;
        setValue(nextVal);
        setIsLoader(false);
    };

    const previewHandler = () => {
        window.open("/PreviewApplicationForm?id=" + applicantId, "_blank");
    };
    //Submit Application Form
    async function SubmitApplicationForm(actionData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(actionData),
        };
        await fetch("ApplicationForm/SubmitApplicationForm/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-white bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Approve Application Form By TA
    async function ApproveApplicationFormByTA(actionData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(actionData),
        };
        await fetch("ApplicationForm/ApproveApplicationFormByTA/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-white bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Reject Application Form By TA
    async function RejectApplicationFormByTA(actionData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(actionData),
        };
        await fetch("ApplicationForm/RejectApplicationFormByTA/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-white bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Approve Application Form By OT
    async function ApproveApplicationFormByOT(actionData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(actionData),
        };
        await fetch("ApplicationForm/ApproveApplicationFormByOT/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-white bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Reject Application Form By OT
    async function RejectApplicationFormByOT(actionData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(actionData),
        };
        await fetch("ApplicationForm/RejectApplicationFormByOT/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-white bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    function changeDisabledValue(id) {
        id == 2 || id == 4
            ? setValueDisabled(true)
            : id == 3
                ? status != null && status != undefined && status != "" && status != 3
                    ? setValueDisabled(true)
                    : setValueDisabled(false)
                : setValueDisabled(false);
    }
    function HRActionDisable(id) {
        if (id == 2 || id == 4) {
            status != null &&
                status != undefined &&
                status != "" &&
                status != 1 &&
                status != 4
                ? setDisableHRAction(true)
                : setDisableHRAction(false);
        } else if (id == 1) {
            status == null || status == undefined || status == "" || status == 4
                ? setDisableHRAction(true)
                : setDisableHRAction(false);
        }
    }
    function changeTab(id) {
        id == 2 || id == 4 ? setValue(2) : setValue(value);
    }
    function disableSubmitButton(id) {
        id != null && id != undefined && id != "" && id != 3
            ? setSubmitButtonDisable(true)
            : setSubmitButtonDisable(false);
    }
    function disableApproveButton(id) {
        if (props.roleId == 1) {
            id == null || id == undefined || id == "" || (id != 1 && id != 2)
                ? setApproveButtonDisable(true)
                : setApproveButtonDisable(false);
            id == null || id == undefined || id == "" || (id != 1 && id != 2)
                ? setApprovalCommentsDisable(true)
                : setApprovalCommentsDisable(false);
        } else if (props.roleId == 2 || props.roleId == 4) {
            id == null || (id != 1 && id != 4)
                ? setApproveButtonDisable(true)
                : setApproveButtonDisable(false);
            id == null || (id != 1 && id != 4)
                ? setApprovalCommentsDisable(true)
                : setApprovalCommentsDisable(false);
        }
    }
    function disableRejectButton(id) {
        if (props.roleId == 1) {
            id == null || (id != "" && id != 1 && id != 2)
                ? setRejectButtonDisable(true)
                : setRejectButtonDisable(false);
            id == null || (id != "" && id != 1 && id != 2)
                ? setRejectionCommentsDisable(true)
                : setRejectionCommentsDisable(false);
        } else if (props.roleId == 2 || props.roleId == 4) {
            id == null || (id != 1 && id != 4)
                ? setRejectButtonDisable(true)
                : setRejectButtonDisable(false);
            id == null || (id != 1 && id != 4)
                ? setRejectionCommentsDisable(true)
                : setRejectionCommentsDisable(false);
        }
    }

    //Set States
    function SetStateAsPerFetched(data) {
        setWorkflowActionId(data.workflowActionId);
        setStatus(data.statusId);
        setIsApproved(data.isApproved);
        setApprovedBy(data.approvedBy);
        setApprovedOn(data.approvedOn);
        setApprovalComments(data.approvalComments);
        setIsRejected(data.isRejected);
        setRejectedBy(data.rejectedBy);
        setRejectedOn(data.rejectedOn);
        setRejectionComments(data.rejectionComments);
        setIsLoader(false);
    }

    //Get Workflow Action Details on Page Load
    function getActionDetails(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("ApplicationForm/GetWorkflowActionDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Get Staff Specification on Page Load
    function getStaffSpecification(id) {
        const token = props.token;
        fetch("ApplicationForm/GetStaffSpecificationDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setStaffSpecification(data.staffSpecificationId);
            })
            .catch((error) => console.log("Error:", error));
    }

    //Get Applicant ARE Details On Page Load
    async function getApplicantAREDetails(id) {
        const token = props.token;
        await fetch("ApplicationForm/GetApplicantAREDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                setApplicantARE(data.companyId);
                //getCompanyObjById(data.companyId).then(data => {
                //    setCompanyAREObj(data);
                //    setCompanyAREText(data.name);
                //    getCostCenterDropdown(data.id);
                //});
            })
            .catch((error) => console.log("Error:", error));
    }



    const [stateA, setStateA] = useState("");
    const [hireDate, setHireDate] = useState(null);
    const [employeeTypeFromJD, setEmployeeTypeFromJD] = useState("");

    const callbackJobDetails = (callbackValues) => {
        setStateA(callbackValues.staffSelection);
        setHireDate(callbackValues.hireDate);
        setEmployeeTypeFromJD(callbackValues.employeeType);
    };

    const callbackDetailsByOB = (callbackValue) => {
        setStatus(callbackValue);
    };

    function setCompleted(value) {
        value && value == 7 ? setIsCompleted(true) : setIsCompleted(false);
    }
    let [letterWorkflowData, setLetterWorkflowData] = useState({});
    function setWorkflowDataState(data) {
        setLetterWorkflowData({
            ...letterWorkflowData,
            digiLetterWorkflowId: data?.digiLetterWorkflowId,
            applicantId: data?.applicantId,
            userId: props?.userId,
            isCompensationAccepted: data?.isCompensationAccepted,
            isCompensationRejected: data?.isCompensationRejected,
            compensationRejectionComments: data?.compensationRejectionComments,
            isOfferAccepted: data?.isOfferAccepted,
            isOfferRejected: data?.isOfferRejected,
            offerRejectionComments: data?.offerRejectionComments,
            digiLetterStatusId: data?.digiLetterStatusId,
            digiLetterStatus: data?.digiLetterStatus,
            isSuperannuation: data?.isSuperannuation ? data?.isSuperannuation : false,
        });
        setIsLoader(false);
    }
    function getDigiLetterWorkflow(id) {
        setIsLoader(true);
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigiLetterWorkflowDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    setWorkflowDataState(data);
                } else {
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
            });
    }
    let [isDigital, setIsDigital] = useState(false);
    function isDigitalLetter(id) {
        setIsLoader(true);
        const token = props.token;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/IsDigitalLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    setIsDigital(data);
                } else {
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log(error);
            });
    }
    useEffect(() => {
        if (props.token) {
            if (applicantId) {
                getActionDetails(applicantId);
                getStaffSpecification(applicantId);
                getApplicantAREDetails(applicantId);
                getDigiLetterWorkflow(applicantId);
                isDigitalLetter(applicantId);
            }

            //if (applicantId != null && applicantId != "" && applicantId != undefined) {
            //    GetUploadedImages(applicantId);
            //}
        }
    }, [applicantId, props.token]);

    useEffect(() => {
        changeDisabledValue(props.roleId);
        HRActionDisable(props.roleId);
        changeTab(props.roleId);
        disableApproveButton(status);
        disableRejectButton(status);
        disableSubmitButton(status);
        setCompleted(status);
    }, [props.roleId, status]);

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <AppBar className="my-nav" position="static">
                    <Tabs
                        indicatorColor="primary"
                        value={value}
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        className="my-nav"
                    >
                        {isDigital && props.roleId !== 2 ? (
                            <Tab
                                className="pl-10px"
                                label="Contract Letter"
                                value={0}
                                disabled={
                                    isDigital && props.roleId !== 2
                                }
                                {...a11yProps(0)}
                            />
                        ) : (
                            ""
                        )}
                        <Tab
                            className="pl-10px"
                            label="Pre-Hiring Form 1"
                            // {...a11yProps(props.roleId !== 2 ? 1 : 0)}
                            value={isDigital && props.roleId !== 2 ? 1 : 0}
                            {...a11yProps(isDigital && props.roleId !== 2 ? 1 : 0)}
                            disabled={
                                isDigital &&
                                props.roleId === 3 &&
                                !letterWorkflowData?.isCompensationAccepted
                            }
                        />
                        <Tab
                            label="Pre-Hiring Form 2"
                            // {...a11yProps(props.roleId !== 2 ? 2 : 1)}
                            {...a11yProps(isDigital && props.roleId !== 2 ? 2 : 1)}
                            value={isDigital && props.roleId !== 2 ? 2 : 1}
                            disabled={
                                isDigital &&
                                props.roleId === 3 &&
                                !letterWorkflowData?.isCompensationAccepted
                            }
                        />
                        {props.roleId !== 3 ? (
                            <Tab
                                label="HR Details"
                                // {...a11yProps(props.roleId !== 2 ? 3 : 2)}
                                {...a11yProps(isDigital && props.roleId !== 2 ? 3 : 2)}
                                value={isDigital && props.roleId !== 2 ? 3 : 2}
                            />
                        ) : (
                            ""
                        )}
                    </Tabs>
                </AppBar>

                {isDigital && props.roleId !== 2 ? <TabPanel value={value} index={0}>
                    <ContractLetters
                        token={props.token}
                        id={applicantId}
                        userId={props.userId}
                        roleId={props.roleId}
                        loginDetail={[props.loginDetails]}
                        disableValue={isCompleted ? isCompleted : valueDisabled}
                        getDigiLetterWorkflow={getDigiLetterWorkflow}
                        isDigital={isDigital}
                    />
                    <Grid className="d-flex jc-center">
                        <Button
                            xs={12}
                            sm={6}
                            className="ns-btn-primary mt-2"
                            onClick={nextHandler}
                            disabled={
                                props.roleId === 3 &&
                                !letterWorkflowData?.isCompensationAccepted
                            }
                        >
                            <span>Next</span>
                        </Button>
                    </Grid>
                </TabPanel>
                    : <></>}
                <TabPanel value={value} index={isDigital && props.roleId !== 2 ? 1 : 0}>
                    <HiringInfo
                        status={status}
                        loaderForm={setLoader.bind(this)}
                        token={props.token}
                        id={applicantId}
                        disableValue={isCompleted ? isCompleted : valueDisabled}
                        userId={props.userIdencrypted}
                        roleId={props.roleId}
                        disableOnceCompleted={isCompleted}
                    />
                    <Accordion
                        expanded={expanded == "panel1"}
                        onChange={handleChangeAccord("panel1")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Personal Data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PersonalData
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel2"}
                        onChange={handleChangeAccord("panel2")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>
                                Communication Data
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CommunicationData
                                status={status}
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                                roleId={props.roleId}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel3"}
                        onChange={handleChangeAccord("panel3")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className={classes.heading}>Education</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Education
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded={expanded == 'panel4'} onChange={handleChangeAccord('panel4')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className={classes.heading}>Professional Training</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProfessionalTraining loaderForm={setLoader.bind(this)} token={props.token} loginDetail={[props.loginDetails]} id={applicantId} userId={props.userId} disableValue={valueDisabled} />

                        </AccordionDetails>
                    </Accordion> */}
                    <Accordion
                        expanded={expanded == "panel5"}
                        onChange={handleChangeAccord("panel5")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography className={classes.heading}>
                                Previous Employment History
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PreviousEmploymentHistory
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded={expanded == 'panel6'} onChange={handleChangeAccord('panel6')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6a-content"
                            id="panel6a-header"
                        >
                            <Typography className={classes.heading}>Languages</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Language loaderForm={setLoader.bind(this)} token={props.token} loginDetail={[props.loginDetails]} id={applicantId} userId={props.userId} disableValue={valueDisabled} />

                        </AccordionDetails>
                    </Accordion> */}
                    {/*<Accordion expanded={expanded == 'panel7'} onChange={handleChangeAccord('panel7')}>*/}
                    {/*    <AccordionSummary*/}
                    {/*        expandIcon={<ExpandMoreIcon />}*/}
                    {/*        aria-controls="panel7a-content"*/}
                    {/*        id="panel7a-header"*/}
                    {/*    >*/}
                    {/*        <Typography className={classes.heading}>Reference</Typography>*/}
                    {/*    </AccordionSummary>*/}
                    {/*    <AccordionDetails>*/}
                    {/*        <Reference loaderForm={setLoader.bind(this)} loginDetail={[props.loginDetails]} id={applicantId} userId={props.userIdencrypted} disableValue={valueDisabled} />*/}

                    {/*    </AccordionDetails>*/}
                    {/*</Accordion>*/}
                    <Accordion
                        expanded={expanded == "panel7"}
                        onChange={handleChangeAccord("panel7")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7a-content"
                            id="panel7a-header"
                        >
                            <Typography className={classes.heading}>
                                General Background
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GeneralBackground
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel8"}
                        onChange={handleChangeAccord("panel8")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel8a-content"
                            id="panel8a-header"
                        >
                            <Typography className={classes.heading}>Declaration</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Declaration
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <div className="d-flex jc-center">
                        {isDigital && props.roleId !== 2 ? (
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2"
                                onClick={previousHandler}
                            >
                                <span>Previous</span>
                            </Button>
                        ) : (
                            ""
                        )}
                        <Button
                            item
                            xs={12}
                            sm={6}
                            className="ns-btn-primary mt-2"
                            onClick={nextHandler}
                        >
                            <span>Next</span>
                        </Button>
                        {/*<Button item xs={12} sm={6} className="ns-btn-primary ml-1 mt-2" disabled={valueDisabled} onClick={previewHandler}>
                            <span>Print</span>
                        </Button>*/}
                    </div>
                </TabPanel>

                <TabPanel value={value} index={isDigital && props.roleId !== 2 ? 2 : 1}>
                    <PersonalDataOnboarding
                        loaderForm={setLoader.bind(this)}
                        token={props.token}
                        id={applicantId}
                        disableValue={isCompleted ? isCompleted : valueDisabled}
                    />
                    <Accordion
                        expanded={expanded == "panel9"}
                        onChange={handleChangeAccord("panel9")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel9a-content"
                            id="panel9a-header"
                        >
                            <Typography className={classes.heading}>Medical Data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MedicalData
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {/*<Accordion
            expanded={expanded == "panel10"}
            onChange={handleChangeAccord("panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10a-content"
              id="panel10a-header"
            >
              <Typography className={classes.heading}>Family Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FamilyData
                loaderForm={setLoader.bind(this)}
                loginDetail={[props.loginDetails]}
                token={props.token}
                id={applicantId}
                userId={props.userId}
                disableValue={isCompleted ? isCompleted : valueDisabled}
              />
            </AccordionDetails>
          </Accordion> */}
                    {/* <Accordion expanded={expanded == 'panel11'} onChange={handleChangeAccord('panel11')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel11a-content"
                            id="panel11a-header"
                        >
                            <Typography className={classes.heading}>MediClaim Enrollment</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MediClaimEnrollment loginDetail={[props.loginDetails]} id={applicantId} userId={props.userId} disableValue={valueDisabled} />

                        </AccordionDetails>
                    </Accordion> */}

                    <Accordion
                        expanded={expanded == "panel17"}
                        onChange={handleChangeAccord("panel17")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel17a-content"
                            id="panel17a-header"
                        >
                            <Typography className={classes.heading}>
                                Dependent Details
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DependentData
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>

                    {/*<Accordion*/}
                    {/*    expanded={expanded == "panel11"}*/}
                    {/*    onChange={handleChangeAccord("panel11")}*/}
                    {/*    TransitionProps={{ unmountOnExit: true }}*/}
                    {/*>*/}
                    {/*    <AccordionSummary*/}
                    {/*        expandIcon={<ExpandMoreIcon />}*/}
                    {/*        aria-controls="panel11a-content"*/}
                    {/*        id="panel11a-header"*/}
                    {/*    >*/}
                    {/*        <Typography className={classes.heading}>*/}
                    {/*            Provident Fund Nomination*/}
                    {/*        </Typography>*/}
                    {/*    </AccordionSummary>*/}
                    {/*    <AccordionDetails>*/}
                    {/*        <ProvidentFundNomination*/}
                    {/*            loaderForm={setLoader.bind(this)}*/}
                    {/*            loginDetail={[props.loginDetails]}*/}
                    {/*            token={props.token}*/}
                    {/*            id={applicantId}*/}
                    {/*            userId={props.userIdencrypted}*/}
                    {/*            disableValue={isCompleted ? isCompleted : valueDisabled}*/}
                    {/*        />*/}
                    {/*    </AccordionDetails>*/}
                    {/*</Accordion>*/}
                    {/*<Accordion*/}
                    {/*    expanded={expanded == "panel12"}*/}
                    {/*    onChange={handleChangeAccord("panel12")}*/}
                    {/*    TransitionProps={{ unmountOnExit: true }}*/}
                    {/*>*/}
                    {/*    <AccordionSummary*/}
                    {/*        expandIcon={<ExpandMoreIcon />}*/}
                    {/*        aria-controls="panel12a-content"*/}
                    {/*        id="panel12a-header"*/}
                    {/*    >*/}
                    {/*        <Typography className={classes.heading}>*/}
                    {/*            Pension Scheme Nomination*/}
                    {/*        </Typography>*/}
                    {/*    </AccordionSummary>*/}
                    {/*    <AccordionDetails>*/}
                    {/*        <PensionScheme*/}
                    {/*            loaderForm={setLoader.bind(this)}*/}
                    {/*            loginDetail={[props.loginDetails]}*/}
                    {/*            token={props.token}*/}
                    {/*            id={applicantId}*/}
                    {/*            userId={props.userIdencrypted}*/}
                    {/*            disableValue={isCompleted ? isCompleted : valueDisabled}*/}
                    {/*        />*/}
                    {/*    </AccordionDetails>*/}
                    {/*</Accordion>*/}
                    <Accordion
                        expanded={expanded == "panel13"}
                        onChange={handleChangeAccord("panel13")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel13a-content"
                            id="panel13a-header"
                        >
                            <Typography className={classes.heading}>
                                Gratuity Nomination
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GratuityNomination
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel14"}
                        onChange={handleChangeAccord("panel14")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel14a-content"
                            id="panel14a-header"
                        >
                            <Typography className={classes.heading}>
                                SuperAnnuation Nomination
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SuperAnnuationNomination
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel15"}
                        onChange={handleChangeAccord("panel15")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel15a-content"
                            id="panel15a-header"
                        >
                            <Typography className={classes.heading}>
                                Group Term Life Insurance Nomination
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <LifeInsuranceNomination
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel16"}
                        onChange={handleChangeAccord("panel16")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel16a-content"
                            id="panel16a-header"
                        >
                            <Typography className={classes.heading}>
                                Group Personal Accident Nomination
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PersonalAccidentNomination
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableValue={isCompleted ? isCompleted : valueDisabled}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <TaxRegime
                        loaderForm={setLoader.bind(this)}
                        loginDetail={[props.loginDetails]}
                        token={props.token}
                        id={applicantId}
                        userId={props.userIdencrypted}
                        disableValue={isCompleted ? isCompleted : valueDisabled}
                    />
                    <div className="d-flex jc-center">
                        <Button
                            xs={12}
                            sm={4}
                            className="ns-btn-primary mt-2"
                            onClick={previousHandler}
                        >
                            <span>Previous</span>
                        </Button>
                        {props.roleId == 3 ? (
                            <Button
                                xs={12}
                                sm={4}
                                className="ns-btn-primary ml-1 mt-2"
                                onClick={previewHandler}
                            >
                                <span>Form Preview</span>
                            </Button>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        {props.roleId == 3 ? (
                            <Button
                                xs={12}
                                sm={4}
                                className="ns-btn-primary ml-1 mt-2"
                                disabled={isCompleted ? isCompleted : submitButtonDisable}
                                onClick={submitHandler}
                            >
                                <span>Submit</span>
                            </Button>
                        ) : (
                            <Button
                                xs={12}
                                sm={4}
                                className="ns-btn-primary ml-1 mt-2"
                                onClick={nextHandler}
                            >
                                <span>Next</span>
                            </Button>
                        )}
                    </div>
                </TabPanel>

                <TabPanel value={value} index={isDigital && props.roleId !== 2 ? 3 : 2}>
                    {/*<div className="mb-1 d-flex jc-flex-end mr-2 media-jc-center">*/}
                    {/*    <button className="ns-btn-primary" onClick={handleDownloadDocuments}>Download PJQ Form</button>*/}
                    {/*</div>*/}
                    <Accordion
                        expanded={expanded == "panel18"}
                        onChange={handleChangeAccord("panel18")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel18a-content"
                            id="panel18a-header"
                        >
                            <Typography className={classes.heading}>Job Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <JobDetails
                                loaderForm={setLoader.bind(this)}
                                roleId={props.roleId}
                                disableHRAction={disableHRAction}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                callback={callbackJobDetails}
                                disableOnceCompleted={isCompleted}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded == "panel19"}
                        onChange={handleChangeAccord("panel19")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel19a-content"
                            id="panel19a-header"
                        >
                            <Typography className={classes.heading}>
                                Compensation Details
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CompensationDetails
                                loaderForm={setLoader.bind(this)}
                                roleId={props.roleId}
                                disableHRAction={disableHRAction}
                                token={props.token}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableOnceCompleted={isCompleted}
                                empType={employeeTypeFromJD}
                                staffId={stateA}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded == "panel20"}
                        onChange={handleChangeAccord("panel20")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel20a-content"
                            id="panel20a-header"
                        >
                            <Typography className={classes.heading}>
                                Change Org Assignment
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AssignOrganization
                                loaderForm={setLoader.bind(this)}
                                loginDetail={[props.loginDetails]}
                                disableHRAction={disableHRAction}
                                token={props.token}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableOnceCompleted={isCompleted}
                                ApplicantARE={applicantARE}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded == "panel21"}
                        onChange={handleChangeAccord("panel21")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel21a-content"
                            id="panel21a-header"
                        >
                            <Typography className={classes.heading}>
                                Maintain Employee Contract
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MaintainEmployeeContract
                                loaderForm={setLoader.bind(this)}
                                roleId={props.roleId}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                disableHRAction={disableHRAction}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                HireDate={hireDate}
                                disableOnceCompleted={isCompleted}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded == "panel22"}
                        onChange={handleChangeAccord("panel22")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel22a-content"
                            id="panel22a-header"
                        >
                            <Typography className={classes.heading}>
                                Edit Services Date
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EditServicesDate
                                loaderForm={setLoader.bind(this)}
                                roleId={props.roleId}
                                loginDetail={[props.loginDetails]}
                                token={props.token}
                                disableHRAction={disableHRAction}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableOnceCompleted={isCompleted}
                                HireDate={hireDate}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <React.Fragment>
                        {stateA != null && stateA != "" && stateA == "1" ? (
                            <Accordion
                                expanded={expanded == "panel23"}
                                onChange={handleChangeAccord("panel23")}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel23a-content"
                                    id="panel23a-header"
                                >
                                    <Typography className={classes.heading}>
                                        Assign Collective Agreement
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AssignCollectiveAgreemment
                                        loaderForm={setLoader.bind(this)}
                                        token={props.token}
                                        loginDetail={[props.loginDetails]}
                                        disableHRAction={disableHRAction}
                                        id={applicantId}
                                        userId={props.userIdencrypted}
                                        disableOnceCompleted={isCompleted}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </React.Fragment>

                    <React.Fragment>
                        {employeeTypeFromJD != null && employeeTypeFromJD != "" && employeeTypeFromJD != "2" && employeeTypeFromJD != "6" && employeeTypeFromJD != "8" ? (
                            <Accordion
                                expanded={expanded == "panel24"}
                                onChange={handleChangeAccord("panel24")}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel24a-content"
                                    id="panel24a-header"
                                >
                                    <Typography className={classes.heading}>
                                        Manage Probation Period
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ManageProbationPeriod
                                        loaderForm={setLoader.bind(this)}
                                        roleId={props.roleId}
                                        HireDate={hireDate}
                                        token={props.token}
                                        loginDetail={[props.loginDetails]}
                                        disableHRAction={disableHRAction}
                                        id={applicantId}
                                        userId={props.userIdencrypted}
                                        disableOnceCompleted={isCompleted}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </React.Fragment>
                    <Accordion
                        expanded={expanded == "panel25"}
                        onChange={handleChangeAccord("panel25")}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel25a-content"
                            id="panel25a-header"
                        >
                            <Typography className={classes.heading}>
                                Upload Supporting Documents
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadSupportingDocuments
                                loaderForm={setLoader.bind(this)}
                                token={props.token}
                                disableHRAction={disableHRAction}
                                roleId={props.roleId}
                                loginDetail={[props.loginDetails]}
                                id={applicantId}
                                userId={props.userIdencrypted}
                                disableOnceCompleted={isCompleted}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {props.roleId == 1 && status >= 5 ? (
                        <React.Fragment>
                            <Accordion
                                expanded={expanded == "panel26"}
                                onChange={handleChangeAccord("panel26")}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel26a-content"
                                    id="panel26a-header"
                                >
                                    <Typography className={classes.heading}>
                                        Details Entered By Swagat/On-Boarding Team
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DetailsEnteredByOBTeam
                                        loaderForm={setLoader.bind(this)}
                                        loginDetail={[props.loginDetails]}
                                        token={props.token}
                                        id={applicantId}
                                        userId={props.userIdencrypted}
                                        callback={callbackDetailsByOB}
                                        disableOnceCompleted={isCompleted}
                                        HireDate={hireDate}
                                        status={status}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}

                    <div className="">
                        <Grid container spacing={1} className="d-flex fd-column ">
                            <Grid
                                container
                                spacing={2}
                                className="d-flex jc-space-around fd-row"
                            >
                                <Grid item xs={12} sm={6} className="d-flex Width-100 mt-2">
                                    <InputLabel id="demo-simple-select-label" required={true}>
                                        Approval Comments
                                    </InputLabel>
                                    <TextareaAutosize
                                        className="width-90 mr-3"
                                        id="Contract"
                                        name="1"
                                        rowsMin={2}
                                        placeholder="Approval Comments"
                                        disabled={
                                            isCompleted ? isCompleted : approvalCommentsDisable
                                        }
                                        value={approvalComments}
                                        onChange={(e) => setApprovalComments(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="d-flex Width-100 mt-2">
                                    <InputLabel id="demo-simple-select-label" required={true}>
                                        Rejection Comments
                                    </InputLabel>
                                    <TextareaAutosize
                                        className="width-90"
                                        id="Contract"
                                        name="1"
                                        rowsMin={2}
                                        placeholder="Rejection Comments"
                                        disabled={
                                            isCompleted ? isCompleted : rejectionCommentsDisable
                                        }
                                        value={rejectionComments}
                                        onChange={(e) => setRejectionComments(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="d-flex jc-center">
                        <Button
                            xs={12}
                            sm={4}
                            className="ns-btn-primary mt-4"
                            onClick={previousHandler}
                        >
                            <span>Previous</span>
                        </Button>
                        <Button
                            xs={12}
                            sm={4}
                            className="ns-btn-primary ml-1 mt-4"
                            disabled={isCompleted ? isCompleted : approveButtonDisable}
                            onClick={approveHandler}
                        >
                            <span>Approve</span>
                        </Button>
                        <Button
                            xs={12}
                            sm={4}
                            className="ns-btn-primary ml-1 mt-4"
                            disabled={isCompleted ? isCompleted : rejectButtonDisable}
                            onClick={rejectHandler}
                        >
                            <span>Reject</span>
                        </Button>
                    </div>
                </TabPanel>


                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                >
                    <DialogTitle
                        className={openClassName}
                        style={{ cursor: "move" }}
                        id="draggable-dialog-title"
                    >
                        {openType}
                    </DialogTitle>
                    <DialogContent className="bg-popup">
                        <DialogContentText className="clr-dark-blue">
                            {openAlert}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="bg-popup">
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={handleClose}
                            className="ns-btn-secondary-light"
                        >
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadClear"
                >
                    <DialogTitle
                        className={openClassName}
                        style={{ cursor: "move" }}
                        id="draggable-dialog-title"
                    >
                        {openType}
                    </DialogTitle>
                    <DialogContent className="bg-popup">
                        <DialogContentText className="clr-dark-blue">
                            {openAlertConfirm}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="bg-popup">
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={handleConfirm}
                            className="ns-btn-secondary-light"
                        >
                            <span className="f-16 fw-bold">OK</span>
                        </Button>
                        <Button
                            variant="contained"
                            autoFocus
                            onClick={handleCloseConfirm}
                            className="ns-btn-secondary-light"
                        >
                            <span className="f-16 fw-bold">Cancel</span>
                        </Button>
                    </DialogActions>
                </Dialog>

                {isLoader ? <Loader /> : <></>}
            </div>
        </ThemeProvider>
    );
}
