import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
//import Select from '@material-ui/core/Select';
import Input from "@material-ui/core/Input";
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import validator from "validator";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "10px",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.6rem !important",
        backgroundColor: "#ebfbfb !important",
        marginBottom: "-7px ​!important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "1 !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function DependentData(props) {
  const [data, setData] = useState([]); //table data

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [employeeAddress1, setEmployeeAddress1] = useState("");
  const [employeeAddress2, setEmployeeAddress2] = useState("");
  const [employeeAddress3, setEmployeeAddress3] = useState("");
  const [employeeMobileNo, setEmployeeMobileNo] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCodeObj, setPhoneCodeObj] = useState(null);
  const [phoneCodeText, setPhoneCodeText] = useState("");
  const handleClose = () => {
    if (openType === "Success") {
      GetDependentData(props.id);
    }
    setOpen(false);
    //window.location.reload()
  };

  //fetch Gender
  const [itemsGender, setItemsGender] = useState([]);
  async function getGender() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetAllGenders", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsGender(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Relationship
  const [itemsRelationship, setItemsRelationship] = useState([]);
  async function getRelationship() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetAllRelationship", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsRelationship(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Country
  const [itemsCountry, setItemsCountry] = useState([]);
  async function getCountry() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllCountries", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsCountry(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch CountryPhoneCode
  const [itemsCountryPHCode, setItemsCountryPHCode] = useState([]);
  async function getCountryPhoneCode() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetCountryPhoneCode", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsCountryPHCode(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch YesNo Options
  const [itemsYesNo, setItemsYesNo] = useState([]);
  async function getYesNoOptions() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetYesNoOptions", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsRhFactor(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsYesNo(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }
  //function IsValidMobileNo(value) {
  //    const exp = /^\d{10}$/;
  //    return exp.test(value);
  //}
  //function ValidateMobileNo(rowData) {
  //    return IsValidMobileNo(rowData.dependentMobileNo) ? true : false;
  //}

  function IsValidAddressLines(value) {
    const exp = /[\[\]\(\)\\\/#?<>'";:\-{}*&%$@!]/;
    return !exp.test(value);
  }

  function ValidateGender(rowData) {
    return rowData.gender === null || rowData.gender === "" ? false : true;
  }
  function ValidateCountry(rowData) {
    return rowData.country === null || rowData.country === "" ? false : true;
  }
  function ValidateRelationship(rowData) {
    return rowData.relationship === null || rowData.relationship === ""
      ? false
      : true;
  }
  function ValidateDOB(rowData) {
    var isValid = true;
    if (rowData.dependentDob === null || rowData.dependentDob === "") {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }
  function ValidateUseEmployeeAddress(rowData) {
    return rowData.useEmployeeAddressYesNoStr === null ||
      rowData.useEmployeeAddressYesNoStr === ""
      ? false
      : true;
  }
  function ValidateUseEmployeeMobileNo(rowData) {
    return rowData.useEmployeeMobileNoStr === null ||
      rowData.useEmployeeMobileNoStr === ""
      ? false
      : true;
  }
  function ValidatePhoneCode(rowData) {
    var isValid = true;
    if (
      (rowData.phoneCountryCode === null || rowData.phoneCountryCode === "") &&
      rowData.useEmployeeMobileNoStr !== null &&
      rowData.useEmployeeMobileNoStr !== "" &&
      rowData.useEmployeeMobileNoStr !== undefined &&
      (rowData.useEmployeeMobileNoStr.label === "No" ||
        rowData.useEmployeeMobileNoStr === "No")
    ) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  }

  function ValidateAddressLine1(rowData) {
    var isValid = true;

    if (
      rowData.useEmployeeAddressYesNoStr !== null &&
      rowData.useEmployeeAddressYesNoStr !== "" &&
      rowData.useEmployeeAddressYesNoStr !== undefined &&
      (rowData.useEmployeeAddressYesNoStr.label === "No" ||
        rowData.useEmployeeAddressYesNoStr === "No")
    ) {
      if (rowData.addressLine1 === null || rowData.addressLine1 === "") {
        isValid = false;
      } else {
        if (!IsValidAddressLines(rowData.addressLine1)) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    } else if (
      rowData.useEmployeeAddressYesNoStr !== null &&
      rowData.useEmployeeAddressYesNoStr !== "" &&
      rowData.useEmployeeAddressYesNoStr !== undefined &&
      (rowData.useEmployeeAddressYesNoStr.label === "Yes" ||
        rowData.useEmployeeAddressYesNoStr === "Yes")
    ) {
      if (employeeAddress1 === null || employeeAddress1 === "") {
        isValid = false;
      } else {
        if (!IsValidAddressLines(employeeAddress1)) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    } else {
      isValid = true;
    }

    return isValid;
  }

  function ValidateAddressLine2(rowData) {
    var isValid = true;

    if (
      rowData.useEmployeeAddressYesNoStr !== null &&
      rowData.useEmployeeAddressYesNoStr !== "" &&
      rowData.useEmployeeAddressYesNoStr !== undefined &&
      (rowData.useEmployeeAddressYesNoStr.label === "No" ||
        rowData.useEmployeeAddressYesNoStr === "No")
    ) {
      if (rowData.addressLine2 === null || rowData.addressLine2 === "") {
        isValid = false;
      } else {
        if (!IsValidAddressLines(rowData.addressLine2)) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    } else if (
      rowData.useEmployeeAddressYesNoStr !== null &&
      rowData.useEmployeeAddressYesNoStr !== "" &&
      rowData.useEmployeeAddressYesNoStr !== undefined &&
      (rowData.useEmployeeAddressYesNoStr.label === "Yes" ||
        rowData.useEmployeeAddressYesNoStr === "Yes")
    ) {
      if (employeeAddress2 === null || employeeAddress2 === "") {
        isValid = false;
      } else {
        if (!IsValidAddressLines(employeeAddress2)) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    } else {
      isValid = true;
    }

    return isValid;
  }

  function ValidateAddressLine3(rowData) {
    var isValid = true;

    if (
      rowData.useEmployeeAddressYesNoStr !== null &&
      rowData.useEmployeeAddressYesNoStr !== "" &&
      rowData.useEmployeeAddressYesNoStr !== undefined &&
      (rowData.useEmployeeAddressYesNoStr.label === "No" ||
        rowData.useEmployeeAddressYesNoStr === "No")
    ) {
      if (!IsValidAddressLines(rowData.addressLine3)) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else if (
      rowData.useEmployeeAddressYesNoStr !== null &&
      rowData.useEmployeeAddressYesNoStr !== "" &&
      rowData.useEmployeeAddressYesNoStr !== undefined &&
      (rowData.useEmployeeAddressYesNoStr.label === "Yes" ||
        rowData.useEmployeeAddressYesNoStr === "Yes")
    ) {
      if (!IsValidAddressLines(employeeAddress3)) {
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  function ValidateMobileNo(rowData) {
    var isValid = true;
    if (
      rowData.useEmployeeMobileNoStr !== null &&
      rowData.useEmployeeMobileNoStr !== "" &&
      rowData.useEmployeeMobileNoStr !== undefined &&
      (rowData.useEmployeeMobileNoStr.label === "No" ||
        rowData.useEmployeeMobileNoStr === "No")
    ) {
      if (
        rowData.dependentMobileNo === null ||
        rowData.dependentMobileNo === ""
      ) {
        isValid = false;
      } else {
        if (
          rowData.dependentMobileNo != "" &&
          rowData.dependentMobileNo != null &&
          rowData.dependentMobileNo != undefined &&
          (!validator.isMobilePhone(rowData.dependentMobileNo) ||
            rowData.dependentMobileNo.length != 10)
        ) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    } else if (
      rowData.useEmployeeMobileNoStr !== null &&
      rowData.useEmployeeMobileNoStr !== "" &&
      rowData.useEmployeeMobileNoStr !== undefined &&
      (rowData.useEmployeeMobileNoStr.label === "Yes" ||
        rowData.useEmployeeMobileNoStr === "Yes")
    ) {
      if (employeeMobileNo === null || employeeMobileNo === "") {
        isValid = false;
      } else {
        if (
          employeeMobileNo != "" &&
          employeeMobileNo != null &&
          employeeMobileNo != undefined &&
          (!validator.isMobilePhone(employeeMobileNo) ||
            employeeMobileNo.length != 10)
        ) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    } else {
      isValid = true;
    }

    return isValid;
  }

  var columns = [
    { title: "Row ID", field: "dependentDetailsId", hidden: true },
    { title: "Applicant ID*", field: "applicantId", hidden: true },

    {
      title: "First Name*",
      field: "dependentFirstName",
      validate: (rowData) =>
        rowData.dependentFirstName === null || rowData.dependentFirstName === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    {
      title: "Last Name*",
      field: "dependentLastName",
      validate: (rowData) =>
        rowData.dependentLastName === null || rowData.dependentLastName === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    {
      title: "Date Of Birth*",
      field: "dependentDob",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      validate: (rowData) => ValidateDOB(rowData),
      editComponent: (props) => {
        const dependentDob = props.rowData.dependentDob;

        let errorText = "";
        var errorState = false;
        if (dependentDob === null || dependentDob === "") {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              clearable
              disableFuture
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    {
      title: "Country Of Birth*",
      field: "country",
      validate: (rowData) => ValidateCountry(rowData),
      editComponent: (props) => {
        const countryData = props.rowData.countryObj
          ? props.rowData.countryObj.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const country = props.rowData.country;
        const customStyles =
          country !== undefined && country !== null && country !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsCountry}
            value={typeof props.value === "string" ? countryData : props.value}
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Gender*",
      field: "gender",
      validate: (rowData) => ValidateGender(rowData),
      editComponent: (props) => {
        const genderData = props.rowData.genderObj
          ? props.rowData.genderObj.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const gender = props.rowData.gender;
        const customStyles =
          gender !== undefined && gender !== null && gender !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsGender}
            value={typeof props.value === "string" ? genderData : props.value}
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Relationship*",
      field: "relationship",
      validate: (rowData) => ValidateRelationship(rowData),
      editComponent: (props) => {
        const relationshipData = props.rowData.relationshipObj
          ? props.rowData.relationshipObj.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const relationship = props.rowData.relationship;
        const customStyles =
          relationship !== undefined &&
          relationship !== null &&
          relationship !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsRelationship}
            value={
              typeof props.value === "string" ? relationshipData : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    //{ title: 'Employee Address Line 1', field: 'employeeAddressLine1', hidden: true },
    //{ title: 'Employee Address Line 2', field: 'employeeAddressLine2', hidden: true },
    //{ title: 'Employee Address Line 3', field: 'employeeAddressLine3', hidden: true },
    {
      title: "Use Employee MobileNo*",
      field: "useEmployeeMobileNoStr",
      validate: (rowData) => ValidateUseEmployeeMobileNo(rowData),
      editComponent: (props) => {
        const useEmployeeMobileNoData = props.rowData.useEmployeeMobileNoObj
          ? props.rowData.useEmployeeMobileNoObj.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const useEmployeeMobileNo = props.rowData.useEmployeeMobileNoStr;
        const customStyles =
          useEmployeeMobileNo !== undefined &&
          useEmployeeMobileNo !== null &&
          useEmployeeMobileNo !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsYesNo}
            value={
              typeof props.value === "string"
                ? useEmployeeMobileNoData
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Phone Code*",
      field: "phoneCountryCode",
      validate: (rowData) => ValidatePhoneCode(rowData),
      editComponent: (props) => {
        const phoneCountryCodeData = props.rowData.phoneCountryCodeObj
          ? props.rowData.phoneCountryCodeObj.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const phoneCountryCode = props.rowData.phoneCountryCode;
        const useEmployeeMobileNoStr = props.rowData.useEmployeeMobileNoStr;

        const customStyles =
          (phoneCountryCode === null || phoneCountryCode === "") &&
          useEmployeeMobileNoStr !== null &&
          useEmployeeMobileNoStr !== "" &&
          useEmployeeMobileNoStr !== undefined &&
          (useEmployeeMobileNoStr.label === "No" ||
            useEmployeeMobileNoStr === "No")
            ? {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                }),
              };

        return (
          <Select
            options={itemsCountryPHCode}
            value={
              (typeof useEmployeeMobileNoStr === "string" &&
                useEmployeeMobileNoStr === "Yes") ||
              (typeof useEmployeeMobileNoStr === "object" &&
                useEmployeeMobileNoStr !== null &&
                useEmployeeMobileNoStr !== "" &&
                useEmployeeMobileNoStr !== undefined &&
                useEmployeeMobileNoStr.label === "Yes")
                ? phoneCodeObj
                : typeof props.value === "string"
                ? phoneCountryCodeData
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
            isDisabled={
              (typeof useEmployeeMobileNoStr === "string" &&
                useEmployeeMobileNoStr === "Yes") ||
              (typeof useEmployeeMobileNoStr === "object" &&
                useEmployeeMobileNoStr !== null &&
                useEmployeeMobileNoStr !== "" &&
                useEmployeeMobileNoStr !== undefined &&
                useEmployeeMobileNoStr.label === "Yes")
                ? true
                : false
            }
          />
        );
      },
    },
    {
      title: "Mobile No.*",
      field: "dependentMobileNo",
      validate: (rowData) => ValidateMobileNo(rowData),
      editComponent: (props) => {
        const dependentMobileNo = props.rowData.dependentMobileNo;
        const useEmployeeMobileNoStr = props.rowData.useEmployeeMobileNoStr;
        const useEmployeeMobileNo = props.rowData.useEmployeeMobileNo;

        let errorText = "";
        var errorState = false;
        if (
          useEmployeeMobileNoStr !== null &&
          useEmployeeMobileNoStr !== "" &&
          useEmployeeMobileNoStr !== undefined &&
          (useEmployeeMobileNoStr.label === "No" ||
            useEmployeeMobileNoStr === "No")
        ) {
          if (dependentMobileNo === null || dependentMobileNo === "") {
            errorState = true;
            errorText = "Mandatory Field";
          } else {
            if (
              dependentMobileNo !== null &&
              dependentMobileNo !== "" &&
              dependentMobileNo !== undefined &&
              (!validator.isMobilePhone(dependentMobileNo) ||
                dependentMobileNo.length != 10)
            ) {
              errorText = "Invalid Mobile No";
              errorState = true;
            } else {
              errorText = "";
              errorState = false;
            }
          }
        } else if (
          useEmployeeMobileNoStr !== null &&
          useEmployeeMobileNoStr !== "" &&
          useEmployeeMobileNoStr !== undefined &&
          (useEmployeeMobileNoStr.label === "Yes" ||
            useEmployeeMobileNoStr === "Yes")
        ) {
          if (employeeMobileNo === null || employeeMobileNo === "") {
            errorState = true;
            errorText = "Please check communication data";
          } else {
            if (
              employeeMobileNo !== null &&
              employeeMobileNo !== "" &&
              employeeMobileNo !== undefined &&
              (!validator.isMobilePhone(employeeMobileNo) ||
                employeeMobileNo.length != 10)
            ) {
              errorText = "Invalid Mobile No";
              errorState = true;
            } else {
              errorText = "";
              errorState = false;
            }
          }
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <TextField
            value={
              (typeof useEmployeeMobileNoStr === "string" &&
                useEmployeeMobileNoStr === "Yes") ||
              (typeof useEmployeeMobileNoStr === "object" &&
                useEmployeeMobileNoStr !== null &&
                useEmployeeMobileNoStr !== "" &&
                useEmployeeMobileNoStr !== undefined &&
                useEmployeeMobileNoStr.label === "Yes")
                ? employeeMobileNo
                : props.value
            }
            onChange={(e) => props.onChange(e.target.value)}
            type="number"
            //onBlur={validateMobileNo}
            helperText={errorText}
            error={errorState}
            disabled={
              (typeof useEmployeeMobileNoStr === "string" &&
                useEmployeeMobileNoStr === "Yes") ||
              (typeof useEmployeeMobileNoStr === "object" &&
                useEmployeeMobileNoStr !== null &&
                useEmployeeMobileNoStr !== "" &&
                useEmployeeMobileNoStr !== undefined &&
                useEmployeeMobileNoStr.label === "Yes")
                ? true
                : false
            }
          />
        );
      },
      },
      {
          title: "Use Employee Address*",
          field: "useEmployeeAddressYesNoStr",
          validate: (rowData) => ValidateUseEmployeeAddress(rowData),
          editComponent: (props) => {
              const useEmployeeAddressData = props.rowData.useEmployeeAddressYesNoObj
                  ? props.rowData.useEmployeeAddressYesNoObj.map(({ id, name }) => ({
                      label: name,
                      value: id,
                  }))
                  : null;
              const useEmployeeAddress = props.rowData.useEmployeeAddressYesNoStr;
              const customStyles =
                  useEmployeeAddress !== undefined &&
                      useEmployeeAddress !== null &&
                      useEmployeeAddress !== ""
                      ? {
                          control: (provided, state) => ({
                              ...provided,
                          }),
                      }
                      : {
                          control: (provided, state) => ({
                              ...provided,
                              borderColor: "red !important",
                          }),
                      };
              return (
                  <Select
                      options={itemsYesNo}
                      value={
                          typeof props.value === "string"
                              ? useEmployeeAddressData
                              : props.value
                      }
                      onChange={(opt) => {
                          props.onChange(opt);
                      }}
                      isClearable={true}
                      styles={customStyles}
                  />
              );
          },
      },
      {
          title: "Address Line 1*",
          field: "addressLine1",
          validate: (rowData) => ValidateAddressLine1(rowData),
          editComponent: (props) => {
              const addressLine1 = props.rowData.addressLine1;
              const useEmployeeAddressYesNoStr =
                  props.rowData.useEmployeeAddressYesNoStr;

              let errorText = "";
              var errorState = false;

              if (
                  useEmployeeAddressYesNoStr !== null &&
                  useEmployeeAddressYesNoStr !== "" &&
                  useEmployeeAddressYesNoStr !== undefined &&
                  (useEmployeeAddressYesNoStr.label === "No" ||
                      useEmployeeAddressYesNoStr === "No")
              ) {
                  if (addressLine1 === null || addressLine1 === "") {
                      errorState = true;
                      errorText = "Mandatory Field";
                  } else {
                      if (!IsValidAddressLines(addressLine1)) {
                          errorState = true;
                          var str = "#/?><'" + '";:-}{][*&%$@!()';
                          errorText = str.toString() + " are not allowed";
                      } else {
                          errorText = "";
                          errorState = false;
                      }
                  }
              } else if (
                  useEmployeeAddressYesNoStr !== null &&
                  useEmployeeAddressYesNoStr !== "" &&
                  useEmployeeAddressYesNoStr !== undefined &&
                  (useEmployeeAddressYesNoStr.label === "Yes" ||
                      useEmployeeAddressYesNoStr === "Yes")
              ) {
                  if (employeeAddress1 === null || employeeAddress1 === "") {
                      errorState = true;
                      errorText = "Please check communication data";
                  } else {
                      if (!IsValidAddressLines(employeeAddress1)) {
                          errorState = true;
                          var str = "#/?><'" + '";:-}{][*&%$@!()';
                          errorText = str.toString() + " are not allowed";
                      } else {
                          errorText = "";
                          errorState = false;
                      }
                  }
              } else {
                  errorText = "";
                  errorState = false;
              }
              return (
                  <TextField
                      disabled={
                          (typeof useEmployeeAddressYesNoStr === "string" &&
                              useEmployeeAddressYesNoStr === "Yes") ||
                              (typeof useEmployeeAddressYesNoStr === "object" &&
                                  useEmployeeAddressYesNoStr !== undefined &&
                                  useEmployeeAddressYesNoStr !== "" &&
                                  useEmployeeAddressYesNoStr !== null &&
                                  useEmployeeAddressYesNoStr.label === "Yes")
                              ? true
                              : false
                      }
                      value={
                          (typeof useEmployeeAddressYesNoStr === "string" &&
                              useEmployeeAddressYesNoStr === "Yes") ||
                              (typeof useEmployeeAddressYesNoStr === "object" &&
                                  useEmployeeAddressYesNoStr !== undefined &&
                                  useEmployeeAddressYesNoStr !== "" &&
                                  useEmployeeAddressYesNoStr !== null &&
                                  useEmployeeAddressYesNoStr.label === "Yes")
                              ? employeeAddress1
                              : props.value
                      }
                      onChange={(e) => props.onChange(e.target.value)}
                      helperText={errorText}
                      error={errorState}
                  />
              );
          },
      },
      {
          title: "Address Line 2*",
          field: "addressLine2",
          validate: (rowData) => ValidateAddressLine2(rowData),
          editComponent: (props) => {
              const addressLine2 = props.rowData.addressLine2;
              const useEmployeeAddressYesNoStr =
                  props.rowData.useEmployeeAddressYesNoStr;

              let errorText = "";
              var errorState = false;

              if (
                  useEmployeeAddressYesNoStr !== null &&
                  useEmployeeAddressYesNoStr !== "" &&
                  useEmployeeAddressYesNoStr !== undefined &&
                  (useEmployeeAddressYesNoStr.label === "No" ||
                      useEmployeeAddressYesNoStr === "No")
              ) {
                  if (addressLine2 === null || addressLine2 === "") {
                      errorState = true;
                      errorText = "Mandatory Field";
                  } else {
                      if (!IsValidAddressLines(addressLine2)) {
                          errorState = true;
                          var str = "#/?><'" + '";:-}{][*&%$@!()';
                          errorText = str.toString() + " are not allowed";
                      } else {
                          errorText = "";
                          errorState = false;
                      }
                  }
              } else if (
                  useEmployeeAddressYesNoStr !== null &&
                  useEmployeeAddressYesNoStr !== "" &&
                  useEmployeeAddressYesNoStr !== undefined &&
                  (useEmployeeAddressYesNoStr.label === "Yes" ||
                      useEmployeeAddressYesNoStr === "Yes")
              ) {
                  if (employeeAddress2 === null || employeeAddress2 === "") {
                      errorState = true;
                      errorText = "Please check communication data";
                  } else {
                      if (!IsValidAddressLines(employeeAddress2)) {
                          errorState = true;
                          var str = "#/?><'" + '";:-}{][*&%$@!()';
                          errorText = str.toString() + " are not allowed";
                      } else {
                          errorText = "";
                          errorState = false;
                      }
                  }
              } else {
                  errorText = "";
                  errorState = false;
              }
              return (
                  <TextField
                      disabled={
                          (typeof useEmployeeAddressYesNoStr === "string" &&
                              useEmployeeAddressYesNoStr === "Yes") ||
                              (typeof useEmployeeAddressYesNoStr === "object" &&
                                  useEmployeeAddressYesNoStr !== undefined &&
                                  useEmployeeAddressYesNoStr !== "" &&
                                  useEmployeeAddressYesNoStr !== null &&
                                  useEmployeeAddressYesNoStr.label === "Yes")
                              ? true
                              : false
                      }
                      value={
                          (typeof useEmployeeAddressYesNoStr === "string" &&
                              useEmployeeAddressYesNoStr === "Yes") ||
                              (typeof useEmployeeAddressYesNoStr === "object" &&
                                  useEmployeeAddressYesNoStr !== undefined &&
                                  useEmployeeAddressYesNoStr !== "" &&
                                  useEmployeeAddressYesNoStr !== null &&
                                  useEmployeeAddressYesNoStr.label === "Yes")
                              ? employeeAddress2
                              : props.value
                      }
                      onChange={(e) => props.onChange(e.target.value)}
                      helperText={errorText}
                      error={errorState}
                  />
              );
          },
      },
      {
          title: "Address Line 3",
          field: "addressLine3",
          validate: (rowData) => ValidateAddressLine3(rowData),
          editComponent: (props) => {
              const addressLine3 = props.rowData.addressLine3;
              const useEmployeeAddressYesNoStr =
                  props.rowData.useEmployeeAddressYesNoStr;

              let errorText = "";
              var errorState = false;

              if (
                  useEmployeeAddressYesNoStr !== null &&
                  useEmployeeAddressYesNoStr !== "" &&
                  useEmployeeAddressYesNoStr !== undefined &&
                  (useEmployeeAddressYesNoStr.label === "No" ||
                      useEmployeeAddressYesNoStr === "No")
              ) {
                  if (!IsValidAddressLines(addressLine3)) {
                      errorState = true;
                      var str = "#/?><'" + '";:-}{][*&%$@!()';
                      errorText = str.toString() + " are not allowed";
                  } else {
                      errorText = "";
                      errorState = false;
                  }
              } else if (
                  useEmployeeAddressYesNoStr !== null &&
                  useEmployeeAddressYesNoStr !== "" &&
                  useEmployeeAddressYesNoStr !== undefined &&
                  (useEmployeeAddressYesNoStr.label === "Yes" ||
                      useEmployeeAddressYesNoStr === "Yes")
              ) {
                  if (!IsValidAddressLines(employeeAddress3)) {
                      errorState = true;
                      var str = "#/?><'" + '";:-}{][*&%$@!()';
                      errorText = str.toString() + " are not allowed";
                  } else {
                      errorText = "";
                      errorState = false;
                  }
              } else {
                  errorText = "";
                  errorState = false;
              }

              return (
                  <TextField
                      disabled={
                          (typeof useEmployeeAddressYesNoStr === "string" &&
                              useEmployeeAddressYesNoStr === "Yes") ||
                              (typeof useEmployeeAddressYesNoStr === "object" &&
                                  useEmployeeAddressYesNoStr !== undefined &&
                                  useEmployeeAddressYesNoStr !== "" &&
                                  useEmployeeAddressYesNoStr !== null &&
                                  useEmployeeAddressYesNoStr.label === "Yes")
                              ? true
                              : false
                      }
                      value={
                          (typeof useEmployeeAddressYesNoStr === "string" &&
                              useEmployeeAddressYesNoStr === "Yes") ||
                              (typeof useEmployeeAddressYesNoStr === "object" &&
                                  useEmployeeAddressYesNoStr !== undefined &&
                                  useEmployeeAddressYesNoStr !== "" &&
                                  useEmployeeAddressYesNoStr !== null &&
                                  useEmployeeAddressYesNoStr.label === "Yes")
                              ? employeeAddress3
                              : props.value
                      }
                      onChange={(e) => props.onChange(e.target.value)}
                      helperText={errorText}
                      error={errorState}
                  />
              );
          },
      },
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const handleErrorClose = (e) => {
    setIserror(false);
  };

  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (props.token) {
      getGender();
      getRelationship();
      getCountry();
      getCountryPhoneCode();
      getYesNoOptions();
    }
  }, [props.token]);

  function GetDependentData(id) {
    setIsLoader(true);
    const token = props.token;
    axios
      .get("/OnBoardingForm/GetDependentData/" + id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error : ", error);
      });
  }
  function SetStateAsPerFetched(data) {
    setEmployeeAddress1(data.employeeAddressLine1);
    setEmployeeAddress2(data.employeeAddressLine2);
    setEmployeeAddress3(data.employeeAddressLine3);
    setEmployeeMobileNo(data.mobileNo);
    setPhoneCode(data.phoneCountryCodeId);
    if (data.phoneCountryCodeObj[0].id > 0) {
      setPhoneCodeObj(
        data.phoneCountryCodeObj.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    }
    setPhoneCodeText(data.phoneCountryCode);
    setIsLoader(false);
  }
  function GetEmployeeAddressAndMobileDetails(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("/OnBoardingForm/GetEmployeeAddressAndMobileDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error : ", error);
      });
  }

  useEffect(() => {
    if (props.token) {
      if (props.id) {
        GetDependentData(props.id);
        GetEmployeeAddressAndMobileDetails(props.id);
      }
    }
  }, [props.id, props.token]);

  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    //validation
    let errorList = [];
    //if (newData.familyFirstName === "" || newData.familyFirstName === undefined || newData.familyFirstName === null) {
    //    errorList.push("Please enter First Name")
    //}
    ////if (newData.familyMiddleName === "") {
    ////    errorList.push("Please enter Middle Name")
    ////}
    //if (newData.familyLastName === "" || newData.familyLastName === undefined || newData.familyLastName === null) {
    //    errorList.push("Please enter Last Name")
    //}
    //if (newData.familyDateOfBirth === "" || newData.familyDateOfBirth === undefined || newData.familyDateOfBirth === null) {
    //    errorList.push("Please enter Date Of Birth")
    //}
    //if (newData.occupation === "" || newData.occupation === undefined || newData.occupation === null) {
    //    errorList.push("Please enter Occupation")
    //}
    //if (newData.gender === "" || newData.gender === undefined || newData.gender === null) {
    //    errorList.push("Please enter Gender")
    //}
    //if (newData.relationship === "" || newData.relationship === undefined || newData.relationship === null) {
    //    errorList.push("Please enter Relationship")
    //}

    //added for formatting of newData start
    const gender =
      newData.gender === null
        ? null
        : typeof newData.gender === "object"
        ? newData.gender.label
        : newData.gender;
    const relationship =
      newData.relationship === null
        ? null
        : typeof newData.relationship === "object"
        ? newData.relationship.label
        : newData.relationship;
    const country =
      newData.country === null
        ? null
        : typeof newData.country === "object"
        ? newData.country.label
        : newData.country;
    const useEmployeeAddress =
      newData.useEmployeeAddressYesNoStr === null
        ? null
        : typeof newData.useEmployeeAddressYesNoStr === "object"
        ? newData.useEmployeeAddressYesNoStr.label
        : newData.useEmployeeAddressYesNoStr;
    const useEmployeeMobileNo =
      newData.useEmployeeMobileNoStr === null
        ? null
        : typeof newData.useEmployeeMobileNoStr === "object"
        ? newData.useEmployeeMobileNoStr.label
        : newData.useEmployeeMobileNoStr;
    const phoneCountryCode =
      newData.phoneCountryCode === null
        ? null
        : typeof newData.phoneCountryCode === "object"
        ? newData.phoneCountryCode.label
        : newData.phoneCountryCode;

    newData.gender = gender;
    newData.relationship = relationship;
    newData.country = country;
    newData.useEmployeeAddressYesNoStr = useEmployeeAddress;
    newData.useEmployeeMobileNoStr = useEmployeeMobileNo;
    newData.phoneCountryCode = phoneCountryCode;

    if (useEmployeeAddress === "Yes") {
      newData.addressLine1 = employeeAddress1;
      newData.addressLine2 = employeeAddress2;
      newData.addressLine3 = employeeAddress3;
    }

    if (useEmployeeMobileNo === "Yes") {
      newData.dependentMobileNo = employeeMobileNo;
      newData.phoneCountryCode = phoneCodeObj[0].label;
    } else {
      newData.phoneCountryCode = phoneCountryCode;
    }

    //newData.familyDetailsId = oldData.tableData.id;
    newData.applicantId = props.id;
    //newData.userId = props.loginDetail[0].userid;
    newData.userId = props.userId;
    newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
    if (errorList.length < 1) {
      const token = props.token;
      axios
        .put("/OnBoardingForm/UpdateDependentData", newData, {
          headers: { Authorization: "Bearer " + token },
        })

        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })

        .catch((error) => {
          reject();
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      //resolve()
      reject();
    }
  };

  const handleRowAdd = (newData, resolve, reject) => {
    //validation
    let errorList = [];
    if (
      newData.dependentFirstName === "" ||
      newData.dependentFirstName === undefined ||
      newData.dependentFirstName === null
    ) {
      errorList.push("Please enter First Name");
    }
    if (
      newData.dependentLastName === "" ||
      newData.dependentLastName === undefined ||
      newData.dependentLastName === null
    ) {
      errorList.push("Please enter Last Name");
    }
    if (
      newData.dependentDob === "" ||
      newData.dependentDob === undefined ||
      newData.dependentDob === null
    ) {
      errorList.push("Please enter Date Of Birth");
    }
    if (
      newData.country === "" ||
      newData.country === undefined ||
      newData.country === null
    ) {
      errorList.push("Please select Country Of Birth");
    }
    if (
      newData.gender === "" ||
      newData.gender === undefined ||
      newData.gender === null
    ) {
      errorList.push("Please select Gender");
    }
    if (
      newData.relationship === "" ||
      newData.relationship === undefined ||
      newData.relationship === null
    ) {
      errorList.push("Please select Relationship");
    }
    if (
      newData.useEmployeeAddressYesNoStr === "" ||
      newData.useEmployeeAddressYesNoStr === undefined ||
      newData.useEmployeeAddressYesNoStr === null
    ) {
      errorList.push("Please select Use Employee Address");
    }
    if (
      newData.useEmployeeAddressYesNoStr != "" &&
      newData.useEmployeeAddressYesNoStr != undefined &&
      newData.useEmployeeAddressYesNoStr != null &&
      newData.useEmployeeAddressYesNoStr.value === 2
    ) {
      if (
        newData.addressLine1 === "" ||
        newData.addressLine1 === undefined ||
        newData.addressLine1 === null
      ) {
        errorList.push("Please enter Address Line 1");
      }
    }
    if (
      newData.useEmployeeAddressYesNoStr != "" &&
      newData.useEmployeeAddressYesNoStr != undefined &&
      newData.useEmployeeAddressYesNoStr != null &&
      newData.useEmployeeAddressYesNoStr.value === 1
    ) {
      if (
        employeeAddress1 === "" ||
        employeeAddress1 === undefined ||
        employeeAddress1 === null
      ) {
        errorList.push("Please check address lines under communication data");
      }
    }
    if (
      newData.useEmployeeAddressYesNoStr != "" &&
      newData.useEmployeeAddressYesNoStr != undefined &&
      newData.useEmployeeAddressYesNoStr != null &&
      newData.useEmployeeAddressYesNoStr.value === 2
    ) {
      if (
        newData.addressLine2 === "" ||
        newData.addressLine2 === undefined ||
        newData.addressLine2 === null
      ) {
        errorList.push("Please enter Address Line 2");
      }
    }
    if (
      newData.useEmployeeAddressYesNoStr != "" &&
      newData.useEmployeeAddressYesNoStr != undefined &&
      newData.useEmployeeAddressYesNoStr != null &&
      newData.useEmployeeAddressYesNoStr.value === 1
    ) {
      if (
        employeeAddress2 === "" ||
        employeeAddress2 === undefined ||
        employeeAddress2 === null
      ) {
        errorList.push("Please check address lines under communication data");
      }
    }
    if (
      newData.useEmployeeMobileNoStr === "" ||
      newData.useEmployeeMobileNoStr === undefined ||
      newData.useEmployeeMobileNoStr === null
    ) {
      errorList.push("Please select Use Employee Mobile No.");
    }
    if (
      newData.phoneCountryCode === "" ||
      newData.phoneCountryCode === undefined ||
      newData.phoneCountryCode === null
    ) {
      if (
        newData.useEmployeeMobileNoStr != "" &&
        newData.useEmployeeMobileNoStr != undefined &&
        newData.useEmployeeMobileNoStr != null &&
        newData.useEmployeeMobileNoStr.value === 2
      ) {
        errorList.push("Please select Country Phone Code");
      }
    }
    if (
      newData.useEmployeeMobileNoStr != "" &&
      newData.useEmployeeMobileNoStr != undefined &&
      newData.useEmployeeMobileNoStr != null &&
      newData.useEmployeeMobileNoStr.value === 2
    ) {
      if (
        newData.dependentMobileNo === "" ||
        newData.dependentMobileNo === undefined ||
        newData.dependentMobileNo === null
      ) {
        errorList.push("Please enter mobile number");
      }
    }
    if (
      newData.useEmployeeMobileNoStr != "" &&
      newData.useEmployeeMobileNoStr != undefined &&
      newData.useEmployeeMobileNoStr != null &&
      newData.useEmployeeMobileNoStr.value === 1
    ) {
      if (
        employeeMobileNo === "" ||
        employeeMobileNo === undefined ||
        employeeMobileNo === null
      ) {
        errorList.push("Please check mobile number under communication data");
      }
    }

    //else {
    //    if (!IsValidMobileNo(newData.dependentMobileNo)) {
    //        errorList.push("Mobile Number invalid. Please enter the valid one.");
    //    }
    //}
    if (
      newData.dependentMobileNo != "" &&
      newData.dependentMobileNo != undefined &&
      newData.dependentMobileNo != null
    ) {
      if (
        !validator.isMobilePhone(newData.dependentMobileNo) ||
        newData.dependentMobileNo.length != 10
      ) {
        errorList.push("Please enter a valid 10-digit Mobile No");
      }
    }

    if (errorList.length < 1) {
      //added for formatting of newData start
      const gender =
        newData.gender === null
          ? null
          : typeof newData.gender === "object"
          ? newData.gender.label
          : newData.gender;
      const relationship =
        newData.relationship === null
          ? null
          : typeof newData.relationship === "object"
          ? newData.relationship.label
          : newData.relationship;
      const country =
        newData.country === null
          ? null
          : typeof newData.country === "object"
          ? newData.country.label
          : newData.country;
      const useEmployeeAddress =
        newData.useEmployeeAddressYesNoStr === null
          ? null
          : typeof newData.useEmployeeAddressYesNoStr === "object"
          ? newData.useEmployeeAddressYesNoStr.label
          : newData.useEmployeeAddressYesNoStr;
      const useEmployeeMobileNo =
        newData.useEmployeeMobileNoStr === null
          ? null
          : typeof newData.useEmployeeMobileNoStr === "object"
          ? newData.useEmployeeMobileNoStr.label
          : newData.useEmployeeMobileNoStr;
      const phoneCountryCode =
        newData.phoneCountryCode === null
          ? null
          : typeof newData.phoneCountryCode === "object"
          ? newData.phoneCountryCode.label
          : newData.phoneCountryCode;

      if (useEmployeeAddress === "Yes") {
        newData.addressLine1 = employeeAddress1;
        newData.addressLine2 = employeeAddress2;
        newData.addressLine3 = employeeAddress3;
      }

      if (useEmployeeMobileNo === "Yes") {
        newData.dependentMobileNo = employeeMobileNo;
        newData.phoneCountryCode = phoneCodeObj[0].label;
      } else {
        newData.phoneCountryCode = phoneCountryCode;
      }

      newData.gender = gender;
      newData.relationship = relationship;
      newData.country = country;
      newData.useEmployeeAddressYesNoStr = useEmployeeAddress;
      newData.useEmployeeMobileNoStr = useEmployeeMobileNo;
      // end

      newData.applicantId = props.id;
      //newData.userId = props.loginDetail[0].userid;
      newData.userId = props.userId;
      newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
      const token = props.token;
      axios
        .post("/OnBoardingForm/SaveDependentData", newData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
      reject();
    }
  };

  const handleRowDelete = (oldData, resolve, reject) => {
    oldData.applicantId = props.id;

    const token = props.token;
    axios
      .put("/OnBoardingForm/DeleteDependentData", oldData, {
        headers: { Authorization: "Bearer " + token },
      })

      .then((response) => {
        if (response.data.response) {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          resolve();
          setIserror(false);
          setErrorMessages([]);
        } else {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          // resolve();
          reject();
          setIserror(false);
          setErrorMessages([]);
        }
      })
      .catch((error) => {
        reject();
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        // resolve()
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <Grid container spacing={1}>
          <p className="f-12">
            At least one dependent details is mandatory to submit the form
          </p>
          <Grid item xs={12}>
            <div>
              {iserror && (
                <Alert severity="error" onClose={handleErrorClose}>
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              title="Dependent Details"
              columns={columns}
              data={data}
              isLoading={isLoader}
              components={{
                OverlayLoading: (props) => <Loader {...props} />,
              }}
              icons={tableIcons}
              localization={{
                header: {
                  actions: "",
                },
              }}
              options={{
                search: false,
                toolbar: props.disableValue === true ? false : true,
              }}
              editable={{
                isEditable: (rowData) => props.disableValue === false,
                isDeletable: (rowData) => props.disableValue === false,

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(newData, oldData, resolve, reject);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newData, resolve, reject);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldData, resolve, reject);
                  }),
              }}
            />
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle className={openClassName} id="draggable-dialog-title">
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default DependentData;
