import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
//import Select from '@material-ui/core/Select';
import Input from "@material-ui/core/Input";
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "10px",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.6rem !important",
        backgroundColor: "#ebfbfb !important",
        marginBottom: "-7px ​!important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "1 !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function SuperAnnuationNomination(props) {
    const [data, setData] = useState([]); //table data
    const [dependentData, setDependentData] = useState([]);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");

  const handleClose = () => {
    if (openType === "Success") {
      GetSuperAnnuationData(props.id);
    }
    setOpen(false);
    //window.location.reload()
  };

  //fetch Relationship
  const [itemsRelationship, setItemsRelationship] = useState([]);
  async function getRelationship() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetAllRelationship", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsRelationship(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch isValidationOfMinor
  const [itemsValidationOfMinor, setItemsValidationOfMinor] = useState([]);
  async function getValidationOfMinor() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetYesOrNo", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsValidationOfMinor(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    //else if (characterCode.includes(".")) return

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  function getSharePercentage(share, id) {
    let sum = 0;

    for (var i = 0; i < data.length; i++) {
      if (i != id) {
        sum = Number(sum) + Number(data[i].sharePercentage);
      }
    }
    sum = Number(sum) + Number(share);
    if (sum > 100) {
      return 1;
    } else {
      return 0;
    }
  }

    function ValidateRelationship(rowData) {

        let dependentDataRelationship = null;
        if (dependentData && dependentData.length > 0 && rowData.relationWithEmployee) {
            dependentDataRelationship = dependentData.find(x => (x.relationshipId === rowData.relationWithEmployee.value && x.relationship === rowData.relationWithEmployee.label) || x.relationship === rowData.relationWithEmployee)
        }

        var isValid = true;
        if (rowData.relationWithEmployee === null || rowData.relationWithEmployee === '' || !dependentDataRelationship) {
            isValid = false;
        }
        else {
            isValid = true;
        }

        if (rowData.firstNameNominee && rowData.lastNameNominee && rowData.dob && rowData.relationWithEmployee) {
            var count = 0;
            if (dependentData && dependentData.length > 0) {
                for (var i = 0; i < dependentData.length; i++) {
                    var depDate = new Date(dependentData[i].dependentDob).toDateString();
                    var provDate = new Date(rowData.dob).toDateString();

                    if (dependentData[i].dependentFirstName === rowData.firstNameNominee && dependentData[i].dependentLastName === rowData.lastNameNominee
                        && depDate === provDate && ((dependentData[i].relationshipId === rowData.relationWithEmployee.value && dependentData[i].relationship === rowData.relationWithEmployee.label) || dependentData[i].relationship === rowData.relationWithEmployee)) {
                        count = count + 1;
                        break;
                    }
                }
                if (count == 0) {
                    isValid = false;

                }
                else {
                    isValid = true;
                }
            }
        }

        return isValid;


    }


  function ValidateMinor(rowData) {
    return rowData.validationOfMinorYesOrNo === null ||
      rowData.validationOfMinorYesOrNo === ""
      ? false
      : true;
  }

    function ValidateDOB(rowData) {
        var isValid = true;
        if (rowData.dob === null || rowData.dob === '') {
            isValid = false;
        }
        else {
            if (dependentData && dependentData.length > 0 && rowData.dob != undefined) {
                for (var i = 0; i < dependentData.length; i++) {
                    var depDate = new Date(dependentData[i].dependentDob).toDateString();
                    var provDate = new Date(rowData.dob).toDateString();

                    if (depDate == provDate) {
                        isValid = true;
                        break;
                    }
                    else {
                        isValid = false;
                    }
                }
            }
            else {
                isValid = false;
            }

            if (rowData.firstNameNominee && rowData.lastNameNominee && rowData.dob && rowData.relationWithEmployee) {
                var count = 0;
                if (dependentData && dependentData.length > 0) {
                    for (var i = 0; i < dependentData.length; i++) {
                        var depDate = new Date(dependentData[i].dependentDob).toDateString();
                        var provDate = new Date(rowData.dob).toDateString();

                        if (dependentData[i].dependentFirstName === rowData.firstNameNominee && dependentData[i].dependentLastName === rowData.lastNameNominee
                            && depDate === provDate && ((dependentData[i].relationshipId === rowData.relationWithEmployee.value && dependentData[i].relationship === rowData.relationWithEmployee.label) || dependentData[i].relationship === rowData.relationWithEmployee)) {
                            count = count + 1;
                            break;
                        }
                    }
                    if (count == 0) {
                        isValid = false;

                    }
                    else {
                        isValid = true;
                    }
                }
            }
        }
        return isValid;
    }

  function ValidateNomineeShare(rowData) {
    var isValid = true;
    if (rowData.sharePercentage === null || rowData.sharePercentage === "") {
      isValid = false;
    } else {
      if (
        rowData.sharePercentage != "" &&
        rowData.sharePercentage != undefined &&
        rowData.sharePercentage != null &&
        rowData.sharePercentage > 100
      ) {
        isValid = false;
      } else if (rowData.tableData != undefined && rowData.tableData != null) {
        var result = getSharePercentage(
          rowData.sharePercentage,
          rowData.tableData.id
        );
        if (
          result != undefined &&
          result != "" &&
          result != null &&
          result == 1 &&
          rowData.sharePercentage <= 100
        ) {
          isValid = false;
        }
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  function ValidateGuardianAddress(rowData) {
    var isValid = true;
    if (
      rowData.validationOfMinorYesOrNo !== null &&
      rowData.validationOfMinorYesOrNo !== "" &&
      rowData.validationOfMinorYesOrNo !== undefined &&
      (rowData.validationOfMinorYesOrNo === "Yes" ||
        rowData.validationOfMinorYesOrNo.label === "Yes") &&
      (rowData.gaurdianAddress === null ||
        rowData.gaurdianAddress === "" ||
        rowData.gaurdianAddress === undefined)
    ) {
      isValid = false;
    } else {
      if (!IsValidAddressLines(rowData.gaurdianAddress)) {
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  function ValidateGuardianName(rowData) {
    var isValid = true;
    if (
      rowData.validationOfMinorYesOrNo !== null &&
      rowData.validationOfMinorYesOrNo !== "" &&
      rowData.validationOfMinorYesOrNo !== undefined &&
      (rowData.validationOfMinorYesOrNo === "Yes" ||
        rowData.validationOfMinorYesOrNo.label === "Yes") &&
      (rowData.gaurdianName === null ||
        rowData.gaurdianName === "" ||
        rowData.gaurdianName === undefined)
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  function ValidateShareOnSave() {
    let sum = 0;

    for (var i = 0; i < data.length; i++) {
      sum = Number(sum) + Number(data[i].sharePercentage);
    }
    sum = Number(sum);
    if (data.length <= 0) {
      setOpen(true);
      setOpenAlert("No records to submit. Please enter some data.");
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    } else if (sum == 100) {
      setOpen(true);
      setOpenAlert("Superannuation Data submitted successfully.");
      setClassName("clr-dark-blue bg-success");
      setOpenType("Success");
    } else {
      setOpen(true);
      setOpenAlert("Summation of Nominee Share should be 100.");
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  }

  function IsValidAddressLines(value) {
    const exp = /[\[\]\(\)\\\/#?<>'";:\-{}*&%$@!]/;
    return !exp.test(value);
  }

  function ValidateNomineeAddress(rowData) {
    var isValid = true;

    if (rowData.nomineeAddress === null || rowData.nomineeAddress === "") {
      isValid = false;
    } else {
      if (!IsValidAddressLines(rowData.nomineeAddress)) {
        isValid = false;
      } else {
        isValid = true;
      }
    }

    return isValid;
    }


    function ValidateFirstName(rowData) {
        var isValid = true;
        if (rowData.firstNameNominee === null || rowData.firstNameNominee === '') {
            isValid = false;
        }
        else {
            if (dependentData && dependentData.length > 0) {
                let objFirstName = dependentData.find(x => x.dependentFirstName == rowData.firstNameNominee)
                if (!objFirstName && rowData.firstNameNominee != undefined) {
                    isValid = false;
                }
                else {
                    isValid = true;
                }
            }
            else {
                isValid = false;
            }
        }

        if (rowData.firstNameNominee && rowData.lastNameNominee && rowData.dob && rowData.relationWithEmployee) {
            var count = 0;
            if (dependentData && dependentData.length > 0) {
                for (var i = 0; i < dependentData.length; i++) {
                    var depDate = new Date(dependentData[i].dependentDob).toDateString();
                    var provDate = new Date(rowData.dob).toDateString();

                    if (dependentData[i].dependentFirstName === rowData.firstNameNominee && dependentData[i].dependentLastName === rowData.lastNameNominee
                        && depDate === provDate && ((dependentData[i].relationshipId === rowData.relationWithEmployee.value && dependentData[i].relationship === rowData.relationWithEmployee.label) || dependentData[i].relationship === rowData.relationWithEmployee)) {
                        count = count + 1;
                        break;
                    }
                }
                if (count == 0) {
                    isValid = false;

                }
                else {
                    isValid = true;
                }
            }
        }

        return isValid;
    }

    function ValidateLastName(rowData) {
        var isValid = true;
        if (rowData.lastNameNominee === null || rowData.lastNameNominee === '') {
            isValid = false;
        }
        else {
            if (dependentData && dependentData.length > 0) {
                let objLastName = dependentData.find(x => x.dependentLastName == rowData.lastNameNominee)
                if (!objLastName && rowData.lastNameNominee != undefined) {
                    isValid = false;
                }
                else {
                    isValid = true;
                }
            }
            else {
                isValid = false;
            }

            if (rowData.firstNameNominee && rowData.lastNameNominee && rowData.dob && rowData.relationWithEmployee) {
                var count = 0;
                if (dependentData && dependentData.length > 0) {
                    for (var i = 0; i < dependentData.length; i++) {
                        var depDate = new Date(dependentData[i].dependentDob).toDateString();
                        var provDate = new Date(rowData.dob).toDateString();

                        if (dependentData[i].dependentFirstName === rowData.firstNameNominee && dependentData[i].dependentLastName === rowData.lastNameNominee
                            && depDate === provDate && ((dependentData[i].relationshipId === rowData.relationWithEmployee.value && dependentData[i].relationship === rowData.relationWithEmployee.label) || dependentData[i].relationship === rowData.relationWithEmployee)) {
                            count = count + 1;
                            break;
                        }
                    }
                    if (count == 0) {
                        isValid = false;

                    }
                    else {
                        isValid = true;
                    }
                }
            }
        }
        return isValid;
    }

  var columns = [
    { title: "Row ID", field: "superannuationDetailsId", hidden: true },
    { title: "Applicant ID*", field: "employeeId", hidden: true },
    {
      title: "First Name*",
      field: "firstNameNominee",
        validate: rowData => ValidateFirstName(rowData),
        editComponent: props => {
            const firstNameNominee = props.rowData.firstNameNominee;

            let errorText = "";
            var errorState = false;
            if (firstNameNominee === null || firstNameNominee === '') {
                errorState = true;
                errorText = "Mandatory Field";
            }
            else {

                if (dependentData && dependentData.length > 0) {
                    let objFirstName = dependentData.find(x => x.dependentFirstName == firstNameNominee)
                    if (!objFirstName && firstNameNominee != undefined) {
                        errorState = true;
                        errorText = "Does not match with Dependent Details";
                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
                else {
                    errorState = true;
                    errorText = "Please enter Dependent Details first.";
                }

            }

            if (props.rowData.firstNameNominee && props.rowData.lastNameNominee && props.rowData.dob && props.rowData.relationWithEmployee) {
                var count = 0;
                if (dependentData && dependentData.length > 0) {
                    for (var i = 0; i < dependentData.length; i++) {
                        var depDate = new Date(dependentData[i].dependentDob).toDateString();
                        var provDate = new Date(props.rowData.dob).toDateString();

                        if (dependentData[i].dependentFirstName === props.rowData.firstNameNominee && dependentData[i].dependentLastName === props.rowData.lastNameNominee
                            && depDate === provDate && ((dependentData[i].relationshipId === props.rowData.relationWithEmployee.value && dependentData[i].relationship === props.rowData.relationWithEmployee.label) || dependentData[i].relationship === props.rowData.relationWithEmployee)) {
                            count = count + 1;
                            break;
                        }
                    }
                    if (count == 0) {
                        errorState = true;
                        errorText = "Nominee and Dependent Details should match";

                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
            }

            return (
                <TextField
                    defaultValue={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    helperText={errorText}
                    error={errorState}
                />
            )
        }
    },
    { title: "Middle Name", field: "middleNameNominee" },
    {
      title: "Last Name*",
      field: "lastNameNominee",
        validate: rowData => ValidateLastName(rowData),
        editComponent: props => {
            const lastNameNominee = props.rowData.lastNameNominee;

            let errorText = "";
            var errorState = false;
            if (lastNameNominee === null || lastNameNominee === '') {
                errorState = true;
                errorText = "Mandatory Field";
            }
            else {

                if (dependentData && dependentData.length > 0) {
                    let objLastName = dependentData.find(x => x.dependentLastName == lastNameNominee)
                    if (!objLastName && lastNameNominee != undefined) {
                        errorState = true;
                        errorText = "Does not match with Dependent Details";
                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
                else {
                    errorState = true;
                    errorText = "Please enter Dependent Details first.";
                }

            }

            if (props.rowData.firstNameNominee && props.rowData.lastNameNominee && props.rowData.dob && props.rowData.relationWithEmployee) {
                var count = 0;
                if (dependentData && dependentData.length > 0) {
                    for (var i = 0; i < dependentData.length; i++) {
                        var depDate = new Date(dependentData[i].dependentDob).toDateString();
                        var provDate = new Date(props.rowData.dob).toDateString();

                        if (dependentData[i].dependentFirstName === props.rowData.firstNameNominee && dependentData[i].dependentLastName === props.rowData.lastNameNominee
                            && depDate === provDate && ((dependentData[i].relationshipId === props.rowData.relationWithEmployee.value && dependentData[i].relationship === props.rowData.relationWithEmployee.label) || dependentData[i].relationship === props.rowData.relationWithEmployee)) {
                            count = count + 1;
                            break;
                        }
                    }
                    if (count == 0) {
                        errorState = true;
                        errorText = "Nominee and Dependent Details should match";

                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
            }

            return (
                <TextField
                    defaultValue={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    helperText={errorText}
                    error={errorState}
                />
            )
        }
    },
    {
      title: "Date Of Birth*",
      field: "dob",
      type: "date",
      dateSetting: { locale: "fr-ca" },
        validate: rowData => ValidateDOB(rowData),
        editComponent: props => {
            const dob = props.rowData.dob;

            let errorText = "";
            var errorState = false;
            if (dob === null || dob === '') {
                errorState = true;
                errorText = "Mandatory Field";
            }

            else {
                if (dependentData && dependentData.length > 0 && dob != undefined) {
                    for (var i = 0; i < dependentData.length; i++) {
                        var depDate = new Date(dependentData[i].dependentDob).toDateString();
                        var provDate = new Date(dob).toDateString();
                        if (depDate == provDate) {
                            errorText = '';
                            errorState = false;
                            break;
                        }
                        else {
                            errorState = true;
                            errorText = "Does not match with Dependent Details";
                        }
                    }
                }

            }

            if (props.rowData.firstNameNominee && props.rowData.lastNameNominee && props.rowData.dob && props.rowData.relationWithEmployee) {
                var count = 0;
                if (dependentData && dependentData.length > 0) {
                    for (var i = 0; i < dependentData.length; i++) {
                        var depDate = new Date(dependentData[i].dependentDob).toDateString();
                        var provDate = new Date(props.rowData.dob).toDateString();

                        if (dependentData[i].dependentFirstName === props.rowData.firstNameNominee && dependentData[i].dependentLastName === props.rowData.lastNameNominee
                            && depDate === provDate && ((dependentData[i].relationshipId === props.rowData.relationWithEmployee.value && dependentData[i].relationship === props.rowData.relationWithEmployee.label) || dependentData[i].relationship === props.rowData.relationWithEmployee)) {
                            count = count + 1;
                            break;
                        }
                    }
                    if (count == 0) {
                        errorState = true;
                        errorText = "Nominee and Dependent Details should match";

                    }
                    else {
                        errorText = '';
                        errorState = false;
                    }
                }
            }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              clearable
              disableFuture
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    {
      title: "Relationship*",
      field: "relationWithEmployee",
      validate: (rowData) => ValidateRelationship(rowData),
      editComponent: (props) => {
        const relationWithEmployeeData = props.rowData.relationWithEmployeeData
          ? props.rowData.relationWithEmployeeData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
          const relationWithEmployee = props.rowData.relationWithEmployee;

          let dependentDataRelationship = null;
          if (dependentData && dependentData.length > 0 && relationWithEmployee) {
              dependentDataRelationship = dependentData.find(x => (x.relationshipId === relationWithEmployee.value && x.relationship === relationWithEmployee.label) || x.relationship === relationWithEmployee)
          }

          var count = 0;
          if (props.rowData.firstNameNominee && props.rowData.lastNameNominee && props.rowData.dob && props.rowData.relationWithEmployee) {

              if (dependentData && dependentData.length > 0) {
                  for (var i = 0; i < dependentData.length; i++) {
                      var depDate = new Date(dependentData[i].dependentDob).toDateString();
                      var provDate = new Date(props.rowData.dob).toDateString();

                      if (dependentData[i].dependentFirstName === props.rowData.firstNameNominee && dependentData[i].dependentLastName === props.rowData.lastNameNominee
                          && depDate === provDate && ((dependentData[i].relationshipId === props.rowData.relationWithEmployee.value && dependentData[i].relationship === props.rowData.relationWithEmployee.label) || dependentData[i].relationship === props.rowData.relationWithEmployee)) {
                          count = count + 1;
                          break;
                      }
                      else {
                          count = 2;
                      }
                  }
                  //if (count == 0) {
                  //    errorState = true;
                  //    errorText = "Nominee and Dependent Details should match";

                  //}
                  //else {
                  //    errorText = '';
                  //    errorState = false;
                  //}
              }
          }

        const customStyles =
          relationWithEmployee !== undefined &&
          relationWithEmployee !== null &&
                relationWithEmployee !== "" && dependentDataRelationship
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
          return (
              <React.Fragment>
          <Select
            options={itemsRelationship}
            value={
              typeof props.value === "string"
                ? relationWithEmployeeData
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
                  <React.Fragment>
                      {
                          (!dependentDataRelationship && relationWithEmployee != undefined && count != 2)
                              ?
                              <span style={{ color: 'red' }} className="f-10">Does not match with Dependent Details</span>
                              :
                              <React.Fragment></React.Fragment>
                      }
                      {
                          (count == 2 && relationWithEmployee != undefined)
                              ?
                              <span style={{ color: 'red' }} className="f-10">Nominee and Dependent Details should match</span>
                              :
                              <React.Fragment></React.Fragment>
                      }

                  </React.Fragment>
              </React.Fragment>
          )


      }
    },
    {
      title: "Share In Percentage *",
      field: "sharePercentage",
      validate: (rowData) => ValidateNomineeShare(rowData),
      editComponent: (props) => {
        const sharePercentage = props.rowData.sharePercentage;

        let errorText = "";
        var errorState = false;
        if (sharePercentage === null || sharePercentage === "") {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          if (
            sharePercentage != "" &&
            sharePercentage != undefined &&
            sharePercentage != null &&
            sharePercentage > 100
          ) {
            errorState = true;
            errorText = "Share cannot be more than 100";
          } else if (
            props.rowData.tableData != undefined &&
            props.rowData.tableData != null
          ) {
            var result = getSharePercentage(
              sharePercentage,
              props.rowData.tableData.id
            );
            if (
              result != undefined &&
              result != "" &&
              result != null &&
              result == 1 &&
              sharePercentage <= 100
            ) {
              errorState = true;
              errorText = "Summation of share cannot be more than 100";
            }
          } else {
            errorText = "";
            errorState = false;
          }
        }
        return (
          <TextField
            defaultValue={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            //type="number"
            onKeyDown={handleKeypress}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },
    {
      title: "Address*",
      field: "nomineeAddress",
      validate: (rowData) => ValidateNomineeAddress(rowData),
      editComponent: (props) => {
        const nomineeAddress = props.rowData.nomineeAddress;

        let errorText = "";
        var errorState = false;

        if (nomineeAddress === null || nomineeAddress === "") {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          if (!IsValidAddressLines(nomineeAddress)) {
            errorState = true;
            var str = "#/?><'" + '";:-}{][*&%$@!()';
            errorText = str.toString() + " are not allowed";
          } else {
            errorText = "";
            errorState = false;
          }
        }

        return (
          <TextField
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },
    {
      title: "Is Minor*",
      field: "validationOfMinorYesOrNo",
      validate: (rowData) => ValidateMinor(rowData),
      editComponent: (props) => {
        const validationOfMinorYesOrNoData = props.rowData
          .validationOfMinorYesOrNoData
          ? props.rowData.validationOfMinorYesOrNoData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const validationOfMinorYesOrNo = props.rowData.validationOfMinorYesOrNo;
        const customStyles =
          validationOfMinorYesOrNo !== undefined &&
          validationOfMinorYesOrNo !== null &&
          validationOfMinorYesOrNo !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsValidationOfMinor}
            value={
              typeof props.value === "string"
                ? validationOfMinorYesOrNoData
                : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Guardian’s Name",
      field: "gaurdianName",
      validate: (rowData) => ValidateGuardianName(rowData),
      editComponent: (props) => {
        const validationOfMinorYesOrNo = props.rowData.validationOfMinorYesOrNo;
        const gaurdianName = props.rowData.gaurdianName;

        let errorText = "";
        var errorState = false;
        if (
          validationOfMinorYesOrNo !== null &&
          validationOfMinorYesOrNo !== "" &&
          validationOfMinorYesOrNo !== undefined &&
          (validationOfMinorYesOrNo === "Yes" ||
            validationOfMinorYesOrNo.label === "Yes") &&
          (gaurdianName === null ||
            gaurdianName === "" ||
            gaurdianName === undefined)
        ) {
          errorState = true;
          errorText = "Mandatory Field for minor";
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <TextField
            defaultValue={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },
    {
      title: "Guardian’s Address",
      field: "gaurdianAddress",
      validate: (rowData) => ValidateGuardianAddress(rowData),
      editComponent: (props) => {
        const validationOfMinorYesOrNo = props.rowData.validationOfMinorYesOrNo;
        const gaurdianAddress = props.rowData.gaurdianAddress;

        let errorText = "";
        var errorState = false;
        if (
          validationOfMinorYesOrNo !== null &&
          validationOfMinorYesOrNo !== "" &&
          validationOfMinorYesOrNo !== undefined &&
          (validationOfMinorYesOrNo === "Yes" ||
            validationOfMinorYesOrNo.label === "Yes") &&
          (gaurdianAddress === null ||
            gaurdianAddress === "" ||
            gaurdianAddress === undefined)
        ) {
          errorState = true;
          errorText = "Mandatory Field for minor";
        } else {
          if (!IsValidAddressLines(gaurdianAddress)) {
            errorState = true;
            var str = "#/?><'" + '";:-}{][*&%$@!()';
            errorText = str.toString() + " are not allowed";
          } else {
            errorText = "";
            errorState = false;
          }
        }
        return (
          <TextField
            defaultValue={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const handleErrorClose = (e) => {
    setIserror(false);
  };

  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (props.token) {
      getRelationship();
      getValidationOfMinor();
    }
  }, [props.token]);

  //useEffect(() => {
  //    api.get("/users")
  //        .then(res => {
  //            setData(res.data.data)
  //        })
  //        .catch(error => {
  //            console.log("Error")
  //        })
  //}, [])

  function GetSuperAnnuationData(id) {
    setIsLoader(true);
    const token = props.token;
    axios
      .get("/OnBoardingForm/GetSuperAnnutationData/" + id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error : ", error);
      });
    }

    function GetDependentData(id) {
        const token = props.token;
        axios.get('/OnBoardingForm/GetDependentData/' + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then((res) => {
                setDependentData(res.data);
            })
            .catch(error => {
                console.log("Error");
            })
    }

  useEffect(() => {
    if (props.token) {
      if (props.id != null && props.id != undefined) {
          GetSuperAnnuationData(props.id);
          GetDependentData(props.id);
      }
    }
  }, [props.id, props.token]);

  //useEffect(() => {
  //    axios.get('/OnBoardingForm/GetSuperAnnutationData/' + props.id)
  //        .then((res) => {
  //            setData(res.data)
  //        })
  //        .catch(error => {
  //            console.log("Error")
  //        })

  //}, [])

  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    //validation
    let errorList = [];
    //if (newData.firstNameNominee === undefined || newData.firstNameNominee === "" || newData.firstNameNominee === null) {
    //    errorList.push("Please enter First Name")
    //}
    ////if (newData.middleNameNominee === undefined || newData.middleNameNominee === "") {
    ////    errorList.push("Please enter Middle Name")
    ////}
    //if (newData.lastNameNominee === undefined || newData.lastNameNominee === "" || newData.lastNameNominee === null) {
    //    errorList.push("Please enter Last Name")
    //}
    //if (newData.dob === undefined || newData.dob === "" || newData.dob === null) {
    //    errorList.push("Please enter Date Of Birth")
    //}
    //if (newData.relationWithEmployee === undefined || newData.relationWithEmployee === "" || newData.relationWithEmployee === null) {
    //    errorList.push("Please enter Relationship")
    //}
    //if (newData.sharePercentage === undefined || newData.sharePercentage === "" || newData.sharePercentage === null) {
    //    errorList.push("Please enter Share In Percentage")
    //}
    //if (newData.nomineeAddress === undefined || newData.nomineeAddress === "" || newData.nomineeAddress === null) {
    //    errorList.push("Please enter Nominee Address")
    //}
    ////if (newData.gaurdianName === undefined) {
    ////    errorList.push("Please enter Guardian Name")
    ////}
    ////if (newData.gaurdianAddress === undefined) {
    ////    errorList.push("Please enter Guardian Address")
    ////}
    //if (newData.validationOfMinorYesOrNo === undefined || newData.validationOfMinorYesOrNo === "" || newData.validationOfMinorYesOrNo === null) {
    //    errorList.push("Please enter Validation Of Minor")
    //}

    //if (newData.sharePercentage != "" && newData.sharePercentage != undefined && newData.sharePercentage != null && newData.sharePercentage > 100) {
    //    errorList.push("Share in percentage cannot be greater than 100")
    //}

    //var result = getSharePercentage(newData.sharePercentage, oldData.tableData.id);
    //if (result != undefined && result != "" && result != null && result == 1 && newData.sharePercentage <= 100) {
    //    errorList.push("Summation of Share in percentage cannot be greater than 100")
    //}

      if (newData.firstNameNominee && newData.lastNameNominee && newData.dob && newData.relationWithEmployee) {
          var count = 0;
          if (dependentData && dependentData.length > 0) {
              for (var i = 0; i < dependentData.length; i++) {
                  var depDate = new Date(dependentData[i].dependentDob).toDateString();
                  var provDate = new Date(newData.dob).toDateString();

                  if (dependentData[i].dependentFirstName === newData.firstNameNominee && dependentData[i].dependentLastName === newData.lastNameNominee
                      && depDate === provDate && ((dependentData[i].relationshipId === newData.relationWithEmployee.value && dependentData[i].relationship === newData.relationWithEmployee.label) || dependentData[i].relationship === newData.relationWithEmployee)) {
                      count = count + 1;
                      break;
                  }
              }
              if (count == 0) {
                  errorList.push("Nominee and Dependent Details should match")
              }
          }
      }


    //added for formatting of newData start
    const relationWithEmployee =
      newData.relationWithEmployee === null
        ? null
        : typeof newData.relationWithEmployee === "object"
        ? newData.relationWithEmployee.label
        : newData.relationWithEmployee;
    const validationOfMinorYesOrNo =
      newData.validationOfMinorYesOrNo === null
        ? null
        : typeof newData.validationOfMinorYesOrNo === "object"
        ? newData.validationOfMinorYesOrNo.label
        : newData.validationOfMinorYesOrNo;

    newData.relationWithEmployee = relationWithEmployee;
    newData.validationOfMinorYesOrNo = validationOfMinorYesOrNo;

    // end

    newData.superannuationDetailsId = oldData.tableData.id;
    newData.employeeId = props.id;
   // newData.userId = props.loginDetail[0].userid;
      newData.userId = props.userId;
      newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
    if (errorList.length < 1) {
      //api.patch("/users/" + newData.id, newData)
      //    .then(res => {
      //        const dataUpdate = [...data];
      //        const index = oldData.tableData.id;
      //        dataUpdate[index] = newData;
      //        setData([...dataUpdate]);
      //        resolve()
      //        setIserror(false)
      //        setErrorMessages([])
      //    })
      const token = props.token;
      axios
        .put("/OnBoardingForm/UpdateSuperAnnutationData", newData, {
          headers: { Authorization: "Bearer " + token },
        })

        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            // resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          // resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
      reject();
    }
  };

  const handleRowAdd = (newData, resolve, reject) => {
    //validation
    let errorList = [];
    if (
      newData.firstNameNominee === undefined ||
      newData.firstNameNominee === "" ||
      newData.firstNameNominee === null
    ) {
      errorList.push("Please enter First Name");
    }
    //if (newData.middleNameNominee === undefined || newData.middleNameNominee === "") {
    //    errorList.push("Please enter Middle Name")
    //}
    if (
      newData.lastNameNominee === undefined ||
      newData.lastNameNominee === "" ||
      newData.lastNameNominee === null
    ) {
      errorList.push("Please enter Last Name");
    }
    if (
      newData.dob === undefined ||
      newData.dob === "" ||
      newData.dob === null
    ) {
      errorList.push("Please enter Date Of Birth");
    }
    if (
      newData.relationWithEmployee === undefined ||
      newData.relationWithEmployee === "" ||
      newData.relationWithEmployee === null
    ) {
      errorList.push("Please enter Relationship");
    }
    if (
      newData.sharePercentage === undefined ||
      newData.sharePercentage === "" ||
      newData.sharePercentage === null
    ) {
      errorList.push("Please enter Share In Percentage");
    }
    if (
      newData.nomineeAddress === undefined ||
      newData.nomineeAddress === "" ||
      newData.nomineeAddress === null
    ) {
      errorList.push("Please enter Nominee Address");
    }
    //if (newData.gaurdianName === undefined) {
    //    errorList.push("Please enter Guardian Name")
    //}
    //if (newData.gaurdianAddress === undefined) {
    //    errorList.push("Please enter Guardian Address")
    //}
    if (
      newData.validationOfMinorYesOrNo === undefined ||
      newData.validationOfMinorYesOrNo === "" ||
      newData.validationOfMinorYesOrNo === null
    ) {
      errorList.push("Please enter Is Minor");
    }
    if (
      newData.validationOfMinorYesOrNo !== undefined &&
      newData.validationOfMinorYesOrNo !== "" &&
      newData.validationOfMinorYesOrNo !== null &&
      (newData.validationOfMinorYesOrNo === "Yes" ||
        newData.validationOfMinorYesOrNo.label === "Yes") &&
      (newData.gaurdianName === null ||
        newData.gaurdianName === "" ||
        newData.gaurdianName === undefined)
    ) {
      errorList.push("Please enter Guardian Name for minor");
    }

    if (
      newData.validationOfMinorYesOrNo !== undefined &&
      newData.validationOfMinorYesOrNo !== "" &&
      newData.validationOfMinorYesOrNo !== null &&
      (newData.validationOfMinorYesOrNo === "Yes" ||
        newData.validationOfMinorYesOrNo.label === "Yes") &&
      (newData.gaurdianAddress === null ||
        newData.gaurdianAddress === "" ||
        newData.gaurdianAddress === undefined)
    ) {
      errorList.push("Please enter Guardian Address for minor");
    }

    if (
      newData.sharePercentage != undefined &&
      newData.sharePercentage != "" &&
      newData.sharePercentage != null &&
      newData.sharePercentage > 100
    ) {
      errorList.push("Share in percentage cannot be greater than 100");
    }

    var result = getSharePercentage(newData.sharePercentage, -1);
    if (
      result != undefined &&
      result != "" &&
      result != null &&
      result == 1 &&
      newData.sharePercentage <= 100
    ) {
      errorList.push(
        "Summation of Share in percentage cannot be greater than 100"
      );
      }

      if (newData.firstNameNominee && newData.lastNameNominee && newData.dob && newData.relationWithEmployee) {
          var count = 0;
          if (dependentData && dependentData.length > 0) {
              for (var i = 0; i < dependentData.length; i++) {
                  var depDate = new Date(dependentData[i].dependentDob).toDateString();
                  var provDate = new Date(newData.dob).toDateString();

                  if (dependentData[i].dependentFirstName === newData.firstNameNominee && dependentData[i].dependentLastName === newData.lastNameNominee
                      && depDate === provDate && ((dependentData[i].relationshipId === newData.relationWithEmployee.value && dependentData[i].relationship === newData.relationWithEmployee.label) || dependentData[i].relationship === newData.relationWithEmployee)) {
                      count = count + 1;
                      break;
                  }
              }
              if (count == 0) {
                  errorList.push("Nominee and Dependent Details should match")
              }
          }
      }

    //added for formatting of newData start
    const relationWithEmployee =
      newData.relationWithEmployee === null
        ? null
        : typeof newData.relationWithEmployee === "object"
        ? newData.relationWithEmployee.label
        : newData.relationWithEmployee;
    const validationOfMinorYesOrNo =
      newData.validationOfMinorYesOrNo === null
        ? null
        : typeof newData.validationOfMinorYesOrNo === "object"
        ? newData.validationOfMinorYesOrNo.label
        : newData.validationOfMinorYesOrNo;

    newData.relationWithEmployee = relationWithEmployee;
    newData.validationOfMinorYesOrNo = validationOfMinorYesOrNo;

    // end

    newData.employeeId = props.id;
   // newData.userId = props.loginDetail[0].userid;
      newData.userId = props.userId;
      newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
    if (errorList.length < 1) {
      //no error
      //api.post("/users", newData)
      //    .then(res => {
      //        let dataToAdd = [...data];
      //        dataToAdd.push(newData);
      //        setData(dataToAdd);
      //        resolve()
      //        setErrorMessages([])
      //        setIserror(false)
      //    })
      const token = props.token;
      axios
        .post("/OnBoardingForm/SaveSuperAnnutationData", newData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          // resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
      reject();
    }
  };

  const handleRowDelete = (oldData, resolve, reject) => {
    //api.delete("/users/" + oldData.id)
    //    .then(res => {
    //        const dataDelete = [...data];
    //        const index = oldData.tableData.id;
    //        dataDelete.splice(index, 1);
    //        setData([...dataDelete]);
    //        resolve()
    //    })

    oldData.superannuationDetailsId = oldData.tableData.id;
    oldData.employeeId = props.id;
    const token = props.token;
    axios
      .put("/OnBoardingForm/DeleteSuperAnnutationData", oldData, {
        headers: { Authorization: "Bearer " + token },
      })

      .then((response) => {
        if (response.data.response) {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          resolve();
          setIserror(false);
          setErrorMessages([]);
        } else {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          // resolve();
          reject();
          setIserror(false);
          setErrorMessages([]);
        }
      })

      .catch((error) => {
        reject();
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        // resolve()
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <Grid container spacing={1}>
          {/* <p className="f-12">
            Note: 1. At least one dependent details is mandatory to fill
            SuperAnnuation Nomination
            <br />
            2. Nominee Details should match with Dependent Details (First name,
            Last name, DOB, Relationship)
          </p> */}
          <p className="f-12">
            Note:
            <span className="ml-1">
              1. At least one nominee should be first registred as a dependant
              in the dependant details tab
            </span>
            <br />
            <span className="ml-2 pl-2">
              2. Nominee Details should match with Dependent Details (First
              name, Last name, DOB, Relationship)
            </span>
          </p>
          <Grid item xs={12}>
            <div>
              {iserror && (
                <Alert severity="error" onClose={handleErrorClose}>
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              title="Superannuation Nomination"
              columns={columns}
              data={data}
              isLoading={isLoader}
              components={{
                OverlayLoading: (props) => <Loader {...props} />,
              }}
              icons={tableIcons}
              localization={{
                header: {
                  actions: "",
                },
              }}
              options={{
                search: false,
                toolbar:
                  props.disableValue === true
                    ? false
                    : dependentData.length > 0
                    ? true
                    : false,
              }}
              editable={{
                isEditable: (rowData) => props.disableValue === false,
                isDeletable: (rowData) => props.disableValue === false,

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(newData, oldData, resolve, reject);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newData, resolve, reject);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldData, resolve, reject);
                  }),
              }}
            />
            <div className="d-flex jc-center">
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2 ml-2"
                disabled={props.disableValue}
                onClick={ValidateShareOnSave}
              >
                <span>Submit</span>
              </Button>
            </div>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle className={openClassName} id="draggable-dialog-title">
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default SuperAnnuationNomination;
