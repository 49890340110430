import { Checkbox, CircularProgress, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { checkedIcon, icon, getRoundUptoXDecimals } from "./utilsDHLPrerequisites";
import Loader from "../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { inputDataDHLAction, compensationDetailsAction, compensationWSADetailsAction, insuranceDetailsAction, insuranceDataAction } from "../../Redux/Actions/actions";

export default function CompensationDetailsDHL(props) {
  let [loaderProps, setLoaderProps] = useState({
    isEnable: false,
    loaderText: "",
  });
    const dispatch = useDispatch();
    let encryptedId = getURLParameter("id");
    let data, id;
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }

    var compensationfactor1 = localStorage.getItem("showCompensation1"),
        compensationfactor2 = localStorage.getItem("showCompensation2");
    const storedInputDataDHL = useSelector(state => state.inputDataDHLReducer.inputDataDHL);
  const handleLoader = (isEnable, text) => {
    setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
    };
    let [compensationProgressProps, setCompensationProgressProps] = useState({
        isEnable: false,
        loaderText: "",
    });
  const ProgressComponent = (
    <h4
      style={{
        fontStyle: "italic",
        color: "#2EE6E2",
        textAlign: "center",
      }}
    >

      <CircularProgress style={{ height: `25px`, width: `25px` }} />
      {compensationProgressProps?.loaderText}
    </h4>
    );

    let [inputDataToDHL, setInputDataToDHL] = useState({
        applicantId: getURLParameter("id"),
        userIdencrypted: props.userId,
        firstName: "",
        lastName: "",
        email: "",
        areObj: {},
        taname: "",
        taemail: "",
        address: "",
        dateOfBirth: null,
        mprnumber: "",
        referenceNo: "",
        letterType: 0,
        letterTypeLabel: "",
        letterTypeObj: {},
        letterTemplateType: 0,
        letterTemplateTypeLabel: "",
        letterTemplateTypeObj: {},
        noOfSignatories: 0,
        noOfSignatoriesLabel: "",
        noOfSignatoriesObj: {},
        signatoryDetails: [],
        employeeType: 0,
        employeeTypeLabel: "",
        employeeTypeObj: {},
        jobLocation: 0,
        jobLocationLabel: "",
        jobLocationObj: {},
        jobTitle: 0,
        jobTitleLabel: "",
        jobTitleObj: {},
        businessTitle: "",
        businessUnit: "",
        grip: 0,
        gripLabel: "",
        gripCode: "",
        gripObj: {},
        staff: 0,
        staffLabel: "",
        staffObj: {},
        compensationGrade: 0,
        compensationGradeLabel: "",
        compensationGradeObj: {},
        compensationGradeProfile: 0,
        compensationGradeProfileLabel: "",
        compensationGradeProfileObj: {},
        payPlan: 1,
        payPlanLabel: "Salary Plan",
        payPlanObj: { id: 1, name: "Salary Plan" },
        performencePayPlan: 0,
        performencePayPlanLabel: "",
        performencePayPlanObj: {},
        salaryAmount: 0,
        performencePay: 0,
        targetPay: 0,
        allowancePlan: "",
        allowancePlanLabel: "",
        allowancePlanObj: [],
        allowanceAmountObj: [],
        bfp: 0,
        bp: 0,
        bpa: 0,
        hra: 0,
        totalRetirals: 0,
        pfContri: 0,
        gratuityContri: 0,
        negotiationLetterType: 0,
        negotiationLetterTypeLabel: "",
        negotiationLetterTypeObj: {},
        compensationLetterUploadId: 0,
        isCompensationLetterUploaded: false,
        isNegotiationSent: false,
        digiLetterWorkflowId: 0,
        isCompensationAccepted: true,
        isCompensationRejected: false,
        digiLetterStatusId: 0,
        isOfferAccepted: false,
        isOfferRejected: false,
        offerLetterUploadId: 0,
        isOfferLetteruploaded: false,
        siemensLogo: "../assets/images/sie-logo-petrol-rgb.png",
    });
  //fetch Compensation Grade
  const [itemsCompensationGrade, setItemsCompensationGrade] = useState([]);
  async function getCompensationGrade() {

    const token = props.token;
    handleLoader(true, "Fetching Calculation...");
    const response = await fetch("HRDetails/GetCompensationGrade", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();

    setItemsCompensationGrade(jsonItems);
    handleLoader(false, "");

  }

  //fetch Compensation Grade Profile
  const [itemsCompensationGradeProfile, setItemsCompensationGradeProfile] =
    useState([]);
  async function getCompensationGradeProfile(id) {
    handleLoader(true, "Fetching Grade Profile....");
    const token = props.token;
    const response = await fetch(
      "HRDetails/GetCompensationGradeProfile/" + id,
      { headers: { Authorization: "Bearer " + token } }
    );
    const jsonItems = await response.json();
    setItemsCompensationGradeProfile(jsonItems);
    handleLoader(false, "");
  }

  //fetch Pay Plan
  const [itemsPayPlan, setItemsPayPlan] = useState([]);
  async function getPayPlan() {
    const token = props.token;
    handleLoader(true, "Fetching Payplan....");
    const response = await fetch("HRDetails/GetPayPlan", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsPayPlan(jsonItems);
    handleLoader(false, "");
  }
  const [itemsPerformencePayPlan, setItemsPerformencePayPlan] = useState([]);
  async function getPerformencePayPlan() {
    const token = props.token;
    handleLoader(true, "Fetching Performance Payplan....");
    const response = await fetch("HRDetails/GetBonusPlan", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsPerformencePayPlan(jsonItems);
    handleLoader(false, "");
  }
  //fetch Allowance Plan
  const [itemsAllowancePlan, setItemsAllowancePlan] = useState([]);
  function getAllowancePlan(employeeType, staff) {
    handleLoader(true, "Fetching Allowance Plan...");
    //props.setCompensationProgressProps({
    //  ...props.compensationProgressProps,
    //  isEnable: true,
    //  loaderText: "Fetching compensation structure....",
    //});
    const token = props.token;
    const options = {
      method: "get",
      url: "OnboardingDHLMerge/GetAllowancePlan",
      headers: {
        "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
      params: {
        employeeType: employeeType,
        staff: staff,
      },
    };
    axios(options)
      .then((response) => {
        if (response.data && response.data?.length > 0) {
          setItemsAllowancePlan(response.data);
          //props.setCompensationProgressProps({
          //  ...props.compensationProgressProps,
          //  isEnable: true,
          //    loaderText: "Loading Test1",

          /* });*/
        }
      })
      .catch((error) => {
        console.log(error);
        //props.setCompensationProgressProps({
        //  ...props.compensationProgressProps,
        //  isEnable: true,
        //    loaderText: "Loading Test2",
        //});
      });
    handleLoader(false, "");
  }

    const handleAutoCompleteChange = (e, newValue) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        switch (elemName) {
            case "compensationGrade":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "compensationGradeProfile":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "allowancePlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "payPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "performencePayPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "negotiationLetterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "noOfSignatories":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterTemplateType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };
    const handleAutoCompleteTextChange = (e, newText) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        switch (elemName) {
            case "compensationGrade":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "compensationGradeProfile":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "payPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "performencePayPlan":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "negotiationLetterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "noOfSignatories":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "letterTemplateType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };
    const [itemsNoOfSignatories] = useState([
        { value: 0, label: "None" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
    ]);
    const handleChange = (e) => {
        let value = e.target.value;
        let inputUpdatedDHL = {};
        switch (e.target.name) {
            case "noOfSignatoriesLabel":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [e.target.name]: itemsNoOfSignatories.find((x) => x.value === value)
                //         ?.label,
                //     noOfSignatoriesValue: parseInt(value),
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [e.target.name]: itemsNoOfSignatories.find((x) => x.value === value)
                        ?.label,
                    noOfSignatoriesValue: parseInt(value),
                };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "targetPay":
                value = parseFloat(e.target.value);
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "joiningBonus":
                value = parseFloat(e.target.value);
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "recoveryDuration":
                value = e.target.value > 0 ? parseInt(e.target.value) : 0;
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "businessTitle":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "businessUnit":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            case "gripCode":
                // setInputDataToDHL({ ...inputDataToDHL, [e.target.name]: value });
                inputUpdatedDHL = { ...storedInputDataDHL, [e.target.name]: value };
                setInputDataToDHL(inputUpdatedDHL);
                dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
    };
    function getDefaultSelectedAllowancePlan(staff, employeeType) {
        let defaultAllowance = itemsAllowancePlan.filter(
            (x) =>
                x.name.includes("Base Flexible Pay p.m.") ||
                x.name.includes("Total Retirals p.a.")
        );
        if (staff === 3 && employeeType === 1) {
            //setInputDataToDHL({
            //    ...inputDataToDHL,
            //    allowancePlanObj: defaultAllowance,
            //    allowanceAmountObj: defaultAllowance.map((c) => ({
            //        id: c.id,
            //        name: c.name,
            //        allowanceAmount: 0.0,
            //    })),
            //});
            let inputUpdatedDHL = {
                ...storedInputDataDHL,
                allowancePlanObj: defaultAllowance,
                allowanceAmountObj: defaultAllowance.map((c) => ({
                    id: c.id,
                    name: c.name,
                    allowanceAmount: 0.0,
                })),
            };
            setInputDataToDHL(inputUpdatedDHL);
            dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
        setCompensationProgressProps({
            ...compensationProgressProps,
            isEnable: false,
            loaderText: "",
        });
    }
  useEffect(() => {
    console.log("Compensation details", props);
    if (props) {
      getCompensationGrade();
      getPayPlan();
      getPerformencePayPlan();
    }
  }, [props.token]);
  useEffect(() => {
    if (
        storedInputDataDHL?.employeeType > 0 &&
        storedInputDataDHL?.staff > 0
    ) {
      getAllowancePlan(
          storedInputDataDHL?.employeeType,
          storedInputDataDHL?.staff
      );
    }
  }, [storedInputDataDHL?.staff, storedInputDataDHL?.employeeType]);

  useEffect(() => {
      if (storedInputDataDHL?.compensationGrade > 0) {
          getCompensationGradeProfile(storedInputDataDHL?.compensationGrade);
    }
  }, [storedInputDataDHL?.compensationGrade]);
  useEffect(() => {
    if (
      itemsAllowancePlan?.length > 0 &&
        storedInputDataDHL?.staff > 0 &&
        storedInputDataDHL?.employeeType > 0
    )
      setCompensationProgressProps({
        ...compensationProgressProps,
        isEnable: true,
        loaderText: "Fetching compensation structure...",
      });
    getDefaultSelectedAllowancePlan(
        storedInputDataDHL?.staff,
        storedInputDataDHL?.employeeType
    );
  }, [
      storedInputDataDHL?.staff,
      storedInputDataDHL?.employeeType,
    itemsAllowancePlan,
  ]);


    
    let [compensationAmount, setCompensationAmount] = useState({
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    });
    let compensationAmountUpdated = {
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    };
    let [compensationWSAAmount, setCompensationWSAAmount] = useState({
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        safpm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    });
    let compensationWSAAmountUpdated = {
        targetPay: 0.0,
        basicpa: 0.0,
        basicpm: 0.0,
        tfppa: 0.0,
        tfppm: 0.0,
        bfppm: 0.0,
        pfpm: 0.0,
        gratuitypm: 0.0,
        safpm: 0.0,
        totalRetiralspm: 0.0,
        totalRetiralspa: 0.0,
        tapa: 0.0,
        bppa: 0.0,
        pppa: 0.0,
        elepa: 0.0,
        hra: 0.0,
        bpa: 0.0,
    };

    let [insuranceAmount, setInsuranceAmount] = useState({
        ghic: 0.0,
        gpaic: 0.0,
        gtLic: 0.0,
        edli: 0.0,
        esic: "",
    });
    let insuranceAmountUpdated = {
        ghic: 0.0,
        gpaic: 0.0,
        gtLic: 0.0,
        edli: 0.0,
        esic: "",
    };
    let [compensationFactors, setCompensationFactors] = useState({
        compensationFactorId: 0,
        gripId: 0,
        letterTypeId: 0,
        basicSalaryFactor: 0.0,
        performencePayFactor: 0.0,
        pfFactor: 0.0,
        gratuityFactor: 0.0,
        totalFixedPayFactor: 0.0,
        safFactor: 0.0,
        totalFixedPayWithSafFactor: 0.0,
    });
    let [insuranceData, setInsuranceData] = useState({
        id: 0,
        gripId: 0,
        ghicAmount: 0.0,
        gpaicAmount: 0.0,
        gtLicAmount: 0.0,
        edliAmount: 0.0,
        micAmount: 0.0,
    });
    let insuranceDataUpdated = {
        id: 0,
        gripId: 0,
        ghicAmount: 0.0,
        gpaicAmount: 0.0,
        gtLicAmount: 0.0,
        edliAmount: 0.0,
        micAmount: 0.0,
    };
    function compensationCalculationforPL13_14(value) {

        handleLoader(true, "Fetching data....");
        let tfppa = getRoundUptoXDecimals(
            value * compensationFactors.totalFixedPayFactor,
            0
        );
        let basicpa = getRoundUptoXDecimals(
            tfppa * compensationFactors.basicSalaryFactor,
            0
        );
        let basicpm = getRoundUptoXDecimals(basicpa / 12, 0);
        let pfpm = 0;
        if (basicpm > 15000) {
            pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
        } else {
            // pfpm = 1800;
            pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
        }
        let gratuitypm = getRoundUptoXDecimals(
            basicpm * compensationFactors.gratuityFactor,
            0
        );
        let totalRetiralspm = getRoundUptoXDecimals(pfpm + gratuitypm, 0);
        let totalRetiralspa = getRoundUptoXDecimals(totalRetiralspm * 12, 0);
        let tapa = getRoundUptoXDecimals(tfppa - totalRetiralspa - basicpa, 0);
        let bfppm = getRoundUptoXDecimals(tapa / 12 - 0, 0);
        let bppa = getRoundUptoXDecimals(basicpa + tapa, 0);
        let tfppm = getRoundUptoXDecimals(bppa / 12 + totalRetiralspm, 0);
        let pppa = getRoundUptoXDecimals(
            (tfppa - totalRetiralspa) * compensationFactors.performencePayFactor,
            0
        );
        let elepa = getRoundUptoXDecimals((basicpm / 30) * 22, 0);

        //With SAF
        let tfpWSApa = getRoundUptoXDecimals(
            value * compensationFactors.totalFixedPayWithSafFactor,
            0
        );
        let basicWSApa = getRoundUptoXDecimals(
            tfpWSApa * compensationFactors.basicSalaryFactor,
            0
        );
        let basicWSApm = getRoundUptoXDecimals(basicWSApa / 12, 0);
        let pfWSApm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.pfFactor,
            0
        );
        let gratuityWSApm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.gratuityFactor,
            0
        );
        let safpm = getRoundUptoXDecimals(
            basicWSApm * compensationFactors.safFactor,
            0
        );
        let totalRetiralsWSApm = getRoundUptoXDecimals(
            pfWSApm + gratuityWSApm + safpm,
            0
        );
        let totalRetiralsWSApa = getRoundUptoXDecimals(totalRetiralsWSApm * 12, 0);
        let taWSApa = getRoundUptoXDecimals(
            tfpWSApa - totalRetiralsWSApa - basicWSApa,
            0
        );
        let bfpWSApm = getRoundUptoXDecimals(taWSApa / 12 - 0, 0);
        let bpWSApa = getRoundUptoXDecimals(basicWSApa + taWSApa, 0);
        let tfpWSApm = getRoundUptoXDecimals(bpWSApa / 12 + totalRetiralsWSApm, 0);
        let ppWSApa = getRoundUptoXDecimals(
            (tfpWSApa - totalRetiralsWSApa) *
            compensationFactors.performencePayFactor,
            0
        );
        let eleWSApa = getRoundUptoXDecimals((basicWSApm / 30) * 22, 0);

        //Insurances
        let ghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
        let gpaicpa = getRoundUptoXDecimals(
            value < (insuranceData.gpaicAmount) / 2 ? insuranceData.gpaicAmount : value * 2,
            0
        );
        let gtLicpa = getRoundUptoXDecimals(
            value < (insuranceData.gtLicAmount) / 2 ? insuranceData.gtLicAmount : value * 2,
            0
        );
        let edlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
        let esic =
            getRoundUptoXDecimals(tapa / 12, 0) < 23200 ||
                getRoundUptoXDecimals(taWSApa / 12, 0) < 23200
                ? "Eligible as gross salary below ESI eligibility"
                : " Not applicable as gross salary above ESI eligibility";
        //setCompensationAmount({
        //    ...compensationAmount,
        //    targetPay: getRoundUptoXDecimals(value, 0),
        //    basicpa: basicpa,
        //    basicpm: basicpm,
        //    tfppa: tfppa,
        //    tfppm: tfppm,
        //    bfppm: bfppm,
        //    pfpm: pfpm,
        //    gratuitypm: gratuitypm,
        //    totalRetiralspm: totalRetiralspm,
        //    totalRetiralspa: totalRetiralspa,
        //    tapa: tapa,
        //    bppa: bppa,
        //    pppa: pppa,
        //    elepa: elepa,
        //    hra: 0.0,
        //    bpa: 0.0,
        //});
        compensationAmountUpdated = {
            ...compensationAmount,
            targetPay: getRoundUptoXDecimals(value, 0),
            basicpa: basicpa,
            basicpm: basicpm,
            tfppa: tfppa,
            tfppm: tfppm,
            bfppm: bfppm,
            pfpm: pfpm,
            gratuitypm: gratuitypm,
            totalRetiralspm: totalRetiralspm,
            totalRetiralspa: totalRetiralspa,
            tapa: tapa,
            bppa: bppa,
            pppa: pppa,
            elepa: elepa,
            hra: 0.0,
            bpa: 0.0,
        };
        setCompensationAmount(compensationAmountUpdated);
        dispatch(compensationDetailsAction(compensationAmountUpdated));

        //setCompensationWSAAmount({
        //    ...compensationWSAAmount,
        //    targetPay: getRoundUptoXDecimals(value, 0),
        //    basicpa: basicWSApa,
        //    basicpm: basicWSApm,
        //    tfppa: tfpWSApa,
        //    tfppm: tfpWSApm,
        //    bfppm: bfpWSApm,
        //    pfpm: pfWSApm,
        //    gratuitypm: gratuityWSApm,
        //    safpm: safpm,
        //    totalRetiralspm: totalRetiralsWSApm,
        //    totalRetiralspa: totalRetiralsWSApa,
        //    tapa: taWSApa,
        //    bppa: bpWSApa,
        //    pppa: ppWSApa,
        //    elepa: eleWSApa,
        //});
        compensationWSAAmountUpdated = {
            ...compensationWSAAmount,
            targetPay: getRoundUptoXDecimals(value, 0),
            basicpa: basicWSApa,
            basicpm: basicWSApm,
            tfppa: tfpWSApa,
            tfppm: tfpWSApm,
            bfppm: bfpWSApm,
            pfpm: pfWSApm,
            gratuitypm: gratuityWSApm,
            safpm: safpm,
            totalRetiralspm: totalRetiralsWSApm,
            totalRetiralspa: totalRetiralsWSApa,
            tapa: taWSApa,
            bppa: bpWSApa,
            pppa: ppWSApa,
            elepa: eleWSApa,
        };
        setCompensationWSAAmount(compensationWSAAmountUpdated);
        dispatch(compensationWSADetailsAction(compensationWSAAmountUpdated));

        //setInsuranceAmount({
        //    ...insuranceAmount,
        //    ghic: ghicpa,
        //    gpaic: gpaicpa,
        //    gtLic: gtLicpa,
        //    edli: edlipa,
        //    esic: esic,
        //});
        insuranceAmountUpdated = {
            ...insuranceAmount,
            ghic: ghicpa,
            gpaic: gpaicpa,
            gtLic: gtLicpa,
            edli: edlipa,
            esic: esic,
        };
        setInsuranceAmount(insuranceAmountUpdated);
        dispatch(insuranceDetailsAction(insuranceAmountUpdated));

        let allowanceAmount = storedInputDataDHL?.allowanceAmountObj.map((c) => {
            if (c.name.includes("Base Flexible Pay p.m.")) {
                c.allowanceAmount = bfppm > 0 ? bfppm : c.allowanceAmount;
            } else if (c.name.includes("Total Retirals p.a.")) {
                c.allowanceAmount =
                    totalRetiralspa > 0 ? totalRetiralspa : c.allowanceAmount;
            }
            return c;
        });
        let inputUpdatedDHL = {
            ...storedInputDataDHL,
            salaryAmount: basicpm,
            allowanceAmountObj: allowanceAmount,
            performencePay: pppa,
        };
        // setInputDataToDHL({
        //     ...inputDataToDHL,
        //     salaryAmount: basicpm,
        //     allowanceAmountObj: allowanceAmount,
        //     performencePay: pppa,
        // });
        setInputDataToDHL(inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));
        handleLoader(false, "");
    }
    let [dialogPropsALert, setDialogPropsALert] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    const handleDialogOpenAlert = (type, title, content) => {
        setDialogPropsALert({
            ...dialogPropsALert,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };
    //------------------- GBS Calculation for PL10,PL11,PL12 Without SF and Without Performance Pay----------------------------
    function gbsCompensationForBelow15KBasic(value) {
        handleLoader(true, "Fetching Compensation...")
        if (value <= 450000) {

            let gbssupallowance = 0;
            let gbsbfppm = 0;
            let gbspfpm = 0;

            let gbstfppm = getRoundUptoXDecimals(value / 12, 2);
            let gbstfppa = getRoundUptoXDecimals(gbstfppm * 12, 2);
            let gbsbasicpm = getRoundUptoXDecimals(gbstfppm * 0.4, 2);
            let gbsbasicpa = getRoundUptoXDecimals(gbsbasicpm * 12, 2);
            let gbshra = getRoundUptoXDecimals(gbsbasicpm / 2, 2);
            let gbsmealcard = getRoundUptoXDecimals(2200, 0);
            let gbsgratuitypm = getRoundUptoXDecimals(gbsbasicpm * .0425, 2);
            let factor1;
            let factor2;
            if (value > gbsmealcard) {
                factor1 = (gbstfppm - gbsgratuitypm - (1.12 * gbsbasicpm) - gbshra - gbsmealcard);
                factor2 = (gbstfppm - gbsgratuitypm - 1800 - gbsbasicpm - gbshra - gbsmealcard);
            }
            else {
                factor1 = (gbsmealcard - gbstfppm - gbsgratuitypm - (1.12 * gbsbasicpm) - gbshra);
                factor2 = (gbsmealcard - 1800 - gbstfppm - gbsgratuitypm - - gbsbasicpm - gbshra);
            }

            if (((factor1 / 1.12) + gbsbasicpm) <= 15000) {
                gbssupallowance = getRoundUptoXDecimals((factor1 / 1.12), 2);
            } else {
                gbssupallowance = getRoundUptoXDecimals(factor2, 2);
            }
            gbsbfppm = getRoundUptoXDecimals((gbsmealcard + gbssupallowance), 2);
            let gbsbasepaypm = gbsbasicpm + gbsbfppm + gbshra;
            let gbsbasepaypa = getRoundUptoXDecimals(gbsbasepaypm * 12, 2);
            // For GBS Annexure
            let gbsnewpfwage = gbsbasicpm + gbssupallowance;
            if (gbsnewpfwage >= 15000) {
                gbspfpm = 1800;
            }
            else if (gbsnewpfwage < 15000) {
                gbspfpm = getRoundUptoXDecimals((gbsnewpfwage / 100) * 12, 2);

            }

            let gbstotalRetiralspm = getRoundUptoXDecimals(gbspfpm + gbsgratuitypm, 2);
            let gbstotalRetiralspa = getRoundUptoXDecimals(gbstotalRetiralspm * 12, 2);
            let gbstapa = getRoundUptoXDecimals(gbsbasepaypa - gbstotalRetiralspa - gbsbasicpa, 2);
            let gbsbppa = getRoundUptoXDecimals(gbsbasicpa + gbstapa, 2);


            let gbspppa = getRoundUptoXDecimals(
                (gbstfppa - gbstotalRetiralspa) * compensationFactors.performencePayFactor,
                2);
            let gbselepa = getRoundUptoXDecimals((gbsbasicpm / 30) * 22, 2);
            //let gbstfppm = getRoundUptoXDecimals(gbsbppa / 12 + gbstotalRetiralspm, 0);
            //let gbstfppa = getRoundUptoXDecimals(
            //    gbstfppm * 12,
            //    0
            //);

            //setCompensationAmount({
            //    ...compensationAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicpa,
            //    basicpm: gbsbasicpm,
            //    tfppa: gbstfppa,
            //    tfppm: gbstfppm,
            //    bfppm: gbsbfppm,
            //    pfpm: gbspfpm,
            //    gratuitypm: gbsgratuitypm,
            //    totalRetiralspm: gbstotalRetiralspm,
            //    totalRetiralspa: gbstotalRetiralspa,
            //    tapa: gbstapa,
            //    bppa: gbsbppa,
            //    pppa: gbspppa,
            //    elepa: gbselepa,
            //    bpa: gbsbasepaypa,
            //    hra: gbshra,
            //});

            compensationAmountUpdated = {
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                bpa: gbsbasepaypa,
                hra: gbshra,
            };
            setCompensationAmount(compensationAmountUpdated);
            dispatch(compensationDetailsAction(compensationAmountUpdated));
            //let gbstfpWSApa = getRoundUptoXDecimals(
            //    value * compensationFactors.totalFixedPayWithSafFactor,
            //    0
            //);
            //let gbsbasicWSApa = getRoundUptoXDecimals(
            //    gbstfpWSApa * compensationFactors.basicSalaryFactor,
            //    0
            //);
            //let gbsbasicWSApm = getRoundUptoXDecimals(gbsbasicWSApa / 12, 0);
            ////let gbspfWSApm = getRoundUptoXDecimals(
            ////    gbsbasicWSApm * compensationFactors.pfFactor,
            ////    0
            ////);
            //let gbspfWSApm = 1800;
            //let gbsgratuityWSApm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.gratuityFactor,
            //    0
            //);
            //let gbssafpm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.safFactor,
            //    0
            //);
            //let gbstotalRetiralsWSApm = getRoundUptoXDecimals(
            //    gbspfWSApm + gbsgratuityWSApm + gbssafpm,
            //    0
            //);
            //let gbstotalRetiralsWSApa = getRoundUptoXDecimals(gbstotalRetiralsWSApm * 12, 0);
            //let gbstaWSApa = getRoundUptoXDecimals(
            //    gbstfpWSApa - gbstotalRetiralsWSApa - gbsbasicWSApa,
            //    0
            //);
            //let gbsbfpWSApm = getRoundUptoXDecimals(gbstaWSApa / 12 - 0, 0);
            //let gbsbpWSApa = getRoundUptoXDecimals(gbsbasicWSApa + gbstaWSApa, 0);
            //let gbstfpWSApm = getRoundUptoXDecimals(gbsbpWSApa / 12 + gbstotalRetiralsWSApm, 0);
            //let gbsppWSApa = getRoundUptoXDecimals(
            //    (gbstfpWSApa - gbstotalRetiralsWSApa) *
            //    compensationFactors.performencePayFactor,
            //    0
            //);
            //let gbseleWSApa = getRoundUptoXDecimals((gbsbasicWSApm / 30) * 22, 0);

            //Insurances
            let gbsghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gbsgpaicpa = getRoundUptoXDecimals(
                value < (insuranceData.gpaicAmount) / 2 ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gbsgtLicpa = getRoundUptoXDecimals(
                value < (insuranceData.gtLicAmount) / 2 ? insuranceData.gtLicAmount : value * 2,
                0
            );
            let gbsedlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let gbsesic =
                getRoundUptoXDecimals(gbstapa / 12, 0) < 23200
                    ? "Eligible as gross salary below ESI eligibility"
                    : " Not applicable as gross salary above ESI eligibility";
            //setCompensationAmount({
            //    ...compensationAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicpa,
            //    basicpm: gbsbasicpm,
            //    tfppa: gbstfppa,
            //    tfppm: gbstfppm,
            //    bfppm: gbsbfppm,
            //    pfpm: gbspfpm,
            //    gratuitypm: gbsgratuitypm,
            //    totalRetiralspm: gbstotalRetiralspm,
            //    totalRetiralspa: gbstotalRetiralspa,
            //    tapa: gbstapa,
            //    bppa: gbsbppa,
            //    pppa: gbspppa,
            //    elepa: gbselepa,
            //    bpa: gbsbasepaypa,
            //    hra: gbshra,
            //});
            compensationAmountUpdated = {
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                bpa: gbsbasepaypa,
                hra: gbshra,
            };
            setCompensationAmount(compensationAmountUpdated);
            dispatch(compensationDetailsAction(compensationAmountUpdated));
            //setCompensationWSAAmount({
            //    ...compensationWSAAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicWSApa,
            //    basicpm: gbsbasicWSApm,
            //    tfppa: gbstfpWSApa,
            //    tfppm: gbstfpWSApm,
            //    bfppm: gbsbfpWSApm,
            //    pfpm: gbspfWSApm,
            //    gratuitypm: gbsgratuityWSApm,
            //    safpm: gbssafpm,
            //    totalRetiralspm: gbstotalRetiralsWSApm,
            //    totalRetiralspa: gbstotalRetiralsWSApa,
            //    tapa: gbstaWSApa,
            //    bppa: gbsbpWSApa,
            //    pppa: gbsppWSApa,
            //    elepa: gbseleWSApa,
            //    bpa: gbsbasepaypa,
            //    hra: gbshra,
            //});
            
            //setInsuranceAmount({
            //    ...insuranceAmount,
            //    ghic: gbsghicpa,
            //    gpaic: gbsgpaicpa,
            //    gtLic: gbsgtLicpa,
            //    edli: gbsedlipa,
            //    esic: gbsesic,
            //});
            insuranceAmountUpdated = {
                ...insuranceAmount,
                ghic: gbsghicpa,
                gpaic: gbsgpaicpa,
                gtLic: gbsgtLicpa,
                edli: gbsedlipa,
                esic: gbsesic,
            };
            setInsuranceAmount(insuranceAmountUpdated);
            dispatch(insuranceDetailsAction(insuranceAmountUpdated));

            let allowanceAmount = storedInputDataDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = gbsbfppm > 0 ? gbsbfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        gbstotalRetiralspa > 0 ? gbstotalRetiralspa : c.allowanceAmount;
                }
                return c;
            });

            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: gbsbasicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: gbspppa,
            // });

            let inputUpdatedDHL = {
                ...storedInputDataDHL,
                salaryAmount: gbsbasicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: gbspppa,
            };
            setInputDataToDHL(inputUpdatedDHL);
            dispatch(inputDataDHLAction(inputUpdatedDHL));
        }
        handleLoader(false, "");
    }
    /*-------------------------------------------------BELOW 15K GBS Annexure Ends Here-------------------------------------*/
    function compensationCalculation(value) {
        handleLoader(true, "Fetching Compensation...");
        if (value == 510000) {
            handleLoader(true, "Fetching Compensation...");
            let gbstfppa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayFactor,
                0
            );
            compensationFactors.basicSalaryFactor = 0.353;
            let gbsbasicpa = getRoundUptoXDecimals(
                gbstfppa * compensationFactors.basicSalaryFactor,
                0
            );
            let gbsbasicpm = getRoundUptoXDecimals(gbsbasicpa / 12, 0);
            // For GBS Annexure

            let gbspfpm = 1800;
            let gbsgratuitypm = getRoundUptoXDecimals(
                gbsbasicpm * compensationFactors.gratuityFactor,
                0
            );
            let gbstotalRetiralspm = getRoundUptoXDecimals(gbspfpm + gbsgratuitypm, 0);
            let gbstotalRetiralspa = getRoundUptoXDecimals(gbstotalRetiralspm * 12, 0);
            let gbstapa = getRoundUptoXDecimals(gbstfppa - gbstotalRetiralspa - gbsbasicpa, 0);
            let gbsbfppm = getRoundUptoXDecimals(gbstapa / 12 - 0, 0);
            let gbsbppa = getRoundUptoXDecimals(gbsbasicpa + gbstapa, 0);
            let gbstfppm = getRoundUptoXDecimals(gbsbppa / 12 + gbstotalRetiralspm, 0);
            let gbspppa = getRoundUptoXDecimals(
                (gbstfppa - gbstotalRetiralspa) * compensationFactors.performencePayFactor,
                0
            );
            let gbselepa = getRoundUptoXDecimals((gbsbasicpm / 30) * 22, 0);

            //setCompensationAmount({
            //    ...compensationAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicpa,
            //    basicpm: gbsbasicpm,
            //    tfppa: gbstfppa,
            //    tfppm: gbstfppm,
            //    bfppm: gbsbfppm,
            //    pfpm: gbspfpm,
            //    gratuitypm: gbsgratuitypm,
            //    totalRetiralspm: gbstotalRetiralspm,
            //    totalRetiralspa: gbstotalRetiralspa,
            //    tapa: gbstapa,
            //    bppa: gbsbppa,
            //    pppa: gbspppa,
            //    elepa: gbselepa,
            //    hra: 0.0,
            //    bpa: 0.0,
            //});
            compensationAmountUpdated = {
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                hra: 0.0,
                bpa: 0.0,
            };
            setCompensationAmount(compensationAmountUpdated);
            dispatch(compensationDetailsAction(compensationAmountUpdated));

            let gbstfpWSApa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayWithSafFactor,
                0
            );
            let gbsbasicWSApa = getRoundUptoXDecimals(
                gbstfpWSApa * compensationFactors.basicSalaryFactor,
                0
            );
            let gbsbasicWSApm = getRoundUptoXDecimals(gbsbasicWSApa / 12, 0);
            //let gbspfWSApm = getRoundUptoXDecimals(
            //    gbsbasicWSApm * compensationFactors.pfFactor,
            //    0
            //);
            let gbspfWSApm = 1800;
            let gbsgratuityWSApm = getRoundUptoXDecimals(
                gbsbasicWSApm * compensationFactors.gratuityFactor,
                0
            );
            let gbssafpm = getRoundUptoXDecimals(
                gbsbasicWSApm * compensationFactors.safFactor,
                0
            );
            let gbstotalRetiralsWSApm = getRoundUptoXDecimals(
                gbspfWSApm + gbsgratuityWSApm + gbssafpm,
                0
            );
            let gbstotalRetiralsWSApa = getRoundUptoXDecimals(gbstotalRetiralsWSApm * 12, 0);
            let gbstaWSApa = getRoundUptoXDecimals(
                gbstfpWSApa - gbstotalRetiralsWSApa - gbsbasicWSApa,
                0
            );
            let gbsbfpWSApm = getRoundUptoXDecimals(gbstaWSApa / 12 - 0, 0);
            let gbsbpWSApa = getRoundUptoXDecimals(gbsbasicWSApa + gbstaWSApa, 0);
            let gbstfpWSApm = getRoundUptoXDecimals(gbsbpWSApa / 12 + gbstotalRetiralsWSApm, 0);
            let gbsppWSApa = getRoundUptoXDecimals(
                (gbstfpWSApa - gbstotalRetiralsWSApa) *
                compensationFactors.performencePayFactor,
                0
            );
            let gbseleWSApa = getRoundUptoXDecimals((gbsbasicWSApm / 30) * 22, 0);

            //Insurances
            let gbsghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gbsgpaicpa = getRoundUptoXDecimals(
                value < (insuranceData.gpaicAmount) / 2 ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gbsgtLicpa = getRoundUptoXDecimals(
                value < (insuranceData.gtLicAmount) / 2 ? insuranceData.gtLicAmount : value * 2,
                0
            );
            let gbsedlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let gbsesic =
                getRoundUptoXDecimals(gbstapa / 12, 0) < 23200 ||
                    getRoundUptoXDecimals(gbstaWSApa / 12, 0) < 23200
                    ? "Eligible as gross salary below ESI eligibility"
                    : " Not applicable as gross salary above ESI eligibility";
            //setCompensationAmount({
            //    ...compensationAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicpa,
            //    basicpm: gbsbasicpm,
            //    tfppa: gbstfppa,
            //    tfppm: gbstfppm,
            //    bfppm: gbsbfppm,
            //    pfpm: gbspfpm,
            //    gratuitypm: gbsgratuitypm,
            //    totalRetiralspm: gbstotalRetiralspm,
            //    totalRetiralspa: gbstotalRetiralspa,
            //    tapa: gbstapa,
            //    bppa: gbsbppa,
            //    pppa: gbspppa,
            //    elepa: gbselepa,
            //    hra: 0.0,
            //    bpa: 0.0,
            //});
            compensationAmountUpdated = {
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicpa,
                basicpm: gbsbasicpm,
                tfppa: gbstfppa,
                tfppm: gbstfppm,
                bfppm: gbsbfppm,
                pfpm: gbspfpm,
                gratuitypm: gbsgratuitypm,
                totalRetiralspm: gbstotalRetiralspm,
                totalRetiralspa: gbstotalRetiralspa,
                tapa: gbstapa,
                bppa: gbsbppa,
                pppa: gbspppa,
                elepa: gbselepa,
                hra: 0.0,
                bpa: 0.0,
            };
            setCompensationAmount(compensationAmountUpdated);
            dispatch(compensationDetailsAction(compensationAmountUpdated));
            //setCompensationWSAAmount({
            //    ...compensationWSAAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: gbsbasicWSApa,
            //    basicpm: gbsbasicWSApm,
            //    tfppa: gbstfpWSApa,
            //    tfppm: gbstfpWSApm,
            //    bfppm: gbsbfpWSApm,
            //    pfpm: gbspfWSApm,
            //    gratuitypm: gbsgratuityWSApm,
            //    safpm: gbssafpm,
            //    totalRetiralspm: gbstotalRetiralsWSApm,
            //    totalRetiralspa: gbstotalRetiralsWSApa,
            //    tapa: gbstaWSApa,
            //    bppa: gbsbpWSApa,
            //    pppa: gbsppWSApa,
            //    elepa: gbseleWSApa,
            //});
            compensationWSAAmountUpdated = {
                ...compensationWSAAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: gbsbasicWSApa,
                basicpm: gbsbasicWSApm,
                tfppa: gbstfpWSApa,
                tfppm: gbstfpWSApm,
                bfppm: gbsbfpWSApm,
                pfpm: gbspfWSApm,
                gratuitypm: gbsgratuityWSApm,
                safpm: gbssafpm,
                totalRetiralspm: gbstotalRetiralsWSApm,
                totalRetiralspa: gbstotalRetiralsWSApa,
                tapa: gbstaWSApa,
                bppa: gbsbpWSApa,
                pppa: gbsppWSApa,
                elepa: gbseleWSApa,
            };
            setCompensationWSAAmount(compensationWSAAmountUpdated);
            dispatch(compensationWSADetailsAction(compensationWSAAmountUpdated));
            //setInsuranceAmount({
            //    ...insuranceAmount,
            //    ghic: gbsghicpa,
            //    gpaic: gbsgpaicpa,
            //    gtLic: gbsgtLicpa,
            //    edli: gbsedlipa,
            //    esic: gbsesic,
            //});
            insuranceAmountUpdated = {
                ...insuranceAmount,
                ghic: gbsghicpa,
                gpaic: gbsgpaicpa,
                gtLic: gbsgtLicpa,
                edli: gbsedlipa,
                esic: gbsesic,
            };
            setInsuranceAmount(insuranceAmountUpdated);
            dispatch(insuranceDetailsAction(insuranceAmountUpdated));

            let allowanceAmount = storedInputDataDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = gbsbfppm > 0 ? gbsbfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        gbstotalRetiralspa > 0 ? gbstotalRetiralspa : c.allowanceAmount;
                }
                return c;
            });

            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: gbsbasicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: gbspppa,
            // });
            let inputUpdatedDHL = {
                ...storedInputDataDHL,
                salaryAmount: gbsbasicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: gbspppa,
            };
            setInputDataToDHL(inputUpdatedDHL);
            dispatch(inputDataDHLAction(inputUpdatedDHL));
            handleLoader(false, "");
        }
        else {
            handleLoader(true, "Fetching Compensation...");

            //Without SAF
            let tfppa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayFactor,
                0
            );
            let basicpa = getRoundUptoXDecimals(
                tfppa * compensationFactors.basicSalaryFactor,
                0
            );
            let basicpm = getRoundUptoXDecimals(basicpa / 12, 0);
            let pfpm = 0;
            if (basicpm > 15003) {
                pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            } else {
                //pfpm = 1800;
                pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            }
            // let pfpm = getRoundUptoXDecimals(basicpm * compensationFactors.pfFactor, 0);
            let gratuitypm = getRoundUptoXDecimals(
                basicpm * compensationFactors.gratuityFactor,
                0
            );
            let totalRetiralspm = getRoundUptoXDecimals(pfpm + gratuitypm, 0);
            let totalRetiralspa = getRoundUptoXDecimals(totalRetiralspm * 12, 0);
            let tapa = getRoundUptoXDecimals(tfppa - totalRetiralspa - basicpa, 0);
            let bfppm = getRoundUptoXDecimals(tapa / 12 - 0, 0);
            let bppa = getRoundUptoXDecimals(basicpa + tapa, 0);
            let tfppm = getRoundUptoXDecimals(bppa / 12 + totalRetiralspm, 0);
            let pppa = getRoundUptoXDecimals(
                (tfppa - totalRetiralspa) * compensationFactors.performencePayFactor,
                0
            );
            let elepa = getRoundUptoXDecimals((basicpm / 30) * 22, 0);

            //With SAF
            let tfpWSApa = getRoundUptoXDecimals(
                value * compensationFactors.totalFixedPayWithSafFactor,
                0
            );
            let basicWSApa = getRoundUptoXDecimals(
                tfpWSApa * compensationFactors.basicSalaryFactor,
                0
            );
            let basicWSApm = getRoundUptoXDecimals(basicWSApa / 12, 0);
            let pfWSApm = 0;
            if (basicWSApm > 15003) {
                pfWSApm = getRoundUptoXDecimals(basicWSApm * compensationFactors.pfFactor, 0);
            } else {
                //pfWSApm = 1800;
                pfWSApm = getRoundUptoXDecimals(basicWSApm * compensationFactors.pfFactor, 0);
            }
            //let pfWSApm = getRoundUptoXDecimals(
            //    basicWSApm * compensationFactors.pfFactor,
            //    0
            //);
            let gratuityWSApm = getRoundUptoXDecimals(
                basicWSApm * compensationFactors.gratuityFactor,
                0
            );
            let safpm = getRoundUptoXDecimals(
                basicWSApm * compensationFactors.safFactor,
                0
            );
            let totalRetiralsWSApm = getRoundUptoXDecimals(
                pfWSApm + gratuityWSApm + safpm,
                0
            );
            let totalRetiralsWSApa = getRoundUptoXDecimals(totalRetiralsWSApm * 12, 0);
            let taWSApa = getRoundUptoXDecimals(
                tfpWSApa - totalRetiralsWSApa - basicWSApa,
                0
            );
            let bfpWSApm = getRoundUptoXDecimals(taWSApa / 12 - 0, 0);
            let bpWSApa = getRoundUptoXDecimals(basicWSApa + taWSApa, 0);
            let tfpWSApm = getRoundUptoXDecimals(bpWSApa / 12 + totalRetiralsWSApm, 0);
            let ppWSApa = getRoundUptoXDecimals(
                (tfpWSApa - totalRetiralsWSApa) *
                compensationFactors.performencePayFactor,
                0
            );
            let eleWSApa = getRoundUptoXDecimals((basicWSApm / 30) * 22, 0);

            //Insurances
            let ghicpa = getRoundUptoXDecimals(insuranceData.ghicAmount, 0);
            let gpaicpa = getRoundUptoXDecimals(
                value < (insuranceData.gpaicAmount) / 2 ? insuranceData.gpaicAmount : value * 2,
                0
            );
            let gtLicpa = getRoundUptoXDecimals(
                value < (insuranceData.gtLicAmount) / 2 ? insuranceData.gtLicAmount : value * 2,
                0
            );
            let edlipa = getRoundUptoXDecimals(insuranceData.edliAmount, 0);
            let esic =
                getRoundUptoXDecimals(tapa / 12, 0) < 23200 ||
                    getRoundUptoXDecimals(taWSApa / 12, 0) < 23200
                    ? "Eligible as gross salary below ESI eligibility"
                    : " Not applicable as gross salary above ESI eligibility";
            //setCompensationAmount({
            //    ...compensationAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: basicpa,
            //    basicpm: basicpm,
            //    tfppa: tfppa,
            //    tfppm: tfppm,
            //    bfppm: bfppm,
            //    pfpm: pfpm,
            //    gratuitypm: gratuitypm,
            //    totalRetiralspm: totalRetiralspm,
            //    totalRetiralspa: totalRetiralspa,
            //    tapa: tapa,
            //    bppa: bppa,
            //    pppa: pppa,
            //    elepa: elepa,
            //    hra: 0.0,
            //    bpa: 0.0,
            //});
            compensationAmountUpdated = {
                ...compensationAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: basicpa,
                basicpm: basicpm,
                tfppa: tfppa,
                tfppm: tfppm,
                bfppm: bfppm,
                pfpm: pfpm,
                gratuitypm: gratuitypm,
                totalRetiralspm: totalRetiralspm,
                totalRetiralspa: totalRetiralspa,
                tapa: tapa,
                bppa: bppa,
                pppa: pppa,
                elepa: elepa,
                hra: 0.0,
                bpa: 0.0,
            };
            setCompensationAmount(compensationAmountUpdated);
            dispatch(compensationDetailsAction(compensationAmountUpdated));
            //setCompensationWSAAmount({
            //    ...compensationWSAAmount,
            //    targetPay: getRoundUptoXDecimals(value, 0),
            //    basicpa: basicWSApa,
            //    basicpm: basicWSApm,
            //    tfppa: tfpWSApa,
            //    tfppm: tfpWSApm,
            //    bfppm: bfpWSApm,
            //    pfpm: pfWSApm,
            //    gratuitypm: gratuityWSApm,
            //    safpm: safpm,
            //    totalRetiralspm: totalRetiralsWSApm,
            //    totalRetiralspa: totalRetiralsWSApa,
            //    tapa: taWSApa,
            //    bppa: bpWSApa,
            //    pppa: ppWSApa,
            //    elepa: eleWSApa,
            //});
            compensationWSAAmountUpdated = {
                ...compensationWSAAmount,
                ...compensationWSAAmount,
                targetPay: getRoundUptoXDecimals(value, 0),
                basicpa: basicWSApa,
                basicpm: basicWSApm,
                tfppa: tfpWSApa,
                tfppm: tfpWSApm,
                bfppm: bfpWSApm,
                pfpm: pfWSApm,
                gratuitypm: gratuityWSApm,
                safpm: safpm,
                totalRetiralspm: totalRetiralsWSApm,
                totalRetiralspa: totalRetiralsWSApa,
                tapa: taWSApa,
                bppa: bpWSApa,
                pppa: ppWSApa,
                elepa: eleWSApa,
            };
            setCompensationWSAAmount(compensationWSAAmountUpdated);
            dispatch(compensationWSADetailsAction(compensationWSAAmountUpdated));
            //setInsuranceAmount({
            //    ...insuranceAmount,
            //    ghic: ghicpa,
            //    gpaic: gpaicpa,
            //    gtLic: gtLicpa,
            //    edli: edlipa,
            //    esic: esic,
            //});
            insuranceAmountUpdated = {
                ...insuranceAmount,
                ghic: ghicpa,
                gpaic: gpaicpa,
                gtLic: gtLicpa,
                edli: edlipa,
                esic: esic,
            };
            setInsuranceAmount(insuranceAmountUpdated);
            dispatch(insuranceDetailsAction(insuranceAmountUpdated));

            let allowanceAmount = storedInputDataDHL?.allowanceAmountObj.map((c) => {
                if (c.name.includes("Base Flexible Pay p.m.")) {
                    c.allowanceAmount = bfppm > 0 ? bfppm : c.allowanceAmount;
                } else if (c.name.includes("Total Retirals p.a.")) {
                    c.allowanceAmount =
                        totalRetiralspa > 0 ? totalRetiralspa : c.allowanceAmount;
                }
                return c;
            });
            // setInputDataToDHL({
            //     ...inputDataToDHL,
            //     salaryAmount: basicpm,
            //     allowanceAmountObj: allowanceAmount,
            //     performencePay: pppa,
            // });

            let inputUpdatedDHL = {
                ...storedInputDataDHL,
                salaryAmount: basicpm,
                allowanceAmountObj: allowanceAmount,
                performencePay: pppa,
            };
            setInputDataToDHL(inputUpdatedDHL);
             dispatch(inputDataDHLAction(inputUpdatedDHL));
            handleLoader(false, "");
        }
        handleLoader(false, "");
    }
    function setDigiLetterDataState(data) {
        handleLoader(true, "Fetching Calculation...");
        let inputUpdatedDHL = {
            ...inputDataToDHL,
            digiLetterDetailsId: data?.digiLetterDetailsId,
            applicantId: data?.applicantIdEncrypted,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            joiningDate: data?.joiningDate,
            companyId: data?.companyId,
            areObj: {
                id: data?.areObj?.companyId,
                areCode: data?.areObj?.companyArename,
                areName: data?.areObj?.companyAredescription,
            },
            mprnumber: data?.mprnumber,
            referenceNo: data?.referenceNo,
            division: data?.division,
            taname: data?.taname,
            taemail: data?.taemail,
            address: data?.address,
            dateOfBirth: data?.dateOfBirth,
            letterType: data?.letterType,
            letterTypeDetails: data?.letterTypeObj,
            letterTypeLabel: data?.letterTypeObj
                ? data?.letterTypeObj?.letterTypeName
                : "",
            letterTypeObj: {
                id: data?.letterTypeObj?.letterTypeId,
                name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
            },
            noOfSignatories: data?.noOfSignatories,
            noOfSignatoriesObj: {
                id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                name:
                    data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            },
            noOfSignatoriesLabel:
                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
            signatoryDetails: data?.signatoryDetails,
            employeeType: data?.employeeType,
            employeeTypeLabel: data?.employeeTypeObj
                ? data?.employeeTypeObj?.name
                : "",
            employeeTypeObj: data?.employeeTypeObj,
            jobLocation: data?.jobLocation,
            jobLocationLabel: data?.jobLocationObj
                ? data?.jobLocationObj?.locationCode +
                " - " +
                data?.jobLocationObj?.cityName +
                " - " +
                data?.jobLocationObj?.streetName
                : "",
            jobLocationObj: data?.jobLocationObj
                ? {
                    id: data?.jobLocationObj?.locationCodeId,
                    name:
                        data?.jobLocationObj?.locationCode +
                        " - " +
                        data?.jobLocationObj?.cityName +
                        " - " +
                        data?.jobLocationObj?.streetName,
                }
                : { id: 0, name: "" },
            locationObj: data?.jobLocationObj,
            jobTitle: data?.jobTitle,
            jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
            businessTitle: data?.businessTitle
                ? data?.businessTitle
                : data?.jobTitleObj
                    ? data?.jobTitleObj?.name
                    : "",
            jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
            gripLabel: data?.gripObj ? data?.gripObj?.name : "",
            grip: data?.grip,
            businessUnit: data?.businessUnit,
            gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
            gripCode: data?.gripCode,
            staff: data?.staff,
            staffLabel: data?.staffObj ? data?.staffObj?.name : "",
            staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
            compensationGrade: data?.compensationGrade,
            compensationGradeLabel: data?.compensationGradeObj
                ? data?.compensationGradeObj?.name
                : "",
            compensationGradeObj: data?.compensationGradeObj
                ? data?.compensationGradeObj
                : { id: 0, name: "" },
            compensationGradeProfile: data?.compensationGradeProfile
                ? data?.compensationGradeProfile
                : { id: 0, name: "" },
            compensationGradeProfileLabel: data?.compensationGradeProfileObj
                ? data?.compensationGradeProfileObj?.name
                : "",
            compensationGradeProfileObj: data?.compensationGradeProfileObj,
            payPlan: data?.payPlan,
            payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
            payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
            performencePayPlan: data?.performencePayPlan,
            performencePayPlanLabel: data?.performencePayPlanObj
                ? data?.performencePayPlanObj?.name
                : "",
            performencePayPlanObj: data?.performencePayPlanObj
                ? data?.performencePayPlanObj
                : { id: 0, name: "" },
            salaryAmount: data?.salaryAmount,
            targetPay: data?.targetPay,
            performencePay: data?.performencePay,
            joiningBonus: data?.joiningBonus,
            recoveryDuration: data?.recoveryDuration,
            allowancePlanObj:
                data?.allowancePlanObj?.length > 0
                    ? data?.allowancePlanObj
                    : inputDataToDHL?.allowancePlanObj,
            allowanceAmountObj:
                data?.allowanceAmountObj?.length > 0
                    ? data?.allowanceAmountObj
                    : inputDataToDHL?.allowanceAmountObj,
            compensationLetterUploadId: data?.compensationLetterUploadId,
            isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
            offerLetterUploadId: data?.offerLetterUploadId,
            isOfferLetteruploaded: data?.isOfferLetteruploaded,
            siemensLogo:
                data?.companyId === 1 || data.companyId === 2
                    ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                    : "../assets/images/sie-logo-petrol-rgb.png",
            digiLetterWorkflowId: data?.digiLetterWorkflowId,
            isCompensationAccepted: data?.isCompensationAccepted,
            isCompensationRejected: data?.isCompensationRejected,
            digiLetterStatusId: data?.digiLetterStatusId,
            isOfferAccepted: data?.isOfferAccepted,
            isOfferRejected: data?.isOfferRejected,
            isSuperannuation: data?.isSuperannuation,
        }
        setInputDataToDHL(inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));
        handleLoader(false, "");
    }
    function getDigiLetterDetails(encryptedId) {

        handleLoader(true, "Fetching data....");
        const token = props.token;
        var stat = localStorage.getItem("Status");
        // id = encryptedId.substr(22);
        id = encryptedId;
        const options = {
            method: "get",
            url: "OnboardingDHLMerge/GetDigitalLetterDetails",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                id: id,
            },
        };
        axios(options)
            .then((response) => {
                let result = response.data.result;
                if (result.response) {
                    let data = result.objResult;
                    console.log("Inside getDigiLetterDetails", data);
                    
                    let inputUpdatedDHL = {
                        ...inputDataToDHL,
                        digiLetterDetailsId: data?.digiLetterDetailsId,
                        applicantId: data?.applicantIdEncrypted,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        email: data?.email,
                        joiningDate: data?.joiningDate,
                        companyId: data?.companyId,
                        areObj: {
                            id: data?.areObj?.companyId,
                            areCode: data?.areObj?.companyArename,
                            areName: data?.areObj?.companyAredescription,
                        },
                        mprnumber: data?.mprnumber,
                        referenceNo: data?.referenceNo,
                        division: data?.division,
                        taname: data?.taname,
                        taemail: data?.taemail,
                        address: data?.address,
                        dateOfBirth: data?.dateOfBirth,
                        letterType: data?.letterType,
                        letterTypeDetails: data?.letterTypeObj,
                        letterTypeLabel: data?.letterTypeObj
                            ? data?.letterTypeObj?.letterTypeName
                            : "",
                        letterTypeObj: {
                            id: data?.letterTypeObj?.letterTypeId,
                            name: data?.letterTypeObj ? data?.letterTypeObj?.letterTypeName : "",
                        },
                        noOfSignatories: data?.noOfSignatories,
                        noOfSignatoriesObj: {
                            id: data?.noOfSignatories > 0 ? data?.noOfSignatories : 0,
                            name:
                                data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        },
                        noOfSignatoriesLabel:
                            data?.noOfSignatories > 0 ? data?.noOfSignatories?.toString() : "",
                        signatoryDetails: data?.signatoryDetails,
                        employeeType: data?.employeeType,
                        employeeTypeLabel: data?.employeeTypeObj
                            ? data?.employeeTypeObj?.name
                            : "",
                        employeeTypeObj: data?.employeeTypeObj,
                        jobLocation: data?.jobLocation,
                        jobLocationLabel: data?.jobLocationObj
                            ? data?.jobLocationObj?.locationCode +
                            " - " +
                            data?.jobLocationObj?.cityName +
                            " - " +
                            data?.jobLocationObj?.streetName
                            : "",
                        jobLocationObj: data?.jobLocationObj
                            ? {
                                id: data?.jobLocationObj?.locationCodeId,
                                name:
                                    data?.jobLocationObj?.locationCode +
                                    " - " +
                                    data?.jobLocationObj?.cityName +
                                    " - " +
                                    data?.jobLocationObj?.streetName,
                            }
                            : { id: 0, name: "" },
                        locationObj: data?.jobLocationObj,
                        jobTitle: data?.jobTitle,
                        jobTitleLabel: data?.jobTitleObj ? data?.jobTitleObj?.name : "",
                        businessTitle: data?.businessTitle
                            ? data?.businessTitle
                            : data?.jobTitleObj
                                ? data?.jobTitleObj?.name
                                : "",
                        jobTitleObj: data?.jobTitleObj ? data?.jobTitleObj : { id: 0, name: "" },
                        gripLabel: data?.gripObj ? data?.gripObj?.name : "",
                        grip: data?.grip,
                        businessUnit: data?.businessUnit,
                        gripObj: data?.gripObj ? data?.gripObj : { id: 0, name: "" },
                        gripCode: data?.gripCode,
                        staff: data?.staff,
                        staffLabel: data?.staffObj ? data?.staffObj?.name : "",
                        staffObj: data?.staffObj ? data?.staffObj : { id: 0, name: "" },
                        compensationGrade: data?.compensationGrade,
                        compensationGradeLabel: data?.compensationGradeObj
                            ? data?.compensationGradeObj?.name
                            : "",
                        compensationGradeObj: data?.compensationGradeObj
                            ? data?.compensationGradeObj
                            : { id: 0, name: "" },
                        compensationGradeProfile: data?.compensationGradeProfile
                            ? data?.compensationGradeProfile
                            : { id: 0, name: "" },
                        compensationGradeProfileLabel: data?.compensationGradeProfileObj
                            ? data?.compensationGradeProfileObj?.name
                            : "",
                        compensationGradeProfileObj: data?.compensationGradeProfileObj,
                        payPlan: data?.payPlan,
                        payPlanLabel: data?.payPlanObj ? data?.payPlanObj?.name : "",
                        payPlanObj: data?.payPlanObj ? data?.payPlanObj : { id: 0, name: "" },
                        performencePayPlan: data?.performencePayPlan,
                        performencePayPlanLabel: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj?.name
                            : "",
                        performencePayPlanObj: data?.performencePayPlanObj
                            ? data?.performencePayPlanObj
                            : { id: 0, name: "" },
                        salaryAmount: data?.salaryAmount,
                        targetPay: data?.targetPay,
                        performencePay: data?.performencePay,
                        joiningBonus: data?.joiningBonus,
                        recoveryDuration: data?.recoveryDuration,
                        allowancePlanObj:
                            data?.allowancePlanObj?.length > 0
                                ? data?.allowancePlanObj
                                : inputDataToDHL?.allowancePlanObj,
                        allowanceAmountObj:
                            data?.allowanceAmountObj?.length > 0
                                ? data?.allowanceAmountObj
                                : inputDataToDHL?.allowanceAmountObj,
                        compensationLetterUploadId: data?.compensationLetterUploadId,
                        isCompensationLetterUploaded: data?.isCompensationLetterUploaded,
                        offerLetterUploadId: data?.offerLetterUploadId,
                        isOfferLetteruploaded: data?.isOfferLetteruploaded,
                        siemensLogo:
                            data?.companyId === 1 || data.companyId === 2
                                ? "../assets/images/Siemens_Logo_Healthineers.jpg"
                                : "../assets/images/sie-logo-petrol-rgb.png",
                        digiLetterWorkflowId: data?.digiLetterWorkflowId,
                        isCompensationAccepted: data?.isCompensationAccepted,
                        isCompensationRejected: data?.isCompensationRejected,
                        digiLetterStatusId: data?.digiLetterStatusId,
                        isOfferAccepted: data?.isOfferAccepted,
                        isOfferRejected: data?.isOfferRejected,
                        isSuperannuation: data?.isSuperannuation,
                    }
                    setInputDataToDHL(inputUpdatedDHL);
                    dispatch(inputDataDHLAction(inputUpdatedDHL));

                    setDigiLetterDataState(data);
                    handleLoader(false, "");
                    //alert(stat);
                    if (stat == "Success") {
                        handleDialogOpenAlert(
                            "Success",
                            "Letter Uploaded Successfully",
                            "Letter Uploaded Successfully"
                        );
                        //alert("Success");
                        localStorage.removeItem("Status");
                    }
                    else if (stat == "Failed") {
                        handleDialogOpenAlert(
                            "Failure",
                            "Letter Uploaded Failed",
                            "Letter Upload Failed"
                        );
                    }
                    console.log("Inside 1 getDigiLetterDetails", data);
                    handleLoader(false, "");
                }
                console.log("Inside 2 getDigiLetterDetails", result);
                handleLoader(false, "");
            })
            .catch((error) => {
                console.log(error);
                handleLoader(false, "");
            });
    }
    function getCompensationFactors(grip, letterType) {
        setCompensationProgressProps({
            ...compensationProgressProps,
            isEnable: true,
            loaderText: "Fetching compensation factors....",
        });
        const token = props.token;
        let options = {
            method: "get",
            url: "OnboardingDHLMerge/GetCompensationFactors",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                grip: grip,
                letterType: letterType,
            },
        };
        axios(options)
            .then((response) => {
                if (response.data.result.response) {
                    setCompensationProgressProps({
                        ...compensationProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    let data = response.data.result.objResult;
                    handleLoader(true, "Fetching Allowance Details....");
                    setCompensationFactors({
                        ...compensationFactors,
                        compensationFactorId: data?.compensationFactorId,
                        gripId: data?.gripId,
                        letterTypeId: data?.letterTypeId,
                        basicSalaryFactor: data?.basicSalaryFactor,
                        performencePayFactor: data?.performencePayFactor,
                        pfFactor: data?.pfFactor,
                        gratuityFactor: data?.gratuityFactor,
                        totalFixedPayFactor: data?.totalFixedPayFactor,
                        safFactor: data?.safFactor,
                        totalFixedPayWithSafFactor: data?.totalFixedPayWithSafFactor,
                    });
                    handleLoader(false, "");
                }
                else {
                    setCompensationProgressProps({
                        ...compensationProgressProps,
                        isEnable: false,
                        loaderText: "",
                    });
                    handleDialogOpenAlert(
                        "Alert",
                        "Fetch Compensation Factors Alert",
                        response.data.result.responseMsg
                    );
                }
            })
            .catch((error) => {
                setCompensationProgressProps({
                    ...compensationProgressProps,
                    isEnable: false,
                    loaderText: "",
                });
                handleDialogOpenAlert(
                    "Exception",
                    "Fetch Compensation Factors Error",
                    error
                );
            });
    }
    async function getInsuranceData(grip) {
        handleLoader(true, "Fetching Insurance Data");
        const token = props.token;
        let options = {
            method: "get",
            url: "OnboardingDHLMerge/GetInsurancesDataPerGrip",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            params: {
                grip: grip,
            },
        };
        let response = await axios(options);
        if (response.data.result.response) {
            debugger;
            let data = response.data.result.objResult;
            //setInsuranceData({
            //    ...insuranceData,
            //    id: data?.id,
            //    gripId: data?.gripId,
            //    ghicAmount: data?.ghicAmount,
            //    gpaicAmount: data?.gpaicAmount,
            //    gtLicAmount: data?.gtLicAmount,
            //    edliAmount: data?.edliAmount,
            //    micAmount: data?.micAmount,
            //});
            insuranceDataUpdated = {
                ...insuranceData,
                id: data?.id,
                gripId: data?.gripId,
                ghicAmount: data?.ghicAmount,
                gpaicAmount: data?.gpaicAmount,
                gtLicAmount: data?.gtLicAmount,
                edliAmount: data?.edliAmount,
                micAmount: data?.micAmount,
            };
            setInsuranceData(insuranceDataUpdated);
            dispatch(insuranceDataAction(insuranceDataUpdated));

        }
        handleLoader(false, "");
    }

    useEffect(() => {
        //dispatch(inputDataDHLAction(inputDataToDHL));
        if (props.token) {
            if (encryptedId) {
                getDigiLetterDetails(encryptedId);
            }
        }
    }, [encryptedId, props.token]);
    useEffect(() => {
        if (props.token) {
            if (storedInputDataDHL?.grip > 0 && storedInputDataDHL?.letterType > 0) {
                getCompensationFactors(
                    storedInputDataDHL?.grip,
                    storedInputDataDHL?.letterType
                );
            }
        }
    }, [storedInputDataDHL?.grip, storedInputDataDHL?.letterType, props.token]);
    useEffect(() => {
        if (props.token) {
            if (storedInputDataDHL?.grip > 0) {
                getInsuranceData(storedInputDataDHL?.grip);
            }
        }
    }, [storedInputDataDHL?.grip, props.token]);
    useEffect(() => {
        if (storedInputDataDHL?.letterType == 7) {
            if (storedInputDataDHL?.targetPay && storedInputDataDHL.targetPay <= 450000) {

                gbsCompensationForBelow15KBasic(storedInputDataDHL?.targetPay);
            }
            else if (storedInputDataDHL?.targetPay && storedInputDataDHL.targetPay > 450000) {
                if (storedInputDataDHL?.targetPay && storedInputDataDHL?.grip > 10) {
                    compensationCalculationforPL13_14(storedInputDataDHL?.targetPay);
                }

                else if (storedInputDataDHL?.grip <= 10) {
                    compensationCalculation(storedInputDataDHL?.targetPay);
                }

            }


            //else {
            //    handleDialogOpenAlert(
            //        "Alert",
            //        "For GBS Below 15K: Target Pay should be less than " + inputDataToDHL.targetPay + ".",
            //        "For GBS Below 15K: Target Pay should be less than " + inputDataToDHL.targetPay + ".",
            //    );


            //    inputDataToDHL.targetPay = 0.0;
            //    inputDataToDHL.allowanceAmountObj[0].allowanceAmount = 0.0;
            //    inputDataToDHL.allowanceAmountObj[1].allowanceAmount = 0.0;
            //    // let basefppm = 0.0;
            //    // let totalretiralperannum = 0.0;
            //    // setcompensationAmount({
            //    // ...compensationAmount,
            //    // bfppm: basefppm,

            //    // })
            //    inputDataToDHL.salaryAmount = 0.0;


            //}


        }
        else
            if (storedInputDataDHL?.letterType != 7) {
                if (storedInputDataDHL?.targetPay && storedInputDataDHL?.grip > 10) {
                    compensationCalculationforPL13_14(storedInputDataDHL?.targetPay);
                }

                else if (storedInputDataDHL?.grip <= 10) {
                    compensationCalculation(storedInputDataDHL?.targetPay);
                }
            }

    }, [
        storedInputDataDHL?.targetPay,
        storedInputDataDHL?.letterType,
        storedInputDataDHL?.grip,
        compensationFactors,
    ]);
    

  return (
    <>
      {compensationProgressProps?.isEnable ? (
              ProgressComponent
          ) : (compensationfactor1 == "true" && compensationfactor2 == "true" ?
<>
        <Grid container spacing={1} className="d-flex jc-space-around fd-row">
          <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
            <h5 className="clr-dark-blue">Compensation Details</h5>
          </Grid>
                          <Grid container spacing={2} className="d-flex jc-space-bt fd-row">
                              <p className="f-12 clr-red">
                                  <i>Note: *Target pay should be greater than or equal to Rs.5,15,000/-</i>
                              </p>
                          </Grid>
          <Grid item xs={12} sm={6} className="d-flex Width-100">
            <Autocomplete
              disableClearable={true}
              className="lightInputFields hover-lightblue mt-1"
                              id="compensationGrade"
                                  value={storedInputDataDHL.compensationGradeObj}
                                  inputValue={storedInputDataDHL.compensationGradeLabel}
              options={itemsCompensationGrade}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={handleAutoCompleteChange}
              onInputChange={handleAutoCompleteTextChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Compensation Grade"
                  label="Compensation Entity"
                  name="compensationGrade"
                  variant="filled"
                  required
                  error={
                      storedInputDataDHL.compensationGradeErrorText &&
                      storedInputDataDHL.compensationGradeErrorText.length > 0
                  }
                      helperText={storedInputDataDHL.compensationGradeErrorText}
                  style={{ color: "#f00" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="d-flex Width-100">
            <Autocomplete
              disableClearable={true}
              className="lightInputFields hover-lightblue mt-1"
              id="compensationGradeProfile"
                                  value={storedInputDataDHL.compensationGradeProfileObj}
                                  inputValue={storedInputDataDHL.compensationGradeProfileLabel}
              options={itemsCompensationGradeProfile}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={handleAutoCompleteChange}
              onInputChange={handleAutoCompleteTextChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Compensation Grade Profile"
                  label="GRIP Position Level"
                  name="compensationGradeProfile"
                  variant="filled"
                  required
                  error={
                      storedInputDataDHL.compensationGradeProfileErrorText &&
                      storedInputDataDHL.compensationGradeProfileErrorText
                      .length > 0
                  }
                  helperText={
                      storedInputDataDHL.compensationGradeProfileErrorText
                  }
                  style={{ color: "#f00" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="d-flex Width-100">
            <Autocomplete
              disableClearable={true}
              className="lightInputFields hover-lightblue"
              id="payPlan"
                                  value={storedInputDataDHL.payPlanObj}
                                  inputValue={storedInputDataDHL.payPlanLabel}
              options={itemsPayPlan}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={handleAutoCompleteChange}
              onInputChange={handleAutoCompleteTextChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pay Plan"
                  name="payPlan"
                  variant="filled"
                  required
                  error={
                      storedInputDataDHL.payPlanErrorText &&
                      storedInputDataDHL.payPlanErrorText.length > 0
                  }
                      helperText={storedInputDataDHL.payPlanErrorText}
                  style={{ color: "#f00" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={9} className="d-flex Width-100">
            <Autocomplete
              className="lightInputFields hover-lightblue"
              multiple
                                  value={
                                      storedInputDataDHL?.allowancePlanObj?.name
                                          ? storedInputDataDHL?.allowancePlanObj?.name
                                          : storedInputDataDHL?.allowancePlanObj
              }
              limitTags={3}
              id="allowancePlan"
              options={itemsAllowancePlan}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              getOptionSelected={(option, value) => option.id === value.id}
              disableClearable
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name ? option.name : ""}
                </React.Fragment>
              )}
              //value={[allowancePlanText] ? [allowancePlanText] : [""]}
              //value={obj.id}
              // defaultValue={[allowancePlanText[0]]}
              onChange={handleAutoCompleteChange}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Allowance Plan"
                  name="allowancePlan"
                />
              )}
              disabled={
                props.disableOnceCompleted
                  ? props.disableOnceCompleted
                  : props.disableValue
              }
            />
          </Grid>
                          <Grid item xs={12} sm={3} className="d-flex Width-100">
                              
            <TextField
              className="mt-1 lightInputFields"
              id="targetPay"
              label="Target Pay p.a."
              variant="filled"
              fullWidth
              type="number"
              inputProps={{ min: 0 }}
              name="targetPay"
              onChange={handleChange}
                                  value={storedInputDataDHL.targetPay}
              required
              disabled={!compensationFactors.compensationFactorId > 0}
              error={
                  storedInputDataDHL.targetPayErrorText &&
                  storedInputDataDHL.targetPayErrorText.length > 0
              }
                                  helperText={storedInputDataDHL.targetPayErrorText}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="d-flex Width-100">
            <TextField
              className="mt-1 lightInputFields"
              id="basicSalary"
              label="Basic Salary p.m."
              variant="filled"
              fullWidth
              type="number"
              name="basicSalary"
                                  value={storedInputDataDHL.salaryAmount}
              required
              disabled
              error={
                  storedInputDataDHL.salaryAmountErrorText &&
                  storedInputDataDHL.salaryAmountErrorText.length > 0
              }
                                  helperText={storedInputDataDHL.salaryAmountErrorText}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
                          {storedInputDataDHL?.allowanceAmountObj.map((c) => (
            <Grid item xs={12} sm={3} className="d-flex Width-100">
              <TextField
                key={c?.id}
                className="mt-1 lightInputFields"
                id="bfp"
                label={c?.name}
                variant="filled"
                fullWidth
                type="number"
                name={c?.id}
                value={c?.allowanceAmount}
                required
                disabled
                error={
                    storedInputDataDHL.bfpErrorText &&
                    storedInputDataDHL.bfpErrorText.length > 0
                }
                                      helperText={storedInputDataDHL.bfpErrorText}
              />
            </Grid>
          ))}
          {/* <Grid item xs={12} sm={3} className="d-flex Width-100">
          <TextField
            className="mt-1 lightInputFields"
            id="bfp"
            label="Base Flexi Pay p.m."
            variant="filled"
            fullWidth
            type="number"
            name="bfp"
            value={props.inputDataToDHL.bfp}
            required
            disabled
            error={
              props.inputDataToDHL.bfpErrorText &&
              props.inputDataToDHL.bfpErrorText.length > 0
            }
            helperText={props.inputDataToDHL.bfpErrorText}
          />
        </Grid>
        <Grid item xs={12} sm={3} className="d-flex Width-100">
          <TextField
            className="mt-1 lightInputFields"
            id="totalRetirals"
            label="Total Retirals p.m."
            variant="filled"
            fullWidth
            type="number"
            name="totalRetirals"
            value={props.inputDataToDHL.totalRetirals}
            required
            disabled
            error={
              props.inputDataToDHL.totalRetiralsErrorText &&
              props.inputDataToDHL.totalRetiralsErrorText.length > 0
            }
            helperText={props.inputDataToDHL.totalRetiralsErrorText}
          />
        </Grid> */}
          <Grid item xs={12} sm={6} className="d-flex Width-100">
            <Autocomplete
              disableClearable={true}
              className="lightInputFields hover-lightblue mt-1"
              id="performencePayPlan"
                                  value={storedInputDataDHL.performencePayPlanObj}
                                  inputValue={storedInputDataDHL.performencePayPlanLabel}
              options={itemsPerformencePayPlan}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={handleAutoCompleteChange}
              onInputChange={handleAutoCompleteTextChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Performance Pay Plan"
                  name="performencePayPlan"
                  variant="filled"
                  required
                  error={
                      storedInputDataDHL.PayPlanErrorText &&
                      storedInputDataDHL.PayPlanErrorText.length > 0
                  }
                      helperText={storedInputDataDHL.performencePayPlanErrorText}
                  style={{ color: "#f00" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="d-flex Width-100">
            <TextField
              className="mt-1 lightInputFields"
              id="performncePay"
              label="Perfromance Pay p.a."
              variant="filled"
              fullWidth
              type="number"
              name="performencePay"
              value={inputDataToDHL.performencePay}
              required
              disabled
              error={
                inputDataToDHL.performencePayErrorText &&
                inputDataToDHL.performencePayErrorText.length > 0
              }
              helperText={inputDataToDHL.performencePayErrorText}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="d-flex Width-100">
            <TextField
              className="mt-1 lightInputFields"
              id="joiningBonus"
              label="Joining Bounus (One Time)"
              variant="filled"
              fullWidth
              type="number"
              name="joiningBonus"
                                  value={storedInputDataDHL.joiningBonus}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3} className="d-flex Width-100">
            <TextField
              className="mt-1 lightInputFields"
              id="recoveryDuration"
              label="Recovery Duration (in years)"
              variant="filled"
              fullWidth
              type="number"
              name="recoveryDuration"
                                  value={storedInputDataDHL.recoveryDuration}
              onChange={handleChange}
              inputProps={{ min: 0 }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

        </Grid>

               </>   : <p className="clr-red text-center">
              <i>
                  <strong>
                      *Note: To access compensation details first select valid
                      employee type and staff specification
                  </strong>
              </i>
          </p>
      )}
      {loaderProps?.isEnable ? (
        <Loader loaderText={loaderProps?.loaderText} />
      ) : (
        <></>
      )}
    </>

  );
}
