import { Fab } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";

import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import axios from "axios";
import {
    IconButton,

} from "@material-ui/core";
import Loader from "../../components/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//let [loaderProps, setLoaderProps] = useState({
//    isEnable: false,
//    loaderText: "",
//});

//const handleLoader = (isEnable, text) => {
//    setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
//};
export default function PreviewLetters(props) {
    let [html, setHtml] = useState({ __html: "<p id='test'>Hello</p>" });
    function getHtml() {
        let htmlContent = localStorage.getItem("LetterHtml");
        setHtml({ ...html, __html: htmlContent });
        console.log(htmlContent);
    }
    let [loaderProps, setLoaderProps] = useState({
        isEnable: false,
        loaderText: "",
    });

    const handleLoader = (isEnable, text) => {

        setLoaderProps({ ...loaderProps, isEnable: isEnable, loaderText: text });
    };
    let [dialogPropsALert, setDialogPropsALert] = useState({
        open: false,
        maxWidth: "md",
        className: "bg-light-green clr-white",
        type: "",
        title: "",
        content: "",
    });
    const handleDialogOpenAlert = (type, title, content) => {
        setDialogPropsALert({
            ...dialogPropsALert,
            open: true,
            type: type,
            className: title.includes("Success")
                ? "bg-light-green clr-white"
                : "bg-red clr-white",
            title: title,
            content: content,
        });
    };
    const handleCloseAlert = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogPropsALert({
            ...dialogPropsALert,
            open: false,
            className: "bg-light-green clr-white",
            type: "",
            title: "",
            content: "",
        });

    };
    const printPreview = () => {
        window.print();
    };
    const uploadcontent = (ApplicantId, UserId, files) => { return { ApplicantId: ApplicantId, UserId: UserId, files: files } }
    function handleUploadFile(path, type, routePath) {
        const uploadData = uploadcontent(localStorage.getItem("ApplicantId"), props.userId, path)
        //let uploadData = new FormData();
        //uploadData.append(
        //    "ApplicantId",
        //    props?.inputDataToDHL?.applicantId);
        //uploadData.append("UserId", props?.inputDataToDHL?.userId);
        ////uploadData.append("files", selectedFile);
        //uploadData.append("files", path)
        //props.setLetterDetailsProgressProps({
        //    ...props.letterDetailsProgressProps,
        //    isEnable: true,
        //    loaderText: "Upload Letter in progress....",
        //});
        console.log(uploadData);
        switch (type) {
            case "Compensation":
                uploadCompensationLetter(uploadData, routePath);
                break;
            case "Offer":
                uploadOfferLetter(uploadData, routePath);

                break;
            case "Document":
                //uploadAdditionalDocument(uploadData);
                break;
            default:
                break;
        }
    }

    function uploadOfferLetter(uploadData, routePath) {
        // console.log(uploadData);


        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/UploadOfferLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: uploadData,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    //                props.setLetterDetailsProgressProps({
                    //                    ...props.letterDetailsProgressProps,
                    //                    isEnable: false,
                    //                    loaderText: "",
                    //                });
                    let data = res.objResult;
                    //getUploadedOfferLetterDetails(props?.inputDataToDHL?.applicantId);
                    //                props.handleDialogOpenAlert(
                    //                    "Success",
                    //                    "Upload Offer Letter Success",
                    //                    res.responseMsg
                    //                );
                    //setOfferUploadState(data);
                    // alert("Upload Success");
                    //handleDialogOpenAlert(
                    //    "Success",
                    //    "Letter Uploaded Successfully",
                    //    "Letter Uploaded Successfully"
                    //);
                    localStorage.setItem("Status", "Success");

                } else {
                    //handleDialogOpenAlert(
                    //    "Failure",
                    //    "Letter Upload Failed",
                    //    "Letter Upload Failed"
                    //);
                    localStorage.setItem("Status", "Failure");
                    //                props.setLetterDetailsProgressProps({
                    //                    ...props.letterDetailsProgressProps,
                    //                    isEnable: false,
                    //                    loaderText: "",
                    //                });
                    //                props.handleDialogOpenAlert(
                    //                    "Alert",
                    //                    "Upload Offer Letter Alert",
                    //                    res.responseMsg
                    //                );
                }
                window.location.href = routePath;
            })
            .catch((error) => {
                //            props.setLetterDetailsProgressProps({
                //                ...props.letterDetailsProgressProps,
                //                isEnable: false,
                //                loaderText: "",
                //            });
                //            props.handleDialogOpenAlert(
                //                "Exception",
                //                "Upload Offer Letter Error",
                //                error
                //            );
            });
    }

    function uploadCompensationLetter(uploadData, routePath) {

        //console.log(uploadData);
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/UploadCompensationLetter",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: uploadData,
        };
        axios(options)
            .then((response) => {
                let res = response.data.result;
                if (res.response) {
                    //                props.setLetterDetailsProgressProps({
                    //                    ...props.letterDetailsProgressProps,
                    //                    isEnable: false,
                    //                    loaderText: "",
                    //                });
                    let data = res.objResult;
                    //getUploadedOfferLetterDetails(props?.inputDataToDHL?.applicantId);
                    //                props.handleDialogOpenAlert(
                    //                    "Success",
                    //                    "Upload Offer Letter Success",
                    //                    res.responseMsg
                    //                );
                    //setOfferUploadState(data);
                    //alert("Upload Success");
                    //handleDialogOpenAlert(
                    //    "Success",
                    //    "Letter Uploaded Successfully",
                    //    "Letter Uploaded Successfully"
                    //);
                    localStorage.setItem("Status", "Success");

                } else {
                    //handleDialogOpenAlert(
                    //    "Failure",
                    //    "Letter Uploaded Failed",
                    //    "Letter Upload Failed"
                    //);
                    localStorage.setItem("Status", "Failed");
                    //                props.setLetterDetailsProgressProps({
                    //                    ...props.letterDetailsProgressProps,
                    //                    isEnable: false,
                    //                    loaderText: "",
                    //                });
                    //                props.handleDialogOpenAlert(
                    //                    "Alert",
                    //                    "Upload Offer Letter Alert",
                    //                    res.responseMsg
                    //                );
                }
               window.location.href = routePath;
            })
            .catch((error) => {
                //            props.setLetterDetailsProgressProps({
                //                ...props.letterDetailsProgressProps,
                //                isEnable: false,
                //                loaderText: "",
                //            });
                //            props.handleDialogOpenAlert(
                //                "Exception",
                //                "Upload Offer Letter Error",
                //                error
                //            );
            });
    }

    const downloadcontent = (Type, Content) => { return { Type: Type, Content: Content } }
    function uploadPreview() {
        handleLoader(true, "Uploading....");
        const downloaddata = downloadcontent(localStorage.getItem("Type"), localStorage.getItem("LetterPdf"))
        const token = props.token;
        const options = {
            method: "post",
            url: "OnboardingDHLMerge/PreviewLetterPDF",
            headers: {
                "Access-Control-Allow-Origin": `${window.location.origin}`,
                Authorization: "Bearer " + token,
            },
            data: downloaddata,
        };
        axios(options)
            .then((response) => {
                // alert(data);
                let result = response.data.result;
                //alert(result);
                if (result != " ") {
                    handleLoader(false, "");
                    /* handleDialogOpenAlert(
                        "Success",
                        "Offer Letter Uplaoded successfully",
                        result.responseMsg
                    );*/
                    handleUploadFile(result, localStorage.getItem("Type"), localStorage.getItem("routePath"));
                    //alert("Success");
                } else {
                    // handleLoader(false, "");
                    /* handleDialogOpenAlert(
                         "Alert",
                         "Upload Failed",
                         result.responseMsg
                     );*/
                    //handleUploadFile(response,localStorage.getItem("Type"));
                    alert("Failed");
                }
            })
            .catch((error) => {
                console.log(error);
                //alert(error);
                handleLoader(false, "");
                /*handleDialogOpenAlert("Exception", "Upload Exception", error);*/
            });
    }
    const alertDialogComponent = (
        <Dialog
            open={dialogPropsALert?.open}
            onClose={handleCloseAlert}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
        >
            <DialogTitle
                className={dialogPropsALert?.className}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
            >
                {dialogPropsALert?.type}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="clr-dark-blue">
                    {dialogPropsALert?.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-content">
                <Button
                    variant="contained"
                    autoFocus
                    onClick={handleCloseAlert}
                    className="ns-btn-secondary-light"
                >
                    <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
    const closePreview = () => {
        localStorage.removeItem("LetterHtml");
        window.close();
    };
    useEffect(() => {
        getHtml();
    }, []);
    useEffect(() => {
        console.log(html);
    }, [html]);
    return (
        <>
            <div style={{ marginTop: "6em" }} className="no-print d-flex">
                <Fab
                    variant="extended"
                    size="small"
                    onClick={uploadPreview}
                    className="preview-letter-upload-btn"
                >
                    <UploadIcon fontSize="medium" sx={{ mr: 1 }} />

                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    onClick={printPreview}
                    className="preview-letter-print-btn"
                >
                    <PrintIcon fontSize="medium" sx={{ mr: 1 }} />
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    onClick={closePreview}
                    className="preview-letter-close-btn"
                >
                    <CloseIcon fontSize="medium" sx={{ mr: 1 }} />
                </Fab>
            </div>
            <div
                //   id="printLetters"
                className="bg-intact"
                dangerouslySetInnerHTML={html}
            ></div>
            {alertDialogComponent}
            {loaderProps?.isEnable ? (
                <Loader loaderText={loaderProps?.loaderText} />
            ) : (
                <></>
            )}
        </>
    );
}
