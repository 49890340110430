import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormDatePicker from "../../components/DatePicker";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        fontSize: "1.1rem",
        //fontWeight: "600",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },
    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#005673",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function GeneralBackground(props) {
  var id;
  const [buttonTextSave, setButtonTextSave] = useState("Save");
  const [gbSalaryAndJoinedDateDetailsId, setGBSalaryAndJoinedDateDetailsId] =
    useState(0);
  const [employeeGBDetailsId, setEmployeeGBDetailsId] = useState([]);
  const [gbDecalarationId, setGBDeclarationId] = useState(0);
  const [interestAndProficiency, setInterestAndProficiency] = useState("");
  const [radioOtherBusiness, setRadioOtherBusiness] = useState("No");
  const [otherBusiness, setOtherBusiness] = useState("");
  const [radioFriendsOrRelatives, setRadioFriendsOrRelatives] = useState("No");
  const [friendsOrRelatives, setFriendsOrRelatives] = useState("");
  const [radioIllness, setRadioIllness] = useState("No");
  const [illness, setIllness] = useState("");
  const [radioHandicapped, setRadioHandicapped] = useState("No");
  const [handicapped, setHandicapped] = useState("");
  const [radioPrevInSiemens, setRadioPrevInSiemens] = useState("No");
  const [prevInSiemens, setPrevInSiemens] = useState("");
  const [radioInterviewB4Siemens, setRadioInterviewB4Siemens] = useState("No");
  const [interviewB4Siemens, setInterviewB4Siemens] = useState("");
  const [
    radioSocialOrCulturalOrProffessional,
    setRadioSocialOrCulturalOrProffessional,
  ] = useState("No");
  const [socialOrCulturalOrProffessional, setSocialOrCulturalOrProffessional] =
    useState("");
  const [anyPosition, setAnyPosition] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [canJoinDate, setcanJoinDate] = useState(null);

  //Error State and Its UI
  const [
    textareaClassInterestAndProficiency,
    settextareaClassInterestAndProficiency,
  ] = useState("lightInputFields");
  const [
    errorPlaceHolderInterestAndProficiency,
    setErrorPlaceHolderInterestAndProficiency,
  ] = useState("Give details");
  const [textareaClassOtherBusiness, settextareaClassOtherBusiness] =
    useState("lightInputFields");
  const [errorPlaceHolderOtherBusiness, setErrorPlaceHolderOtherBusiness] =
    useState("If Yes, Give details");
  const [textareaClassFriendsOrRelatives, settextareaClassFriendsOrRelatives] =
    useState("lightInputFields");
  const [
    errorPlaceHolderFriendsOrRelatives,
    setErrorPlaceHolderFriendsOrRelatives,
  ] = useState("If Yes, Give details");
  const [textareaClassIllness, settextareaClassIllness] =
    useState("lightInputFields");
  const [errorPlaceHolderIllness, setErrorPlaceHolderIllness] = useState(
    "If Yes, Give details"
  );
  const [textareaClassHandicapped, settextareaClassHandicapped] =
    useState("lightInputFields");
  const [errorPlaceHolderHandicapped, setErrorPlaceHolderHandicapped] =
    useState("If Yes, Give details");
  const [textareaClassPrevInSiemens, settextareaClassPrevInSiemens] =
    useState("lightInputFields");
  const [errorPlaceHolderPrevInSiemens, setErrorPlaceHolderPrevInSiemens] =
    useState("If yes, give these details GID, start date and end date");
  const [textareaClassInterviewB4Siemens, settextareaClassInterviewB4Siemens] =
    useState("lightInputFields");
  const [
    errorPlaceHolderInterviewB4Siemens,
    setErrorPlaceHolderInterviewB4Siemens,
  ] = useState("If yes,For Which Position");
  const [
    textareaClassSocialOrCulturalOrProffessional,
    settextareaClassSocialOrCulturalOrProffessional,
  ] = useState("lightInputFields");
  const [
    errorPlaceHolderSocialOrCulturalOrProffessional,
    setErrorPlaceHolderSocialOrCulturalOrProffessional,
  ] = useState("If Yes, Give details");
  const [textareaClassPosition, settextareaClassPosition] =
    useState("lightInputFields");
  const [errorPlaceHolderPosition, setErrorPlaceHolderPosition] = useState(
    "If Yes, Give details"
  );
  const [textareaClassExpectedSalary, settextareaClassExpectedSalary] =
    useState("lightInputFields");
  const [errorPlaceHolderExpectedSalary, setErrorPlaceHolderExpectedSalary] =
    useState("If Yes, Give details");
  const [errorCanjoinDate, setErrorCanjoinDate] = useState(false);
  const [errorTextCanjoinDate, setErrorTextCanjoinDate] = useState("");
  const [errorExpectedSalary, setErrorExpectedSalary] = useState(false);
  const [errorTextExpectedSalary, setErrorTextExpectedSalary] = useState("");

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  var formData = {
    GbexpectedSalaryAndJoinedDateDetailsId: gbSalaryAndJoinedDateDetailsId,
    ApplicantId: props.id,
    UserId: props.userId,
    ListEmployeeGBData: [
      // {
      //   EmployeeGbdetailsId: employeeGBDetailsId[0],
      //   GbdeclarationId: 1,
      //   YesNo: 1,
      //   GbdeclarationComments: interestAndProficiency,
      // },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[1],
        GbdeclarationId: 2,
        YesNo: radioOtherBusiness == "No" ? 0 : 1,
        GbdeclarationComments: otherBusiness,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[2],
        GbdeclarationId: 3,
        YesNo: radioFriendsOrRelatives == "No" ? 0 : 1,
        GbdeclarationComments: friendsOrRelatives,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[3],
        GbdeclarationId: 4,
        YesNo: radioIllness == "No" ? 0 : 1,
        GbdeclarationComments: illness,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[4],
        GbdeclarationId: 5,
        YesNo: radioHandicapped == "No" ? 0 : 1,
        GbdeclarationComments: handicapped,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[5],
        GbdeclarationId: 6,
        YesNo: radioPrevInSiemens == "No" ? 0 : 1,
        GbdeclarationComments: prevInSiemens,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[6],
        GbdeclarationId: 7,
        YesNo: radioInterviewB4Siemens == "No" ? 0 : 1,
        GbdeclarationComments: interviewB4Siemens,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[7],
        GbdeclarationId: 8,
        YesNo: radioSocialOrCulturalOrProffessional == "No" ? 0 : 1,
        GbdeclarationComments: socialOrCulturalOrProffessional,
      },
      {
        EmployeeGbdetailsId: employeeGBDetailsId[8],
        GbdeclarationId: 9,
        YesNo: 1,
        GbdeclarationComments: anyPosition,
      },
    ],
    ExpectedSalary: expectedSalary,
    CanJoinDate: canJoinDate,
  };
  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false,
      e = false,
      f = false,
      g = false,
      h = false,
      j = false,
      k = false,
      l = false;
    if (data.ExpectedSalary == null || data.ExpectedSalary == "") {
      //setErrorPlaceHolderExpectedSalary("Expected Salary (p.a.) Mandatory Field");
      //settextareaClassExpectedSalary('border-color-textarea-validate mt-1');
      setErrorExpectedSalary(true);
      setErrorTextExpectedSalary("Mandatory Field");
      a = true;
    } else {
      //setErrorPlaceHolderExpectedSalary("Expected Salary (p.a.)");
      //settextareaClassExpectedSalary('lightInputFields mt-1');
      setErrorExpectedSalary(false);
      setErrorTextExpectedSalary("");
      a = false;
    }
    if (data.CanJoinDate == null || data.CanJoinDate == "") {
      setErrorCanjoinDate(true);
      setErrorTextCanjoinDate("Mandatory Field");
      b = true;
    } else {
      setErrorCanjoinDate(false);
      setErrorTextCanjoinDate("");
      b = false;
    }
    var lstempGBData = data.ListEmployeeGBData;
    for (let i = 0; i < lstempGBData.length; i++) {
      switch (lstempGBData[i].GbdeclarationId) {
        case 1:
          if (
            lstempGBData[i].GbdeclarationComments == null ||
            lstempGBData[i].GbdeclarationComments == ""
          ) {
            settextareaClassInterestAndProficiency(
              "border-color-textarea-validate mt-1"
            );
            setErrorPlaceHolderInterestAndProficiency("Mandatory Field");
            l = true;
          } else {
            settextareaClassInterestAndProficiency("lightInputFields mt-1");
            setErrorPlaceHolderInterestAndProficiency("Give Details");
            l = false;
          }
          break;
        case 2:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassOtherBusiness("border-color-textarea-validate");
              setErrorPlaceHolderOtherBusiness("Mandatory Field");
              c = true;
            } else {
              settextareaClassOtherBusiness("lightInputFields");
              setErrorPlaceHolderOtherBusiness("If Yes, Give Details");
              c = false;
            }
          } else {
            settextareaClassOtherBusiness("lightInputFields");
            setErrorPlaceHolderOtherBusiness("If Yes, Give Details");
            c = false;
          }
          break;
        case 3:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassFriendsOrRelatives(
                "border-color-textarea-validate"
              );
              setErrorPlaceHolderFriendsOrRelatives("Mandatory Field");
              d = true;
            } else {
              settextareaClassFriendsOrRelatives("lightInputFields");
              settextareaClassFriendsOrRelatives("If Yes, Give Details");
              d = false;
            }
          } else {
            settextareaClassFriendsOrRelatives("lightInputFields");
            settextareaClassFriendsOrRelatives("If Yes, Give Details");
            d = false;
          }
          break;
        case 4:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassIllness("border-color-textarea-validate");
              setErrorPlaceHolderIllness("Mandatory Field");
              e = true;
            } else {
              settextareaClassIllness("lightInputFields");
              setErrorPlaceHolderIllness("If Yes, Give Details");
              e = false;
            }
          } else {
            settextareaClassIllness("lightInputFields");
            setErrorPlaceHolderIllness("If Yes, Give Details");
            e = false;
          }
          break;
        case 5:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassHandicapped("border-color-textarea-validate");
              setErrorPlaceHolderHandicapped("Mandatory Field");
              f = true;
            } else {
              settextareaClassHandicapped("lightInputFields");
              setErrorPlaceHolderHandicapped("If Yes, Give Details");
              f = false;
            }
          } else {
            settextareaClassHandicapped("lightInputFields");
            setErrorPlaceHolderHandicapped("If Yes, Give Details");
            f = false;
          }
          break;
        case 6:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassPrevInSiemens("border-color-textarea-validate");
              setErrorPlaceHolderPrevInSiemens("Mandatory Field");
              g = true;
            } else {
              settextareaClassPrevInSiemens("lightInputFields");
              setErrorPlaceHolderPrevInSiemens(
                "If yes,Give details including GID"
              );
              g = false;
            }
          } else {
            settextareaClassPrevInSiemens("lightInputFields");
            setErrorPlaceHolderPrevInSiemens(
              "If yes,Give details including GID"
            );
            g = false;
          }
          break;
        case 7:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassInterviewB4Siemens(
                "border-color-textarea-validate"
              );
              setErrorPlaceHolderInterviewB4Siemens("Mandatory Field");
              h = true;
            } else {
              settextareaClassInterviewB4Siemens("lightInputFields");
              setErrorPlaceHolderInterviewB4Siemens(
                "If yes,For Which Position"
              );
              h = false;
            }
          } else {
            settextareaClassInterviewB4Siemens("lightInputFields");
            setErrorPlaceHolderInterviewB4Siemens("If yes,For Which Position");
            h = false;
          }
          break;
        case 8:
          if (lstempGBData[i].YesNo === 1) {
            if (
              lstempGBData[i].GbdeclarationComments == null ||
              lstempGBData[i].GbdeclarationComments == ""
            ) {
              settextareaClassSocialOrCulturalOrProffessional(
                "border-color-textarea-validate"
              );
              setErrorPlaceHolderSocialOrCulturalOrProffessional(
                "Mandatory Field"
              );
              j = true;
            } else {
              settextareaClassSocialOrCulturalOrProffessional(
                "lightInputFields"
              );
              setErrorPlaceHolderSocialOrCulturalOrProffessional(
                "If Yes, Give Details"
              );
              j = false;
            }
          } else {
            settextareaClassSocialOrCulturalOrProffessional("lightInputFields");
            setErrorPlaceHolderSocialOrCulturalOrProffessional(
              "If Yes, Give Details"
            );
            j = false;
          }
          break;
        case 9:
          if (
            lstempGBData[i].GbdeclarationComments == null ||
            lstempGBData[i].GbdeclarationComments == ""
          ) {
            settextareaClassPosition("border-color-textarea-validate");
            setErrorPlaceHolderPosition("Mandatory Field");
            k = true;
          } else {
            settextareaClassPosition("lightInputFields");
            setErrorPlaceHolderPosition("If Yes, Give Details");
            k = false;
          }
          break;
        default:
          break;
      }
    }
    if (a || b || c || d || e || f || g || h || j || k) {
      return false;
    } else {
      return true;
    }
  }
  //Alerts
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  const expectedSalaryChangeEvent = (event) => {
    //var number = event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedValue = (
      Number(event.target.value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    setExpectedSalary(formattedValue);
  };

  function dateChangeEvent(dateValue, label) {
    if (label === "Can Join Date") {
      setcanJoinDate(dateValue);
    }
  }
  const handleChangeRadioButton = (event) => {
    switch (event.target.name) {
      case "2":
        setRadioOtherBusiness(event.target.value);
        if (event.target.value == "No") {
          setOtherBusiness("");
        }
        break;
      case "3":
        setRadioFriendsOrRelatives(event.target.value);
        if (event.target.value == "No") {
          setFriendsOrRelatives("");
        }
        break;
      case "4":
        setRadioIllness(event.target.value);
        if (event.target.value == "No") {
          setIllness("");
        }
        break;
      case "5":
        setRadioHandicapped(event.target.value);
        if (event.target.value == "No") {
          setHandicapped("");
        }
        break;
      case "6":
        setRadioPrevInSiemens(event.target.value);
        if (event.target.value == "No") {
          setPrevInSiemens("");
        }
        break;
      case "7":
        setRadioInterviewB4Siemens(event.target.value);
        if (event.target.value == "No") {
          setInterviewB4Siemens("");
        }
        break;
      case "8":
        setRadioSocialOrCulturalOrProffessional(event.target.value);
        if (event.target.value == "No") {
          setSocialOrCulturalOrProffessional("");
        }
        break;
      default:
        break;
    }
  };

  const submitHandler = (e) => {
    setIsLoader(true);
    formData = {
      GbexpectedSalaryAndJoinedDateDetailsId: gbSalaryAndJoinedDateDetailsId,
      ApplicantId: props.id,
      UserId: props.userId,
      ListEmployeeGBData: [
        {
          EmployeeGbdetailsId: employeeGBDetailsId[0],
          GbdeclarationId: 1,
          YesNo: 1,
          GbdeclarationComments: interestAndProficiency,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[1],
          GbdeclarationId: 2,
          YesNo: radioOtherBusiness == "No" ? 0 : 1,
          GbdeclarationComments: otherBusiness,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[2],
          GbdeclarationId: 3,
          YesNo: radioFriendsOrRelatives == "No" ? 0 : 1,
          GbdeclarationComments: friendsOrRelatives,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[3],
          GbdeclarationId: 4,
          YesNo: radioIllness == "No" ? 0 : 1,
          GbdeclarationComments: illness,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[4],
          GbdeclarationId: 5,
          YesNo: radioHandicapped == "No" ? 0 : 1,
          GbdeclarationComments: handicapped,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[5],
          GbdeclarationId: 6,
          YesNo: radioPrevInSiemens == "No" ? 0 : 1,
          GbdeclarationComments: prevInSiemens,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[6],
          GbdeclarationId: 7,
          YesNo: radioInterviewB4Siemens == "No" ? 0 : 1,
          GbdeclarationComments: interviewB4Siemens,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[7],
          GbdeclarationId: 8,
          YesNo: radioSocialOrCulturalOrProffessional == "No" ? 0 : 1,
          GbdeclarationComments: socialOrCulturalOrProffessional,
        },
        {
          EmployeeGbdetailsId: employeeGBDetailsId[8],
          GbdeclarationId: 9,
          YesNo: 1,
          GbdeclarationComments: anyPosition,
        },
      ],
      ExpectedSalary: expectedSalary,
      CanJoinDate: canJoinDate,
    };
    if (ValidateFormFields(formData)) {
      if (
        gbSalaryAndJoinedDateDetailsId &&
        gbSalaryAndJoinedDateDetailsId > 0
      ) {
        UpdateGBData(formData);
      } else {
        SaveGBData(formData);
      }
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    }
  };

  function clearStates() {
    setInterestAndProficiency("");
    setOtherBusiness("");
    setRadioOtherBusiness("No");
    setFriendsOrRelatives("");
    setRadioFriendsOrRelatives("No");
    setIllness("");
    setRadioIllness("No");
    setHandicapped("");
    setRadioHandicapped("No");
    setPrevInSiemens("");
    setRadioPrevInSiemens("No");
    setInterviewB4Siemens("");
    setRadioInterviewB4Siemens("No");
    setSocialOrCulturalOrProffessional("");
    setRadioSocialOrCulturalOrProffessional("No");
    setAnyPosition("");
    setExpectedSalary("");
    setcanJoinDate(null);
  }
  const cancelHandler = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //Change Button Text
  function getButtonTextSave(gbSalaryAndJoinedDateDetailsId) {
    gbSalaryAndJoinedDateDetailsId != null && gbSalaryAndJoinedDateDetailsId > 0
      ? setButtonTextSave("Update")
      : setButtonTextSave(buttonTextSave);
  }

  //Set All States
  function SetStateAsPerFetched(data) {
    setGBSalaryAndJoinedDateDetailsId(
      data.gbexpectedSalaryAndJoinedDateDetailsId
    );
    setcanJoinDate(data.canJoinDate);
    if (
      data.expectedSalary != null &&
      data.expectedSalary != "" &&
      data.expectedSalary != undefined
    ) {
      const formattedValue = (
        Number(data.expectedSalary.toString().replace(/\D/g, "")) || ""
      ).toLocaleString();
      setExpectedSalary(formattedValue);
    }
    //setExpectedSalary(data.expectedSalary);
    if (data.listEmployeeGBData != null) {
      var arrIds = [];
      for (var i = 0; i < data.listEmployeeGBData.length; i++) {
        arrIds.push(data.listEmployeeGBData[i].employeeGbdetailsId);
        setEmployeeGBDetailsId(arrIds);
        switch (data.listEmployeeGBData[i].gbdeclarationId) {
          case 1:
            setInterestAndProficiency(
              data.listEmployeeGBData[i].gbdeclarationComments
            );
            break;
          case 2:
            setRadioOtherBusiness(
              data.listEmployeeGBData[i].yesNo ? "Yes" : "No"
            );
            setOtherBusiness(data.listEmployeeGBData[i].gbdeclarationComments);
            break;
          case 3:
            setRadioFriendsOrRelatives(
              data.listEmployeeGBData[i].yesNo ? "Yes" : "No"
            );
            setFriendsOrRelatives(
              data.listEmployeeGBData[i].gbdeclarationComments
            );
            break;
          case 4:
            setRadioIllness(data.listEmployeeGBData[i].yesNo ? "Yes" : "No");
            setIllness(data.listEmployeeGBData[i].gbdeclarationComments);
            break;
          case 5:
            setRadioHandicapped(
              data.listEmployeeGBData[i].yesNo ? "Yes" : "No"
            );
            setHandicapped(data.listEmployeeGBData[i].gbdeclarationComments);
            break;
          case 6:
            setRadioPrevInSiemens(
              data.listEmployeeGBData[i].yesNo ? "Yes" : "No"
            );
            setPrevInSiemens(data.listEmployeeGBData[i].gbdeclarationComments);
            break;
          case 7:
            setRadioInterviewB4Siemens(
              data.listEmployeeGBData[i].yesNo ? "Yes" : "No"
            );
            setInterviewB4Siemens(
              data.listEmployeeGBData[i].gbdeclarationComments
            );
            break;
          case 8:
            setRadioSocialOrCulturalOrProffessional(
              data.listEmployeeGBData[i].yesNo ? "Yes" : "No"
            );
            setSocialOrCulturalOrProffessional(
              data.listEmployeeGBData[i].gbdeclarationComments
            );
            break;
          case 9:
            setAnyPosition(data.listEmployeeGBData[i].gbdeclarationComments);
            break;
          default:
            break;
        }
      }
    }
    setIsLoader(false);
  }
  //Save General Background Data
  function SaveGBData(formData) {
    const token = props.token;
    const options = {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("PreHiringForm/SaveGeneralBackground/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          //alert(data.responseMsg);
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          //alert(data.responseMsg);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }
  //Update General Background Data
  function UpdateGBData(formDetails) {
    const token = props.token;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formDetails),
    };
    fetch("PreHiringForm/UpdateGeneralBackground/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          // alert(data.responseMsg);
          SetStateAsPerFetched(data);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          //alert(data.responseMsg);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }
  //Get General Background Data on Page Load
  function getGBDataOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("PreHiringForm/GetGeneralBackgroundDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getGBDataOnLoad(props.id);
      }
    }
    getButtonTextSave(gbSalaryAndJoinedDateDetailsId);
  }, [props.id, gbSalaryAndJoinedDateDetailsId, props.token]);

  useEffect(() => {
    getButtonTextSave(gbSalaryAndJoinedDateDetailsId);
  }, [gbSalaryAndJoinedDateDetailsId]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            {/* <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt fd-row bdr-bottom"
            >
              <p>
                Mention any two interest and proficiency achieved in
                them.&nbsp;*
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextareaAutosize
                  className={textareaClassInterestAndProficiency}
                  id="interests"
                  placeholder={errorPlaceHolderInterestAndProficiency}
                  rowsMin={2}
                  label=""
                  name="1"
                  value={interestAndProficiency}
                  onChange={(e) => setInterestAndProficiency(e.target.value)}
                  disabled={props.disableValue}
                />
              </Grid>
            </Grid> */}
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>
                Are/Were you personally connected to any business in
                present/past?&nbsp;*
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="2"
                    value={radioOtherBusiness}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioOtherBusiness == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassOtherBusiness}
                      id="personalBussiness"
                      rowsMin={2}
                      placeholder={errorPlaceHolderOtherBusiness}
                      name="2"
                      value={otherBusiness}
                      onChange={(e) => setOtherBusiness(e.target.value)}
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>
                Do you have any family members / relatives who are or were
                working as a Director / Permanent employee / contractual
                employee at Siemens?&nbsp;*
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="3"
                    value={radioFriendsOrRelatives}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioFriendsOrRelatives == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassFriendsOrRelatives}
                      id="contacts"
                      rowsMin={2}
                      name="3"
                      placeholder={errorPlaceHolderFriendsOrRelatives}
                      value={friendsOrRelatives}
                      onChange={(e) => setFriendsOrRelatives(e.target.value)}
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>Have you suffered from any major illness or Surgery?&nbsp;*</p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="4"
                    value={radioIllness}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioIllness == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassIllness}
                      id="illness"
                      rowsMin={2}
                      name="4"
                      placeholder={errorPlaceHolderIllness}
                      value={illness}
                      onChange={(e) => setIllness(e.target.value)}
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>
                Are you Physically
                Handicapped?&nbsp;*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="5"
                    value={radioHandicapped}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioHandicapped == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassHandicapped}
                      id="physical"
                      placeholder={errorPlaceHolderHandicapped}
                      rowsMin={2}
                      name="5"
                      onChange={(e) => setHandicapped(e.target.value)}
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>
                Have you worked with Siemens group of Companies in the past as a
                permanent employee / contract staff / on deputation?&nbsp;*
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="6"
                    value={radioPrevInSiemens}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioPrevInSiemens == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassPrevInSiemens}
                      id="siemensPast"
                      placeholder={errorPlaceHolderPrevInSiemens}
                      rowsMin={2}
                      name="6"
                      value={prevInSiemens}
                      onChange={(e) => setPrevInSiemens(e.target.value)}
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>
                Have you been interviewed before in
                Siemens?&nbsp;*&nbsp;&nbsp;&nbsp;
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="7"
                    value={radioInterviewB4Siemens}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioInterviewB4Siemens == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassInterviewB4Siemens}
                      id="siemensPastInterview"
                      placeholder={errorPlaceHolderInterviewB4Siemens}
                      rowsMin={2}
                      name="7"
                      value={interviewB4Siemens}
                      onChange={(e) => setInterviewB4Siemens(e.target.value)}
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row bdr-bottom"
            >
              <p>
                Are you connected with any Social / cultural / professional
                association?&nbsp;*
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <FormControl component="fieldset" className="mt-1">
                  <RadioGroup
                    aria-label=""
                    name="8"
                    value={radioSocialOrCulturalOrProffessional}
                    onChange={handleChangeRadioButton}
                    row
                  >
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={props.disableValue}
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {radioSocialOrCulturalOrProffessional == "No" ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex jc-center Width-100"
                  >
                    <TextareaAutosize
                      className={textareaClassSocialOrCulturalOrProffessional}
                      id="association"
                      placeholder={
                        errorPlaceHolderSocialOrCulturalOrProffessional
                      }
                      rowsMin={2}
                      name="8"
                      value={socialOrCulturalOrProffessional}
                      onChange={(e) =>
                        setSocialOrCulturalOrProffessional(e.target.value)
                      }
                      disabled={props.disableValue}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row"
            >
              <p>
                Would you like to be considered Employment in any positions at
                Siemens?&nbsp;*
              </p>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextareaAutosize
                  className={textareaClassPosition}
                  id="position"
                  placeholder={errorPlaceHolderPosition}
                  rowsMin={2}
                  name="9"
                  value={anyPosition}
                  onChange={(e) => setAnyPosition(e.target.value)}
                  disabled={props.disableValue}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-bt mt-1 fd-row"
            >
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="expectedSalary"
                  fullWidth
                  variant="filled"
                  //type="number"
                  //rowsMin={2}
                  placeholder={errorPlaceHolderExpectedSalary}
                  label="Expected Salary (p.a.)"
                  value={expectedSalary}
                  required={true}
                  onKeyDown={handleKeypress}
                  onChange={expectedSalaryChangeEvent}
                  disabled={props.disableValue}
                  error={errorExpectedSalary}
                  helperText={errorTextExpectedSalary}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                {/*<TextField className="lightInputFields mt-1"
                                    id="date"
                                    fullWidth
                                    label="Can Join Date"
                                    type="date"
                                    defaultValue="2017-05-24"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setcanJoinDate(e.target.value)}
                                />*/}
                <FormDatePicker
                  placeholder="Can Join Date"
                  label="Can Join Date"
                  value={canJoinDate}
                  onChange={dateChangeEvent}
                  disablefuturedate={false}
                  disablepastdate={true}
                  disabled={props.disableValue}
                  required={true}
                  error={errorCanjoinDate}
                  helperText={errorTextCanjoinDate}
                />
              </Grid>
            </Grid>
            <div className="d-flex jc-center">
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2"
                disabled={props.disableValue}
                onClick={cancelHandler}
              >
                <span>Clear</span>
              </Button>
              <Button
                xs={12}
                sm={6}
                className="ns-btn-primary mt-2 ml-2"
                disabled={props.disableValue}
                onClick={submitHandler}
              >
                <span>{buttonTextSave}</span>
              </Button>
            </div>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
