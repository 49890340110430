import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
    TextField,
    createMuiTheme,
    ThemeProvider,
    InputAdornment,
    IconButton,
    ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import ImageUploader from "react-images-upload";
import Autocomplete from "@material-ui/lab/Autocomplete";
import validator from "validator";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileSaver from "file-saver";
import axios from "axios";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";
import ErrorOutlineOutlined from "@material-ui/icons/ErrorOutlineOutlined";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
            adornedEnd: {
                backgroundColor: "#E8F8F8",
                //padding:"12px 0 0 12px !important",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8 !important",
                },
                //"&&&&:hover": {
                //    backgroundColor: "#E8F8F8"
                //},
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },

        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#00E8F8",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8",
        //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8",
        //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },
        MuiDialogTitle: {
            root: {
                padding: "8px 24px",
            },
        },
        MuiDialogActions: {
            root: {
                background: "#000028",
            },
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#000028",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#6a7272",
            },
        },
        //MuiPickersBasePicker: {
        //    pickerView: {
        //        background: "#FFFFFF"
        //    }
        //},
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#000028",
            },
            background: "#000028",
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
        MuiFormHelperText: {
            contained: {
                backgroundColor: "white",
                marginBottom: "-10px !important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important",
            },
        },
    },
});

export default function CommunicationData(props) {
    var id;
    const [buttonTextSave, setButtonTextSave] = useState("Save");
    const [requirePAN, setRequirePAN] = useState(true);
    const [requireAadhar, setRequireAadhar] = useState(true);
    const [communicationDetailsId, setCommunicationDetailsId] = useState(0);
    const [appliedPAN, setAppliedPAN] = useState(1);
    const [appliedPANObj, setAppliedPANObj] = useState("");
    const [appliedPANText, setAppliedPANText] = useState("");
    const [disablePANField, setDisablePANField] = useState(false);
    const [panNo, setPAN] = useState("");
    const [panImage, setPANImage] = useState("");
    const [appliedAadhar, setAppliedAadhar] = useState(1);
    const [appliedAadharObj, setAppliedAadharObj] = useState("");
    const [appliedAadharText, setAppliedAadharText] = useState("");
    const [disableAadharField, setDisableAadharField] = useState(false);
    const [aadharCardNumber, setAadhar] = useState("");
    const [totalNoOfDependents, setTotalNoOfDependents] = useState("");
    const [addressUsageType, setAddressUsageType] = useState("");
    const [addressUsageTypeObj, setAddressUsageTypeObj] = useState("");
    const [addressUsageTypeText, setAddressUsageTypeText] = useState("");
    const [phoneUsageType, setPhoneUsageType] = useState("");
    const [phoneUsageTypeObj, setPhoneUsageTypeObj] = useState("");
    const [phoneUsageTypeText, setPhoneUsageTypeText] = useState("");
    //For Present Address
    const [cOPresent, setCOPresent] = useState("");
    const [firstAddressLinePresent, setFirstAddressLinePresent] = useState("");
    const [secondAddressLinePresent, setSecondAddressLinePresent] = useState("");
    const [thirdAddressLinePresent, setThirdAddressLinePresent] = useState("");
    const [countryPresent, setCountryPresent] = useState("");
    const [countryPresentObj, setCountryPresentObj] = useState("");
    const [countryPresentText, setCountryPresentText] = useState("");
    const [statePresent, setStatePresent] = useState("");
    const [statePresentObj, setStatePresentObj] = useState("");
    const [statePresentText, setStatePresentText] = useState("");
    const [cityPresent, setCityPresent] = useState("");
    const [pinCodePresent, setPinCodePresent] = useState("");
    const [regionPresent, setRegionPresent] = useState("");
    const [presentTelephoneNo, setPresentTelephoneNo] = useState("");
    const [countryPhoneCodePresent, setCountryPhoneCodePresent] = useState("");
    const [countryPhoneCodePresentObj, setCountryPhoneCodePresentObj] =
        useState("");
    const [countryPhoneCodePresentText, setCountryPhoneCodePresentText] =
        useState("");
    const [mobileNoPresent, setMobileNoPresent] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [alternateEmail, setAlternateEmail] = useState("");
    const [presentAddressPrimary, setPresentAddressPrimary] = useState(1);
    const [presentAddressPrimaryObj, setPresentAddressPrimaryObj] = useState("");
    const [presentAddressPrimaryText, setPresentAddressPrimaryText] =
        useState("");
    const [disablePresentAddressPrimary, setDisablePresentAddressPrimary] =
        useState(false);
    const [phoneISOText, setPhoneISOText] = useState("");
    //For Permanent Address
    const [cOPermanent, setCOPermanent] = useState("");
    const [firstAddressLinePermanent, setFirstAddressLinePermanent] =
        useState("");
    const [secondAddressLinePermanent, setSecondAddressLinePermanent] =
        useState("");
    const [thirdAddressLinePermanent, setThirdAddressLinePermanent] =
        useState("");
    const [countryPermanent, setCounrtyPermanent] = useState("");
    const [countryPermanentObj, setCounrtyPermanentObj] = useState("");
    const [countryPermanentText, setCounrtyPermanentText] = useState("");
    const [statePermanent, setStatePermanent] = useState("");
    const [statePermanentObj, setStatePermanentObj] = useState("");
    const [statePermanentText, setStatePermanentText] = useState("");
    const [cityPermanent, setCityPermanent] = useState("");
    const [pinCodePermanent, setPinCodePermanent] = useState("");
    const [regionPermanent, setRegionPermanent] = useState("");
    const [permanentTelephoneNo, setPermanentTelephoneNo] = useState("");
    const [alternateMobileNo, setAlternateMobileNo] = useState("");
    const [permanentAddressPrimary, setPermanentAddressPrimary] = useState(1);
    const [permanentAddressPrimaryObj, setPermanentAddressPrimaryObj] =
        useState("");
    const [permanentAddressPrimaryText, setPermanentAddressPrimaryText] =
        useState("");
    const [disablePermanentAddressPrimary, setDisablePermanentAddressPrimary] =
        useState(false);
    //For Passport
    const [passportDetailsId, setPassportDetailsId] = useState(0);
    const [passportNo, setPassportNo] = useState("");
    const [placeIssueOfPassport, setPlaceIssueOfPassport] = useState("");
    const [dateOfIssueOfPassport, setDateOfIssueOfPassport] = useState(null);
    const [countryOfIssueOfPassport, setCountryOfIssueOfPassport] = useState("");
    const [countryOfIssueOfPassportObj, setCountryOfIssueOfPassportObj] =
        useState("");
    const [countryOfIssueOfPassportText, setCountryOfIssueOfPassportText] =
        useState("");
    const [passportValidTill, setPassportValidTill] = useState(null);
    const [issuingAuthorityOfPassport, setIssuingAuthorityOfPassport] =
        useState("");
    const [encrStatusOfPassport, setENCRStatusOfPassport] = useState("");
    //For Driving License
    const [dlDetailsId, setDLDetailsId] = useState(0);
    const [drivingLicenseNo, setDrivingLicenseNo] = useState("");
    const [placeOfIssueDL, setPlaceOfIssueDL] = useState("");
    const [dateOfIssueOfLicense, setDateOfIssueOfLicense] = useState(null);
    const [countryOfIssueOfLicense, setCountryOfIssueOfLicense] = useState("");
    const [countryOfIssueOfLicenseObj, setCountryOfIssueOfLicenseObj] =
        useState("");
    const [countryOfIssueOfLicenseText, setCountryOfIssueOfLicenseText] =
        useState("");
    const [licenseValidTill, setLicenseValidTill] = useState(null);
    const [issuingAuthorityOfLicense, setIssuingAuthorityOfLicense] =
        useState("");
    //For Emergency Contact Details
    const [emergencyContactDetailsId, setEmergencyContactDetailsId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [genderObj, setGenderObj] = useState("");
    const [genderText, setGenderText] = useState("");
    const [landlineMobile, setLandlineMobile] = useState("");
    const [landlineMobileObj, setLandlineMobileObj] = useState("");
    const [landlineMobileText, setLandlineMobileText] = useState("");
    const [emergencyContactNo, setEmergencyContactNo] = useState("");

    //For Uploads
    const [panImageFile, setPANImageFile] = useState(null);
    const [imageHelperPAN, setImageHelperPAN] = useState("");
    const [inputFileValuePAN, setInputFileValuePAN] = useState(Date.now);
    const [aadharImageFile, setAadharImageFile] = useState(null);
    const [imageHelperAadhar, setImageHelperAadhar] = useState("");
    const [inputFileValueAadhar, setInputFileValueAadhar] = useState(Date.now);
    const [panImageData, setPANImageData] = useState(null);
    const [aadharImageData, setAadharImageData] = useState(null);
    const [aadharFileInBase64, setAadharFileInBase64] = useState(null);
    const [panFileInBase64, setPanFileInBase64] = useState(null);
    const [disabledUploadButtonPAN, setDisableUploadButtonPAN] = useState(false);
    const [disabledUploadButtonAadhar, setDisableUploadButtonAadhar] =
        useState(false);

    //Error States and Msg
    const [errorPANExist, setErrorPANExist] = useState(false);
    const [errorTextPANExist, setErrorTextPANExist] = useState("");
    const [errorPANno, setErrorPANno] = useState(false);
    const [errorTextPANno, setErrorTextPANno] = useState("");
    const [errorAadharExist, setErrorAadharExist] = useState(false);
    const [errorTextAadharExist, setErrorTextAadharExist] = useState("");
    const [errorAadharNo, setErrorAadharNo] = useState(false);
    const [errorTextAadharNo, setErrorTextAadharNo] = useState("");
    const [errorFirstAddressLinePresent, setErrorFirstAddressLinePresent] =
        useState(false);
    const [
        errorTextFirstAddressLinePresent,
        setErrorTextFirstAddressLinePresent,
    ] = useState("");
    const [errorSecondAddressLinePresent, setErrorSecondAddressLinePresent] =
        useState(false);
    const [
        errorTextSecondAddressLinePresent,
        setErrorTextSecondAddressLinePresent,
    ] = useState("");
    const [errorThirdAddressLinePresent, setErrorThirdAddressLinePresent] =
        useState(false);
    const [
        errorTextThirdAddressLinePresent,
        setErrorTextThirdAddressLinePresent,
    ] = useState("");
    const [errorCountryPresent, setErrorCountryPresent] = useState(false);
    const [errorTextCountryPresent, setErrorTextCountryPresent] = useState("");
    const [errorStatePresent, setErrorStatePresent] = useState(false);
    const [errorTextStatePresent, setErrorTextStatePresent] = useState("");
    const [errorCityPresent, setErrorCityPresent] = useState(false);
    const [errorTextCityPresent, setErrorTextCityPresent] = useState("");
    const [errorPinCodePresent, setErrorPinCodePresent] = useState(false);
    const [errorTextPinCodePresent, setErrorTextPinCodePresent] = useState("");
    const [errorPersonalEmail, setErrorPersonalEmail] = useState(false);
    const [errorTextPersonalEmail, setErrorTextPersonalEmail] = useState("");
    const [errorCountryPhoneCode, setErrorCountryPhoneCode] = useState(false);
    const [errorTextCountryPhoneCode, setErrorTextCountryPhoneCode] =
        useState("");
    const [errorPhoneISOText, setErrorPhoneISOText] = useState(false);
    const [errorTextPhoneISOText, setErrorTextPhoneISOText] = useState("");
    const [errorMobileNumber, setErrorMobileNumber] = useState(false);
    const [errorTextMobileNumber, setErrorTextMobileNumber] = useState("");
    const [errorFirstAddressLinePermanent, setErrorFirstAddressLinePermanent] =
        useState(false);
    const [
        errorTextFirstAddressLinePermanent,
        setErrorTextFirstAddressLinePermanent,
    ] = useState("");
    const [errorSecondAddressLinePermanent, setErrorSecondAddressLinePermanent] =
        useState(false);
    const [
        errorTextSecondAddressLinePermanent,
        setErrorTextSecondAddressLinePermanent,
    ] = useState("");
    const [errorThirdAddressLinePermanent, setErrorThirdAddressLinePermanent] =
        useState(false);
    const [
        errorTextThirdAddressLinePermanent,
        setErrorTextThirdAddressLinePermanent,
    ] = useState("");
    const [errorCountryPermanent, setErrorCountryPermanent] = useState(false);
    const [errorTextCountryPermanent, setErrorTextCountryPermanent] =
        useState("");
    const [errorStatePermanent, setErrorStatePermanent] = useState(false);
    const [errorTextStatePermanent, setErrorTextStatePermanent] = useState("");
    const [errorCityPermanent, setErrorCityPermanent] = useState(false);
    const [errorTextCityPermanent, setErrorTextCityPermanent] = useState("");
    const [errorPinCodePermanent, setErrorPinCodePermanent] = useState(false);
    const [errorTextPinCodePermanent, setErrorTextPinCodePermanent] =
        useState("");
    const [errorCountryOfIssueOfPassport, setErrorCountryOfIssueOfPassport] =
        useState(false);
    const [
        errorTextCountryOfIssueOfPassport,
        setErrorTextCountryOfIssueOfPassport,
    ] = useState("");
    const [errorCountryOfIssueOfLicense, setErrorCountryOfIssueOfLicense] =
        useState(false);
    const [
        errorTextCountryOfIssueOfLicense,
        setErrorTextCountryOfIssueOfLicense,
    ] = useState("");
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorTextFirstName, setErrorTextFirstName] = useState("");
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorTextLastName, setErrorTextLastName] = useState("");
    const [errorGender, setErrorGender] = useState(false);
    const [errorTextGender, setErrorTextGender] = useState("");
    const [errorMobile, setErrorMobile] = useState(false);
    const [errorTextMobile, setErrorTextMobile] = useState("");
    const [errorEmergencyContactNo, setErrorEmergencyContactNo] = useState(false);
    const [errorTextEmergencyContactNo, setErrorTextEmergencyContactNo] =
        useState("");
    const [errorPresentAddressPrimary, setErrorPresentAddressPrimary] =
        useState(false);
    const [errorTextPresentAddressPrimary, setErrorTextPresentAddressPrimary] =
        useState("");
    const [errorPermanentAddressPrimary, setErrorPermanentAddressPrimary] =
        useState(false);
    const [
        errorTextPermanentAddressPrimary,
        setErrorTextPermanentAddressPrimary,
    ] = useState("");
    const [errorAddressUsageType, setErrorAddressUsageType] = useState(false);
    const [errorTextAddressUsageType, setErrorTextAddressUsageType] =
        useState("");
    const [errorPhoneUsageType, setErrorPhoneUsageType] = useState(false);
    const [errorTextPhoneUsageType, setErrorTextPhoneUsageType] = useState("");

    const [mustAadharOrPANNote, setMustAadharOrPANNote] = useState(false);

    const [errorAlternateEmail, setErrorAlternateEmail] = useState("");
    const [errorTextAlternateEmail, setErrorTextAlternateEmail] = useState("");
    const [errorAlternateMobileNo, setErrorAlternateMobileNo] = useState("");
    const [errorTextAlternateMobileNo, setErrorTextAlternateMobileNo] =
        useState("");

    const [hideTickPAN, setHideTickPAN] = useState(true);
    const [hideExclaimationPAN, setHideExclaimationPAN] = useState(true);
    const [hideTickAadhar, setHideTickAadhar] = useState(true);
    const [hideExclaimationAadhar, setHideExclaimationAadhar] = useState(true);

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const [isLoader, setIsLoader] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        clearStates();
        setOpenConfirm(false);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    function GetSelectFileTooltip() {
        return (
            <React.Fragment>
                <ListItemText primary="• File size: 150KB Max" />
                <ListItemText primary="• File Type: .pdf only" />
            </React.Fragment>
        );
    }
    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleKeypress = (e) => {
        const characterCode = e.key;
        if (characterCode === "Backspace") return;

        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            }
        } else {
            e.preventDefault();
        }
    };

    var fileUploadDataPAN = new FormData();
    const changePANFileHandler = async (e) => {
        if (e.target.files[0]) {
            let type = e.target.files[0].type.replace("application/", "");
            let size = e.target.files[0].size;
            if (type.toLowerCase() !== "pdf") {
                setImageHelperPAN("Only pdf file is allowed");
                setPANImageFile(null);
                setPanFileInBase64(null);
                setInputFileValuePAN(Date.now);
            } else if (size > 153600) {
                setImageHelperPAN("Max of 150KB file is allowed");
                setPANImageFile(null);
                setPanFileInBase64(null);
                setInputFileValuePAN(Date.now);
            } else {
                setImageHelperPAN("");
                setPANImageFile(e.target.files[0]);
                let binFile = await toBase64(e.target.files[0]).catch((e) => Error(e));
                if (binFile instanceof Error) {
                    console.log("Error: ", binFile.message);
                    setImageHelperPAN("Some error in file conversion");
                    setPANImageFile(null);
                    setPanFileInBase64(null);
                    setInputFileValuePAN(Date.now);
                } else {
                    let binFileArr = binFile.split(",");
                    setPanFileInBase64(binFileArr[1]);
                }
            }
        } else {
            setPANImageFile(null);
            setPanFileInBase64(null);
            setInputFileValuePAN(Date.now);
        }
    };
    const uploadPANFileHandler = (e) => {
        setIsLoader(true);
        uploadFileData.applicantId = props.id;
        uploadFileData.panFileData = panFileInBase64;
        uploadFileData.userId = props.userId;
        UploadPANImage(uploadFileData);
    };
    const downloadPANImageHandler = (e) => {
        getPANPhoto(props.id);
    };
    const deletePANImageHandler = (e) => {
        setIsLoader(true);
        uploadFileData.applicantId = props.id;
        uploadFileData.panFileData = panFileInBase64;
        uploadFileData.userId = props.userId;
        deletePANImage(uploadFileData);
    };
    function deletePANImage(id) {
        const token = props.token;
        const options = {
            method: "delete",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(uploadFileData),
        };
        fetch("PreHiringForm/DeletePANImage/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setPANImageFile(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    getPANImage(props.id);
                } else {
                    setPANImageFile(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    function getPANImage(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("PreHiringForm/GetPANImage/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAfterPANFileOperation(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    function UploadPANImage(uploadFileData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(uploadFileData),
        };
        fetch("PreHiringForm/UploadPANImage/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAfterPANFileOperation(data);
                } else {
                    setPANImageFile(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    function SetStateAfterPANFileOperation(data) {
        setPANImageData(data.panFileData);
        setPANImageFile(null);
        setIsLoader(false);
    }

    var fileUploadDataAadhar = new FormData();
    let uploadFileData = {
        applicantId: props.id,
        aadhaarFileData: aadharFileInBase64,
        panFileData: panFileInBase64,
        userId: props.userId,
    };
    const changeAadharFileHandler = async (e) => {
        if (e.target.files[0]) {
            let type = e.target.files[0].type.replace("application/", "");
            let size = e.target.files[0].size;
            if (type.toLowerCase() !== "pdf") {
                setImageHelperAadhar("Only pdf file is allowed");
                setAadharImageFile(null);
                setAadharFileInBase64(null);
                setInputFileValueAadhar(Date.now);
            } else if (size > 153600) {
                setImageHelperAadhar("Max of 150KB file is allowed");
                setAadharImageFile(null);
                setAadharFileInBase64(null);
                setInputFileValueAadhar(Date.now);
            } else {
                setImageHelperAadhar("");
                setAadharImageFile(e.target.files[0]);
                let binFile = await toBase64(e.target.files[0]).catch((e) => Error(e));
                if (binFile instanceof Error) {
                    console.log("Error: ", binFile.message);
                    setImageHelperAadhar("Some error in file conversion");
                    setAadharImageFile(null);
                    setAadharFileInBase64(null);
                    setInputFileValueAadhar(Date.now);
                } else {
                    let binFileArr = binFile.split(",");
                    setAadharFileInBase64(binFileArr[1]);
                }
            }
        } else {
            setAadharImageFile(null);
            setAadharFileInBase64(null);
            setInputFileValueAadhar(Date.now);
        }
    };
    const uploadAadharFileHandler = (e) => {
        setIsLoader(true);
        uploadFileData.applicantId = props.id;
        uploadFileData.aadhaarFileData = aadharFileInBase64;
        uploadFileData.userId = props.userId;
        UploadAadharImage(uploadFileData);
    };
    const deleteAadharImageHandler = (e) => {
        setIsLoader(true);
        uploadFileData.applicantId = props.id;
        uploadFileData.aadhaarFileData = aadharFileInBase64;
        uploadFileData.userId = props.userId;
        deleteAadharImage(uploadFileData);
    };
    const downloadAadharImageHandler = (e) => {
        getAadharPhoto(props.id);
    };
    function UploadAadharImage(uploadFileData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(uploadFileData),
        };
        fetch("PreHiringForm/UploadAadharImage/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAfterAadharFileOperation(data);
                } else {
                    setAadharImageFile(null);
                    setIsLoader(true);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    function deleteAadharImage(id) {
        const token = props.token;
        const options = {
            method: "delete",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(uploadFileData),
        };
        fetch("PreHiringForm/DeleteAadharImage/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setAadharImageFile(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    getAadharImage(props.id);
                } else {
                    setAadharImageFile(null);
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    function getAadharImage(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("PreHiringForm/GetAadharImage/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAfterAadharFileOperation(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    async function getAadharPhoto(id) {
        /*window.location.href = "/PreHiringForm/GetAadharData/" + id;*/
        setIsLoader(true);
        const token = props.token;
        axios
            .get("/PreHiringForm/DownloadAadhar/" + id, {
                responseType: "arraybuffer",
                headers: { Authorization: "Bearer " + token },
            })
            .then(function (response) {
                var blob = new Blob([response.data], { type: "application/pdf" });
                FileSaver.saveAs(blob, "Aadhar.pdf");
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    async function getPANPhoto(id) {
        /*window.location.href = "/PreHiringForm/GetPanData/" + id;*/
        setIsLoader(true);
        const token = props.token;
        axios
            .get("/PreHiringForm/DownloadPAN/" + id, {
                responseType: "arraybuffer",
                headers: { Authorization: "Bearer " + token },
            })
            .then(function (response) {
                var blob = new Blob([response.data], { type: "application/pdf" });
                FileSaver.saveAs(blob, "PAN.pdf");
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    function SetStateAfterAadharFileOperation(data) {
        setAadharImageData(data.aadhaarFileData);
        setAadharImageFile(null);
        setIsLoader(false);
    }
    const handleChangePAN = (event) => {
        setPAN(event.target.value);
        if (event.target.value != "") {
            if (IsValidPAN(event.target.value)) {
                setHideTickPAN(false);
                setHideExclaimationPAN(true);
            } else {
                setHideTickPAN(true);
                setHideExclaimationPAN(false);
            }
        } else {
            setHideTickPAN(true);
            setHideExclaimationPAN(true);
        }
    };
    const handleChangeAadhar = (event) => {
        setAadhar(autospaceOnKeyPress(event.target.value));
        if (event.target.value != "") {
            if (IsValidAadhar(event.target.value)) {
                setHideTickAadhar(false);
                setHideExclaimationAadhar(true);
            } else {
                setHideTickAadhar(true);
                setHideExclaimationAadhar(false);
            }
        } else {
            setHideTickAadhar(true);
            setHideExclaimationAadhar(true);
        }
    };
    const handleChangeNumberOfDependants = (event) => {
        setTotalNoOfDependents(event.target.value);
    };
    //present address fields
    const handleChangeCareOFPresent = (event) => {
        setCOPresent(event.target.value);
    };
    const handleChangeFirstLineOfAddressPresent = (event) => {
        setFirstAddressLinePresent(event.target.value);
    };
    const handleChangeSecondLineOfAddressPresent = (event) => {
        setSecondAddressLinePresent(event.target.value);
    };
    const handleChangeThirdLineAddressPresent = (event) => {
        setThirdAddressLinePresent(event.target.value);
    };
    const handleChangeCityPresent = (event) => {
        setCityPresent(event.target.value);
    };
    const handleChangePINCodePresent = (event) => {
        setPinCodePresent(ReplaceChars(event.target.value));
    };
    const handleChangeRegionPresent = (event) => {
        setRegionPresent(event.target.value);
    };
    const handleChangeTelePhonePresent = (event) => {
        setPresentTelephoneNo(event.target.value);
    };
    const handleChangeCountryPhoneCodePresent = (event, newValue) => {
        setCountryPhoneCodePresentObj(newValue);
        setCountryPhoneCodePresent(newValue ? newValue.id : 0);
        if (newValue && newValue.id == 31) {
            setPhoneISOText("IND");
        }
        else {
            setPhoneISOText("");
        }
    };
    const handleChangeCountryPhoneCodePresentInput = (event, newInputValue) => {
        setCountryPhoneCodePresentText(newInputValue);
    };
    const handleChangeAddressUsageType = (event, newValue) => {
        setAddressUsageTypeObj(newValue);
        setAddressUsageType(newValue ? newValue.id : 0);
    };
    const handleChangeAddressUsageTypeInput = (event, newInputValue) => {
        setAddressUsageTypeText(newInputValue);
    };
    const handleChangePhoneUsageType = (event, newValue) => {
        setPhoneUsageTypeObj(newValue);
        setPhoneUsageType(newValue ? newValue.id : 0);
    };
    const handleChangePhoneUsageTypeInput = (event, newInputValue) => {
        setPhoneUsageTypeText(newInputValue);
    };
    const handleChangeMobilePresent = (event) => {
        setMobileNoPresent(event.target.value);
    };
    const handleChangePersonalEmail = (event) => {
        setPersonalEmail(event.target.value);
    };
    const handleChangeAlternateEmail = (event) => {
        setAlternateEmail(event.target.value);
    };
    //permanent address fields
    const handleChangeCareOfPermanent = (event) => {
        setCOPermanent(event.target.value);
    };
    const handleChangeFirstLineOfAddressPermanent = (event) => {
        setFirstAddressLinePermanent(event.target.value);
    };
    const handleChangeSecondLineOfAddressPermanent = (event) => {
        setSecondAddressLinePermanent(event.target.value);
    };
    const handleChangeThirdLineAddressPermanent = (event) => {
        setThirdAddressLinePermanent(event.target.value);
    };
    const handleChangeCityPermanent = (event) => {
        setCityPermanent(event.target.value);
    };
    const handleChangePINCodePermanent = (event) => {
        setPinCodePermanent(ReplaceChars(event.target.value));
    };

    function ReplaceChars(value) {
        return value.replace(/\D/g, "");
    }
    function IsValidPINCode(value) {
        const exp = /^[1-9]{1}[0-9]{5}$/;
        return exp.test(value);
    }
    const handleChangeRegionPermanent = (event) => {
        setRegionPermanent(event.target.value);
    };
    const handleChangeTelePhonePermanent = (event) => {
        setPermanentTelephoneNo(event.target.value);
    };
    const handleChangeAlternateMobilePermanent = (event) => {
        setAlternateMobileNo(event.target.value);
    };
    //Passport Fields
    const handleChangePassportNo = (event) => {
        setPassportNo(event.target.value);
    };
    const handleChangePlaceOfIssuePassport = (event) => {
        setPlaceIssueOfPassport(event.target.value);
    };
    const handleChangePassportIssuingAuthority = (event) => {
        setIssuingAuthorityOfPassport(event.target.value);
    };
    const handleChangePassportENCRStatus = (event) => {
        setENCRStatusOfPassport(event.target.value);
    };
    //Driving License Fields
    const handleChangeDLNo = (event) => {
        setDrivingLicenseNo(event.target.value);
    };
    const handleChangePlaceOfIssueDL = (event) => {
        setPlaceOfIssueDL(event.target.value);
    };
    const handleChangeDLIssuingAuthority = (event) => {
        setIssuingAuthorityOfLicense(event.target.value);
    };
    const handleChangePANAppliedOptions = (event, newValue) => {
        setMustAadharOrPANNote(false);
        setAppliedPANObj(newValue);
        setAppliedPAN(newValue ? newValue.id : 0);
        if ((newValue ? newValue.id : 0) != 1) {
            setDisablePANField(true);
            setRequirePAN(false);
        } else {
            setDisablePANField(false);
            setRequirePAN(true);
        }
    };
    const handleChangePANAppliedOptionsInput = (event, newInputValue) => {
        setAppliedPANText(newInputValue);
    };
    const handleChangeAadharAppliedOptions = (event, newValue) => {
        setMustAadharOrPANNote(false);
        setAppliedAadhar(newValue ? newValue.id : 0);
        setAppliedAadharObj(newValue);
        if ((newValue ? newValue.id : 0) != 1) {
            setDisableAadharField(true);
            setRequireAadhar(false);
        } else {
            setDisableAadharField(false);
            setRequireAadhar(true);
        }
    };
    const handleChangeAadharAppliedOptionsInput = (event, newInputValue) => {
        setAppliedAadharText(newInputValue);
    };
    const handleChangeCountryPresent = (event, newValue) => {
        setCountryPresentObj(newValue);
        setCountryPresent(newValue ? newValue.id : 0);
        id = newValue == undefined ? 0 : newValue.id;
        getStatesPresent(id);
        setStatePresent("");
        setStatePresentObj("");
        setStatePresentText("");
    };
    const handleChangeCountryPresentInput = (event, newInputValue) => {
        setCountryPresentText(newInputValue);
        setStatePresent("");
        setStatePresentObj("");
        setStatePresentText("");
    };
    const handleChangeCountryPermanent = (event, newValue) => {
        setCounrtyPermanentObj(newValue);
        setCounrtyPermanent(newValue ? newValue.id : 0);
        id = newValue == undefined ? 0 : newValue.id;
        getStatesPermanent(id);
        setStatePermanent("");
        setStatePermanentObj("");
        setStatePermanentText("");
    };
    const handleChangeCountryPermanentInput = (event, newInputValue) => {
        setCounrtyPermanentText(newInputValue);
        setStatePermanent("");
        setStatePermanentObj("");
        setStatePermanentText("");
    };
    const handleChangeCountryPassport = (event, newValue) => {
        setCountryOfIssueOfPassportObj(newValue);
        setCountryOfIssueOfPassport(newValue ? newValue.id : 0);
    };
    const handleChangeCountryPassportInput = (event, newInputValue) => {
        setCountryOfIssueOfPassportText(newInputValue);
    };
    const handleChangeCountryDL = (event, newValue) => {
        setCountryOfIssueOfLicenseObj(newValue);
        setCountryOfIssueOfLicense(newValue ? newValue.id : 0);
    };
    const handleChangeCountryDLInput = (event, newInputValue) => {
        setCountryOfIssueOfLicenseText(newInputValue);
    };
    const handleChangeStatePresent = (event, newValue) => {
        setStatePresentObj(newValue);
        setStatePresent(newValue ? newValue.id : 0);
    };
    const handleChangeStatePresentInput = (event, newInputValue) => {
        setStatePresentText(newInputValue);
    };
    const handleChangeStatePermanent = (event, newValue) => {
        setStatePermanentObj(newValue);
        setStatePermanent(newValue ? newValue.id : 0);
    };
    const handleChangeStatePermanentInput = (event, newInputValue) => {
        setStatePermanentText(newInputValue);
    };
    const handleChangeGender = (event, newValue) => {
        setGenderObj(newValue);
        setGender(newValue ? newValue.id : 0);
    };
    const handleChangeGenderInput = (event, newInputValue) => {
        setGenderText(newInputValue);
    };
    const handleChangeLandline = (event, newValue) => {
        setLandlineMobileObj(newValue);
        setLandlineMobile(newValue ? newValue.id : 0);
    };
    const handleChangeLandlineInput = (event, newInputValue) => {
        setLandlineMobileText(newInputValue);
    };

    const handleChangePresentAddressPrimaryOptions = (event, newValue) => {
        setPresentAddressPrimary(newValue ? newValue.id : 0);
        setPresentAddressPrimaryObj(newValue);
        if ((newValue ? newValue.id : 0) == 1) {
            setPermanentAddressPrimary(2);
            setPermanentAddressPrimaryText("No");
            setDisablePermanentAddressPrimary(true);
        } else if ((newValue ? newValue.id : 0) == 2) {
            setPermanentAddressPrimary(1);
            setPermanentAddressPrimaryText("Yes");
            setDisablePermanentAddressPrimary(true);
        } else {
            setPermanentAddressPrimary(0);
            setDisablePermanentAddressPrimary(false);
            setPermanentAddressPrimaryText("");
            setPresentAddressPrimary(0);
            setDisablePresentAddressPrimary(false);
            setPresentAddressPrimaryText("");
        }
    };
    const handleChangePresentAddressPrimaryOptionsInput = (
        event,
        newInputValue
    ) => {
        setPresentAddressPrimaryText(newInputValue);
    };

    const handleChangePermanentAddressPrimaryOptions = (event, newValue) => {
        setPermanentAddressPrimary(newValue ? newValue.id : 0);
        setPermanentAddressPrimaryObj(newValue);
        if ((newValue ? newValue.id : 0) == 1) {
            setPresentAddressPrimary(2);
            setPresentAddressPrimaryText("No");
            setDisablePresentAddressPrimary(true);
        } else if ((newValue ? newValue.id : 0) == 2) {
            setPresentAddressPrimary(1);
            setPresentAddressPrimaryText("Yes");
            setDisablePresentAddressPrimary(true);
        } else {
            setPermanentAddressPrimary(0);
            setDisablePermanentAddressPrimary(false);
            setPermanentAddressPrimaryText("");
            setPresentAddressPrimary(0);
            setDisablePresentAddressPrimary(false);
            setPresentAddressPrimaryText("");
        }
    };
    const handleChangePermanentAddressPrimaryOptionsInput = (
        event,
        newInputValue
    ) => {
        setPermanentAddressPrimaryText(newInputValue);
    };

    function IsValidPAN(value) {
        const exp = /^[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
        return exp.test(value);
    }

    function IsValidPAN(value) {
        const exp = /^[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
        return exp.test(value);
    }

    function IsValidAddressLines(value) {
        const exp = /[\[\]\(\)\\\/#?<>'";:\-{}*&%$@!]/;
        return !exp.test(value);
    }

    function IsValidAadhar(value) {
        const exp = /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/;
        return exp.test(value);
    }

    function autospaceOnKeyPress(value) {
        if (value != "") {
            var val = value.split(" ").join("");
            if (val.length > 0) {
                value = val.match(new RegExp(".{1,4}", "g")).join(" ");
            }
        }
        return value;
    }

    function dateChangeEvent(dateValue, label) {
        if (label === "Date of Issue of Passport") {
            setDateOfIssueOfPassport(dateValue);
        } else if (label === "Passport Valid Till") {
            setPassportValidTill(dateValue);
        } else if (label === "Date of Issue of Driving License") {
            setDateOfIssueOfLicense(dateValue);
        } else if (label === "Driving License Valid Till") {
            setLicenseValidTill(dateValue);
        }
    }
    var formData = {
        CommunicationDetailsId: communicationDetailsId,
        ApplicantId: props.id,
        PanappliedId: appliedPAN,
        Panno: panNo,
        Panimage: panImage,
        AadharAppliedId: appliedAadhar,
        AadharNo: aadharCardNumber,
        PresentCareOf: cOPresent,
        PresentAddress1: firstAddressLinePresent,
        PresentAddress2: secondAddressLinePresent,
        PresentAddress3: thirdAddressLinePresent,
        PresentAddressCountryId: countryPresent,
        PresentAddressStateId: statePresent,
        PresentAddressCity: cityPresent,
        PresentAddressPinCode: pinCodePresent,
        PresentAddressRegion: regionPresent,
        PresentTelephoneNo: presentTelephoneNo,
        PresentCountryPhoneCode: countryPhoneCodePresent,
        PresentCountryPhoneCodeText: phoneISOText,
        PresentMobileNo: mobileNoPresent,
        PermanentCareOf: cOPermanent,
        PremanentAddress1: firstAddressLinePermanent,
        PermanentAddress2: secondAddressLinePermanent,
        PermanentAddress3: thirdAddressLinePermanent,
        PermanentAddressCountryId: countryPermanent,
        PermanentAddressStateId: statePermanent,
        PermanentAddressCity: cityPermanent,
        PermanentAddressPinCode: pinCodePermanent,
        PermanentAddressRegion: regionPermanent,
        PermanentTelephoneNo: permanentTelephoneNo,
        PremanentMobileNo: alternateMobileNo,
        PassportDetailsId: passportDetailsId,
        PassportNumber: passportNo,
        PlaceOfPassportIssued: placeIssueOfPassport,
        DateOfPassportIssued: dateOfIssueOfPassport,
        CountryIdofPassportIssued: countryOfIssueOfPassport,
        PassportValidTill: passportValidTill,
        IssuingAuthorityOfPassport: issuingAuthorityOfPassport,
        EncrstatusOfPassport: encrStatusOfPassport,
        DrivingLicenseDetailsId: dlDetailsId,
        Dlnumber: drivingLicenseNo,
        PlaceOfDlissued: placeOfIssueDL,
        DateOfDlissued: dateOfIssueOfLicense,
        CountryIdofDlissued: countryOfIssueOfLicense,
        DlvalidTill: licenseValidTill,
        IssuingAuthorityOfDl: issuingAuthorityOfLicense,
        EmergencyContactDetailsId: emergencyContactDetailsId,
        EcfirstName: firstName,
        EclastName: lastName,
        EcgenderId: gender,
        EcnumberTypeId: landlineMobile,
        Ecnumber: emergencyContactNo,
        PersonalEmailId: personalEmail,
        AlternateEmailId: alternateEmail,
        TotalNoOfDependants: null,
        PresentAddressPrimaryId: presentAddressPrimary,
        PermanentAddressPrimaryId: permanentAddressPrimary,
        AddressUsageTypeId: addressUsageType,
        PrimaryPhoneUsageTypeId: phoneUsageType,
        UserId: props.userId,
    };

    const submitHandler = (event) => {
        setIsLoader(true);
        //if (countryPresent == '' || countryPresent == null || statePresent == '' || statePresent == null
        //    || countryPermanent == '' || countryPermanent == null || statePermanent == '' || statePermanent == null) {
        //    setOpen(true);
        //    if (countryPresent == '' || statePresent == '') {
        //        event.preventDefault();
        //        setOpenAlert("Country or State in Present Address needs to be selected");
        //    }
        //    else if (countryPermanent == '' || statePermanent == '') {
        //        event.preventDefault();
        //        setOpenAlert("Country or State in Permanent Address needs to be selected");
        //    }

        //    setClassName("clr-dark-blue");
        //    setOpenType("Alert");
        //}
        if (
            emergencyContactNo != null &&
            emergencyContactNo != "" &&
            emergencyContactNo != undefined &&
            mobileNoPresent != null &&
            mobileNoPresent != "" &&
            mobileNoPresent != undefined &&
            emergencyContactNo == mobileNoPresent
        ) {
            setIsLoader(false);
            setOpen(true);
            event.preventDefault();
            setOpenAlert(
                "Emergency Contact No and  Primary Contact No cannot be same"
            );
            setClassName("clr-dark-blue bg-alert");
            setOpenType("Alert");
        } else {
            if (appliedAadhar != 1 && appliedPAN != 1) {
                setMustAadharOrPANNote(true);
                setIsLoader(false);
                setOpen(true);
                setOpenAlert("Candidate must have either Aadhar or PAN");
                setClassName("clr-white bg-alert");
                setOpenType("Alert");
            } else {
                formData.CommunicationDetailsId = communicationDetailsId;
                formData.AadharAppliedId = appliedAadhar;
                formData.AadharNo = aadharCardNumber;
                formData.AlternateEmailId = alternateEmail;
                formData.ApplicantId = props.id;
                formData.PanappliedId = appliedPAN;
                formData.Panno = panNo;
                formData.Panimage = panImage;
                formData.PremanentAddress1 = firstAddressLinePresent;
                formData.PresentAddress2 = secondAddressLinePresent;
                formData.PresentAddress3 = thirdAddressLinePresent;
                formData.PresentAddressCity = cityPresent;
                formData.PresentAddressCountryId = countryPresent;
                formData.PresentAddressStateId = statePresent;
                formData.PresentCareOf = cOPresent;
                formData.PresentAddressRegion = regionPresent;
                formData.PresentAddressPinCode = pinCodePresent;
                formData.PresentTelephoneNo = presentTelephoneNo;
                formData.PresentCountryPhoneCode = countryPhoneCodePresent;
                formData.PresentCountryPhoneCodeText = phoneISOText;
                formData.PresentMobileNo = mobileNoPresent;
                formData.PermanentCareOf = cOPermanent;
                formData.PremanentAddress1 = firstAddressLinePermanent;
                formData.PermanentAddress2 = secondAddressLinePermanent;
                formData.PermanentAddress3 = thirdAddressLinePermanent;
                formData.PermanentAddressCity = cityPermanent;
                formData.PermanentAddressCountryId = countryPermanent;
                formData.PermanentAddressStateId = statePermanent;
                formData.PermanentAddressPinCode = pinCodePermanent;
                formData.PermanentAddressRegion = regionPermanent;
                formData.PermanentTelephoneNo = permanentTelephoneNo;
                formData.PremanentMobileNo = alternateMobileNo;
                formData.PassportDetailsId = passportDetailsId;
                formData.PassportNumber = passportNo;
                formData.PassportValidTill = passportValidTill;
                formData.DateOfPassportIssued = dateOfIssueOfPassport;
                formData.CountryIdofPassportIssued = countryOfIssueOfPassport;
                formData.PlaceOfPassportIssued = placeIssueOfPassport;
                formData.IssuingAuthorityOfPassport = issuingAuthorityOfPassport;
                formData.EncrstatusOfPassport = encrStatusOfPassport;
                formData.DrivingLicenseDetailsId = dlDetailsId;
                formData.Dlnumber = drivingLicenseNo;
                formData.DlvalidTill = licenseValidTill;
                formData.PlaceOfDlissued = placeOfIssueDL;
                formData.DateOfDlissued = dateOfIssueOfLicense;
                formData.CountryIdofDlissued = countryOfIssueOfLicense;
                formData.IssuingAuthorityOfDl = issuingAuthorityOfLicense;
                formData.EmergencyContactDetailsId = emergencyContactDetailsId;
                formData.EcfirstName = firstName;
                formData.EcgenderId = gender;
                formData.EclastName = lastName;
                formData.Ecnumber = emergencyContactNo;
                formData.EcnumberTypeId = landlineMobile;
                formData.PersonalEmailId = personalEmail;
                formData.AlternateEmailId = alternateEmail;
                formData.TotalNoOfDependants = null;
                formData.PresentAddressPrimaryId = presentAddressPrimary;
                formData.PermanentAddressPrimaryId = permanentAddressPrimary;
                formData.AddressUsageTypeId = addressUsageType;
                formData.PrimaryPhoneUsageTypeId = phoneUsageType;
                formData.UserId = props.userId;
                formData.UserIdencrypted = props.loginDetail[0].userEncryptedId;

                if (ValidateFormFields(formData)) {
                    setHideExclaimationAadhar(true);
                    setHideExclaimationPAN(true);
                    setHideTickAadhar(true);
                    setHideTickPAN(true);
                    if (communicationDetailsId && communicationDetailsId > 0) {
                        UpdateCommunicationData(formData);
                    } else {
                        SaveCommunicationData(formData);
                    }
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(
                        "Form validation unsuccessful. Please check the error messages."
                    );
                    setClassName("clr-white bg-alert");
                    setOpenType("Alert");
                }
            }
        }
    };

    //Validation
    function ValidateFormFields(data) {
        var a = false,
            b = false,
            c = false,
            f = false,
            g = false,
            h = false,
            i = false,
            j = false,
            k = false,
            l = false,
            m = false,
            n = false,
            o = false,
            p = false,
            q = false,
            r = false,
            s = false,
            t = false,
            u = false,
            v = false,
            w = false,
            x = false,
            y = false,
            z = false,
            za = false,
            zb = false,
            zc = false,
            zd = false,
            ze = false,
            zf = false,
            zg = false,
            zh = false,
            zi = false,
            zj = false,
            zk = false;

        if (data.PanappliedId == null || data.PanappliedId == "") {
            setErrorPANExist(true);
            setErrorTextPANExist("Mandatory field");
            a = true;
        } else {
            setErrorPANExist(false);
            setErrorTextPANExist("");
            a = false;
        }
        if (data.PanappliedId == 1 && (data.Panno == null || data.Panno == "")) {
            setErrorPANno(true);
            setErrorTextPANno("Mandatory field");
            b = true;
        } else {
            if (data.PanappliedId == 1 && data.Panno != null && data.Panno != "") {
                if (!IsValidPAN(data.Panno)) {
                    setErrorPANno(true);
                    setErrorTextPANno("Invalid PAN Number");
                    b = true;
                } else {
                    setErrorPANno(false);
                    setErrorTextPANno("");
                    b = false;
                }
            } else {
                setErrorPANno(false);
                setErrorTextPANno("");
                b = false;
            }
        }
        if (data.AadharAppliedId == null || data.AadharAppliedId == "") {
            setErrorAadharExist(true);
            setErrorTextAadharExist("Mandatory field");
            c = true;
        } else {
            setErrorAadharExist(false);
            setErrorTextAadharExist("");
            c = false;
        }
        //if (data.AadharAppliedId == 1 && (data.AadharNo == null || data.AadharNo == '')) {
        //    setErrorAadharNo(true);
        //    setErrorTextAadharNo("Mandatory field");
        //    d = true;
        //}
        //else {
        //    setErrorAadharNo(false);
        //    setErrorTextAadharNo("");
        //    d = false;
        //}
        //if (data.AadharAppliedId == null || data.AadharAppliedId == '') {
        //    setErrorAadharExist(true);
        //    setErrorTextAadharExist("Mandatory field");
        //    e = true;
        //}
        //else {
        //    setErrorAadharExist(false);
        //    setErrorTextAadharExist("");
        //    e = false;
        //}
        if (
            data.AadharAppliedId == 1 &&
            (data.AadharNo == null || data.AadharNo == "")
        ) {
            setErrorAadharNo(true);
            setErrorTextAadharNo("Mandatory field");
            f = true;
        } else {
            if (
                data.AadharAppliedId == 1 &&
                data.AadharNo != null &&
                data.AadharNo != ""
            ) {
                if (!IsValidAadhar(data.AadharNo)) {
                    setErrorAadharNo(true);
                    setErrorTextAadharNo("Invalid Aadhar Number");
                    f = true;
                } else {
                    setErrorAadharNo(false);
                    setErrorTextAadharNo("");
                    f = false;
                }
            } else {
                setErrorAadharNo(false);
                setErrorTextAadharNo("");
                f = false;
            }
        }
        if (data.PresentAddress1 == null || data.PresentAddress1 == "") {
            setErrorFirstAddressLinePresent(true);
            setErrorTextFirstAddressLinePresent("Mandatory field");
            g = true;
        } else {
            if (!IsValidAddressLines(data.PresentAddress1)) {
                setErrorFirstAddressLinePresent(true);
                var str = "#/?><'" + '";:-}{][*&%$@!()';
                setErrorTextFirstAddressLinePresent(
                    str.toString() + " characters are not allowed"
                );
                g = true;
            } else {
                setErrorFirstAddressLinePresent(false);
                setErrorTextFirstAddressLinePresent("");
                g = false;
            }
        }
        if (data.PresentAddress2 == null || data.PresentAddress2 == "") {
            setErrorSecondAddressLinePresent(true);
            setErrorTextSecondAddressLinePresent("Mandatory field");
            h = true;
        } else {
            if (!IsValidAddressLines(data.PresentAddress2)) {
                setErrorSecondAddressLinePresent(true);
                var str = "#/?><'" + '";:-}{][*&%$@!()';
                setErrorTextSecondAddressLinePresent(
                    str.toString() + " characters are not allowed"
                );
                h = true;
            } else {
                setErrorSecondAddressLinePresent(false);
                setErrorTextSecondAddressLinePresent("");
                h = false;
            }
        }
        if (
            data.PresentAddressCountryId == null ||
            data.PresentAddressCountryId == ""
        ) {
            setErrorCountryPresent(true);
            setErrorTextCountryPresent("Mandatory field");
            i = true;
        } else {
            setErrorCountryPresent(false);
            setErrorTextCountryPresent("");
            i = false;
        }
        if (
            data.PresentAddressCountryId != null &&
            data.PresentAddressCountryId != ""
        ) {
            if (data.PresentAddressCountryId == 94) {
                if (
                    data.PresentAddressStateId == null ||
                    data.PresentAddressStateId == ""
                ) {
                    setErrorStatePresent(true);
                    setErrorTextStatePresent("Mandatory field");
                    j = true;
                } else {
                    setErrorStatePresent(false);
                    setErrorTextStatePresent("");
                    j = false;
                }
            } else {
                setErrorStatePresent(false);
                setErrorTextStatePresent("");
                j = false;
            }
        } else {
            setErrorStatePresent(false);
            setErrorTextStatePresent("");
            j = false;
        }
        //if (data.PresentAddressStateId == null || data.PresentAddressStateId == '') {
        //    setErrorStatePresent(true);
        //    setErrorTextStatePresent("Mandatory field");
        //    j = true;
        //}
        //else {
        //    setErrorStatePresent(false);
        //    setErrorTextStatePresent("");
        //    j = false;
        //}
        if (data.PresentAddressCity == null || data.PresentAddressCity == "") {
            setErrorCityPresent(true);
            setErrorTextCityPresent("Mandatory field");
            k = true;
        } else {
            setErrorCityPresent(false);
            setErrorTextCityPresent("");
            k = false;
        }
        if (
            data.PresentAddressPinCode == null ||
            data.PresentAddressPinCode == ""
        ) {
            setErrorPinCodePresent(true);
            setErrorTextPinCodePresent("Mandatory field");
            l = true;
        } else {
            if (!IsValidPINCode(data.PresentAddressPinCode)) {
                setErrorPinCodePresent(true);
                setErrorTextPinCodePresent("Invalid PINCode");
                l = true;
            } else {
                setErrorPinCodePresent(false);
                setErrorTextPinCodePresent("");
                l = false;
            }
        }
        if (data.PersonalEmailId == null || data.PersonalEmailId == "") {
            setErrorPersonalEmail(true);
            setErrorTextPersonalEmail("Mandatory field");
            m = true;
        } else if (
            data.PersonalEmailId != null &&
            data.PersonalEmailId != "" &&
            data.PersonalEmailId != undefined &&
            !validator.isEmail(data.PersonalEmailId)
        ) {
            setErrorPersonalEmail(true);
            setErrorTextPersonalEmail("Please enter a valid Email Id");
            m = true;
        } else {
            setErrorPersonalEmail(false);
            setErrorTextPersonalEmail("");
            m = false;
        }
        if (
            data.PresentCountryPhoneCode == null ||
            data.PresentCountryPhoneCode == ""
        ) {
            setErrorCountryPhoneCode(true);
            setErrorTextCountryPhoneCode("Mandatory field");
            n = true;
        } else {
            setErrorCountryPhoneCode(false);
            setErrorTextCountryPhoneCode("");
            n = false;
        }
        if (
            data.PresentCountryPhoneCodeText == null ||
            data.PresentCountryPhoneCodeText == ""
        ) {
            setErrorPhoneISOText(true);
            setErrorTextPhoneISOText("Mandatory field");
            zk = true;
        } else {
            setErrorPhoneISOText(false);
            setErrorTextPhoneISOText("");
            zk = false;
        }
        if (data.PresentMobileNo == null || data.PresentMobileNo == "") {
            setErrorMobileNumber(true);
            setErrorTextMobileNumber("Mandatory field");
            o = true;
        } else if (
            data.PresentMobileNo != "" &&
            data.PresentMobileNo != null &&
            data.PresentMobileNo != undefined &&
            (!validator.isMobilePhone(data.PresentMobileNo) ||
                data.PresentMobileNo.length != 10)
        ) {
            setErrorMobileNumber(true);
            setErrorTextMobileNumber("Please enter a valid 10-digit Mobile No");
            o = true;
        } else {
            setErrorMobileNumber(false);
            setErrorTextMobileNumber("");
            o = false;
        }
        if (data.PremanentAddress1 == null || data.PremanentAddress1 == "") {
            setErrorFirstAddressLinePermanent(true);
            setErrorTextFirstAddressLinePermanent("Mandatory field");
            p = true;
        } else {
            if (!IsValidAddressLines(data.PremanentAddress1)) {
                setErrorFirstAddressLinePermanent(true);
                var str = "#/?><'" + '";:-}{][*&%$@!()';
                setErrorTextFirstAddressLinePermanent(
                    str.toString() + " characters are not allowed"
                );
                p = true;
            } else {
                setErrorFirstAddressLinePermanent(false);
                setErrorTextFirstAddressLinePermanent("");
                p = false;
            }
        }
        if (data.PermanentAddress2 == null || data.PermanentAddress2 == "") {
            setErrorSecondAddressLinePermanent(true);
            setErrorTextSecondAddressLinePermanent("Mandatory field");
            q = true;
        } else {
            if (!IsValidAddressLines(data.PermanentAddress2)) {
                setErrorSecondAddressLinePermanent(true);
                var str = "#/?><'" + '";:-}{][*&%$@!()';
                setErrorTextSecondAddressLinePermanent(
                    str.toString() + " characters are not allowed"
                );
                q = true;
            } else {
                setErrorSecondAddressLinePermanent(false);
                setErrorTextSecondAddressLinePermanent("");
                q = false;
            }
        }
        if (
            data.PermanentAddressCountryId == null ||
            data.PermanentAddressCountryId == ""
        ) {
            setErrorCountryPermanent(true);
            setErrorTextCountryPermanent("Mandatory field");
            r = true;
        } else {
            setErrorCountryPermanent(false);
            setErrorTextCountryPermanent("");
            r = false;
        }
        if (
            data.PermanentAddressCountryId != null &&
            data.PermanentAddressCountryId != ""
        ) {
            if (data.PermanentAddressCountryId == 94) {
                if (
                    data.PermanentAddressStateId == null ||
                    data.PermanentAddressStateId == ""
                ) {
                    setErrorStatePermanent(true);
                    setErrorTextStatePermanent("Mandatory field");
                    s = true;
                } else {
                    setErrorStatePermanent(false);
                    setErrorTextStatePermanent("");
                    s = false;
                }
            } else {
                setErrorStatePresent(false);
                setErrorTextStatePresent("");
                s = false;
            }
        } else {
            setErrorStatePermanent(false);
            setErrorTextStatePermanent("");
            s = false;
        }
        if (data.PermanentAddressCity == null || data.PermanentAddressCity == "") {
            setErrorCityPermanent(true);
            setErrorTextCityPermanent("Mandatory field");
            t = true;
        } else {
            setErrorCityPermanent(false);
            setErrorTextCityPermanent("");
            t = false;
        }
        if (
            data.PermanentAddressPinCode == null ||
            data.PermanentAddressPinCode == ""
        ) {
            setErrorPinCodePermanent(true);
            setErrorTextPinCodePermanent("Mandatory field");
            u = true;
        } else {
            if (!IsValidPINCode(data.PermanentAddressPinCode)) {
                setErrorPinCodePermanent(true);
                setErrorTextPinCodePermanent("Invalid PINCode");
                u = true;
            } else {
                setErrorPinCodePermanent(false);
                setErrorTextPinCodePermanent("");
                u = false;
            }
        }
        //if (data.CountryIdofPassportIssued == null || data.CountryIdofPassportIssued == '') {
        //    setErrorCountryOfIssueOfPassport(true);
        //    setErrorTextCountryOfIssueOfPassport("Mandatory field");
        //    v = true;
        //}
        //else {
        //    setErrorCountryOfIssueOfPassport(false);
        //    setErrorTextCountryOfIssueOfPassport("");
        //    v = false;
        //}
        //if (data.CountryIdofDlissued == null || data.CountryIdofDlissued == '') {
        //    setErrorCountryOfIssueOfLicense(true);
        //    setErrorTextCountryOfIssueOfLicense("Mandatory field");
        //    w = true;
        //}
        //else {
        //    setErrorCountryOfIssueOfLicense(false);
        //    setErrorTextCountryOfIssueOfLicense("");
        //    w = false;
        //}
        if (data.EcfirstName == null || data.EcfirstName == "") {
            setErrorFirstName(true);
            setErrorTextFirstName("Mandatory field");
            x = true;
        } else {
            setErrorFirstName(false);
            setErrorTextFirstName("");
            x = false;
        }
        if (data.EclastName == null || data.EclastName == "") {
            setErrorLastName(true);
            setErrorTextLastName("Mandatory field");
            y = true;
        } else {
            setErrorLastName(false);
            setErrorTextLastName("");
            y = false;
        }
        if (data.EcgenderId == null || data.EcgenderId == "") {
            setErrorGender(true);
            setErrorTextGender("Mandatory field");
            z = true;
        } else {
            setErrorGender(false);
            setErrorTextGender("");
            z = false;
        }
        if (data.EcnumberTypeId == null || data.EcnumberTypeId == "") {
            setErrorMobile(true);
            setErrorTextMobile("Mandatory field");
            za = true;
        } else {
            setErrorMobile(false);
            setErrorTextMobile("");
            za = false;
        }
        if (data.Ecnumber == null || data.Ecnumber == "") {
            setErrorEmergencyContactNo(true);
            setErrorTextEmergencyContactNo("Mandatory field");
            zb = true;
        } else if (
            data.EcnumberTypeId == 2 &&
            data.Ecnumber != "" &&
            data.Ecnumber != null &&
            data.Ecnumber != undefined &&
            (!validator.isMobilePhone(data.Ecnumber) || data.Ecnumber.length != 10)
        ) {
            setErrorEmergencyContactNo(true);
            setErrorTextEmergencyContactNo("Please enter a valid 10-digit Mobile No");
            zb = true;
        } else {
            setErrorEmergencyContactNo(false);
            setErrorTextEmergencyContactNo("");
            zb = false;
        }
        if (
            data.AlternateEmailId != null &&
            data.AlternateEmailId != "" &&
            data.AlternateEmailId != undefined &&
            !validator.isEmail(data.AlternateEmailId)
        ) {
            setErrorAlternateEmail(true);
            setErrorTextAlternateEmail("Please enter a valid Email Id");
            zc = true;
        } else {
            setErrorAlternateEmail(false);
            setErrorTextAlternateEmail("");
            zc = false;
        }
        if (
            data.PremanentMobileNo != "" &&
            data.PremanentMobileNo != null &&
            data.PremanentMobileNo != undefined &&
            (!validator.isMobilePhone(data.PremanentMobileNo) ||
                data.PremanentMobileNo.length != 10)
        ) {
            setErrorAlternateMobileNo(true);
            setErrorTextAlternateMobileNo("Please enter a valid 10-digit Mobile No");
            zd = true;
        } else {
            setErrorAlternateMobileNo(false);
            setErrorTextAlternateMobileNo("");
            zd = false;
        }
        if (!IsValidAddressLines(data.PresentAddress3)) {
            setErrorThirdAddressLinePresent(true);
            var str = "#/?><'" + '";:-}{][*&%$@!()';
            setErrorTextThirdAddressLinePresent(
                str.toString() + " characters are not allowed"
            );
            ze = true;
        } else {
            setErrorThirdAddressLinePresent(false);
            setErrorTextThirdAddressLinePresent("");
            ze = false;
        }
        if (!IsValidAddressLines(data.PermanentAddress3)) {
            setErrorThirdAddressLinePermanent(true);
            var str = "#/?><'" + '";:-}{][*&%$@!()';
            setErrorTextThirdAddressLinePermanent(
                str.toString() + " characters are not allowed"
            );
            zf = true;
        } else {
            setErrorThirdAddressLinePermanent(false);
            setErrorTextThirdAddressLinePermanent("");
            zf = false;
        }
        if (
            data.PresentAddressPrimaryId == null ||
            data.PresentAddressPrimaryId == ""
        ) {
            setErrorPresentAddressPrimary(true);
            setErrorTextPresentAddressPrimary("Mandatory field");
            zg = true;
        } else {
            setErrorPresentAddressPrimary(false);
            setErrorTextPresentAddressPrimary("");
            zg = false;
        }

        if (
            data.PermanentAddressPrimaryId == null ||
            data.PermanentAddressPrimaryId == ""
        ) {
            setErrorPermanentAddressPrimary(true);
            setErrorTextPermanentAddressPrimary("Mandatory field");
            zh = true;
        } else {
            setErrorPermanentAddressPrimary(false);
            setErrorTextPermanentAddressPrimary("");
            zh = false;
        }
        if (data.AddressUsageTypeId == null || data.AddressUsageTypeId == "") {
            setErrorAddressUsageType(true);
            setErrorTextAddressUsageType("Mandatory field");
            zi = true;
        } else {
            setErrorAddressUsageType(false);
            setErrorTextAddressUsageType("");
            zi = false;
        }
        if (
            data.PrimaryPhoneUsageTypeId == null ||
            data.PrimaryPhoneUsageTypeId == ""
        ) {
            setErrorPhoneUsageType(true);
            setErrorTextPhoneUsageType("Mandatory field");
            zj = true;
        } else {
            setErrorPhoneUsageType(false);
            setErrorTextPhoneUsageType("");
            zj = false;
        }
        if (
            a ||
            b ||
            c ||
            f ||
            g ||
            h ||
            i ||
            j ||
            k ||
            l ||
            m ||
            n ||
            o ||
            p ||
            q ||
            r ||
            s ||
            t ||
            u ||
            v ||
            w ||
            x ||
            y ||
            z ||
            za ||
            zb ||
            zc ||
            zd ||
            ze ||
            zf ||
            zg ||
            zh ||
            zi ||
            zj ||
            zk
        ) {
            return false;
        } else {
            return true;
        }
    }
    const clearHandler = () => {
        setOpenConfirm(true);
        setOpenAlertConfirm("Do you want to clear all the above fields?");
        setClassName("clr-dark-blue bg-alert");
        setOpenType("Confirmation");
    };
    const copyAddressHandler = (event) => {
        setIsLoader(true);
        if (
            cOPresent == "" &&
            firstAddressLinePresent == "" &&
            secondAddressLinePresent == "" &&
            thirdAddressLinePresent == "" &&
            countryPresent == "" &&
            statePresent == "" &&
            cityPresent == "" &&
            pinCodePresent == "" &&
            regionPresent == "" &&
            presentTelephoneNo == "" &&
            mobileNoPresent == ""
        ) {
            event.preventDefault();
            setIsLoader(false);
            setOpen(true);
            setOpenAlert("Nothing To Be Copied to Permanent Address");
            setClassName("clr-dark-blue bg-alert");
            setOpenType("Alert");
        } else {
            //setCOPermanent(cOPresent);
            setFirstAddressLinePermanent(firstAddressLinePresent);
            setSecondAddressLinePermanent(secondAddressLinePresent);
            setThirdAddressLinePermanent(thirdAddressLinePresent);
            setCounrtyPermanent(countryPresent);
            setCounrtyPermanentObj(countryPresentObj);
            setCounrtyPermanentText(countryPresentText);
            setStatePermanent(statePresent);
            setStatePermanentObj(statePresentObj);
            setStatePermanentText(statePresentText);
            setCityPermanent(cityPresent);
            setPinCodePermanent(pinCodePresent);
            setRegionPermanent(regionPresent);
            //setPermanentTelephoneNo(presentTelephoneNo);
            //setAlternateMobileNo(mobileNoPresent);
            setIsLoader(false);
            setOpen(true);
            setOpenAlert("Present Address Copied to Permanent Address Successfully");
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
        }
    };

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: "100%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
    }));
    const classes = useStyles();

    function clearStates() {
        setAppliedPAN(1);
        setAppliedPANObj("");
        setAppliedPANText("");
        setPAN("");
        setAppliedAadhar(1);
        setAppliedAadharObj("");
        setAppliedAadharText("");
        setAadhar("");
        setTotalNoOfDependents("");
        setCOPresent("");
        setFirstAddressLinePresent("");
        setSecondAddressLinePresent("");
        setThirdAddressLinePresent("");
        setCountryPresent("");
        setCountryPresentObj("");
        setCountryPresentText("");
        setStatePresent("");
        setStatePresentObj("");
        setStatePresentText("");
        setCityPresent("");
        setPinCodePresent("");
        setRegionPresent("");
        setPersonalEmail("");
        setCountryPhoneCodePresent("");
        setCountryPhoneCodePresentObj("");
        setCountryPhoneCodePresentText("");
        setMobileNoPresent("");
        setPresentTelephoneNo("");
        setAlternateEmail("");
        setCOPermanent("");
        setFirstAddressLinePermanent("");
        setSecondAddressLinePermanent("");
        setThirdAddressLinePermanent("");
        setCounrtyPermanent("");
        setCounrtyPermanentObj("");
        setCounrtyPermanentText("");
        setStatePermanent("");
        setStatePermanentObj("");
        setStatePermanentText("");
        setCityPermanent("");
        setPinCodePermanent("");
        setRegionPermanent("");
        setPermanentTelephoneNo("");
        setAlternateMobileNo("");
        setPassportNo("");
        setPlaceIssueOfPassport("");
        setDateOfIssueOfPassport(null);
        setCountryOfIssueOfPassport("");
        setCountryOfIssueOfPassportObj("");
        setCountryOfIssueOfPassportText("");
        setPassportValidTill(null);
        setIssuingAuthorityOfPassport("");
        setENCRStatusOfPassport("");
        setDrivingLicenseNo("");
        setPlaceOfIssueDL("");
        setDateOfIssueOfLicense(null);
        setLicenseValidTill(null);
        setCountryOfIssueOfLicense("");
        setCountryOfIssueOfLicenseObj("");
        setCountryOfIssueOfLicenseText("");
        setIssuingAuthorityOfLicense("");
        setFirstName("");
        setLastName("");
        setGender("");
        setGenderObj("");
        setGenderText("");
        setLandlineMobile("");
        setLandlineMobileObj("");
        setLandlineMobileText("");
        setEmergencyContactNo("");
        setPresentAddressPrimary(0);
        setPresentAddressPrimaryObj("");
        setPresentAddressPrimaryText("");
        setPermanentAddressPrimary(0);
        setPermanentAddressPrimaryObj("");
        setPermanentAddressPrimaryText("");
        setDisablePermanentAddressPrimary(false);
        setDisablePresentAddressPrimary(false);
    }

    //fetch Address Usage Type
    const [itemsAddressUsageType, setItemsAddressUsageType] = useState([]);
    async function getAddressUsageType() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllAddressUsageTypes", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsGender(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsAddressUsageType(jsonItems);
    }
    //fetch Genders
    const [itemsGender, setItemsGender] = useState([]);
    async function getGenders() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllGenders", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsGender(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsGender(jsonItems);
    }
    //fetch Countries
    const [itemCountries, setItemCountries] = useState([]);
    async function getCountries() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllCountries", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemCountries(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemCountries(jsonItems);
    }
    //fetch States Present
    const [itemStatesPresent, setItemStatesPresent] = useState([]);
    async function getStatesPresent(id) {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllStates/" + id, {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemStatesPresent(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemStatesPresent(jsonItems);
    }
    //fetch States Permanent
    const [itemStatesPermanent, setItemStatesPermanent] = useState([]);
    async function getStatesPermanent(id) {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllStates/" + id, {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemStatesPermanent(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemStatesPermanent(jsonItems);
    }
    //fetch HasApplied Options
    const [itemContactNumberTypes, setItemContactNumberTypes] = useState([]);
    async function getContactNumberTypes() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetContactNumberTypes", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemContactNumberTypes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemContactNumberTypes(jsonItems);
    }
    //fetch HasApplied Options
    const [itemsHasApplied, setItemsHasApplied] = useState([]);
    async function getHasAppliedOptions() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetHasAppliedOptions", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsHasApplied(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsHasApplied(jsonItems);
    }

    //fetch Country Code
    const [itemCountryPhoneCode, setItemCountryPhoneCode] = useState([]);
    async function getCountryPhoneCode() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetCountryPhoneCode", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemCountryPhoneCode(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemCountryPhoneCode(jsonItems);
    }

    //fetch PrimaryYesNo Options
    const [itemsIsPrimary, setItemsIsPrimary] = useState([]);
    async function getIsPrimaryOptions() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetYesOrNo", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsHasApplied(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsIsPrimary(jsonItems);
    }

    //Change Button Text
    function getButtonTextSave(communicationDetailsId) {
        communicationDetailsId != null && communicationDetailsId > 0
            ? setButtonTextSave("Update")
            : setButtonTextSave(buttonTextSave);
    }



    //Set All States
    function SetStateAsPerFetched(data) {
        data.aadharAppliedId != null && data.aadharAppliedId > 0
            ? setAppliedAadhar(data.aadharAppliedId)
            : setAppliedAadhar(1);
        if (data.aadharAppliedOption[0].id > 0) {
            setAppliedAadharObj(data.aadharAppliedOption[0]);
            setAppliedAadharText(data.aadharAppliedOption[0].name);
        }
        if (data.aadharAppliedId != null && data.aadharAppliedId == 1) {
            setDisableAadharField(false);
            setRequireAadhar(true);
        } else {
            setDisableAadharField(true);
            setRequireAadhar(false);
        }
        setAadhar(data.aadharNo);
        setAlternateEmail(data.alternateEmailId);
        setCommunicationDetailsId(data.communicationDetailsId);
        setCountryOfIssueOfLicense(data.countryIdofDlissued);
        if (data.dlCountry[0].id > 0) {
            setCountryOfIssueOfLicenseObj(data.dlCountry[0]);
            setCountryOfIssueOfLicenseText(data.dlCountry[0].name);
        }
        setCountryOfIssueOfPassport(data.countryIdofPassportIssued);
        if (data.passportCountry[0].id > 0) {
            setCountryOfIssueOfPassportObj(data.passportCountry[0]);
            setCountryOfIssueOfPassportText(data.passportCountry[0].name);
        }
        setDateOfIssueOfLicense(data.dateOfDlissued);
        setDateOfIssueOfPassport(data.dateOfPassportIssued);
        setDrivingLicenseNo(data.dlnumber);
        setLicenseValidTill(data.dlvalidTill);
        setDLDetailsId(data.drivingLicenseDetailsId);
        setFirstName(data.ecfirstName);
        setGender(data.ecgenderId);
        if (data.ecGender[0].id > 0) {
            setGenderObj(data.ecGender[0]);
            setGenderText(data.ecGender[0].name);
        }
        setLastName(data.eclastName);
        setEmergencyContactNo(data.ecnumber);
        setLandlineMobile(data.ecnumberTypeId);
        if (data.ecNumberType[0].id > 0) {
            setLandlineMobileObj(data.ecNumberType[0]);
            setLandlineMobileText(data.ecNumberType[0].name);
        }
        setEmergencyContactDetailsId(data.emergencyContactDetailsId);
        setENCRStatusOfPassport(data.encrstatusOfPassport);
        setIssuingAuthorityOfLicense(data.issuingAuthorityOfDl);
        setIssuingAuthorityOfPassport(data.issuingAuthorityOfPassport);
        data.panappliedId != null && data.panappliedId > 0
            ? setAppliedPAN(data.panappliedId)
            : setAppliedPAN(1);
        if (data.panAppliedOption[0].id > 0) {
            setAppliedPANObj(data.panAppliedOption[0]);
            setAppliedPANText(data.panAppliedOption[0].name);
        }
        if (data.panappliedId != null && data.panappliedId == 1) {
            setDisablePANField(false);
            setRequirePAN(true);
        } else {
            setDisablePANField(true);
            setRequirePAN(false);
        }
        setPANImage(data.panimage);
        setPAN(data.panno);
        setPassportDetailsId(data.passportDetailsId);
        setPassportNo(data.passportNumber);
        setPassportValidTill(data.passportValidTill);
        setSecondAddressLinePermanent(data.permanentAddress2);
        setThirdAddressLinePermanent(data.permanentAddress3);
        setCityPermanent(data.permanentAddressCity);
        setCounrtyPermanent(data.permanentAddressCountryId);
        if (data.permanentCountry[0].id > 0) {
            setCounrtyPermanentObj(data.permanentCountry[0]);
            setCounrtyPermanentText(data.permanentCountry[0].name);
        }
        getStatesPermanent(data.permanentAddressCountryId);
        setPinCodePermanent(data.permanentAddressPinCode);
        setRegionPermanent(data.permanentAddressRegion);
        setStatePermanent(data.permanentAddressStateId);
        if (data.permanentState[0].id > 0) {
            setStatePermanentObj(data.permanentState[0]);
            setStatePermanentText(data.permanentState[0].name);
        }
        setCOPermanent(data.permanentCareOf);
        setPermanentTelephoneNo(data.permanentTelephoneNo);
        setPersonalEmail(data.personalEmailId);
        setPlaceOfIssueDL(data.placeOfDlissued);
        setPlaceIssueOfPassport(data.placeOfPassportIssued);
        setFirstAddressLinePermanent(data.premanentAddress1);
        setAlternateMobileNo(data.premanentMobileNo);
        setFirstAddressLinePresent(data.presentAddress1);
        setSecondAddressLinePresent(data.presentAddress2);
        setThirdAddressLinePresent(data.presentAddress3);
        setCityPresent(data.presentAddressCity);
        setCountryPresent(data.presentAddressCountryId);
        if (data.presentCountry[0].id > 0) {
            setCountryPresentObj(data.presentCountry[0]);
            setCountryPresentText(data.presentCountry[0].name);
        }
        getStatesPresent(data.presentAddressCountryId);
        setPinCodePresent(data.presentAddressPinCode);
        setRegionPresent(data.presentAddressRegion);
        setStatePresent(data.presentAddressStateId);
        if (data.presentState[0].id > 0) {
            setStatePresentObj(data.presentState[0]);
            setStatePresentText(data.presentState[0].name);
        }
        setCOPresent(data.presentCareOf);
        setCountryPhoneCodePresent(data.presentCountryPhoneCode);
        if (data.presentCountryPhCode[0].id > 0) {
            setCountryPhoneCodePresentObj(data.presentCountryPhCode[0]);
            setCountryPhoneCodePresentText(data.presentCountryPhCode[0].name);
        }
        setMobileNoPresent(data.presentMobileNo);
        setPresentTelephoneNo(data.presentTelephoneNo);
        setTotalNoOfDependents(data.totalNoOfDependants);

        data.presentAddressPrimaryId != null && data.presentAddressPrimaryId > 0
            ? setPresentAddressPrimary(data.presentAddressPrimaryId)
            : setPresentAddressPrimary(1);
        if (data.presentAddressPrimaryOption[0].id > 0) {
            setPresentAddressPrimaryObj(data.presentAddressPrimaryOption[0]);
            setPresentAddressPrimaryText(data.presentAddressPrimaryOption[0].name);
        }
        data.permanentAddressPrimaryId != null && data.permanentAddressPrimaryId > 0
            ? setPermanentAddressPrimary(data.permanentAddressPrimaryId)
            : setPermanentAddressPrimary(2);
        if (data.permanentAddressPrimaryOption[0].id > 0) {
            setPermanentAddressPrimaryObj(data.permanentAddressPrimaryOption[0]);
            setPermanentAddressPrimaryText(
                data.permanentAddressPrimaryOption[0].name
            );
        }

        if (
            data.presentAddressPrimaryId != null &&
            data.presentAddressPrimaryId == 1
        ) {
            setPermanentAddressPrimary(2);
            setDisablePermanentAddressPrimary(true);
        } else if (
            data.presentAddressPrimaryId != null &&
            data.presentAddressPrimaryId == 2
        ) {
            setPermanentAddressPrimary(1);
            setDisablePermanentAddressPrimary(true);
        } else {
            setPermanentAddressPrimary(0);
            setDisablePermanentAddressPrimary(false);
            setPermanentAddressPrimaryText("");
            setPresentAddressPrimary(0);
            setDisablePresentAddressPrimary(false);
            setPresentAddressPrimaryText("");
        }
        setAddressUsageType(data.addressUsageTypeId);
        if (data.addressUsageTypeObj[0].id > 0) {
            setAddressUsageTypeObj(data.addressUsageTypeObj[0]);
            setAddressUsageTypeText(data.addressUsageTypeObj[0].name);
        }
        setPhoneUsageType(data.primaryPhoneUsageTypeId);
        if (data.primaryPhoneUsageTypeObj[0].id > 0) {
            setPhoneUsageTypeObj(data.primaryPhoneUsageTypeObj[0]);
            setPhoneUsageTypeText(data.primaryPhoneUsageTypeObj[0].name);
        }
        setPhoneISOText(
            !data.presentCountryPhoneCodeText &&
                data.presentCountryPhoneCode &&
                data.presentCountryPhoneCode == 31
                ? "IND"
                : data.presentCountryPhoneCodeText
        );
        setIsLoader(false);
    }

    //Get CommunicationData On Page Load
    function getCommunicationDataOnLoad(applicantId) {
        setIsLoader(true);
        const token = props.token;
        id = applicantId;
        fetch("PreHiringForm/GetCommunicationData/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {

                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Save Communication Data
    function SaveCommunicationData(formData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("PreHiringForm/SaveCommunicationData/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    //alert(data.responseMsg);
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Update Communication Data
    function UpdateCommunicationData(formData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("PreHiringForm/UpdateCommunicationData/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    // alert(data.responseMsg);
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Disable upload button if image exists
    async function disableUploadButton(panImageContent, aadharImageContent) {
        panImageContent != null && panImageContent.length > 0
            ? setDisableUploadButtonPAN(true)
            : setDisableUploadButtonPAN(false);
        aadharImageContent != null && aadharImageContent.length > 0
            ? setDisableUploadButtonAadhar(true)
            : setDisableUploadButtonAadhar(false);
    }

    useEffect(() => {
        if (props.token) {
            getGenders();
            getCountries();
            getStatesPresent(id);
            getStatesPermanent(id);
            getHasAppliedOptions();
            getContactNumberTypes();
            getCountryPhoneCode();
            getIsPrimaryOptions();
            getAddressUsageType();

            if (props.id != undefined && props.id != "") {
                getCommunicationDataOnLoad(props.id);
            }
        }
    }, [props.id, props.token]);

    useEffect(() => {
        if (props.token) {
            if (
                communicationDetailsId != undefined &&
                communicationDetailsId > 0 &&
                props.id != undefined &&
                props.id != ""
            ) {
                getPANImage(props.id);
                getAadharImage(props.id);
                disableUploadButton(panImageData, aadharImageData);
            }
        }
        getButtonTextSave(communicationDetailsId);
    }, [
        props.id,
        communicationDetailsId,
        panImageData,
        aadharImageData,
        props.token,
    ]);

    return (
        <ThemeProvider theme={theme}>
            <div className="width-100">
                <form className="d-flex jc-center">
                    <Grid container spacing={1} className="d-flex fd-column ">
                        <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                            PAN and Aadhar Details
                        </p>
                        {
                            //(aadharImageData != null && aadharImageData.length > 0 && panImageData != null && panImageData.length > 0)
                            //    ?
                            //    <React.Fragment></React.Fragment>
                            //    :
                            <React.Fragment>
                                <p className="clr-red f-12 d-flex jc-space-bt">
                                    <span className="clr-red f-12 mPL-3 m-0">
                                        <i>
                                            *Mandatory : Employee must upload PAN or Aadhar Card <br />
                                            PDF file as Govt. ID proof, if either PAN or Aadhar Card
                                            exists.
                                        </i>
                                    </span>
                                    <span className="clr-dark-blue f-12 mPL-3 m-0">
                                        <i>**Note : File size: 150KB Max; File Type: .pdf</i>
                                    </span>
                                </p>
                            </React.Fragment>
                        }
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            {
                                //<Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>PAN Exist</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="PAN Applied"
                                //            name="PAN Applied"
                                //            value={appliedPAN}
                                //            onChange={handleChangeAppliedOptions}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsHasApplied.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid
                                item
                                xs={12}
                                sm={appliedPAN === 1 ? (panImageData ? 4 : 3) : 4}
                                className="d-flex Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={appliedPANObj}
                                    inputValue={appliedPANText}
                                    options={itemsHasApplied}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangePANAppliedOptions}
                                    onInputChange={handleChangePANAppliedOptionsInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="PAN Exist"
                                            variant="filled"
                                            required
                                            error={errorPANExist}
                                            helperText={errorTextPANExist}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={appliedPAN === 1 ? (panImageData ? 4 : 3) : 4}
                                className="d-flex Width-100"
                            >
                                <TextField
                                    className="mt-1 lightInputFields"
                                    required={requirePAN}
                                    id="panNo"
                                    label="PAN No"
                                    variant="filled"
                                    fullWidth
                                    name="PAN No or Applied For PAN"
                                    value={panNo}
                                    onChange={handleChangePAN}
                                    disabled={
                                        props.disableValue == true
                                            ? props.disableValue
                                            : disablePANField
                                    }
                                    error={errorPANno}
                                    helperText={errorTextPANno}
                                    inputProps={{
                                        maxLength: 10,
                                        style: {
                                            textTransform: "uppercase",
                                        },
                                    }}
                                    InputProps={{
                                        // <-- This is where the error text is added.
                                        endAdornment: (
                                            <>
                                                {hideTickPAN && hideExclaimationPAN ? (
                                                    <></>
                                                ) : (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title={
                                                                !hideTickPAN
                                                                    ? "Valid"
                                                                    : !hideExclaimationPAN
                                                                        ? "Invalid"
                                                                        : ""
                                                            }
                                                            placement="bottom"
                                                        >
                                                            {!hideTickPAN ? (
                                                                <CheckCircleOutlineOutlined
                                                                    style={{ color: "green" }}
                                                                />
                                                            ) : !hideExclaimationPAN ? (
                                                                <ErrorOutlineOutlined
                                                                    style={{ color: "red" }}
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                            <>
                                {appliedPAN === 1 ? (
                                    panImageData ? (
                                        <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                            <a
                                                className="mt-1"
                                                onClick={downloadPANImageHandler}
                                                href="javascript:void(0)"
                                            >
                                                <Tooltip title="Download PAN" placement="top">
                                                    <span
                                                        className="f-14 fw-bold clr-dark-blue"
                                                        style={{
                                                            maxWidth: "100%",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        Employee PAN File Uploaded
                                                    </span>
                                                </Tooltip>
                                            </a>
                                            <br />
                                            {(props.roleId == 3 &&
                                                (props.status == null ||
                                                    props.status == "" ||
                                                    props.status == 3)) ||
                                                (props.roleId == 1 &&
                                                    props.status != null &&
                                                    props.status != "" &&
                                                    props.status > 1 &&
                                                    props.status < 4 &&
                                                    props.status != 3) ? (
                                                <>
                                                    <Tooltip title="Delete PAN" placement="top">
                                                        <IconButton onClick={deletePANImageHandler}>
                                                            <RemoveCircleOutlineOutlinedIcon
                                                                color="secondary"
                                                                style={{ fontSize: 15 }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                className="d-flex Width-100 mt-1"
                                            >
                                                <TextField
                                                    id="employeePANChooseFile"
                                                    name="PANChooseFile"
                                                    type="file"
                                                    className="lightInputFields"
                                                    required={appliedPAN === 1}
                                                    label="Select PAN"
                                                    onChange={changePANFileHandler}
                                                    error={imageHelperPAN.length !== 0}
                                                    helperText={imageHelperPAN}
                                                    inputProps={{
                                                        accept: "application/pdf",
                                                        multiple: false,
                                                    }}
                                                    key={inputFileValuePAN}
                                                    variant="filled"
                                                    disabled={props.roleId === 3 ? false : true}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        // <-- This is where the error text is added.
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                    title={
                                                                        panImageFile
                                                                            ? "Image selected successfully"
                                                                            : GetSelectFileTooltip()
                                                                    }
                                                                    placement="bottom"
                                                                >
                                                                    {panImageFile ? (
                                                                        <CheckCircleOutlineOutlined
                                                                            style={{ color: "green" }}
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            style={{ color: "red", fontSize: "10px" }}
                                                                        >
                                                                            <i>
                                                                                <strong>
                                                                                    read
                                                                                    <br />
                                                                                    me
                                                                                </strong>
                                                                            </i>
                                                                        </span>
                                                                        // <ErrorOutlineOutlined
                                                                        //   style={{ color: "red" }}
                                                                        // />
                                                                    )}
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={2}
                                                className="d-flex Width-100 mt-1"
                                            >
                                                <Button
                                                    variant="contained"
                                                    className="ns-btn-upload"
                                                    startIcon={
                                                        <CloudUploadIcon style={{ color: "white" }} />
                                                    }
                                                    onClick={uploadPANFileHandler}
                                                    //disabled={disabledUploadButton}
                                                    disabled={
                                                        props.roleId === 3
                                                            ? panImageFile
                                                                ? false
                                                                : true
                                                            : true
                                                    }
                                                >
                                                    Upload PAN
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className="d-flex Width-100 mt-1"
                                    ></Grid>
                                )}
                            </>
                            <>
                                {/* <Grid item xs={12} sm={1} className="d-flex jc-center Width-100">
                {hideTickPAN ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Tooltip title="Valid" placement="top">
                      <CheckCircleOutlineOutlinedIcon
                        style={{
                          fontSize: 25,
                          marginRight: "50px",
                          marginTop: "2.5rem",
                          color: "#6cc070",
                        }}
                      />
                    </Tooltip>
                  </React.Fragment>
                )}
                {hideExclaimationPAN ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Tooltip title="Invalid" placement="top">
                      <ErrorOutlineOutlinedIcon
                        style={{
                          fontSize: 25,
                          marginRight: "50px",
                          marginTop: "2.5rem",
                          color: "red",
                        }}
                      />
                    </Tooltip>
                  </React.Fragment>
                )}
              </Grid> */}
                                {
                                    //<Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                    //    <FormControl variant="filled" className={classes.formControl}>
                                    //        <InputLabel id="demo-simple-select-label" required={true}>Aadhar Exist</InputLabel>
                                    //        <Select className="lightInputFields"
                                    //            labelId="demo-simple-select-label"
                                    //            id="Aadhar Applied"
                                    //            name="Aadhar Applied"
                                    //            value={appliedAadhar}
                                    //            onChange={handleChangeAppliedOptions}
                                    //            disabled={props.disableValue}
                                    //        >
                                    //            {itemsHasApplied.map(item => (
                                    //                <MenuItem
                                    //                    key={item.value}
                                    //                    value={item.value}
                                    //                >
                                    //                    {item.label}
                                    //                </MenuItem>)
                                    //            )}
                                    //        </Select>
                                    //    </FormControl>
                                    //</Grid>
                                }
                            </>
                            <Grid
                                item
                                xs={12}
                                sm={appliedAadhar === 1 ? (aadharImageData ? 4 : 3) : 4}
                                className="d-flex Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={appliedAadharObj}
                                    inputValue={appliedAadharText}
                                    options={itemsHasApplied}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeAadharAppliedOptions}
                                    onInputChange={handleChangeAadharAppliedOptionsInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Aadhar Exist"
                                            variant="filled"
                                            required
                                            error={errorAadharExist}
                                            helperText={errorTextAadharExist}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={appliedAadhar === 1 ? (aadharImageData ? 4 : 3) : 4}
                                className="d-flex jc-center Width-100"
                            >
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={requireAadhar}
                                    id="aadharCardNumber"
                                    fullWidth
                                    label="Aadhar Card Number"
                                    variant="filled"
                                    name="Aadhar Card Number"
                                    value={aadharCardNumber}
                                    onChange={handleChangeAadhar}
                                    disabled={
                                        props.disableValue == true
                                            ? props.disableValue
                                            : disableAadharField
                                    }
                                    error={errorAadharNo}
                                    helperText={errorTextAadharNo}
                                    inputProps={{ maxLength: 14 }}
                                    InputProps={{
                                        // <-- This is where the error text is added.
                                        endAdornment: (
                                            <>
                                                {hideTickAadhar && hideExclaimationAadhar ? (
                                                    <></>
                                                ) : (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title={
                                                                !hideTickAadhar
                                                                    ? "Valid"
                                                                    : !hideExclaimationAadhar
                                                                        ? "Invalid"
                                                                        : ""
                                                            }
                                                            placement="bottom"
                                                        >
                                                            {!hideTickAadhar ? (
                                                                <CheckCircleOutlineOutlined
                                                                    style={{ color: "green" }}
                                                                />
                                                            ) : !hideExclaimationAadhar ? (
                                                                <ErrorOutlineOutlined
                                                                    style={{ color: "red" }}
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                            <>
                                {appliedAadhar === 1 ? (
                                    aadharImageData ? (
                                        <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                            <a
                                                className="mt-1"
                                                onClick={downloadAadharImageHandler}
                                                href="javascript:void(0)"
                                            >
                                                <Tooltip title="Download Aadhar" placement="top">
                                                    <span
                                                        className="f-14 fw-bold clr-dark-blue"
                                                        style={{
                                                            maxWidth: "100%",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        Employee Aadhar File Uploaded
                                                    </span>
                                                </Tooltip>
                                            </a>
                                            <br />
                                            {(props.roleId == 3 &&
                                                (props.status == null ||
                                                    props.status == "" ||
                                                    props.status == 3)) ||
                                                (props.roleId == 1 &&
                                                    props.status != null &&
                                                    props.status != "" &&
                                                    props.status > 1 &&
                                                    props.status < 4 &&
                                                    props.status != 3) ? (
                                                <>
                                                    <Tooltip title="Delete Aadhar" placement="top">
                                                        <IconButton onClick={deleteAadharImageHandler}>
                                                            <RemoveCircleOutlineOutlinedIcon
                                                                color="secondary"
                                                                style={{ fontSize: 15 }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                className="d-flex Width-100 mt-1"
                                            >
                                                <TextField
                                                    id="employeeAadharChooseFile"
                                                    name="AadharChooseFile"
                                                    type="file"
                                                    className="lightInputFields"
                                                    required={appliedAadhar === 1}
                                                    label="Select Aadhar"
                                                    onChange={changeAadharFileHandler}
                                                    error={imageHelperAadhar.length !== 0}
                                                    helperText={imageHelperAadhar}
                                                    inputProps={{
                                                        accept: "application/pdf",
                                                        multiple: false,
                                                    }}
                                                    key={inputFileValueAadhar}
                                                    variant="filled"
                                                    disabled={props.roleId === 3 ? false : true}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        // <-- This is where the error text is added.
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                    title={
                                                                        aadharImageFile
                                                                            ? "Image selected successfully"
                                                                            : GetSelectFileTooltip()
                                                                    }
                                                                    placement="bottom"
                                                                >
                                                                    {aadharImageFile ? (
                                                                        <CheckCircleOutlineOutlined
                                                                            style={{ color: "green" }}
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            style={{ color: "red", fontSize: "10px" }}
                                                                        >
                                                                            <i>
                                                                                <strong>
                                                                                    read
                                                                                    <br />
                                                                                    me
                                                                                </strong>
                                                                            </i>
                                                                        </span>
                                                                        // <ErrorOutlineOutlined
                                                                        //   style={{ color: "red" }}
                                                                        // />
                                                                    )}
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={2}
                                                className="d-flex Width-100 mt-1"
                                            >
                                                <Button
                                                    variant="contained"
                                                    className="ns-btn-upload"
                                                    startIcon={
                                                        <CloudUploadIcon style={{ color: "white" }} />
                                                    }
                                                    onClick={uploadAadharFileHandler}
                                                    //disabled={disabledUploadButton}
                                                    disabled={
                                                        props.roleId === 3
                                                            ? aadharImageFile
                                                                ? false
                                                                : true
                                                            : true
                                                    }
                                                >
                                                    Upload Aadhar
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className="d-flex Width-100 mt-1"
                                    ></Grid>
                                )}
                            </>

                            <>
                                {/* <Grid item xs={12} sm={1} className="d-flex jc-center Width-100">
                {hideTickAadhar ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Tooltip title="Valid" placement="top">
                      <CheckCircleOutlineOutlinedIcon
                        style={{
                          fontSize: 25,
                          marginRight: "50px",
                          marginTop: "2.5rem",
                          color: "#6cc070",
                        }}
                      />
                    </Tooltip>
                  </React.Fragment>
                )}
                {hideExclaimationAadhar ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Tooltip title="Invalid" placement="top">
                      <ErrorOutlineOutlinedIcon
                        style={{
                          fontSize: 25,
                          marginRight: "50px",
                          marginTop: "2.5rem",
                          color: "red",
                        }}
                      />
                    </Tooltip>
                  </React.Fragment>
                )}
              </Grid> */}
                            </>
                            {mustAadharOrPANNote ? (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        className="d-flex jc-center Width-100"
                                    >
                                        <p className="clr-red">
                                            <i>
                                                *Note:- Any one of either Aadhar or PAN must be present.
                                            </i>
                                        </p>
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            )}
                            {/*<Grid item xs={12} sm={3} className="d-flex jc-center Width-100">*/}
                            {/*  <TextField*/}
                            {/*    className="lightInputFields"*/}
                            {/*    id="totalNoOfDependents"*/}
                            {/*    type="text"*/}
                            {/*    fullWidth*/}
                            {/*    label="Total No. of Dependents"*/}
                            {/*    variant="filled"*/}
                            {/*    name="Total No. of Dependents"*/}
                            {/*    value={totalNoOfDependents}*/}
                            {/*    onKeyDown={handleKeypress}*/}
                            {/*    onChange={handleChangeNumberOfDependants}*/}
                            {/*    disabled={props.disableValue}*/}
                            {/*  />*/}
                            {/*</Grid>*/}

                            {/* <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
                <p className="clr-red fw-bold">
                  <i>
                    *Please upload the Pan card and Aadhar card copy post saving
                    Communication Data
                  </i>
                </p>
              </Grid> */}
                        </Grid>
                        <>
                            {
                                // communicationDetailsId != null &&
                                // communicationDetailsId != undefined &&
                                // communicationDetailsId > 0 ? (
                                //   <React.Fragment>
                                //     <Grid
                                //       container
                                //       spacing={2}
                                //       className="d-flex jc-space-around fd-row mt-1"
                                //     >
                                //       <Grid item xs={12} sm={3} className="d-flex Width-100">
                                //         {appliedPAN != 1 ? (
                                //           <React.Fragment></React.Fragment>
                                //         ) : (
                                //           <React.Fragment>
                                //             {panImageData != null && panImageData.length > 0 ? (
                                //               <React.Fragment>
                                //                 <a
                                //                   onClick={downloadPANImageHandler}
                                //                   href="javascript:void(0)"
                                //                 >
                                //                   <Tooltip title="Download PAN" placement="top">
                                //                     <span>
                                //                       {/*<img src={`data:image/*;base64,${panImageData}`} alt="PAN Image" style={{ maxWidth: "100%", marginTop: "5px" }} />*/}
                                //                       <p
                                //                         className="f-14 fw-bold clr-dark-blue"
                                //                         style={{
                                //                           maxWidth: "100%",
                                //                           marginTop: "5px",
                                //                         }}
                                //                       >
                                //                         Employee PAN File Uploaded
                                //                       </p>
                                //                       <br />
                                //                     </span>
                                //                   </Tooltip>
                                //                 </a>
                                //                 {(props.roleId == 3 &&
                                //                   (props.status == null ||
                                //                     props.status == "" ||
                                //                     props.status == 3)) ||
                                //                 (props.roleId == 1 &&
                                //                   props.status != null &&
                                //                   props.status != "" &&
                                //                   props.status > 1 &&
                                //                   props.status < 4 &&
                                //                   props.status != 3) ? (
                                //                   <React.Fragment>
                                //                     <Tooltip title="Delete PAN" placement="top">
                                //                       <span>
                                //                         <Button onClick={deletePANImageHandler}>
                                //                           <span className="mr-2">
                                //                             <RemoveCircleOutlineOutlinedIcon
                                //                               color="secondary"
                                //                               style={{ fontSize: 15 }}
                                //                             />
                                //                           </span>
                                //                         </Button>
                                //                       </span>
                                //                     </Tooltip>
                                //                   </React.Fragment>
                                //                 ) : (
                                //                   <React.Fragment></React.Fragment>
                                //                 )}
                                //               </React.Fragment>
                                //             ) : (
                                //               <ImageUploader
                                //                 withIcon={false}
                                //                 withPreview={false}
                                //                 withLabel={false}
                                //                 //label="Max file size: 150KB Accepted: .jpg, .jpeg, .png, .webp, .tiff, .tif, .bmp"
                                //                 buttonText="SELECT PAN"
                                //                 buttonClassName="ns-btn-upload"
                                //                 onChange={changePANFileHandler}
                                //                 //imgExtension={['.jpg', '.jpeg', '.png', '.webp', '.tiff', '.tif', '.bmp']}
                                //                 imgExtension={[".pdf", ".PDF"]}
                                //                 accept="application/pdf"
                                //                 maxFileSize={153600}
                                //                 singleImage={true}
                                //                 name="PAN"
                                //               />
                                //             )}
                                //             <React.Fragment>
                                //               {panImageFile != null && panImageFile.size > 0 ? (
                                //                 <React.Fragment>
                                //                   <Tooltip
                                //                     title="PAN Image Selected Successfully"
                                //                     placement="top"
                                //                   >
                                //                     <DoneOutlinedIcon
                                //                       style={{
                                //                         fontSize: 30,
                                //                         marginTop: "0.6rem",
                                //                         marginRight: "2rem",
                                //                         color: "#6cc070",
                                //                       }}
                                //                     />
                                //                   </Tooltip>
                                //                 </React.Fragment>
                                //               ) : (
                                //                 <React.Fragment></React.Fragment>
                                //               )}
                                //             </React.Fragment>
                                //             <React.Fragment>
                                //               {panImageData != null && panImageData.length > 0 ? (
                                //                 <React.Fragment></React.Fragment>
                                //               ) : (
                                //                 <Grid item xs={4} sm={2} className="Width-100">
                                //                   {/*<Tooltip title="Upload PAN" placement="top">*/}
                                //                   {/*    <span>*/}
                                //                   {/*        <Button className="mt-10px" onClick={uploadPANFileHandler} disabled={disabledUploadButtonPAN}>*/}
                                //                   {/*            <span className="mr-1"><PublishOutlinedIcon fontSize="large" /></span>*/}
                                //                   {/*        </Button>*/}
                                //                   {/*    </span>*/}
                                //                   {/*</Tooltip>*/}
                                //                   <Button
                                //                     variant="contained"
                                //                     className="ns-btn-upload"
                                //                     startIcon={
                                //                       <CloudUploadIcon style={{ color: "white" }} />
                                //                     }
                                //                     onClick={uploadPANFileHandler}
                                //                     disabled={disabledUploadButtonPAN}
                                //                   >
                                //                     Upload PAN
                                //                   </Button>
                                //                 </Grid>
                                //               )}
                                //             </React.Fragment>
                                //           </React.Fragment>
                                //         )}
                                //       </Grid>
                                //       <Grid item xs={12} sm={3} className="d-flex Width-100">
                                //         {appliedAadhar != 1 ? (
                                //           <React.Fragment></React.Fragment>
                                //         ) : (
                                //           <React.Fragment>
                                //             {aadharImageData != null &&
                                //             aadharImageData.length > 0 ? (
                                //               <React.Fragment>
                                //                 <a
                                //                   onClick={downloadAadharImageHandler}
                                //                   href="javascript:void(0)"
                                //                 >
                                //                   <Tooltip title="Download Aadhar" placement="top">
                                //                     <span>
                                //                       {/*<img src={`data:image/*;base64,${aadharImageData}`} alt="Aadhar Image" className="mPL-3" style={{ maxWidth: "100%", marginTop: "5px" }} />*/}
                                //                       <p
                                //                         className="f-14 fw-bold clr-dark-blue"
                                //                         style={{
                                //                           maxWidth: "100%",
                                //                           marginTop: "5px",
                                //                         }}
                                //                       >
                                //                         Employee Aadhar File Uploaded
                                //                       </p>
                                //                       <br />
                                //                     </span>
                                //                   </Tooltip>
                                //                 </a>
                                //                 {(props.roleId == 3 &&
                                //                   (props.status == null ||
                                //                     props.status == "" ||
                                //                     props.status == 3)) ||
                                //                 (props.roleId == 1 &&
                                //                   props.status != null &&
                                //                   props.status != "" &&
                                //                   props.status > 1 &&
                                //                   props.status < 4 &&
                                //                   props.status != 3) ? (
                                //                   <React.Fragment>
                                //                     <Tooltip title="Delete Aadhar" placement="top">
                                //                       <span>
                                //                         <Button onClick={deleteAadharImageHandler}>
                                //                           <span className="mr-2">
                                //                             <RemoveCircleOutlineOutlinedIcon
                                //                               color="secondary"
                                //                               style={{ fontSize: 15 }}
                                //                             />
                                //                           </span>
                                //                         </Button>
                                //                       </span>
                                //                     </Tooltip>
                                //                   </React.Fragment>
                                //                 ) : (
                                //                   <React.Fragment></React.Fragment>
                                //                 )}
                                //               </React.Fragment>
                                //             ) : (
                                //               <ImageUploader
                                //                 withIcon={false}
                                //                 withPreview={false}
                                //                 withLabel={false}
                                //                 //label="Max file size: 5MB Accepted: .jpg, .jpeg, .png, .webp, .tiff, .tif, .bmp"
                                //                 buttonText="SELECT AADHAR"
                                //                 buttonClassName="ns-btn-upload"
                                //                 onChange={changeAadharFileHandler}
                                //                 //imgExtension={['.jpg', '.jpeg', '.png', '.webp', '.tiff', '.tif', '.bmp']}
                                //                 imgExtension={[".pdf", ".PDF"]}
                                //                 accept="application/pdf"
                                //                 maxFileSize={153600}
                                //                 singleImage={true}
                                //                 name="AADHAR"
                                //               />
                                //             )}
                                //             <React.Fragment>
                                //               {aadharImageFile != null &&
                                //               aadharImageFile.size > 0 ? (
                                //                 <React.Fragment>
                                //                   <Tooltip
                                //                     title="Aadhar Selected Successfully"
                                //                     placement="top"
                                //                   >
                                //                     <DoneOutlinedIcon
                                //                       style={{
                                //                         fontSize: 30,
                                //                         marginTop: "0.6rem",
                                //                         marginRight: "3rem",
                                //                         color: "#6cc070",
                                //                       }}
                                //                     />
                                //                   </Tooltip>
                                //                 </React.Fragment>
                                //               ) : (
                                //                 <React.Fragment></React.Fragment>
                                //               )}
                                //             </React.Fragment>
                                //             <React.Fragment>
                                //               {aadharImageData != null &&
                                //               aadharImageData.length > 0 ? (
                                //                 <React.Fragment></React.Fragment>
                                //               ) : (
                                //                 <Grid item xs={4} sm={2} className="Width-100">
                                //                   {/*<Tooltip title="Upload Aadhar" placement="top">*/}
                                //                   {/*    <span>*/}
                                //                   {/*        <Button className="mt-10px" onClick={uploadAadharFileHandler} disabled={disabledUploadButtonAadhar}>*/}
                                //                   {/*            <span className="mr-1"><PublishOutlinedIcon fontSize="large" /></span>*/}
                                //                   {/*        </Button>*/}
                                //                   {/*    </span>*/}
                                //                   {/*</Tooltip>*/}
                                //                   <Button
                                //                     variant="contained"
                                //                     className="ns-btn-upload"
                                //                     startIcon={
                                //                       <CloudUploadIcon style={{ color: "white" }} />
                                //                     }
                                //                     onClick={uploadAadharFileHandler}
                                //                     disabled={disabledUploadButtonAadhar}
                                //                     style={{ marginRight: "-150px !important" }}
                                //                   >
                                //                     Upload Aadhar
                                //                   </Button>
                                //                 </Grid>
                                //               )}
                                //             </React.Fragment>
                                //           </React.Fragment>
                                //         )}
                                //       </Grid>
                                //     </Grid>
                                //   </React.Fragment>
                                // ) : (
                                //   <React.Fragment></React.Fragment>
                                // )
                            }
                        </>
                        <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                            Present Address
                        </p>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            {/* <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField className="lightInputFields mt-1"
                                    required={true}
                                    id="cO"
                                    fullWidth
                                    label="C/O"
                                    variant="filled"
                                    name="C/o"
                                    value={cOPresent}
                                    onChange={handleChangeCareOFPresent}
                                    disabled={props.disableValue}
                                />
                            </Grid>*/}
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="firstAddressLine"
                                    label="1st Address Line"
                                    variant="filled"
                                    fullWidth
                                    name="1st Address Line"
                                    value={firstAddressLinePresent}
                                    onChange={handleChangeFirstLineOfAddressPresent}
                                    disabled={props.disableValue}
                                    error={errorFirstAddressLinePresent}
                                    helperText={errorTextFirstAddressLinePresent}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="secondAddressLine"
                                    label="2nd Address Line"
                                    variant="filled"
                                    fullWidth
                                    name="2nd Address Line"
                                    value={secondAddressLinePresent}
                                    onChange={handleChangeSecondLineOfAddressPresent}
                                    disabled={props.disableValue}
                                    error={errorSecondAddressLinePresent}
                                    helperText={errorTextSecondAddressLinePresent}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="thirdAddressLine"
                                    label="3rd Address Line"
                                    variant="filled"
                                    fullWidth
                                    name="3rd Address Line"
                                    value={thirdAddressLinePresent}
                                    onChange={handleChangeThirdLineAddressPresent}
                                    disabled={props.disableValue}
                                    error={errorThirdAddressLinePresent}
                                    helperText={errorTextThirdAddressLinePresent}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Country</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="CountryPresent"
                                //            name="CountryPresent"
                                //            value={countryPresent}
                                //            onChange={handleChangeCountry}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemCountries.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={countryPresentObj}
                                    inputValue={countryPresentText}
                                    options={itemCountries}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeCountryPresent}
                                    onInputChange={handleChangeCountryPresentInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            variant="filled"
                                            required
                                            error={errorCountryPresent}
                                            helperText={errorTextCountryPresent}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>State</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="StatePresent"
                                //            name="StatePresent"
                                //            value={statePresent}
                                //            onChange={handleChangeState}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemStates.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={statePresentObj}
                                    inputValue={statePresentText}
                                    options={itemStatesPresent}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    noOptionsText={
                                        countryPresent && countryPresent != 94
                                            ? "No options - Skip"
                                            : "No options"
                                    }
                                    onChange={handleChangeStatePresent}
                                    onInputChange={handleChangeStatePresentInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="State"
                                            variant="filled"
                                            required={
                                                countryPresent && countryPresent == 94 ? true : false
                                            }
                                            error={errorStatePresent}
                                            helperText={errorTextStatePresent}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="city"
                                    label="City"
                                    variant="filled"
                                    fullWidth
                                    name="City"
                                    value={cityPresent}
                                    onChange={handleChangeCityPresent}
                                    disabled={props.disableValue}
                                    error={errorCityPresent}
                                    helperText={errorTextCityPresent}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="pinCode"
                                    label="Pin Code"
                                    variant="filled"
                                    fullWidth
                                    name="Pin Code"
                                    value={pinCodePresent}
                                    onChange={handleChangePINCodePresent}
                                    disabled={props.disableValue}
                                    inputProps={{ maxLength: 6 }}
                                    error={errorPinCodePresent}
                                    helperText={errorTextPinCodePresent}
                                />
                            </Grid>
                            {/*<Grid item xs={12} sm={4} className="d-flex Width-100">*/}
                            {/*    <TextField className="lightInputFields mt-1"*/}
                            {/*        id="region"*/}
                            {/*        label="Region"*/}
                            {/*        variant="filled"*/}
                            {/*        fullWidth*/}
                            {/*        name="Region"*/}
                            {/*        value={regionPresent}*/}
                            {/*        onChange={handleChangeRegionPresent}*/}
                            {/*        disabled={props.disableValue}*/}
                            {/*    />*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={presentAddressPrimaryObj}
                                    inputValue={presentAddressPrimaryText}
                                    options={itemsIsPrimary}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangePresentAddressPrimaryOptions}
                                    onInputChange={handleChangePresentAddressPrimaryOptionsInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Primary Address"
                                            variant="filled"
                                            required
                                            error={errorPresentAddressPrimary}
                                            helperText={errorTextPresentAddressPrimary}
                                        />
                                    )}
                                    disabled={
                                        props.disableValue == true
                                            ? props.disableValue
                                            : disablePresentAddressPrimary
                                    }
                                />
                            </Grid>
                            {presentAddressPrimary == 1 ? (
                                <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                    <Autocomplete
                                        className="lightInputFields hover-lightblue"
                                        id="combo-box-demo"
                                        value={addressUsageTypeObj}
                                        inputValue={addressUsageTypeText}
                                        options={itemsAddressUsageType}
                                        getOptionLabel={(option) =>
                                            option.name ? option.name : ""
                                        }
                                        onChange={handleChangeAddressUsageType}
                                        onInputChange={handleChangeAddressUsageTypeInput}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Address Usage Type"
                                                variant="filled"
                                                required
                                                error={errorAddressUsageType}
                                                helperText={errorTextAddressUsageType}
                                            />
                                        )}
                                        disabled={props.disableValue}
                                    />
                                </Grid>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="personalEmail"
                                    label="Personal Email"
                                    variant="filled"
                                    fullWidth
                                    name="Personal Email"
                                    value={personalEmail}
                                    onChange={handleChangePersonalEmail}
                                    disabled={props.disableValue}
                                    error={errorPersonalEmail}
                                    helperText={errorTextPersonalEmail}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Country Phone Code</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="countryPhoneCode"
                                //            name="countryPhoneCode"
                                //            value={countryPhoneCodePresent}
                                //            onChange={handleChangeCountryPhoneCodePresent}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemCountryPhoneCode.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={countryPhoneCodePresentObj}
                                    inputValue={countryPhoneCodePresentText}
                                    options={itemCountryPhoneCode}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeCountryPhoneCodePresent}
                                    onInputChange={handleChangeCountryPhoneCodePresentInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country Phone Code"
                                            variant="filled"
                                            required
                                            error={errorCountryPhoneCode}
                                            helperText={errorTextCountryPhoneCode}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required
                                    id="isoCode"
                                    label="Phone ISO Code"
                                    variant="filled"
                                    fullWidth
                                    name="Phone ISO Code"
                                    value={phoneISOText}
                                    onChange={(e) => setPhoneISOText(e.target.value)}
                                    disabled={
                                        countryPhoneCodePresent && countryPhoneCodePresent == 31
                                            ? true
                                            : props.disableValue
                                    }
                                    error={errorPhoneISOText}
                                    helperText={errorTextPhoneISOText}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="mobileNo"
                                    label="Primary Mobile No"
                                    variant="filled"
                                    fullWidth
                                    name="Primary Mobile No"
                                    value={mobileNoPresent}
                                    onChange={handleChangeMobilePresent}
                                    disabled={props.disableValue}
                                    error={errorMobileNumber}
                                    helperText={errorTextMobileNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={phoneUsageTypeObj}
                                    inputValue={phoneUsageTypeText}
                                    options={itemsAddressUsageType}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangePhoneUsageType}
                                    onInputChange={handleChangePhoneUsageTypeInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Phone Usage Type"
                                            variant="filled"
                                            required
                                            error={errorPhoneUsageType}
                                            helperText={errorTextPhoneUsageType}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="presenttelephoneNo"
                                    label="Present Landline Number"
                                    variant="filled"
                                    fullWidth
                                    name="Present Landline Number"
                                    value={presentTelephoneNo}
                                    onChange={handleChangeTelePhonePresent}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="alternateEmail"
                                    fullWidth
                                    label="Alternate Email"
                                    variant="filled"
                                    name="Alternate Email"
                                    value={alternateEmail}
                                    onChange={handleChangeAlternateEmail}
                                    disabled={props.disableValue}
                                    error={errorAlternateEmail}
                                    helperText={errorTextAlternateEmail}
                                />
                            </Grid>
                        </Grid>
                        <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                            Permanent Address
                        </p>
                        <Button
                            xs={12}
                            sm={6}
                            className="ns-btn-primary width-25"
                            disabled={props.disableValue}
                            onClick={copyAddressHandler}
                        >
                            <span>Copy From Present Address</span>
                        </Button>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="firstAddressLine_P"
                                    label="1st Address Line"
                                    variant="filled"
                                    fullWidth
                                    name="1st Address Line"
                                    value={firstAddressLinePermanent}
                                    onChange={handleChangeFirstLineOfAddressPermanent}
                                    disabled={props.disableValue}
                                    error={errorFirstAddressLinePermanent}
                                    helperText={errorTextFirstAddressLinePermanent}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="secondAddressLine_P"
                                    label="2nd Address Line"
                                    variant="filled"
                                    fullWidth
                                    name="2nd Address Line"
                                    value={secondAddressLinePermanent}
                                    onChange={handleChangeSecondLineOfAddressPermanent}
                                    disabled={props.disableValue}
                                    error={errorSecondAddressLinePermanent}
                                    helperText={errorTextSecondAddressLinePermanent}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="thirdAddressLine"
                                    label="3rd Address Line"
                                    variant="filled"
                                    fullWidth
                                    name="3rd Address Line"
                                    value={thirdAddressLinePermanent}
                                    onChange={handleChangeThirdLineAddressPermanent}
                                    disabled={props.disableValue}
                                    error={errorThirdAddressLinePermanent}
                                    helperText={errorTextThirdAddressLinePermanent}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Country</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="CountryPermanent"
                                //            name="CountryPermanent"
                                //            value={countryPermanent}
                                //            onChange={handleChangeCountry}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemCountries.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={countryPermanentObj}
                                    inputValue={countryPermanentText}
                                    options={itemCountries}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeCountryPermanent}
                                    onInputChange={handleChangeCountryPermanentInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            variant="filled"
                                            required
                                            error={errorCountryPermanent}
                                            helperText={errorTextCountryPermanent}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>State</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="StatePermanent"
                                //            name="StatePermanent"
                                //            value={statePermanent}
                                //            onChange={handleChangeState}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemStates.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={statePermanentObj}
                                    inputValue={statePermanentText}
                                    options={itemStatesPermanent}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    noOptionsText={
                                        countryPermanent && countryPermanent != 94
                                            ? "No options - Skip"
                                            : "No options"
                                    }
                                    onChange={handleChangeStatePermanent}
                                    onInputChange={handleChangeStatePermanentInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="State"
                                            variant="filled"
                                            required={
                                                countryPermanent && countryPermanent == 94
                                                    ? true
                                                    : false
                                            }
                                            error={errorStatePermanent}
                                            helperText={errorTextStatePermanent}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="city"
                                    label="City"
                                    variant="filled"
                                    fullWidth
                                    name="City"
                                    value={cityPermanent}
                                    onChange={handleChangeCityPermanent}
                                    disabled={props.disableValue}
                                    error={errorCityPermanent}
                                    helperText={errorTextCityPermanent}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="pinCode"
                                    label="Pin Code"
                                    variant="filled"
                                    fullWidth
                                    name="Pin Code"
                                    value={pinCodePermanent}
                                    onChange={handleChangePINCodePermanent}
                                    disabled={props.disableValue}
                                    inputProps={{ maxLength: 6 }}
                                    error={errorPinCodePermanent}
                                    helperText={errorTextPinCodePermanent}
                                />
                            </Grid>
                            {/*<Grid item xs={12} sm={4} className="d-flex Width-100">*/}
                            {/*    <TextField className="lightInputFields mt-1"*/}
                            {/*        id="region"*/}
                            {/*        label="Region"*/}
                            {/*        variant="filled"*/}
                            {/*        fullWidth*/}
                            {/*        name="Region"*/}
                            {/*        value={regionPermanent}*/}
                            {/*        onChange={handleChangeRegionPermanent}*/}
                            {/*        disabled={props.disableValue}*/}
                            {/*    />*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={permanentAddressPrimaryObj}
                                    inputValue={permanentAddressPrimaryText}
                                    options={itemsIsPrimary}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangePermanentAddressPrimaryOptions}
                                    onInputChange={
                                        handleChangePermanentAddressPrimaryOptionsInput
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Primary Address"
                                            variant="filled"
                                            required
                                            error={errorPermanentAddressPrimary}
                                            helperText={errorTextPermanentAddressPrimary}
                                        />
                                    )}
                                    disabled={
                                        props.disableValue == true
                                            ? props.disableValue
                                            : disablePermanentAddressPrimary
                                    }
                                />
                            </Grid>
                            {permanentAddressPrimary == 1 ? (
                                <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                    <Autocomplete
                                        className="lightInputFields hover-lightblue"
                                        id="combo-box-demo"
                                        value={addressUsageTypeObj}
                                        inputValue={addressUsageTypeText}
                                        options={itemsAddressUsageType}
                                        getOptionLabel={(option) =>
                                            option.name ? option.name : ""
                                        }
                                        onChange={handleChangeAddressUsageType}
                                        onInputChange={handleChangeAddressUsageTypeInput}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Address Usage Type"
                                                variant="filled"
                                                required
                                                error={errorAddressUsageType}
                                                helperText={errorTextAddressUsageType}
                                            />
                                        )}
                                        disabled={props.disableValue}
                                    />
                                </Grid>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="permanentTelephoneNo"
                                    label="Permanent Contact No"
                                    variant="filled"
                                    fullWidth
                                    name="Permanent Contact No"
                                    value={permanentTelephoneNo}
                                    onChange={handleChangeTelePhonePermanent}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="alternateMobileNo"
                                    label="Alternate Mobile No"
                                    variant="filled"
                                    fullWidth
                                    name="Alternate Mobile No"
                                    value={alternateMobileNo}
                                    onChange={handleChangeAlternateMobilePermanent}
                                    disabled={props.disableValue}
                                    error={errorAlternateMobileNo}
                                    helperText={errorTextAlternateMobileNo}
                                />
                            </Grid>
                        </Grid>
                        <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                            Passport Details
                        </p>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="passportNo"
                                    fullWidth
                                    label="Passport No"
                                    variant="filled"
                                    name="Passport No"
                                    value={passportNo}
                                    onChange={handleChangePassportNo}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="placeIssueOfPassport"
                                    label="Place Issue Of Passport"
                                    variant="filled"
                                    fullWidth
                                    name="Place Issue Of Passport"
                                    value={placeIssueOfPassport}
                                    onChange={handleChangePlaceOfIssuePassport}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="Date of Issue of Passport"
                                    label="Date of Issue of Passport"
                                    value={dateOfIssueOfPassport}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={true}
                                    disablepastdate={false}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label">Country Of Issue Of Passport</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="Country Of Issue Of Passport"
                                //            name="Country Of Issue Of Passport"
                                //            value={countryOfIssueOfPassport}
                                //            onChange={handleChangeCountry}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemCountries.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={countryOfIssueOfPassportObj}
                                    inputValue={countryOfIssueOfPassportText}
                                    options={itemCountries}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeCountryPassport}
                                    onInputChange={handleChangeCountryPassportInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country Of Issue Of Passport"
                                            variant="filled"
                                            //required
                                            error={errorCountryOfIssueOfPassport}
                                            helperText={errorTextCountryOfIssueOfPassport}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="Passport Valid Till"
                                    label="Passport Valid Till"
                                    value={passportValidTill}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={false}
                                    disablepastdate={true}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="issuingAuthorityOfPassport"
                                    label="Issuing Authority of Passport"
                                    variant="filled"
                                    fullWidth
                                    name="Issuing Authority of Passport"
                                    value={issuingAuthorityOfPassport}
                                    onChange={handleChangePassportIssuingAuthority}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="ENCRStatusOfPassport"
                                    label="ENCR Status Of Passport"
                                    variant="filled"
                                    fullWidth
                                    name="ENCR Status Of Passport"
                                    value={encrStatusOfPassport}
                                    onChange={handleChangePassportENCRStatus}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                        </Grid>
                        <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                            Driving License Details
                        </p>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="drivingLicenceNo"
                                    fullWidth
                                    label="Driving Licence No"
                                    variant="filled"
                                    name="Driving Licence No"
                                    value={drivingLicenseNo}
                                    onChange={handleChangeDLNo}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="placeOfIssue"
                                    label="Place Of Issue Driving License"
                                    variant="filled"
                                    fullWidth
                                    name="Place Of Issue Driving License"
                                    value={placeOfIssueDL}
                                    onChange={handleChangePlaceOfIssueDL}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="Date of Issue of Driving License"
                                    label="Date of Issue of Driving License"
                                    value={dateOfIssueOfLicense}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={true}
                                    disablepastdate={false}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label">Country Of Issue Of Driving License</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="Country Of Issue Of Driving License"
                                //            name="Country Of Issue Of Driving License"
                                //            value={countryOfIssueOfLicense}
                                //            onChange={handleChangeCountry}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemCountries.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={countryOfIssueOfLicenseObj}
                                    inputValue={countryOfIssueOfLicenseText}
                                    options={itemCountries}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeCountryDL}
                                    onInputChange={handleChangeCountryDLInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country Of Issue Of Driving License"
                                            variant="filled"
                                            //required
                                            error={errorCountryOfIssueOfLicense}
                                            helperText={errorTextCountryOfIssueOfLicense}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="Driving License Valid Till"
                                    label="Driving License Valid Till"
                                    value={licenseValidTill}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={false}
                                    disablepastdate={true}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="issuingAuthorityOfPassport"
                                    label="Issuing Authority of Driving License"
                                    variant="filled"
                                    fullWidth
                                    name="Issuing Authority of Driving License"
                                    value={issuingAuthorityOfLicense}
                                    onChange={handleChangeDLIssuingAuthority}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                        </Grid>
                        <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                            Emergency Contact Number
                        </p>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="firstName"
                                    fullWidth
                                    label="First Name"
                                    variant="filled"
                                    name="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    disabled={props.disableValue}
                                    error={errorFirstName}
                                    helperText={errorTextFirstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="lastName"
                                    label="Last Name"
                                    variant="filled"
                                    fullWidth
                                    name="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    disabled={props.disableValue}
                                    error={errorLastName}
                                    helperText={errorTextLastName}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Gender</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={gender}
                                //            onChange={handleChangeGender}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsGender.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={genderObj}
                                    inputValue={genderText}
                                    options={itemsGender}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeGender}
                                    onInputChange={handleChangeGenderInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Gender"
                                            variant="filled"
                                            required
                                            error={errorGender}
                                            helperText={errorTextGender}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Landline/Mobile</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={landlineMobile}
                                //            onChange={handleChangeLandline}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemContactNumberTypes.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={landlineMobileObj}
                                    inputValue={landlineMobileText}
                                    options={itemContactNumberTypes}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeLandline}
                                    onInputChange={handleChangeLandlineInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Landline/Mobile"
                                            variant="filled"
                                            required
                                            error={errorMobile}
                                            helperText={errorTextMobile}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="emergencyContactNo"
                                    fullWidth
                                    label="Emergency Contact No"
                                    variant="filled"
                                    name="Emergency Contact No"
                                    value={emergencyContactNo}
                                    onChange={(e) => setEmergencyContactNo(e.target.value)}
                                    disabled={props.disableValue}
                                    error={errorEmergencyContactNo}
                                    helperText={errorTextEmergencyContactNo}
                                />
                            </Grid>
                        </Grid>

                        <div className="d-flex jc-center">
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2"
                                disabled={props.disableValue}
                                onClick={clearHandler}
                            >
                                <span>Clear</span>
                            </Button>
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2 ml-2"
                                disabled={props.disableValue}
                                onClick={submitHandler}
                            >
                                <span>{buttonTextSave}</span>
                            </Button>
                        </div>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadClear"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlertConfirm}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">Cancel</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? (
                            <Loader loaderText="It may take a little while, please wait..." />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
