import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  TextField,
  createMuiTheme,
  ThemeProvider,
  Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "@material-ui/core/Tooltip";
import FileSaver from "file-saver";
import JSZip from "jszip";

import MaterialTable from "material-table";
import axios from "axios";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    //MuiAutocomplete: {
    //    root: {
    //        background: "#E8F8F8",
    //        '&$disabled': {
    //            backgroundColor: '#454545 !important',
    //        },
    //        padding: "0 !important",
    //        height: "55px !important",
    //    },
    //},
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: "none",
        marginBottom: "5px",
        background: "#FFFFFF",
        color: "#000028",
      },
      elevation2: {
        boxShadow: "none",
      },
    },
    MuiToolbar: {
      root: {
        background: "000028",
        color: "#000028",
        '&[class*="MTableToolbar-highlight"]': {
          color: "#000028 !important",
          backgroundColor: "#FFFFFF !important",
        },
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "transparent",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
      h6: {
        fontSize: "16px !important",
        fontWeight: "bold !important",
        color: "#000028 !important",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#FFFFFF !important",
        padding: "1px",
        fontSize: "15px !important",
        borderBottom: "none",
        //border: "1px solid #CCC"
        alignItems: "center",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        background: "#FFFFFF !important",
        fontSize: "15px !important",
        alignItems: "center",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#000028",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
        fontSize: "13px",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

export default function UploadSupportingDocuments(props) {
  const [documentName, setDocumentName] = useState("");
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputFileValue, setInputFileValue] = useState(Date.now);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  //Error States and Message
  const [errorDocumentName, setErrorDocumentName] = useState(false);
  const [errorTextDocumentName, setErrorTextDocumentName] = useState("");
  const [errorDocumentFileInput, setErrorDocumentFileInput] = useState(false);

  //Alerts
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  var fileUploadData = new FormData();

  const handleChangeDocumentName = (e) => {
    setErrorDocumentName(false);
    setErrorTextDocumentName("");
    setDocumentName(e.target.value);
  };

  const changeFileHandler = (e) => {
    setSelectedFile(e.target.files[0]);
    setErrorDocumentFileInput(false);
  };
  const uploadHandler = (e) => {
    setIsLoader(true);
    if (documentName == null || documentName == "") {
      setIsLoader(false);
      setErrorDocumentName(true);
      setErrorTextDocumentName("Mandatory field");
      setOpen(true);
      setOpenAlert("Document name can not be blank.");
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    } else if (selectedFile == null || selectedFile.length <= 0) {
      setIsLoader(false);
      setErrorDocumentFileInput(true);
      setOpen(true);
      setOpenAlert("Please select a file before upload.");
      setClassName("clr-white bg-alert");
      setOpenType("Alert");
    } else {
      fileUploadData.append("ApplicantId", props.id);
      fileUploadData.append("UserId", props.userId);
      fileUploadData.append("DocumentName", documentName);
      fileUploadData.append("SelectedFile", selectedFile);
      UploadDocuments(fileUploadData);
    }
  };
  var formData = {
    DocumentDetailsId: null,
    UserId: props.userId,
  };
  function downloadDocument(data) {
    setIsLoader(true);
    var id = data.documentDetailsId;
    var fileName = data.gid + "_" + data.documentName + ".pdf";
    const token = props.token;
    axios
      .get("/HRDetails/DownloadUploadedDocument/" + id, {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + token },
      })
      .then(function (response) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(blob, fileName);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
    /*window.location.href = '/HRDetails/DownloadUploadedDocument/' + id;*/
  }
  function deleteDocument(id) {
    setIsLoader(true);
    const token = props.token;
    formData.DocumentDetailsId = id;
    formData.UserId = props.userId;
    const options = {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(formData),
    };
    fetch("HRDetails/DeleteUploadedDocuments/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          // alert(data.responseMsg);
          getUploadedDocumentsaOnLoad(props.id);
        } else {
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          //alert(data.responseMsg);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }
  function SetStateAsPerFetched(data) {
    const dataOnLoad = data.map((c) => {
      c.gid = c.gid ? c.gid : "[GID]";
      return c;
    });
    setFileList(dataOnLoad);
    setInputFileValue(Date.now);
    setDocumentName("");
    setSelectedFile(null);
    setErrorDocumentFileInput(false);
    setErrorDocumentName(false);
    setErrorTextDocumentName("");
    setIsLoader(false);
  }
  function UploadDocuments(fileUploadData) {
    const token = props.token;
    const options = {
      method: "post",
      body: fileUploadData,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch("HRDetails/UploadSupportingDocuments/", options)
      .then((response) => response.json())
      .then((data) => {
        if (data[0].response) {
          setOpen(true);
          setOpenAlert(data[0].responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          SetStateAsPerFetched(data);
        } else {
          setSelectedFile(null);
          setInputFileValue(Date.now);
          setDocumentName("");
          setIsLoader(false);
          setOpen(true);
          setOpenAlert(data[0].responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
      .catch((error) => console.log("Error:", error));
  }
  //Get Documents On Page Load
  function getUploadedDocumentsaOnLoad(id) {
    setIsLoader(true);
    const token = props.token;
    fetch("HRDetails/GetUploadedDocuments/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  function GetDownloadButton() {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          className="ns-btn-secondary-light"
          startIcon={<CloudDownloadIcon style={{ color: "white" }} />}
          disabled={props.disableOnceCompleted}
        >
          Download Selected
        </Button>
      </React.Fragment>
    );
  }

  function GetDocumentNameComponent(rowData) {
    return props.disableOnceCompleted ? (
      <React.Fragment>
        <Tooltip title={"Download " + rowData.documentName} placement="top">
          <span className="f-14 fw-bold clr-dark-blue">
            {rowData.documentName}
          </span>
        </Tooltip>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <a
          className="mt-1"
          onClick={() => downloadDocument(rowData)}
          href="javascript:void(0)"
        >
          <Tooltip title={"Download " + rowData.documentName} placement="top">
            <span className="f-14 fw-bold clr-dark-blue">
              {rowData.documentName}
            </span>
          </Tooltip>
        </a>
      </React.Fragment>
    );
  }

  function GetDeleteDocumentButton(rowData) {
    return (
      <Button
        variant="contained"
        className="ns-btn-delete-doc"
        startIcon={<DeleteIcon />}
        onClick={() => deleteDocument(rowData.documentDetailsId)}
        disabled={
          props.disableOnceCompleted
            ? props.disableOnceCompleted
            : props.disableHRAction
        }
      >
        Delete
      </Button>
    );
  }

  function DownloadMultiple(data) {
    setIsLoader(true);
    var zip = new JSZip();
    for (var i = 0; i < data.length; i++) {
      var fileName = data[i].gid + "_" + data[i].documentName + ".pdf";
      zip.file(fileName, data[i].documentContent, { base64: true });
    }
    zip
      .generateAsync({ type: "blob" })
      .then(function (content) {
        FileSaver.saveAs(content, data[0].gid + "_SupportingDocuments.zip");
        setIsLoader(false);
        getUploadedDocumentsaOnLoad(props.id);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
  }

  var columns = [
    { title: "Applicant Id", field: "applicantId", hidden: true },
    { title: "GID", field: "gid", hidden: true },
    { title: "Document Details Id", field: "documentDetailsId", hidden: true },
    { title: "Document Content", field: "documentContent", hidden: true },
    {
      title: "Select All",
      field: "",
      render: (rowData) => rowData && GetDocumentNameComponent(rowData),
    },
    {
      title: "",
      field: "",
      hidden: props.roleId == 2 || props.roleId == 4 ? false : true,
      render: (rowData) => rowData && GetDeleteDocumentButton(rowData),
    },
  ];
  const actions = [
    {
      icon: () => GetDownloadButton(),
      //icon: 'delete',
      tooltip: "Download Documents",
      onClick: (event, rowData) => DownloadMultiple(rowData),
    },
  ];

  useEffect(() => {
    if (props.token) {
        if (props.id != undefined && props.id != "") {
        getUploadedDocumentsaOnLoad(props.id);
      }
    }
  }, [props.id, props.token]);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className="Width-100">
        <form className="d-flex jc-center">
          <Grid container spacing={1} className="d-flex fd-column ">
            {props.roleId == 2 || props.roleId == 4 ? (
              <React.Fragment>
                <p className="f-16 fw-bold clr-dark-blue d-flex jc-center">
                  Upload Supporting Documents
                </p>
                <Grid
                  container
                  spacing={2}
                  className="d-flex jc-space-around fd-row"
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    className="d-flex jc-center mt-1 Width-100"
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="d-flex jc-center mt-1 Width-100"
                  >
                    <TextField
                      className="lightInputFields mt-1"
                      required={true}
                      id="filetName"
                      fullWidth
                      label="Enter File Name"
                      variant="filled"
                      name="File Name"
                      value={documentName}
                      onChange={handleChangeDocumentName}
                      error={errorDocumentName}
                      helperText={errorTextDocumentName}
                      disabled={props.disableOnceCompleted}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="d-flex jc-center mt-1 Width-100"
                  >
                    <Input
                      type="file"
                      className="mt-1"
                      fullWidth
                      onChange={changeFileHandler}
                      key={inputFileValue}
                      required
                      error={errorDocumentFileInput}
                      disabled={
                        props.disableOnceCompleted
                          ? props.disableOnceCompleted
                          : props.disableHRAction
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    className="d-flex jc-center mt-1 Width-100"
                  >
                    <Button
                      variant="contained"
                      className="ns-btn-secondary-light mt-1bt2 mr-4"
                      startIcon={<CloudUploadIcon />}
                      onClick={uploadHandler}
                      disabled={
                        props.disableOnceCompleted
                          ? props.disableOnceCompleted
                          : props.disableHRAction
                      }
                    >
                      Upload
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    className="d-flex jc-center mt-1 Width-100"
                  ></Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                    className="d-flex jc-center Width-100"
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className="d-flex jc-flex-start Width-100"
                  >
                    <p className="f-12 clr-red mt-minus1">
                      <i>
                        *Note: Only PDF files are allowed
                        <br /> File Size 0 to 5 MB
                      </i>
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className="d-flex jc-center Width-100"
                  ></Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {fileList.length > 0 ? (
              fileList[0].documentName != null &&
              fileList[0].documentName != "" ? (
                <React.Fragment>
                  {/*<p className="f-16 fw-bold clr-dark-blue mt-2 d-flex jc-center">List Of Uploaded Documents</p>*/}
                  <Grid
                    container
                    spacing={2}
                    className="d-flex jc-space-around fd-row"
                  >
                    <Grid item xs={12} lg={6} md={6} className="mb-2">
                      <MaterialTable
                        title="List Of Uploaded Documents"
                        actions={!props.disableOnceCompleted ? actions : null}
                        columns={columns}
                        data={fileList}
                        icons={tableIcons}
                        options={{
                          search: false,
                          selection: true,
                          paging: false,
                          pageSize: 30,
                          showTitle: true,
                          sorting: false,
                          showTextRowsSelected: false,
                        }}
                        components={{
                          overlayLoading: () => (
                            <React.Fragment>
                              <CircularProgress />
                            </React.Fragment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )
            ) : (
              <React.Fragment>
                <p className="f-18 fw-bold clr-dark-blue mt-2 d-flex jc-center">
                  Documents Not Uploaded Yet
                </p>
              </React.Fragment>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-popup">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? (
              <Loader loaderText="Loading documents, please wait..." />
            ) : (
              <></>
            )}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
