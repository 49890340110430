import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider, Typography } from "@material-ui/core";

const theme = createMuiTheme({
    overrides: {
        MuiAppBar: {
            root: {
                display: 'flex',
            },
        }
    }
});

export default function PrivacyNotice() {
    return (
        <ThemeProvider theme={theme}>
            <div className="master-layout-blockPrint mt-6">
                <p className="ml-2">
                    <img src="../assets/images/Siemens_Logo_Grey.png" alt="Siemens Company Logo" height="25" width="100" />
                </p>
                <Typography className="clr-dark-blue f-14 mt-2 ml-2">
                    <span className="fw-bold">
                        Privacy Notice
                        <br />
                        Last Update 14 August 2023
                    </span>
                    <br />
                    <br />
                    Protecting the security and privacy of your personal data is important to Siemens Limited (“Siemens”). Therefore Siemens operates Shubharambh application (“App”) in compliance with applicable laws on data protection and data security.
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Categories of personal data processed and purpose of the processing
                    </span>
                    <br />
                    <br />
                    When visiting and using the App, the following personal data about you is processed with the respective purpose:
                    <br />
                    <br />
                    <span className="fw-bold">
                        Fist, Middle & Last Name, Date of Birth, Gender, Nationality, Address & Location, Photo graphs and visual images, Marital Status, No. of Children & Dependents, Official Identifiers e.g. Permanent Account Number Card, Aadhar Card, Passport, Driving License, Election Voting Card, etc., E-Mail ID. Mobile / Telephone Numbers, Emergency Contact Details, Educational & Professional Information & Documents, Compensation details, Language Proficiency, Health & Medical Information, Litigation Information & Status, Conflict of Interest Declaration, Government / Political Connections / Affiliations, Immigration, right to work and residence status,
                    </span>
                    <br />
                    <br />
                    <ol type="i" className="p-0">
                        The above mentioned personal data (including Special Category Data & Sensitive Personal Data & Information) and /or non-personal data, as appliable (“data”)as defined under the applicable Data Privacy & Data Protection laws, statutes, rules & regulations including GDPR and Digital Personal Data Protection Act, 2023 is processed for the following purposes:
                        <br />
                        <br />
                        <li className="fw-bold">
                            Employment purposes & any other related & incidental matters. Employment purpose includes but is not limited to interview & on-boarding process; retirement & nomination, employee compensation & welfare, conducting due diligence, background checks & verification; recording of attendance & access control within the premises of Siemens' performance of assigned roles, functions & jobs; business contact with internal & external stakeholders; emergency contact; health & medical related purposes; statutory & internal audits; investigations; analysis, evaluation & assessment of monitoring of performance & behavior;
                        </li>
                        <br />
                        <li className="fw-bold">
                            To administer the functionality of the App & related services
                        </li>
                    </ol>
                    <br />
                    We only process your personal data if we are permitted by applicable law to do so. In particular, if you are based in the European Economic Area, the legal basis for Siemens processing data about you is that such processing is necessary for the purposes of:
                    <br />
                    <ol type="i" className="pl-1">
                        <li className="pl-1">
                            Siemens exercising its rights and performing its obligations in connection with any contract we make with you (Article 6 (1) (b) General Data Protection Regulation),
                        </li>
                        <li className="pl-1">
                            compliance with Siemens’ legal obligations (Article 6 (1) (c) General Data Protection Regulation), and/or
                        </li>
                        <li className="pl-1">
                            legitimate interests pursued by Siemens (Article 6 (1) (f) General Data Protection Regulation).
                        </li>
                        <li className="pl-1">
                            Siemens exercising its rights and performing its obligations in connection with legitimate uses as outlined in the Section 4 (Grounds for processing digital personal data) and Section 7 (certain legitimate interests) of India Digital Personal Data Protection Act, 2023
                        </li>
                    </ol>
                    Generally, the legitimate interest pursued by Siemens in relation to our use of your personal data is the efficient operation, management and support of the App, but we may disclose other legitimate interests for specific uses.
                    <br />
                    <br />
                    In some cases, we may ask if you consent to the relevant use of your personal data. In such cases, the legal basis for Siemens processing that personal data is that you have consented (Article 6 (1) (a) General Data Protection Regulation).
                    <br />
                    <span className="fw-bold">
                        Your Rights
                    </span>
                    <br />
                    The data protection laws in the jurisdiction in which you work or reside may entitle you to specific rights in relation to your personal data. You can find out more about those rights in the Sections below, or by contacting <a href="dataprotection@siemens.com">dataprotection@siemens.com</a> or your contact person in the Siemens Data Privacy Organization.
                    <br />
                    In particular, and subject to the legal requirements, you may be entitled to
                    <ul>
                        <li className="pl-1">
                            Obtain from us confirmation as to whether or not personal data concerning you are being processed, and where that is the case, access to the personal data;
                        </li>
                        <li className="pl-1">
                            Obtain from us the correction of inaccurate personal data concerning you
                        </li>
                        <li className="pl-1">
                            Update any personal data concerning you
                        </li>
                        <li className="pl-1">
                            Obtain from us the erasure of your personal data
                        </li>
                        <li className="pl-1">
                            Obtain from us the correction of inaccurate personal data concerning you
                        </li>
                        <li className="pl-1">
                            Update any personal data concerning you
                        </li>
                        <li className="pl-1">
                            Obtain from us the erasure of your personal data
                        </li>
                        <li className="pl-1">
                            Obtain from us restriction of processing regarding your personal data
                        </li>
                        <li className="pl-1">
                            Data portability concerning personal data, which you actively provided
                        </li>
                        <li className="pl-1">
                            Object, on grounds relating to your particular situation, to further processing of personal data concerning you; and
                        </li>
                        <li className="pl-1">
                            Withdraw your consent to our processing of your personal data.
                        </li>
                    </ul>
                    <br />
                    <br />
                    <span className="fw-bold">
                        App permissions
                    </span>
                    <br />
                    <br />
                    The App does not access any functionalities in your device
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Push Notifications
                    </span>
                    <br />
                    <br />
                    The App does not make use of push notification services
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Location
                    </span>
                    <br />
                    <br />
                    The App does not collect and process information about the user’s location.
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Links
                    </span>
                    <br />
                    <br />
                    This App does not contain links to any other webpages, websites or URLs.
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Transfer and disclosure of personal data
                    </span>
                    <br />
                    <br />
                    Siemens may transfer or disclose your personal data to:
                    <br />
                    <ul>
                        <li className="pl-1">
                            Other Siemens companies in connection with your employment and internal communication and cooperation.
                        </li>
                        <li className="pl-1">
                            Other third parties in connection with your employment, (e.g. to attorneys and consultants, to benefit providers, to customers for whom you will be engaged in tasks)
                        </li>
                        <li className="pl-1">
                            Service providers (so-called data processors), e.g. hosting or IT maintenance service providers, cloud service providers, third party software providers or simply third-party service providers which only act upon instructions of Siemens and are contractually bound to act in compliance with applicable Data Privacy & Protection statutes, rules & regulations
                        </li>
                        <li className="pl-1">
                            Third parties in connection with complying with legal obligations or establishing, exercising or defending rights or claims (e.g. for court and arbitration proceedings, to law enforcement authorities and regulators, to attorneys and consultants)
                        </li>
                        <li className="pl-1">
                            A newly formed or acquiring organization if Siemens is involved in a merger, sale or a transfer of its businesses.
                        </li>
                    </ul>
                    If you are located within European Economic Area, please be aware that sometimes the recipients to whom Siemens transfers or discloses your personal data are located in countries in which applicable laws do not offer the same level of data protection as the laws of your home country.
                    <br />
                    <br />
                    In such cases and if required by applicable law, Siemens takes measures to implement appropriate and suitable safeguards for the protection of your personal data. In particular:
                    <br />
                    <ul className="pl-1">
                        <li className="pl-1">
                            We share your personal data with Siemens companies in such countries only if they have implemented the Siemens Binding Corporate Rules ("BCR") for the protection of personal data. Further information about the Siemens BCR can be found&nbsp;
                            <a href="https://www.siemens.com/corp/pool/en/siemens-bcr-summary-of-third-party-rights-en.pdf" target="_blank">here</a>.
                        </li>
                        <li className="pl-1">
                            We transfer personal data to external recipients in such countries only if the recipient has (i) entered into&nbsp;
                            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en" target="_blank">
                                EU Standard Contractual Clauses
                            </a>
                            &nbsp;with Siemens, (ii) implemented&nbsp;
                            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en" target="_blank">
                                Binding Corporate Rules
                            </a>
                            &nbsp;in its organization. You may request further information about the safeguards implemented in relation to specific transfers by contacting&nbsp;
                            <a href="mailto:dataprotection@siemens.com" target="_blank">
                                dataprotection@siemens.com
                            </a>.
                        </li>
                    </ul>
                    <br />
                    <br />
                    <span className="fw-bold">
                        Retention periods
                    </span>
                    <br />
                    <br />
                    Unless indicated otherwise at the time of the collection of your personal data (e.g., within a form completed by you), we erase your personal data if the retention of that personal data is no longer necessary (i) for the purposes for which they were collected or otherwise processed, or (ii) to comply with legal obligations. The data shall be automatically deleted after period of 6 months from the joining date of the employee.
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Do Not Track
                    </span>
                    <br />
                    <br />
                    At this time our Internet sites do not recognize or respond to “Do Not Track” browser signals.
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Security
                    </span>
                    <br />
                    <br />
                    To protect your personal data against accidental or unlawful destruction, loss, use, or alteration and against unauthorized disclosure or access, Siemens uses reasonable physical, technical and organizational security measures.  Unfortunately, there is no complete assurance of security in transit or storage to any information that you transmit, no complete assurance that information will not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or organizational measures.
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        Data Privacy Contact
                    </span>
                    <br />
                    <br />
                    The Siemens Data Privacy Organization provides support with any data privacy related questions, comments, concerns or complaints or in case you wish to exercise any of your data privacy related rights. The Siemens Data Privacy Organization may be contacted at:&nbsp;
                    <a href="mailto:dataprotection@siemens.com" target="_blank">
                        dataprotection@siemens.com
                    </a>.
                    <br />
                    <br />
                    The Siemens Data Privacy Organization will always use best efforts to address and settle any requests or complaints you bring to its attention. Besides contacting the Siemens Data Privacy Organization, you always have the right to approach the competent data protection authority with your request or complaint.
                    <br />
                    <br />
                    <br />
                    You may also contact the local Data Privacy Organization of Siemens India details of whom have been provided below:
                    <br />
                    <br />
                    <span className="fw-bold">
                        Data Privacy Officer
                    </span>
                    <br />
                    Mr.Sankar Balasubramanian
                    <br />
                    E-Mail:&nbsp;<a href="mailto:sankar.balasubramanian@siemens.com" target="_blank">sankar.balasubramanian@siemens.com</a>
                    <br />
                    <br />
                    <span className="fw-bold">
                        Data Privacy Manager & Advisor
                    </span>
                    <br />
                    Ms. Rema Ramnath
                    <br />
                    E-Mail:&nbsp;<a href="mailto:rema.ramnath@siemens.com" target="_blank">rema.ramnath@siemens.com</a>
                    <br />
                    <br />
                    <br />
                    <span className="fw-bold">
                        We may update this Privacy Notice from time to time. The date of the latest update is indicated at the top of this Privacy Notice.
                    </span>
                    <br />
                    <br />
                    <br />
                </Typography>
                {/*    <div id="donotprintdiv" className="d-flex jc-center mt-1 mb-2 no-print">*/}
                {/*        <button onClick={() => window.print()}>Print</button>*/}
                {/*    </div>*/}
                {/*    <Dialog*/}
                {/*        open={openLoader}*/}
                {/*        //onClose={handleClose}*/}
                {/*        aria-labelledby="draggable-dialog-title"*/}
                {/*        id="templateUploadSuccess"*/}
                {/*        PaperProps={{*/}
                {/*            style: {*/}
                {/*                backgroundColor: "transparent",*/}
                {/*                boxShadow: "none",*/}
                {/*                borderColor: "transparent"*/}
                {/*            },*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <DialogContent>*/}
                {/*            <CircularProgress />*/}
                {/*        </DialogContent>*/}
                {/*    </Dialog>*/}
            </div>
        </ThemeProvider>
    );

}
