import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        //MuiAutocomplete: {
        //    root: {
        //        background: "#E8F8F8",
        //        '&$disabled': {
        //            backgroundColor: '#454545 !important',
        //        },
        //        padding: "0 !important",
        //        height: "55px !important",
        //    },
        //},
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiDialogActions: {
            root: {
                background: "#000028",
            },
        },
        MuiDialogTitle: {
            root: {
                padding: "8px 24px",
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "0px solid #FFFFFF",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #FFFFFF",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #FFFFFF",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },

        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#000028",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#6a7272",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#000028",
            },
            container: {
                background: "#000028",
            },
            background: "#000028",
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: "#000028"
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
        MuiFormHelperText: {
            contained: {
                backgroundColor: "white",
                marginBottom: "-10px !important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important",
            },
        },
    },
});

export default function PersonalData(props) {
    var id, applicantId;
    const [personalDetailsId, setPersonalDetailsId] = useState("");
    const [buttonTextSave, setButtonTextSave] = useState("Save");
    const [requiredMarriedSince, setRequiredMarriedSince] = useState(false);
    const [requiredSpouse, setRequiredSpouse] = useState(false);
    const [requiredNumberofChildren, setRequiredNumberofChildren] =
        useState(false);
    const [disableMarriedSince, setDisableMarriedSince] = useState(true);
    const [disableNoOfChildren, setDisableNoOfChildren] = useState(true);
    const [title, setTitle] = useState("");
    const [titleObj, setTitleObj] = useState("");
    const [titleText, setTitleText] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [initial, setInitial] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [gender, setGender] = useState("");
    const [genderObj, setGenderObj] = useState("");
    const [genderText, setGenderText] = useState("");
    const [countryOfBirth, setCounrtyOfBirth] = useState("");
    const [birthCountry, setBirthCountry] = useState("");
    const [birthCountryText, setBirthCountryText] = useState("");
    const [nationality, setNationality] = useState("");
    const [nationalityObj, setNationalityObj] = useState("");
    const [nationalityText, setNationalityText] = useState("");
    const [stateOfBirth, setStateOfBirth] = useState("");
    const [birthState, setBirthState] = useState("");
    const [birthStateText, setBirthStateText] = useState("");
    const [cityOfBirth, setCityOfBirth] = useState("");
    const [religion, setReligion] = useState("");
    const [religionObj, setReligionObj] = useState("");
    const [religionText, setReligionText] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [maritalStatusObj, setMaritalStatusObj] = useState("");
    const [maritalStatusText, setMaritalStatusText] = useState("");
    const [marriedSince, setMarriedSince] = useState(null);
    const [noOfChildren, setNoOfChildern] = useState("");
    const [fatherName, setFatherName] = useState("");
    const [spouseName, setSpouseName] = useState("");
    const [spouseDisable, setSpouseDisable] = useState(true);
    const [businessTitle, setBusinessTitle] = useState("");
    const [businessTitleObj, setBusinessTitleObj] = useState("");
    const [businessTitleText, setBusinessTitleText] = useState("");

    //Error States and Msg
    const [errorFirstName, setErrorFirstName] = useState(false);
    const [errorTextFirstName, setErrorTextFirstName] = useState("");
    const [errorLastName, setErrorLastName] = useState(false);
    const [errorTextLastName, setErrorTextLastName] = useState("");
    const [errorDOB, setErrorDOB] = useState(false);
    const [errorTextDOB, setErrorTextDOB] = useState("");
    const [errorGender, setErrorGender] = useState(false);
    const [errorTextGender, setErrorTextGender] = useState("");
    const [errorCountryofBirth, setErrorCountryofBirth] = useState(false);
    const [errorTextCountryofBirth, setErrorTextCountryofBirth] = useState("");
    const [errorStateofBirth, setErrorStateofBirth] = useState(false);
    const [errorTextStateofBirth, setErrorTextStateofBirth] = useState("");
    const [errorCityofBirth, setErrorCityofBirth] = useState(false);
    const [errorTextCityofBirth, setErrorTextCityofBirth] = useState("");
    const [errorNationality, setErrorNationality] = useState(false);
    const [errorTextNationality, setErrorTextNationality] = useState("");
    const [errorReligion, setErrorReligion] = useState(false);
    const [errorTextReligion, setErrorTextReligion] = useState("");
    const [errorFatherName, setErrorFatherName] = useState(false);
    const [errorTextFatherName, setErrorTextFatherName] = useState("");
    const [errorMarital, setErrorMarital] = useState(false);
    const [errorTextMarital, setErrorTextMarital] = useState("");
    const [errorSpouseName, setErrorSpouseName] = useState(false);
    const [errorTextSpouseName, setErrorTextSpouseName] = useState("");
    const [errorMarriedSince, setErrorMarriedSince] = useState(false);
    const [errorTextMarriedSince, setErrorTextMarriedSince] = useState("");
    const [errorNumberofChildren, setErrorNumberofChildren] = useState(false);
    const [errorTextNumberofChildren, setErrorTextNumberofChildren] =
        useState("");

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const [isLoader, setIsLoader] = useState(false);

    function dateChangeEvent(dateValue, label) {
        if (label === "Date Of Birth") {
            setDateOfBirth(dateValue);
        } else if (label === "Married Since") {
            setMarriedSince(dateValue);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        clearStates();
        setOpenConfirm(false);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleChangeTitle = (event, newValue) => {
        setTitleObj(newValue);
        setTitle(newValue ? newValue.id : 0);
    };
    const handleChangeTitleInput = (event, newInputValue) => {
        setTitleText(newInputValue);
    };

    const handleChangeBusinessTitle = (event, newValue) => {
        setBusinessTitleObj(newValue);
        setBusinessTitle(newValue ? newValue.id : 0);
    };
    const handleChangeBusinessTitleInput = (event, newInputValue) => {
        setBusinessTitleText(newInputValue);
    };
    const handleChangeGender = (event, newValue) => {
        setGenderObj(newValue);
        setGender(newValue ? newValue.id : 0);
    };
    const handleChangeGenderInput = (event, newInputValue) => {
        setGenderText(newInputValue);
    };
    const handleChangeReligion = (event, newValue) => {
        setReligionObj(newValue);
        setReligion(newValue ? newValue.id : 0);
    };
    const handleChangeReligionInput = (event, newInputValue) => {
        setReligionText(newInputValue);
    };
    const handleChangeCountry = (event, newValue) => {
        setBirthCountry(newValue);
        setCounrtyOfBirth(newValue ? newValue.id : 0);
        id = newValue == undefined ? 0 : newValue.id;
        getStates(id);
        setBirthState("");
        setBirthStateText("");
        setStateOfBirth("");
    };
    const handleChangeContryInput = (event, newInputValue) => {
        setBirthCountryText(newInputValue);
        setBirthState("");
        setBirthStateText("");
        setStateOfBirth("");
    };
    const handleChangeState = (event, newValue) => {
        setBirthState(newValue);
        setStateOfBirth(newValue ? newValue.id : 0);
    };
    const handleChangeStateInput = (event, newValueInput) => {
        setBirthStateText(newValueInput);
    };
    const handleChangeNationality = (event, newValue) => {
        setNationalityObj(newValue);
        setNationality(newValue ? newValue.id : 0);
    };
    const handleChangeNationalitInput = (event, newValueInput) => {
        setNationalityText(newValueInput);
    };

    const handleChangeMaritalStatus = (event, newValue) => {
        setMaritalStatusObj(newValue);
        setMaritalStatus(newValue ? newValue.id : 0);
        if (newValue) {
            if (newValue.id != 2 && newValue.id != 6) {
                setDisableNoOfChildren(false);
                setRequiredNumberofChildren(true);
                if (newValue.id != 3 && newValue.id != 7) {
                    if (newValue.id == 1) {
                        setSpouseDisable(false);
                        setRequiredSpouse(true);
                        setDisableMarriedSince(false);
                        setRequiredMarriedSince(true);
                    } else if (newValue.id == 4) {
                        setSpouseDisable(true);
                        setRequiredSpouse(false);
                        setSpouseName("");
                        setDisableMarriedSince(true);
                        setRequiredMarriedSince(false);
                    } else if (newValue.id == 5) {
                        setDisableNoOfChildren(true);
                        setRequiredNumberofChildren(false);
                        setNoOfChildern(0);
                        setSpouseDisable(false);
                        setRequiredSpouse(true);
                        setDisableMarriedSince(true);
                        setRequiredMarriedSince(false);
                        setMarriedSince(null);
                    } else {
                        setSpouseDisable(true);
                        setRequiredSpouse(false);
                        setDisableMarriedSince(true);
                        setRequiredMarriedSince(false);
                    }
                } else {
                    setDisableMarriedSince(true);
                    setRequiredMarriedSince(false);
                    setMarriedSince(null);
                    setSpouseDisable(true);
                    setRequiredSpouse(false);
                    setSpouseName("");
                }
            } else {
                setSpouseName("");
                setMarriedSince(null);
                setNoOfChildern(0);
                setDisableMarriedSince(true);
                setRequiredMarriedSince(false);
                setSpouseDisable(true);
                setRequiredSpouse(false);
                setDisableNoOfChildren(true);
                setRequiredNumberofChildren(false);
            }
        } else {
            setSpouseName("");
            setMarriedSince(null);
            setNoOfChildern(0);
            setDisableMarriedSince(true);
            setRequiredMarriedSince(false);
            setSpouseDisable(true);
            setRequiredSpouse(false);
            setDisableNoOfChildren(true);
            setRequiredNumberofChildren(false);
        }
    };
    const handleChangeMaritalStatusInput = (event, newValueInput) => {
        setMaritalStatusText(newValueInput);
    };

    const handleKeypress = (e) => {
        const characterCode = e.key;
        if (characterCode === "Backspace") return;

        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            }
        } else {
            e.preventDefault();
        }
    };

    const handleChangeNoOfChlidren = (event) => {
        //if (event.target.value.includes("-")) {
        //    setOpen(true);
        //    setOpenAlert("Please enter valid Number of Children");
        //    setClassName("clr-dark-blue bg-success");
        //    setOpenType("Alert");
        //}
        if (event.target.value.includes("-")) {
            event.preventDefault();
        } else {
            setNoOfChildern(event.target.value);
        }
    };
    //const handleChangeCountry = (event) => {
    //    setCounrtyOfBirth(event.target.value);
    //    id = event.target.value;
    //    getStates(id);
    //};
    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };
    const handleChangeMiddleName = (event) => {
        setMiddleName(event.target.value);
    };
    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };
    const handleChangeInitial = (event) => {
        setInitial(event.target.value);
    };
    const handleChangeCityOfBirth = (event) => {
        setCityOfBirth(event.target.value);
    };
    const handleChangeFatherName = (event) => {
        setFatherName(event.target.value);
    };
    const handleChangeSpouseName = (event) => {
        setSpouseName(event.target.value);
    };
    var formDetails = {
        PersonalDetailsId: personalDetailsId,
        UserId: props.userId,
        ApplicantId: props.id,
        SourceId: null,
        FirstName: firstName,
        MiddleName: middleName,
        LastName: lastName,
        RegistrationNo: null,
        PostAppliedFor: null,
        TitleId: title,
        Initial: initial,
        DateOfBirth: dateOfBirth,
        GenderId: gender,
        CountryIdofBirth: countryOfBirth,
        FatherName: fatherName,
        StateIdofBirth: stateOfBirth,
        CityOfBirth: cityOfBirth,
        MaritalStatusId: maritalStatus,
        MarriedSince: marriedSince,
        SpouseName: spouseName,
        ReligionId: religion,
        NationalityId: nationality,
        NumberOfChildren: noOfChildren,
        BusinessTitleId: businessTitle,
    };

    const submitHandler = (event) => {
        setIsLoader(true);
        formDetails.ApplicantId = props.id;
        formDetails.CityOfBirth = cityOfBirth;
        formDetails.CountryIdofBirth = countryOfBirth;
        formDetails.DateOfBirth = dateOfBirth;
        formDetails.FatherName = fatherName;
        formDetails.FirstName = firstName;
        formDetails.MiddleName = middleName;
        formDetails.LastName = lastName;
        formDetails.GenderId = gender;
        formDetails.Initial = initial;
        formDetails.MaritalStatusId = maritalStatus;
        formDetails.MarriedSince = marriedSince;
        formDetails.NationalityId = nationality;
        formDetails.PersonalDetailsId = personalDetailsId;
        formDetails.ReligionId = religion;
        formDetails.SpouseName = spouseName;
        formDetails.StateIdofBirth = stateOfBirth;
        formDetails.TitleId = title;
        formDetails.UserId = props.userId;
        formDetails.NumberOfChildren = noOfChildren;
        formDetails.BusinessTitleId = businessTitle;
        formDetails.UserIdencrypted = props.loginDetail[0].userEncryptedId;

        if (ValidateFormFields(formDetails)) {
            if (
                personalDetailsId &&
                personalDetailsId > 0
            ) {
                UpdatePersonalData(formDetails);
            } else {
                SavePersonalData(formDetails);
                //console.log(formDetails);
            }
        } else {
            setIsLoader(false);
            setOpen(true);
            setOpenAlert(
                "Form validation unsuccessful. Please check the error messages."
            );
            setClassName("clr-white bg-alert");
            setOpenType("Alert");
        }
    };

    //Validation
    function ValidateFormFields(data) {
        var a = false,
            b = false,
            c = false,
            d = false,
            e = false,
            f = false,
            g = false,
            h = false,
            i = false,
            j = false,
            k = false,
            l = false,
            m = false,
            n = false;
        if (data.FirstName == null || data.FirstName == "") {
            setErrorFirstName(true);
            setErrorTextFirstName("Mandatory field");
            a = true;
        } else {
            setErrorFirstName(false);
            setErrorTextFirstName("");
            a = false;
        }
        if (data.LastName == null || data.LastName == "") {
            setErrorLastName(true);
            setErrorTextLastName("Mandatory field");
            b = true;
        } else {
            setErrorLastName(false);
            setErrorTextLastName("");
            b = false;
        }
        if (data.DateOfBirth == null || data.DateOfBirth == "") {
            setErrorDOB(true);
            setErrorTextDOB("Mandatory field");
            c = true;
        } else {
            setErrorDOB(false);
            setErrorTextDOB("");
            c = false;
        }
        if (data.GenderId == null || data.GenderId == "") {
            setErrorGender(true);
            setErrorTextGender("Mandatory field");
            d = true;
        } else {
            setErrorGender(false);
            setErrorTextGender("");
            d = false;
        }
        if (data.CountryIdofBirth == null || data.CountryIdofBirth == "") {
            setErrorCountryofBirth(true);
            setErrorTextCountryofBirth("Mandatory field");
            e = true;
        } else {
            setErrorCountryofBirth(false);
            setErrorTextCountryofBirth("");
            e = false;
        }
        //if ((data.countryIdofBirth == null || data.countryIdofBirth == '') && (data.StateIdofBirth == null && data.StateIdofBirth == '')) {
        //    setErrorStateofBirth(true);
        //    setErrorTextStateofBirth("Mandatory field");
        //    f = true;
        //}
        //else {
        //    setErrorStateofBirth(false);
        //    setErrorTextStateofBirth("");
        //    f = false;
        //}

        if (data.CountryIdofBirth != null && data.CountryIdofBirth != "") {
            if (data.CountryIdofBirth == 94) {
                if (data.StateIdofBirth == null || data.StateIdofBirth == "") {
                    setErrorStateofBirth(true);
                    setErrorTextStateofBirth("Mandatory field");
                    f = true;
                } else {
                    setErrorStateofBirth(false);
                    setErrorTextStateofBirth("");
                    f = false;
                }
            } else {
                setErrorStateofBirth(false);
                setErrorTextStateofBirth("");
                f = false;
            }
        } else {
            setErrorStateofBirth(false);
            setErrorTextStateofBirth("");
            f = false;
        }

        if (data.CityOfBirth == null || data.CityOfBirth == "") {
            setErrorCityofBirth(true);
            setErrorTextCityofBirth("Mandatory field");
            g = true;
        } else {
            setErrorCityofBirth(false);
            setErrorTextCityofBirth("");
            g = false;
        }
        if (data.NationalityId == null || data.NationalityId == "") {
            setErrorNationality(true);
            setErrorTextNationality("Mandatory field");
            h = true;
        } else {
            setErrorNationality(false);
            setErrorTextNationality("");
            h = false;
        }
        //if (data.ReligionId == null || data.ReligionId == '') {
        //    setErrorReligion(true);
        //    setErrorTextReligion("Mandatory field");
        //    i = true;
        //}
        //else {
        //    setErrorReligion(false);
        //    setErrorTextReligion("");
        //    i = false;
        //}
        if (data.FatherName == null || data.FatherName == "") {
            setErrorFatherName(true);
            setErrorTextFatherName("Mandatory field");
            j = true;
        } else {
            setErrorFatherName(false);
            setErrorTextFatherName("");
            j = false;
        }
        if (data.MaritalStatusId != null && data.MaritalStatusId != "") {
            setErrorMarital(false);
            setErrorTextMarital("");
            k = false;
        } else {
            setErrorMarital(true);
            setErrorTextMarital("Mandatory field");
            k = true;
        }
        if (
            data.MaritalStatusId != 2 &&
            data.MaritalStatusId != 6 &&
            data.MaritalStatusId != null &&
            data.MaritalStatusId != ""
        ) {
            if (data.MaritalStatusId != 3 && data.MaritalStatusId != 7) {
                if (data.MaritalStatusId == 1) {
                    if (data.SpouseName == null || data.SpouseName == "") {
                        setErrorSpouseName(true);
                        setErrorTextSpouseName("Mandatory field");
                        l = true;
                    } else {
                        setErrorSpouseName(false);
                        setErrorTextSpouseName("");
                        l = false;
                    }
                    if (data.MarriedSince == null || data.MarriedSince == "") {
                        setErrorMarriedSince(true);
                        setErrorTextMarriedSince("Mandatory field");
                        m = true;
                    } else {
                        setErrorMarriedSince(false);
                        setErrorTextMarriedSince("");
                        m = false;
                    }
                    if (data.NumberOfChildren === null || data.NumberOfChildren === "") {
                        setErrorNumberofChildren(true);
                        setErrorTextNumberofChildren("Mandatory field");
                        n = true;
                    } else {
                        setErrorNumberofChildren(false);
                        setErrorTextNumberofChildren("");
                        n = false;
                    }
                } else if (data.MaritalStatusId == 4) {
                    setErrorSpouseName(false);
                    setErrorTextSpouseName("");
                    l = false;

                    setErrorMarriedSince(false);
                    setErrorTextMarriedSince("");
                    m = false;

                    if (data.NumberOfChildren === null || data.NumberOfChildren === "") {
                        setErrorNumberofChildren(true);
                        setErrorTextNumberofChildren("Mandatory field");
                        n = true;
                    } else {
                        setErrorNumberofChildren(false);
                        setErrorTextNumberofChildren("");
                        n = false;
                    }
                } else if (data.MaritalStatusId == 5) {
                    if (data.SpouseName == null || data.SpouseName == "") {
                        setErrorSpouseName(true);
                        setErrorTextSpouseName("Mandatory field");
                        l = true;
                    } else {
                        setErrorSpouseName(false);
                        setErrorTextSpouseName("");
                        l = false;
                    }
                    setErrorMarriedSince(false);
                    setErrorTextMarriedSince("");
                    m = false;
                    setErrorNumberofChildren(false);
                    setErrorTextNumberofChildren("");
                    n = false;
                }
            } else {
                setErrorSpouseName(false);
                setErrorTextSpouseName("");
                l = false;
                setErrorMarriedSince(false);
                setErrorTextMarriedSince("");
                m = false;

                if (data.NumberOfChildren === null || data.NumberOfChildren === "") {
                    setErrorNumberofChildren(true);
                    setErrorTextNumberofChildren("Mandatory field");
                    n = true;
                } else {
                    setErrorNumberofChildren(false);
                    setErrorTextNumberofChildren("");
                    n = false;
                }
            }
        } else {
            setErrorSpouseName(false);
            setErrorTextSpouseName("");
            l = false;
            setErrorMarriedSince(false);
            setErrorTextMarriedSince("");
            m = false;
            setErrorNumberofChildren(false);
            setErrorTextNumberofChildren("");
            n = false;
        }

        if (a || b || c || d || e || f || g || h || i || j || k || l || m || n) {
            return false;
        } else {
            return true;
        }
    }

    async function SavePersonalData(formDetails) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formDetails),
        };
        await fetch("PreHiringForm/SavePersonalData/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    //alert(data.responseMsg);
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    async function UpdatePersonalData(formDetails) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formDetails),
        };
        await fetch("PreHiringForm/UpdatePersonalData/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    // alert(data.responseMsg);
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                    //alert(data.responseMsg);
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    const clearHandler = () => {
        setOpenConfirm(true);
        setOpenAlertConfirm("Do you want to clear all the above fields?");
        setClassName("clr-dark-blue bg-alert");
        setOpenType("Confirmation");

        //var x = window.confirm('Do you want to clear all the above fields?');
        //if (x) {
        //clearStates();
        //}
    };

    function clearStates() {
        setFatherName("");
        setCityOfBirth("");
        setCounrtyOfBirth("");
        setBirthCountry("");
        setBirthCountryText("");
        getStates(0);
        setStateOfBirth("");
        setBirthState("");
        setBirthStateText("");
        setDateOfBirth(null);
        setFirstName("");
        setGender("");
        setGenderObj("");
        setGenderText("");
        setInitial("");
        setLastName("");
        setMaritalStatus("");
        setMaritalStatusObj("");
        setMaritalStatusText("");
        setMiddleName("");
        setNationality("");
        setNationalityObj("");
        setNationalityText("");
        setTitle("");
        setTitleObj("");
        setTitleText("");
        setReligion("");
        setReligionObj("");
        setReligionText("");
        setLastName("");
        setSpouseName("");
        setMarriedSince(null);
        setNoOfChildern(0);
        setDisableMarriedSince(true);
        setSpouseDisable(true);
        setDisableNoOfChildren(true);
        setBusinessTitle("");
        setBusinessTitleObj("");
        setBusinessTitleText("");
    }

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: "100%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
    }));
    const classes = useStyles();

    //fetch Titles
    const [itemsTitle, setItemsTitle] = useState([]);
    async function getTitles() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllTitlesPersonalData", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsTitle(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsTitle(jsonItems);
    }

    //fetch Business Titles
    const [itemsBusinessTitle, setItemsBusinessTitle] = useState([]);
    async function getBusinessTitles() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllTitles", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsTitle(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsBusinessTitle(jsonItems);
    }

    //fetch Nationalities
    const [itemsNationality, setItemsNationality] = useState([]);
    async function getNationalities() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllNationalities", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsNationality(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsNationality(jsonItems);
    }

    //fetch Genders
    const [itemsGender, setItemsGender] = useState([]);
    async function getGenders() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllGenders", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsGender(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsGender(jsonItems);
    }

    //fetch Religions
    const [itemsReligion, setItemsReligion] = useState([]);
    async function getReligions() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllReligions", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsReligion(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsReligion(jsonItems);
    }

    //fetch Marital Status
    const [itemsMaritalStatus, setItemsMaritalStatus] = useState([]);
    async function getMaritalStatus() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllMaritalStatus", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsMaritalStatus(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsMaritalStatus(jsonItems);
    }

    //fetch Countries
    const [itemCountries, setItemCountries] = useState([""]);
    async function getCountries() {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllCountries", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemCountries(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemCountries(jsonItems);
    }

    //fetch States
    const [itemStates, setItemStates] = useState([]);
    async function getStates(id) {
        const token = props.token;
        const response = await fetch("PreHiringForm/GetAllStates/" + id, {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemStates(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemStates(jsonItems);
    }

    //Change Button Text
    function getButtonTextSave(personalDetailsId) {
        personalDetailsId != null && personalDetailsId > 0
            ? setButtonTextSave("Update")
            : setButtonTextSave(buttonTextSave);
    }

    //Set All States
    function SetStateAsPerFetched(data) {
        setCityOfBirth(data.cityOfBirth);
        setCounrtyOfBirth(data.countryIdofBirth);
        if (data.coutries[0].id > 0) {
            setBirthCountry(data.coutries[0]);
            setBirthCountryText(data.coutries[0].name);
        }
        setFatherName(data.fatherName);
        getStates(data.countryIdofBirth);
        setStateOfBirth(data.stateIdofBirth);
        if (data.states[0].id > 0) {
            setBirthState(data.states[0]);
            setBirthStateText(data.states[0].name);
        }
        setDateOfBirth(data.dateOfBirth);
        setFirstName(data.firstName);
        setGender(data.genderId);
        if (data.genders[0].id > 0) {
            setGenderObj(data.genders[0]);
            setGenderText(data.genders[0].name);
        }
        setInitial(data.initial);
        setLastName(data.lastName);
        setMaritalStatus(data.maritalStatusId);
        if (data.maritalStatuses[0].id > 0) {
            setMaritalStatusObj(data.maritalStatuses[0]);
            setMaritalStatusText(data.maritalStatuses[0].name);
        }
        if (props.disableValue) {
            setSpouseDisable(props.disableValue);
            setDisableNoOfChildren(props.disableValue);
            setDisableMarriedSince(props.disableValue);
        } else {
            if (
                data.maritalStatusId != 2 &&
                data.maritalStatusId != 6 &&
                data.maritalStatusId != null &&
                data.maritalStatusId != ""
            ) {
                setDisableNoOfChildren(false);
                setRequiredNumberofChildren(true);
                if (data.maritalStatusId != 3 && data.maritalStatusId != 7) {
                    if (data.maritalStatusId == 1) {
                        setSpouseDisable(false);
                        setRequiredSpouse(true);
                        setDisableMarriedSince(false);
                        setRequiredMarriedSince(true);
                    } else if (data.maritalStatusId == 4) {
                        setSpouseDisable(true);
                        setRequiredSpouse(false);
                        setDisableMarriedSince(true);
                        setRequiredMarriedSince(false);
                    } else if (data.maritalStatusId == 5) {
                        setDisableNoOfChildren(true);
                        setRequiredNumberofChildren(false);
                        setSpouseDisable(false);
                        setRequiredSpouse(true);
                        setDisableMarriedSince(true);
                        setRequiredMarriedSince(false);
                    }
                } else {
                    setDisableMarriedSince(true);
                    setRequiredMarriedSince(false);
                }
            } else {
                setSpouseName("");
                setMarriedSince(null);
                setNoOfChildern(0);
                setDisableMarriedSince(true);
                setRequiredMarriedSince(false);
                setSpouseDisable(true);
                setRequiredSpouse(false);
                setDisableNoOfChildren(true);
                setRequiredNumberofChildren(false);
            }
        }

        setMarriedSince(data.marriedSince);
        setSpouseName(data.spouseName);
        setMiddleName(data.middleName);
        setNationality(data.nationalityId);
        if (data.nationalities[0].id > 0) {
            setNationalityObj(data.nationalities[0]);
            setNationalityText(data.nationalities[0].name);
        }
        setPersonalDetailsId(data.personalDetailsId);
        setTitle(data.titleId);
        if (data.titles[0].id > 0) {
            setTitleObj(data.titles[0]);
            setTitleText(data.titles[0].name);
        }
        setReligion(data.religionId);
        if (data.religions[0].id > 0) {
            setReligionObj(data.religions[0]);
            setReligionText(data.religions[0].name);
        } else {
            setReligion(1);
            setReligionObj({ id: 1, name: "Do not wish to disclose" });
            setReligionText("Do not wish to disclose");
        }
        setLastName(data.lastName);
        setNoOfChildern(data.numberOfChildren);
        setBusinessTitle(data.businessTitleId);
        if (data.businessTitles[0].id > 0) {
            setBusinessTitleObj(data.businessTitles[0]);
            setBusinessTitleText(data.businessTitles[0].name);
        }
        setIsLoader(false);
    }

    //Get PersonalData On Page Load
    function getPersonalDataOnLoad(applicantId) {
        setIsLoader(true);
        const token = props.token;
        id = applicantId;
        fetch("PreHiringForm/GetPersonalData/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    useEffect(() => {
        if (props.token) {
            getTitles();
            getBusinessTitles();
            getNationalities();
            getGenders();
            getReligions();
            getMaritalStatus();
            getCountries();
            getStates(id);

            //if (props.id != undefined && props.id > 0) {
            if (props.id != undefined && props.id != "") {
                getPersonalDataOnLoad(props.id);
            }
        }
        if (props.disableValue) {
            setSpouseDisable(props.disableValue);
            setDisableNoOfChildren(props.disableValue);
            setDisableMarriedSince(props.disableValue);
        }
    }, [props.id, personalDetailsId, props.disableValue, props.token]);

    useEffect(() => {
        getButtonTextSave(personalDetailsId);
    }, [personalDetailsId])

    return (
        <ThemeProvider theme={theme}>
            <div className="width-100">
                <form className="d-flex jc-center">
                    <Grid container spacing={1} className="d-flex fd-column ">
                        <table>
                            <tbody>
                                <tr className="d-flex jc-space-bt f-12 font-italic mt-1">
                                    <td></td>
                                    <td className="clr-dark-blue">
                                        {/***Note : Please provide GID on <span className="fw-bold">In Company Manager</span> text field*/}
                                    </td>
                                    <td className="clr-red fw-bold">
                                        *Please note : Name and Date of birth should be as per Aadhar Card
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            {
                                //<Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                //</Grid>
                                //<Grid item xs={12} sm={4} className="d-flex  Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Title</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={title}
                                //            onChange={handleChangeTitle}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsTitle.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={titleObj}
                                    inputValue={titleText}
                                    options={itemsTitle}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeTitle}
                                    onInputChange={handleChangeTitleInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Title"
                                            variant="filled"
                                        //required
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="firstName"
                                    fullWidth
                                    label="First Name"
                                    variant="filled"
                                    name="First Name"
                                    value={firstName}
                                    onChange={handleChangeFirstName}
                                    disabled={props.disableValue}
                                    // required
                                    error={errorFirstName}
                                    helperText={errorTextFirstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="middleName"
                                    fullWidth
                                    label="Middle Name"
                                    variant="filled"
                                    name="Middle Name"
                                    value={middleName}
                                    onChange={handleChangeMiddleName}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="lastName"
                                    fullWidth
                                    label="Last Name"
                                    variant="filled"
                                    name="Last Name"
                                    value={lastName}
                                    onChange={handleChangeLastName}
                                    disabled={props.disableValue}
                                    // required
                                    error={errorLastName}
                                    helperText={errorTextLastName}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={3}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={businessTitleObj}
                                    inputValue={businessTitleText}
                                    options={itemsBusinessTitle}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeBusinessTitle}
                                    onInputChange={handleChangeBusinessTitleInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Business Title"
                                            variant="filled"
                                        //required
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                //</Grid>
                            }

                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="initial"
                                    label="Initial"
                                    variant="filled"
                                    fullWidth
                                    name="Initial"
                                    value={initial}
                                    onChange={handleChangeInitial}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="Enter DOB*"
                                    label="Date Of Birth"
                                    value={dateOfBirth}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={true}
                                    disablepastdate={false}
                                    disabled={props.disableValue}
                                    required={true}
                                    error={errorDOB}
                                    helperText={errorTextDOB}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Gender</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={gender}
                                //            onChange={handleChangeGender}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsGender.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={genderObj}
                                    inputValue={genderText}
                                    options={itemsGender}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeGender}
                                    onInputChange={handleChangeGenderInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Gender"
                                            variant="filled"
                                            required
                                            error={errorGender}
                                            helperText={errorTextGender}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Country Of Birth</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={countryOfBirth}
                                //            onChange={handleChangeCountry}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemCountries.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={birthCountry}
                                    inputValue={birthCountryText}
                                    options={itemCountries}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeCountry}
                                    onInputChange={handleChangeContryInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country Of Birth"
                                            variant="filled"
                                            required
                                            error={errorCountryofBirth}
                                            helperText={errorTextCountryofBirth}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>State Of Birth</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={stateOfBirth}
                                //            onChange={handleChangeState}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemStates.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={birthState}
                                    inputValue={birthStateText}
                                    options={itemStates}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    noOptionsText={
                                        countryOfBirth && countryOfBirth != 94
                                            ? "No options - Skip"
                                            : "No options"
                                    }
                                    onChange={handleChangeState}
                                    onInputChange={handleChangeStateInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="State Of Birth"
                                            variant="filled"
                                            required={
                                                countryOfBirth && countryOfBirth == 94 ? true : false
                                            }
                                            error={errorStateofBirth}
                                            helperText={errorTextStateofBirth}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="cityOfBirth"
                                    label="City Of Birth"
                                    variant="filled"
                                    fullWidth
                                    name="City Of Birth"
                                    value={cityOfBirth}
                                    onChange={handleChangeCityOfBirth}
                                    disabled={props.disableValue}
                                    error={errorCityofBirth}
                                    helperText={errorTextCityofBirth}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={nationality}
                                //            onChange={handleChangeNationality}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsNationality.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={nationalityObj}
                                    inputValue={nationalityText}
                                    options={itemsNationality}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeNationality}
                                    onInputChange={handleChangeNationalitInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Nationality"
                                            variant="filled"
                                            required
                                            error={errorNationality}
                                            helperText={errorTextNationality}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Religion</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={religion}
                                //            onChange={handleChangeReligion}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsReligion.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={religionObj}
                                    inputValue={religionText}
                                    options={itemsReligion}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeReligion}
                                    onInputChange={handleChangeReligionInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Religion"
                                            variant="filled"
                                        //required
                                        //error={errorReligion}
                                        //helperText={errorTextReligion}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="fatherName"
                                    fullWidth
                                    required={true}
                                    label="Father's Name"
                                    variant="filled"
                                    name="Father's Name"
                                    value={fatherName}
                                    onChange={handleChangeFatherName}
                                    disabled={props.disableValue}
                                    error={errorFatherName}
                                    helperText={errorTextFatherName}
                                />
                            </Grid>
                            {
                                //<Grid item xs={12} sm={4} className="d-flex Width-100 mt-1">
                                //    <FormControl variant="filled" className={classes.formControl}>
                                //        <InputLabel id="demo-simple-select-label" required={true}>Marital Status</InputLabel>
                                //        <Select className="lightInputFields"
                                //            labelId="demo-simple-select-label"
                                //            id="demo-simple-select"
                                //            value={maritalStatus}
                                //            onChange={handleChangeMaritalStatus}
                                //            disabled={props.disableValue}
                                //        >
                                //            {itemsMaritalStatus.map(item => (
                                //                <MenuItem
                                //                    key={item.value}
                                //                    value={item.value}
                                //                >
                                //                    {item.label}
                                //                </MenuItem>)
                                //            )}
                                //        </Select>
                                //    </FormControl>
                                //</Grid>
                            }
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={maritalStatusObj}
                                    inputValue={maritalStatusText}
                                    options={itemsMaritalStatus}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeMaritalStatus}
                                    onInputChange={handleChangeMaritalStatusInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Marital Status"
                                            variant="filled"
                                            required
                                            error={errorMarital}
                                            helperText={errorTextMarital}
                                        />
                                    )}
                                    disabled={props.disableValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="husbandName"
                                    fullWidth
                                    disabled={spouseDisable}
                                    label="Spouse Name"
                                    variant="filled"
                                    name="Spouse Name"
                                    value={spouseName}
                                    onChange={handleChangeSpouseName}
                                    required={requiredSpouse}
                                    error={errorSpouseName}
                                    helperText={errorTextSpouseName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex Width-100">
                                <FormDatePicker
                                    placeholder="Enter Married Since"
                                    label="Married Since"
                                    value={marriedSince}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={true}
                                    disablepastdate={false}
                                    disabled={disableMarriedSince}
                                    required={requiredMarriedSince}
                                    error={errorMarriedSince}
                                    helperText={errorTextMarriedSince}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} className="d-flex Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    id="noOfChildren"
                                    label="No Of Children"
                                    variant="filled"
                                    fullWidth
                                    type="number"
                                    name="No Of Children"
                                    value={noOfChildren}
                                    onKeyDown={handleKeypress}
                                    onChange={handleChangeNoOfChlidren}
                                    required={requiredNumberofChildren}
                                    disabled={disableNoOfChildren}
                                    error={errorNumberofChildren}
                                    helperText={errorTextNumberofChildren}
                                />
                            </Grid>
                        </Grid>

                        <div className="d-flex jc-center">
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2"
                                disabled={props.disableValue}
                                onClick={clearHandler}
                            >
                                <span>Clear</span>
                            </Button>
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2 ml-2"
                                disabled={props.disableValue}
                                onClick={submitHandler}
                            >
                                <span>{buttonTextSave}</span>
                            </Button>
                        </div>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-content">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadClear"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlertConfirm}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-content">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">Cancel</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? <Loader /> : <></>}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
