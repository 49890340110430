import React, { useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const theme = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class-
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    MuiTextField: {
      root: {
        color: "#002949",
        background: "#E8F8F8",
        width: "100%",
        //added
        height: "55px !important",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    //MuiPickersBasePicker: {
    //    pickerView: {
    //        background: "#000028"
    //    },
    //    background: "#000028"
    //},
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersMonth: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      root: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

function FormDatePicker(props) {
  //const [dateOfBirth, handleChangeDOB] = useState(new Date());
  //const [selectedDate, handleDateChange] = useState(new Date());
  //console.log(props);
  //const dateChangeEvent = date => {
  //    const inputDate = date.format();
  //    props.dateChangeEvent(inputDate);
  //}
  function dateChangeEvent(date) {
    props.onChange(date, props.label);
  }
  function DisableBeforeAndSpecific(
    date,
    disableBefore,
    disableAfter,
    isSpecificDateDisable
  ) {
    if (disableBefore || disableAfter || isSpecificDateDisable) {
      if (disableBefore) {
        const saidDate = new Date(disableBefore);
        if (date < saidDate) {
          return date < saidDate;
        }
      }
      if (disableAfter) {
        const saidDate = new Date(disableAfter);
        return date > saidDate;
      }
      if (isSpecificDateDisable) {
        return (
          date.getDay() === 0 ||
          date.getDay() === 2 ||
          date.getDay() === 3 ||
          date.getDay() === 4 ||
          date.getDay() === 5 ||
          date.getDay() === 6 ||
          date.getDay() === 7
        );
      }
    } else {
      return false;
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} variant={"filled"}>
        <Grid container className="mt-1" justify="space-around">
          <KeyboardDatePicker
            orientation="portrait"
            fullWidth
            clearable
            invalidLabel="Invalid Date"
            emptyLabel={props.placeholder}
            value={props.value}
            onChange={dateChangeEvent}
            //format="dd/MM/yyyy"
            format="yyyy-MM-dd"
            disableFuture={props.disablefuturedate}
            disablePast={props.disablepastdate}
            shouldDisableDate={(date) =>
              DisableBeforeAndSpecific(
                date,
                props.disableBefore,
                props.disableAfter,
                props.isSpecificDateDisable
              )
            }
            disabled={props.disabled}
            label={props.label}
            required={props.required}
            helperText={props.helperText}
            InputProps={{ readOnly: true }}
            views={["year", "month", "date"]}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default FormDatePicker;
