import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Checkbox } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        PrivateSwitchBase: {
            root: {
                padding: "1px",
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "0px solid #FFFFFF",
                },
                "&:after": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #FFFFFF",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },
        MuiDialogTitle: {
            root: {
                padding: "8px 24px",
            },
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2",
            },
        },
        MuiPickersBasePicker: {
            container: {
                background: "",
            },
            pickerView: {
                689: {
                    background: "#000028",
                },
                background: "#000028",
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: ""
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
    },
});

export default function PersonalDataOnboarding(props) {
    const [taxRegimeId, setTaxRegimeId] = useState("");
    const [buttonTextSave, setButtonTextSave] = useState("Save");
    const [taxRegimeSelect, setTaxRegimeSelect] = useState("");
    const [declarationIsChecked, setDeclarationIsChecked] = useState(false);

    //Alerts
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const [isLoader, setIsLoader] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        //clearStates();
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleChangeTaxSelection = (event) => {
        setTaxRegimeSelect(event.target.value);
    };

    const handleCheckDeclaration = (event) => {
        setDeclarationIsChecked(event.target.checked);
    };

  var formData = {
    ApplicantId: props.id,
    TaxRegime: taxRegimeSelect,
    DeclarationIsChecked: declarationIsChecked,
    UserId: props.userId,
  };
  const submitHandler = (e) => {
    setIsLoader(true);
    formData = {
      ApplicantId: props.id,
      TaxRegime: taxRegimeSelect,
      DeclarationIsChecked: declarationIsChecked,
      UserId: props.userId,
    };
    if (
      //formData.TaxRegime != "" &&
      //formData.TaxRegime != null &&
      formData.DeclarationIsChecked !== "" &&
      formData.DeclarationIsChecked !== false &&
        formData.DeclarationIsChecked !== null
    ) {
      if (taxRegimeId != null && taxRegimeId > 0 && taxRegimeId != undefined) {
        UpdateTaxRegimeDetails(formData);
      } else {
        SaveTaxRegimeDetails(formData);
      }
    } /*else {*/
      //e.preventDefault();
      //if (
      //  formData.DeclarationIsChecked === "" ||
      //  formData.DeclarationIsChecked === false
      //) {
      //  setIsLoader(false);
      //  setOpen(true);
      //  setOpenAlert("Please check the data privacy declaration.");
      //  setClassName("clr-dark-blue bg-alert");
      //  setOpenType("Alert");
      //} //else if (formData.TaxRegime == "" || formData.TaxRegime == null) {
      //  setIsLoader(false);
      //  setOpen(true);
      //  setOpenAlert("Please take action on Tax Regime selection.");
      //  setClassName("clr-dark-blue bg-alert");
      //  setOpenType("Alert");      } 
      else {
        setIsLoader(false);
        setOpen(true);
        setOpenAlert("Please check the data privacy declaration.");
        setClassName("clr-dark-blue bg-alert");
        setOpenType("Alert");
      }
    
  };
  //const cancelHandler = () => {
  //    setOpen(true);
  //    setOpenAlert("Yet to be developed");
  //    setClassName("bg-light-green clr-white");
  //    setOpenType("");

    //    //alert("11");
    //}

    //Save Tax Regime Details
    function SaveTaxRegimeDetails(formData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("OnBoardingForm/SaveTaxRegimeDetails/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Update Tax Regime Details
    function UpdateTaxRegimeDetails(formData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("OnBoardingForm/UpdateTaxRegimeDetails/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: "100%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
    }));
    const classes = useStyles();

    //Change Button Text
    function getButtonTextSave(id) {
        id != null && id > 0 && id != undefined
            ? setButtonTextSave("Update")
            : setButtonTextSave(buttonTextSave);
    }

    //Set All States
    function SetStateAsPerFetched(data) {
        setTaxRegimeId(data.taxRegimeId);
        setTaxRegimeSelect(data.taxRegime);
        setDeclarationIsChecked(data.declarationIsChecked);
        setIsLoader(false);
    }

    //Get Tax Regime Details On Page Load
    function getTaxRegimeDetailsOnLoad(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("OnBoardingForm/GetTaxRegimeDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    useEffect(() => {
        if (props.token) {
            if (props.id != undefined && props.id != "") {
                getTaxRegimeDetailsOnLoad(props.id);
            }
        } else {
            props.loaderForm(false);
        }
        getButtonTextSave(taxRegimeId);
    }, [props.id, taxRegimeId, props.token]);

    useEffect(() => {
        getButtonTextSave(taxRegimeId);
    }, [taxRegimeId]);

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100 mt-2">
        <Grid item xs={12} sm={12} className="mb-1">
          <h4 className="m-0">
            Data Privacy Note  <span className="clr-red"></span>
          </h4>
        </Grid>
        {/*<Grid item xs={12} sm={12} className="mb-1">*/}
        {/*  <h5 className="m-0">I want to opt for Tax Regime</h5>*/}
        {/*</Grid>*/}
        <Grid container spacing={2} className="d-flex mt-1 fd-row bdr-bottom">
          {/*<div className="d-flex ml-1 jc-flex-start">*/}
          {/*  <FormControl component="fieldset" className="mt-1">*/}
          {/*    <RadioGroup*/}
          {/*      aria-label=""*/}
          {/*      name="confirmationTax"*/}
          {/*      value={taxRegimeSelect}*/}
          {/*      onChange={handleChangeTaxSelection}*/}
          {/*      row*/}
          {/*    >*/}
          {/*      <FormControlLabel*/}
          {/*        disabled={props.disableValue}*/}
          {/*        value="Old"*/}
          {/*        control={<Radio />}*/}
          {/*        label="Old"*/}
          {/*      />*/}
          {/*      <FormControlLabel*/}
          {/*        disabled={props.disableValue}*/}
          {/*        value="New"*/}
          {/*        control={<Radio />}*/}
          {/*        label="New"*/}
          {/*      />*/}
          {/*    </RadioGroup>*/}
          {/*  </FormControl>*/}
          {/*</div>*/}
          {/*{taxRegimeSelect == "New" ? (*/}
          {/*  <p className="ml-1 f-14">*/}
          {/*    Under new Tax regime, you will not be entitled to various*/}
          {/*    exemptions and deductions such as Standard Deductions, Leave*/}
          {/*    Travel Exemption, House Rent Exemption, Children Education*/}
          {/*    Allowances, Electronic Food vouchers, Deductions under Section 16,*/}
          {/*    80C, 80D,80E, Home Loan etc.*/}
          {/*  </p>*/}
          {/*) : (*/}
          {/*  <React.Fragment></React.Fragment>*/}
          {/*)}*/}
          <Grid item xs={12} sm={12} className="m-0">
            <div className="d-flex fd-column">
              <form noValidate>
                <p className="f-12 fw-bold font-italic">
                  <Checkbox
                    checked={declarationIsChecked}
                    onChange={handleCheckDeclaration}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    disabled={props.disableValue}
                  />
                  {/*I confirm that I have opted for the{" "}*/}
                  {/*{taxRegimeSelect === "New" ? (*/}
                  {/*  <>New Personal Tax Regime (NPTR)</>*/}
                  {/*) : (*/}
                  {/*  <>OLD Personal Tax Regime (OPTR)</>*/}
                  {/*)}{" "}*/}
                  {/*as maintained in my previous employment in this Financial Year*/}
                  {/*and hereby authorize Siemens to calculate my tax liability as*/}
                  {/*per the provisions and guidelines of the opted Tax regime for*/}
                  {/*this Financial Year.*/}
                  {/*<br />*/}
                  {/*I further certify that while at Siemens, I will not use any trade secret or confidential information. I have received through any prior employment.(if you have answered 'Yes' to any of these questions, please contact HR representative and provide complete copies thereof.)*/}
                                  I hereby declare & acknowledge that the information &
                                  documents provided to Siemens are true, valid & genuine. I
                                  further declare that should there be any change in the said
                                  information or documents I shall duly inform Siemens & shall
                                  share the updated information & documents
                                  <br />
                                  {/*I have read and fully understood the foregoing questions. I have answered them to best of my belief and knowledge, and my statements are truthful and without evasion. Any misrepresentation or omission of a fact in my application may be justification for Siemens refusal to hire me, or, if I am employed, for termination of my employment by Siemens.*/}
                                  I further declare & certify that I am not in possession of any
                                  trade secrets & confidential & sensitive information &
                                  documents pertaining to any of my previous employers AND/OR I shall
                                  not use any trade secrets & confidential & sensitive
                                  information & documents pertaining to any of my previous
                                  employers while working for or representing Siemens.
                                  <br/>
                                  I shall bear full responsibility & liability in case of any violations
                                  of any laws, statutes, rules & regulations and / or circulars,
                                  policies & guidelines of my previous employer, in this regard.
                                  <br />
                                  {/*Siemens will conduct a background check / investigation to verify all data given in my application for employment, resume, related papers and oral interviews. I understand that misrepresentations or omissions of facts discovered as a result of this investigation may prevent my being hired, or if hired, may subject me to termination. I understand and agree to give my consent and comply with the process for the background investigation by a public or private agency.*/}
                                  I hereby give my free, specific, informed, unconditional and unambiguous consent to
                                  Siemens Limited or Siemens AG or any of its affiliates and
                                  subsidiaries(“Siemens”)or any third party engaged by Siemens
                                  for processing of my personal data & information (including
                                  Special Category Data & Sensitive Personal Data & Information)
                                  and /or non-personal data, as appliable (“data”)as defined
                                  under the applicable Data Privacy & Data Protection laws,
                                  statutes, rules & regulations including GDPR and Indian Digital Personal Data Protection Act, 2023 for employment
                                  purposes & any other related & incidental matters. Employment
                                  purpose includes but is not limited to interview & on-boarding
                                  process; retirement & nomination, employee compensation &
                                  welfare, conducting due diligence, background checks &
                                  verification; recording of attendance & access control within
                                  the premises of Siemens' performance of assigned roles,
                                  functions & jobs; business contact with internal & external
                                  stakeholders; emergency contact; health & medical related
                                  purposes; statutory & internal audits; investigations;
                                  analysis, evaluation & assessment of monitoring of performance
                                  & behavior.
                                  <br />
                                  {/*As part of my selection process, I hereby agree to undertake psychometric assessments administered to me by the company.*/}
                                  Further, I am aware that for the purposes mentioned above
                                  Siemens may transfer or disclose my data to:
                                  <ul>
                                      <li>
                                          Courts, law enforcement authorities, regulators or
                                          attorneys if necessary to comply with applicable laws,
                                          statutes, rules & regulations or for the establishment,
                                          exercise or defense of legal claims
                                      </li>
                                      <br />
                                      <li>
                                          Service providers (so-called data processors), e.g.
                                          hosting or IT maintenance service providers, cloud service
                                          providers, third party software providers or simply
                                          third-party service providers which only act upon
                                          instructions of Siemens and are contractually bound to act
                                          in compliance with applicable Data Privacy & Protection
                                          laws, statutes, rules & regulations including GDPR and India Digital Personal Data Protection Act, 2023
                                      </li>
                                  </ul>
                                  {/*I hereby provide my personal information to the organization and give consent to use this information for employment purpose only.*/}
                                  Further, I am aware that unless indicated otherwise at the
                                  time of collection of my personal data & information
                                  (including Special Category Data & Sensitive Personal Data &
                                  Information), Siemens may process my data & information
                                  (including Special Category Data & Sensitive Personal Data &
                                  Information) in physical, manual, soft or digital form & media
                                  as deemed fit & necessary
                                  <br />
                                  <br />
                                  For details regarding processing of your personal data in this
                                  application please refer the attached&nbsp;
                                  <a href="/PrivacyNotice" target="_blank">
                                      Privacy Notice
                                  </a>
                              </p>
                          </form>
                      </div>
            <div className="d-flex mb-1">
              {/*<Button item xs={12} sm={6} className="ns-btn-primary mt-2" onClick={cancelHandler}>
                                <span>Cancel</span>
                            </Button>*/}
                            <Button
                                xs={12}
                                sm={6}
                                className="ns-btn-primary mt-2 ml-2 width-10"
                                disabled={props.disableValue}
                                onClick={submitHandler}
                            >
                                <span>{buttonTextSave}</span>
                            </Button>
                        </div>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadClear"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlertConfirm}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">Cancel</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? <Loader /> : <></>}
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}
