import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
//import Select from '@material-ui/core/Select';
import Input from "@material-ui/core/Input";
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "10px",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.6rem !important",
        backgroundColor: "#ebfbfb !important",
        marginBottom: "-7px ​!important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function FamilyData(props) {
  const [data, setData] = useState([]); //table data

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");

  const handleClose = () => {
    if (openType === "Success") {
      GetFamilyData(props.id);
    }
    setOpen(false);
    //window.location.reload()
  };

  //fetch Gender
  const [itemsGender, setItemsGender] = useState([]);
  async function getGender() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetAllGenders", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsGender(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch Relationship
  const [itemsRelationship, setItemsRelationship] = useState([]);
  async function getRelationship() {
    const token = props.token;
    const response = await fetch("OnBoardingForm/GetAllRelationship", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsRelationship(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  function ValidateGender(rowData) {
    return rowData.gender === null || rowData.gender === "" ? false : true;
  }
  function ValidateRelationship(rowData) {
    return rowData.relationship === null || rowData.relationship === ""
      ? false
      : true;
  }

  function ValidateDOB(rowData) {
    var isValid = true;
    if (
      rowData.familyDateOfBirth === null ||
      rowData.familyDateOfBirth === ""
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  var columns = [
    { title: "Row ID", field: "familyDetailsId", hidden: true },
    { title: "Applicant ID*", field: "applicantId", hidden: true },

    {
      title: "First Name*",
      field: "familyFirstName",
      validate: (rowData) =>
        rowData.familyFirstName === null || rowData.familyFirstName === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    { title: "Middle Name", field: "familyMiddleName" },
    {
      title: "Last Name*",
      field: "familyLastName",
      validate: (rowData) =>
        rowData.familyLastName === null || rowData.familyLastName === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    {
      title: "Date Of Birth*",
      field: "familyDateOfBirth",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      validate: (rowData) => ValidateDOB(rowData),
      editComponent: (props) => {
        const familyDateOfBirth = props.rowData.familyDateOfBirth;

        let errorText = "";
        var errorState = false;
        if (familyDateOfBirth === null || familyDateOfBirth === "") {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              clearable
              disableFuture
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
      //lookup: {

      //}
    },
    {
      title: "Occupation",
      field: "occupation",
      //, validate: rowData => (rowData.occupation === null || rowData.occupation === '') ? { isValid: false, helperText: 'Mandatory Field' } : true
    },
    {
      title: "Gender*",
      field: "gender",
      validate: (rowData) => ValidateGender(rowData),
      editComponent: (props) => {
        const genderData = props.rowData.genderData
          ? props.rowData.genderData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const gender = props.rowData.gender;
        const customStyles =
          gender !== undefined && gender !== null && gender !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsGender}
            value={typeof props.value === "string" ? genderData : props.value}
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Relationship*",
      field: "relationship",
      validate: (rowData) => ValidateRelationship(rowData),
      editComponent: (props) => {
        const relationshipData = props.rowData.relationshipData
          ? props.rowData.relationshipData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const relationship = props.rowData.relationship;
        const customStyles =
          relationship !== undefined &&
          relationship !== null &&
          relationship !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsRelationship}
            value={
              typeof props.value === "string" ? relationshipData : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const handleErrorClose = (e) => {
    setIserror(false);
  };

  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (props.token) {
      getGender();
      getRelationship();
    }
  }, [props.token]);

  //useEffect(() => {
  //    api.get("/OnBoardingForm/GetFamilyData/16")
  //        .then(res => {
  //            setData(res.data.data)
  //        })
  //        .catch(error => {
  //            console.log("Error")
  //        })
  //}, [])

  function GetFamilyData(id) {
    setIsLoader(true);
    const token = props.token;
    axios
      .get("/OnBoardingForm/GetFamilyData/" + id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error : ", error);
      });
  }

  useEffect(() => {
    if (props.token) {
      if (props.id != null && props.id != undefined) {
        GetFamilyData(props.id);
      }
    }
  }, [props.id, props.token]);

  //useEffect(() => {
  //    axios.get('/OnBoardingForm/GetFamilyData/' + props.id)
  //        .then((res) => {
  //            setData(res.data)
  //        })
  //        .catch(error => {
  //            console.log("Error")
  //        })

  //}, [])

  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    //validation
    let errorList = [];
    //if (newData.familyFirstName === "" || newData.familyFirstName === undefined || newData.familyFirstName === null) {
    //    errorList.push("Please enter First Name")
    //}
    ////if (newData.familyMiddleName === "") {
    ////    errorList.push("Please enter Middle Name")
    ////}
    //if (newData.familyLastName === "" || newData.familyLastName === undefined || newData.familyLastName === null) {
    //    errorList.push("Please enter Last Name")
    //}
    //if (newData.familyDateOfBirth === "" || newData.familyDateOfBirth === undefined || newData.familyDateOfBirth === null) {
    //    errorList.push("Please enter Date Of Birth")
    //}
    //if (newData.occupation === "" || newData.occupation === undefined || newData.occupation === null) {
    //    errorList.push("Please enter Occupation")
    //}
    //if (newData.gender === "" || newData.gender === undefined || newData.gender === null) {
    //    errorList.push("Please enter Gender")
    //}
    //if (newData.relationship === "" || newData.relationship === undefined || newData.relationship === null) {
    //    errorList.push("Please enter Relationship")
    //}

    //added for formatting of newData start
    const gender =
      newData.gender === null
        ? null
        : typeof newData.gender === "object"
        ? newData.gender.label
        : newData.gender;
    const relationship =
      newData.relationship === null
        ? null
        : typeof newData.relationship === "object"
        ? newData.relationship.label
        : newData.relationship;

    newData.gender = gender;
    newData.relationship = relationship;

    // end

    newData.familyDetailsId = oldData.tableData.id;
    newData.applicantId = props.id;
    newData.userId = props.loginDetail[0].userid;

    if (errorList.length < 1) {
      //api.patch("/users/" + newData.id, newData)
      //    .then(res => {
      //        const dataUpdate = [...data];
      //        const index = oldData.tableData.id;
      //        dataUpdate[index] = newData;
      //        setData([...dataUpdate]);
      //        resolve()
      //        setIserror(false)
      //        setErrorMessages([])
      //    })
      const token = props.token;
      axios
        .put("/OnBoardingForm/UpdateFamilyData", newData, {
          headers: { Authorization: "Bearer " + token },
        })

        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })

        .catch((error) => {
          reject();
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      //resolve()
      reject();
    }
  };

  const handleRowAdd = (newData, resolve, reject) => {
    //validation
    let errorList = [];
    if (
      newData.familyFirstName === "" ||
      newData.familyFirstName === undefined ||
      newData.familyFirstName === null
    ) {
      errorList.push("Please enter First Name");
    }
    //if (newData.familyMiddleName === "") {
    //    errorList.push("Please enter Middle Name")
    //}
    if (
      newData.familyLastName === "" ||
      newData.familyLastName === undefined ||
      newData.familyLastName === null
    ) {
      errorList.push("Please enter Last Name");
    }
    if (
      newData.familyDateOfBirth === "" ||
      newData.familyDateOfBirth === undefined ||
      newData.familyDateOfBirth === null
    ) {
      errorList.push("Please enter Date Of Birth");
    }
    //if (newData.occupation === "" || newData.occupation === undefined || newData.occupation === null) {
    //    errorList.push("Please enter Occupation")
    //}
    if (
      newData.gender === "" ||
      newData.gender === undefined ||
      newData.gender === null
    ) {
      errorList.push("Please enter Gender");
    }
    if (
      newData.relationship === "" ||
      newData.relationship === undefined ||
      newData.relationship === null
    ) {
      errorList.push("Please enter Relationship");
    }

    //added for formatting of newData start
    const gender =
      newData.gender === null
        ? null
        : typeof newData.gender === "object"
        ? newData.gender.label
        : newData.gender;
    const relationship =
      newData.relationship === null
        ? null
        : typeof newData.relationship === "object"
        ? newData.relationship.label
        : newData.relationship;

    newData.gender = gender;
    newData.relationship = relationship;

    // end

    newData.applicantId = props.id;
    newData.userId = props.loginDetail[0].userid;

    if (errorList.length < 1) {
      //no error
      //api.post("/users", newData)
      //    .then(res => {
      //        let dataToAdd = [...data];
      //        dataToAdd.push(newData);
      //        setData(dataToAdd);
      //        resolve()
      //        setErrorMessages([])
      //        setIserror(false)
      //    })
      const token = props.token;
      axios
        .post("/OnBoardingForm/SaveFamilyData", newData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          // resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
      reject();
    }
  };

  const handleRowDelete = (oldData, resolve, reject) => {
    //api.delete("/users/" + oldData.id)
    //    .then(res => {
    //        const dataDelete = [...data];
    //        const index = oldData.tableData.id;
    //        dataDelete.splice(index, 1);
    //        setData([...dataDelete]);
    //        resolve()
    //    })

    oldData.familyDetailsId = oldData.tableData.id;
    oldData.applicantId = props.id;

    const token = props.token;

    axios
      .put("/OnBoardingForm/DeleteFamilyData", oldData, {
        headers: { Authorization: "Bearer " + token },
      })

      .then((response) => {
        if (response.data.response) {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          resolve();
          setIserror(false);
          setErrorMessages([]);
        } else {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          // resolve();
          reject();
          setIserror(false);
          setErrorMessages([]);
        }
      })
      .catch((error) => {
        reject();
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        // resolve()
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <Grid container spacing={1}>
          <p className="f-12">Family Data Father details are mandatory</p>
          <Grid item xs={12}>
            <div>
              {iserror && (
                <Alert severity="error" onClose={handleErrorClose}>
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              title="Family Details"
              columns={columns}
              data={data}
              isLoading={isLoader}
              components={{
                OverlayLoading: (props) => <Loader {...props} />,
              }}
              icons={tableIcons}
              localization={{
                header: {
                  actions: "",
                },
              }}
              options={{
                search: false,
                toolbar: props.disableValue === true ? false : true,
              }}
              editable={{
                isEditable: (rowData) => props.disableValue === false,
                isDeletable: (rowData) => props.disableValue === false,

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(newData, oldData, resolve, reject);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newData, resolve, reject);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldData, resolve, reject);
                  }),
              }}
            />
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle className={openClassName} id="draggable-dialog-title">
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default FamilyData;
