import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader";
import SaveIcon from "@material-ui/icons/SaveOutlined"
import FamilyData from "../ApplicationForm/OnBoardingFormComponents/FamilyData";

const theme = createMuiTheme({
    overrides: {
        ContainerFlexiHoliday: {
            fontFamily: "SiemensSans_Roman, Arial, Helvetica, sans-serif !important",
           
		},
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                backgroundColor: "#E8F8F8",
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiTabs: {
            scroller: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },

        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },      
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                border: `1px solid #FFFFFF`,
                marginBottom: "5px",
                color: "#000028",
            },
        },
        MuiToolbar: {
            root: {
                background: "#E8F8F8",
                color: "#000028",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#000028",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#000028",
                "&$focused": {
                    color: "#000028",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#FFFFFF !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#FFFFFF",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#000028 !important",
                background: "#E8F8F8 !important",
                padding: "10px",
                fontFamily: "SiemensSans_Roman, Arial, Helvetica, sans-serif !important",
                fontSize: "15px !important",
            },
            body: {
                background: "#FFFFFF",
                color: "#000028",
                fontFamily: "SiemensSans_Roman, Arial, Helvetica, sans-serif !important",
                fontSize:"15px !important",
            },
            head: {
                lineHeight: "1rem",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#000028",
            },
            container: {
                background: "#000028",
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPickersMonth: {
            root: {
                color: "#FFFFFF",
            },
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: "#000028"
        //    }
        //},
        MuiDialogActions: {
            root: {
                background: "#000028",
            },
        },
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        MTableToolbar: {
            searchField: {
                paddingLeft: "0px !important",
            },
        },
        MuiFormHelperText: {
            // root: {
            //   fontSize: "0.6rem !important",
            //   backgroundColor: "#ebfbfb !important",
            //   marginBottom: "-7px ​!important",
            //   marginLeft: "0px !important",
            //   marginRight: "0px !important",
            //   color: "red !important",
            //   lineHeight: "1.2 !important",
            // },
            root: {
                position: "absolute",
                marginTop: "-5px",
                marginLeft: "-2px",
                fontSize: "10px",
            },
        },
    },
});
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function FlexiHoliday(props) {  
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [dataFixed, setDataFixed] = useState([]);
    const [dataFixed1, setDataFixed1] = useState([]);
    const [finalSelectedHoliday, setFinalSelectedHoliday] = useState([]);
    const [dataFlexible, setDataFlexible] = useState([]);

    var columns = [
        { title: "Holiday description", field: "holidayName" },
        { title: "Holiday date", field: "date", type: "date" },
        { title: "Holiday type", field: "type" },
    ];

    const legend = [
        { name: "Fixed :", age: 19, gender: "Fixed holiday(cannot be swapped)" },
        { name: "Flexible :", age: 19, gender: "Flexible,that can be swapped with swap option" },
        { name: "Swap :", age: 25, gender: "Swap option" },
    ];

    //Alerts
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const handleClose = () => {
        setOpen(false);
        
    };

    const handleConfirm = () => {
        setOpenConfirm(false);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    //Get Applicant ARE Details On Page Load
    async function getApplicantAREDetail() {
        setIsLoader(true);
        const token = props.token;
        const id = props.id;
        await fetch("ApplicationForm/GetApplicantAREDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {               
                GetAllFixedHoliday(data.companyId);
                GetAllFlexibleHoliday(data.companyId);                
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    async function GetAllFixedHoliday(AREId) {
        const token = props.token;
        const id = props.id;
        await fetch("FlexibleHoliday/GetAllFixedHoliday/" + id + "/" + AREId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {               
                setIsLoader(false);
                setDataFixed(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    async function GetAllFlexibleHoliday(AREId) {
        const token = props.token;
        const id = props.id;
        await fetch("FlexibleHoliday/GetAllFlexibleHoliday/" + id + "/" + AREId, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {     
                setIsLoader(false);
                setDataFlexible(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    function SaveFlexiHoliday(dataFlexible) {
        setIsLoader(true);
        if ((dataFlexible.length + dataFixed.length) > 10 ) {
            setOpen(true);
            setOpenAlert("You can select total 10 Holidays (including Fixed and Flexible)");
            setClassName("bg-light-green clr-white");
            setOpenType("Alert");
            setIsLoader(false);
        }
        else {
            var dataFlexibleNew = dataFlexible.concat(dataFixed);
            dataFlexibleNew = dataFlexibleNew.map(function (element) {
                element.applicantId = props.id;              
                return element;
            })
            var userData = JSON.stringify(dataFlexibleNew);
            const token = props.token;
            const options = {
                method: "post",
                headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: userData,
            };
            fetch("FlexibleHoliday/SaveFlexiHoliday", options)
                .then((response) => response.json())
                .then((data) => {                    
                    setIsLoader(false);
                    if (data[0].responseMsg == "Flexible Holiday Data Saved Successfully.") {                        
                        setOpen(true);                      
                        setOpenAlert(data[0].responseMsg);
                        setClassName("clr-dark-blue bg-success");
                        setOpenType("Success");
                        setFinalSelectedHoliday(data);
                        
                    } else {                       
                        setOpen(true);
                        setOpenAlert(data[0].responseMsg);
                        setClassName("clr-dark-blue bg-error");
                        setOpenType("Error");
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    console.log("Error:", error);
                });
		}
        

    }

    useEffect(() => {       
        getApplicantAREDetail();
    }, []);

    return (
        <ThemeProvider theme={theme}>          
            <div className="master-layout-block ContainerFlexiHoliday" >
                <div style={
                    {
                        width: '38%',
                        fontSize: '14px',
                        border: '1px solid #FFFFFF',
                        background: '#E8F8F8'
                    }
                }
                >
                    <table>
                        <tr>
                        </tr>
                        {legend.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td><span className="fontLegend">{val.name}</span></td>
                                    <td><span className="fontLegend">{val.gender}</span></td>

                                </tr>
                            )
                        })}
                    </table>
                </div>
                <Grid container spacing={2} className="d-flex jc-center">

                    <Grid item xs={6} className="mt-1 mb-2">
                        <MaterialTable
                            title="Fixed Holiday"
                            columns={columns}
                            data={dataFixed}
                            icons={tableIcons}
                            options={{
                                search: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 25, 50],
                            }}

                        />
                    </Grid>
                    <Grid item xs={6} className="mt-1 mb-2">
                        <MaterialTable
                            title="Flexible Holiday"
                            columns={columns}
                            data={dataFlexible}
                            icons={tableIcons}
                            options={{
                                selection: true,
                                search: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 25, 50],
                            }}
                            actions={[
                                {
                                    tooltip: 'Submit All Holidays',
                                    icon: SaveIcon,

                                    onClick: (evt, dataFlexible) => SaveFlexiHoliday(dataFlexible)
                                }
                            ]}


                        />

                    </Grid>
                </Grid>

                {finalSelectedHoliday.length > 0 ? (
                    <Grid container spacing={2}>

                        <Grid item xs={6} className="mt-1 mb-2">
                            <MaterialTable
                                title="Final Selected Holiday List"
                                columns={columns}
                                data={finalSelectedHoliday}
                                icons={tableIcons}
                                options={{
                                    search: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 25, 50],
                                }}

                            />
                        </Grid>
                    </Grid>
                ) : (
                        <React.Fragment></React.Fragment>
                    )}                
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadSuccess"
            >
                <DialogTitle
                    className={openClassName}
                    style={{ cursor: "move", background: "#00B3B3" }}
                    id="draggable-dialog-title"
                >
                    {openType}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="clr-dark-blue">
                        {openAlert}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="bg-content">
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={handleClose}
                        className="ns-btn-secondary-light"
                    >
                        <span className="f-16 fw-bold clr-white">OK</span>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="draggable-dialog-title"
                id="templateUploadClear"
            >
                <DialogTitle
                    className={openClassName}
                    style={{ cursor: "move", background: "#00B3B3" }}
                    id="draggable-dialog-title"
                >
                    {openType}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="clr-dark-blue">
                        {openAlertConfirm}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="bg-content">
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={handleConfirm}
                        className="ns-btn-secondary-light mr-15px"
                    >
                        <span className="f-16 fw-bold clr-white">OK</span>
                    </Button>
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={handleCloseConfirm}
                        className="ns-btn-secondary-light"
                    >
                        <span className="f-16 fw-bold clr-white">Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
        </ThemeProvider>
    );
}
