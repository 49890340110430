import React, { Component } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  TextField,
  createMuiTheme,
  ThemeProvider,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader";
import UserManualToRaiseTicket from "../../common/SupportDoc/User_Manual.pdf";

const theme = createMuiTheme({
  overrides: {
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#E8F8F8",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#E8F8F8",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&$hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `2px solid #2EE6E2`,
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },
    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#FFFFFF",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    //MuiPickersBasePicker: {
    //    pickerView: {
    //        background: "#FFFFFF"
    //    }
    //},
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "",
      },
      pickerView: {
        background: "#FFFFFF",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
  },
});

export default function LoginPage(props) {
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMeIsChecked, setRememberMeIsChecked] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownShowPassword = () => setShowPassword(!showPassword);

  const [isLoader, setIsLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    //function btoaSecure(value) {
    //    const { btoa } = require("abab");
    //    let currDate = new Date().toDateString();
    //    let valueModified = value + " " + currDate;
    //    let valueSecured = btoa(btoa(valueModified));
    //    return valueSecured;
    //}

  const changeEventUsername = (e) => {
    setUserName(e.target.value);
    setFormData({
      ...formData,
      userName: [e.target.value.trim()],
      password: password.trim(),
    });
    props.loginChangeEvent("username", e.target.value.trim());
  };

  const changeEventPassword = (e) => {
    setPassword(e.target.value);
    setFormData({
      ...formData,
      userName: [userName.trim()],
      password: [e.target.value.trim()],
    });
    props.loginChangeEvent("password", e.target.value.trim());
  };

  const changeRememberMe = (event) => {
    setRememberMeIsChecked(event.target.checked);
    props.onChangeRememberMe(event.target.checked);
    if (!event.target.checked) {
      setUserName(props.loginDetails[0].username.trim());
      setPassword("");
    } else {
      setUserName(userName.trim());
      setPassword(password.trim());
    }
  };
  const submit = () => {
    //props.loginClick(props.loginDetails);
    props.loginClick();
  };

  const forgetPasswordHandler = () => {
    props.forgetPasswordClick();
  };

  const enterKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      props.loginClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", enterKeyDown);
    if (
      props.rememberMeDetails[0].rememberMeIsChecked &&
      props.rememberMeDetails[0].username !== ""
    ) {
      const UserName = props.rememberMeDetails[0].username;
      const Password = props.rememberMeDetails[0].password;
      setRememberMeIsChecked(props.rememberMeDetails[0].rememberMeIsChecked);
      setUserName(UserName);
      props.loginChangeEvent("username", UserName.trim());
      setPassword(Password);
      props.loginChangeEvent("password", Password.trim());
    }
    props.loader === true ? setIsLoader(true) : setIsLoader(false);

    return () => {
      document.removeEventListener("keydown", enterKeyDown);
    };
  }, [props.rememberMeDetails[0], props.loader]);
  //function LoginCandidate(loginData) {
  //    const options = {
  //        method: 'post',
  //        headers: {
  //            'Accept': 'application/json, text/plain, */*',
  //            'Content-Type': 'application/json'
  //        },
  //        body: loginData
  //    }
  //    fetch('Login/LoginUser/', options)
  //        .then(response => response.json())
  //        .then(data => {
  //            if (data) {
  //                if (data.isOTPEnable) {
  //                    window.location.href = '/OTPpage';
  //                }
  //                else {
  //                    if (data.roleId == 1) {
  //                        window.location.href = '/Home';
  //                    }
  //                    else if (data.roleId == 2) {
  //                        window.location.href = '/Home';
  //                    }
  //                    else if (data.roleId == 3) {
  //                        window.location.href = '/Home';
  //                    }
  //                }
  //            }
  //            else {
  //                alert("Login Failed.");
  //            }
  //        }).catch(error => console.log("Error:", error));
  //}

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={1}
        align="center"
        justify="center"
        className="position-relative"
      >
        <Grid
          item
          xs={12}
          sm={6}
          className="home-user-description bg-white mt-2"
        >
          <Grid className="d-flex fd-column box-shadow bdr-radius align-item-center">
            <Typography className="mt-1" component="h1" variant="h5">
              Sign in
            </Typography>
            <form noValidate className="p-1 mb-2">
              <TextField
                className="lightInputFields"
                //id="gf-statement-userName"
                label="Username"
                variant="filled"
                id="userName"
                type="search"
                fullWidth
                required
                onChange={changeEventUsername}
                name="Username"
                value={userName}
              />
              <TextField
                className="lightInputFields mt-2"
               // id="gf-statement-userName"
                //id="gf-statement-password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                variant="filled"
                onChange={changeEventPassword}
                name="Password"
                fullWidth
                required
                value={password}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className="f-16 fw-bold mt-1 ml-1 clr-darkk-blue">
                Remember Me
                <Checkbox
                  checked={rememberMeIsChecked}
                  onChange={changeRememberMe}
                  name="Remember Me"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Typography>
              {
                //<Link variant="contained" className="mt-1 jc-center" component={Link} to="/ForgetPassword">
                //    <span className="f-16 fw-bold clr-dark-blue">Forgot Password</span>
                //</Link><br />
              }
              <a
                href="javascript:void(0)"
                variant="contained"
                className="mt-1 jc-center"
                onClick={forgetPasswordHandler}
              >
                <span className="f-16 fw-bold clr-dark-blue">
                  Forgot Password
                </span>
              </a>
              <br />
              <Button
                variant="contained"
                className="ns-btn-primary jc-center mt-1"
                onClick={submit}
              >
                <span className="f-14 fw-bold">Login</span>
              </Button>
            </form>
            <Typography
              className="fw-bold clr-dark-blue f-16"
              variant="subtitle1"
            >
              *Note: - Best viewed in Google Chrome browser
            </Typography>
          </Grid>
          <Grid>
            <Typography
              className="clr-dark-green fw-bold f-14 mt-1"
              variant="subtitle1"
            >
              &#169; Siemens 2021
              <a href="/PrivacyNotice" target="_blank">
                <span className="ml-2">Privacy</span>
              </a>
              <a href={UserManualToRaiseTicket} target="_blank">
                <span className="ml-2">Support</span>
              </a>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="cognisphere-vid-block"
          id="CognisphereVid"
        >
          <video autoPlay loop muted className="width-100 height-100">
            <source
              src={"../assets/animation/cognisphere_home.mp4"}
              type="video/mp4"
            />
          </video>
        </Grid>
        {isLoader ? <Loader /> : <></>}
      </Grid>
    </ThemeProvider>
  );
}
