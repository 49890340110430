import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import ResetPassword from './pages/LoginPage/ResetPassword';
import ForgetPassword from './pages/LoginPage/ForgetPassword';
import UserRegistration from './pages/NewUserRegistration/NewUser';
import CandidateRegistration from './pages/NewUserRegistration/NewCandidate';
import AppHeader from './pages/AppHeader';
import ApplicationForm from './pages/ApplicationForm/ApplicationForm';
import OTPpage from './pages/OTPpage';
import AdminOrRecruiterData from './pages/AdminOrRecruiterData';
import CandidateData from './pages/CandidateData';
import UnauthorizedAccess from './pages/UnauthorizedAccess';
import UnauthorizedRoute from './pages/UnauthorizedRoute';
import ApplicationFormPreview from './pages/PreviewComponents/ApplicationFormPreview';
import OnboardingFormPreview from './pages/PreviewComponents/OnboardingFormPreview';
import SHPL from './pages/DownloadDocuments/SHPL';
import STSPL from './pages/DownloadDocuments/STSPL';
import Gratuity from './pages/DownloadDocuments/Gratuity';
import Superannuation from './pages/DownloadDocuments/Superannuation';
import SLLIC from './pages/DownloadDocuments/SLLIC';
import FORM2 from './pages/DownloadDocuments/FORM2';
import EmployeeConsentForm from './pages/DownloadDocuments/EmployeeConsentForm';
import DownloadDocuments from './pages/DownloadDocuments/DownloadDocuments';
import TimeTicker from './pages/TimeTicker';
import UploadSupportingDocuments from '../src/pages/ApplicationForm/HRDetails/UploadSupportingDocuments';

import "./common/styles/appbar.css";
import "./common/styles/common.css";
import "./common/styles/drawer.css";
import "./common/styles/component.css";
import "./common/styles/mobile.css";
import "./common/styles/fonts.css";
import { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollToTop from './pages/components/ScrollToTop';
import WorkdayData from './pages/WorkdayData';
import PrivacyNotice from '../src/pages/PrivacyNotice';
import DHLPrerequisites from './pages/DHLPrerequisites/DHLPrerequisites';
import PreviewLetters from './pages/DHLPrerequisites/PreviewLetters';

export default class App extends Component {

    saveStateToLocalStorage() {
        for (let key in this.state) {
            localStorage.setItem(key, JSON.stringify(this.state[key]));
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            loginDetails: [
                {
                    username: '', password: '', UserEncryptedId: '', userid: '', userfirstname: '', userlastname: ''
                    , isOTPEnabled: false, otp: '', roleId: '', loading: false, applicantid: '',
                    LoginFlag: '', redirectedto: '', forgetPassword: false, authToken: '', AREId: ''
                }
            ],
            otpDetails: [
                {
                    otpEntry: '', otpGenerated: '', isOTPVerified: false, otpAlertText: '', buttontext: '', forgetPassword: false, otpSent: false
                }
            ],
            rememberMeDetails: [
                {
                    rememberMeIsChecked: false, username: '', password: ''
                }
            ],
            isLoading: false,
            isTokenValid: '',
        };
        //this.idleTimer = null
        //this.handleOnIdle = this.handleOnIdle.bind(this)
        this.initState = {
            loginDetails: [
                {
                    username: '', password: '', UserEncryptedId: '', userid: '', userfirstname: '', userlastname: ''
                    , isOTPEnabled: false, otp: '', roleId: '', loading: false, applicantid: '',
                    LoginFlag: '', redirectedto: '', forgetPassword: false, authToken: '', AREId: ''
                },
            ],
            otpDetails: [
                {
                    otpEntry: '', otpGenerated: '', isOTPVerified: false, otpAlertText: '', buttontext: '', forgetPassword: false, otpSent: false
                },
            ],
            rememberMeDetails: [
                {
                    rememberMeIsChecked: false, username: '', password: ''
                }
            ],
            isLoading: false,
        };
        //this.timeout = 600;
        this.state.timeout = '';
        this.getScreenIdleTime();
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();

    }

    startInterval() {
        this.updateExpiredTime();
        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
            if (expiredTime < Date.now() && this.state.loginDetails[0].LoginFlag == 1) {
                this.cleanUpTimeout();
                this.clickEventLogout();
            }
            else {

            }
        }, 1000);
    }

    updateExpiredTime() {
        const setScreenIdletime = parseInt(localStorage.getItem("_screenIdleTime"), 10);
        localStorage.setItem("_expiredTime", Date.now() + setScreenIdletime * 1000);
    }
    async getScreenIdleTime() {
        const options = {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
        }
        await fetch('Login/GetScreenIdleTime', options)
            .then(response => response.json())
            .then(data => {
                if (data != null && data != '') {
                    this.setState({ timeout: data });
                    //localStorage.setItem("_expiredTime", Date.now() + this.state.timeout * 1000);
                    localStorage.setItem("_screenIdleTime", data);
                }
                else {
                    console.log("Error:");
                }
            }).catch(error => console.log("Error:", error));
    }

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }
    cleanUpTimeout() {
        localStorage.removeItem("_expiredTime");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
    componentDidMount() {
        if (localStorage.IsChecked && localStorage.UserName !== '') {
            const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
                c.rememberMeIsChecked = localStorage.IsChecked;
                c.username = localStorage.UserName;
                c.password = localStorage.Password;
                return c;
            });
            this.setState({ rememberMeDetails });
        }
        else {
            const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
                c.rememberMeIsChecked = false;
                c.username = '';
                c.password = '';
                return c;
            });
            this.setState({ rememberMeDetails });
        }
        this.GetUser();
        this.startInterval();
    }

    changeEventRememberMe(value) {
        if (value) {
            const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
                c.rememberMeIsChecked = value;
                return c;
            });
            this.setState({ rememberMeDetails });
        }
        else {
            const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
                c.rememberMeIsChecked = false;
                c.username = '';
                c.password = '';
                return c;
            });
            this.setState({ rememberMeDetails });
        }
    }
    changeEventTextFieldLogin(fieldName, value) {
        if (fieldName == "username") {
            const loginDetails = this.state.loginDetails.map((c) => {
                c.username = value;
                return c;
            });
            this.setState({ loginDetails });
        }
        else if (fieldName == "password") {
            const loginDetails = this.state.loginDetails.map((c) => {
                c.password = value;
                return c;
            });
            this.setState({ loginDetails });
        }
    }
    async validateTheUserBasedOnApi(username, password, loginData) {
        const systemUser = {
            userEmail: username,
            userPassword: password
        };
        const options = {
            method: "post",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
                /* "Access-Control-Allow-Origin": "*",*/
                "Access-Control-Allow-Origin": `${window.location.origin}`,
            },
            body: JSON.stringify(systemUser),
        };
        const response = await fetch('Login/ValidateUserRequest', options)
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.token !== undefined) {
                    this.state.isTokenValid = responseJson.token;
                    this.FetchLoginUserAuthenticate(loginData);
                }
                else {
                    // dispatch(storeUserDetailsAction(responseJson.result));            
                }
            }).catch(error => {
                alert('Please enter valid credentials');
                console.log("Error:", error)
                this.setState({ isLoading: false });
            });

    }

    async GetUser() {
        const options = {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
        }
        await fetch('Login/GetUser', options)
            .then(response => response.json())
            .then(data => {
                if (data.userId != null && data.userId != '') {
                    let loginDetails = this.state.loginDetails.map((c) => {
                        c.userid = data.userEncryptedId;
                        c.userEncryptedId = data.userEncryptedId;
                        c.applicantid = data.applicantId;
                        c.roleId = data.roleId;
                        c.AREId = data.companyId;
                        c.username = data.userName;
                        c.userfirstname = data.firstName;
                        c.userlastname = data.lastName;
                        c.LoginFlag = data.loginFlag;
                        c.isOTPEnabled = false;
                        c.authToken = data.responseMsg;
                        return c;
                    });

                    this.setState({ loginDetails });
                    let otpDetails = this.state.otpDetails.map((c) => {
                        c.isOTPVerified = true;
                        return c;
                    });
                    this.setState({ otpDetails });
                }
                else {
                    let loginDetails = [...this.initState.loginDetails];
                    this.setState({ loginDetails });
                    let otpDetails = [...this.initState.otpDetails];
                    this.setState({ otpDetails });
                }
            }).catch(error => console.log("Error:", error));
    }

    FetchLoginUserAuthenticate(loginData) {
        const token = this.state.isTokenValid;

        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: loginData
        }
        fetch('Login/LoginUser', options)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    if (data.loginFlag == 1) {
                        if (data.isOTPEnable) {
                            const loginDetails = this.state.loginDetails.map((c) => {
                                c.otp = data.otp;
                                c.isOTPEnabled = data.isOTPEnable;
                                c.loading = false;
                                c.username = data.userName;
                                c.userfirstname = data.firstName;
                                c.userlastname = data.lastName;
                                c.LoginFlag = data.loginFlag;
                                c.emailValid = data.emailValid;
                                c.passwordValid = data.passwordValid;
                                c.authToken = token;
                                return c;
                            });
                            this.setState({ loginDetails });

                            const otpDetails = this.state.otpDetails.map((c) => {
                                c.otpGenerated = data.otp;
                                c.otpSent = true;
                                return c;
                            });
                            this.setState({ otpDetails });
                        }
                        else {
                            if (data.roleId == 1) {
                                window.location.href = '/Home';
                            }
                            else if (data.roleId == 2) {
                                window.location.href = '/Home';
                            }
                            else if (data.roleId == 3) {
                                window.location.href = '/Home';
                            }
                        }
                    }
                    else {
                        const loginDetails = this.state.loginDetails.map((c) => {
                            c.LoginFlag = data.loginFlag;
                            c.emailValid = data.emailValid;
                            c.passwordValid = data.passwordValid;
                            return c;
                        });
                        this.setState({ loginDetails });
                    }
                    this.setState({ isLoading: false });
                }
            }).catch(error => {
                console.log("Error:", error)
                this.setState({ isLoading: false });
            });
    }

    clickEventLogin() {
        this.setState({ isLoading: true });
        const rememberMeDetails = this.state.rememberMeDetails;
        let loginDetails = this.state.loginDetails;
        var userName;
        var passWord;
        var isChecked;
        if (rememberMeDetails[0].rememberMeIsChecked && rememberMeDetails[0].username !== '') {
            userName = rememberMeDetails[0].username;
            passWord = rememberMeDetails[0].password;
            isChecked = rememberMeDetails[0].rememberMeIsChecked;
        }
        else {
            userName = loginDetails[0].username;
            passWord = loginDetails[0].password;
            isChecked = rememberMeDetails[0].rememberMeIsChecked;
        }
        if (userName != "" && passWord != "") {
            if (isChecked) {
                localStorage.UserName = userName;
                localStorage.Password = passWord;
                localStorage.IsChecked = isChecked;
            }
            else {
                localStorage.UserName = '';
                localStorage.Password = '';
                localStorage.IsChecked = false;
            }
            const formDetails = {
                'UserName': userName,
                'Password': passWord,
            };
            var loginData = JSON.stringify(formDetails);
            if (this.state.isTokenValid != '' && loginData != null) {
                this.FetchLoginUserAuthenticate(loginData);
            }
            else {

                this.validateTheUserBasedOnApi(userName, passWord, loginData);
            }

            // this.FetchLoginUserAuthenticate(loginData);
        }
        else {
            this.setState({ isLoading: false });
            alert('Username or Password can not be blank');
        }
    }
    changeEventTextFieldOTP(value) {
        const otpDetails = this.state.otpDetails.map((c) => {
            c.otpEntry = value;
            c.otpSent = false;
            return c;
        })
        this.setState({ otpDetails });
    }
    async clickEventVerifyOTP() {
        this.setState({ isLoading: true });
        let otpDetails = this.state.otpDetails;
        let loginDetails = this.state.loginDetails;
        const token = loginDetails[0].authToken;
        if (otpDetails[0].otpEntry != "") {
            var enteredOtp = otpDetails[0].otpEntry;
            await fetch("Login/isValid/" + enteredOtp, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.token != "" && data.token != undefined) {
                        if (!otpDetails[0].forgetPassword) {
                            const loginDetails = this.state.loginDetails.map((c) => {
                                c.authToken = data.token;
                                return c;
                            });
                            this.setState({ loginDetails });
                            const userName = this.state.loginDetails[0].username;
                            const formDetails = {
                                'userEncryptedId': userName,
                                'otp': otpDetails[0].otpEntry
                            };
                            var loginData = JSON.stringify(formDetails);
                            const options = {
                                method: 'post',
                                headers: {
                                    'Accept': 'application/json, text/plain, */*',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + data.token
                                },
                                body: loginData
                            }
                            fetch('Login/SetSession', options)
                                .then(response => response.json())
                                .then(data => {
                                    if (data) {
                                        otpDetails = this.state.otpDetails.map((c) => {
                                            c.isOTPVerified = true;
                                            c.otpAlertText = '';
                                            c.otpEntry = '';
                                            c.otpGenerated = '';
                                            c.otpSent = false;
                                            return c;
                                        });
                                        this.setState({ otpDetails });

                                        const loginDetails = this.state.loginDetails.map((c) => {
                                            c.otp = data.otp;
                                            c.roleId = data.roleId;
                                            c.AREId = data.companyId;
                                            c.isOTPEnabled = data.isOTPEnable;
                                            c.loading = false;
                                            c.userid = (data.userIdencrypted == null) ? data.userEncryptedId : data.userIdencrypted;
                                            c.userEncryptedId = (data.userIdencrypted == null) ? data.userEncryptedId : data.userIdencrypted;
                                            c.username = data.userName;
                                            c.userfirstname = data.firstName;
                                            c.userlastname = data.lastName;
                                            c.applicantid = data.applicantId;
                                            c.LoginFlag = data.loginFlag;
                                            c.emailValid = data.emailValid;
                                            c.passwordValid = data.passwordValid;
                                            c.isOTPEnabled = false;
                                            c.password = '';
                                            c.otp = '';
                                            return c;
                                        });

                                        this.setState({ loginDetails });
                                        this.setState({ isLoading: false });
                                    }
                                }).catch(error => {
                                    console.log("Error:", error);
                                    this.setState({ isLoading: false });
                                });
                        }
                        else {
                            otpDetails = this.state.otpDetails.map((c) => {
                                c.isOTPVerified = true;
                                c.otpAlertText = '';
                                c.otpEntry = '';
                                c.otpGenerated = '';
                                c.otpSent = false;
                                return c;
                            });
                            this.setState({ otpDetails });
                            const loginDetails = this.state.loginDetails.map((c) => {
                                c.isOTPEnabled = false;
                                c.password = '';
                                c.otp = '';
                                c.authToken = data.token;
                                return c;
                            });
                            this.setState({ loginDetails });
                            this.setState({ isLoading: false });
                        }
                    }
                }).catch(error => {
                    console.log("Error:", error);
                    this.setState({ isLoading: false });

                    otpDetails = this.state.otpDetails.map((c) => {
                        c.otpAlertText = 'Please enter valid OTP or click on RESEND OTP';
                        c.otpSent = false;
                        return c;
                    });
                    this.setState({ otpDetails });

                });
        }
        else {
            otpDetails = this.state.otpDetails.map((c) => {
                c.otpAlertText = 'Please enter valid OTP and proceed';
                c.otpSent = false;
                return c;
            });
            this.setState({ otpDetails });
            this.setState({ isLoading: false });
        }
    }



    clickEventResendOTP(loginDetails) {
        this.setState({ isLoading: true });
        const token = loginDetails[0].authToken;
        if (!loginDetails[0].forgetPassword) {
            const userName = loginDetails[0].username;
            const passWord = loginDetails[0].password;
            const formDetails = {
                'UserName': userName,
                'Password': passWord,
            };
            var loginData = JSON.stringify(formDetails);
            const options = {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    //'Authorization': 'Bearer ' + token
                },
                body: loginData
            }
            fetch('Login/ResendOTP', options)
                .then(response => response.json())
                .then(data => {
                    if (data.otpResendSuccessFlag === 0) {
                        alert(data.responseMsg);
                        this.setState({ isLoading: false });
                    }
                    else if (data.otpResendSuccessFlag === 1) {
                        const loginDetails = this.state.loginDetails.map((c) => {
                            c.otp = data.otp;
                            c.authToken = data.responseMsg;
                            return c;
                        });
                        this.setState({ loginDetails });
                        const otpDetails = this.state.otpDetails.map((c) => {
                            c.otpGenerated = data.otp;
                            c.otpSent = true;
                            return c;
                        });
                        this.setState({ otpDetails });
                        this.setState({ isLoading: false });
                    }
                    else {
                        alert(data.responseMsg);
                        this.setState({ isLoading: false });
                    }
                }).catch(error => {
                    console.log("Error:", error);
                    this.setState({ isLoading: false });
                });
        }
        else {
            this.clickEventForgetPasswordSendOTP(loginDetails);
        }
    }
    clickEventLogout() {
        let loginDetails = this.state.loginDetails;
        const token = loginDetails[0].authToken;
        const options = {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }

        fetch('Login/Logout', options)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    window.location.href = '/';
                }
                else {
                    alert('logout falied');
                }
            }).catch(error => {
                console.log("Error :" + error);
            });

    }
    clickEventForgetPassword() {
        this.setState({ isLoading: true });
        const loginDetails = this.state.loginDetails.map((c) => {
            c.forgetPassword = true;
            return c;
        });
        this.setState({ loginDetails });
        this.setState({ isLoading: false });
    }
    changeEventEmailFieldForgetPassword(value) {
        const loginDetails = this.state.loginDetails.map((c) => {
            c.username = value;
            return c;
        });
        this.setState({ loginDetails });
    }
    clickEventForgetPasswordSendOTP(loginDetails, token) {
        const userName = loginDetails[0].username;
        const forgetData = {
            'UserName': userName
        }
        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(forgetData)
        }
        fetch('Login/SendOTPForgetPassword', options)
            .then(response => response.json())
            .then(data => {
                if (data.isOTPSentForget && data.response) {
                    const loginDetails = this.state.loginDetails.map((c) => {
                        c.otp = data.otp;
                        c.isOTPEnabled = true;
                        c.username = data.userName;
                        c.authToken = token;
                        return c;
                    });
                    this.setState({ loginDetails });
                    const otpDetails = this.state.otpDetails.map((c) => {
                        c.otpGenerated = data.otp;
                        c.otpSent = true;
                        c.forgetPassword = data.forgetFlag;
                        return c;
                    });
                    this.setState({ otpDetails });
                    this.setState({ isLoading: false });
                }
                else {
                    alert(data.responseMsg);
                    this.setState({ isLoading: false });
                }
            }).catch(error => {
                console.log("Error:", error);
                this.setState({ isLoading: false });
            });
    }


    render() {
        const loginDetails = [...this.state.loginDetails];
        const otpDetails = [...this.state.otpDetails];
        const rememberMeDetails = [...this.state.rememberMeDetails];
        const isLoading = this.state.isLoading;
        //let contents = (loginDetails[0].loading)
        //    ? <p><em>Loading...</em></p>
        //    : this.renderActualPage(this.state.loginDetails, this.state.otpDetails);
        //let contents = this.renderActualPage(loginDetails, otpDetails, rememberMeDetails);
        let contents = this.renderActualPage(loginDetails, otpDetails, rememberMeDetails, isLoading);
        //return (isLoading === true)
        //    ?
        //    (
        //        <div>
        //            <CircularProgress />
        //        </div>
        //    )
        //    :
        return (
            <div>
                {contents}
            </div>
        );
    }
    renderActualPage(loginDetails, otpDetails, rememberMeDetails, isLoading) {
        const obPages = ['PrivacyNotice', 'UnauthorizedAccess', 'OTPPage', 'AdminOrRecruiterData', 'ResetPassword', 'UserRegistration', 'CandidateData', 'CandidateRegistration', 'ApplicationForm', 'PreviewApplicationForm', 'DownloadDocuments', 'WorkdayData', "DHLPrerequisites", "PreviewLetters"
        ];
        const taPages = ['PrivacyNotice', 'UnauthorizedAccess', 'OTPPage', 'ResetPassword', 'CandidateData', 'CandidateRegistration', 'ApplicationForm', 'PreviewApplicationForm', "DHLPrerequisites", "PreviewLetters"];
        const candidatePages = ['PrivacyNotice', 'UnauthorizedAccess', 'OTPPage', 'ResetPassword', 'ApplicationForm', 'PreviewApplicationForm', "PreviewLetters"
        ];
        let currentURL = window.location.href;
        var arrURL = currentURL.split('/');
        const appHeader = <AppHeader
            loginDetails={loginDetails}
            otpDetails={otpDetails}
            logoutClick={this.clickEventLogout.bind(this)}
            roleId={loginDetails[0].roleId}
            loader={isLoading}
            token={loginDetails[0].authToken}
        />;
        const timeTicker = <TimeTicker
            loginDetails={loginDetails}
            eventHandler={this.updateExpiredTime.bind(this)}
        />;
        const scrollToTop = <ScrollToTop />;
        if (loginDetails[0].isOTPEnabled) {
            var routes = (
                <Switch>
                    <Route exact path="/OTPPage"
                        render={() =>
                            <OTPpage
                                loginDetails={loginDetails}
                                otpDetails={otpDetails}
                                otpChangeEvent={this.changeEventTextFieldOTP.bind(this)}
                                otpClickVerify={this.clickEventVerifyOTP.bind(this)}
                                otpClickResend={this.clickEventResendOTP.bind(this)}
                                url={"http://localhost:5000/OTPPage"}
                                loader={isLoading}
                                token={loginDetails[0].authToken}
                            />
                        }
                    />
                    <Redirect from="/" to="/OTPPage" />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routes}</main>
                        {scrollToTop}
                        {timeTicker}
                    </div>
                </BrowserRouter>
            );
        }
        else if (loginDetails[0].LoginFlag === 0 && loginDetails[0].username != '' && loginDetails[0].emailValid === 0) {
            var routes = (
                <Switch>
                    <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                    <Redirect from="/" to="/UnauthorizedAccess" />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routes}</main>
                        {scrollToTop}
                        {timeTicker}
                    </div>
                </BrowserRouter>
            );
        }
        else if (loginDetails[0].LoginFlag === 0 && loginDetails[0].username != '' && loginDetails[0].emailValid === 1 && loginDetails[0].passwordValid === 0) {


            alert('Please enter correct Password');
            loginDetails[0].passwordValid = 1;
            // window.location.href = '/';
            var routes = (
                <Switch>
                    <Route exact path="/" render={() =>
                        <LoginPage
                            loginDetails={loginDetails}
                            loginClick={this.clickEventLogin.bind(this)}
                            loginChangeEvent={this.changeEventTextFieldLogin.bind(this)}
                            forgetPasswordClick={this.clickEventForgetPassword.bind(this)}
                            onChangeRememberMe={this.changeEventRememberMe.bind(this)}
                            rememberMeDetails={rememberMeDetails}
                        />
                    }
                    />
                    <Redirect from="/" to="/LoginPage" />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routes}</main>
                        {scrollToTop}
                        {timeTicker}
                    </div>
                </BrowserRouter>
            );


        }

        // else if (loginDetails[0].LoginFlag === 0 && loginDetails[0].username != '') {
        //    var routes = (
        //        <Switch>
        //            <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess />} />
        //            <Redirect from="/" to="/UnauthorizedAccess" />
        //        </Switch>
        //    );
        //    return (
        //        <BrowserRouter>
        //            <div>
        //                {appHeader}
        //                <main className="">{routes}</main>
        //            </div>
        //        </BrowserRouter>
        //    );
        //}
        else if (loginDetails[0].LoginFlag === 1 && loginDetails[0].username != '' && otpDetails[0].isOTPVerified) {
            if (loginDetails[0].roleId == 1) {
                if (!(arrURL[3].includes(obPages[0]) || arrURL[3].includes(obPages[1]) || arrURL[3].includes(obPages[2])
                    || arrURL[3].includes(obPages[3]) || arrURL[3].includes(obPages[4]) || arrURL[3].includes(obPages[5])
                    || arrURL[3].includes(obPages[6]) || arrURL[3].includes(obPages[7]) || arrURL[3].includes(obPages[8])
                    || arrURL[3].includes(obPages[9]) || arrURL[3].includes(obPages[10]) || arrURL[3].includes(obPages[11])
                    || arrURL[3].includes(obPages[12]) || arrURL[3].includes(obPages[13]))) {
                    var routes = (
                        <Switch>
                            <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                            <Redirect from="/" to="/UnauthorizedAccess" />
                        </Switch>
                    );
                    return (
                        <BrowserRouter>
                            <div>
                                {appHeader}
                                <main className="">{routes}</main>
                                {scrollToTop}
                                {timeTicker}
                            </div>
                        </BrowserRouter>
                    );
                }
                else {
                    var routes =
                        (
                            <Switch>
                                <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                                <Route exact path="/AdminOrRecruiterData" render={(props) =>
                                    <AdminOrRecruiterData
                                        loginDetails={loginDetails}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        url={"http://localhost:5000/AdminOrRecruiterData"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Redirect from="/OTPPage" to="/AdminOrRecruiterData" />

                                <Route exact path="/ResetPassword" render={(props) =>
                                    <ResetPassword
                                        loginDetails={loginDetails}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        logout={this.clickEventLogout.bind(this)}
                                        url={"http://localhost:5000/ResetPassword"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/UserRegistration*" render={(props) =>
                                    <UserRegistration
                                        loginDetails={loginDetails}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        url={"http://localhost:5000/UserRegistration"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/CandidateData" render={(props) =>
                                    <CandidateData
                                        loginDetails={loginDetails}
                                        UserFullName={loginDetails[0].userfirstname + " " + loginDetails[0].userlastname}
                                        applicantId={loginDetails[0].applicantid}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        userId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        url={"http://localhost:5000/CandidateData"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/CandidateRegistration*" render={(props) =>
                                    <CandidateRegistration
                                        loginDetails={loginDetails}
                                        applicantId={loginDetails[0].applicantid}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        userId={loginDetails[0].userEncryptedId}
                                        url={"http://localhost:5000/CandidateRegistration"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                        roleId={loginDetails[0].roleId}
                                        AREId={loginDetails[0].AREId}
                                    />
                                }
                                />
                                <Route exact path="/ApplicationForm*" render={(props) =>
                                    <ApplicationForm
                                        loginDetails={loginDetails[0]}
                                        applicantId={loginDetails[0].applicantid}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        userId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        url={"http://localhost:5000/ApplicationForm"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                        AREId={loginDetails[0].AREId}
                                    />
                                }
                                />
                                <Route exact path="/PreviewApplicationForm*" render={(props) =>
                                    <ApplicationFormPreview
                                        loginDetails={loginDetails[0]}
                                        applicantId={loginDetails[0].applicantid}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        userId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/DownloadDocuments" render={() => <DownloadDocuments token={loginDetails[0].authToken} />} />
                                <Route exact path="/WorkdayData" render={(props) =>
                                    <WorkdayData
                                        loginDetails={loginDetails[0]}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        url={"http://localhost:5000/WorkdayData"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/PrivacyNotice" render={() => <PrivacyNotice />} />
                                <Route
                                    exact
                                    path="/DHLPrerequisites"
                                    render={() => (
                                        <DHLPrerequisites
                                            token={loginDetails[0].authToken}
                                            userId={loginDetails[0].userid}
                                            roleId={loginDetails[0].roleId}
                                            url={"http://localhost:5000/DHLPrerequisites"}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/PreviewLetters"
                                    render={() => (
                                        <PreviewLetters
                                            token={loginDetails[0].authToken}
                                            userId={loginDetails[0].userid}
                                            roleId={loginDetails[0].roleId}
                                            url={"http://localhost:5000/PreviewLetters"}
                                        />
                                    )}
                                />

                            </Switch>
                        );
                    return (
                        <BrowserRouter>
                            <div>
                                {appHeader}
                                <main className="">{routes}</main>
                                {scrollToTop}
                                {timeTicker}
                            </div>
                        </BrowserRouter >);
                }
            }
            else if (loginDetails[0].roleId == 2 || loginDetails[0].roleId == 4) {
                if (!(arrURL[3].includes(taPages[0]) || arrURL[3].includes(taPages[1]) || arrURL[3].includes(taPages[2])
                    || arrURL[3].includes(taPages[3]) || arrURL[3].includes(taPages[4]) || arrURL[3].includes(taPages[5])
                    || arrURL[3].includes(taPages[6]) || arrURL[3].includes(taPages[7]) || arrURL[3].includes(taPages[8]) || arrURL[3].includes(taPages[9]))) {
                    var routes = (
                        <Switch>
                            <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                            <Redirect from="/" to="/UnauthorizedAccess" />
                        </Switch>
                    );
                    return (
                        <BrowserRouter>
                            <div>
                                {appHeader}
                                <main className="">{routes}</main>
                                {scrollToTop}
                                {timeTicker}
                            </div>
                        </BrowserRouter>
                    );
                }
                else {
                    var routes =
                        (
                            <Switch>
                                <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                                <Route exact path="/CandidateData" render={(props) =>
                                    <CandidateData
                                        loginDetails={loginDetails}
                                        UserFullName={loginDetails[0].userfirstname + " " + loginDetails[0].userlastname}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        applicantId={loginDetails[0].applicantid}
                                        roleId={loginDetails[0].roleId}
                                        url={"http://localhost:5000/CandidateData"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Redirect from="/OTPPage" to="/CandidateData" />

                                <Route exact path="/ResetPassword" render={(props) =>
                                    <ResetPassword
                                        loginDetails={loginDetails}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        logout={this.clickEventLogout.bind(this)}
                                        url={"http://localhost:5000/ResetPassword"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/CandidateRegistration*" render={(props) =>
                                    <CandidateRegistration
                                        loginDetails={loginDetails}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        url={"http://localhost:5000/CandidateRegistration"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                        roleId={loginDetails[0].roleId}
                                        AREId={loginDetails[0].AREId}
                                    />
                                }
                                />
                                <Route exact path="/ApplicationForm*" render={(props) =>
                                    <ApplicationForm
                                        loginDetails={loginDetails[0]}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        url={"http://localhost:5000/ApplicationForm"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                        AREId={loginDetails[0].AREId}
                                    />
                                }
                                />
                                <Route exact path="/PreviewApplicationForm*" render={(props) =>
                                    <ApplicationFormPreview
                                        loginDetails={loginDetails[0]}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/DownloadDocuments" render={() => <DownloadDocuments token={loginDetails[0].authToken} />} />
                                <Route exact path="/PrivacyNotice" render={() => <PrivacyNotice />} />
                                <Route
                                    exact
                                    path="/DHLPrerequisites"
                                    render={() => (
                                        <DHLPrerequisites
                                            token={loginDetails[0].authToken}
                                            userId={loginDetails[0].userid}
                                            roleId={loginDetails[0].roleId}
                                            url={"http://localhost:5000/DHLPrerequisites"}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/PreviewLetters"
                                    render={() => (
                                        <PreviewLetters
                                            token={loginDetails[0].authToken}
                                            userId={loginDetails[0].userid}
                                            roleId={loginDetails[0].roleId}
                                            url={"http://localhost:5000/PreviewLetters"}
                                        />
                                    )}
                                />
                            </Switch>

                        );
                    return (
                        <BrowserRouter>
                            <div>
                                {appHeader}
                                <main className="">{routes}</main>
                                {scrollToTop}
                                {timeTicker}
                            </div>
                        </BrowserRouter >);
                }
            }
            else if (loginDetails[0].roleId == 3) {
                if (!(arrURL[3].includes(candidatePages[0]) || arrURL[3].includes(candidatePages[1]) || arrURL[3].includes(candidatePages[2])
                    || arrURL[3].includes(candidatePages[3]) || arrURL[3].includes(candidatePages[4]) || arrURL[3].includes(candidatePages[5]) || arrURL[3].includes(candidatePages[6]))) {
                    var routes = (
                        <Switch>
                            <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                            <Redirect from="/" to="/UnauthorizedAccess" />
                        </Switch>
                    );
                    return (
                        <BrowserRouter>
                            <div>
                                {appHeader}
                                <main className="">{routes}</main>
                                {scrollToTop}
                                {timeTicker}
                            </div>
                        </BrowserRouter>
                    );
                }
                else {
                    var routes =
                        (
                            <Switch>
                                <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                                <Route exact path="/ApplicationForm" render={(props) =>
                                    <ApplicationForm
                                        loginDetails={loginDetails[0]}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        url={"http://localhost:5000/ApplicationForm"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                        AREId={loginDetails[0].AREId}
                                    />
                                }
                                />
                                <Redirect from="/OTPPage" to="/ApplicationForm" />

                                <Route exact path="/ResetPassword" render={(props) =>
                                    <ResetPassword
                                        loginDetails={loginDetails}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        logout={this.clickEventLogout.bind(this)}
                                        url={"http://localhost:5000/ResetPassword"}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/PreviewApplicationForm" render={(props) =>
                                    <ApplicationFormPreview
                                        loginDetails={loginDetails[0]}
                                        applicantId={loginDetails[0].applicantid}
                                        userId={loginDetails[0].userEncryptedId}
                                        userEncryptedId={loginDetails[0].userEncryptedId}
                                        roleId={loginDetails[0].roleId}
                                        loader={isLoading}
                                        token={loginDetails[0].authToken}
                                    />
                                }
                                />
                                <Route exact path="/PrivacyNotice" render={() => <PrivacyNotice />} />
                                <Route
                                    exact
                                    path="/PreviewLetters"
                                    render={() => (
                                        <PreviewLetters
                                            token={loginDetails[0].authToken}
                                            userId={loginDetails[0].userid}
                                            roleId={loginDetails[0].roleId}
                                            url={"http://localhost:5000/PreviewLetters"}
                                        />
                                    )}
                                />
                            </Switch>

                        );
                    return (
                        <BrowserRouter>
                            <div>
                                {appHeader}
                                <main className="">{routes}</main>
                                {scrollToTop}
                                {timeTicker}
                            </div>
                        </BrowserRouter >);
                }
            }
        }
        else if (loginDetails[0].LoginFlag === '' && loginDetails[0].forgetPassword) {
            if (otpDetails[0].isOTPVerified) {
                const routes = (
                    <Switch>
                        <Route exact path="/ResetPassword" render={(props) =>
                            <ResetPassword
                                loginDetails={loginDetails}
                                userId={loginDetails[0].userEncryptedId}
                                userEncryptedId={loginDetails[0].userEncryptedId}
                                logout={this.clickEventLogout.bind(this)}
                                url={"http://localhost:5000/ResetPassword"}
                                loader={isLoading}
                                token={loginDetails[0].authToken}
                            />
                        }
                        />
                        <Redirect from="/OTPPage" to="/ResetPassword" />
                    </Switch>
                )
                return (
                    <BrowserRouter>
                        <div>
                            {appHeader}
                            <main className="">{routes}</main>
                            {scrollToTop}
                            {timeTicker}
                        </div>
                    </BrowserRouter>
                );
            }
            else if (otpDetails[0].isOTPEnabled) {
                var routes = (
                    <Switch>
                        <Route exact path="/OTPPage"
                            render={() =>
                                <OTPpage
                                    loginDetails={loginDetails}
                                    otpDetails={otpDetails}
                                    otpChangeEvent={this.changeEventTextFieldOTP.bind(this)}
                                    otpClickVerify={this.clickEventVerifyOTP.bind(this)}
                                    otpClickResend={this.clickEventResendOTP.bind(this)}
                                    url={"http://localhost:5000/OTPPage"}
                                    loader={isLoading}
                                    token={loginDetails[0].authToken}
                                />
                            }
                        />
                        <Redirect from="/ForgetPassword" to="/OTPPage" />
                    </Switch>
                );
                return (
                    <BrowserRouter>
                        <div>
                            {appHeader}
                            <main className="">{routes}</main>
                            {scrollToTop}
                            {timeTicker}
                        </div>
                    </BrowserRouter>
                );
            }
            else {
                const routes = (
                    <Switch>
                        <Route exact path="/ForgetPassword" render={() =>
                            <ForgetPassword
                                loginData={loginDetails}
                                otpData={otpDetails}
                                sendOTPClick={this.clickEventForgetPasswordSendOTP.bind(this)}
                                emailFieldOnChange={this.changeEventEmailFieldForgetPassword.bind(this)}
                                loader={isLoading}
                                token={loginDetails[0].authToken}
                            />
                        }
                        />
                        <Redirect from="/" to="/ForgetPassword" />
                    </Switch>
                )
                return (
                    <BrowserRouter>
                        <div>
                            {appHeader}
                            <main className="">{routes}</main>
                            {scrollToTop}
                            {timeTicker}
                        </div>
                    </BrowserRouter>
                );
            }
        }
        else {
            const routes = (
                <Switch>
                    <Route exact path="/" render={() =>
                        <LoginPage
                            loginDetails={loginDetails}
                            loginClick={this.clickEventLogin.bind(this)}
                            loginChangeEvent={this.changeEventTextFieldLogin.bind(this)}
                            forgetPasswordClick={this.clickEventForgetPassword.bind(this)}
                            onChangeRememberMe={this.changeEventRememberMe.bind(this)}
                            rememberMeDetails={rememberMeDetails}
                            loader={isLoading}
                        />
                    }
                    />

                    <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                    <Route exact path="/PrivacyNotice" render={() => <PrivacyNotice />} />
                    {/*<Route exact path="/ForgetPassword" render={() => <ForgetPassword />} />*/}

                    {/*<Route exact path="/ResetPassword" render={() => <ResetPassword />} />*/}

                    {/*<Route exact path="/UserRegistration*" render={() => <UserRegistration />} />*/}

                    {/*<Route exact path="/CandidateRegistration*" render={() => <CandidateRegistration />} />*/}

                    {/*<Route exact path="/ApplicationForm*" render={(props) => <ApplicationForm />} />*/}

                    {/*<Route exact path="/AdminOrRecruiterData" render={(props) => <AdminOrRecruiterData />} />*/}

                    {/*<Route exact path="/CandidateData" render={() => <CandidateData />} />*/}

                    {/*<Route exact path="/UnauthorizedRoute" render={() => <UnauthorizedRoute />} />*/}

                    {/*<Route exact path="/PreviewApplicationForm" render={(props) => <ApplicationFormPreview />} />*/}

                    {/*<Route exact path="/OnboardingFormPreview" render={() => <OnboardingFormPreview />} />*/}

                    {/*<Route exact path="/SHPL" render={() => <SHPL />} />*/}

                    {/*<Route exact path="/Gratuity" render={() => <Gratuity />} />*/}

                    {/*<Route exact path="/STSPL" render={() => <STSPL />} />*/}

                    {/*<Route exact path="/Superannuation" render={() => <Superannuation />} />*/}

                    {/*<Route exact path="/SLLIC" render={() => <SLLIC />} />*/}

                    {/*<Route exact path="/FORM2" render={() => <FORM2 />} />*/}

                    {/*<Route exact path="/EmployeeConsentForm" render={() => <EmployeeConsentForm />} />*/}

                    {/*<Route exact path="/DownloadDocuments" render={() => <DownloadDocuments />} />*/}

                    {/*<Route exact path="/UploadSupportingDocuments" render={() => <UploadSupportingDocuments />} />*/}

                    {/*<Route exact path="/otpPage" render={() =>*/}
                    {/*    <OTPpage*/}
                    {/*        loginDetails={this.state.loginDetails}*/}
                    {/*    />*/}
                    {/*}*/}
                    {/*/>*/}
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routes}</main>
                        {scrollToTop}
                        {timeTicker}
                    </div>
                </BrowserRouter>);
        }

    };

    //render() {
    //    const loginDetails = { ...this.state.loginDetails };

    //    if (loginDetails[0].isOTPEnabled) {
    //        return (
    //            <BrowserRouter>
    //                <div component={Link} to={this.state.loginDetails.redirect}></div>
    //            </BrowserRouter>);

    //    }
    //    const routes = (<Switch>
    //        <Route exact path="/" render={() =>
    //            <LoginPage
    //                loginDetails={this.state.loginDetails}
    //                loginClick={this.clickEventLogin}
    //                loginChangeEvent={this.changeEventTextFieldLogin.bind(this)}
    //            />
    //        }
    //        />

    //        <Route exact path="/ForgetPassword" render={() => <ForgetPassword />} />

    //        <Route exact path="/ResetPassword" render={() => <ResetPassword />} />

    //        <Route exact path="/UserRegistration*" render={() => <UserRegistration />} />

    //        <Route exact path="/CandidateRegistration*" render={() => <CandidateRegistration />} />

    //        <Route exact path="/ApplicationForm" render={() => <ApplicationForm />} />

    //        <Route exact path="/AdminOrRecruiterData" render={() => <AdminOrRecruiterData />} />

    //        <Route exact path="/CandidateData" render={() => <CandidateData />} />

    //        <Route exact path="/otpPage" render={() =>
    //            <OTPpage
    //                loginDetails={this.state.loginDetails}
    //            />
    //        }
    //        />
    //    </Switch>
    //    );
    //    return (
    //        <BrowserRouter>
    //            <div>
    //                <AppHeader />
    //                <main className="">{routes}</main>
    //            </div>
    //        </BrowserRouter>);
    //}

}
