import { combineReducers } from "redux";

let inputDataDHLInitialState = {
  inputDataDHL: {
    applicantId: "",
    userIdencrypted: "",
    firstName: "",
    lastName: "",
    email: "",
    areObj: {},
    taname: "",
    taemail: "",
    address: "",
    dateOfBirth: null,
    mprnumber: "",
    referenceNo: "",
    letterType: 0,
    letterTypeLabel: "",
    letterTypeObj: {},
    letterTemplateType: 0,
    letterTemplateTypeLabel: "",
    letterTemplateTypeObj: {},
    noOfSignatories: 0,
    noOfSignatoriesLabel: "",
    noOfSignatoriesObj: {},
    signatoryDetails: [],
    employeeType: 0,
    employeeTypeLabel: "",
    employeeTypeObj: {},
    jobLocation: 0,
    jobLocationLabel: "",
    jobLocationObj: {},
    jobTitle: 0,
    jobTitleLabel: "",
    jobTitleObj: {},
    businessTitle: "",
    businessUnit: "",
    grip: 0,
    gripLabel: "",
    gripCode: "",
    gripObj: {},
    staff: 0,
    staffLabel: "",
    staffObj: {},
    compensationGrade: 0,
    compensationGradeLabel: "",
    compensationGradeObj: {},
    compensationGradeProfile: 0,
    compensationGradeProfileLabel: "",
    compensationGradeProfileObj: {},
    payPlan: 1,
    payPlanLabel: "Salary Plan",
    payPlanObj: { id: 1, name: "Salary Plan" },
    performencePayPlan: 0,
    performencePayPlanLabel: "",
    performencePayPlanObj: {},
    salaryAmount: 0,
    performencePay: 0,
    targetPay: 0,
    allowancePlan: "",
    allowancePlanLabel: "",
    allowancePlanObj: [],
    allowanceAmountObj: [],
    bfp: 0,
    bp: 0,
    bpa: 0,
    hra: 0,
    totalRetirals: 0,
    pfContri: 0,
    gratuityContri: 0,
    negotiationLetterType: 0,
    negotiationLetterTypeLabel: "",
    negotiationLetterTypeObj: {},
    compensationLetterUploadId: 0,
    isCompensationLetterUploaded: false,
    isNegotiationSent: false,
    digiLetterWorkflowId: 0,
    isCompensationAccepted: true,
    isCompensationRejected: false,
    digiLetterStatusId: 0,
    isOfferAccepted: false,
    isOfferRejected: false,
    offerLetterUploadId: 0,
    isOfferLetteruploaded: false,
    siemensLogo: "",
  }
};

const inputDataDHLReducer = (
  state = inputDataDHLInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "inputDataDHL": {
      return {
        inputDataDHL: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

let compensationAmountInitialState = {
    compensationAmount: {
    targetPay: 0.0,
    basicpa: 0.0,
    basicpm: 0.0,
    tfppa: 0.0,
    tfppm: 0.0,
    bfppm: 0.0,
    pfpm: 0.0,
    gratuitypm: 0.0,
    totalRetiralspm: 0.0,
    totalRetiralspa: 0.0,
    tapa: 0.0,
    bppa: 0.0,
    pppa: 0.0,
    elepa: 0.0,
    hra: 0.0,
    bpa: 0.0,
    }
};
const compensationDataReducer = (
    state = compensationAmountInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "compensationDetails": {
            return {
                compensationDetails: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};
let compensationWSAAmountInitialState = {
    targetPay: 0.0,
    basicpa: 0.0,
    basicpm: 0.0,
    tfppa: 0.0,
    tfppm: 0.0,
    bfppm: 0.0,
    pfpm: 0.0,
    gratuitypm: 0.0,
    safpm: 0.0,
    totalRetiralspm: 0.0,
    totalRetiralspa: 0.0,
    tapa: 0.0,
    bppa: 0.0,
    pppa: 0.0,
    elepa: 0.0,
    hra: 0.0,
    bpa: 0.0,
};
const compensationWSADataReducer = (
    state = compensationWSAAmountInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "compensationWSADetails": {
            return {
                compensationWSADetails: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};

let insuranceDetailsInitialState = {
    ghic: 0.0,
    gpaic: 0.0,
    gtLic: 0.0,
    edli: 0.0,
    esic: "",
};
const insuranceDetailsReducer = (
    state = insuranceDetailsInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "insuranceDetails": {
            return {
                insuranceDetails: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};

let insuranceDataInitialState = {
    id: 0,
        gripId: 0,
            ghicAmount: 0.0,
                gpaicAmount: 0.0,
                    gtLicAmount: 0.0,
                        edliAmount: 0.0,
                            micAmount: 0.0,
};
const insuranceDataReducer = (
    state = insuranceDataInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "insuranceData": {
            return {
                insuranceData: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export const masterReducer = combineReducers({
    inputDataDHLReducer: inputDataDHLReducer,
    compensationDataReducer: compensationDataReducer,
    compensationWSADataReducer: compensationWSADataReducer,
    insuranceDetailsReducer: insuranceDetailsReducer,
    insuranceDataReducer: insuranceDataReducer
});
