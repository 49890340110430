import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";


export default function EmployeeConsentForm(props) {

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var sParameterName = sURLVariables[i].split('=');
            //var value = '';
            //for (var j = 0; j < sParameterName.length; j++) {
            //    if (sParameterName[j] == sParam) {
            //        for (var k = j + 1; k < sParameterName.length; k++) {
            //            value = value + sParameterName[k];
            //        }
            //        break;
            //    }
            //    //return sParameterName[1];
            //}

            if (!value) {

                continue;

            }

            else {

                break;

            }
            
        }
        return value;
    }
    var encryptedId = getURLParameter('id');
    var id, data;

    const [departmentData, setDepartmentData] = useState([]);
    const departmentDataTemp = [];


    function SetStateAsPerFetched(data) {
        //Department and Sector Data
        departmentDataTemp.push({
            'department': data.department ? data.department : "", 'sector': data.sector ? data.sector : "",
            'placeOfWork': data.placeOfWork ? data.placeOfWork : "", 'joiningDate': data.joiningDateStr

        });

        setDepartmentData(departmentDataTemp);
    }

    function GetDocumentsDataById(encryptedId) {
        id = encryptedId;
        const token = props.token;
        fetch("HRDetails/GetAllDocumentsDownload/" + id, { headers: { 'Authorization': 'Bearer ' + token } })
            .then(response => response.json())
            .then(data => {
                SetStateAsPerFetched(data);

            })
            .catch(error => console.log("Error:", error));

    }

    useEffect(() => {
        if (props.token) {
            if (encryptedId != undefined && encryptedId != "" && encryptedId != null) {
                GetDocumentsDataById(encryptedId);

            }
        }

    }, [encryptedId, props.token]);


    return (
        <div className="master-layout-blockPrint mt-5 pagebreakAfter">
            <table className="width-100">
               

                <tbody className="f-12">
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-2e">
                                GID : <span className="text-underline">{props.gid}</span>
                                </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">
                                Department: {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.department}</span>)}
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">
                                Location: {departmentData.map(departmentDataNew => <span className="text-underline">{departmentDataNew.placeOfWork}</span>)}
                            </span>
                        </td>
                    </tr >

                    <tr>
                        <td className="d-flex jc-center">
                            <span className="mt-1 f-14 fw-bold text-underline">
                                Form of consent & commitment of employees</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">I hereby confirm that personal information including the following sensitive personal information
                            has been provided by me in connection with my employment related contract with Siemens Healthcare Pvt. Ltd. (“Company’) and
                            its Group of Companies:</span>
                        </td>
                    </tr >
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">(i) financial information such as Bank Account or credit card or debit card or other payment
                                instrument details;</span>
                        </td>
                    </tr >
                    
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">(ii) physical, physiological and mental health condition;</span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">(iii) medical records and history;</span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">(iv) biometric information.</span>
                        </td>
                    </tr >

                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">1. I understand and agree that the above employee related information is collected
                            & processed by the company or its authorized agencies for employment purposes or such other incidental or
                            ancillary as the Company may require to perform its duty of employer.
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">2. I hereby consent to the collection, processing & the storage and use of my personal
                            data and its transfer to any other Siemens entity or a third party for further processing, storage or archiving
                            in line with Siemens archiving & processing policy.
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">3. I confirm that I understand that I am not allowed, unless autorised, to collect, process 
                            or use protected personal data which became known to me either inside or outside the Company during my employment.
                            I further understand that the duty to observe confidentiality shall continue in the event of transfer or following 
                            termination of my employment.
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">4. I confirm that I am aware that the duty to observe data secrecy in addition to 
                            confidentiality shall also apply not only during my employment but also subsequent to the termination of my employment in the Company.
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="f-12 mt-1">
                            <span className="f-12 mt-1">5. I further understand that my consent is voluntary and that I have the right to withdraw
                            my data protection consent with future effect at any time by contacting HR.
                            </span>
                        </td>
                    </tr >

                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">In addition, I am aware that the above mentioned information can be handed over to any 
                            statutory body for all statutory or legal purposes.
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">P.S. - Employee signature or online acceptance of the above is binding in nature and
                            indicates Employee’s complete acceptance of the terms and condition mentioned hereinabove.
                            </span>
                        </td>
                    </tr >

                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">Signature :
                            </span>
                        </td>
                    </tr >
                    <tr>
                        <td className="d-flex jc-start">
                            <span className="f-12 mt-1">Date : {departmentData.map(departmentDataNew => <span>{departmentDataNew.joiningDate}</span>)}
                            </span>
                        </td>
                    </tr >
                </tbody>
            </table>

        </div>
        );

}