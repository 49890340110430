import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { inputDataDHLAction } from "../../Redux/Actions/actions";
import CompensationDetailsDHL from "./CompensationDetailsDHL";

export default function LetterTypeSelection(props) {
    const dispatch = useDispatch();
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }
    const storedInputDataDHL = useSelector(state => state.inputDataDHLReducer.inputDataDHL);
    let [inputDataToDHL, setInputDataToDHL] = useState({
        applicantId: getURLParameter("id"),
        userIdencrypted: props.userId,
        firstName: "",
        lastName: "",
        email: "",
        areObj: {},
        taname: "",
        taemail: "",
        address: "",
        dateOfBirth: null,
        mprnumber: "",
        referenceNo: "",
        letterType: 0,
        letterTypeLabel: "",
        letterTypeObj: {},
        letterTemplateType: 0,
        letterTemplateTypeLabel: "",
        letterTemplateTypeObj: {},
        noOfSignatories: 0,
        noOfSignatoriesLabel: "",
        noOfSignatoriesObj: {},
        signatoryDetails: [],
        employeeType: 0,
        employeeTypeLabel: "",
        employeeTypeObj: {},
        jobLocation: 0,
        jobLocationLabel: "",
        jobLocationObj: {},
        jobTitle: 0,
        jobTitleLabel: "",
        jobTitleObj: {},
        businessTitle: "",
        businessUnit: "",
        grip: 0,
        gripLabel: "",
        gripCode: "",
        gripObj: {},
        staff: 0,
        staffLabel: "",
        staffObj: {},
        compensationGrade: 0,
        compensationGradeLabel: "",
        compensationGradeObj: {},
        compensationGradeProfile: 0,
        compensationGradeProfileLabel: "",
        compensationGradeProfileObj: {},
        payPlan: 1,
        payPlanLabel: "Salary Plan",
        payPlanObj: { id: 1, name: "Salary Plan" },
        performencePayPlan: 0,
        performencePayPlanLabel: "",
        performencePayPlanObj: {},
        salaryAmount: 0,
        performencePay: 0,
        targetPay: 0,
        allowancePlan: "",
        allowancePlanLabel: "",
        allowancePlanObj: [],
        allowanceAmountObj: [],
        bfp: 0,
        bp: 0,
        bpa: 0,
        hra: 0,
        totalRetirals: 0,
        pfContri: 0,
        gratuityContri: 0,
        negotiationLetterType: 0,
        negotiationLetterTypeLabel: "",
        negotiationLetterTypeObj: {},
        compensationLetterUploadId: 0,
        isCompensationLetterUploaded: false,
        isNegotiationSent: false,
        digiLetterWorkflowId: 0,
        isCompensationAccepted: true,
        isCompensationRejected: false,
        digiLetterStatusId: 0,
        isOfferAccepted: false,
        isOfferRejected: false,
        offerLetterUploadId: 0,
        isOfferLetteruploaded: false,
        siemensLogo: "../assets/images/sie-logo-petrol-rgb.png",
    });
    const handleAutoCompleteChange = (e, newValue) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        switch (elemName) {
            case "letterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName]: newValue ? newValue.id : 0,
                //     [elemName + "Label"]: newValue ? newValue.name : "",
                //     [elemName + "Obj"]: newValue,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName]: newValue ? newValue.id : 0,
                    [elemName + "Label"]: newValue ? newValue.name : "",
                    [elemName + "Obj"]: newValue,
                };
                setInputDataToDHL(inputUpdatedDHL);
                localStorage.setItem("showCompensation2", "true");
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
        console.log("Letter Type Selection Change", inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));

    };

    const handleAutoCompleteTextChange = (e, newText) => {
        let elemName = e.target.id.includes("-")
            ? e.target.id.substring(0, e.target.id.indexOf("-"))
            : e.target.id;
        let inputUpdatedDHL = {};
        switch (elemName) {
            case "letterType":
                // setInputDataToDHL({
                //     ...inputDataToDHL,
                //     [elemName + "Label"]: newText,
                // });
                inputUpdatedDHL = {
                    ...storedInputDataDHL,
                    [elemName + "Label"]: newText,
                };
                setInputDataToDHL(inputUpdatedDHL);
                //dispatch(inputDataDHLAction(inputUpdatedDHL));
                break;
            default:
                break;
        }
        console.log("Letter Type Selection Text Change", inputUpdatedDHL);
        dispatch(inputDataDHLAction(inputUpdatedDHL));
    };

  let [itemsLetterType, setItemsLetterTypes] = useState([]);
  async function getLetterTypes() {
    const token = props.token;
    const options = {
      method: "get",
      url: "OnboardingDHLMerge/GetAllLetterTypes",
      headers: {
          "Access-Control-Allow-Origin": `${window.location.origin}`,
        Authorization: "Bearer " + token,
      },
    };
    const response = await axios(options);
    setItemsLetterTypes(
      response?.data?.map((c) => ({
        id: c.letterTypeId,
        name: c.letterTypeName,
      }))
    );
  }
  useEffect(() => {
    if (props) {
      getLetterTypes();
    }
  }, [props]);
  return (
    <>
      <Grid container spacing={1} className="d-flex jc-space-around fd-row">
        <Grid item xs={12} sm={12} className="d-flex jc-center Width-100">
          <h5 className="clr-dark-blue">Letter Type Selection</h5>
        </Grid>
        <Grid item xs={12} sm={9} className="d-flex Width-100">
          <Autocomplete
            disableClearable={true}
            className="lightInputFields hover-lightblue mt-1"
            id="letterType"
            fullWidth
            value={storedInputDataDHL.letterTypeObj}
            inputValue={storedInputDataDHL.letterTypeLabel}
            options={itemsLetterType}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            onChange={handleAutoCompleteChange}
            onInputChange={handleAutoCompleteTextChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Letter Templates"
                name="letterType"
                variant="filled"
                required
                // error={
                //   props.inputDataToDHL.letterTypeErrorText &&
                //   props.inputDataToDHL.letterTypeErrorText.length > 0
                // }
                // helperText={props.inputDataToDHL.letterTypeErrorText}
                style={{ color: "#f00" }}
              />
            )}
          />
              </Grid>
      </Grid>
    </>
  );
}
