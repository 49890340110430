import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import Box from "@material-ui/core/Box";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import FileSaver from "file-saver";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormDatePicker from "../pages/components/DatePicker";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ListItemText from "@material-ui/core/ListItemText";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../components/Loader";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "000028",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "transparent",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "1px",
        fontSize: "11px !important",
        //border: "1px solid #CCC"
        alignItems: "center",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#000028",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#FFFFFF",
      },
      container: {
        background: "",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: ""
    //    }
    //},
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
      root: {
        minWidth: "40px !important",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
        fontSize: "13px",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function CandidateData(props) {
  const [formHeader, setFormHeader] = useState("Candidate Data");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [initiatorName, setInitiatorName] = useState("");
  const [data, setData] = useState([]);
  const [applicantId, setApplicantId] = useState("");
  const [status, setStatus] = useState(0);
  const [statusObj, setStatusObj] = useState("");
  const [statusText, setStatusText] = useState("");
  const [are, setARE] = useState("");
  const [areObj, setAREObj] = useState("");
  const [areText, setAREText] = useState("");
  const [doj, setDOJ] = useState(null);
  const [cc, setCC] = useState("");
  const [ccObj, setCCObj] = useState("");
  const [ccText, setCCText] = useState("");
  const [isOldCC, setIsOldCC] = useState(false);

  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");
  const [confirmationClause, setConfirmationClause] = useState("");

  const handleClose = () => {
    if (openType === "Success" && confirmationClause === "PushToNomination") {
      setOpen(false);
      //window.location.href = "/CandidateData";
      clickEventSearchFilter();
    } else {
      setOpen(false);
    }
  };

  function dateChangeEvent(dateValue, label) {
    if (label === "Date Of Joining") {
      setDOJ(dateValue);
    }
  }

  const handleConfirm = () => {
    if (confirmationClause === "PushToNomination") {
      setOpenConfirm(false);
      PushToNomination(applicantId);
    } else {
      setOpenConfirm(false);
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const changeEventFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const changeEventLastName = (e) => {
    setLastName(e.target.value);
  };
  const changeEventEmail = (e) => {
    setEmail(e.target.value);
  };
  const changeEventInitiatorName = (e) => {
    setInitiatorName(e.target.value);
  };
  const handleChangeStatus = (event, newValue) => {
    setStatusObj(newValue);
    setStatus(newValue ? newValue.id : 0);
  };
  const handleChangeStatusInput = (event, newInputValue) => {
    setStatusText(newInputValue);
  };
  const handleChangeARE = (event, newValue) => {
    setAREObj(newValue);
    setARE(newValue ? newValue.id : 0);
  };
  const handleChangeAREInput = (event, newInputValue) => {
    setAREText(newInputValue);
  };
  const changeEventCC = (e) => {
    setCCText(e.target.value);
  };
  var searchTexts = {
    FirstName: firstName,
    LastName: lastName,
    UserEmail: email,
    RecruiterName: props.UserFullName,
    StatusId: status,
    AREId: are,
    DOJ: doj,
    CostCenter: ccText,
  };
  var searchFilterData = JSON.stringify(searchTexts);
  const clickEventSearchFilter = async () => {
    setIsLoader(true);
    searchTexts = {
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
      RecruiterName: initiatorName,
      StatusId: status,
      AREId: are,
      DOJ: doj,
      CostCenter: ccText,
    };
    searchFilterData = JSON.stringify(searchTexts);
    GetCandidateList(searchFilterData);
  };
  async function GetCandidateList(searchFilterData) {
    const token = props.token;
    const options = {
      method: "post",
      //type: 'async',
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: searchFilterData,
    };
    await fetch("Employee/GetAllCandidate/", options)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        const oldCCdetect = data.find(
          (c) =>
            c.costCenter != null &&
            c.costCenter != "" &&
            c.costCenter.includes("!_")
        );
        if (oldCCdetect) {
          setIsOldCC(true);
        } else {
          setIsOldCC(false);
        }
      })
      .catch((error) => console.log("Error:", error));
    setIsLoader(false);
  }

  //fetch Status
  const [itemsStatus, setItemsStatus] = useState([]);
  async function getStatus() {
    const token = props.token;
    const response = await fetch("Employee/GetAllStatus", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsStatus(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsStatus(jsonItems);
  }
  //fetch AREs
  const [itemsARE, setItemsARE] = useState([]);
  async function getAREs() {
    const token = props.token;
    const response = await fetch("Employee/GetAllAREs", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsStatus(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsARE(jsonItems);
  }
  const [itemsCostCenter, setItemsCostCenter] = useState([]);
  async function getCostCenters() {
    const token = props.token;
    const response = await fetch("Employee/GetAllCostCenters", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsStatus(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsCostCenter(jsonItems);
  }
  //for (var index in data) {
  //    console.log(data[index].employeeId + " : " + data[index].panimage);
  //}
  const clickEventClearSearchFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    if (props.roleId == 1) {
      setInitiatorName("");
    }
    setStatus(0);
    setStatusObj("");
    setStatusText("");
    setARE("");
    setAREObj("");
    setAREText("");
    setDOJ(null);
    setCC("");
    setCCObj("");
    setCCText("");
  };
  const clickEventExportToExcel = () => {
    searchTexts = {
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
      RecruiterName: initiatorName,
      StatusId: status,
      AREId: are,
      DOJ: doj,
      CostCenter: ccText,
    };
    searchFilterData = JSON.stringify(searchTexts);
    GetCandidateListDownload(searchFilterData);
  };

  function GetCandidateListDownload(searchFilterData) {
    setIsLoader(true);
    searchTexts = {
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
      RecruiterName: initiatorName,
      StatusId: status,
      AREId: are,
      DOJ: doj,
      CostCenter: ccText,
    };
    const token = props.token;

    axios
      .post("/Employee/GetAllCandidateDownload/", searchTexts, {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, "CandidateList.xlsx");
        setIsLoader(false);
      });
  }

  async function PushToNomination(id) {
    setIsLoader(true);
    const token = props.token;
    await fetch("Employee/PushToNomination/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.response) {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
        } else {
          setOpen(true);
          setOpenAlert(data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
        }
      })
        .catch((error) => {
            setIsLoader(false);
            console.log("Error:", error);
        });
  }

  async function clickEventPushToNomination(id) {
    setApplicantId(id);
    setConfirmationClause("PushToNomination");
    setOpenConfirm(true);
    setOpenAlertConfirm(
      "Are you Sure you want to push OnBoarding data to Nomination?"
    );
    setClassName("clr-dark-blue bg-alert");
    setOpenType("Confirmation");
  }

  function GetDHLButton(rowData) {
    return (
      <Tooltip
        title={
          !rowData.isDigitalLetter ? "Not Applicable" : "Send Letter"
        }
        placement="top"
      >
        <span>
          <Button
            onClick={() => goToDHLPrerequisites(rowData)}
            disabled={!rowData.isDigitalLetter}
          >
            <span>
              <MailOutlinedIcon fontSize="small" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function goToDHLPrerequisites(rowData) {
    //window.open(
    //  "/DHLPrerequisites?id=" +
    //    rowData.employeeIdencrypted +
    //    rowData.employeeId,
    //  "_blank"
    //  );
      window.open(
          "/DHLPrerequisites?id=" +
          rowData.employeeIdencrypted ,
          "_blank"
      );
  }

  function EditCandidate(rowData) {
    //window.open(
    //  "/CandidateRegistration?id=" +
    //    rowData.employeeIdencrypted +
    //    rowData.employeeId,
    //  "_blank"
    //);
      window.open(
          "/CandidateRegistration?id=" +
          rowData.employeeIdencrypted, 
          "_blank"
      );
  }
  function GetEditButton(rowData) {
    return (
      <Tooltip title="Edit Candidate" placement="top">
        <span>
          <Button onClick={() => EditCandidate(rowData)}>
            <span>
              <EditOutlinedIcon fontSize="small" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function EditApplicationForm(rowData) {
    //window.open(
    //  "/ApplicationForm?id=" + rowData.employeeIdencrypted + rowData.employeeId,
    //  "_blank"
    //  );
      window.open(
          "/ApplicationForm?id=" + rowData.employeeIdencrypted,
          "_blank"
      );
  }
  function GetEditApplicationFormButton(rowData) {
    //var url =  "/ApplicationForm?id=" + rowData.employeeIdencrypted + rowData.employeeId;
    var url =  "/ApplicationForm?id=" + rowData.employeeIdencrypted;
    return (
      <Tooltip title="Edit Application Form" placement="top">
        <span>
          <Button
            href="javascript:void(0)"
            onClick={() => EditApplicationForm(rowData)}
          >
            <span>
              <ListAltOutlinedIcon fontSize="small" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function GetPANImagePreviewButton(rowData) {
    return (
      <React.Fragment>
        {rowData.panimage ? (
          <React.Fragment>
            <Tooltip title="Download PAN" placement="top">
              <span>
                <Button onClick={(e) => viewPANImageHandler(rowData)}>
                  <span>
                    <PictureAsPdfIcon fontSize="small" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip title="Download PAN" placement="top">
              <span>
                <Button disabled>
                  <span>
                    <PictureAsPdfIcon fontSize="small" color="disabled" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  function GetAadharImagePreviewButton(rowData) {
    return (
      <React.Fragment>
        {rowData.aadharImage ? (
          <React.Fragment>
            <Tooltip title="Download Aadhar" placement="top">
              <span>
                <Button onClick={(e) => viewAadharImageHandler(rowData)}>
                  <span>
                    <PictureAsPdfIcon fontSize="small" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip title="Download Aadhar" placement="top">
              <span>
                <Button disabled>
                  <span>
                    <PictureAsPdfIcon fontSize="small" color="disabled" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  function viewPANImageHandler(data) {
    setIsLoader(true);
    //const id = data.employeeId;
    const id = data.employeeIdencrypted;
    const fileName = data.firstName + "_" + data.lastName + "_PAN" + ".pdf";
    const token = props.token;
    axios
      .get("/PreHiringForm/DownloadPAN/" + id, {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + token },
      })
      .then(function (response) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(blob, fileName);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
    /*window.location.href = "/PreHiringForm/GetPanData/" + id;*/
  }
  function viewAadharImageHandler(data) {
    setIsLoader(true);
     // const id = data.employeeId;
      const id = data.employeeIdencrypted;
    const fileName = data.firstName + "_" + data.lastName + "_Aadhar" + ".pdf";
    const token = props.token;
    axios
      .get("/PreHiringForm/DownloadAadhar/" + id, {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + token },
      })
      .then(function (response) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        FileSaver.saveAs(blob, fileName);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
    /*window.location.href = "/PreHiringForm/GetAadharData/" + id;*/
  }
  function viewEmployeeImageHandler(data) {
    setIsLoader(roundToNearestMinutes);
    //const id = data.employeeId;
    const id = data.employeeIdencrypted;
    const fileName = data.firstName + "_" + data.lastName + "_Photo" + ".jpg";
    const token = props.token;
    axios
      .get("/ApplicationForm/DownloadEmployeePhoto/" + id, {
        responseType: "arraybuffer",
        headers: { Authorization: "Bearer " + token },
      })
      .then(function (response) {
        var blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        FileSaver.saveAs(blob, fileName);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error:", error);
      });
    /*window.location.href = "/ApplicationForm/DownloadEmployeePhoto/" + id;*/
  }
  function getImageTooltipTitle(content) {
    return (
      <img src={`data:image/*;base64,${content}`} height="100" width="100" />
    );
  }
  function GetEmployeePhotoPreviewButton(rowData) {
    return (
      <React.Fragment>
        {rowData.employeePhoto ? (
          <React.Fragment>
            <Tooltip
              //title={getImageTooltipTitle(rowData.employeePhoto)}
              //placement="right"
              title="Download Employee Photo"
              placement="top"
            >
              <span>
                <Button onClick={(e) => viewEmployeeImageHandler(rowData)}>
                  <span>
                    <ImageOutlinedIcon fontSize="small" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip title="Download Employee Photo" placement="top">
              <span>
                <Button disabled>
                  <span>
                    <ImageOutlinedIcon fontSize="small" color="disabled" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  function IsEnablePushToNomination(dtStr) {
    var currDate = new Date().toLocaleDateString();
    var currdt = new Date(currDate);
    var targetDate = new Date(dtStr);
    return true;
    if (currdt >= targetDate) {
      return true;
    } else {
      return false;
    }
  }
  function GetPushForwardButton(rowData) {
    //var url = "/CandidateData";
    var tooltipTitleText = "";
    var dtStr = "30-Aug-2021";
    if (!IsEnablePushToNomination(dtStr)) {
      tooltipTitleText =
        "Push To Nomination Process Feature will be disabled till " + dtStr;
    } else {
      if (rowData.statusId === 5 && rowData.gid != null && rowData.gid != "") {
        if (props.roleId == 1) {
          tooltipTitleText = "Push To Nomination Process";
        } else {
          tooltipTitleText = "You are unauthorized to take this action";
        }
      } else {
        if (rowData.statusId < 5) {
          tooltipTitleText = "Data not yet ready to push to Nomination Process";
        } else if (
          rowData.statusId === 5 &&
          (rowData.gid == null || rowData.gid == "")
        ) {
          tooltipTitleText = "GID not yet avaiable";
        } else if (rowData.statusId > 5) {
          tooltipTitleText = "Already pushed to Nomination Process";
        } else {
          tooltipTitleText = "";
        }
      }
    }
    return (
      <React.Fragment>
        {rowData.statusId === 5 &&
        rowData.gid != null &&
        rowData.gid != "" &&
        props.roleId == 1 &&
        IsEnablePushToNomination(dtStr) ? (
          <React.Fragment>
            <Tooltip title={tooltipTitleText} placement="top">
              <span>
                <Button
                                    onClick={(e) =>
                                        clickEventPushToNomination(rowData.employeeIdencrypted)
                  }
                >
                  <span>
                    <ArrowForwardOutlinedIcon fontSize="small" />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip title={tooltipTitleText} placement="top">
              <span>
                <Button disabled>
                  <span>
                    <ArrowForwardOutlinedIcon
                      fontSize="small"
                      color="disabled"
                    />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  function PreviewApplicationForm(rowData) {
    window.open(
      "/PreviewApplicationForm?id=" +
        rowData.employeeIdencrypted,       
      "_blank"
    );
  }
  function GetApplicationFormPreviewButton(rowData) {
    var url =
      "/PreviewApplicationForm?id=" +
      rowData.employeeIdencrypted;
    return (
      <React.Fragment>
        {rowData.applicationFormSubmitted == "Yes" ? (
          <React.Fragment>
            <Tooltip title="View Application Form" placement="top">
              <Button
                href="javascript:void(0)"
                onClick={() => PreviewApplicationForm(rowData)}
              >
                <span>
                  <VisibilityOutlinedIcon fontSize="small" />
                </span>
              </Button>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tooltip title="View Application Form" placement="top">
              <span>
                <Button disabled>
                  <span>
                    <VisibilityOffOutlinedIcon
                      fontSize="small"
                      color="disabled"
                    />
                  </span>
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  function ChangeFormHeaderRolewise(id) {
    if (id != null && id != "" && id == 1) {
      setFormHeader("On-Boarding Dashboard");
    } else {
      setFormHeader(formHeader);
    }
  }

  var columns = [
    {
      title: "Edit",
      field: "",
      render: (rowData) => rowData && GetEditButton(rowData),
    },
    {
      title: "Send Digital HR Letter",
      field: "",
      render: (rowData) => rowData && GetDHLButton(rowData),
    },
    {
      title: "Edit Application Form",
      field: "",
      render: (rowData) => rowData && GetEditApplicationFormButton(rowData),
    },
    {
      title: "EmployeeIDEncrypted",
      field: "employeeIdencrypted",
      hidden: true,
    },
    { title: "EmployeeID", field: "employeeId", hidden: true },
    {
      title: "Photo",
      field: "employeePhoto",
      align: "left",
      render: (rowData) => rowData && GetEmployeePhotoPreviewButton(rowData),
    },
    {
      title: "PAN",
      field: "panimage",
      align: "left",
      render: (rowData) => rowData && GetPANImagePreviewButton(rowData),
    },
    {
      title: "Aadhar",
      field: "aadharImage",
      align: "left",
      render: (rowData) => rowData && GetAadharImagePreviewButton(rowData),
    },
    { title: "Reference No.", field: "referenceNo", hidden: true },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email", field: "userEmail" },
    { title: "GID", field: "gid", hidden: true },
    {
      title: "Application Form Submitted",
      field: "applicationFormSubmitted",
      hidden: true,
    },
    {
      title: "Application Form Rejected",
      field: "applicationFormRejected",
      hidden: true,
    },
    { title: "Status Id", field: "statusId", hidden: true },
    { title: "Status", field: "status" },
    {
      title: "Push To Nomination Process",
      field: "",
      align: "left",
      render: (rowData) => rowData && GetPushForwardButton(rowData),
    },
    {
      title: "Application Form Preview",
      field: "",
      align: "left",
      render: (rowData) => rowData && GetApplicationFormPreviewButton(rowData),
    },
    { title: "ARE", field: "are" },
    { title: "Cost Center", field: "costCenter" },
    { title: "Date Of Joining", field: "doj" },
      { title: "Recruiter ARE", field: "recruiterDivision" },
      { title: "DigiLetter Status", field: "digiLetterStatusName"},
    { title: "Initiator Name", field: "hiringManagerName" },
    { title: "Post Applied For", field: "postAppliedFor", hidden: true },
    { title: "Comments", field: "comments", hidden: true },
    ];
    

  function GetMTitle() {
    return (
      <React.Fragment>
        <span className="f-16">
          List Of Candidates
          {isOldCC ? (
            <React.Fragment>
              <Tooltip title={GetMTitleToolTip()} placement="right">
                <ErrorOutlineOutlinedIcon fontSize="small" color="secondary" />
              </Tooltip>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </span>
      </React.Fragment>
    );
  }
  function GetMTitleToolTip() {
    var pageName =
      props.roleId == 1 ? "On-Boarding Dashboard" : "Candidate Data";
    return (
      <React.Fragment>
        <ListItemText primary="Candidate Cost Center modification alert! Cost Center Master has been modified." />
        <ListItemText
          secondary={
            '1. Click on "Edit Application Form" button for a candidate in ' +
            pageName +
            " page"
          }
        />
        <ListItemText secondary='2. Go to "HR Details" tab' />
        <ListItemText secondary='3. Expand "Change Org Assignment" section' />
        <ListItemText secondary='4. Select "Cost Center" from dropdown as applicable and "Save" or "Update"' />
      </React.Fragment>
    );
  }

  useEffect(() => {
    setIsLoader(true);
    if (props.token) {
      if (
        props.UserFullName !== undefined &&
        props.UserFullName != "" &&
        props.UserFullName != null
      ) {
        setInitiatorName(props.UserFullName);
      }
      getStatus();
      getAREs();
      getCostCenters();
      GetCandidateList(searchFilterData);
    } else {
      setIsLoader(false);
    }
    //clickEventSearchFilter();
    ChangeFormHeaderRolewise(props.roleId);
  }, [props.roleId, props.UserFullName, props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="master-layout-data">
        <Box paddingTop={{ xs: "5rem", sm: "2rem" }}>
          <h3 className="clr-dark-blue d-flex jc-center">{formHeader}</h3>
        </Box>
        <Grid
          container
          spacing={1}
          className="d-flex fd-column px-1 box-shadow mb-2 bdr-radius"
        >
          <Grid container spacing={2} className="d-flex jc-space-around fd-row">
            <Grid item xs={10} sm={10} className="d-flex Width-100">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <TextField
                    className="lightInputFields mt-1"
                    id="firstName"
                    type="search"
                    label="First Name"
                    variant="filled"
                    fullWidth
                    name="First Name"
                    value={firstName}
                    onChange={changeEventFirstName}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <TextField
                    className="lightInputFields mt-1"
                    id="lastName"
                    type="search"
                    label="Last Name"
                    variant="filled"
                    fullWidth
                    name="Last Name"
                    value={lastName}
                    onChange={changeEventLastName}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <TextField
                    className="lightInputFields mt-1"
                    id="email"
                    type="search"
                    label="Email"
                    variant="filled"
                    fullWidth
                    name="Email"
                    value={email}
                    onChange={changeEventEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <Autocomplete
                    className="lightInputFields hover-lightblue mt-1"
                    id="combo-box-demo"
                    value={areObj}
                    inputValue={areText}
                    options={itemsARE}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    onChange={handleChangeARE}
                    onInputChange={handleChangeAREInput}
                    renderInput={(params) => (
                      <TextField {...params} label="ARE" variant="filled" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <TextField
                    className="lightInputFields mt-1"
                    id="costCenter"
                    type="search"
                    label="Cost Center"
                    variant="filled"
                    fullWidth
                    name="Cost Center"
                    value={ccText}
                    onChange={changeEventCC}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <Autocomplete
                    className="lightInputFields hover-lightblue mt-1"
                    id="combo-box-demo"
                    value={statusObj}
                    inputValue={statusText}
                    options={itemsStatus}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    onChange={handleChangeStatus}
                    onInputChange={handleChangeStatusInput}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" variant="filled" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <FormDatePicker
                    placeholder="DOJ"
                    label="Date Of Joining"
                    value={doj}
                    onChange={dateChangeEvent}
                    disablefuturedate={false}
                    disablepastdate={false}
                    disabled={false}
                    required={false}
                    error={false}
                    helperText={null}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="d-flex Width-100">
                  <TextField
                    className="lightInputFields mt-1"
                    id="recruiterName"
                    type="search"
                    label="Initiator Name"
                    variant="filled"
                    fullWidth
                    name="Recruiter Name"
                    value={initiatorName}
                    onChange={changeEventInitiatorName}
                    disabled={props.roleId != 1 ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} className="d-flex Width-100">
              <Tooltip title="Search" placement="top">
                <Button className="mt-1" onClick={clickEventSearchFilter}>
                  <span>
                    <SearchIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip title="Clear Search Fields" placement="top">
                <Button className="mt-1" onClick={clickEventClearSearchFields}>
                  <span>
                    <ClearIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip title="Export to Excel" placement="top">
                <Button className="mt-1" onClick={clickEventExportToExcel}>
                  <span>
                    <ImportExportIcon fontSize="large" />
                  </span>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt-1 mb-2">
            <MaterialTable
              title={GetMTitle()}
              columns={columns}
              data={data}
              icons={tableIcons}
              options={{
                search: false,
              }}
              components={{
                OverlayLoading: () => (
                  <React.Fragment>
                    <CircularProgress />
                  </React.Fragment>
                ),
              }}
              //editable={{
              //    onRowUpdate: (newData, oldData) =>
              //        new Promise((resolve) => {
              //            handleRowUpdate(newData, oldData, resolve);

              //        }),
              //    onRowAdd: (newData) =>
              //        new Promise((resolve) => {
              //            handleRowAdd(newData, resolve)
              //        }),
              //    onRowDelete: (oldData) =>
              //        new Promise((resolve) => {
              //            handleRowDelete(oldData, resolve)
              //        }),
              //}}
            />
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle
              className={openClassName}
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
            >
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadClear"
          >
            <DialogTitle
              className={openClassName}
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
            >
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlertConfirm}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleConfirm}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={handleCloseConfirm}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">Cancel</span>
              </Button>
            </DialogActions>
          </Dialog>
          {isLoader ? <Loader /> : <></>}
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default CandidateData;
