export const inputDataDHLAction = (inputDataDHL) => {
  return {
    type: "inputDataDHL",
    payLoad: inputDataDHL,
  };
};

export const compensationDetailsAction = (compensationDetails) => {
    return {
        type: "compensationDetails",
        payLoad: compensationDetails,
    };
};

export const compensationWSADetailsAction = (compensationWSADetails) => {
    return {
        type: "compensationWSADetails",
        payLoad: compensationWSADetails,
    };
};

export const insuranceDetailsAction = (insuranceDetails) => {
    return {
        type: "insuranceDetails",
        payLoad: insuranceDetails,
    };
};

export const insuranceDataAction = (insuranceData) => {
    return {
        type: "insuranceData",
        payLoad: insuranceData,
    };
};