import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormDatePicker from "../../components/DatePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiFilledInput: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8",
                },
                "&$disabled": {
                    pointerEvent: "none",
                    color: "#707080",
                    backgroundColor: "#001035",
                    border: "2px solid #707070",
                    borderRadius: "0",
                    "&:before": {
                        borderBottom: "1px solid #001035",
                    },
                    "&:hover:before": {
                        borderBottom: "1px solid #001035",
                        borderBottomStyle: "solid",
                    },
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                "&$disabled": {
                    backgroundColor: "#454545 !important",
                },
            },
        },
        MuiDialogActions: {
            root: {
                background: "#000028",
            },
        },
        MuiDialogTitle: {
            root: {
                padding: "8px 24px",
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
            },
        },
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            },
            asterisk: {
                color: "red",
            },
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68",
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {
                    backgroundColor: "#CCF5F5",
                },
            },
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                },
            },
            underline: {
                "&:before": {
                    borderBottom: "0px solid #FFFFFF",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:after": {
                    borderBottom: "2px solid #FFFFFF",
                    transition:
                        "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "&:hover:before": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:hover:after": {
                    borderBottom: "2px solid #FFFFFF",
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #FFFFFF",
                },
                "&:disabled:before": {
                    border: "none",
                },
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028",
            },
        },
        //MuiFilledInput: {
        //    root: {
        //        "&$focused": { // increase the specificity for the pseudo class
        //            color: "#000028",
        //            backgroundColor: "#E8F8F8",
        //        },
        //        "&$hover": {
        //            backgroundColor: "#E8F8F8"
        //        },
        //        "&$disabled": {
        //            "pointerEvent": "none",
        //            "color": "#707080",
        //            "backgroundColor": "#001035",
        //            "border": "2px solid #707070",
        //            "borderRadius": "0",
        //            "&:before": {
        //                "borderBottom": "1px solid #001035",
        //            },
        //            "&:hover:before": {
        //                "borderBottom": "1px solid #001035",
        //                "borderBottomStyle": "solid"
        //            }
        //        }
        //    },
        //    underline: {
        //        "&:before": {
        //            borderBottom: "2px solid #E8F8F8",
        //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        //        },
        //        "&:after": {
        //            borderBottom: "2px solid #E8F8F8",
        //            transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        //        },
        //        "&:hover:before": {
        //            borderBottom: "2px solid #00CCCC"
        //        },
        //        "&:hover:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:focused:after": {
        //            borderBottom: "2px solid #6CD6D6"
        //        },
        //        "&:disabled:before": {
        //            border: "none"
        //        }
        //    },
        //},
        //MuiFormControl: {
        //    root: {
        //        color: "#FFFFFF",
        //        width: "100"
        //    }
        //},
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5",
            },
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "20px",
                background: "#CCF5F5",
            },
        },

        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none",
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`,
                },
            },
            menuItem: {
                color: "#FFFFFF",
            },
            selectIcon: {
                color: "#00CCCC",
            },
            input: {
                backgroundColor: "#000028",
            },
        },
        MuiTypography: {
            caption: {
                color: "#000028",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
                "& div:hover": {
                    color: "#00CCCC",
                },
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF",
                },
                "&$active": {
                    color: "#00CCCC",
                },
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important",
            },
            body: {
                background: "#000028",
                color: "#FFFFFF",
            },
        },
        MuiFormControl: {
            root: {
                paddingLeft: "0px",
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#6a7272",
            },
        },
        MuiPickersBasePicker: {
            container: {
                background: "#000028",
            },
            pickerView: {
                background: "#000028",
            },
            background: "#000028",
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028",
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF",
            },
            switchHeader: {
                color: "#FFFFFF",
            },
            daysHeader: {
                color: "#FFFFFF",
            },
            dayLabel: {
                color: "#FFFFFF",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF",
            },
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: "#000028"
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF",
            },
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF",
            },
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028",
            },
        },
        MuiFormHelperText: {
            contained: {
                backgroundColor: "white",
                marginBottom: "-10px !important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important",
            },
        },
    },
});

export default function JobDetails(props) {
    var id;

    const [jobDetailsId, setJobDetailsId] = useState("");
    const [buttonTextSave, setButtonTextSave] = useState("Save");
    const [supervisoryOrganization, setSupervisoryOrganization] = useState("");
    const [errorsupervisoryOrganization, setErrorsupervisoryOrganization] =
        useState(false);
    const [
        errorTextsupervisoryOrganization,
        setErrorTextsupervisoryOrganization,
    ] = useState("");
    const [supervisoryOrganizationObj, setSupervisoryOrganizationObj] =
        useState("");
    const [supervisoryOrganizationText, setSupervisoryOrganizationText] =
        useState("");
    const [hireDate, setHireDate] = useState(null);
    const [errorhireDate, setErrorhireDate] = useState(false);
    const [errorTexthireDate, setErrorTexthireDate] = useState("");
    const [firstDayOfWork, setFirstDayOfWork] = useState(null);
    const [errorfirstDayOfWork, setErrorfirstDayOfWork] = useState(false);
    const [errorTextfirstDayOfWork, setErrorTextfirstDayOfWork] = useState("");
    const [hireReason, setHireReason] = useState("");
    const [errorhireReason, setErrorhireReason] = useState(false);
    const [errorTexthireReason, setErrorTexthireReason] = useState("");
    const [hireReasonObj, setHireReasonObj] = useState("");
    const [hireReasonText, setHireReasonText] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const [erroremployeeType, setErroremployeeType] = useState(false);
    const [errorTextemployeeType, setErrorTextemployeeType] = useState("");
    const [employeeTypeObj, setEmployeeTypeObj] = useState("");
    const [employeeTypeText, setEmployeeTypeText] = useState("");
    const [jobProfile, setJobProfile] = useState("");
    const [errorjobProfile, setErrorjobProfile] = useState(false);
    const [errorTextjobProfile, setErrorTextjobProfile] = useState("");
    const [jobProfileObj, setJobProfileObj] = useState("");
    const [jobProfileText, setJobProfileText] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [errorjobTitle, setErrorjobTitle] = useState(false);
    const [errorTextjobTitle, setErrorTextjobTitle] = useState("");
    const [jobTitleObj, setJobTitleObj] = useState("");
    const [jobTitleText, setJobTitleText] = useState("");
    const [businessTitle, setBusinessTitle] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [errorlocationCode, setErrorlocationCode] = useState(false);
    const [errorTextlocationCode, setErrorTextlocationCode] = useState("");
    const [locationCodeObj, setLocationCodeObj] = useState("");
    const [locationCodeText, setLocationCodeText] = useState("");
    const [timeType, setTimeType] = useState("");
    const [errortimeType, setErrortimeType] = useState(false);
    const [errorTexttimeType, setErrorTexttimeType] = useState("");
    const [timeTypeObj, setTimeTypeObj] = useState("");
    const [timeTypeText, setTimeTypeText] = useState("");
    const [defaultWeeklyHours, setDefaultWeeklyHours] = useState("");
    const [errordefaultWeeklyHours, setErrordefaultWeeklyHours] = useState(false);
    const [errorTextdefaultWeeklyHours, setErrorTextdefaultWeeklyHours] =
        useState("");
    const [scheduledWeeklyHours, setScheduledWeeklyHours] = useState("");
    const [errorscheduledWeeklyHours, setErrorscheduledWeeklyHours] =
        useState(false);
    const [errorTextscheduledWeeklyHours, setErrorTextscheduledWeeklyHours] =
        useState("");
    const [gripLevel, setGripLevel] = useState("");
    const [errorgripLevel, setErrorgripLevel] = useState(false);
    const [errorTextgripLevel, setErrorTextgripLevel] = useState("");
    const [gripLevelObj, setGripLevelObj] = useState("");
    const [gripLevelText, setGripLevelText] = useState("");
    const [managementStructure, setManagementStructure] = useState("");
    const [errormanagementStructure, setErrormanagementStructure] =
        useState(false);
    const [errorTextmanagementStructure, setErrorTextmanagementStructure] =
        useState("");
    const [managementStructureObj, setManagementStructureObj] = useState("");
    const [managementStructureText, setManagementStructureText] = useState("");
    const [staffSpecification, setStaffSpecification] = useState("");
    const [errorstaffSpecification, setErrorstaffSpecification] = useState(false);
    const [errorTextstaffSpecification, setErrorTextstaffSpecification] =
        useState("");
    const [staffSpecificationObj, setStaffSpecificationObj] = useState("");
    const [staffSpecificationText, setStaffSpecificationText] = useState("");
    const [value, setValue] = useState("");
    const [changeHiringDate, setChangeHiringDate] = useState(null);
    const [changeFirstDayDate, setChangeFirstDayDate] = useState(null);
    //Alerts
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState("");
    const [openClassName, setClassName] = useState("");
    const [openType, setOpenType] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlertConfirm, setOpenAlertConfirm] = useState("");

    const [isLoader, setIsLoader] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = () => {
        clearStates();
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    function clearStates() {
        setSupervisoryOrganization("");
        setSupervisoryOrganizationObj("");
        setSupervisoryOrganizationText("");
        setHireDate(null);
        setHireReason("");
        setHireReasonObj("");
        setHireReasonText("");
        //setEmployeeType('');
        //setEmployeeTypeObj('');
        //setEmployeeTypeText('');
        setJobProfile("");
        setJobProfileObj("");
        setJobProfileText("");
        setJobTitle("");
        setJobTitleObj("");
        setJobTitleText("");
        setBusinessTitle("");
        setDefaultWeeklyHours("");
        setScheduledWeeklyHours("");
        setLocationCode("");
        setLocationCodeObj("");
        setLocationCodeText("");
        setTimeType("");
        setTimeTypeObj("");
        setTimeTypeText("");
        if (props.roleId && (props.roleId == 1) == 1) {
            setFirstDayOfWork(null);
        }
        setGripLevel("");
        setGripLevelObj("");
        setGripLevelText("");
        setManagementStructure("");
        setManagementStructureObj("");
        setManagementStructureText("");
        setStaffSpecification("");
        setStaffSpecificationObj("");
        setStaffSpecificationText("");
    }

    function dateChangeEvent(dateValue, label) {
        if (label === "Hire Date" && dateValue.toLocaleDateString() != "10/24/2022") {
            setHireDate(dateValue);
            setFirstDayOfWork(dateValue);
            setChangeHiringDate(dateValue);
            setChangeFirstDayDate(dateValue);
        } else if (label === "First Day Of Work" && dateValue.toLocaleDateString() != "10/24/2022") {
            setFirstDayOfWork(dateValue);
            setChangeFirstDayDate(dateValue);
        } else {
            setChangeHiringDate(dateValue);
            setChangeFirstDayDate(dateValue);
            let date = new Date(dateValue);
            date = date.setDate(date.getDate() + 1);
            setFirstDayOfWork(date);
            setHireDate(date);


        }
    }

    const handleChangeSupervisoryOrg = (event, newValue) => {
        setSupervisoryOrganizationObj(newValue);
        setSupervisoryOrganization(newValue ? newValue.id : 0);
    };
    const handleChangeSupervisoryOrgInput = (event, newInputValue) => {
        setSupervisoryOrganizationText(newInputValue);
    };

    const handleChangeJobProfile = (event, newValue) => {
        setJobProfileObj(newValue);
        setJobProfile(newValue ? newValue.id : 0);
    };
    const handleChangeJobProfileInput = (event, newInputValue) => {
        setJobProfileText(newInputValue);
    };

    const handleChangeJobTitle = (event, newValue) => {
        setJobTitleObj(newValue);
        setJobTitle(newValue ? newValue.id : 0);
        setJobProfileObj(newValue);
        setJobProfile(newValue ? newValue.id : 0);
        setBusinessTitle(newValue ? newValue.name : "");
    };
    const handleChangeJobTitleInput = (event, newInputValue) => {
        setJobTitleText(newInputValue);
        setJobProfileText(newInputValue);
        setBusinessTitle(newInputValue);
    };

    const handleChangeLocationCode = (event, newValue) => {
        setLocationCodeObj(newValue);
        setLocationCode(newValue ? newValue.id : 0);
    };
    const handleChangeLocationCodeInput = (event, newInputValue) => {
        setLocationCodeText(newInputValue);
    };

    const handleChangeHireReason = (event, newValue) => {
        setHireReasonObj(newValue);
        setHireReason(newValue ? newValue.id : 0);
    };
    const handleChangeHireReasonInput = (event, newInputValue) => {
        setHireReasonText(newInputValue);
    };

    const handleChangeEmployeeType = (event, newValue) => {
        setEmployeeTypeObj(newValue);
        setEmployeeType(newValue ? newValue.id : 0);
    };
    const handleChangeEmployeeTypeInput = (event, newInputValue) => {
        setEmployeeTypeText(newInputValue);
    };

    const handleChangeTimeType = (event, newValue) => {
        setTimeTypeObj(newValue);
        setTimeType(newValue ? newValue.id : 0);
        if (newValue && newValue.id == 1) {
            setDefaultWeeklyHours(45);
            setScheduledWeeklyHours(45);
        } else {
            setDefaultWeeklyHours("");
            setScheduledWeeklyHours("");
        }
    };
    const handleChangeTimeTypeInput = (event, newInputValue) => {
        setTimeTypeText(newInputValue);
    };

    const handleChangeGripLevel = (event, newValue) => {
        setGripLevelObj(newValue);
        setGripLevel(newValue ? newValue.id : 0);
    };
    const handleChangeGripLevelInput = (event, newInputValue) => {
        setGripLevelText(newInputValue);
    };

    const handleChangeManagementStructure = (event, newValue) => {
        setManagementStructureObj(newValue);
        setManagementStructure(newValue ? newValue.id : 0);
    };
    const handleChangeManagementStructureInput = (event, newInputValue) => {
        setManagementStructureText(newInputValue);
    };

    const handleChangeStaffSpecification = (event, newValue) => {
        setStaffSpecificationObj(newValue);
        setStaffSpecification(newValue ? newValue.id : 0);
    };
    const handleChangeStaffSpecificationInput = (event, newInputValue) => {
        setStaffSpecificationText(newInputValue);
    };

    const handleKeypress = (e) => {
        const characterCode = e.key;
        if (
            characterCode === "Backspace" ||
            characterCode === "ArrowUp" ||
            characterCode === "ArrowDown" ||
            characterCode === "ArrowRight" ||
            characterCode === "ArrowLeft"
        )
            return;
        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            }
        } else {
            e.preventDefault();
        }
    };
    const handleChangeDefaultWeeklyHours = (e) => {
        setDefaultWeeklyHours(e.target.value);
    };
    const handleChangeScheduledWeeklyHours = (e) => {
        setScheduledWeeklyHours(e.target.value);
    };

    var formData = {
        ApplicantId: props.id,
        SupervisoryOrganizationId: supervisoryOrganization,
        HireDate: hireDate,
        HireReasonId: hireReason,
        EmployeeTypeId: employeeType,
        FirstDayOfWork: firstDayOfWork,
        JobProfileId: jobProfile,
        JobTitleId: jobTitle,
        BusinessTitle: businessTitle,
        LocationCodeId: locationCode,
        ContractTimeId: timeType,
        DefaultWeeklyHours: defaultWeeklyHours,
        ScheduledWeeklyHours: scheduledWeeklyHours,
        GripLevelId: gripLevel,
        ManagementStructureId: managementStructure,
        StaffSpecificationId: staffSpecification,
        UserId: props.userId,
    };
    //Validation
    function ValidateFormFields(data) {
        var a = false,
            b = false,
            c = false,
            d = false,
            e = false,
            f = false,
            g = false,
            h = false,
            i = false,
            j = false,
            k = false,
            l = false,
            m = false,
            n = false;
        if (
            data.SupervisoryOrganizationId == null ||
            data.SupervisoryOrganizationId == ""
        ) {
            setErrorsupervisoryOrganization(true);
            setErrorTextsupervisoryOrganization("Mandatory field");
            a = true;
        } else {
            setErrorsupervisoryOrganization(false);
            setErrorTextsupervisoryOrganization("");
            a = false;
        }
        if (data.HireDate == null || data.HireDate == "") {
            setErrorhireDate(true);
            setErrorTexthireDate("Mandatory field");
            b = true;
        } else {
            setErrorhireDate(false);
            setErrorTexthireDate("");
            b = false;
        }
        if (data.HireReasonId == null || data.HireReasonId == "") {
            setErrorhireReason(true);
            setErrorTexthireReason("Mandatory field");
            c = true;
        } else {
            setErrorhireReason(false);
            setErrorTexthireReason("");
            c = false;
        }
        if (data.EmployeeTypeId == null || data.EmployeeTypeId == "") {
            setErroremployeeType(true);
            setErrorTextemployeeType("Mandatory field");
            d = true;
        } else {
            setErroremployeeType(false);
            setErrorTextemployeeType("");
            d = false;
        }
        if (data.FirstDayOfWork == null || data.FirstDayOfWork == "") {
            setErrorfirstDayOfWork(true);
            setErrorTextfirstDayOfWork("Mandatory field");
            e = true;
        } else {
            setErrorfirstDayOfWork(false);
            setErrorTextfirstDayOfWork("");
            e = false;
        }
        if (data.JobProfileId == null || data.JobProfileId == "") {
            setErrorjobProfile(true);
            setErrorTextjobProfile("Mandatory field");
            f = true;
        } else {
            setErrorjobProfile(false);
            setErrorTextjobProfile("");
            f = false;
        }
        if (data.JobTitleId == null || data.JobTitleId == "") {
            setErrorjobTitle(true);
            setErrorTextjobTitle("Mandatory field");
            g = true;
        } else {
            setErrorjobTitle(false);
            setErrorTextjobTitle("");
            g = false;
        }
        if (data.LocationCodeId == null || data.LocationCodeId == "") {
            setErrorlocationCode(true);
            setErrorTextlocationCode("Mandatory field");
            h = true;
        } else {
            setErrorlocationCode(false);
            setErrorTextlocationCode("");
            h = false;
        }
        if (data.ContractTimeId == null || data.ContractTimeId == "") {
            setErrortimeType(true);
            setErrorTexttimeType("Mandatory field");
            i = true;
        } else {
            setErrortimeType(false);
            setErrorTexttimeType("");
            i = false;
        }
        if (data.DefaultWeeklyHours == null || data.DefaultWeeklyHours == "") {
            setErrordefaultWeeklyHours(true);
            setErrorTextdefaultWeeklyHours("Mandatory field");
            j = true;
        } else {
            if (
                parseInt(data.DefaultWeeklyHours) ||
                parseFloat(data.DefaultWeeklyHours)
            )
                setErrordefaultWeeklyHours(false);
            setErrorTextdefaultWeeklyHours("");
            j = false;
        }
        if (data.ScheduledWeeklyHours == null || data.ScheduledWeeklyHours == "") {
            setErrorscheduledWeeklyHours(true);
            setErrorTextscheduledWeeklyHours("Mandatory field");
            k = true;
        } else {
            setErrorscheduledWeeklyHours(false);
            setErrorTextscheduledWeeklyHours("");
            k = false;
        }
        if (data.GripLevelId == null || data.GripLevelId == "") {
            setErrorgripLevel(true);
            setErrorTextgripLevel("Mandatory field");
            l = true;
        } else {
            setErrorgripLevel(false);
            setErrorTextgripLevel("");
            l = false;
        }
        if (
            data.ManagementStructureId == null ||
            data.ManagementStructureId == ""
        ) {
            setErrormanagementStructure(true);
            setErrorTextmanagementStructure("Mandatory field");
            m = true;
        } else {
            setErrormanagementStructure(false);
            setErrorTextmanagementStructure("");
            m = false;
        }
        if (data.StaffSpecificationId == null || data.StaffSpecificationId == "") {
            setErrorstaffSpecification(true);
            setErrorTextstaffSpecification("Mandatory field");
            m = true;
        } else {
            setErrorstaffSpecification(false);
            setErrorTextstaffSpecification("");
            m = false;
        }
        if (a || b || c || d || e || f || g || h || i || j || k || l || m || n) {
            return false;
        } else {
            return true;
        }
    }
    const submitHandler = (e) => {
        setIsLoader(true);
        setHireDate(null);
        setFirstDayOfWork(null);
        setHireDate(changeHiringDate);
        setFirstDayOfWork(changeFirstDayDate);
        formData = {
            ApplicantId: props.id,
            SupervisoryOrganizationId: supervisoryOrganization,
            HireDate: changeHiringDate,
            HireReasonId: hireReason,
            EmployeeTypeId: employeeType,
            FirstDayOfWork: changeFirstDayDate,
            JobProfileId: jobProfile,
            JobTitleId: jobTitle,
            BusinessTitle: businessTitle,
            LocationCodeId: locationCode,
            ContractTimeId: timeType,
            DefaultWeeklyHours: defaultWeeklyHours,
            ScheduledWeeklyHours: scheduledWeeklyHours,
            GripLevelId: gripLevel,
            ManagementStructureId: managementStructure,
            StaffSpecificationId: staffSpecification,
            UserId: props.userId,
        };
        if (ValidateFormFields(formData)) {
            if (
                jobDetailsId != null &&
                jobDetailsId > 0 &&
                jobDetailsId != undefined
            ) {
                UpdateJobDetails(formData);
            } else {
                SaveJobDetails(formData);
            }
        } else {
            setIsLoader(false);
            setOpen(true);
            setOpenAlert(
                "Form validation unsuccessful. Please check the error messages."
            );
            setClassName("clr-white bg-alert");
            setOpenType("Alert");
        }
    };
    const clearHandler = () => {
        setOpenConfirm(true);
        setOpenAlertConfirm("Do you want to clear all the above fields?");
        setClassName("clr-dark-blue bg-alert");
        setOpenType("Confirmation");
    };

    //Save Job Details
    function SaveJobDetails(formData) {
        const token = props.token;
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("HRDetails/SaveJobDetails/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Update Job Details
    function UpdateJobDetails(formData) {
        const token = props.token;
        const options = {
            method: "put",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(formData),
        };
        fetch("HRDetails/UpdateJobDetails/", options)
            .then((response) => response.json())
            .then((data) => {
                if (data.response) {
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-success");
                    setOpenType("Success");
                    SetStateAsPerFetched(data);
                } else {
                    setIsLoader(false);
                    setOpen(true);
                    setOpenAlert(data.responseMsg);
                    setClassName("clr-dark-blue bg-error");
                    setOpenType("Error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }
    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: "100%",
            color: "#00CCCCC",
            background: "#FFFFFF",
        },
    }));
    const classes = useStyles();

    //fetch Supervisory organisation
    const [itemsSupervisoryOrg, setItemsSupervisoryOrg] = useState([]);
    async function getSupervisoryOrg(id) {
        const token = props.token;
        const response = await fetch("HRDetails/GetSupervisoryOrganisation/" + id, {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsSupervisoryOrg(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsSupervisoryOrg(jsonItems);
    }

    //fetch Job Profile
    const [itemsJobProfile, setItemsJobProfile] = useState([]);
    async function getJobProfile() {
        const token = props.token;
        const response = await fetch("HRDetails/GetJobProfile", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsJobProfile(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsJobProfile(jsonItems);
    }

    //fetch Job Title
    const [itemsJobTitle, setItemsJobTitle] = useState([]);
    async function getJobTitle() {
        const token = props.token;
        const response = await fetch("HRDetails/GetJobTitle", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsJobTitle(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsJobTitle(jsonItems);
    }

    //fetch Location Code
    const [itemsLocationCode, setItemsLocationCode] = useState([]);
    async function getLocationCode() {
        const token = props.token;
        const response = await fetch("HRDetails/GetAllLocationCode", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemsLocationCode(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemsLocationCode(jsonItems);
    }

    //fetch Hiring Reasons
    const [itemHiringReasons, setItemHiringReasons] = useState([]);
    async function getHiringReasons() {
        const token = props.token;
        const response = await fetch("HRDetails/GetHiringReasons", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemHiringReasons(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemHiringReasons(jsonItems);
    }
    //fetch Employee Type
    const [itemEmployeeTypes, setItemEmployeeTypes] = useState([]);
    async function getEmployeeTypes() {
        const token = props.token;
        const response = await fetch("HRDetails/GetEmployeeTypes", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemEmployeeTypes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemEmployeeTypes(jsonItems);
    }
    //fetch Contract Time Types
    const [itemContractTimeTypes, setItemContractTimeTypes] = useState([]);
    async function getContractTimeTypes() {
        const token = props.token;
        const response = await fetch("HRDetails/GetContractTimeTypes", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemContractTimeTypes(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemContractTimeTypes(jsonItems);
    }
    //fetch Grip Levels
    const [itemGripLevels, setItemGripLevels] = useState([]);
    async function getGripLevels() {
        const token = props.token;
        const response = await fetch("HRDetails/GetGripLevels", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemGripLevels(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemGripLevels(jsonItems);
    }
    //fetch Management Structures
    const [itemManagementStructures, setItemManagementStructures] = useState([]);
    async function getManagementStructures() {
        const token = props.token;
        const response = await fetch("HRDetails/GetManagementStructures", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemManagementStructures(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemManagementStructures(jsonItems);
    }
    //fetch Staff Spefications
    const [itemStaffSpefications, setItemStaffSpefications] = useState([]);
    async function getStaffSpefications() {
        const token = props.token;
        const response = await fetch("HRDetails/GetStaffSpecifications", {
            headers: { Authorization: "Bearer " + token },
        });
        const jsonItems = await response.json();
        //setItemStaffSpefications(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
        setItemStaffSpefications(jsonItems);
    }

    //Change Button Text
    function getButtonTextSave(id) {
        id != null && id > 0 && id != undefined
            ? setButtonTextSave("Update")
            : setButtonTextSave(buttonTextSave);
    }

    //Set All States
    function SetStateAsPerFetched(data) {
        setJobDetailsId(data.jobDetailsId);
        setSupervisoryOrganization(data.supervisoryOrganizationId);
        if (data.supervisoryOrganizationObj[0].id > 0) {
            setSupervisoryOrganizationObj(data.supervisoryOrganizationObj[0]);
            setSupervisoryOrganizationText(data.supervisoryOrganizationObj[0].name);
        }
        setHireDate(data.hireDate);
        setHireReason(data.hireReasonId);
        if (data.hireReasonObj[0].id > 0) {
            setHireReasonObj(data.hireReasonObj[0]);
            setHireReasonText(data.hireReasonObj[0].name);
        }
        setEmployeeType(data.employeeTypeId);
        if (data.employeeTypeObj[0].id > 0) {
            setEmployeeTypeObj(data.employeeTypeObj[0]);
            setEmployeeTypeText(data.employeeTypeObj[0].name);
        }
        setFirstDayOfWork(data.firstDayOfWork);
        setJobProfile(data.jobProfileId);
        if (data.jobProfileObj[0].id > 0) {
            setJobProfileObj(data.jobProfileObj[0]);
            setJobProfileText(data.jobProfileObj[0].name);
        }
        setJobTitle(data.jobTitleId);
        if (data.jobTitleObj[0].id > 0) {
            setJobTitleObj(data.jobTitleObj[0]);
            setJobTitleText(data.jobTitleObj[0].name);
        }
        setBusinessTitle(data.businessTitle);
        setLocationCode(data.locationCodeId);
        if (data.locationCodeObj[0].id > 0) {
            setLocationCodeObj(data.locationCodeObj[0]);
            setLocationCodeText(data.locationCodeObj[0].name);
        }
        setTimeType(data.contractTimeId);
        if (data.contractTimeObj[0].id > 0) {
            setTimeTypeObj(data.contractTimeObj[0]);
            setTimeTypeText(data.contractTimeObj[0].name);
        }
        setDefaultWeeklyHours(data.defaultWeeklyHours);
        setScheduledWeeklyHours(data.scheduledWeeklyHours);
        setGripLevel(data.gripLevelId);
        if (data.gripLevelObj[0].id > 0) {
            setGripLevelObj(data.gripLevelObj[0]);
            setGripLevelText(data.gripLevelObj[0].name);
        }
        setManagementStructure(data.managementStructureId);
        if (data.managementStructureObj[0].id > 0) {
            setManagementStructureObj(data.managementStructureObj[0]);
            setManagementStructureText(data.managementStructureObj[0].name);
        }
        setStaffSpecification(data.staffSpecificationId);
        if (data.staffSpecificationObj[0].id > 0) {
            setStaffSpecificationObj(data.staffSpecificationObj[0]);
            setStaffSpecificationText(data.staffSpecificationObj[0].name);
        }

        const callbackValues = {
            staffSelection: data.staffSpecificationId
                ? data.staffSpecificationId
                : "",
            hireDate: data.hireDate ? data.hireDate : null,
            employeeType: data.employeeTypeId
                ? data.employeeTypeId
                : "",
        };
        setIsLoader(false);
        props.callback(callbackValues);
    }

    //Get Job Details On Page Load
    function getJobDetailsOnLoad(id) {
        setIsLoader(true);
        const token = props.token;
        fetch("HRDetails/GetJobDetails/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    //Get EmployeeType from RegistrationData On Page Load
    function getEmployeeTypeDetailsOnLoad(id) {
        console.log('TESTTTTTTTTTTTTT', id);
        const token = props.token;
        fetch("HRDetails/GetEmployeeType/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setEmployeeType(data.id);
                    setEmployeeTypeObj(data);
                    setEmployeeTypeText(data.name);
                } else {
                    console.log("Error at getEmployeeTypeDetailsOnLoad");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("Error:", error);
            });
    }

    useEffect(() => {
        if (props.token) {
            if (props.id != undefined && props.id != "") {
                getJobDetailsOnLoad(props.id);
                getEmployeeTypeDetailsOnLoad(props.id);
            }
        }
        getButtonTextSave(jobDetailsId);
    }, [props.id, jobDetailsId, props.token]);

    useEffect(() => {
        if (props.token) {
            getSupervisoryOrg(props.id);
            getHiringReasons();
            getEmployeeTypes();
            getContractTimeTypes();
            getGripLevels();
            getManagementStructures();
            getStaffSpefications();
            getJobProfile();
            getJobTitle();
            getLocationCode();
        }
    }, [props.token]);

    return (
        <ThemeProvider theme={theme}>
            <div className="width-100">
                <form className="d-flex jc-center">
                    <Grid container spacing={1} className="d-flex fd-column ">
                        <table>
                            <tbody>
                                <tr className="d-flex jc-space-bt f-12 font-italic mt-1">
                                    <td></td>
                                    <td className="clr-dark-blue">
                                        {/***Note : Please provide GID on <span className="fw-bold">In Company Manager</span> text field*/}
                                    </td>
                                    <td className="clr-red">
                                        *Note : To change{" "}
                                        <span className="fw-bold">Employee Type</span> please click
                                        on <span className="fw-bold">Edit Candidate</span> Data from{" "}
                                        <span className="fw-bold">
                                            Candidate Data or On-Boarding Dashboard
                                        </span>{" "}
                                        List
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Grid
                            container
                            spacing={2}
                            className="d-flex jc-space-around fd-row"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={employeeTypeObj}
                                    inputValue={employeeTypeText}
                                    options={itemEmployeeTypes}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeEmployeeType}
                                    onInputChange={handleChangeEmployeeTypeInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Type"
                                            variant="filled"
                                            required
                                            error={erroremployeeType}
                                            helperText={errorTextemployeeType}
                                        />
                                    )}
                                    //disabled={props.disableValue}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="Hire Date"
                                    label="Hire Date"
                                    value={hireDate}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={false}
                                    disablepastdate={false}
                                    disableBefore={"01-Sep-2021"}
                                    isSpecificDateDisable={
                                        props.roleId && props.roleId == 1 ? false : false
                                    }
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : false
                                    }
                                    error={errorhireDate}
                                    helperText={errorTexthireDate}
                                    required={true}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={hireReasonObj}
                                    inputValue={hireReasonText}
                                    options={itemHiringReasons}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeHireReason}
                                    onInputChange={handleChangeHireReasonInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Hire Reason"
                                            variant="filled"
                                            required
                                            error={errorhireReason}
                                            helperText={errorTexthireReason}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} className="d-flex jc-center Width-100">
                                <FormDatePicker
                                    placeholder="First Day Of Work"
                                    label="First Day Of Work"
                                    value={firstDayOfWork}
                                    onChange={dateChangeEvent}
                                    disablefuturedate={false}
                                    disablepastdate={false}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.roleId && props.roleId == 1
                                                ? false
                                                : true
                                    }
                                    error={errorfirstDayOfWork}
                                    helperText={errorTextfirstDayOfWork}
                                    required={true}
                                    disableBefore={"01-Sep-2021"}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={supervisoryOrganizationObj}
                                    inputValue={supervisoryOrganizationText}
                                    options={itemsSupervisoryOrg}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeSupervisoryOrg}
                                    onInputChange={handleChangeSupervisoryOrgInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Supervisory Organization"
                                            variant="filled"
                                            required
                                            error={errorsupervisoryOrganization}
                                            helperText={errorTextsupervisoryOrganization}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            {/*<Grid item xs={12} sm={3} className="d-flex jc-center Width-100 mt-1">*/}
                            {/*    <Autocomplete*/}
                            {/*        className="lightInputFields hover-lightblue"*/}
                            {/*        id="combo-box-demo"*/}
                            {/*        value={jobProfileObj}*/}
                            {/*        inputValue={jobProfileText}*/}
                            {/*        options={itemsJobProfile}*/}
                            {/*        getOptionLabel={option => option.name ? option.name : ""}*/}
                            {/*        onChange={handleChangeJobProfile}*/}
                            {/*        onInputChange={handleChangeJobProfileInput}*/}
                            {/*        renderInput={*/}
                            {/*            (params) =>*/}
                            {/*                <TextField {...params}*/}
                            {/*                    label="Job Profile"*/}
                            {/*                    variant="filled"*/}
                            {/*                    required*/}
                            {/*                    error={errorjobProfile}*/}
                            {/*                    helperText={errorTextjobProfile}*/}
                            {/*                />*/}
                            {/*        }*/}
                            {/*        disabled={props.disableValue}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={jobTitleObj}
                                    inputValue={jobTitleText}
                                    options={itemsJobTitle}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeJobTitle}
                                    onInputChange={handleChangeJobTitleInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Job Title"
                                            variant="filled"
                                            required
                                            error={errorjobTitle}
                                            helperText={errorTextjobTitle}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    //required={true}
                                    id="businessTitle"
                                    fullWidth
                                    label="Business Title"
                                    variant="filled"
                                    name="Business Title"
                                    value={businessTitle}
                                    onChange={(e) => setBusinessTitle(e.target.value)}
                                    disabled={props.disableOnceCompleted}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={locationCodeObj}
                                    inputValue={locationCodeText}
                                    options={itemsLocationCode}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeLocationCode}
                                    onInputChange={handleChangeLocationCodeInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Location Code"
                                            variant="filled"
                                            required
                                            error={errorlocationCode}
                                            helperText={errorTextlocationCode}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={2}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={timeTypeObj}
                                    inputValue={timeTypeText}
                                    options={itemContractTimeTypes}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeTimeType}
                                    onInputChange={handleChangeTimeTypeInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Time Type"
                                            variant="filled"
                                            required
                                            error={errortimeType}
                                            helperText={errorTexttimeType}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="defaultWeeklyHours"
                                    fullWidth
                                    type="number"
                                    label="Default Weekly hours"
                                    variant="filled"
                                    name="Default Weekly hours"
                                    value={defaultWeeklyHours}
                                    onKeyDown={handleKeypress}
                                    onChange={handleChangeDefaultWeeklyHours}
                                    //required
                                    inputProps={{ min: 0, step: 0.01 }}
                                    error={errordefaultWeeklyHours}
                                    helperText={errorTextdefaultWeeklyHours}
                                    disabled={props.disableOnceCompleted}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} className="d-flex jc-center Width-100">
                                <TextField
                                    className="lightInputFields mt-1"
                                    required={true}
                                    id="scheduledWeeklyHours"
                                    fullWidth
                                    type="number"
                                    label="Scheduled Weekly hours"
                                    variant="filled"
                                    name="Scheduled Weekly hours"
                                    value={scheduledWeeklyHours}
                                    onKeyDown={handleKeypress}
                                    onChange={handleChangeScheduledWeeklyHours}
                                    //required
                                    inputProps={{ min: 0, step: 0.01 }}
                                    error={errorscheduledWeeklyHours}
                                    helperText={errorTextscheduledWeeklyHours}
                                    disabled={props.disableOnceCompleted}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={gripLevelObj}
                                    inputValue={gripLevelText}
                                    options={itemGripLevels}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeGripLevel}
                                    onInputChange={handleChangeGripLevelInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Grip Level"
                                            variant="filled"
                                            required
                                            error={errorgripLevel}
                                            helperText={errorTextgripLevel}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={managementStructureObj}
                                    inputValue={managementStructureText}
                                    options={itemManagementStructures}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeManagementStructure}
                                    onInputChange={handleChangeManagementStructureInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Management Structure"
                                            variant="filled"
                                            required
                                            error={errormanagementStructure}
                                            helperText={errorTextmanagementStructure}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className="d-flex jc-center Width-100 mt-1"
                            >
                                <Autocomplete
                                    className="lightInputFields hover-lightblue"
                                    id="combo-box-demo"
                                    value={staffSpecificationObj}
                                    inputValue={staffSpecificationText}
                                    options={itemStaffSpefications}
                                    getOptionLabel={(option) => (option.name ? option.name : "")}
                                    onChange={handleChangeStaffSpecification}
                                    onInputChange={handleChangeStaffSpecificationInput}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Staff Specification"
                                            variant="filled"
                                            required
                                            error={errorstaffSpecification}
                                            helperText={errorTextstaffSpecification}
                                        />
                                    )}
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableValue
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} className="d-flex jc-center">
                                <Button
                                    className="ns-btn-primary mt-2"
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableHRAction
                                    }
                                    onClick={clearHandler}
                                >
                                    <span>Clear</span>
                                </Button>
                                <Button
                                    className="ns-btn-primary mt-2 ml-2"
                                    disabled={
                                        props.disableOnceCompleted
                                            ? props.disableOnceCompleted
                                            : props.disableHRAction
                                    }
                                    onClick={submitHandler}
                                >
                                    <span>{buttonTextSave}</span>
                                </Button>
                            </Grid>
                        </Grid>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadSuccess"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlert}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleClose}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openConfirm}
                            onClose={handleCloseConfirm}
                            aria-labelledby="draggable-dialog-title"
                            id="templateUploadClear"
                        >
                            <DialogTitle
                                className={openClassName}
                                style={{ cursor: "move" }}
                                id="draggable-dialog-title"
                            >
                                {openType}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="clr-dark-blue">
                                    {openAlertConfirm}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className="bg-popup">
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">OK</span>
                                </Button>
                                <Button
                                    variant="contained"
                                    autoFocus
                                    onClick={handleCloseConfirm}
                                    className="ns-btn-secondary-light"
                                >
                                    <span className="f-16 fw-bold">Cancel</span>
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {isLoader ? <Loader /> : <></>}
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    );
}
