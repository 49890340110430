import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader";

const theme = createMuiTheme({
    overrides: {
        MuiAppBar: {
            root: {
                display: "flex",
            },
        },
    },
});

export default function ApplicationFormPreview(props) {
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            //var sParameterName = sURLVariables[i].split("=");
            var sParameterName = sURLVariables[i].split("id=");
            var value = sParameterName[1];
            //var value = "";
            //for (var j = 0; j < sParameterName.length; j++) {
            //  if (sParameterName[j] == sParam) {
            //    for (var k = j + 1; k < sParameterName.length; k++) {
            //      value = value + sParameterName[k];
            //    }
            //    break;
            //  }
            //  //return sParameterName[1];
            //}

            if (!value) {
                continue;
            } else {
                break;
            }
        }
        return value;
    }
    var encryptedId = getURLParameter("id");
    var id, data;

    const [employeeImage, setEmployeeImage] = useState(null);
    const [postAppliedFor, setPostAppliedFor] = useState("");
    const [referenceNumber, setReferenceNumber] = useState("");
    const [source, setSource] = useState("");
    const [title, setTitle] = useState("");
    const [initial, setInitial] = useState("");
    const [firstName, setFirstName] = useState("");
    const [are, setARE] = useState("");
    const [logoAREWise, setLogoAREWise] = useState(null);
    const [lastName, setLastName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [countryOfBirth, setCountryOfBirth] = useState("");
    const [nationality, setNationality] = useState("");
    const [stateOfBirth, setStateOfBirth] = useState("");
    const [religion, setReligion] = useState("");
    const [cityOfBirth, setCityOfBirth] = useState("");
    const [martialStatus, setMartialStatus] = useState("");
    const [marriedSince, setMarriedSince] = useState("");
    const [noOfChildren, setNoOfChildren] = useState("");
    const [panNo, setPANno] = useState("");
    const [aadharNo, setAadharNO] = useState("");
    const [noOfDependants, setNoOfDependants] = useState("");
    const [presentCO, setPresentCO] = useState("");
    const [presentFirstAddLine, setPresentFirstAddLine] = useState("");
    const [presentSecondAddLine, setPresentSecondAddLine] = useState("");
    const [presentThirdAddLine, setPresentThirdAddLine] = useState("");
    const [presentCountry, setPresentCountry] = useState("");
    const [presentState, setPresentState] = useState("");
    const [presentCity, setPresentCity] = useState("");
    const [pinCodePresent, setPinCodePresent] = useState("");
    const [regionPresent, setRegionPresent] = useState("");
    const [presentTelephoneNo, setPresentTelephoneNo] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [permanentCO, setPermanentCO] = useState("");
    const [permanentFirstAddLine, setPermanentFirstAddLine] = useState("");
    const [permanentSecondAddLine, setPermanentSecondAddLine] = useState("");
    const [permanentThirdAddLine, setPermanentThirdAddLine] = useState("");
    const [permanentCountry, setPermanentCountry] = useState("");
    const [permanentState, setPermanentState] = useState("");
    const [permanentCity, setPermanentCity] = useState("");
    const [pinCodePermanent, setPinCodePermanent] = useState("");
    const [regionPermanent, setRegionPermanent] = useState("");
    const [permanentTelephoneNo, setpermanentTelephoneNo] = useState("");
    const [mobileNoP, setMobileNoP] = useState("");
    //const [permanentTelephoneNoP, setPermanentTelephoneNoP] = useState('permanentTelephoneNo -Data');
    //const [alternateMobileNo, setAlternateMobileNo] = useState('alternateMobileNo -Data');
    const [passportNo, setPassportNo] = useState("");
    const [placeIssueOfPassport, setPlaceIssueOfPassport] = useState("");
    const [dateOfIssueOfPassport, setDateOfIssueOfPassport] = useState("");
    const [countryOfIssueOfPassport, setCountryOfIssueOfPassport] = useState("");
    const [passportValidTill, setPassportValidTill] = useState("");
    const [issuingAuthorityOfPassport, setIssuingAuthorityOfPassport] =
        useState("");
    const [encrStatusOfPassport, setENCRStatusOfPassport] = useState("");
    const [drivingLicenseNo, setDrivingLicenseNo] = useState("");
    const [placeOfIssue, setPlaceOfIssue] = useState("");
    const [dateOfIssueOfLicense, setDateOfIssueOfLicense] = useState("");
    const [countryOfIssueOfLicense, setCountryOfIssueOfLicense] = useState("");
    const [licenseValidTill, setLicenseValidTill] = useState("");
    const [issuingAuthorityOfLicense, setIssuingAuthorityOfLicense] =
        useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [alternateEmail, setAlternateEmail] = useState("");
    const [emgContactFirstName, setEmgContactFirstName] = useState("");
    const [emgLastName, setEmgLastName] = useState("");
    const [emgGender, setEmgGender] = useState("");
    const [landlineMobile, setLandlineMobile] = useState("");
    const [emergencyContactNo, setEmergencyContactNo] = useState("");
    const [educationData, setEducationData] = useState([]);
    const [professionalTraining, setProfessionalTraining] = useState([]);
    const [previousEmployment, setPreviousEmployment] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [references, setReferences] = useState([]);

    const [interest, setInterest] = useState("");
    const [interestYes, setInterestYes] = useState("");
    const [interestNo, setInterestNo] = useState("");
    const [personalBussiness, setPersonalBusiness] = useState("");
    const [personalBussinessYes, setPersonalBusinessYes] = useState("");
    const [personalBussinessNo, setPersonalBusinessNo] = useState("");
    const [contacts, setContacts] = useState("");
    const [contactsYes, setContactsYes] = useState("");
    const [contactsNo, setContactsNo] = useState("");
    const [illness, setIllness] = useState("");
    const [illnessYes, setIllnessYes] = useState("");
    const [illnessNo, setIllnessNo] = useState("");
    const [physical, setPhysical] = useState("");
    const [physicalYes, setPhysicalYes] = useState("");
    const [physicalNo, setPhysicalNo] = useState("");
    const [siemensPast, setSiemensPast] = useState("");
    const [siemensPastYes, setSiemensPastYes] = useState("");
    const [siemensPastNo, setSiemensPastNo] = useState("");
    const [siemensPastInterview, setSiemensPastInterview] = useState("");
    const [siemensPastInterviewYes, setSiemensPastInterviewYes] = useState("");
    const [siemensPastInterviewNo, setSiemensPastInterviewNo] = useState("");
    const [association, setAssociation] = useState("");
    const [associationYes, setAssociationYes] = useState("");
    const [associationNo, setAssociationNo] = useState("");
    const [position, setPosition] = useState("");
    const [positionYes, setPositionYes] = useState("");
    const [positionNo, setPositionNo] = useState("");
    const [expectedSalary, setExpectedSalary] = useState("");
    const [canJoinDate, setcanJoinDate] = useState("");
    const [contract, setContract] = useState("");
    const [contractYes, setContractYes] = useState("");
    const [contractNo, setContractNo] = useState("");
    const [contractNA, setContractNA] = useState("");
    const [confidentially, setConfidentially] = useState("");
    const [confidentiallyYes, setConfidentiallyYes] = useState("");
    const [confidentiallyNo, setConfidentiallyNo] = useState("");
    const [confidentiallyNA, setConfidentiallyNA] = useState("");
    const [arrested, setArrested] = useState("");
    const [arrestedYes, setArrestedYes] = useState("");
    const [arrestedNo, setArrestedNo] = useState("");
    const [arrestedNA, setArrestedNA] = useState("");
    const [litigation, setLitigation] = useState("");
    const [litigationYes, setLitigationYes] = useState("");
    const [litigationNo, setLitigationNo] = useState("");
    const [litigationNA, setLitigationNA] = useState("");
    const [closeRelatives, setCloseRelatives] = useState("");
    const [closeRelativesYes, setCloseRelativesYes] = useState("");
    const [closeRelativesNo, setCloseRelativesNo] = useState("");
    const [closeRelativesNA, setCloseRelativesNA] = useState("");
    const [closeRelativesPolitical, setCloseRelativesPolitical] = useState("");
    const [closeRelativesPoliticalYes, setCloseRelativesPoliticalYes] = useState("");
    const [closeRelativesPoliticalNo, setCloseRelativesPoliticalNo] = useState("");
    const [closeRelativesPoliticalNA, setCloseRelativesPoliticalNA] = useState("");
    const [contractualObligation, setContractualObligation] = useState("");
    const [contractualObligationYes, setContractualObligationYes] = useState("");
    const [contractualObligationNo, setContractualObligationNo] = useState("");
    const [contractualObligationNA, setContractualObligationNA] = useState("");

    const [dateOfExamination, setDateOfExamination] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [rhFactor, setRhFactor] = useState("");
    const [disability, setDisability] = useState("");
    const [disabilityYesOrNo, setDisabilityYesOrNo] = useState("");
    const [disabilityGrade, setDisabilityGrade] = useState("");
    const [disabledSince, setDisabledSince] = useState("");
    const [familyData, setFamilyData] = useState([]);
    const [providentFund, setProvidentFund] = useState([]);
    const [pensionScheme, setPensionScheme] = useState([]);
    const [gratuity, setGratuity] = useState([]);
    const [superAnnuation, setSuperAnnuation] = useState([]);
    const [lifeInsurance, setLifeInsurance] = useState([]);
    const [personalAccident, setPersonalAccident] = useState([]);
    const [taxRegime, setTaxRegime] = useState("");
    const [declarationCheck, setDeclarationCheck] = useState("");

    const [supervisoryOrganization, setSupervisoryOrganization] = useState("");
    const [hireDate, setHireDate] = useState("");
    const [hireReason, setHireReason] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const [jobProfile, setJobProfile] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [businessTitle, setBusinessTitle] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [timeType, setTimeType] = useState("");
    const [defaultWeeklyHours, setDefaultWeeklyHours] = useState("");
    const [scheduledWeeklyHours, setScheduledWeeklyHours] = useState("");
    const [gripLevel, setGripLevel] = useState("");
    const [managementStructure, setManagementStructure] = useState("");
    const [staffSpecification, setStaffSpecification] = useState("");
    const [company, setCompany] = useState("");
    const [costCenter, setCostCenter] = useState("");
    const [inCompanyManager, setInCompanyManager] = useState("");
    const [effectiveDate, setEffectiveDate] = useState("");
    const [contractType, setContractType] = useState("");
    const [contractStartDate, setContractStartDate] = useState("");
    const [contractEndDate, setContractEndDate] = useState("");
    const [contractStatus, setContractStatus] = useState("");
    const [contractReason, setContractReason] = useState("");
    const [continuousServiceDates, setContinuousServiceDates] = useState("");
    const [companyServiceDate, setCompanyServiceDate] = useState("");
    const [seniorityDate, setSeniorityDate] = useState("");
    const [collectiveAgreement, setCollectiveAgreement] = useState("");
    const [collectiveAgreementGroup, setCollectiveAgreementGroup] = useState("");
    const [probationType, setProbationType] = useState("");
    const [probationReason, setProbationReason] = useState("");
    const [probationStartDate, setProbationStartDate] = useState("");
    const [probationEndDate, setProbationEndDate] = useState("");
    const [probationDuration, setProbationDuration] = useState("");
    const [probationUnit, setProbationUnit] = useState("");
    const educationTemp = [];
    const languagesTemp = [];
    const trainingTemp = [];
    const previousEmploymentTemp = [];
    const referencesTemp = [];
    const familyTemp = [];
    const providentFundTemp = [];
    const pensionSchemeTemp = [];
    const gratuityTemp = [];
    const superAnnuationTemp = [];
    const lifeInsuranceTemp = [];
    const personalAccidentTemp = [];

    const [isLoader, setIsLoader] = useState(false);

    function SetStateAsPerFetched(data) {
        setARE(data.areid);
        //setEmployeeIdEncrypted(data.employeeIdencrypted);
        //(data.areId && data.areId != 1 && data.areId != 2)
        //    ?
        //    setLogoAREWise("../assets/images/sie-logo-petrol-rgb.png")
        //    :
        //    setLogoAREWise("../assets/images/Siemens_Logo_Healthineers.jpg");

        setPostAppliedFor(data.postAppliedFor);
        setReferenceNumber(data.registrationNo);
        setSource(data.source);
        setEmployeeImage(data.employeeImage);
        //Personal Data
        setTitle(data.title);
        setInitial(data.initial);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setMiddleName(data.middleName);
        setDateOfBirth(data.birthDateString);
        setGender(data.gender);
        setCountryOfBirth(data.birthCountry);
        setNationality(data.nationality);
        setStateOfBirth(data.birthState);
        setReligion(data.religion);
        setCityOfBirth(data.birthCity);
        setMartialStatus(data.maritalStatus);
        setMarriedSince(data.marriedSinceString);
        setNoOfChildren(data.noOfChildrenint);

        //Communication Data
        setPANno(data.panno);
        setAadharNO(data.aadharNo);
        setNoOfDependants(data.totalNoOfDependants);
        setPresentCO(data.presentCareOf);
        setPresentFirstAddLine(data.presentAddress1);
        setPresentSecondAddLine(data.presentAddress2);
        setPresentThirdAddLine(data.presentAddress3);
        setPresentCountry(data.presentAddressCountry);
        setPresentState(data.presentAddressState);
        setPresentCity(data.presentAddressCity);
        setPinCodePresent(data.presentAddressPinCode);
        setRegionPresent(data.presentAddressRegion);
        setPresentTelephoneNo(data.presentTelephoneNo);
        setMobileNo(data.presentMobileNo);
        setPermanentCO(data.permanentCareOf);
        setPermanentFirstAddLine(data.premanentAddress1);
        setPermanentSecondAddLine(data.permanentAddress2);
        setPermanentThirdAddLine(data.permanentAddress3);
        setPermanentCountry(data.permanentAddressCountry);
        setPermanentState(data.permanentAddressState);
        setPermanentCity(data.permanentAddressCity);
        setPinCodePermanent(data.permanentAddressPinCode);
        setRegionPermanent(data.permanentAddressRegion);
        setpermanentTelephoneNo(data.permanentTelephoneNo);
        setMobileNoP(data.premanentMobileNo);
        setPassportNo(data.passportNumber);
        setPlaceIssueOfPassport(data.placeOfPassportIssued);
        setDateOfIssueOfPassport(data.dateOfPassportIssuedString);
        setPassportValidTill(data.passportValidTillString);
        setENCRStatusOfPassport(data.encrstatusOfPassport);
        setDrivingLicenseNo(data.dlnumber);
        setIssuingAuthorityOfLicense(data.issuingAuthorityOfDl);
        setDateOfIssueOfLicense(data.dateOfDlissuedString);
        setCountryOfIssueOfLicense(data.countryofDlissued);
        setLicenseValidTill(data.dlvalidTillString);
        setPlaceOfIssue(data.placeOfDlissued);
        setPersonalEmail(data.personalEmailId);
        setAlternateEmail(data.alternateEmailId);
        setEmgContactFirstName(data.ecfirstName);
        setEmgLastName(data.eclastName);
        setEmgGender(data.ecgender);
        setLandlineMobile(data.ecnumberType);
        setEmergencyContactNo(data.ecnumber);

        //Education Data

        for (var i = 0; i < data.educationData.length; i++) {
            educationTemp.push({
                dateOfJoining: data.educationData[i].dateOfJoining.substring(0, 10)
                    ? data.educationData[i].dateOfJoining.substring(0, 10)
                    : "",
                dateOfLeaving: data.educationData[i].dateOfLeaving.substring(0, 10)
                    ? data.educationData[i].dateOfLeaving.substring(0, 10)
                    : "",
                educationDegree: data.educationData[i].educationDegree,
                fieldOfStudy: data.educationData[i].fieldOfStudy,
                instituteCollege: data.educationData[i].instituteCollege,
                specialization2: data.educationData[i].specialization2,
                courseDurationYears: data.educationData[i].courseDurationYears,
                marksTotal: data.educationData[i].marksTotal,
                marksObtained: data.educationData[i].marksObtained,
                percentageGrade: data.educationData[i].percentageGrade,
                higherEducation: data.educationData[i].higherEducation,
                marksheetAvailable: data.educationData[i].marksheetAvailable,
                marksheetSubmissionDate: data.educationData[i].marksheetSubmissionDate
                    ? data.educationData[i].marksheetSubmissionDate.substring(0, 10)
                    : "",

                //specialization1: data.educationData[i].specialization1,
                //  certificate: data.educationData[i].certificate,
                //  country: data.educationData[i].country,
                //  courseDurationMonths: data.educationData[i].courseDurationMonths,
                //training: data.educationData[i].training,
            });
        }

        setEducationData(educationTemp);

        //Professional Training Data
        for (var i = 0; i < data.trainingData.length; i++) {
            trainingTemp.push({
                trainingInstitute: data.trainingData[i].trainingInstitute,
                detailsOfTraining: data.trainingData[i].detailsOfTraining,
                course: data.trainingData[i].course,
                periodFrom: data.trainingData[i].periodFrom.substring(0, 10)
                    ? data.trainingData[i].periodFrom.substring(0, 10)
                    : "",
                periodTo: data.trainingData[i].periodTo.substring(0, 10)
                    ? data.trainingData[i].periodTo.substring(0, 10)
                    : "",
            });
        }

        setProfessionalTraining(trainingTemp);

        //Previous Employment History Data
        for (var i = 0; i < data.previousEmpHistoryData.length; i++) {
            previousEmploymentTemp.push({
                companyName: data.previousEmpHistoryData[i].companyName,
                lastDesignation: data.previousEmpHistoryData[i].lastDesignation,
                industry: data.previousEmpHistoryData[i].industry,
                countryName: data.previousEmpHistoryData[i].countryName,
                dateOfJoining: data.previousEmpHistoryData[i].dateOfJoining.substring(
                    0,
                    10
                )
                    ? data.previousEmpHistoryData[i].dateOfJoining.substring(0, 10)
                    : "",
                dateOfLeaving: data.previousEmpHistoryData[i].dateOfLeaving.substring(
                    0,
                    10
                )
                    ? data.previousEmpHistoryData[i].dateOfLeaving.substring(0, 10)
                    : "",
                relevantExpMonths: data.previousEmpHistoryData[i].relevantExpMonths,
                relevantExpYears: data.previousEmpHistoryData[i].relevantExpYears,
                totalExpMonths: data.previousEmpHistoryData[i].totalExpMonths,
                totalExpYears: data.previousEmpHistoryData[i].totalExpYears,
                fixedSalaryWithComma:
                    data.previousEmpHistoryData[i].fixedSalaryWithComma,
                variableSalaryWithComma:
                    data.previousEmpHistoryData[i].variableSalaryWithComma,
            });
        }

        setPreviousEmployment(previousEmploymentTemp);

        //Language Data
        for (var i = 0; i < data.languageData.length; i++) {
            languagesTemp.push({
                language: data.languageData[i].language,
                proficiency: data.languageData[i].proficiency,
            });
        }

        setLanguages(languagesTemp);

        //Reference Data
        for (var i = 0; i < data.referenceData.length; i++) {
            referencesTemp.push({
                referenceName: data.referenceData[i].referenceName,
                occupation: data.referenceData[i].occupation,
                organizationName: data.referenceData[i].organizationName,
                designation: data.referenceData[i].designation,
                address1: data.referenceData[i].address1,
                email: data.referenceData[i].email,
                telePhoneNo: data.referenceData[i].telePhoneNo,
                mobileNo: data.referenceData[i].mobileNo,
            });
        }

        setReferences(referencesTemp);

        //GB Declaration Data
        if (data.gbDeclarationData.listEmployeeGBData != null) {
            setInterest(
                data.gbDeclarationData.listEmployeeGBData[0].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[0].yesNo == true) {
                setInterestYes("Yes");
            } else {
                setInterestNo("No");
            }

            setPersonalBusiness(
                data.gbDeclarationData.listEmployeeGBData[1].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[1].yesNo == true) {
                setPersonalBusinessYes("Yes");
            } else {
                setPersonalBusinessNo("No");
            }

            setContacts(
                data.gbDeclarationData.listEmployeeGBData[2].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[2].yesNo == true) {
                setContactsYes("Yes");
            } else {
                setContactsNo("No");
            }

            setIllness(
                data.gbDeclarationData.listEmployeeGBData[3].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[3].yesNo == true) {
                setIllnessYes("Yes");
            } else {
                setIllnessNo("No");
            }

            setPhysical(
                data.gbDeclarationData.listEmployeeGBData[4].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[4].yesNo == true) {
                setPhysicalYes("Yes");
            } else {
                setPhysicalNo("No");
            }

            setSiemensPast(
                data.gbDeclarationData.listEmployeeGBData[5].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[5].yesNo == true) {
                setSiemensPastYes("Yes");
            } else {
                setSiemensPastNo("No");
            }

            setSiemensPastInterview(
                data.gbDeclarationData.listEmployeeGBData[6].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[6].yesNo == true) {
                setSiemensPastInterviewYes("Yes");
            } else {
                setSiemensPastInterviewNo("No");
            }

            setAssociation(
                data.gbDeclarationData.listEmployeeGBData[7].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[7].yesNo == true) {
                setAssociationYes("Yes");
            } else {
                setAssociationNo("No");
            }

            setPosition(
                data.gbDeclarationData.listEmployeeGBData[8].gbdeclarationComments
            );
            if (data.gbDeclarationData.listEmployeeGBData[8].yesNo == true) {
                setPositionYes("Yes");
            } else {
                setPositionNo("No");
            }
        }

        setExpectedSalary(data.gbDeclarationData.expectedSalary);
        if (
            data.gbDeclarationData.canJoinDate != null &&
            data.gbDeclarationData.canJoinDate != "" &&
            data.gbDeclarationData.canJoinDate != undefined
        ) {
            setcanJoinDate(data.gbDeclarationData.canJoinDate.substring(0, 10));
        }

        //Declarations
        if (data.declarationData.listDeclarationData) {
            setContract(
                data.declarationData.listDeclarationData[0].declarationComments
            );
            if (data.declarationData.listDeclarationData[0].yesNoNa == 1) {
                setContractYes("Yes");
            } else if (data.declarationData.listDeclarationData[0].yesNoNa == 0) {
                setContractNo("No");
            } else if (data.declarationData.listDeclarationData[0].yesNoNa == 2) {
                setContractNA("NA");
            }

            setConfidentially(
                data.declarationData.listDeclarationData[1].declarationComments
            );
            if (data.declarationData.listDeclarationData[1].yesNoNa == 1) {
                setConfidentiallyYes("Yes");
            } else if (data.declarationData.listDeclarationData[1].yesNoNa == 0) {
                setConfidentiallyNo("No");
            } else if (data.declarationData.listDeclarationData[1].yesNoNa == 2) {
                setConfidentiallyNA("NA");
            }

            setArrested(
                data.declarationData.listDeclarationData[2].declarationComments
            );
            if (data.declarationData.listDeclarationData[2].yesNoNa == 1) {
                setArrestedYes("Yes");
            } else if (data.declarationData.listDeclarationData[2].yesNoNa == 0) {
                setArrestedNo("No");
            } else if (data.declarationData.listDeclarationData[2].yesNoNa == 2) {
                setArrestedNA("NA");
            }

            setCloseRelatives(
                data.declarationData.listDeclarationData[3].declarationComments
            );
            if (data.declarationData.listDeclarationData[3].yesNoNa == 1) {
                setCloseRelativesYes("Yes");
            } else if (data.declarationData.listDeclarationData[3].yesNoNa == 0) {
                setCloseRelativesNo("No");
            } else if (data.declarationData.listDeclarationData[3].yesNoNa == 2) {
                setCloseRelativesNA("NA");
            }

            setContractualObligation(
                data.declarationData.listDeclarationData[4].declarationComments
            );
            if (data.declarationData.listDeclarationData[4].yesNoNa == 1) {
                setContractualObligationYes("Yes");
            } else if (data.declarationData.listDeclarationData[4].yesNoNa == 0) {
                setContractualObligationNo("No");
            } else if (data.declarationData.listDeclarationData[4].yesNoNa == 2) {
                setContractualObligationNA("NA");
            }

            setLitigation(
                data.declarationData.listDeclarationData[5]?.declarationComments
            );
            if (data.declarationData.listDeclarationData[5]?.yesNoNa == 1) {
                setLitigationYes("Yes");
            } else if (data.declarationData.listDeclarationData[5]?.yesNoNa == 0) {
                setLitigationNo("No");
            } else if (data.declarationData.listDeclarationData[5]?.yesNoNa == 2) {
                setLitigationNA("NA");
            }

            setCloseRelativesPolitical(
                data.declarationData.listDeclarationData[6]?.declarationComments
            );
            if (data.declarationData.listDeclarationData[6]?.yesNoNa == 1) {
                setCloseRelativesPoliticalYes("Yes");
            } else if (data.declarationData.listDeclarationData[6]?.yesNoNa == 0) {
                setCloseRelativesPoliticalNo("No");
            } else if (data.declarationData.listDeclarationData[6]?.yesNoNa == 2) {
                setCloseRelativesPoliticalNA("NA");
            }
        }

        //Onboarding form
        //Medical Data
        if (
            data.medicalData.dateOfExamination != null &&
            data.medicalData.dateOfExamination != "" &&
            data.medicalData.dateOfExamination != undefined
        ) {
            setDateOfExamination(data.medicalData.dateOfExamination.substring(0, 10));
        }
        if (
            data.medicalData.disabledSince != null &&
            data.medicalData.disabledSince != "" &&
            data.medicalData.disabledSince != undefined
        ) {
            setDisabledSince(data.medicalData.disabledSince.substring(0, 10));
        }

        setBloodGroup(data.bloodGroup);
        setRhFactor(data.rhFactor);
        setDisabilityYesOrNo(data.disabilityYesOrNo);
        setDisability(data.disability);
        setDisabilityGrade(data.disabilityGrade);

        //Family Data
        for (var i = 0; i < data.familyData.length; i++) {
            familyTemp.push({
                familyFirstName: data.familyData[i].familyFirstName,
                familyMiddleName: data.familyData[i].familyMiddleName,
                familyLastName: data.familyData[i].familyLastName,
                familyDateOfBirth: data.familyData[i].familyDateOfBirth.substring(0, 10)
                    ? data.familyData[i].familyDateOfBirth.substring(0, 10)
                    : "",
                occupation: data.familyData[i].occupation,
                gender: data.familyData[i].gender,
                relationship: data.familyData[i].relationship,
            });
        }

        setFamilyData(familyTemp);

        //Provident Fund Data
        for (var i = 0; i < data.providentFundData.length; i++) {
            providentFundTemp.push({
                firstNameNominee: data.providentFundData[i].firstNameNominee,
                middleNameNominee: data.providentFundData[i].middleNameNominee,
                lastNameNominee: data.providentFundData[i].lastNameNominee,
                dob: data.providentFundData[i].dob.substring(0, 10)
                    ? data.providentFundData[i].dob.substring(0, 10)
                    : "",
                nomineeRelationWithMember:
                    data.providentFundData[i].nomineeRelationWithMember,
                nomineeAddress: data.providentFundData[i].nomineeAddress,
                nomineeShare: data.providentFundData[i].nomineeShare,
                guardianName: data.providentFundData[i].guardianName,
                guardianAddress: data.providentFundData[i].guardianAddress,
                validationOfMinorYesOrNo:
                    data.providentFundData[i].validationOfMinorYesOrNo,
            });
        }

        setProvidentFund(providentFundTemp);

        //Pension Scheme Data
        for (var i = 0; i < data.pensionSchemeData.length; i++) {
            pensionSchemeTemp.push({
                firstNameNominee: data.pensionSchemeData[i].firstNameNominee,
                middleNameNominee: data.pensionSchemeData[i].middleNameNominee,
                lastNameNominee: data.pensionSchemeData[i].lastNameNominee,
                dob: data.pensionSchemeData[i].dob.substring(0, 10)
                    ? data.pensionSchemeData[i].dob.substring(0, 10)
                    : "",
                relationWithMember: data.pensionSchemeData[i].relationWithMember,
                nomineeAddress: data.pensionSchemeData[i].nomineeAddress,
                gaurdianName: data.pensionSchemeData[i].gaurdianName,
                gaurdianAddress: data.pensionSchemeData[i].gaurdianAddress,
                validationOfMinorYesOrNo:
                    data.pensionSchemeData[i].validationOfMinorYesOrNo,
            });
        }

        setPensionScheme(pensionSchemeTemp);

        //Gratuity Data
        for (var i = 0; i < data.gratuityData.length; i++) {
            gratuityTemp.push({
                firstNameNominee: data.gratuityData[i].firstNameNominee,
                middleNameNominee: data.gratuityData[i].middleNameNominee,
                lastNameNominee: data.gratuityData[i].lastNameNominee,
                dob: data.gratuityData[i].dob.substring(0, 10)
                    ? data.gratuityData[i].dob.substring(0, 10)
                    : "",
                relationWithEmployee: data.gratuityData[i].relationWithEmployee,
                address: data.gratuityData[i].address,
                proportionShared: data.gratuityData[i].proportionShared,
                gaurdianName: data.gratuityData[i].gaurdianName,
                gaurdianAddress: data.gratuityData[i].gaurdianAddress,
                validationOfMinorYesOrNo: data.gratuityData[i].validationOfMinorYesOrNo,
            });
        }

        setGratuity(gratuityTemp);

        //Super Annuation Data
        for (var i = 0; i < data.superAnnuationData.length; i++) {
            superAnnuationTemp.push({
                firstNameNominee: data.superAnnuationData[i].firstNameNominee,
                middleNameNominee: data.superAnnuationData[i].middleNameNominee,
                lastNameNominee: data.superAnnuationData[i].lastNameNominee,
                dob: data.superAnnuationData[i].dob.substring(0, 10)
                    ? data.superAnnuationData[i].dob.substring(0, 10)
                    : "",
                relationWithEmployee: data.superAnnuationData[i].relationWithEmployee,
                nomineeAddress: data.superAnnuationData[i].nomineeAddress,
                sharePercentage: data.superAnnuationData[i].sharePercentage,
                gaurdianName: data.superAnnuationData[i].gaurdianName,
                gaurdianAddress: data.superAnnuationData[i].gaurdianAddress,
                validationOfMinorYesOrNo:
                    data.superAnnuationData[i].validationOfMinorYesOrNo,
            });
        }

        setSuperAnnuation(superAnnuationTemp);

        //Life Insurance Data
        for (var i = 0; i < data.lifeInsuranceData.length; i++) {
            lifeInsuranceTemp.push({
                firstNameNominee: data.lifeInsuranceData[i].firstNameNominee,
                middleNameNominee: data.lifeInsuranceData[i].middleNameNominee,
                lastNameNominee: data.lifeInsuranceData[i].lastNameNominee,
                dob: data.lifeInsuranceData[i].dob.substring(0, 10)
                    ? data.lifeInsuranceData[i].dob.substring(0, 10)
                    : "",
                relationWithEmployee: data.lifeInsuranceData[i].relationWithEmployee,
                nomineeAddress: data.lifeInsuranceData[i].nomineeAddress,
                sharePercentage: data.lifeInsuranceData[i].sharePercentage,
                guardianName: data.lifeInsuranceData[i].guardianName,
                guardianAddress: data.lifeInsuranceData[i].guardianAddress,
                validationOfMinorYesOrNo:
                    data.lifeInsuranceData[i].validationOfMinorYesOrNo,
            });
        }

        setLifeInsurance(lifeInsuranceTemp);

        //Personal Accident Data
        for (var i = 0; i < data.personalAccidentData.length; i++) {
            personalAccidentTemp.push({
                firstNameNominee: data.personalAccidentData[i].firstNameNominee,
                middleNameNominee: data.personalAccidentData[i].middleNameNominee,
                lastNameNominee: data.personalAccidentData[i].lastNameNominee,
                dob: data.personalAccidentData[i].dob.substring(0, 10)
                    ? data.personalAccidentData[i].dob.substring(0, 10)
                    : "",
                relationWithEmployee: data.personalAccidentData[i].relationWithEmployee,
                nomineeAddress: data.personalAccidentData[i].nomineeAddress,
                sharePercentage: data.personalAccidentData[i].sharePercentage,
                guardianName: data.personalAccidentData[i].guardianName,
                guardianAddress: data.personalAccidentData[i].guardianAddress,
                validationOfMinorYesOrNo:
                    data.personalAccidentData[i].validationOfMinorYesOrNo,
            });
        }

        setPersonalAccident(personalAccidentTemp);

        //Tax Regime Data
        setTaxRegime(data.taxRegimeData.taxRegime);
        setDeclarationCheck(data.taxRegimeData.declarationIsChecked);

        ////Declaration Check
        //if (data.taxRegimeData.declarationIsChecked == true) {
        //    setDeclarationCheck(require('/OnBoarding/Images/icn_checkbox_inaktiv.gif'));
        //}

        //HR Details
        //Job Details
        setSupervisoryOrganization(data.supervisoryOrganization);
        if (
            data.jobDetailsData.hireDate != null &&
            data.jobDetailsData.hireDate != "" &&
            data.jobDetailsData.hireDate != undefined
        ) {
            setHireDate(data.jobDetailsData.hireDate.substring(0, 10));
        }
        setHireReason(data.hireReason);
        setEmployeeType(data.employeeType);
        setJobProfile(data.jobProfile);
        setJobTitle(data.jobTitle);
        setBusinessTitle(data.jobDetailsData.businessTitle);
        setLocationCode(data.locationCode);
        setTimeType(data.contractTime);
        setDefaultWeeklyHours(data.jobDetailsData.defaultWeeklyHours);
        setScheduledWeeklyHours(data.jobDetailsData.scheduledWeeklyHours);
        setGripLevel(data.gripLevel);
        setManagementStructure(data.managementStructure);
        setStaffSpecification(data.staffSpecification);

        //Change Org Assignment
        setCompany(data.company);
        setCostCenter(data.costCenter);
        setInCompanyManager(data.changeOrgData.companyManagerGid);

        //Maintain Employee Contract
        if (
            data.manageEmpContractData.effectiveDate != null &&
            data.manageEmpContractData.effectiveDate != "" &&
            data.manageEmpContractData.effectiveDate != undefined
        ) {
            setEffectiveDate(
                data.manageEmpContractData.effectiveDate.substring(0, 10)
            );
        }
        setContractType(data.contractType);
        if (
            data.manageEmpContractData.contractStartDate != null &&
            data.manageEmpContractData.contractStartDate != "" &&
            data.manageEmpContractData.contractStartDate != undefined
        ) {
            setContractStartDate(
                data.manageEmpContractData.contractStartDate.substring(0, 10)
            );
        }
        if (
            data.manageEmpContractData.contractEndDate != null &&
            data.manageEmpContractData.contractEndDate != "" &&
            data.manageEmpContractData.contractEndDate != undefined
        ) {
            setContractEndDate(
                data.manageEmpContractData.contractEndDate.substring(0, 10)
            );
        }
        setContractStatus(data.contractStatus);
        setContractReason(data.contractReason);

        //Edit Service Dates
        if (
            data.editServicesDate.continuousServiceDates != null &&
            data.editServicesDate.continuousServiceDates != "" &&
            data.editServicesDate.continuousServiceDates != undefined
        ) {
            setContinuousServiceDates(
                data.editServicesDate.continuousServiceDates.substring(0, 10)
            );
        }
        if (
            data.editServicesDate.companyServiceDate != null &&
            data.editServicesDate.companyServiceDate != "" &&
            data.editServicesDate.companyServiceDate != undefined
        ) {
            setCompanyServiceDate(
                data.editServicesDate.companyServiceDate.substring(0, 10)
            );
        }
        if (
            data.editServicesDate.seniorityDate != null &&
            data.editServicesDate.seniorityDate != "" &&
            data.editServicesDate.seniorityDate != undefined
        ) {
            setSeniorityDate(data.editServicesDate.seniorityDate.substring(0, 10));
        }

        //Assign Collective Agreeement
        setCollectiveAgreement(data.collectiveAgreement);
        setCollectiveAgreementGroup(data.collectiveGroupAgreement);

        //Manage Probation Period
        setProbationType(data.probationType);
        setProbationReason(data.probationReason);
        if (
            data.manageProbationData.probationStartDate != null &&
            data.manageProbationData.probationStartDate != "" &&
            data.manageProbationData.probationStartDate != undefined
        ) {
            setProbationStartDate(
                data.manageProbationData.probationStartDate.substring(0, 10)
            );
        }
        if (
            data.manageProbationData.probationEndDate != null &&
            data.manageProbationData.probationEndDate != "" &&
            data.manageProbationData.probationEndDate != undefined
        ) {
            setProbationEndDate(
                data.manageProbationData.probationEndDate.substring(0, 10)
            );
        }
        setProbationDuration(data.manageProbationData.probationDuration);
        setProbationUnit(data.manageProbationData.probationUnits);

        setIsLoader(false);
    }

    async function GetApplicationFormPreviewById(encryptedId) {
        const token = props.token;
        //if (encryptedId.substr(22) != null && encryptedId.substr(22) != "") {
        //  id = encryptedId.substr(22);
        //} else {
        //  id = encryptedId;
        //}
        id = encryptedId;
        await fetch("Employee/GetCandidateApplicationFormPreview/" + id, {
            headers: { Authorization: "Bearer " + token },
        })
            .then((response) => response.json())
            .then((data) => {
                SetStateAsPerFetched(data);
            })
            .catch((error) => console.log("Error:", error));
    }

    useEffect(() => {
        setIsLoader(true);
        if (props.token) {
            if (
                encryptedId != undefined &&
                encryptedId != "" &&
                encryptedId != null
            ) {
                GetApplicationFormPreviewById(encryptedId);
            }
        } else {
            setIsLoader(false);
        }
    }, [encryptedId, props.token]);

    function SetLogoPerARE(are) {
        if (are == 1 || are == 2) {
            setLogoAREWise("../assets/images/Siemens_Logo_Healthineers.jpg");
        } else {
            setLogoAREWise("../assets/images/sie-logo-petrol-rgb.png");
        }
    }

    useEffect(() => {
        if (are != null && are != "") {
            SetLogoPerARE(are);
        }
    }, [are]);

    return (
        <ThemeProvider theme={theme}>
            <div className="master-layout-blockPrint mt-5">
                <table id="tblMain" classNameName="tablemain width-100">
                    <tr>
                        <td>
                            <div id="divPrint">
                                <table>
                                    <tr>
                                        <td>
                                            <thead className="d-flex">
                                                <tr>
                                                    <th>
                                                        <img
                                                            src={logoAREWise}
                                                            alt="Siemens Company Logo"
                                                            height="30"
                                                            width="100"
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tr>
                                                <td>
                                                    <table className="d-flex jc-center">
                                                        <tr>
                                                            <td className="tablename">
                                                                <strong>Pre Hiring Application Form</strong>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="d-flex jc-flex-end">
                                                    <img
                                                        id="ImgPhoto"
                                                        src={`data:image/*;base64,${employeeImage}`}
                                                        height="140"
                                                        width="140"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table
                                                        id="tblPost"
                                                        className="width-100 table-border"
                                                    >
                                                        <tr>
                                                            <td className="width-100 custom-font">
                                                                <table className=" width-100">
                                                                    {/* <tr>
                                    <td className=" custom-font width-50">
                                      Post Applied For
                                    </td>
                                    <td className=" custom-font width-50">
                                      {postAppliedFor}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className=" custom-font width-50">
                                      Reference Number
                                    </td>
                                    <td className=" custom-font width-50">
                                      {referenceNumber}
                                    </td>
                                  </tr> */}
                                                                    <tr>
                                                                        <td className=" custom-font width-50">
                                                                            Source
                                                                        </td>
                                                                        <td className=" custom-font width-50">
                                                                            {source}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Personal Data
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Title
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {title}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Initial
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {initial}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            First Name
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {firstName}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Last Name
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {lastName}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Middle Name
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {middleName}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Date Of Birth
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {dateOfBirth}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Gender
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {gender}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Country Of Birth
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {countryOfBirth}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Nationality
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {nationality}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            State Of Birth
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {stateOfBirth}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Religion
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {religion}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            City Of Birth
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {cityOfBirth}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Marital Status
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {martialStatus}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {" "}
                                                                            Married Since
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {marriedSince}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            No Of Children
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {noOfChildren}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            colspan="2"
                                                                            className="custom-font fw-bold width-100"
                                                                        >
                                                                            Communication Data
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            PAN No or Applied For PAN
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {panNo}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Aadhar Card Number
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {aadharNo}
                                                                        </td>
                                                                        <td className="width-25 custom-font"></td>
                                                                        <td className="width-25 custom-font"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Address Present
                                                                        </td>
                                                                        <td className="width-25 custom-font"></td>
                                                                        <td className="width-25 custom-font">
                                                                            {" "}
                                                                            Address Permanent
                                                                        </td>
                                                                        <td className="width-25 custom-font"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            C/o
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentCO}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            C/o
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentCO}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            1st Address Line
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentFirstAddLine}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            1st Address Line
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentFirstAddLine}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            2nd Address Line
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentSecondAddLine}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            2nd Address Line
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentSecondAddLine}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            3rd Address Line
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentThirdAddLine}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            3rd Address Line
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentThirdAddLine}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Country
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentCountry}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Country
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentCountry}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            State
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentState}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            State
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentState}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            City
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentCity}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            City
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentCity}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Pin Code
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {pinCodePresent}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Pin Code
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {pinCodePermanent}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Region
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {regionPresent}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Region
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {regionPermanent}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Present Telephone No.
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {presentTelephoneNo}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Permanent Telephone No.
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {permanentTelephoneNo}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Mobile No.
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {mobileNo}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Alternate Mobile No.
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {mobileNoP}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Passport No.
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {passportNo}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Place Issue of Passport
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {placeIssueOfPassport}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Date of Issue of Passport
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {dateOfIssueOfPassport}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Passport Valid Till
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {passportValidTill}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            ENCR Status of Passport
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {encrStatusOfPassport}
                                                                        </td>
                                                                        <td className="width-25 custom-font"></td>
                                                                        <td className="width-25 custom-font"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Driving Licence No
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {drivingLicenseNo}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Issuing Authority
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {issuingAuthorityOfLicense}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Date of Issue of License
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {dateOfIssueOfLicense}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            License Valid Till
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {licenseValidTill}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Country of Issue
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {countryOfIssueOfLicense}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Place of Issue
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {placeOfIssue}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Personal Email
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {personalEmail}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Alternate Email
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {alternateEmail}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Emergency Contact Details
                                                                        </td>
                                                                        <td className="width-25 custom-font"></td>
                                                                        <td className="width-25 custom-font"></td>
                                                                        <td className="width-25 custom-font"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            First Name
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {emgContactFirstName}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Last Name
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {emgLastName}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Gender
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {emgGender}
                                                                        </td>
                                                                        <td className="width-25 custom-font"></td>
                                                                        <td className="width-25 custom-font"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Landline/Mobile
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {landlineMobile}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Emergency Contact No
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {emergencyContactNo}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className=" width-100 custom-font fw-bold">
                                                                Education Data
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 border-collapse">
                                                                    <tr>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Start Date
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            End Date
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Education Degree
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Field of Study
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Institute or School Name
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Specialization
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Course Duration (Years)
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Total Marks
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Marks Obtained
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Percentage
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Highest Education
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Marksheet and Certificate available
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Marksheet and Certificate submission date
                                                                        </td>
                                                                    </tr>
                                                                    {educationData.map((item, index) => (
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                {item.dateOfJoining}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.dateOfLeaving}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.educationDegree}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.fieldOfStudy}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.instituteCollege}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.specialization2}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.courseDurationYears}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.marksTotal}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.marksObtained}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.percentageGrade}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.higherEducation}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.marksheetAvailable}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.marksheetSubmissionDate}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        {/*<tr>*/}
                                                        {/*    <td className=" width-100 custom-font fw-bold">Professional Trainings</td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                        {/*    <td colspan="2" className="width-100">*/}
                                                        {/*        <table className="width-100 table-border border-collapse">*/}
                                                        {/*            <tr>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Course</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Period From</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Period To</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Institute/Oragnization</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Details Of Training</td>*/}
                                                        {/*            </tr>*/}
                                                        {/*            <tr>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {professionalTraining.map(professionalTrainingNew => <div>{professionalTrainingNew.course}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {professionalTraining.map(professionalTrainingNew => <div>{professionalTrainingNew.periodFrom}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {professionalTraining.map(professionalTrainingNew => <div>{professionalTrainingNew.periodTo}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {professionalTraining.map(professionalTrainingNew => <div>{professionalTrainingNew.trainingInstitute}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {professionalTraining.map(professionalTrainingNew => <div>{professionalTrainingNew.detailsOfTraining}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*            </tr>*/}
                                                        {/*        </table>*/}
                                                        {/*    </td>*/}
                                                        {/*</tr>*/}

                                                        <tr>
                                                            <td className=" width-100 custom-font fw-bold">
                                                                Previous Employment History
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border border-collapse">
                                                                    <tr>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Name Of Company
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Last Designation
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Industry
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Country
                                                                        </td>

                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Date Of Joining
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Date Of leaving
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Relevant Experience(Months)
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Relevant Experience(Years)
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Total Experience(Months)
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Total Experience(Years)
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Fixed Salary(p.a.)
                                                                        </td>
                                                                        <td className=" custom-font fw-bold table-border">
                                                                            Variable Salary(p.a.)
                                                                        </td>
                                                                    </tr>
                                                                    {previousEmployment.map((item, index) => (
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                {item.companyName}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.lastDesignation}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.industry}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.countryName}
                                                                            </td>

                                                                            <td className=" custom-font table-border">
                                                                                {item.dateOfJoining}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.dateOfLeaving}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.relevantExpMonths}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.relevantExpYears}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.totalExpMonths}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.totalExpYears}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.fixedSalaryWithComma}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {item.variableSalaryWithComma}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        {/*<tr>*/}
                                                        {/*    <td colspan="2" className="width-100 custom-font fw-bold" >Languages</td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                        {/*    <td colspan=" 2" className="width-100">*/}
                                                        {/*        <table className="width-100 table-border border-collapse">*/}
                                                        {/*            <tr>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Language</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Proficiency</td>*/}
                                                        {/*            </tr>*/}
                                                        {/*            <tr>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {languages.map(languageDataNew => <div>{languageDataNew.language}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {languages.map(languageDataNew => <div>{languageDataNew.proficiency}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*            </tr>*/}
                                                        {/*        </table>*/}
                                                        {/*    </td>*/}
                                                        {/*</tr>*/}

                                                        {/*<tr>*/}
                                                        {/*    <td className="width-100" className=" custom-font fw-bold">References</td>*/}
                                                        {/*</tr>*/}
                                                        {/*<tr>*/}
                                                        {/*    <td colspan=" 2" className="width-100">*/}
                                                        {/*        <table className="width-100 table-border border-collapse">*/}
                                                        {/*            <tr>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Name</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Occupation</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Name Of Organization</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Designation</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Address</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Email Ids</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Telephone Nos</td>*/}
                                                        {/*                <td className=" custom-font fw-bold table-border">Mobile Nos</td>*/}
                                                        {/*            </tr>*/}
                                                        {/*            <tr>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.referenceName}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.occupation}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.organizationName}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.designation}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.address1}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.email}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.telePhoneNo}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*                <td className=" custom-font table-border">*/}
                                                        {/*                    {references.map(referenceDataNew => <div>{referenceDataNew.mobileNo}</div>)}*/}
                                                        {/*                </td>*/}
                                                        {/*            </tr>*/}

                                                        {/*        </table>*/}
                                                        {/*    </td>*/}
                                                        {/*</tr>*/}
                                                    </table>
                                                    <div className="pagebreak table-border">
                                                        <table>
                                                            <tr>
                                                                <td className=" width-100 custom-font fw-bold">
                                                                    General Background
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" className="width-100">
                                                                    <table className="width-100  table-border border-collapse">
                                                                        <tr>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                Declaration
                                                                            </td>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                Yes
                                                                            </td>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                No
                                                                            </td>
                                                                        </tr>
                                                                        {/* <tr>
                                      <td className=" custom-font table-border">
                                        <table>
                                          <tr>
                                            <td className="custom-font">
                                              Mention any two interest and
                                              proficiency achieved in them
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="custom-font">
                                              Comments : {interest}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td className=" custom-font table-border">
                                        {interestYes}
                                      </td>
                                      <td className=" custom-font table-border">
                                        {interestNo}
                                      </td>
                                    </tr> */}
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Are/Were you personally connected
                                                                                            to any business in present/past?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {personalBussiness}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {personalBussinessYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {personalBussinessNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Do you have any family members /
                                                                                            relatives who are or were working
                                                                                            as a Director / Permanent employee
                                                                                            / contractual employee at Siemens?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {contacts}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {contactsYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {contactsNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Have you suffered from any major
                                                                                            illness or Surgery?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {illness}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {illnessYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {illnessNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Are you Physically Handicapped?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {physical}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {physicalYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {physicalNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Have you worked with Siemens group
                                                                                            of Companies in the past as a
                                                                                            permanent employee / contract
                                                                                            staff / on deputation?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {siemensPast}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {siemensPastYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {siemensPastNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Have you been interviewed before
                                                                                            in Siemens?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {siemensPastInterview}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {siemensPastInterviewYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {siemensPastInterviewNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Are you connected with any Social
                                                                                            / cultural / professional
                                                                                            association?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {association}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {associationYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {associationNo}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Would you like to be considered for
                                                                                            employment in any positions at
                                                                                            Siemens?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {position}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Expected Salary (p.a.) :{" "}
                                                                                            {expectedSalary}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Can Join Date : {canJoinDate}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {positionYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {positionNo}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colspan="2"
                                                                    className="width-100 custom-font fw-bold"
                                                                >
                                                                    Declarations
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan=" 2" className="width-100">
                                                                    <table className="width-100 table-border border-collapse">
                                                                        <tr>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                Declaration
                                                                            </td>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                Yes
                                                                            </td>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                No
                                                                            </td>
                                                                            <td className=" custom-font fw-bold table-border">
                                                                                Not Applicable
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Do you have a non-compete clause in
                                                                                            contract with your current or
                                                                                            former employer that has not yet
                                                                                            expired that could impact your
                                                                                            ability to work at Siemens in the
                                                                                            position for which you have
                                                                                            applied?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {contract}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {contractYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {contractNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {contractNA}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Do you have a confidentiality
                                                                                            obligation with your current or
                                                                                            former employer that could impact
                                                                                            your ability to work at Siemens in
                                                                                            the position for which you have
                                                                                            applied?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {confidentially}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {confidentiallyYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {confidentiallyNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {confidentiallyNA}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Have you ever been arrested /
                                                                                            prosecuted / detained / convicted
                                                                                            / fined?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {arrested}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {arrestedYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {arrestedNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {arrestedNA}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Are you currently involved in any
                                                                                            past or ongoing litigations
                                                                                            related to fraud, bribery, money
                                                                                            laundering, or any other criminal
                                                                                            litigations or proceedings by any
                                                                                            Court of Law or any Government
                                                                                            Authority?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {litigation}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {litigationYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {litigationNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {litigationNA}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Do you or any of your family
                                                                                            members or close relatives had /
                                                                                            have any relationship, connection
                                                                                            or link to any politician or
                                                                                            political party or government
                                                                                            official?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments :{" "}
                                                                                            {closeRelativesPolitical}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {closeRelativesPoliticalYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {closeRelativesPoliticalNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {closeRelativesPoliticalNA}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Are you or any of your family
                                                                                            members or close relatives
                                                                                            currently holding direct /
                                                                                            indirect stake or a key
                                                                                            decision-making position in any
                                                                                            organization which is currently
                                                                                            engaged in performing business
                                                                                            with Siemens (e.g., Suppliers /
                                                                                            Customers / Business Partners) or
                                                                                            is in competition with Siemens
                                                                                            business?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {closeRelatives}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {closeRelativesYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {closeRelativesNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {closeRelativesNA}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className=" custom-font table-border">
                                                                                <table>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Do you have a contractual
                                                                                            obligation with your current or
                                                                                            former employer that could impact
                                                                                            your ability to work at Siemens in
                                                                                            the position for which you have
                                                                                            applied?
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="custom-font">
                                                                                            Comments : {contractualObligation}
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                            <td className=" custom-font table-border border-width">
                                                                                {contractualObligationYes}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {contractualObligationNo}
                                                                            </td>
                                                                            <td className=" custom-font table-border">
                                                                                {contractualObligationNA}
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" className="width-100">
                                                                    <table className="width-100 table-border border-collapse">
                                                                        <tr height="25px">
                                                                            <td className="custom-font width-50"></td>
                                                                            <td className="custom-font width-50"></td>
                                                                        </tr>
                                                                        <tr height="25px">
                                                                            <td className="custom-font width-50">
                                                                                Date :
                                                                                ________________________________________________________________________
                                                                            </td>
                                                                            <td className="custom-font width-50"></td>
                                                                        </tr>
                                                                        <tr height="25px">
                                                                            <td className="custom-font width-50"></td>
                                                                            <td className="custom-font width-50"></td>
                                                                        </tr>
                                                                        <tr height="25px">
                                                                            <td className="custom-font width-50">
                                                                                Place :
                                                                                ________________________________________________________________________
                                                                            </td>
                                                                            <td className="custom-font" width="50%">
                                                                                Signature :
                                                                                ________________________________________________________________________
                                                                            </td>
                                                                        </tr>
                                                                        <tr height="50px"><td colspan="2" class="custom-font">Note : Please note that if the answer to any of the above questions is "Yes" then a P&O representative shall contact you for further details.</td></tr>

                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>

                {/* Onboarding Form */}

                <div className="pagebreak">
                    <table id="tblMain2" className="tablemain width-100 mt-2 mb-1">
                        <tr>
                            <td>
                                <div id="divPrint">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="d-flex">
                                                    <img
                                                        src={logoAREWise}
                                                        alt="Siemens Company Logo"
                                                        height="30"
                                                        width="100"
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tr>
                                            <td>
                                                <table className="d-flex jc-center">
                                                    <tr>
                                                        <td className="tablename">
                                                            <strong>Onboarding Form</strong>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table id="tblPost" className="table-border width-100">
                                                    <tr>
                                                        <td colspan="2" className="width-100">
                                                            <table className="table-border width-100">
                                                                <tr>
                                                                    <td
                                                                        className="custom-font fw-bold width-100"
                                                                        colspan="2"
                                                                    >
                                                                        Personal Data
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="custom-font width-25">
                                                                        Title
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {title}
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        Middle name
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {middleName}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="custom-font width-25">
                                                                        First Name
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {firstName}
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        Last Name
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {lastName}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="custom-font fw-bold width-100">
                                                            Medical Data
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" className="width-100">
                                                            <table className="table-border width-100">
                                                                <tr>
                                                                    <td className="custom-font width-25">
                                                                        Date Of Examination
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {dateOfExamination}
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        Blood Group
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {bloodGroup}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    {/* <td className="custom-font width-25">Rh Factor</td>
                                                                <td className="custom-font width-25">{rhFactor}</td> */}
                                                                    <td className="custom-font width-25">
                                                                        Are you disabled
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {disabilityYesOrNo}
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        Disability
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {disability}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="custom-font width-25">
                                                                        Disability Grade
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {disabilityGrade}
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        Disabled Since
                                                                    </td>
                                                                    <td className="custom-font width-25">
                                                                        {disabledSince}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                            <td className="custom-font fw-bold width-100">
                              Family Data
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" className="width-100">
                              <table className="table-border border-collapse  width-100">
                                <tr>
                                  <td className="table-border fw-bold custom-font">
                                    First Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Middle Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Last Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Date Of Birth
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Gender
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Relationship
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Occupation
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>{familyDataNew.familyFirstName}</div>
                                    ))}
                                  </td>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>
                                        {familyDataNew.familyMiddleName}
                                      </div>
                                    ))}
                                  </td>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>{familyDataNew.familyLastName}</div>
                                    ))}
                                  </td>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>
                                        {familyDataNew.familyDateOfBirth}
                                      </div>
                                    ))}
                                  </td>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>{familyDataNew.gender}</div>
                                    ))}
                                  </td>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>{familyDataNew.relationship}</div>
                                    ))}
                                  </td>
                                  <td className="table-border custom-font">
                                    {familyData.map((familyDataNew) => (
                                      <div>{familyDataNew.occupation}</div>
                                    ))}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr> */}

                                                    {/*<tr>*/}
                                                    {/*    <td className="custom-font fw-bold width-100">*/}
                                                    {/*        Provident Fund Nomination*/}
                                                    {/*    </td>*/}
                                                    {/*</tr>*/}
                                                    {/*<tr>*/}
                                                    {/*    <td colspan="2" className="width-100">*/}
                                                    {/*        <table className="table-border border-collapse  width-100">*/}
                                                    {/*            <tr>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    {" "}*/}
                                                    {/*                    First Name*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Middle Name*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Last Name*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Date Of Birth*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Relationship*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Share In Percentage*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Address*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Guardian’s Name*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Guardian’s Address*/}
                                                    {/*                </td>*/}
                                                    {/*                <td className="table-border fw-bold custom-font">*/}
                                                    {/*                    Validation of Minor*/}
                                                    {/*                </td>*/}
                                                    {/*            </tr>*/}
                                                    {/*            {providentFund.map((item, index) => (*/}
                                                    {/*                <tr>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.firstNameNominee}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.middleNameNominee}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.lastNameNominee}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.dob}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.nomineeRelationWithMember}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.nomineeShare}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.nomineeAddress}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.guardianName}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.guardianAddress}*/}
                                                    {/*                    </td>*/}
                                                    {/*                    <td className="table-border custom-font">*/}
                                                    {/*                        {item.validationOfMinorYesOrNo}*/}
                                                    {/*                    </td>*/}
                                                    {/*                </tr>*/}
                                                    {/*            ))}*/}
                                                    {/*        </table>*/}
                                                    {/*    </td>*/}
                                                    {/*</tr>*/}
                          {/*                          <tr>*/}
                          {/*                              <td className="custom-font fw-bold width-100">*/}
                          {/*                                  Pension Scheme Nomination*/}
                          {/*                              </td>*/}
                          {/*                          </tr>*/}
                          {/*                          <tr>*/}
                          {/*                              <td colspan="2" className="width-100">*/}
                          {/*                                  <table className="table-border border-collapse  width-100">*/}
                          {/*                                      <tr>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              {" "}*/}
                          {/*                                              First Name*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Middle Name*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Last Name*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Date Of Birth*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Relationship*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Address*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Guardian’s Name*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Guardian’s Address*/}
                          {/*                                          </td>*/}
                          {/*                                          <td className="table-border fw-bold custom-font">*/}
                          {/*                                              Validation of Minor*/}
                          {/*                                          </td>*/}
                          {/*                                      </tr>*/}

                          {/*      {pensionScheme.map((item, index) => (*/}
                          {/*        <tr>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.firstNameNominee}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.middleNameNominee}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.lastNameNominee}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.dob}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.relationWithMember}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.nomineeAddress}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.gaurdianName}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.gaurdianAddress}*/}
                          {/*          </td>*/}
                          {/*          <td className="table-border custom-font">*/}
                          {/*            {item.validationOfMinorYesOrNo}*/}
                          {/*          </td>*/}
                          {/*        </tr>*/}
                          {/*      ))}*/}
                          {/*    </table>*/}
                          {/*  </td>*/}
                          {/*</tr>*/}
                          <tr>
                            <td className="custom-font fw-bold width-100">
                              Gratutity Nomination
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" className="width-100">
                              <table className="table-border border-collapse  width-100">
                                <tr>
                                  <td className="table-border fw-bold custom-font">
                                    {" "}
                                    First Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Middle Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Last Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Date Of Birth
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Relationship
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Share In Percentage
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Validation of Minor
                                  </td>
                                </tr>
                                {gratuity.map((item, index) => (
                                  <tr>
                                    <td className="table-border custom-font">
                                      {item.firstNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.middleNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.lastNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.dob}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.relationWithEmployee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.proportionShared}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.address}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.gaurdianName}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.gaurdianAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.validationOfMinorYesOrNo}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td className="custom-font fw-bold width-100">
                              Superannuation Nomination
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" className="width-100">
                              <table className="table-border border-collapse  width-100">
                                <tr>
                                  <td className="table-border fw-bold custom-font">
                                    {" "}
                                    First Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Middle Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Last Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Date Of Birth
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Relationship
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Share In Percentage
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Validation of Minor
                                  </td>
                                </tr>
                                {superAnnuation.map((item, index) => (
                                  <tr>
                                    <td className="table-border custom-font">
                                      {item.firstNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.middleNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.lastNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.dob}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.relationWithEmployee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.sharePercentage}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.nomineeAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.gaurdianName}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.gaurdianAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.validationOfMinorYesOrNo}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td className="custom-font fw-bold width-100">
                              Group Term Life Insurance Nomination
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" className="width-100">
                              <table className="table-border border-collapse  width-100">
                                <tr>
                                  <td className="table-border fw-bold custom-font">
                                    {" "}
                                    First Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Middle Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Last Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Date Of Birth
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Relationship
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Share In Percentage
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Validation of Minor
                                  </td>
                                </tr>
                                {lifeInsurance.map((item, index) => (
                                  <tr>
                                    <td className="table-border custom-font">
                                      {item.firstNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.middleNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.lastNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.dob}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.relationWithEmployee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.sharePercentage}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.nomineeAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.guardianName}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.guardianAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.validationOfMinorYesOrNo}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td className="custom-font fw-bold width-100">
                              Group Personal Accident Nomination
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" className="width-100">
                              <table className="table-border border-collapse  width-100">
                                <tr>
                                  <td className="table-border fw-bold custom-font">
                                    {" "}
                                    First Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Middle Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Last Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Date Of Birth
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Relationship
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Share In Percentage
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Name
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Guardian’s Address
                                  </td>
                                  <td className="table-border fw-bold custom-font">
                                    Validation of Minor
                                  </td>
                                </tr>
                                {personalAccident.map((item, index) => (
                                  <tr>
                                    <td className="table-border custom-font">
                                      {item.firstNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.middleNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.lastNameNominee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.dob}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.relationWithEmployee}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.sharePercentage}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.nomineeAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.guardianName}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.guardianAddress}
                                    </td>
                                    <td className="table-border custom-font">
                                      {item.validationOfMinorYesOrNo}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td className="custom-font fw-bold width-100">
                              Data Privacy Note
                            </td>
                          </tr>
                          {/*<tr>*/}
                          {/*  <td colspan="2" className="width-100">*/}
                          {/*    <table className="table-border border-collapse  width-100">*/}
                          {/*      <tr>*/}
                          {/*        <td className=" table-border fw-bold custom-font">*/}
                          {/*          */}{/*{taxRegime}*/}
                          {/*        </td>*/}
                          {/*      </tr>*/}
                          {/*    </table>*/}
                          {/*  </td>*/}
                          {/*</tr>*/}
                          <tr>
                            <td colspan="2" className="width-100">
                              <table className="width-100">
                                <tr>
                                  <td>
                                    <table className="width-100">
                                      <tr>
                                        <td className="custom-font borderWidth">
                                                                                    {declarationCheck ? <img
                                                                                        src={
                                                                                            "../assets/images/checked-checkbox.png"
                                                                                        }
                                                                                        height="15"
                                                                                        width="15"
                                                                                        alt="Checkbox"
                                                                                    /> : <img
                                                                                        src={
                                                                                            "../assets/images/unchecked-checkbox.png"
                                                                                        }
                                                                                        height="15"
                                                                                        width="15"
                                                                                        alt="Checkbox"
                                                                                    />}
                                        {/*<img*/}
                                        {/*    src={*/}
                                        {/*      "../assets/images/unchecked-checkbox.png"*/}
                                        {/*    }*/}
                                        {/*    height="15"*/}
                                        {/*    width="15"*/}
                                        {/*    alt="Checkbox"*/}
                                        {/*  />*/}
                                        </td>
                                        {/* <td className="custom-font">
                                          */}{/*I further certify that while at*/}{/*
                                          */}{/*Siemens, I will not use any trade*/}{/*
                                          */}{/*secret or confidential information. I*/}{/*
                                          */}{/*have received through any prior*/}{/*
                                          */}{/*employment.(if you have answered 'Yes'*/}{/*
                                          */}{/*to any of these questions, please*/}{/*
                                          */}{/*contact HR representative and provide*/}{/*
                                          */}{/*complete copies thereof.)*/}{/*
                                        </td> */}
                                        {/*<td>*/}
                                        {/*  I,{firstName} {lastName} confirm that*/}
                                        {/*  I have opted for the{" "}*/}
                                        {/*  {taxRegime === "New" ? (*/}
                                        {/*    <>New Personal Tax Regime (NPTR)</>*/}
                                        {/*  ) : (*/}
                                        {/*    <>OLD Personal Tax Regime (OPTR)</>*/}
                                        {/*  )}{" "}*/}
                                        {/*  as maintained in my previous*/}
                                        {/*  employment in this Financial Year and*/}
                                        {/*  hereby authorize Siemens to calculate*/}
                                        {/*  my tax liability as per the provisions*/}
                                        {/*  and guidelines of the opted Tax regime*/}
                                        {/*  for this Financial Year.*/}
                                        {/*</td>*/}
                                        {/*<td> I have read and fully understood the*/}
                                        {/*  foregoing questions. I have answered*/}
                                        {/*  them to best of my belief and*/}
                                        {/*  knowledge, and my statements are*/}
                                        {/*  truthful and without evasion. Any*/}
                                        {/*  misrepresentation or omission of a*/}
                                        {/*  fact in my application may be*/}
                                        {/*  justification for Siemens refusal to*/}
                                        {/*  hire me, or, if I am employed, for*/}
                                        {/*  termination of my employment by*/}
                                        {/*  Siemens.</td>*/}
                                        <td>
                                          I hereby declare & acknowledge that
                                          the information & documents provided
                                          to Siemens are true, valid & genuine.
                                          I further declare that should there be
                                          any change in the said information or
                                          documents I shall duly inform Siemens
                                          & shall share the updated information
                                          & documents.
                                        </td>
                                      </tr>
                                      <tr>
                                        {/* <td colspan="2" className="custom-font">
                                          */}{/*I have read and fully understood the*/}{/*
                                          */}{/*foregoing questions. I have answered*/}{/*
                                          */}{/*them to best of my belief and*/}{/*
                                          */}{/*knowledge, and my statements are*/}{/*
                                          */}{/*truthful and without evasion. Any*/}{/*
                                          */}{/*misrepresentation or omission of a*/}{/*
                                          */}{/*fact in my application may be*/}{/*
                                          */}{/*justification for Siemens refusal to*/}{/*
                                          */}{/*hire me, or, if I am employed, for*/}{/*
                                          */}{/*termination of my employment by*/}{/*
                                          */}{/*Siemens.*/}{/*
                                        </td> */}
                                        </tr>
                                                                            <tr>
                                                                                {/* <td colspan="2" className="custom-font">
                                          */}{/*Siemens will conduct a background*/}{/*
                                          */}{/*check / investigation to verify all*/}{/*
                                          */}{/*data given in my application for*/}{/*
                                          */}{/*employment, resume, related papers and*/}{/*
                                          */}{/*oral interviews. I understand that*/}{/*
                                          */}{/*misrepresentations or omissions of*/}{/*
                                          */}{/*facts discovered as a result of this*/}{/*
                                          */}{/*investigation may prevent my being*/}{/*
                                          */}{/*hired, or if hired, may subject me to*/}{/*
                                          */}{/*termination. I understand and agree to*/}{/*
                                          */}{/*give my consent and comply with the*/}{/*
                                          */}{/*process for the background*/}{/*
                                          */}{/*investigation by a public or private*/}{/*
                                          */}{/*agency.*/}{/*
                                        </td> */}
                                                                                <td colspan="2" className="custom-font">
                                                                                    I further declare & certify that I am
                                                                                    not in possession of any trade secrets
                                                                                    & confidential & sensitive information
                                                                                    & documents pertaining to any of my
                                                                                    previous employers AND/OR I shall not use
                                                                                    any trade secrets & confidential &
                                                                                    sensitive information & documents
                                                                                    pertaining to any of my previous
                                                                                    employers while working for or
                                                                                    representing Siemens.
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="2" className="custom-font">
                                                                                  I shall bear
                                                                                    full responsibility & liability in
                                                                                    case of any violations of any laws,
                                                                                    statutes, rules & regulations and / or
                                                                                    circulars, policies & guidelines of my
                                                                                    previous employer, in this regard.
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                {/* <td colspan="2" className="custom-font">
                                          */}{/*As part of my selection process, I*/}{/*
                                          */}{/*hereby agree to undertake psychometric*/}{/*
                                          */}{/*assessments administered to me by the*/}{/*
                                          */}{/*company.*/}{/*
                                        </td> */}
                                                                                <td colspan="2" className="custom-font">
                                                                                    I hereby give my free, specific, informed, unconditional and unambiguous consent to Siemens Limited
                                                                                    or Siemens AG or any of its affiliates
                                                                                    and subsidiaries(“Siemens”)or any
                                                                                    third party engaged by Siemens for
                                                                                    processing of my personal data &
                                                                                    information (including Special
                                                                                    Category Data & Sensitive Personal
                                                                                    Data & Information) and /or
                                                                                    non-personal data, as appliable
                                                                                    (“data”)as defined under the
                                                                                    applicable Data Privacy & Data
                                                                                    Protection laws, statutes, rules &
                                                                                    regulations including GDPR and Indian Digital Personal Data Protection Act, 2023  for
                                                                                    employment purposes & any other
                                                                                    related & incidental matters.
                                                                                    Employment purpose includes but is not
                                                                                    limited to interview & on-boarding
                                                                                    process; retirement & nomination,
                                                                                    employee compensation & welfare,
                                                                                    conducting due diligence, background
                                                                                    checks & verification; recording of
                                                                                    attendance & access control within the
                                                                                    premises of Siemens' performance of
                                                                                    assigned roles, functions & jobs;
                                                                                    business contact with internal &
                                                                                    external stakeholders; emergency
                                                                                    contact; health & medical related
                                                                                    purposes; statutory & internal audits;
                                                                                    investigations; analysis, evaluation &
                                                                                    assessment of monitoring of
                                                                                    performance & behavior.
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                        {/*                                        */}{/* <td colspan="2" className="custom-font">*/}{/*
                                        */}{/*  I hereby provide my personal*/}{/*
                                        */}{/*  information to the organization and*/}{/*
                                        */}{/*  give consent to use this information*/}{/*
                                        */}{/*  for employment purpose only.*/}{/*
                                        */}{/*</td> */}
                                                                                <td colspan="2" className="custom-font">
                                                                                    <br />
                                                                                    Further, I am aware that for the
                                                                                    purposes mentioned above Siemens may
                                                                                    transfer or disclose my data to:
                                                                                    <ul>
                                                                                        <li>
                                                                                            Courts, law enforcement
                                                                                            authorities, regulators or
                                                                                            attorneys if necessary to comply
                                                                                            with applicable laws, statutes,
                                                                                            rules & regulations or for the
                                                                                            establishment, exercise or defense
                                                                                            of legal claims.
                                                                                        </li>
                                                                                        <br />
                                                                                        <li>
                                                                                            Service providers (so-called data
                                                                                            processors), e.g. hosting or IT
                                                                                            maintenance service providers,
                                                                                            cloud service providers, third
                                                                                            party software providers or simply
                                                                                            third-party service providers
                                                                                            which only act upon instructions
                                                                                            of Siemens and are contractually
                                                                                            bound to act in compliance with
                                                                                            applicable Data Privacy &
                                                                                            Protection laws, statutes, rules &
                                                                                            regulations including GDPR and India Digital Personal Data Protection Act, 2023.
                                                                                        </li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                {/* <td colspan="2" className="custom-font">
                                          */}{/*I,{firstName} {lastName} confirm that*/}{/*
                                          */}{/*I have opted for the{" "}*/}{/*
                                          */}{/*{taxRegime === "New" ? (*/}{/*
                                          */}{/*  <>New Personal Tax Regime (NPTR)</>*/}{/*
                                          */}{/*) : (*/}{/*
                                          */}{/*  <>Old Personal Tax Regime (OPTR)</>*/}{/*
                                          */}{/*)}{" "}*/}{/*
                                          */}{/*and hereby authorize Siemens to*/}{/*
                                          */}{/*calculate my tax liability as per the*/}{/*
                                          */}{/*provisions/guidelines of the opted Tax*/}{/*
                                          */}{/*regime*/}{/*
                                        </td> */}
                                                                                <td colspan="2" className="custom-font">
                                                                                    Further, I am aware that unless
                                                                                    indicated otherwise at the time of
                                                                                    collection of my personal data &
                                                                                    information (including Special
                                                                                    Category Data & Sensitive Personal
                                                                                    Data & Information), Siemens may
                                                                                    process my data & information
                                                                                    (including Special Category Data &
                                                                                    Sensitive Personal Data & Information)
                                                                                    in physical, manual, soft or digital
                                                                                    form & media as deemed fit &
                                                                                    necessary.
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" className="width-100">
                                                            <table className="table-border border-collapse width-100">
                                                                <tr className=" h-25">
                                                                    <td className="custom-font width-50"></td>
                                                                    <td className="custom-font width-50"></td>
                                                                </tr>
                                                                <tr className="h-25">
                                                                    <td className="custom-font width-50">
                                                                        Date :
                                                                        ________________________________________________________________________
                                                                    </td>
                                                                    <td className="custom-font width-50"></td>
                                                                </tr>
                                                                <tr className="h-25">
                                                                    <td className="custom-font width-50"></td>
                                                                    <td className="custom-font width-50"></td>
                                                                </tr>
                                                                <tr className="h-50">
                                                                    <td className="custom-font width-50">
                                                                        Place :
                                                                        ________________________________________________________________________
                                                                    </td>
                                                                    <td className="custom-font width-50">
                                                                        Signature :
                                                                        ________________________________________________________________________
                                                                    </td>
                                                                </tr>
                                                                <tr className="h-50">
                                                                    <td className="custom-font width-50"></td>
                                                                    <td className="custom-font width-50"></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                {/* HR DEtails */}

                {props.roleId && props.roleId != 3 ? (
                    <div className="pagebreak no-print">
                        <table id="tblMain2" className="tablemain width-100 mt-2 mb-1">
                            <tr>
                                <td>
                                    <div id="divPrint">
                                        <table id="tblMain" className="tablemain width-100">
                                            <thead>
                                                <tr>
                                                    <th className="d-flex">
                                                        <img
                                                            src={logoAREWise}
                                                            alt="Siemens Company Logo"
                                                            height="30"
                                                            width="100"
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tr>
                                                <td>
                                                    <table className="d-flex jc-center">
                                                        <tr>
                                                            <td className="tablename">
                                                                <strong>HR Details</strong>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table
                                                        id="tblPost"
                                                        className="table-border width-100"
                                                    >
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Job Details
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Supervisory Organization
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {supervisoryOrganization}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Hire Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {hireDate}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Hire Reason
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {hireReason}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Employee Type
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {employeeType}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Job Profile
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {jobProfile}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Job Title
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {jobTitle}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Business Title
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {businessTitle}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Location Code
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {locationCode}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Time Type
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {timeType}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Default Weekly Hours
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {defaultWeeklyHours}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Scheduled Weekly Hours
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {scheduledWeeklyHours}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Grip Level
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {gripLevel}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Management Structure
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {managementStructure}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {" "}
                                                                            Staff Specification
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {staffSpecification}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Change Org Assignment
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Company
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {company}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Cost Center
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {costCenter}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            In Company Manager
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {inCompanyManager}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Manage Employee Contract
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Effective Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {effectiveDate}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Contract Type
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {contractType}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Contract Start Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {contractStartDate}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Contract End date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {contractEndDate}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Contract Status
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {contractStatus}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Contract Reason
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {contractReason}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Edit Services Date
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Continuous Service Dates
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {continuousServiceDates}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Company Service Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {companyServiceDate}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Seniority Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {seniorityDate}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Assign Collective Agreement
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Collective Agreement Group
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {collectiveAgreementGroup}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Collective Agreement
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {collectiveAgreement}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" className="width-100">
                                                                <table className="width-100 table-border">
                                                                    <tr>
                                                                        <td
                                                                            className="custom-font fw-bold width-100"
                                                                            colspan=" 2"
                                                                        >
                                                                            Manage Probation Period
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Probation Type
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {probationType}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Probation Reason
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {probationReason}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Probation Start Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {probationStartDate}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Probation End Date
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {probationEndDate}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="width-25 custom-font">
                                                                            Probation Unit
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {probationUnit}
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            Probation Duration
                                                                        </td>
                                                                        <td className="width-25 custom-font">
                                                                            {probationDuration}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                ) : (
                    <React.Fragment></React.Fragment>
                )}

                <div id="donotprintdiv" className="d-flex jc-center mt-1 mb-2 no-print">
                    <button onClick={() => window.print()}>Print</button>
                </div>
                {isLoader ? <Loader /> : <></>}
            </div>
        </ThemeProvider>
    );
}
