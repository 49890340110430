import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { id } from "date-fns/esm/locale";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isValid } from "date-fns/esm";
import Loader from "../../components/Loader";

import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        minHeight: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    //MuiAutocomplete: {
    //    root: {
    //        background: "#E8F8F8",
    //        '&$disabled': {
    //            backgroundColor: '#454545 !important',
    //        },
    //        padding: "0 !important",
    //        height: "55px !important",
    //    },
    //},
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        background: "#00CCCC",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "8px 24px",
      },
    },
    //MuiTextField: {
    //    root: {
    //        color: "#002949",
    //        background: "#000028",
    //        width: '100%'
    //    },
    //},
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        background: "#00CCCC",
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
      asterisk: {
        color: "red",
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        borderBottom: "0px solid #707070",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "0px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #FFFFFF",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:hover:after": {
          borderBottom: "2px solid #FFFFFF",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #FFFFFF",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "20px",
        marginBottom: "20px",
        background: "#CCF5F5",
      },
    },

    MuiToolbar: {
      root: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#00CCCC",
        "&$focused": {
          color: "#00CCCC",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF !important",
      },
      body: {
        background: "#000028",
        color: "#FFFFFF",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#6a7272",
      },
    },
    //MuiPickersBasePicker: {
    //    pickerView: {
    //        background: "#000028"
    //    },
    //    background: "#000028"
    //},
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPickersBasePicker: {
      container: {
        background: "#000028",
      },
      pickerView: {
        background: "#000028",
      },
      background: "#000028",
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#000028",
      },
    },
    MuiFormHelperText: {
      contained: {
        backgroundColor: "white",
        marginBottom: "-10px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "0.5 !important",
      },
    },
  },
});

export default function UserRegistration(props) {
   
    function getURLParameter(sParam) {
      
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      //var sParameterName = sURLVariables[i].split("=");
        var sParameterName = sURLVariables[i].split("id=");
        var value = sParameterName[1];
      //for (var j = 0; j < sParameterName.length; j++) {
      //  if (sParameterName[j] == sParam) {
      //    for (var k = j + 1; k < sParameterName.length; k++) {
      //      value = value + sParameterName[k];
      //    }
      //    break;
      //  }
      //  //return sParameterName[1];
      //  }

        if (!value) {

            continue;

        }

        else {

            break;

        }
      
      }
      return value;
    }
   
    var encryptedId = getURLParameter("id");
  var id, data;
  const [buttonTextSubmit, setButtonTextSubmit] = useState("Register");
  const [formHeaderText, setFormHeaderText] = useState("User Registration");
  const [userIdencrypted, setUserIdencrypted] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhonNumber] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [role, setRole] = useState("");
  const [roleObj, setRoleObj] = useState("");
  const [roleText, setRoleText] = useState("");
  const [opCoPo, setOpCoPo] = useState("");
  const [opCoPoObj, setOpCoPoObj] = useState("");
  const [opCoPoText, setOpCoPoText] = useState("");
  const [businessUnit, setBusinessUnit] = useState("");
  const [businessUnitObj, setBusinessUnitObj] = useState("");
  const [businessUnitText, setBusinessUnitText] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(null);
  const [are, setARE] = useState("");
  //const [areObj, setAREObj] = useState("");
  const [areObj, setAREObj] = useState([]);
  const [areText, setAREText] = useState("");
  const [isARERequired, setIsARERequired] = useState(false);
  const [areDisable, setAREDisable] = useState(true);

  //Error States and Msg
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorTextFirstName, setErrorTextFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorTextLastName, setErrorTextLastName] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorTextPhoneNumber, setErrorTextPhoneNumber] = useState("");
  const [errorRollNumber, setErrorRollNumber] = useState(false);
  const [errorTextRollNumber, setErrorTextRollNumber] = useState("");
  const [errorRole, setErrorRole] = useState(false);
  const [errorTextRole, setErrorTextRole] = useState("");
  const [errorOpCo, setErrorOpCo] = useState(false);
  const [errorTextOpCo, setErrorTextOpCo] = useState("");
  const [errorBusinessUnit, setErrorBusinessUnit] = useState(false);
  const [errorTextBusinessUnit, setErrorTextBusinessUnit] = useState("");
  const [errorARE, setErrorARE] = useState(false);
  const [errorTextARE, setErrorTextARE] = useState("");

  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState("");

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

    function SetStateAsPerFetched(data) {
     
    setUserIdencrypted(data.userIdencrypted);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.userEmail);
    setPhonNumber(data.phoneNo);
    setRollNumber(data.rollNo);
    setRole(data.roleId);
    if (data.roleObj[0].id > 0) {
      setRoleObj(data.roleObj[0]);
      setRoleText(data.roleObj[0].name);
      if (data.roleObj[0].id == 2) {
        setIsARERequired(true);
        setAREDisable(false);
      } else {
        setIsARERequired(false);
        setAREDisable(true);
      }
    }

    if (data.companyAREObj && data.companyAREObj.length > 0) {
      for (var i = 0; i < data.companyAREObj.length; i++) {
        let objFind = areObj.find((x) => x.id == data.companyAREObj[i].id);
        if (objFind) {
          objFind.id = data.companyAREObj[i].id;
          objFind.name = data.companyAREObj[i].name;

          setAREObj([...areObj]);
        } else {
          areObj.push({
            id: data.companyAREObj[i].id,
            name: data.companyAREObj[i].name,
          });
          setAREObj([...areObj]);
        }
      }
    }

    //setARE(data.companyId);
    //if (data.companyObj[0].id > 0) {
    //  setAREObj(data.companyObj[0]);
    //  setAREText(data.companyObj[0].name);
    //}
    //setOpCoPo(data.divisionId);
    //if (data.divisionObj[0].id > 0) {
    //    setOpCoPoObj(data.divisionObj[0]);
    //    setOpCoPoText(data.divisionObj[0].name);
    //}
    //getBusinessUnits(data.divisionId);
    //setBusinessUnit(data.businessUnitId);
    //if (data.businessUnitObj[0].id > 0) {
    //    setBusinessUnitObj(data.businessUnitObj[0]);
    //    setBusinessUnitText(data.businessUnitObj[0].name);
    //}
    setButtonTextSubmit("Update");
    setFormHeaderText("Update User");
    setIsLoader(false);
  }
  function IsValidEmail(value) {
    const exp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return exp.test(String(value).toLowerCase());
  }
  function IsValidMobileNo(value) {
    const exp = /^\d{10}$/;
    return exp.test(value);
  }
  function ReplaceChars(value) {
    return value.replace(/\D/g, "");
  }
    async function GetUserDetailsById(id) {
   // id = encryptedId.substr(22);

     // id = encodeURIComponent(encryptedId);
      //id.replace()
      const token = props.token;
      await fetch("Employee/GetUserDetails/" + id, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => response.json())
      .then((data) => {
        SetStateAsPerFetched(data);
      })
      .catch((error) => console.log("Error : " + error));
  }

  const changeEventFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const changeEventLastName = (event) => {
    setLastName(event.target.value);
  };
  const changeEventEmail = (event) => {
    setEmail(event.target.value);
    if (event.target.value != "") {
      if (IsValidEmail(event.target.value)) {
        setEmailIsValid(true);
      } else {
        setEmailIsValid(false);
      }
    } else {
      setEmailIsValid(null);
    }
  };
  const changeEventPhoneNumber = (event) => {
    setPhonNumber(ReplaceChars(event.target.value));
  };
  const changeEventRollNumber = (event) => {
    setRollNumber(event.target.value);
  };
  const changeEventRole = (event, newValue) => {
    setRole(newValue ? newValue.id : 0);
    setRoleObj(newValue);
    if (newValue && newValue.id === 2) {
      setIsARERequired(true);
      setAREDisable(false);
    } else {
      setARE("");
      setAREObj([]);
      setAREText("");
      setIsARERequired(false);
      setAREDisable(true);
    }
  };
  const changeEventRoleInput = (event, newValueInput) => {
    setRoleText(newValueInput);
  };

  const changeEventARE = (event, newValue) => {
    setARE(newValue ? newValue.id : 0);
    setAREObj(newValue);
    setErrorARE(false);
    setErrorTextARE(false);
  };

  //const changeEventARE = (event, newValue) => {
  //  setARE(newValue ? newValue.id : null);
  //  setAREObj(newValue);
  //  setErrorARE(false);
  //  setErrorTextARE(false);
  //};
  const changeEventAREInput = (event, newValueInput) => {
    setAREText(newValueInput);
  };
  const changeEventOpCoPo = (event, newValue) => {
    setOpCoPo(newValue ? newValue.id : 0);
    setOpCoPoObj(newValue);
    id = newValue ? newValue.id : 0;
    getBusinessUnits(id);
    setBusinessUnit("");
    setBusinessUnitObj("");
    setBusinessUnitText("");
  };
  const changeEventOpCoPoInput = (event, newValueInput) => {
    setOpCoPoText(newValueInput);
    getBusinessUnits(id);
    setBusinessUnit("");
    setBusinessUnitObj("");
    setBusinessUnitText("");
  };
  const changeEventBU = (event, newValue) => {
    setBusinessUnit(newValue ? newValue.id : 0);
    setBusinessUnitObj(newValue);
  };
  const changeEventBUInput = (event, newValueInput) => {
    setBusinessUnitText(newValueInput);
  };

  const handleClose = () => {
    setOpen(false);
    if (openType === "Success") {
      window.location.href = "/AdminOrRecruiterData";
    }
  };

  const handleConfirm = () => {
    clearStates();
    setOpenConfirm(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const clickEventRegisterUser = () => {
    setIsLoader(true);
    const formData = {
      UserIdencrypted: userIdencrypted,
      FirstName: firstName,
      LastName: lastName,
      UserEmail: email,
      PhoneNo: phoneNumber,
      RollNo: rollNumber,
      RoleId: role,
      //CompanyId: are,
      CompanyAREObj: areObj,
      DivisionId: opCoPo,
        BusinessUnitId: businessUnit,
        LoggedInUserId: props.userEncryptedId,
    };
    if (ValidateFormFields(formData)) {
      var userData = JSON.stringify(formData);
      RegisterUser(userData);
    } else {
      setIsLoader(false);
      setOpen(true);
      setOpenAlert(
        "Form validation unsuccessful. Please check the error messages."
      );
      setClassName("bg-red clr-white");
      setOpenType("Alert");
    }
  };
  function ValidateFormFields(data) {
    var a = false,
      b = false,
      c = false,
      d = false,
      e = false,
      f = false,
      g = false,
      h = false,
      i = false;
    if (data.FirstName == null || data.FirstName == "") {
      setErrorFirstName(true);
      setErrorTextFirstName("Mandatory field");
      a = true;
    } else {
      setErrorFirstName(false);
      setErrorTextFirstName("");
      a = false;
    }
    if (data.LastName == null || data.LastName == "") {
      setErrorLastName(true);
      setErrorTextLastName("Mandatory field");
      b = true;
    } else {
      setErrorLastName(false);
      setErrorTextLastName("");
      b = false;
    }
    if (data.UserEmail == null || data.UserEmail == "") {
      setErrorEmail(true);
      setErrorTextEmail("Mandatory field");
      c = true;
    } else {
      if (!IsValidEmail(data.UserEmail)) {
        setErrorEmail(true);
        setErrorTextEmail("Email format invalid");
        c = true;
      } else {
        setErrorEmail(false);
        setErrorTextEmail("");
        c = false;
      }
    }
    if (data.PhoneNo == null || data.PhoneNo == "") {
      setErrorPhoneNumber(true);
      setErrorTextPhoneNumber("Mandatory field");
      d = true;
    } else {
      var arrNo = data.PhoneNo.split("");
      if (arrNo.length !== 10) {
        setErrorPhoneNumber(true);
        setErrorTextPhoneNumber("Phone number must be of 10 digits");
        d = true;
      } else {
        setErrorPhoneNumber(false);
        setErrorTextPhoneNumber("");
        d = false;
      }
    }
    if (data.RollNo == null || data.RollNo == "") {
      setErrorRollNumber(true);
      setErrorTextRollNumber("Mandatory field");
      e = true;
    } else {
      setErrorRollNumber(false);
      setErrorTextRollNumber("");
      e = false;
    }
    if (data.RoleId == null || data.RoleId == "") {
      setErrorRole(true);
      setErrorTextRole("Mandatory field");
      f = true;
    } else {
      setErrorRole(false);
      setErrorTextRole("");
      f = false;
    }
    //if (data.DivisionId == null || data.DivisionId == '') {
    //    setErrorOpCo(true);
    //    setErrorTextOpCo("Mandatory field");
    //    g = true;
    //}
    //else {
    //    setErrorOpCo(false);
    //    setErrorTextOpCo("");
    //    g = false;
    //}
    //if (data.BusinessUnitId == null || data.BusinessUnitId == '') {
    //    setErrorBusinessUnit(true);
    //    setErrorTextBusinessUnit("Mandatory field");
    //    h = true;
    //}
    //else {
    //    setErrorBusinessUnit(false);
    //    setErrorTextBusinessUnit("");
    //    h = false;
    //}
    if (data.RoleId && data.RoleId == 2) {
      //if (data.CompanyId == null || data.CompanyId == "") {
      if (data.CompanyAREObj == null || data.CompanyAREObj == "") {
        setErrorARE(true);
        setErrorTextARE("Mandatory field");
        i = true;
      } else {
        setErrorARE(false);
        setErrorTextARE("");
        i = false;
      }
    } else {
      setErrorARE(false);
      setErrorTextARE("");
      i = false;
    }

    if (a || b || c || d || e || f || g || h || i) {
      return false;
    } else {
      return true;
    }
  }
  function RegisterUser(userData) {
    if (buttonTextSubmit == "Register") {
      const token = props.token;
      const options = {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: userData,
      };
      fetch("Employee/RegisterUser", options)
        .then((response) => response.json())
        .then((data) => {
          if (data.response) {
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-light-green clr-white");
            setOpenType("Success");
            //alert("User has been registered successfully.");
            //window.location.href = '/AdminOrRecruiterData';
          } else {
              setIsLoader(false);
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-red clr-white");
            setOpenType("Alert");
            //alert("User registration failed.");
          }
        })
        .catch((error) => console.log("Error:", error));
    } else {
      const token = props.token;
      const options = {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: userData,
      };
      fetch("Employee/UpdateUserDetails", options)
        .then((response) => response.json())
        .then((data) => {
          if (data.response) {
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("bg-light-green clr-white");
            setOpenType("Success");
            //alert("User data has been updated successfully.");
            //window.location.href = '/AdminOrRecruiterData';
          } else {
            setOpen(true);
            setOpenAlert(data.responseMsg);
            setClassName("failed clr-white");
            setOpenType("Alert");
            //alert("User data update failed.");
          }
        })
        .catch((error) => console.log("Error:", error));
    }
  }

  function clearStates() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhonNumber("");
    setRollNumber("");
    setRole("");
    setRoleObj("");
    setRoleText("");
    setOpCoPo("");
    setOpCoPoObj("");
    setOpCoPoText("");
    setBusinessUnit("");
    setBusinessUnitObj("");
    setBusinessUnitText("");
    setARE("");
    //setAREObj("");
    setAREObj([]);
    setAREText("");
  }

  const clickEventClearData = () => {
    setOpenConfirm(true);
    setOpenAlertConfirm("Do you want to clear all the above fields?");
    setClassName("bg-light-green clr-white");
    setOpenType("Confirmation");
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: "100%",
      color: "#00CCCCC",
      background: "#FFFFFF",
    },
  }));
  const classes = useStyles();

  //fetch Divisions
  const [itemsDivision, setItemsDivision] = useState([]);
  async function getDivisions() {
    const token = props.token;
    const response = await fetch("Employee/GetAllDivisions", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsDivision(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsDivision(jsonItems);
  }
  //fetch Roles
  const [itemsRole, setItemsRole] = useState([]);
  async function getRoles() {
    const token = props.token;
    const response = await fetch("Employee/GetAllRolesForUserRegistration", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsRole(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsRole(jsonItems);
  }
  //fetch AREs
  const [itemsARE, setItemsARE] = useState([]);
  async function getAREs() {
    const token = props.token;
    const response = await fetch("Employee/GetAllAREsWithDesc/", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsBusinessUnit(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsARE(jsonItems);
  }
  //fetch BusinessUnits
  const [itemsBusinessUnit, setItemsBusinessUnit] = useState([]);
  async function getBusinessUnits(id) {
    const token = props.token;
    const response = await fetch("Employee/GetAllBusinessUnits/" + id, {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    //setItemsBusinessUnit(jsonItems.map(({ id, name }) => ({ label: name, value: id })));
    setItemsBusinessUnit(jsonItems);
  }
    useEffect(() => {
      
    setIsLoader(true);
    if (props.token) {
      /*getDivisions();*/
      getRoles();
      getAREs();
      /*getBusinessUnits(id);*/

      if (
        encryptedId != undefined &&
        encryptedId != "" &&
        encryptedId != null
      ) {
        GetUserDetailsById(encryptedId);
      } else {
        setIsLoader(false);
      }
    } else {
      setIsLoader(false);
    }
  }, [encryptedId, props.token]);

  return (
    <ThemeProvider theme={theme}>
      <div className="master-layout-block">
        <Box paddingTop={{ xs: "5rem", sm: "4rem" }}>
          <h3 className="clr-dark-blue d-flex jc-center">{formHeaderText}</h3>
        </Box>
        <form className="d-flex jc-center">
          <Grid
            container
            item
            xs={8}
            spacing={1}
            className="d-flex fd-column box-shadow px-2 bdr-radius "
          >
            <table>
              <tbody>
                <tr className="d-flex jc-space-bt f-12 font-italic mt-1">
                  <td className="clr-red"></td>
                  <td></td>
                  <td className="clr-red">
                    *Please enter SIEMENS email id in Email field (e.g.{" "}
                    <span className="fw-bold">youremail@siemens.com</span>)
                  </td>
                </tr>
              </tbody>
            </table>
            <Grid
              container
              spacing={2}
              className="d-flex jc-space-around fd-row"
            >
              <Grid item xs={12} sm={5} className="d-flex  Width-100">
                <TextField
                  className="mt-1 lightInputFields"
                  id="firstName"
                  label="First Name"
                  variant="filled"
                  fullWidth
                  name="First Name"
                  value={firstName}
                  onChange={changeEventFirstName}
                  required
                  error={errorFirstName}
                  helperText={errorTextFirstName}
                />
              </Grid>
              <Grid item xs={12} sm={5} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="lastName"
                  fullWidth
                  label="Last Name"
                  variant="filled"
                  name="Last Name"
                  value={lastName}
                  onChange={changeEventLastName}
                  required
                  error={errorLastName}
                  helperText={errorTextLastName}
                />
              </Grid>

              <Grid item xs={12} sm={5} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="email"
                  label="Email"
                  variant="filled"
                  placeholder="e.g. youremail@siemens.com"
                  fullWidth
                  name="Email"
                  value={email}
                  onChange={changeEventEmail}
                  required
                  error={errorEmail}
                  helperText={errorTextEmail}
                />
                {emailIsValid != null ? (
                  emailIsValid ? (
                    <React.Fragment>
                      <Tooltip title="Valid Format" placement="top">
                        <CheckCircleOutlineOutlinedIcon
                          style={{
                            fontSize: 20,
                            marginRight: "-20px",
                            marginTop: "2.5rem",
                            color: "#6cc070",
                          }}
                        />
                      </Tooltip>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Tooltip title="Invalid Email Format" placement="top">
                        <ErrorOutlineOutlinedIcon
                          style={{
                            fontSize: 20,
                            marginRight: "-20px",
                            marginTop: "2.5rem",
                            color: "red",
                          }}
                        />
                      </Tooltip>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid>

              <Grid item xs={12} sm={5} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="rollNumber"
                  label="GID"
                  variant="filled"
                  fullWidth
                  name="Roll Number"
                  value={rollNumber}
                  onChange={changeEventRollNumber}
                  required
                  error={errorRollNumber}
                  helperText={errorTextRollNumber}
                />
              </Grid>

              <Grid item xs={12} sm={5} className="d-flex Width-100">
                <TextField
                  className="lightInputFields mt-1"
                  id="phoneNumber"
                  label="Phone Number"
                  variant="filled"
                  fullWidth
                  name="Phone Number"
                  value={phoneNumber}
                  onChange={changeEventPhoneNumber}
                  inputProps={{ maxLength: 10 }}
                  required
                  error={errorPhoneNumber}
                  helperText={errorTextPhoneNumber}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                className="d-flex jc-center mt-1 Width-100"
              >
                {
                  //<FormControl variant="filled" className={classes.formControl}>
                  //    <InputLabel
                  //        id="demo-simple-select-label"
                  //        required
                  //        error={errorRole}
                  //    //helperText={errorTextFirstName}
                  //    >
                  //        Role
                  //    </InputLabel>
                  //    <Select className="lightInputFields"
                  //        labelId="demo-simple-select-label"
                  //        id="demo-simple-select"
                  //        value={role}
                  //        onChange={changeEventRole}
                  //    >
                  //        {itemsRole.map(item => (
                  //            <MenuItem
                  //                key={item.value}
                  //                value={item.value}
                  //            >
                  //                {item.label}
                  //            </MenuItem>)
                  //        )}
                  //    </Select>
                  //</FormControl>
                }
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="ddlAutoRole"
                  value={roleObj}
                  inputValue={roleText}
                  options={itemsRole}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={changeEventRole}
                  onInputChange={changeEventRoleInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Role"
                      variant="filled"
                      required
                      error={errorRole}
                      helperText={errorTextRole}
                      style={{color: "#f00"}}
                    />
                  )}
                />
              </Grid>
              {/*<Grid
                item
                xs={12}
                sm={10}
                className="d-flex jc-center mt-1 Width-100"
              >
                <Autocomplete
                  className="lightInputFields hover-lightblue"
                  id="combo-box-demo"
                  value={areObj}
                  inputValue={areText}
                  options={itemsARE}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={changeEventARE}
                  onInputChange={changeEventAREInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select ARE"
                      variant="filled"
                      required={isARERequired}
                      error={errorARE}
                      helperText={errorTextARE}
                    />
                  )}
                  disabled={areDisable}
                />
              </Grid> */}

              <Grid
                item
                xs={12}
                sm={10}
                className="d-flex jc-center mt-1 Width-100"
              >
                <Autocomplete
                
                  className="lightInputFields hover-lightblue"
                  multiple
                  value={areObj}
                  limitTags={1}
                  id="ddlMultiARE"
                  options={itemsARE}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name ? option.name : ""}
                    </React.Fragment>
                  )}
                  onChange={changeEventARE}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Select ARE"
                      required={isARERequired}
                      error={errorARE}
                      helperText={errorTextARE}
                    />
                  )}
                  disabled={areDisable}
                />
              </Grid>

              {/*<Grid item xs={12} sm={5} className="d-flex jc-center mt-1 Width-100">*/}
              {/*    {*/}
              {/*        //<FormControl variant="filled" className={classes.formControl}>*/}
              {/*        //    <InputLabel*/}
              {/*        //        id="demo-simple-select-label"*/}
              {/*        //        required*/}
              {/*        //        error={errorOpCo}*/}
              {/*        //    //helperText={errorTextFirstName}*/}
              {/*        //    >*/}
              {/*        //        Op Co/Po Co/St. Co/Ctry fn*/}
              {/*        //    </InputLabel>*/}
              {/*        //    <Select className="lightInputFields"*/}
              {/*        //        labelId="demo-simple-select-label"*/}
              {/*        //        id="demo-simple-select"*/}
              {/*        //        value={opCoPo}*/}
              {/*        //        onChange={changeEventOpCoPo}*/}
              {/*        //    >*/}
              {/*        //        {itemsDivision.map(item => (*/}
              {/*        //            <MenuItem*/}
              {/*        //                key={item.value}*/}
              {/*        //                value={item.value}*/}
              {/*        //            >*/}
              {/*        //                {item.label}*/}
              {/*        //            </MenuItem>)*/}
              {/*        //        )}*/}
              {/*        //    </Select>*/}
              {/*        //</FormControl>*/}
              {/*    }*/}
              {/*    <Autocomplete*/}
              {/*        className="lightInputFields hover-lightblue"*/}
              {/*        id="combo-box-demo"*/}
              {/*        value={opCoPoObj}*/}
              {/*        inputValue={opCoPoText}*/}
              {/*        options={itemsDivision}*/}
              {/*        getOptionLabel={option => option.name ? option.name : ""}*/}
              {/*        onChange={changeEventOpCoPo}*/}
              {/*        onInputChange={changeEventOpCoPoInput}*/}
              {/*        renderInput={*/}
              {/*            (params) =>*/}
              {/*                <TextField {...params}*/}
              {/*                    label="Op Co/Po Co/St. Co/Ctry fn"*/}
              {/*                    variant="filled"*/}
              {/*                    required*/}
              {/*                    error={errorOpCo}*/}
              {/*                    helperText={errorTextOpCo}*/}
              {/*                />*/}
              {/*        }*/}
              {/*    />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} sm={5} className="d-flex mt-1 jc-center Width-100">*/}
              {/*    {*/}
              {/*        //<FormControl variant="filled" className={classes.formControl}>*/}
              {/*        //    <InputLabel*/}
              {/*        //        id="demo-simple-select-filled-label"*/}
              {/*        //        required*/}
              {/*        //        error={errorBusinessUnit}*/}
              {/*        //    //helperText={errorTextFirstName}*/}
              {/*        //    >*/}
              {/*        //        Business Unit*/}
              {/*        //</InputLabel>*/}
              {/*        //    <Select*/}
              {/*        //        labelId="demo-simple-select-filled-label"*/}
              {/*        //        id="demo-simple-select-filled"*/}
              {/*        //        value={businessUnit}*/}
              {/*        //        onChange={changeEventBU}*/}
              {/*        //    >*/}
              {/*        //        {itemsBusinessUnit.map(item => (*/}
              {/*        //            <MenuItem*/}
              {/*        //                key={item.value}*/}
              {/*        //                value={item.value}*/}
              {/*        //            >*/}
              {/*        //                {item.label}*/}
              {/*        //            </MenuItem>)*/}
              {/*        //        )}*/}
              {/*        //    </Select>*/}
              {/*        //</FormControl>*/}
              {/*    }*/}
              {/*    <Autocomplete*/}
              {/*        className="lightInputFields hover-lightblue"*/}
              {/*        id="combo-box-demo"*/}
              {/*        value={businessUnitObj}*/}
              {/*        inputValue={businessUnitText}*/}
              {/*        options={itemsBusinessUnit}*/}
              {/*        getOptionLabel={option => option.name ? option.name : ""}*/}
              {/*        onChange={changeEventBU}*/}
              {/*        onInputChange={changeEventBUInput}*/}
              {/*        renderInput={*/}
              {/*            (params) =>*/}
              {/*                <TextField {...params}*/}
              {/*                    label="Business Unit"*/}
              {/*                    variant="filled"*/}
              {/*                    required*/}
              {/*                    error={errorBusinessUnit}*/}
              {/*                    helperText={errorTextBusinessUnit}*/}
              {/*                />*/}
              {/*        }*/}
              {/*    />*/}
              {/*</Grid>*/}
              <Grid item xs={12} className="d-flex mb-2 jc-center">
                <Button
                  className="ns-btn-primary mt-2"
                  onClick={clickEventClearData}
                >
                  <span>Clear</span>
                </Button>
                <Button
                  className="ns-btn-primary mt-2 ml-2"
                  onClick={clickEventRegisterUser}
                >
                  <span>{buttonTextSubmit}</span>
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadSuccess"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlert}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                  className="ns-btn-primary-light"
                >
                  <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openConfirm}
              onClose={handleCloseConfirm}
              aria-labelledby="draggable-dialog-title"
              id="templateUploadClear"
            >
              <DialogTitle
                className={openClassName}
                style={{ cursor: "move", background: "#00B3B3" }}
                id="draggable-dialog-title"
              >
                {openType}
              </DialogTitle>
              <DialogContent>
                <DialogContentText className="clr-dark-blue">
                  {openAlertConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="bg-content">
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleConfirm}
                  className="ns-btn-secondary-light mr-15px"
                >
                  <span className="f-16 fw-bold clr-white">OK</span>
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={handleCloseConfirm}
                  className="ns-btn-secondary-light"
                >
                  <span className="f-16 fw-bold clr-white">Cancel</span>
                </Button>
              </DialogActions>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
          </Grid>
        </form>
      </div>
    </ThemeProvider>
  );
}
