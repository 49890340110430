import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
//import Select from '@material-ui/core/Select';
import Input from "@material-ui/core/Input";
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import validator from "validator";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../components/Loader";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "0 !important",
        },
        height: "55px !important",
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#000028",
          backgroundColor: "#E8F8F8",
        },
        "&&&&:hover": {
          backgroundColor: "#E8F8F8",
        },
        "&$disabled": {
          pointerEvent: "none",
          color: "#707080",
          backgroundColor: "#001035",
          border: "2px solid #707070",
          borderRadius: "0",
          "&:before": {
            borderBottom: "1px solid #001035",
          },
          "&:hover:before": {
            borderBottom: "1px solid #001035",
            borderBottomStyle: "solid",
          },
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:after": {
          borderBottom: "2px solid #E8F8F8",
          transition:
            "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:focused:after": {
          borderBottom: "2px solid #6CD6D6",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiTextField: {
      root: {
        color: "#000028",
        backgroundColor: "#E8F8F8",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#E8F8F8",
        "&$disabled": {
          backgroundColor: "#454545 !important",
        },
      },
    },
    MuiTabs: {
      scroller: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiFormLabel: {
      root: {
        //color: "#B3B3BE",
        color: "#000028",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },

    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        //color: "#B3B3BE",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#00CCCC",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#4C4C68",
      },
      input: {
        backgroundColor: "#EBFBFB",
        color: "#4C4C68",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "#E0F9F9",
          backgroundColor: "#CCF5F5",
        },
        "&$hover": {
          backgroundColor: "#CCF5F5",
        },
      },
    },
    MuiInput: {
      root: {
        height: "56px",
        backgroundColor: "#E8F8F8",
        color: "#000028",
        paddingLeft: "0px",
        "&$focused": {
          backgroundColor: "#E8F8F8",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "2px solid #002949",
        },
        "&:after": {
          borderBottom: "2px solid #002949",
        },
        "&:hover:before": {
          borderBottom: "2px solid #00CCCC",
        },
        "&:hover:after": {
          borderBottom: "2px solid #2EE6E2",
        },
        "&:focused:after": {
          borderBottom: "2px solid  #2EE6E2",
        },
        "&:disabled:before": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    //MuiFilledInput: {
    //    root: {
    //        "&$focused": { // increase the specificity for the pseudo class
    //            color: "#000028",
    //            backgroundColor: "#E8F8F8",
    //        },
    //        "&$hover": {
    //            backgroundColor: "#E8F8F8"
    //        },
    //        "&$disabled": {
    //            "pointerEvent": "none",
    //            "color": "#707080",
    //            "backgroundColor": "#001035",
    //            "border": "2px solid #707070",
    //            "borderRadius": "0",
    //            "&:before": {
    //                "borderBottom": "1px solid #001035",
    //            },
    //            "&:hover:before": {
    //                "borderBottom": "1px solid #001035",
    //                "borderBottomStyle": "solid"
    //            }
    //        }
    //    },
    //    underline: {
    //        "&:before": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:after": {
    //            borderBottom: "2px solid #E8F8F8"
    //        },
    //        "&:hover:before": {
    //            borderBottom: "2px solid #00CCCC"
    //        },
    //        "&:hover:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:focused:after": {
    //            borderBottom: "2px solid #6CD6D6"
    //        },
    //        "&:disabled:before": {
    //            border: "none"
    //        }
    //    },
    //},
    //MuiFormControl: {
    //    root: {
    //        color: "#FFFFFF",
    //        width: "100"
    //    }
    //},
    MuiList: {
      root: {
        backgroundColor: "#CCF5F5",
      },
    },
    MuiListItem: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      rounded: {
        border: `1px solid #FFFFFF`,
        marginBottom: "5px",
        color: "#000028",
      },
    },
    MuiToolbar: {
      root: {
        background: "#E8F8F8",
        color: "#000028",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
      toolbar: {
        borderTop: `1px solid #FFFFFF`,
        "& MuiTypography-root": {
          color: `#00CCCC !important`,
        },
      },
      menuItem: {
        color: "#FFFFFF",
      },
      selectIcon: {
        color: "#00CCCC",
      },
      input: {
        backgroundColor: "#000028",
      },
    },
    MuiTypography: {
      caption: {
        color: "#000028",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#000028",
        "&$focused": {
          color: "#000028",
        },
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
        "& div:hover": {
          color: "#00CCCC",
        },
      },
      icon: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionDesc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
      iconDirectionAsc: {
        color: `#00CCCC !important`,
        "&$hover": {
          color: "#FFFFFF",
        },
        "&$active": {
          color: "#00CCCC",
        },
      },
    },
    MuiTableCell: {
      root: {
        color: "#000028 !important",
        background: "#E8F8F8 !important",
        padding: "10px",
      },
      body: {
        background: "#FFFFFF",
        color: "#000028",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiFormControl: {
      root: {
        paddingLeft: "0px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#2EE6E2",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "#000028",
      },
      container: {
        background: "#000028",
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        background: "#000028",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "none",
        color: "#FFFFFF",
      },
      switchHeader: {
        color: "#FFFFFF",
      },
      daysHeader: {
        color: "#FFFFFF",
      },
      dayLabel: {
        color: "#FFFFFF",
      },
    },
    MuiPickersYear: {
      root: {
        color: "#FFFFFF",
      },
    },
    //MuiPickersBasePicker: {
    //    container: {
    //        background: "#000028"
    //    }
    //},
    MuiDialogActions: {
      root: {
        background: "#000028",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#FFFFFF",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#E8F8F8",
        color: "#000028",
      },
    },
    MTableToolbar: {
      searchField: {
        paddingLeft: "0px !important",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.6rem !important",
        backgroundColor: "#ebfbfb !important",
        marginBottom: "-7px ​!important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
        color: "red !important",
        lineHeight: "1 !important",
      },
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
  baseURL: `https://reqres.in/api`,
});

function PreviousEmploymentHistory(props) {
  const [data, setData] = useState([]); //table data

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState("");
  const [openClassName, setClassName] = useState("");
  const [openType, setOpenType] = useState("");

  var day = 60 * 60 * 24 * 1000;

  const handleClose = () => {
    if (openType === "Success") {
      GetPrevEmpHistoryData(props.id);
    }
    setOpen(false);
    //window.location.reload()
  };

  //fetch Country
  const [itemsCountry, setItemsCountry] = useState([]);
  async function getCountry() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetAllCountries", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsCountry(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  //fetch isRelievingCertificateAvailable
  const [
    itemsRelievingCertificateAvailable,
    setItemsRelievingCertificateAvailable,
  ] = useState([]);
  async function getRelievingCertificateAvailable() {
    const token = props.token;
    const response = await fetch("PreHiringForm/GetYesOrNo", {
      headers: { Authorization: "Bearer " + token },
    });
    const jsonItems = await response.json();
    setItemsRelievingCertificateAvailable(
      jsonItems.map(({ id, name }) => ({ label: name, value: id }))
    );
  }

  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    //else if (characterCode.includes(".")) return

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  const handleKeypressInt = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    //else if (characterCode.includes(".")) return

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };

  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  function ValidateCountryName(rowData) {
    return rowData.countryName === null || rowData.countryName === ""
      ? false
      : true;
  }
  function ValidateRelievingCertificate(rowData) {
    return rowData.relievingCertificate === null ||
      rowData.relievingCertificate === ""
      ? false
      : true;
  }

  function ValidatePeriodFrom(rowData) {
    var isValid = true;
    if (rowData.dateOfJoining === null || rowData.dateOfJoining === "") {
      isValid = false;
    } else {
      const dateFrom = new Date(rowData.dateOfJoining);
      const dateTo = new Date(rowData.dateOfLeaving);
      if (
        rowData.dateOfLeaving !== null &&
        rowData.dateOfLeaving !== "" &&
        dateFrom > dateTo
      ) {
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }
  function ValidatePeriodTo(rowData) {
    var isValid = true;
    if (rowData.dateOfLeaving === null || rowData.dateOfLeaving === "") {
      isValid = false;
    } else {
      const dateFrom = new Date(rowData.dateOfJoining);
      const dateTo = new Date(rowData.dateOfLeaving);
      if (
        rowData.dateOfJoining !== null &&
        rowData.dateOfJoining !== "" &&
        dateFrom > dateTo
      ) {
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
    }
    function CalculateTotalExpYears(rowData) {

        let strtDate = rowData.dateOfLeaving;
        let endDate = rowData.dateOfJoining;
        var enddate = new Date(strtDate);
        var startdate = new Date(endDate);
        var totalRelMonths = enddate.getMonth() - startdate.getMonth() + (12 * (enddate.getFullYear() - startdate.getFullYear()));
        var RelYears = parseInt(totalRelMonths / 12);
        
        return RelYears;

    }
    function CalculateTotalExpMonths(rowData) {

        let strtDate = rowData.dateOfLeaving;
        let endDate = rowData.dateOfJoining;
        var enddate = new Date(strtDate);
        var startdate = new Date(endDate);
        var totalRelMonths = enddate.getMonth() - startdate.getMonth() + (12 * (enddate.getFullYear() - startdate.getFullYear()));
        
        var RelMonths = totalRelMonths % 12;
       
        return RelMonths;

    }


  function ValidateRelevantExpMonths(rowData) {
    var isValid = true;
      if (
          rowData.relevantExpMonths === null ||
          rowData.relevantExpMonths === "" || rowData.relevantExpMonths > 11 || (CalculateTotalExpYears(rowData) === rowData.relevantExpYears && rowData.relevantExpMonths > CalculateTotalExpMonths(rowData))
      ) {
    
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }
  function ValidateRelevantExpYears(rowData) {
      var isValid = true;
      if (rowData.relevantExpYears === null || rowData.relevantExpYears === "" || rowData.relevantExpYears > CalculateTotalExpYears(rowData)) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }
  function ValidateTotalExpMonths(rowData) {
    var isValid = true;
    if (rowData.totalExpMonths === null || rowData.totalExpMonths === "") {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }
  function ValidateTotalExpYears(rowData) {
    var isValid = true;
    if (rowData.totalExpYears === null || rowData.totalExpYears === "") {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
    }
    

  function ValidateFixedSalaryWithComma(rowData) {
    var isValid = true;
    if (
      rowData.fixedSalaryWithComma === null ||
      rowData.fixedSalaryWithComma === ""
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }
  function ValidateVariableSalaryWithComma(rowData) {
    var isValid = true;
    if (
      rowData.variableSalaryWithComma === null ||
      rowData.variableSalaryWithComma === ""
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  //var validSupervisorContactNo = true;
  //const validateSupervisorContactNo = (e) => {
  //    const number = e.target.value;
  //    if (number != "" && number != null && number != undefined && (!validator.isMobilePhone(number) || number.length != 10)) {
  //        validSupervisorContactNo = false;
  //    }
  //    else {
  //        validSupervisorContactNo = true;
  //    }
  //    return validSupervisorContactNo;
  //}

  function ValidateRelievingCertificateSubmissionDate(rowData) {
    var isValid = true;
    if (
      (rowData.relievingCertificateSubmissionDate === null ||
        rowData.relievingCertificateSubmissionDate === "") &&
      rowData.relievingCertificate !== null &&
      rowData.relievingCertificate !== "" &&
      rowData.relievingCertificate !== undefined &&
      (rowData.relievingCertificate === "No" ||
        rowData.relievingCertificate.label === "No")
    ) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }

  function IsValidAddressLines(value) {
    const exp = /[\[\]\(\)\\\/#?<>'";:\-{}*&%$@!]/;
    return !exp.test(value);
  }

  function ValidateAddress(rowData) {
    var isValid = true;

    if (
      rowData.address !== null &&
      rowData.address !== "" &&
      rowData.address !== undefined
    ) {
      if (!IsValidAddressLines(rowData.address)) {
        isValid = false;
      } else {
        isValid = true;
      }
    }

    return isValid;
  }

  var columns = [
    { title: "Row ID", field: "previousEmploymentHistoryId", hidden: true },
    { title: "Applicant ID*", field: "employeeId", hidden: true },
    {
      title: "Name Of Company*",
      field: "companyName",
      validate: (rowData) =>
        rowData.companyName === null || rowData.companyName === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    {
      title: "Last Designation*",
      field: "lastDesignation",
      validate: (rowData) =>
        rowData.lastDesignation === null || rowData.lastDesignation === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    {
      title: "Industry*",
      field: "industry",
      validate: (rowData) =>
        rowData.industry === null || rowData.industry === ""
          ? { isValid: false, helperText: "Mandatory Field" }
          : true,
    },
    {
      title: "Country*",
      field: "countryName",
      validate: (rowData) => ValidateCountryName(rowData),
      editComponent: (props) => {
        const countryNameData = props.rowData.countryNameData
          ? props.rowData.countryNameData.map(({ id, name }) => ({
              label: name,
              value: id,
            }))
          : null;
        const countryName = props.rowData.countryName;
        const customStyles =
          countryName !== undefined &&
          countryName !== null &&
          countryName !== ""
            ? {
                control: (provided, state) => ({
                  ...provided,
                }),
              }
            : {
                control: (provided, state) => ({
                  ...provided,
                  borderColor: "red !important",
                }),
              };
        return (
          <Select
            options={itemsCountry}
            value={
              typeof props.value === "string" ? countryNameData : props.value
            }
            onChange={(opt) => {
              props.onChange(opt);
            }}
            isClearable={true}
            styles={customStyles}
          />
        );
      },
    },
    {
      title: "Date Of Joining*",
      field: "dateOfJoining",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      validate: (rowData) => ValidatePeriodFrom(rowData),
      editComponent: (props) => {
        const dateFrom = props.rowData.dateOfJoining;
        const dateTo = props.rowData.dateOfLeaving;
        let errorText = "";
        var errorState = false;
        if (dateFrom === null || dateFrom === "") {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          const dtFrom = new Date(dateFrom);
          const dtTo = new Date(dateTo);
          if (dateTo !== null && dateTo !== "" && dtFrom > dtTo) {
            errorText = "Must be less than end date";
            errorState = true;
          } else {
            errorText = "";
            errorState = false;
          }
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              clearable
              disableFuture
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    {
      title: "Date Of Leaving*",
      field: "dateOfLeaving",
      type: "date",
      dateSetting: { locale: "fr-ca" },
      validate: (rowData) => ValidatePeriodTo(rowData),
      editComponent: (props) => {
        const dateFrom = props.rowData.dateOfJoining;
        const dateTo = props.rowData.dateOfLeaving;
        let errorText = "";
        var errorState = false;
        if (dateTo === null || dateTo === "") {
          errorText = "Mandatory Field";
          errorState = true;
        } else {
          const dtFrom = new Date(dateFrom);
          const dtTo = new Date(dateTo);
          if (dateFrom !== null && dateFrom !== "" && dtFrom > dtTo) {
            errorText = "Must be ahead of start date";
            errorState = true;
          } else {
            errorText = "";
            errorState = false;
          }
        }
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}
          >
            <DatePicker
              value={props.value == undefined ? null : props.value}
              onChange={(date) => {
                props.onChange(date);
              }}
              format={"yyyy-MM-dd"}
              minDate={
                props.rowData.dateOfJoining
                  ? new Date(props.rowData.dateOfJoining).getTime() + day
                  : null
              }
              clearable
              helperText={errorText}
              error={errorState}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
    
    {
      title: "Relevant Experience (Years)*",
      field: "relevantExpYears",
      validate: (rowData) => ValidateRelevantExpYears(rowData),
      editComponent: (props) => {
        const relevantExpYears = props.rowData.relevantExpYears;
        let errorText = "";
        var errorState = false;
        if (relevantExpYears === null || relevantExpYears === "") {
          errorState = true;
          errorText = "Mandatory Field";
        }
        else if (relevantExpYears > CalculateTotalExpYears(props.rowData)) {
            errorState = true;
            errorText = "Relevant Experience should not exceed Total Experience";
        }
        else {
          errorText = "";
          errorState = false;
        }
        return (
          <TextField
            defaultValue={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            //type="number"
            onKeyDown={handleKeypressInt}
                helperText={errorText + " Eg.10.5 years = 10 Relevant years"}
                
            error={errorState}
          />
        );
      },
      },
      {
          title: "Relevant Experience (Months)*",
          field: "relevantExpMonths",
          validate: (rowData) => ValidateRelevantExpMonths(rowData),

          editComponent: (props) => {
              const relevantExpMonths = props.rowData.relevantExpMonths;
              let errorText = "";
              var errorState = false;
              if (relevantExpMonths === null || relevantExpMonths === "") {
                  errorState = true;
                  errorText = "Mandatory Field";
              }
              else if (relevantExpMonths > 11) {
                  errorState = true;
                  errorText = "Value should not be greater than 11 ";
              }
              else if (CalculateTotalExpYears(props.rowData) == props.rowData.relevantExpYears && relevantExpMonths > CalculateTotalExpMonths(props.rowData)) {
                  errorState = true;
                  errorText = "Relevant Experience should not exceed Total Experience ";
              }
              else {
                  errorText = "";
                  errorState = false;
              }
              return (
                  <TextField
                      defaultValue={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      //type="number"
                      onKeyDown={handleKeypressInt}
                      helperText={errorText + " Eg.10.5 years = 5 Relevant months"}
                      error={errorState}
                  />
              );
          },
      },
    
    {
      title: "Total Experience (Years)",
      field: "totalExpYears",
        validate: (rowData) => ValidateTotalExpYears(rowData),        
        editable : "never",
      //editComponent: (props) => {
      //  const totalExpYears = props.rowData.totalExpYears;
      //  let errorText = "";
      //  var errorState = false;
      //  if (totalExpYears === null || totalExpYears === "") {
      //    errorState = true;
      //    errorText = "Mandatory Field";
      //  } else {
      //    errorText = "";
      //    errorState = false;
      //  }
      //  return (
      //    <TextField
      //          // defaultValue={props.value}
      //          defaultValue={CalculateTotalExpYears(props.rowData)}
      //      onChange={(e) => props.onChange(e.target.value)}
      //      //type="number"
      //      onKeyDown={handleKeypressInt}
      //      helperText={errorText}
      //          error={errorState}
               
      //    />
      //  );
      //},
      },
      {
          title: "Total Experience (Months)",
          field: "totalExpMonths",
          validate: (rowData) => ValidateTotalExpMonths(rowData),
          editable: "never",

          //editComponent: (props) => {
          //  const totalExpMonths = props.rowData.totalExpMonths;

          //  let errorText = "";
          //  var errorState = false;
          //  if (totalExpMonths === null || totalExpMonths === "") {
          //    errorState = true;
          //    errorText = "Mandatory Field";
          //  } else {
          //    errorText = "";
          //    errorState = false;
          //  }
          //  return (
          //    <TextField
          //          defaultValue={CalculateTotalExpMonths(props.rowData)}
          //          //defaultValue={props.value}
          //      onChange={(e) => props.onChange(e.target.value)}
          //      //type="number"
          //      onKeyDown={handleKeypressInt}
          //      helperText={errorText}
          //          error={errorState}

          //    />
          //  );
          //},
      },
    {
      title: "Current Salary (Fixed) (p.a.)*",
      field: "fixedSalaryWithComma",
      validate: (rowData) => ValidateFixedSalaryWithComma(rowData),
      editComponent: (props) => {
        const fixedSalaryWithComma = props.rowData.fixedSalaryWithComma;
        var formattedFixedSalaryWithComma = props.rowData.fixedSalaryWithComma;

        if (
          fixedSalaryWithComma != null &&
          fixedSalaryWithComma != "" &&
          fixedSalaryWithComma != undefined
        ) {
          formattedFixedSalaryWithComma = (
            Number(fixedSalaryWithComma.toString().replace(/\D/g, "")) || ""
          ).toLocaleString();
        }

        let errorText = "";
        var errorState = false;
        if (fixedSalaryWithComma === null || fixedSalaryWithComma === "") {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <TextField
            value={
              formattedFixedSalaryWithComma
                ? formattedFixedSalaryWithComma
                : props.value
            }
            onChange={(e) => props.onChange(e.target.value)}
            //type="number"
            onKeyDown={handleKeypress}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },
    {
      title: "Current Salary (Variable) (p.a.)*",
      field: "variableSalaryWithComma",
      validate: (rowData) => ValidateVariableSalaryWithComma(rowData),
      editComponent: (props) => {
        const variableSalaryWithComma = props.rowData.variableSalaryWithComma;
        var formattedVariableSalaryWithComma =
          props.rowData.variableSalaryWithComma;

        if (
          variableSalaryWithComma != null &&
          variableSalaryWithComma != "" &&
          variableSalaryWithComma != undefined
        ) {
          formattedVariableSalaryWithComma = (
            Number(variableSalaryWithComma.toString().replace(/\D/g, "")) || ""
          ).toLocaleString();
        }

        let errorText = "";
        var errorState = false;
        if (
          variableSalaryWithComma === null ||
          variableSalaryWithComma === ""
        ) {
          errorState = true;
          errorText = "Mandatory Field";
        } else {
          errorText = "";
          errorState = false;
        }
        return (
          <TextField
            value={
              formattedVariableSalaryWithComma
                ? formattedVariableSalaryWithComma
                : props.value
            }
            onChange={(e) => props.onChange(e.target.value)}
            //type="number"
            onKeyDown={handleKeypress}
            helperText={errorText}
            error={errorState}
          />
        );
      },
      },
      {
          title: "Is Relieving/Experience Certificate Available*",
          field: "relievingCertificate",
          validate: (rowData) => ValidateRelievingCertificate(rowData),
          editComponent: (props) => {
              const relievingCertificateData = props.rowData.relievingCertificateData
                  ? props.rowData.relievingCertificateData.map(({ id, name }) => ({
                      label: name,
                      value: id,
                  }))
                  : null;
              const relievingCertificate = props.rowData.relievingCertificate;
              const customStyles =
                  relievingCertificate !== undefined &&
                      relievingCertificate !== null &&
                      relievingCertificate !== ""
                      ? {
                          control: (provided, state) => ({
                              ...provided,
                          }),
                      }
                      : {
                          control: (provided, state) => ({
                              ...provided,
                              borderColor: "red !important",
                          }),
                      };
              return (
                  <Select
                      options={itemsRelievingCertificateAvailable}
                      value={
                          typeof props.value === "string"
                              ? relievingCertificateData
                              : props.value
                      }
                      onChange={(opt) => {
                          props.onChange(opt);
                      }}
                      isClearable={true}
                      styles={customStyles}
                  />
              );
          },
      },
      {
          title: "Relieving/Experience Certificate Submission Date",
          field: "relievingCertificateSubmissionDate",
          type: "date",
          dateSetting: { locale: "fr-ca" },
          //initialEditValue: 'If No, Please fill: I hereby mention that I will submit the experience certificate/ relieving letter on or before ____________________.In case, I am unable to provide the same on or before ____________________, company can take any suitable action against me.'
          validate: (rowData) =>
              ValidateRelievingCertificateSubmissionDate(rowData),
          editComponent: (props) => {
              const relievingCertificate = props.rowData.relievingCertificate;
              const relievingCertificateSubmissionDate =
                  props.rowData.relievingCertificateSubmissionDate;

              let errorText = "";
              var errorState = false;
              if (
                  (relievingCertificateSubmissionDate === null ||
                      relievingCertificateSubmissionDate === "") &&
                  relievingCertificate !== null &&
                  relievingCertificate !== "" &&
                  relievingCertificate !== undefined &&
                  (relievingCertificate === "No" || relievingCertificate.label === "No")
              ) {
                  errorState = true;
                  errorText = "Mandatory Field";
              } else {
                  errorText = "";
                  errorState = false;
              }
              return (
                  <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={props.dateTimePickerLocalization}
                  >
                      <DatePicker
                          //value={(props.value == undefined ? null : props.value)}
                          value={
                              (typeof relievingCertificate === "string" &&
                                  relievingCertificate === "Yes") ||
                                  (typeof relievingCertificate === "object" &&
                                      relievingCertificate !== null &&
                                      relievingCertificate !== "" &&
                                      relievingCertificate !== undefined &&
                                      relievingCertificate.label === "Yes")
                                  ? null
                                  : props.value == undefined
                                      ? null
                                      : props.value
                          }
                          onChange={(date) => {
                              props.onChange(date);
                          }}
                          format={"yyyy-MM-dd"}
                          clearable
                          disablePast
                          helperText={errorText}
                          error={errorState}
                          disabled={
                              (typeof relievingCertificate === "string" &&
                                  relievingCertificate === "Yes") ||
                                  (typeof relievingCertificate === "object" &&
                                      relievingCertificate !== null &&
                                      relievingCertificate !== "" &&
                                      relievingCertificate !== undefined &&
                                      relievingCertificate.label === "Yes")
                                  ? true
                                  : false
                          }
                      />
                  </MuiPickersUtilsProvider>
              );
          },
      },
      { title: "Employee Code/Roll Number", field: "empRollNo" },
    {
      title: "Company Address",
      field: "address",
      validate: (rowData) => ValidateAddress(rowData),
      editComponent: (props) => {
        const address = props.rowData.address;

        let errorText = "";
        var errorState = false;

        if (address !== null && address !== "" && address !== undefined) {
          if (!IsValidAddressLines(address)) {
            errorState = true;
            var str = "#/?><'" + '";:-}{][*&%$@!()';
            errorText = str.toString() + " are not allowed";
          } else {
            errorText = "";
            errorState = false;
          }
        }

        return (
          <TextField
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            helperText={errorText}
            error={errorState}
          />
        );
      },
    },

    { title: "Reason Of Leaving", field: "reasonOfLeaving" },
    { title: "Supervisor Name", field: "supervisorName" },
    {
      title: "Supervisor Contact Number",
      field: "supervisorContactNo",
      validate: (rowData) =>
        rowData.supervisorContactNo != "" &&
        rowData.supervisorContactNo != null &&
        rowData.supervisorContactNo != undefined &&
        (!validator.isMobilePhone(rowData.supervisorContactNo) ||
          rowData.supervisorContactNo.length != 10)
          ? { isValid: false, helperText: "Invalid Mobile No" }
          : true,
    },

    {
      title: "Supervisor Email ID",
      field: "supervisorEmailId",
      validate: (rowData) =>
        rowData.supervisorEmailId != "" &&
        rowData.supervisorEmailId != null &&
        rowData.supervisorEmailId != undefined &&
        !validator.isEmail(rowData.supervisorEmailId)
          ? { isValid: false, helperText: "Invalid Email Id" }
          : true,
    },
    {
      title: "HR Name (who can help with background verification)",
      field: "hrname",
    },
    {
      title: "HR Contact Number",
      field: "hrcontactNo",
      validate: (rowData) =>
        rowData.hrcontactNo != "" &&
        rowData.hrcontactNo != null &&
        rowData.hrcontactNo != undefined &&
        (!validator.isMobilePhone(rowData.hrcontactNo) ||
          rowData.hrcontactNo.length != 10)
          ? { isValid: false, helperText: "Invalid Mobile No" }
          : true,
    },
    {
      title: "HR Email ID",
      field: "hremailId",
      validate: (rowData) =>
        rowData.hremailId != "" &&
        rowData.hremailId != null &&
        rowData.hremailId != undefined &&
        !validator.isEmail(rowData.hremailId)
          ? { isValid: false, helperText: "Invalid Email Id" }
          : true,
    },
 
  ];

  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const handleErrorClose = (e) => {
    setIserror(false);
  };

  const [isLoader, setIsLoader] = useState(false);

  //useEffect(() => {

  //    getCountry();
  //    getRelievingCertificateAvailable();

  //}, []);

  //useEffect(() => {
  //    api.get("/users")
  //        .then(res => {
  //            setData(res.data.data)
  //        })
  //        .catch(error => {
  //            console.log("Error")
  //        })
  //}, [])

  function GetPrevEmpHistoryData(id) {
    setIsLoader(true);
    const token = props.token;
    axios
      .get("/PreHiringForm/GetPreviousEmploymentHistory/" + id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("Error : ", error);
      });
  }

  useEffect(() => {
    if (props.token) {
      getCountry();
      getRelievingCertificateAvailable();
      if (props.id != null && props.id != undefined) {
        GetPrevEmpHistoryData(props.id);
      }
    }
  }, [props.id, props.token]);

  //useEffect(() => {
  //    if (props.id != null && props.id != undefined) {
  //        axios.get('/PreHiringForm/GetPreviousEmploymentHistory/' + props.id)
  //            .then((res) => {

  //                setData(res.data)
  //            })
  //            .catch(error => {
  //                console.log("Error")
  //            })
  //    }

  //}, [props.id])

  const handleRowUpdate = (newData, oldData, resolve, reject) => {
    //validation
    let errorList = [];
    //if (newData.companyName === "" || newData.companyName === undefined || newData.companyName === null) {
    //    errorList.push("Please enter Name Of Company")
    //}
    //if (newData.lastDesignation === "" || newData.lastDesignation === undefined || newData.lastDesignation === null) {
    //    errorList.push("Please enter Last Designation")
    //}
    //if (newData.industry === "" || newData.industry === undefined || newData.industry === null) {
    //    errorList.push("Please enter Industry")
    //}
    ////if (newData.empRollNo === "") {
    ////    errorList.push("Please enter Employee Code/Roll Number")
    ////}
    //if (newData.countryName === "" || newData.countryName === undefined || newData.countryName === null) {
    //    errorList.push("Please enter Country")
    //}
    //if (newData.dateOfJoining === "" || newData.dateOfJoining === undefined || newData.dateOfJoining === null) {
    //    errorList.push("Please enter Date Of Joining")
    //}
    //if (newData.dateOfLeaving === "" || newData.dateOfLeaving === undefined || newData.dateOfLeaving === null) {
    //    errorList.push("Please enter Date Of Leaving")
    //}
    //if (newData.relevantExpMonths === "" || newData.relevantExpMonths === undefined || newData.relevantExpMonths === null) {
    //    errorList.push("Please enter Relevant Experience (Months)")
    //}
    //if (newData.relevantExpYears === "" || newData.relevantExpYears === undefined || newData.relevantExpYears === null) {
    //    errorList.push("Please enter Relevant Experience (Years)")
    //}
    //if (newData.totalExpMonths === "" || newData.totalExpMonths === undefined || newData.totalExpMonths === null) {
    //    errorList.push("Please enter Total Experience (Months)")
    //}
    //if (newData.totalExpYears === "" || newData.totalExpYears === undefined || newData.totalExpYears === null) {
    //    errorList.push("Please enter Total Experience (Years)")
    //}
    //if (newData.fixedSalaryWithComma === "" || newData.fixedSalaryWithComma === undefined || newData.fixedSalaryWithComma === null) {
    //    errorList.push("Please enter Current Salary Fixed")
    //}
    //if (newData.variableSalaryWithComma === "" || newData.variableSalaryWithComma === undefined || newData.variableSalaryWithComma === null) {
    //    errorList.push("Please enter Current Salary Variable")
    //}

    ////if (newData.address === "") {
    ////    errorList.push("Please enter Address")
    ////}
    ////if (newData.reasonOfLeaving === "") {
    ////    errorList.push("Please enter Reason Of Leaving")
    ////}
    ////if (newData.supervisorName === "") {
    ////    errorList.push("Please enter Supervisor Name")
    ////}
    ////if (newData.supervisorContactNo === "") {
    ////    errorList.push("Please enter Supervisor Contact Number")
    ////}
    ////if (newData.supervisorEmailId === "") {
    ////    errorList.push("Please enter Supervisor Email ID")
    ////}
    ////if (newData.hrname === "") {
    ////    errorList.push("Please enter HR Name")
    ////}
    ////if (newData.hrcontactNo === "") {
    ////    errorList.push("Please enter HR Contact Number")
    ////}
    ////if (newData.hremailId === "") {
    ////    errorList.push("Please enter HR Email ID")
    ////}
    //if (newData.relievingCertificate === "" || newData.relievingCertificate === undefined || newData.relievingCertificate === null) {
    //    errorList.push("Please enter Is Relieving/Experience Certificate Available ")
    //}
    //if (newData.relievingCertificateComments === "" || newData.relievingCertificateComments === undefined || newData.relievingCertificateComments === null) {
    //    errorList.push("Please enter Relieving/Experience Certificate Available Comments")
    //}

    ////if (newData.fixedSalaryWithComma.includes("-")) {
    ////    errorList.push("Please enter numbers only in Current Salary(Fixed) (p.a.)")
    ////}

    ////if (newData.variableSalaryWithComma.includes("-")) {
    ////    errorList.push("Please enter numbers only in Current Salary(Variable) (p.a.)")
    ////}

    //if (newData.dateOfJoining != "" && newData.dateOfJoining != undefined && newData.dateOfJoining != null && newData.dateOfLeaving != "" && newData.dateOfLeaving != undefined && newData.dateOfLeaving != null) {
    //    var formattedDateJoining = formatDate(newData.dateOfJoining);
    //    var formattedDateLeaving = formatDate(newData.dateOfLeaving);
    //    var dateJoining = new Date(formattedDateJoining);
    //    var dateLeaving = new Date(formattedDateLeaving);
    //    if (dateJoining > dateLeaving) {
    //        errorList.push("Date Of Joining cannot be greater than Date Of Leaving")
    //    }
    //}

    //if (newData.supervisorEmailId != "" && newData.supervisorEmailId != null && newData.supervisorEmailId != undefined && !validator.isEmail(newData.supervisorEmailId)) {
    //    errorList.push("Please enter a valid Supervisor Email Id")
    //}

    //if (newData.hremailId != "" && newData.hremailId != null && newData.hremailId != undefined && !validator.isEmail(newData.hremailId)) {
    //    errorList.push("Please enter a valid HR Email Id")
    //}

    //if (newData.supervisorContactNo != "" && newData.supervisorContactNo != null && newData.supervisorContactNo != undefined && (!validator.isMobilePhone(newData.supervisorContactNo) || newData.supervisorContactNo.length != 10)) {
    //    errorList.push("Please enter a valid 10-digit Mobile No for Supervisor")
    //}

    //if (newData.hrcontactNo != "" && newData.hrcontactNo != null && newData.hrcontactNo != undefined && (!validator.isMobilePhone(newData.hrcontactNo) || newData.hrcontactNo.length != 10)) {
    //    errorList.push("Please enter a valid 10-digit Mobile No for HR")
    //}

    //added for formatting of newData start
    const countryName =
      newData.countryName === null
        ? null
        : typeof newData.countryName === "object"
        ? newData.countryName.label
        : newData.countryName;
    const relievingCertificate =
      newData.relievingCertificate === null
        ? null
        : typeof newData.relievingCertificate === "object"
        ? newData.relievingCertificate.label
        : newData.relievingCertificate;

    newData.countryName = countryName;
    newData.relievingCertificate = relievingCertificate;

    if (
      newData.fixedSalaryWithComma != null &&
      newData.fixedSalaryWithComma != "" &&
      newData.fixedSalaryWithComma != undefined
    ) {
      newData.fixedSalaryWithComma = (
        Number(newData.fixedSalaryWithComma.toString().replace(/\D/g, "")) || ""
      ).toLocaleString();
    }

    if (
      newData.variableSalaryWithComma != null &&
      newData.variableSalaryWithComma != "" &&
      newData.variableSalaryWithComma != undefined
    ) {
      newData.variableSalaryWithComma = (
        Number(newData.variableSalaryWithComma.toString().replace(/\D/g, "")) ||
        ""
      ).toLocaleString();
    }

    // end

    newData.previousEmploymentHistoryId = oldData.tableData.id;
    newData.employeeId = props.id;
    //newData.userId = props.loginDetail[0].userid;
    newData.userId = props.userId;
      newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
      newData.totalExpMonths = CalculateTotalExpMonths(newData);
     
      newData.totalExpYears = CalculateTotalExpYears(newData);
    if (errorList.length < 1) {
      //api.patch("/users/" + newData.id, newData)
      //    .then(res => {
      //        const dataUpdate = [...data];
      //        const index = oldData.tableData.id;
      //        dataUpdate[index] = newData;
      //        setData([...dataUpdate]);
      //        resolve()
      //        setIserror(false)
      //        setErrorMessages([])
      //    })
      const token = props.token;
      axios
        .put("/PreHiringForm/UpdatePreviousEmploymentHistory", newData, {
          headers: { Authorization: "Bearer " + token },
        })

        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            // resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Update failed! Server error"]);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
      reject();
    }
  };

  const handleRowAdd = (newData, resolve, reject) => {
    //validation
    let errorList = [];
    if (
      newData.companyName === "" ||
      newData.companyName === undefined ||
      newData.companyName === null
    ) {
      errorList.push("Please enter Name Of Company");
    }
    if (
      newData.lastDesignation === "" ||
      newData.lastDesignation === undefined ||
      newData.lastDesignation === null
    ) {
      errorList.push("Please enter Last Designation");
    }
    if (
      newData.industry === "" ||
      newData.industry === undefined ||
      newData.industry === null
    ) {
      errorList.push("Please enter Industry");
    }
    //if (newData.empRollNo === "") {
    //    errorList.push("Please enter Employee Code/Roll Number")
    //}
    if (
      newData.countryName === "" ||
      newData.countryName === undefined ||
      newData.countryName === null
    ) {
      errorList.push("Please enter Country");
    }
    if (
      newData.dateOfJoining === "" ||
      newData.dateOfJoining === undefined ||
      newData.dateOfJoining === null
    ) {
      errorList.push("Please enter Date Of Joining");
    }
    if (
      newData.dateOfLeaving === "" ||
      newData.dateOfLeaving === undefined ||
      newData.dateOfLeaving === null
    ) {
      errorList.push("Please enter Date Of Leaving");
    }
    if (
      newData.relevantExpMonths === "" ||
      newData.relevantExpMonths === undefined ||
      newData.relevantExpMonths === null
    ) {
        errorList.push("Please enter Relevant Experience (Months)");

    }
    if (
      newData.relevantExpYears === "" ||
      newData.relevantExpYears === undefined ||
      newData.relevantExpYears === null
    ) {
      errorList.push("Please enter Relevant Experience (Years)");
    }
    if (
      newData.totalExpMonths === "" ||
      newData.totalExpMonths === undefined ||
      newData.totalExpMonths === null
    ) {
      //errorList.push("Please enter Total Experience (Months)");
    }
    if (
      newData.totalExpYears === "" ||
      newData.totalExpYears === undefined ||
      newData.totalExpYears === null
    ) {
      //errorList.push("Please enter Total Experience (Years)");
    }
    if (
      newData.fixedSalaryWithComma === "" ||
      newData.fixedSalaryWithComma === undefined ||
      newData.fixedSalaryWithComma === null
    ) {
      errorList.push("Please enter Current Salary Fixed");
    }
    if (
      newData.variableSalaryWithComma === "" ||
      newData.variableSalaryWithComma === undefined ||
      newData.variableSalaryWithComma === null
    ) {
      errorList.push("Please enter Current Salary Variable");
    }

    //if (newData.fixedSalaryWithComma != undefined) {

    //    if (newData.fixedSalaryWithComma.includes("-")) {
    //        errorList.push("Please enter numbers only in Current Salary(Fixed) (p.a.)")
    //    }
    //}

    //if (newData.variableSalaryWithComma != undefined) {
    //    if (newData.variableSalaryWithComma.includes("-")) {
    //        errorList.push("Please enter numbers only in Current Salary(Variable) (p.a.)")
    //    }
    //}

    //if (newData.address === "") {
    //    errorList.push("Please enter Address")
    //}
    //if (newData.reasonOfLeaving === "") {
    //    errorList.push("Please enter Reason Of Leaving")
    //}
    //if (newData.supervisorName === "") {
    //    errorList.push("Please enter Supervisor Name")
    //}
    //if (newData.supervisorContactNo === "") {
    //    errorList.push("Please enter Supervisor Contact Number")
    //}
    //if (newData.supervisorEmailId === "") {
    //    errorList.push("Please enter Supervisor Email ID")
    //}
    //if (newData.hrname === "") {
    //    errorList.push("Please enter HR Name")
    //}
    //if (newData.hrcontactNo === "") {
    //    errorList.push("Please enter HR Contact Number")
    //}
    //if (newData.hremailId === "") {
    //    errorList.push("Please enter HR Email ID")
    //}
    if (
      newData.relievingCertificate === "" ||
      newData.relievingCertificate === undefined ||
      newData.relievingCertificate === null
    ) {
      errorList.push(
        "Please enter Is Relieving/Experience Certificate Available "
      );
    }
    if (
      (newData.relievingCertificateSubmissionDate === "" ||
        newData.relievingCertificateSubmissionDate === undefined ||
        newData.relievingCertificateSubmissionDate === null) &&
      newData.relievingCertificate !== "" &&
      newData.relievingCertificate !== undefined &&
      newData.relievingCertificate !== null &&
      (newData.relievingCertificate === "No" ||
        newData.relievingCertificate.label === "No")
    ) {
      errorList.push(
        "Please enter Relieving/Experience Certificate Submission Date"
      );
    }

    if (
      newData.dateOfJoining != "" &&
      newData.dateOfJoining != undefined &&
      newData.dateOfJoining != null &&
      newData.dateOfLeaving != "" &&
      newData.dateOfLeaving != undefined &&
      newData.dateOfLeaving != null
    ) {
      var formattedDateJoining = formatDate(newData.dateOfJoining);
      var formattedDateLeaving = formatDate(newData.dateOfLeaving);
      var dateJoining = new Date(formattedDateJoining);
      var dateLeaving = new Date(formattedDateLeaving);
      if (dateJoining > dateLeaving) {
        errorList.push(
          "Date Of Joining cannot be greater than Date Of Leaving"
        );
      }
    }

    if (
      newData.supervisorEmailId != "" &&
      newData.supervisorEmailId != null &&
      newData.supervisorEmailId != undefined &&
      !validator.isEmail(newData.supervisorEmailId)
    ) {
      errorList.push("Please enter a valid Supervisor Email Id");
    }

    if (
      newData.hremailId != "" &&
      newData.hremailId != null &&
      newData.hremailId != undefined &&
      !validator.isEmail(newData.hremailId)
    ) {
      errorList.push("Please enter a valid HR Email Id");
    }

    if (
      newData.supervisorContactNo != "" &&
      newData.supervisorContactNo != null &&
      newData.supervisorContactNo != undefined &&
      (!validator.isMobilePhone(newData.supervisorContactNo) ||
        newData.supervisorContactNo.length != 10)
    ) {
      errorList.push("Please enter a valid 10-digit Mobile No for Supervisor");
    }

    if (
      newData.hrcontactNo != "" &&
      newData.hrcontactNo != null &&
      newData.hrcontactNo != undefined &&
      (!validator.isMobilePhone(newData.hrcontactNo) ||
        newData.hrcontactNo.length != 10)
    ) {
      errorList.push("Please enter a valid 10-digit Mobile No for HR");
    }

    //added for formatting of newData start
    const countryName =
      newData.countryName === null
        ? null
        : typeof newData.countryName === "object"
        ? newData.countryName.label
        : newData.countryName;
    const relievingCertificate =
      newData.relievingCertificate === null
        ? null
        : typeof newData.relievingCertificate === "object"
        ? newData.relievingCertificate.label
        : newData.relievingCertificate;

    newData.countryName = countryName;
    newData.relievingCertificate = relievingCertificate;

    if (
      newData.fixedSalaryWithComma != null &&
      newData.fixedSalaryWithComma != "" &&
      newData.fixedSalaryWithComma != undefined
    ) {
      newData.fixedSalaryWithComma = (
        Number(newData.fixedSalaryWithComma.toString().replace(/\D/g, "")) || ""
      ).toLocaleString();
    }

    if (
      newData.variableSalaryWithComma != null &&
      newData.variableSalaryWithComma != "" &&
      newData.variableSalaryWithComma != undefined
    ) {
      newData.variableSalaryWithComma = (
        Number(newData.variableSalaryWithComma.toString().replace(/\D/g, "")) ||
        ""
      ).toLocaleString();
    }

    // end

      

    newData.employeeId = props.id;
    //newData.userId = props.loginDetail[0].userid;
    newData.userId = props.userId;
      newData.UserIdencrypted = props.loginDetail[0].userEncryptedId;
      newData.totalExpMonths = CalculateTotalExpMonths(newData);      
      newData.totalExpYears = CalculateTotalExpYears(newData);

    if (errorList.length < 1) {
      //no error
      //api.post("/users", newData)
      //    .then(res => {
      //        let dataToAdd = [...data];
      //        dataToAdd.push(newData);
      //        setData(dataToAdd);
      //        resolve()
      //        setErrorMessages([])
      //        setIserror(false)
      //    })
        const token = props.token;        
        
      axios
        .post("/PreHiringForm/SavePreviousEmploymentHistory", newData, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          if (response.data.response) {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-success");
            setOpenType("Success");
            resolve();
            setIserror(false);
            setErrorMessages([]);
          } else {
            setOpen(true);
            setOpenAlert(response.data.responseMsg);
            setClassName("clr-dark-blue bg-error");
            setOpenType("Error");
            //resolve();
            reject();
            setIserror(false);
            setErrorMessages([]);
          }
        })
        .catch((error) => {
          reject();
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      //resolve()
      reject();
    }
  };

  const handleRowDelete = (oldData, resolve, reject) => {
    //api.delete("/users/" + oldData.id)
    //    .then(res => {
    //        const dataDelete = [...data];
    //        const index = oldData.tableData.id;
    //        dataDelete.splice(index, 1);
    //        setData([...dataDelete]);
    //        resolve()
    //    })

    oldData.previousEmploymentHistoryId = oldData.tableData.id;
    oldData.employeeId = props.id;
    const token = props.token;
    axios
      .put("/PreHiringForm/DeletePreviousEmploymentHistory", oldData, {
        headers: { Authorization: "Bearer " + token },
      })

      .then((response) => {
        if (response.data.response) {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-success");
          setOpenType("Success");
          resolve();
          setIserror(false);
          setErrorMessages([]);
        } else {
          setOpen(true);
          setOpenAlert(response.data.responseMsg);
          setClassName("clr-dark-blue bg-error");
          setOpenType("Error");
          // resolve();
          reject();
          setIserror(false);
          setErrorMessages([]);
        }
      })
      .catch((error) => {
        reject();
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        // resolve()
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="width-100">
        <Grid container spacing={1}>
          <div>
            <p className="f-12">
              Note : 1. Please enter Employment History details in reverse
              chronological order (From Current To Previous) In case of current
              employment the date of leaving will be taken as system date.{" "}
              <br />
              2. Please mention the payroll company name in Name of company.{" "}
            </p>
          </div>
          <Grid item xs={12}>
            <div>
              {iserror && (
                <Alert severity="error" onClose={handleErrorClose}>
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <MaterialTable
              title="Previous Employment History"
              columns={columns}
              data={data}
              isLoading={isLoader}
              components={{
                OverlayLoading: (props) => <Loader {...props} />,
              }}
              icons={tableIcons}
              localization={{
                header: {
                  actions: "",
                },
              }}
              options={{
                search: false,
                toolbar: props.disableValue === true ? false : true,
              }}
              editable={{
                isEditable: (rowData) => props.disableValue === false,
                isDeletable: (rowData) => props.disableValue === false,

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(newData, oldData, resolve, reject);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newData, resolve, reject);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldData, resolve, reject);
                  }),
              }}
            />

            <p className="clr-red f-12">
              Disclaimer : "If Relieving/Experience Certificate is not
              available: I hereby mention that I will submit the experience
              certificate/ relieving letter on or before the selected submission
              date. In case, I am unable to provide the same on or before the
              selected date, company can take any suitable action against me."
            </p>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            id="templateUploadSuccess"
          >
            <DialogTitle className={openClassName} id="draggable-dialog-title">
              {openType}
            </DialogTitle>
            <DialogContent className="bg-content">
              <DialogContentText className="clr-dark-blue">
                {openAlert}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-popup">
              <Button
                variant="contained"
                autoFocus
                onClick={handleClose}
                className="ns-btn-secondary-light"
              >
                <span className="f-16 fw-bold">OK</span>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default PreviousEmploymentHistory;
